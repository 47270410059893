import { Component, Input } from '@angular/core';
type ProgressBarMode = 'determinate' | 'indeterminate' | 'buffer' | 'query';
type ProgressBarSize = 'size-large' | 'size-short';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'zs-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent {
  // https://material.angular.io/components/progress-bar/api
  @Input() mode: ProgressBarMode = 'determinate';
  @Input() value = 0;
  @Input() size: ProgressBarSize = 'size-short';

}
