import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Language} from 'src/translation/translation';
import {TranslatorService} from '../translator.service';

interface ModalHelperProps {
  title: string;
  description: string;
  gif?: string;
  helperLink?: string;
  titleHelperLink?: string;
}

@Component({
  selector: 'app-modal-helper',
  templateUrl: './modal-helper.component.html',
  styleUrls: ['./modal-helper.component.scss']
})
export class ModalHelperComponent {
  lang: Language = localStorage.getItem('lang') as Language || 'pt-br';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalHelperProps,
    public matDialog: MatDialogRef<ModalHelperComponent>,
    private translationService: TranslatorService) {
  }


  t(ptText: string) {
    return this.translationService.translate(ptText, this.lang);
  }

  closedModal(): void {
    this.matDialog.close();
  }

  redirectToWhatsAppBotmakerOrLATAM() {
    const textWhatsApp = this.t('Olá, preciso de ajuda, gostaria de falar com a equipe de suporte da ZapSign.');
    const phone = this.lang === 'pt-br' ? '551149508941' : '573112604112';

    return `https://api.whatsapp.com/send?phone=${phone}&text=${textWhatsApp}`
  }
}
