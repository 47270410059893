import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Language, Locale} from '../../../../translation/translation';
import {AppState} from '../../../app.state';
import {TranslatorService} from '../../../components/translator.service';
import {SlugTextType} from '../../../helpers-global.service';
import {LaunchDarkly} from '../../../shared/singleton/launch-darkly.singleton';
import {setAdvancedAuthTypes} from './take-advanced-authentication.action';
import {AuthBackendService} from '../../../auth/backend.service';
import {Company} from '../../../helpers/model/user';

@Injectable({
  providedIn: 'root'
})
export class TakeAdvancedAuthenticationService {
  private readonly lang: Language = localStorage.getItem('lang') as Language || 'pt-br';
  private readonly locale: Locale = localStorage.getItem('locale') as Locale || 'br';
  readonly types: SlugTextType[] = [];

  company: Company;

  private hasCpfValidation = true;
  private hasPremiumCpfValidation = false;
  private hasLivenessValidation = false;

  constructor(
    private translatorService: TranslatorService,
    private store: Store<AppState>,
    private authBackend: AuthBackendService,
  ) {
    const client = LaunchDarkly.getClient();
    client.on('ready', () => {
      this.hasCpfValidation = client.variation('zexp-23-feature-flag-cp-fsem-validacao-na-receita', false);
      this.hasPremiumCpfValidation = client.variation('zexp-16-autenticacao-avancada-cpf', false);
      this.hasLivenessValidation = client.variation('zexp-66-frontend-criar-icone-liveness-no-fluxo-de-criacao-de-documento', false);
      this.getAdvancedAuthenticationTypes().then();
    });
  }

  t(ptText: string, replacements: string[] = []) {
    return this.translatorService.translate(ptText, this.lang, replacements);
  }

  async getMe() {
    const user: any = await this.authBackend.getMe().toPromise();
    this.company = user.company;
  }

  async getAdvancedAuthenticationTypes(): Promise<SlugTextType[]> {
    if (!this.company) {
      await this.getMe();
    }
    const types = [
      {
        slug: 'selfie',
        text: this.t('Selfie'),
        icon: 'camera',
        isSelected: false,
        isDisabled: false,
        tooltip: this.t('Selfie: Pedir que o signatário tire uma selfie (foto do rosto) ao assinar. A foto constará no relatório de assinaturas como um dos pontos de autenticação. Quanto mais autenticações avançadas você usar, maior será sua segurança jurídica!')
      },
      {
        slug: 'identity-document',
        text: this.t('Foto do documento de identidade'),
        icon: 'identity-doc',
        isSelected: false,
        isDisabled: false,
        tooltip: this.t('Documento de identidade: Pedir para que o signatário tire uma foto do documento de identidade (ex RG, CNH) ao assinar. Constará no relatório de assinaturas como um dos pontos de autenticação. Quanto mais autenticações avançadas você usar, maior será sua segurança jurídica!')
      },
      {
        slug: 'facial-recognition',
        text: this.t('Reconhecimento facial'),
        icon: 'video',
        isSelected: false,
        isDisabled: false,
        tooltip: this.t('Reconhecimento facial: Solicitar para que o signatário grave um vídeo seguindo as instruções de “vire o rosto”. Deverá também fornecer fotos frente e verso de seu RG ou CNH. O rosto filmado será comparado com a foto do documento oficial')
      },
    ];

    if (this.locale === 'br') {
      if (this.hasCpfValidation) {
        types.push({
          slug: 'cpf',
          text: this.t('CPF Simples'),
          icon: 'cpf-auth',
          isSelected: false,
          isDisabled: false,
          tooltip: 'CPF (sem validação na Receita Federal): O número do CPF será solicitado ao signatário, mas não passará por nenhum tipo de validação junto à receita federal. Constará no relatório de assinaturas como um dos pontos de autenticação.'
        });
      }
      if (this.hasPremiumCpfValidation) {
        types.push({
          slug: 'cpf-premium',
          text: this.t('CPF Avançado'),
          icon: 'cpf-premium-auth',
          isSelected: false,
          isDisabled: false,
          tooltip: 'CPF (com validação na Receita Federal): O número do CPF e a data de nascimento serão solicitados ao signatário e validados junto à receita federal. Constará no relatório de assinaturas como um dos pontos de autenticação.'
        });
      }
      if (this.hasLivenessValidation && this.company?.liveness_provider === 'nxcd') {
        types.push({
          slug: 'liveness-NXCD',
          text: this.t('Biometria facial - Prova de vida'),
          icon: 'liveness',
          isSelected: false,
          isDisabled: false,
          tooltip: 'Custo adicional: 15 créditos (R$1,50)\n\nBiometria facial - Prova de vida: Pedir que o signatário grave um vídeo seguindo as intruções de “vire o rosto” ao assinar. Faremos uma validação para confirmar a prova de vida. Constará no relatório de assinaturas como um dos pontos de autenticação.'
        });
      }
      types.push({
        slug: 'rg',
        text: this.t('RG Simples'),
        icon: 'cpf-auth',
        isSelected: false,
        isDisabled: false,
        tooltip: 'RG (sem validação na Receita Federal): O número do RG será solicitado ao signatário, mas não passará por nenhum tipo de validação junto à receita federal. Constará no relatório de assinaturas como um dos pontos de autenticação.'
      });
    }

    this.store.dispatch(setAdvancedAuthTypes({payload: types}));
    return types;
  }
}
