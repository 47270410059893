<div class="container-dropzone">
  <div
    ng2FileDrop
    class="drop-zone"
    (fileOver)="fileOverBase($event)"
    [uploader]="uploader"
    [class.background-white-container]="showIntroBackground()"
    [class.drop-zone-intro-hover]="!showIntroBackground()"
    accept=".doc, .docx, .pdf, .odt"
  >
    <div
      class="drop-zone-over"
      [ngStyle]="{ opacity: isDraggingFiles ? '1' : '0' }"
    >
      <span>{{ "Solte aqui" | translate }}</span>
    </div>
    <div *ngIf="checkIfShowIntroView()">
      <label for="files" class="label-dropzone">
        <div
          id="select-files-test"
          class="no-selected-files-messages"
          *ngIf="checksThatNoFilesWereSent()"
        >
          <div *ngIf="checkIfFilesIsUploadingAndNotHasFilesUploaded()">
            <mat-icon class="dir-dropzone-icon" svgIcon="upload"></mat-icon>
          </div>
          <div>
            <span>{{ "Arraste e solte ou " | translate }}</span>
            <span class="text-blue">
              {{ "busque pelo arquivo" | translate }}</span
            >
          </div>
          <span class="message_accept_formatted_file">
            <b>
              <abbr title="Portable Document Format">PDF</abbr>
              {{ "ou" | translate }}
              <abbr title="Document Office Open XML">DOCx</abbr>
            </b>
            {{ "Até $0 arquivos de $1Mb/cada" | translate : [10, 10] }}
          </span>
        </div>
        <span
          data-cy="add-documents-complementary-test-id"
          class="select-files-btn button-add-more-documents-complementary"
          *ngIf="successfulFiles.length > 0"
          matTooltip="{{ 'Adicionar documentos complementares' | translate }}"
        >
          <mat-icon class="icon-add-more-documents">add</mat-icon>
        </span>
        <input
          [attr.multiple]="checkIfAllowMultipleFilesToUpload()"
          id="files"
          type="file"
          name="files"
          ng2FileSelect
          [uploader]="uploader"
          accept=".doc, .docx, .pdf, .odt"
        />
      </label>
    </div>
    <div *ngIf="checkIfShowSecondStep()" class="second-step-wrapper">
      <label for="files">
        <zs-button
          *ngIf="!checkIfUploadButtonIsDisabled()"
          icon="upload"
          label="{{ 'Subir outro arquivo' | translate }}"
          typeButton="tertiary"
          iconDirection="left"
          (click)="triggerFileInputClick()"
        >
        </zs-button>
        <zs-button
          *ngIf="checkIfUploadButtonIsDisabled()"
          label="{{ 'Você atingiu o limite de arquivos' | translate }}"
          typeButton="tertiary"
          [disable]="true"
          (click)="triggerFileInputClick()"
        >
        </zs-button>
        <input
          #fileInput
          [attr.multiple]="checkIfAllowMultipleFilesToUpload()"
          id="files"
          type="file"
          name="files"
          ng2FileSelect
          [uploader]="uploader"
          accept=".doc, .docx, .pdf, .odt"
        />
      </label>
      <div
        class="list-files-successful"
        cdkDropList
        (cdkDropListDropped)="drop($event)"
        [class.cursor-grab]="!isDraggingSuccessfullFiles"
        [class.cursor-grabbing]="isDraggingSuccessfullFiles"
      >
      <app-files-dropzone-file-item
        *ngFor="let successfulFile of successfulFiles; let i = index"
        cdkDrag
        class="list-files-successful-item"
        [class.item-isGrabbing]="isDraggingSuccessfullFiles"
        [index]="i"
        [title]="successfulFile.name"
        (openEmitter)="goToFile(successfulFile.url)"
        (removeEmitter)="removeFile(i)"
        (cdkDragStarted)="onDragStarted($event)"
        (cdkDragEnded)="onDragEnded($event)"
      >
        </app-files-dropzone-file-item>
      </div>
    </div>
    <div *ngFor="let itemUploading of uploader.queue">
      <div *ngIf="itemUploading.progress <= 100" class="list-files-uploading">
        <app-file-dropzone-uploading
          class="list-files-uploading-item"
          [title]="itemUploading.file.name"
          [byteSize]="itemUploading.file.size"
          [progress]="itemUploading.progress"
        ></app-file-dropzone-uploading>
      </div>
    </div>
  </div>
</div>
