import {
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";

import { Language } from "src/translation/translation";
@Component({
  selector: "app-files-dropzone-file-item",
  templateUrl: "./files-dropzone-file-item.component.html",
  styleUrls: ["./files-dropzone-file-item.component.scss"],
})
export class FilesDropzoneFileItemComponent {
  lang: Language = (localStorage.getItem("lang") as Language) || "pt-br";
  dragIcon = 'drag'

  @Input() title: string;
  @Input() index: number;
  @Output() openEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Output() removeEmitter: EventEmitter<void> = new EventEmitter<void>();

  getIndex(): string {
    return String(this.index + 1);
  }

  onClickOpen() {
    this.openEmitter.emit()
  }

  onClickRemove() {
    this.removeEmitter.emit()
  }

  getTitle(): string {
    return this.title
  }

}
