import { Component, OnInit } from '@angular/core';
import {Language} from 'src/translation/translation';
import {TranslatorService} from 'src/app/components/translator.service';
import {Title} from '@angular/platform-browser';
import {Angulartics2GoogleTagManager} from 'angulartics2/gtm';
import {IconRegistryService} from './shared/services/icon-registry/icon-registry.service';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit{
  private lang: Language  = localStorage.getItem('lang') !== null ? localStorage.getItem('lang') as Language :
    (window.location.hostname !== 'app.zapsign.com.br' ?'es' : 'pt-br');
  isSandbox = environment.sandbox ? environment.sandbox : false;
  maintenanceMode = false;

  constructor(
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private titleService: Title,
    private translatorService: TranslatorService,
    private iconRegistryService: IconRegistryService,
  ) {
    this.startGoogleTagManager();
    this.setTitlePage();
    this.setMatIconSvg();
  }

  ngOnInit() {
    localStorage.setItem('lang', this.lang);
  }

  private setMatIconSvg() {
    const {icons} = this.iconRegistryService;

    for (const {iconPath, iconName} of icons) {
      this.iconRegistryService.addNewIcon(
        iconName,
        iconPath
      );
    }

  }

  private startGoogleTagManager() {
    this.angulartics2GoogleTagManager.startTracking();
  }

  private setTitlePage() {
    this.titleService.setTitle(
      this.translatorService.translate(
        'ZapSign | Assinatura eletrônica com validade jurídica',
        this.lang
      )
    );
  }

}


