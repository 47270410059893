<div class="container">
  <aside class="zs-header-doc-waiting">
    <h2 style="padding: 16px 16px 0;">
      <strong>{{'Documentos aguardando sua assinatura' | translate}}</strong>
    </h2>
    <mat-divider></mat-divider>
  </aside>
  <div class="zs-content-doc-waiting">
    <div role="list" *ngIf="doc.name">
      <div class="listitem">
        <span>1.&nbsp;{{ doc.name }}</span>
        <div class="zs-actions-doc-waiting">
          <div data-cy="view-pdf-original-document-waiting" style="cursor: pointer;" (click)="viewPDF(getPdf(doc))" matTooltip="{{'Visualizar' | translate}}: {{doc.name}}">
            <mat-icon>search</mat-icon>
            {{'Visualizar' | translate}}
          </div>
          <div matTooltip="{{'Baixar' | translate}}: {{doc.name}}">
            <a data-cy="download-pdf-original-document-waiting" *ngIf="!doc?.company?.hide_button_download_original && !doc.disable_signers_get_original_file" [href]="getPdf(doc)" target="_blank" mat-icon-button [style.color]="!doc || !doc.original_file ? '#eee':'#333'">
              <mat-icon style="cursor: pointer;">download</mat-icon>{{'Baixar' | translate}}
            </a>
          </div>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div role="list" *ngFor="let item of doc.extra_docs; let i = index; let lastItem = last" [attr.data-index]="i + 1">
      <div class="listitem" >
        <span>{{ i + 2 }}.&nbsp;{{ item.name }}</span>
        <div class="zs-actions-doc-waiting">
          <div data-cy="view-pdf-extra-document-waiting" (click)="viewPDF(getPdf(item))" style="cursor: pointer;" matTooltip="{{'Visualizar' | translate}}: {{item.name}}">
            <mat-icon>search</mat-icon>
            {{'Visualizar' | translate}}
          </div>
          <div>
            <a data-cy="download-pdf-extra-document-waiting" *ngIf="!doc.company.hide_button_download_original && !doc.disable_signers_get_original_file" [href]="getPdf(item)" target="_blank" matTooltip="{{'Baixar' | translate}}: {{item.name}}" mat-icon-button [style.color]="!item || !item.original_file ? '#eee':'#333'">
              <mat-icon style="cursor: pointer;">download</mat-icon>{{'Baixar' | translate}}
            </a>
          </div>
        </div>
      </div>
      <mat-divider *ngIf="!lastItem"></mat-divider>
    </div>
  </div>

  <aside style="padding: 16px;text-align: center;">
    <p>{{'Confirmo que visualizei todos documentos' | translate}}.</p>
    <button class="zs-text-uppercase" [style.backgroundColor]="color_branding" color="primary" style="color:#fff;" mat-raised-button (click)="signatureAll()">{{'ASSINAR' | translate}} {{ doc.extra_docs.length + 1 }} {{'documentos' | translate}}</button>
  </aside>
  <br>
</div>
