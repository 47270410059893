import { Component, Input, Output, EventEmitter } from '@angular/core';
import {PDFDocumentProxy, PDFProgressData} from 'ng2-pdf-viewer';
import { Language } from 'src/translation/translation';
import { TranslatorService } from '../translator.service';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent {

  @Input() lang:Language='pt-br';

  @Input() pdfSrcList:string[];
  @Input() pdfSrc:string;
  @Input() showTools:boolean = true;
  @Input() haveScroll:boolean = true;
  @Output() loadingDone = new EventEmitter<boolean>()
  @Output() pageRenderedEmitter = new EventEmitter<any[]>()
  pdf:any;
  originalSize:boolean = true;
  zoom:number = 1;
  page:number = 1;
  loadingPdf:boolean=true;

  constructor(
    private translator:TranslatorService
  ) { }


  t(text:string){
      return this.translator.translate(text,this.lang)
  }

  afterLoadComplete(pdf: PDFDocumentProxy) {
    this.pdf = pdf;
    this.loadingPdf = false;
    this.loadingDone.emit(true);
  }

  pageRendered(event: any) {
    this.pageRenderedEmitter.emit(event.pageNumber);
  }

  zoomIn() {
    this.zoom += +0.25;
  }

  zoomOut() {
    this.zoom = this.zoom - 0.25;
  }

  redefineZoom() {
    this.originalSize = true;
    this.zoom = 1;
  }

  get isSignerFlow() {
    return window.location.href.includes('verificar');
  }
}
