<div class="header">
  <img class="zs_logo" src="assets/img/LOGO-ZAPSIGN.svg" alt="Logo Zapsign">
</div>
<!--<app-user-navbar></app-user-navbar>-->
<div class="container_maintenance">
  <img class="image" src="assets/img/NoUpcomingTrips.svg" alt="Imagem estática de uma viagem bloqueada">
  <div class="child">
    <h1>Modo de Manutenção</h1>
    <p>Sistema indisponível das 7:00 AM to 8:00 AM (horário de Brasília) para atualizações.</p>
  </div>
  <div class="child">
    <h1>Modo de Mantenimiento</h1>
    <p>Sistema no disponible de 7:00 AM to 8:00 AM (hora de Brasilia) para actualizaciones.</p>
  </div>
  <div class="child">
    <h1>Maintenance Mode</h1>
    <p>System unavailable from 7:00 AM to 8:00 AM (Brasília time) for updates.</p>
  </div>
  <div class="child">
    <h1>Mode de Maintenance</h1>
    <p>Système indisponible de 7:00 AM to 8:00 AM (heure de Brasília) pour des mises à jour.</p>
  </div>
</div>

