import {createAction, props} from '@ngrx/store';
import {CreditsSubscriptions, PlanSubscriptions, SubscriptionProps} from '../settings-payment.model';

export const setPlans = createAction('[Set List Plans]', props<{ payload: PlanSubscriptions[]}>());
export const setPlansAPI = createAction('[Set List Plans API]', props<{ payload: PlanSubscriptions[]}>());
export const setPlansStripe = createAction('[Set List Plans STRIPE]', props<{ payload: PlanSubscriptions[]}>());
export const setPlansAddon = createAction('[Set List Plans ADDON]', props<{ payload: PlanSubscriptions[]}>());
export const setPlansAddonStrip = createAction('[Set List Plans ADDON STRIP]', props<{ payload: PlanSubscriptions[]}>());
export const setSelectPlan = createAction('[Set Select Plan]', props<{ payload: PlanSubscriptions}>());
export const setExtraSeats = createAction('[Set Extra Seats]', props<{ payload: number}>());
export const setCredits = createAction('[Set Credits]', props<{ payload: CreditsSubscriptions}>());
export const setAddonsSubscriptions = createAction('[Set Addons Subscriptions]', props<{ payload: SubscriptionProps[]}>());
export const addAddonSubscription = createAction('[Add Addon Subscription]', props<{ payload: SubscriptionProps}>());

