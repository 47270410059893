import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../app.state';
import {HideModalNoComputerCamera, ViewModalNoComputerCamera} from '../../../components/modal-camera/store/NoComputerCamera/no-computer-camera.actions';
import {HideModalDocumentFront, HideModalDocumentVerse, ViewModalDocumentFront, ViewModalDocumentVerse} from '../../../components/modal-camera/store/PhotoDocument/photo-document.action';
import {hideModalWebcam, viewModalWebcam} from '../../../components/modal-camera/store/PhotoSelfie/photo.actions';
import {hideModalLiveness, viewModalLiveness} from '../../../components/modal-liveness/store/LivenessValidation/liveness-validation.actions';


export type WhoIsCalled = 'no-cam' | 'modal-document-front' | 'modal-document-verse' | 'sign-page' | 'webcam' | 'step-personal-info' | 'modal-liveness' | '';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private store: Store<AppState>) {
  }

  openModalWebcam(whoIsCalled: WhoIsCalled): void {
    this.store.dispatch(viewModalWebcam({payload: whoIsCalled}));
  }

  closeModalWebcam(whoIsCalled: WhoIsCalled): void {
    this.store.dispatch(hideModalWebcam({payload: whoIsCalled}));
  }

  openModalNoCameraAvailable(whoIsCalled: WhoIsCalled): void {
    this.store.dispatch(new ViewModalNoComputerCamera(whoIsCalled));
  }

  closeModalNoCameraAvailable(whoIsCalled: WhoIsCalled): void {
    this.store.dispatch(new HideModalNoComputerCamera(whoIsCalled));
  }

  openModalDocumentFront(whoIsCalled: WhoIsCalled): void {
    this.store.dispatch(new ViewModalDocumentFront(whoIsCalled));
  }

  openModalDocumentVerse(whoIsCalled: WhoIsCalled): void {
    this.store.dispatch(new ViewModalDocumentVerse(whoIsCalled));
  }

  closeModalDocumentFront(whoIsCalled: WhoIsCalled): void {
    this.store.dispatch(new HideModalDocumentFront(whoIsCalled));
  }

  closeModalDocumentVerse(whoIsCalled: WhoIsCalled): void {
    this.store.dispatch(new HideModalDocumentVerse(whoIsCalled));
  }

  openModalLiveness(whoIsCalled: WhoIsCalled): void {
    this.store.dispatch(viewModalLiveness({payload: whoIsCalled}));
  }

  closeModalLiveness(whoIsCalled: WhoIsCalled): void {
    this.store.dispatch(hideModalLiveness({payload: whoIsCalled}));
  }
}
