import {Action, createReducer, on} from '@ngrx/store';
import * as actions from './set-loading-signed-file-redux.actions';
import {SetLoadingSignedFileReduxProps} from './set-loading-signed-file-redux.models';

export const INITIAL_STATE: SetLoadingSignedFileReduxProps = {
  loadingSignedFile: true
};


const _setLoadingSignedFileReducer = createReducer(
  INITIAL_STATE,
  on(
    actions.setLoadingSignedFileAction, (state, {payload}) => {
      return {
        ...state,
        loadingSignedFile: payload
      };
    }
  )
);

export function setLoadingSignedFileReducer(state: SetLoadingSignedFileReduxProps, action: Action) {
  return _setLoadingSignedFileReducer(state, action);
}
