import {Action} from '@ngrx/store';
import {SignPageProps} from './signpage.model';

export enum ActionTypes {
  GET_USER_ACTION = 'GET_USER_ACTION'
}
export class GetUserAgent implements Action {
  readonly type = ActionTypes.GET_USER_ACTION;
  constructor(public payload: SignPageProps) {}
}

export type Actions = GetUserAgent;
