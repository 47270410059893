import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {Language, Locale} from 'src/translation/translation';
import {AppState} from './app.state';
import {TranslatorService} from './components/translator.service';
import {FormControl, ValidationErrors} from '@angular/forms';
import {WarningService} from 'src/app/components/warning.service';

export interface SlugTextType {
  slug: string;
  text: string;
  icon?: string;
  isSelected?: boolean;
  isDisabled?: boolean;
  tooltip?: string;
}

@Injectable({
  providedIn: 'root'
})
export class HelpersGlobalService implements OnInit, OnDestroy {
  lang: Language = localStorage.getItem('lang') as Language || 'pt-br';
  locale: Locale = localStorage.getItem('locale') as Locale || 'br';
  translationSubscription: any;
  datePattern = /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
  emailPattern = /^[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$/;

  constructor(
    private _translationService: TranslatorService,
    private warningService: WarningService,
    private store: Store<AppState>
  ) {
  }

  ngOnInit(): void {
    this.translationSubscription = this.store.select('translation').subscribe(data => this.locale = data.locale);
  }

  ngOnDestroy(): void {
    this.translationSubscription.unsubscribe();
  }

  t(pt_text: string, replacements: string[] = []) {
    return this._translationService.translate(pt_text, this.lang, replacements);
  }

  getSignatureTypes(): SlugTextType[] {
    let types = [
      {slug: 'assinaturaTela', text: this.t('Assinatura na tela'), icon: 'icon_subscription', isSelected: false},
      {slug: 'tokenEmail', text: this.t('Código por e-mail'), icon: 'icon_subscription', isSelected: false},
      {slug: 'tokenSms', text: this.t('Código por SMS'), icon: 'icon_subscription', isSelected: false},
      {slug: 'tokenWhatsapp', text: this.t('Código por WhatsApp'), icon: 'icon_subscription', isSelected: false},
      {slug: 'assinaturaTela-tokenEmail', text: this.t('Assinatura + E-mail'), icon: 'icon_subscription', isSelected: false},
      {slug: 'assinaturaTela-tokenSms', text: this.t('Assinatura + SMS'), icon: 'icon_subscription', isSelected: false},
      {slug: 'assinaturaTela-tokenWhatsapp', text: this.t('Assinatura + Whatsapp'), icon: 'icon_subscription', isSelected: false},
    ];
    if (this.locale === 'br') {
      types = types.concat([{slug: 'certificadoDigital', text: this.t('Certificado digital'), icon: 'icon_subscription', isSelected: false}]);
    }
    if (this.lang !== 'pt-br') {
      // remove whatsapp option in Latam. TODO: create whatsapp templates in spanish and english
      types = types.filter(type => type.slug.indexOf('Whatsapp') === -1);
    }
    return types;
  }


  getQualifications(): SlugTextType[] {
    return [
      {slug: '', text: this.t('Assinar')},
      {slug: 'testemunha', text: this.t('Assinar como testemunha')},
      {slug: 'aprovar', text: this.t('Assinar para aprovar')},
      {slug: 'acusarRecebimento', text: this.t('Assinar para acusar recebimento')},
    ];
  }

  isCpfValid(cpf: string): boolean {
    let sum: number;
    let rest: number;
    sum = 0;
    if (cpf == '00000000000' || cpf == '11111111111') {
      return false;
    }

    for (let i = 1; i <= 9; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }
    rest = (sum * 10) % 11;

    if ((rest == 10) || (rest == 11)) {
      rest = 0;
    }
    if (rest != parseInt(cpf.substring(9, 10))) {
      return false;
    }

    sum = 0;
    for (let i = 1; i <= 10; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }
    rest = (sum * 10) % 11;

    if ((rest == 10) || (rest == 11)) {
      rest = 0;
    }
    return rest == parseInt(cpf.substring(10, 11));

  }

  isRgValid(rg: string) {
    return rg.length > 6 || rg.length === 0;
  }

  isDateValid(date: string): boolean {
    const re = this.datePattern;
    if (!re.test(date)) {
      return false;
    }
    const [day, month, year] = date.split('/').map(Number);

    if (!this.isMonthValid(month)) {
      return false;
    }

    return this.isDateValidWithParsedDate(day, month, year);
  }

  isDateValidWithParsedDate(day: number, month: number, year: number): boolean {
    const parsedDate = new Date(year, month - 1, day);
    if (
      parsedDate.getDate() !== day ||
      parsedDate.getMonth() !== month - 1 ||
      parsedDate.getFullYear() !== year
    ) {
      return false;
    }
    return true;
  }

  isMonthValid(month: number): boolean {
    return month >= 1 && month <= 12;
  }

  isEmailValid(email: string) {
    return this.emailPattern.test(email);
  }

  getLocaleAndLang() {
    const locale = this.getLocale();
    const lang = localStorage.getItem('lang') || 'pt-br';
    return {locale, lang};
  }

  private getLocale() {
    return window.location.host.includes('br') ? 'br' : 'global';
  }

  isFirstNameValid(firstName: string) {
    if (!firstName || firstName.length < 1) {
      return false;
    }
    const regexFirstName = /^(?=.{3,30}$)[a-zA-Z]+(?:\s[a-zA-Z]+)*$/;
    return regexFirstName.test(firstName);
  }

  isLastNameValid(lastName: string) {
    if (!lastName || lastName.length < 1) {
      return false;
    }
    const regexLastName = /^[a-zA-Z]{3,30}$/;
    return regexLastName.test(lastName);
  }

  isFullNameValid(fullName: string) {
    if (!fullName || fullName.length < 1) {
      return false;
    }

    const nameParts = fullName.trim().split(/\s+/);
    if (nameParts.length < 2) {
      return false;
    }

    const regexNamePart = /^[a-zA-ZÀ-ÖØ-öø-ÿ]+(?:[-' ][a-zA-ZÀ-ÖØ-öø-ÿ]+)*$/;
    const regexOneLetterPart = /^[a-zA-Z]$/;

    let validParts = 0;

    for (const part of nameParts) {
      if (regexNamePart.test(part) || regexOneLetterPart.test(part)) {
        validParts++;
      }
    }

    return validParts >= 2;
  }


}
