import { Component, Input } from '@angular/core';
import { Signers, SignersDropdownService, Status } from '../signers-dropdown.service';

type OptionsColors = '#40BF4B' | '#999999';

@Component({
  selector: 'app-signers-dropdown',
  templateUrl: './signers-dropdown.component.html',
  styleUrls: ['./signers-dropdown.component.scss']
})
export class SignersDropdownComponent {
  @Input() signers: Signers[];

  constructor(private signersDropdownService: SignersDropdownService) {}

  getColorBullet(status: Status): Readonly<OptionsColors> {
    return this.signersDropdownService.getColorBullet(status);
  }

  getSignerStatus(signer: Signers): string {
    return this.signersDropdownService.getSignerStatus(signer);
  }

  getCountSigners(): string {
    return this.signersDropdownService.getCountSigners(this.signers);
  }
}
