<div class="user-group-form" *ngIf="!loading; else spinner">
  <div class="user-group-title">
    <h2 *ngIf="state === 'new'">{{"Novo grupo de usuários" | translate}}</h2>
    <h2 *ngIf="state === 'update'">{{"Editar grupo de usuários" | translate}}</h2>
  </div>
  <div class="user-group-name">
    <mat-form-field class="example-full-width user-group-name-input" appearance="fill">
      <mat-label>{{"Nome do grupo" | translate}}</mat-label>
      <input matInput placeholder="{{'Nome do grupo'| translate}}" required="true" [(ngModel)]="userGroup.name">
    </mat-form-field>
  </div>
  <div class="user-group-permitions" id="new-user-grup">
    <div class="content-grup-permissions">
      <p>{{"Permissões de acesso aos documentos" | translate}}</p>
      <mat-radio-group [(ngModel)]="userGroup.doc_permissions" class="user-group-checkbox">
        <mat-radio-button
          value="your_documents"
          matTooltip="{{'Os usuários visualizam apenas os documentos que eles mesmos criaram' | translate}}">
          <span class="text">
            {{"Próprios documentos" | translate}}
          </span>
        </mat-radio-button>
        <mat-radio-button
          value="group_documents"
          matTooltip="{{'Os usuários visualizam documentos criados por todos os integrantes do grupo' | translate}}">
          <span class="text">
            {{"Documentos do grupo" | translate}}
          </span>
        </mat-radio-button>
        <mat-radio-button
          value="all_docmuments"
          matTooltip="{{'Os usuários visualizam todos os documentos da organização' | translate}}">
          <span class="text">
            {{"Todos os documentos" | translate}}
          </span>
        </mat-radio-button>
      </mat-radio-group>
      <p>{{"Permissões para exclusão de documentos" | translate}}</p>
      <mat-radio-group [(ngModel)]="userGroup.doc_exclude_permissions" class="user-group-checkbox">
        <mat-radio-button
          value="your_documents"
          matTooltip="{{'Os usuários podem excluir apenas os documentos que eles mesmos criaram.' | translate}}">
          <span class="text">
            {{"Excluir documentos próprios" | translate}}
          </span>
        </mat-radio-button>
        <mat-radio-button
          value="group_documents"
          matTooltip="{{'Os usuários podem excluir documentos criados por todos os integrantes do grupo.' | translate}}">
          <span class="text">
            {{"Excluir documentos do grupo" | translate}}
          </span>
        </mat-radio-button>
        <mat-radio-button
          value="all_docmuments"
          matTooltip="{{'Os usuários podem excluir todos os documentos da organização.' | translate}}">
          <span class="text">
            {{"Excluir documentos da organização" | translate}}
          </span>
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="user-group-permitions" style="margin-top: 30px;">
    <p>{{"Permissões de acesso gerais" | translate}}</p>
    <div class="user-group-checkbox">
      <mat-checkbox [(ngModel)]="userGroup.generals_access.models_and_forms">{{"Modelos e Forms" | translate}}</mat-checkbox>
      <mat-checkbox [(ngModel)]="userGroup.generals_access.contacts">{{"Contatos" | translate}}</mat-checkbox>
      <mat-checkbox [(ngModel)]="userGroup.generals_access.settings">{{"Configurações" | translate}}</mat-checkbox>
      <mat-checkbox [(ngModel)]="userGroup.generals_access.reports">{{"Relatórios" | translate}}</mat-checkbox>
      <mat-checkbox
        [ngClass]="locale === 'br' ? 'antecedentes-br' : 'antecedentes-latam'"
        [(ngModel)]="userGroup.generals_access.background">{{"Antecedentes" | translate}}
      </mat-checkbox>
      <mat-checkbox [(ngModel)]="userGroup.generals_access.send_documents">{{"Enviar documento" | translate}}</mat-checkbox>
      <mat-checkbox [(ngModel)]="userGroup.generals_access.batch_signature"  [disabled]="!canBatchSignature" [matTooltip]="!canBatchSignature ? t('Essa funcionalidade não está disponível na sua conta. Você pode contratá-la como um plano adicional') : null">{{"Assinatura em lote" | translate}}</mat-checkbox>
      <mat-checkbox [(ngModel)]="userGroup.generals_access.trash">{{"Lixeira" | translate}}</mat-checkbox>
    </div>
  </div>
  <ng-multiselect-dropdown
    [placeholder]="textPlaceholder()"
    [settings]="dropdownSettings"
    [data]="dropdownList"
    [(ngModel)]="selectedItems"
    enableCheckAll="false"
  >
  </ng-multiselect-dropdown>
  <app-old-modal-user-groups-buttons
      [state]="state"
      [buttonDisabled]="userGroup.name === ''"
      (changeState)="createUserGroup($event)">
  </app-old-modal-user-groups-buttons>

</div>
<ng-template #spinner>
  <app-spinner-loading></app-spinner-loading>
</ng-template>
