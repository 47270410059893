import {AfterContentInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {SignerUtilsService} from 'src/app/signers/signer-utils.service';
import {User} from '../../helpers/model/user';
import {AssetsService} from '../../shared/services/assets.service';
import {LaunchDarkly} from '../../shared/singleton/launch-darkly.singleton';


@Component({
  selector: 'app-user-navbar',
  templateUrl: './user-navbar.component.html',
  styleUrls: ['./user-navbar.component.scss']
})
export class UserNavbarComponent implements AfterContentInit {
  @Output() mobileMenuEvent = new EventEmitter<any>();
  @Input() showUnpaidBanner;

  zcre113modalInadimplente ;
  user: User;

  constructor(
    private signerUtils: SignerUtilsService,
    public assetsService: AssetsService
  ) {
    const client = LaunchDarkly.getClient();
    client.on('ready', () => {
      this.zcre113modalInadimplente = client.variation('zcre-113-alterar-o-modal-inadimplente', false);
    });

  }

  ngAfterContentInit(): void {
    this.signerUtils.userAgent();
  }

  showMenuMobile() {
    this.mobileMenuEvent.emit();
  }
}
