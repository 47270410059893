import { Action } from '@ngrx/store';


export enum ActionTypesRequestModal {
  VIEW_MODAL = 'VIEW_MODAL',
  HIDE_MODAL = 'HIDE_MODAL'
}
export class ViewModalRequestCamera implements Action {
  readonly type = ActionTypesRequestModal.VIEW_MODAL;
}
export class HideModalRequestCamera implements Action {
  readonly type = ActionTypesRequestModal.HIDE_MODAL;
}

export type ActionsRequestModal = ViewModalRequestCamera | HideModalRequestCamera;
