import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {concat, Observable, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {UpdateCompanyProps} from '../client/settings-organization/settings-organization.model';
import {SustainabilityProps} from '@client/settings-organization/old-sustainability/old-sustainability.component';
import {HelpersGlobalService} from '../helpers-global.service';
import {User} from '../helpers/model/user';
import {AuthService} from './auth.service';
import {SlugSustainabilityInfos, UpdateMyUserProps} from './backend.service.model';
import {Signer} from '../helpers/model/docs';
import { UserGroup } from '../components/old-modal-user-groups/old-store/old-modal-user-groups.models';



@Injectable({
  providedIn: 'root'
})
export class AuthBackendService {

  api_url: string = environment.API_URL;
  auth_api_url: string = this.api_url + '/auth';
  users_api_url: string = this.api_url + '/user';

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private helpersGlobalService: HelpersGlobalService,
  ) {
  }

  getMe() {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.get<any>(`${this.users_api_url}/me/` ).pipe(
        tap((user: User) => {
          if (user) {
            this.authService.afterGetMeAction(user);
          }
        })
      )
    );
  }

  disableCouponModal() {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(this.users_api_url + '/disable-coupon-modal/', {})
        .pipe(catchError(this.handleError))
    );
  }

  getMyCreditsBalance() {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.get<any>(this.users_api_url + '/me/credits-balance/')
        .pipe(catchError(this.handleError))
    );
  }

  getMySignature() {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.get<any>(this.users_api_url + '/me/signature/')
    );
  }

  updateMyUser(params: UpdateMyUserProps) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(this.users_api_url + '/me/update/', params)
    );
  }

  getMyMemberships() {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.get<any>(this.users_api_url + '/me/memberships/')
    );
  }

  updateNotificationPreference(company_id: number, new_preference: string) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(this.users_api_url + '/memberships/update-notification-preference/', {company_id, new_preference})
    );
  }

  getUser(): Observable<any> {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.get<any>(this.auth_api_url + '/user/').pipe(catchError(this.handleError))
    );
  }

  updateCompany(company_id: number, params: UpdateCompanyProps) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(this.users_api_url + '/company/' + company_id + '/update/', params)
    );
  }

  changeMyCompany(company_id: number) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(this.users_api_url + '/me/change_company/' + company_id + '/', {})
    );
  }

  getMyCompany(company_id: number) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.get<any>(this.users_api_url + '/company/' + company_id + '/', {})
    );
  }

  getCompanyMemberships(company_id: number) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.get<any>(this.users_api_url + '/company/' + company_id + '/memberships/')
    );
  }

  getCompanyMembershipsPagination(company_id: number, page: number = 1) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.get<any>(`${this.users_api_url}/company/${company_id}/memberships-pagination/?page=${page}`)
    );
  }

  addMembershipToCompany(company_id: number, email: string, role: string = 'admin') {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(this.users_api_url + '/company/' + company_id + '/memberships/add/', {email, role})
    );
  }

  deleteMembership(membership_id: number) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.delete<any>(`${this.users_api_url}/memberships/${membership_id}/delete/`)
    );
  }

  updateMembershipRole(membership_id: number, user_group_id: number) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(`${this.users_api_url}/memberships/${membership_id}/update-role/`, {user_group_id})
    );
  }

  updateCompanyOwner(membership_id: number) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(this.users_api_url + '/memberships/update-owner/', {membership_id})
    );
  }

  generateApiToken(company_id: number): Observable<any> {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(this.auth_api_url + '/company/' + company_id + '/generate-api-token/', {}).pipe(catchError(this.handleError))
    );
  }

  deleteApiToken(company_id: number): Observable<any> {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.delete<any>(this.auth_api_url + '/company/' + company_id + '/delete-api-token/', {}).pipe(catchError(this.handleError))
    );
  }

  createWebhook(params: any): Observable<any> {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(this.users_api_url + '/company/webhook/', params).pipe(catchError(this.handleError))
    );
  }

  getWebhooks(page: number, onlyFails: 0 | 1 = 0): Observable<any> {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.get<any>(`${this.users_api_url}/company/webhook/log/?page=${page}&only-fails=${onlyFails}`, {}).pipe(catchError(this.handleError))
    );
  }

  deleteWebhook(webhookId: number): Observable<any> {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.delete<any>(this.users_api_url + '/company/webhook/' + webhookId + '/delete/', {}).pipe(catchError(this.handleError))
    );
  }

  createUserWithEmail(email: string, password: string, params: any = {}): Observable<any> {
    const {locale, lang} = this.helpersGlobalService.getLocaleAndLang();
    return concat(
      this.authService.getV3Captcha(),
      this.http.post<any>(this.auth_api_url + '/create-account-email/', {email, password, locale, lang, ...params})
    );
  }

  sendEmailVerificationToken(emailProvider: string = 'aws'): Observable<any> {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(
        this.auth_api_url + '/user/send-email-verification-token/',
        {email_provider: emailProvider}
      ).pipe(catchError(this.handleError))
    );
  }

  checkEmailVerificationToken(codigo: string, email: string = ''): Observable<any> {
    const body = {codigo, email};
    const userHaveAccount = email != '';

    if (userHaveAccount) {
      return this.http.post<any>(this.auth_api_url + '/user/check-email-verification-token/any/', body).pipe(catchError(this.handleError));
    }

    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(this.auth_api_url + '/user/check-email-verification-token/', body).pipe(catchError(this.handleError))
    );
  }

  authorizeCompanyRequest(email: string, company: string): Observable<any> {
    const body = {email, company};
    const userHaveAccount = email != '';

    if (userHaveAccount) {
      return this.http.post<any>(this.auth_api_url + '/autorizar-company/', body).pipe(catchError(this.handleError));
    }
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
    );
  }

  sealSustentability(slug_sustainability: string, company_id: number): Observable<any> {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<SustainabilityProps>(`${this.users_api_url}/company/${company_id}/set/sustainability/slug/`,
        {slug_sustainability}
      ).pipe(catchError(this.handleError))
    );
  }

  setStatusPageSustainability(sustainability_page_active: boolean, company_id: number) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<SustainabilityProps>(`${this.users_api_url}/company/${company_id}/set/sustainability/page/`,
        {sustainability_page_active}
      ).pipe(catchError(this.handleError))
    );
  }

  sealPageSustainability(slug_sustainability: string): Observable<any> {
    return this.http.get<SlugSustainabilityInfos>(`${this.users_api_url}/company/${slug_sustainability}/sustainability/`);
  }

  validateEmailExistsZapsign(email: string): Observable<string> {
    return this.http.post<any>(`${this.auth_api_url}/validate-email-exists-zapsign/`, {email})
      .pipe(
        map(({ message }: { message: string }) => message),
        catchError(this.handleError)
      );
  }

  createSignerToAssociate(email: string, signer: Signer): Observable<string> {
    return this.http.post<any>(`${this.auth_api_url}/signer-to-associate/`, {email, signer: signer.token})
      .pipe(
        map(({ message }: { message: string }) => message),
        catchError(this.handleError)
      );
  }

  getCompanysUserGroups() {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.get<any>(`${this.users_api_url}/company/user-groups/`)
    );
  }

  getCompanysUserGroupsOptions() {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.get<any>(`${this.users_api_url}/company/user-groups-options/`)
    );
  }

  createUserGroup(userGroup: UserGroup) {
    return this.http.post<any>(`${this.users_api_url}/company/create-user-group/`, {userGroup})
    .pipe(
        catchError(this.handleError)
      );
  }

  updateUserGroup(userGroup: UserGroup) {
    return this.http.put<any>(`${this.users_api_url}/company/update-user-group/${userGroup.id}/`, {userGroup})
    .pipe(
        map(({ message }: { message: string }) => message),
        catchError(this.handleError)
      );
  }

  removeUserGroup(userGroupId: number) {
    return this.http.delete<any>(`${this.users_api_url}/company/delete-user-group/${userGroupId}/`)
    .pipe(
        map(({ message }: { message: string }) => message),
        catchError(this.handleError)
      );
  }

  removeSealSustainability(slug_sustainability: string) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.delete(`${this.users_api_url}/company/${slug_sustainability}/remove-sustainability-slug/`)
        .pipe(
          catchError(this.handleError)
        )
    );
  }

  testCaptcha() {
    return concat(
      this.authService.getV3Captcha(),
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(this.auth_api_url + '/test_captcha/', {})
    );
    // TODO: FALLBACK DO V2
    // .pipe(
    //   catchError(()=>
    //     concat(
    //       this.getV2Captcha(),
    //       this.http.post<any>(this.auth_api_url+"/test_captcha/",{})
    //     )
    //   )
    // )

  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`
      );
    }
    return throwError(
      error.error
    );
  }

  getEmailsUserGroup(userGroupId: number) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.get<any>(this.users_api_url + `/company/get-emails-user-groups/${userGroupId}/`))
  }

  getEmailsMemberships() {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.get<any>(`${this.users_api_url}/company/memberships/emails/`));
  }

  removeMemberFromUserGroup(userGroupId: number, email: string) {
    return this.http.delete<any>(`${this.users_api_url}/company/delete-member-from-user-group/${userGroupId}/${email}/`)
    .pipe(
        map(({ message }: { message: string }) => message),
        catchError(this.handleError)
      );
  }

  getUserGroupPermissionForThisPage(pageAccess: string) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.get<any>(this.users_api_url + '/company/user-group-permission/?page_access=' + pageAccess)
    );
  }

  setUserAlreadySeenUserGroupPopup(membershipId: number) {
    return this.http.put<any>(`${this.users_api_url}/memberships/${membershipId}/user-already-seen-popup/`, {})
      .pipe(
        map(({ message }: { message: string }) => message),
        catchError(this.handleError)
      );
  }
}
