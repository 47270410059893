import { Directive, HostListener, OnInit, Output, EventEmitter, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { setLoadingModalActivateAccount } from 'src/app/components/modal-activate-account/store/modal-activate-account.action';

@Directive({
  selector: '[appDebounceClick]'
})
export class DebounceClickDirective implements OnInit, OnDestroy {
  @Input() debounceTime = 3000;
  @Output() debounceClick = new EventEmitter();

  clicks = new Subject();
  subscription: Subscription;


  constructor(
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.subscription = this.clicks.pipe(debounceTime(this.debounceTime)).subscribe(
      event => this.debounceClick.emit(event)
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('click', ['$event'])
  clickEvent(event: PointerEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.store.dispatch(setLoadingModalActivateAccount({ payload: true }));
    this.clicks.next(event);

  }
}
