import { Component } from '@angular/core';

@Component({
  selector: 'app-ready-to-use-applications',
  templateUrl: './ready-to-use-applications.component.html',
  styleUrls: ['./ready-to-use-applications.component.scss']
})
export class ReadyToUseApplicationsComponent {

}
