import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatChipInputEvent} from '@angular/material/chips';
import {FormControl, FormGroup} from '@angular/forms';
import {FormGroupValidatorsService} from '../../shared/services/FormGroupValidators/form-group-validators.service';

@Component({
  selector: 'app-observers-input',
  templateUrl: './observers-input.component.html',
  styleUrls: ['./observers-input.component.scss']
})
export class ObserversInputComponent implements OnInit {
  @Output() observersEmailsEmitter = new EventEmitter<string[][]>();
  @Input() observersEmailsArray: string[][] = [[], []];
  observersEmails: string[][] = [[], []];
  formGroup = new FormGroup({
    email: new FormControl('', [this.formGroupValidatorsService.emailValidatorNotRequired()])}
  );
  switchStepsLabel = ['Apenas alerta de conclusão', 'À cada assinatura e conclusão'];
  typeOfObserver = 0;


  constructor(private formGroupValidatorsService: FormGroupValidatorsService) {
  }

  ngOnInit(): void {
    let index = 0;
    this.observersEmailsArray.forEach((emails) => {
      if (emails.length > 0) {
        this.observersEmails[index] = emails;
      }
      index++;
    });
  }

  sendObserverForm() {
    this.observersEmailsEmitter.emit(this.observersEmails);
  }

  remove(email: string, typeOfObserver: number) {
    this.observersEmails[typeOfObserver].forEach((item, index) => {
      if (item === email) {
        this.observersEmails[typeOfObserver].splice(index, 1);
      }
    });
    this.sendObserverForm();
  }

  add(event: MatChipInputEvent): void {
    const email = event.value.trim();

    if (email !== '') {
      this.observersEmails[this.typeOfObserver].push(email);
    }

    event.input.value = '';
  }

  addEmail() {
    if (!this.getButtonDisabled) {
      this.observersEmails[this.typeOfObserver].push(this.formGroup.get('email').value);
      this.formGroup.get('email').setValue('');
      this.sendObserverForm();
    }
  }

  get getButtonDisabled() {
    return this.formGroup.get('email').value.length === 0 || this.formGroup.get('email').getError('email') !== null;
  }

  changeSwitch($event: number) {
    this.typeOfObserver = $event;
  }
}
