import {Injectable} from '@angular/core';
import {Membership} from '../../../client/old-membership-item/old-membership-item.component';
import {AuthBackendService} from '../../../auth/backend.service';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserIsOwnerValidationService {

  constructor(public authBackendService: AuthBackendService) { }

  getIsOwner() {
    return new Observable(observer => {
      const isOwnerLocalStorage = localStorage.getItem('is_owner');
      if (isOwnerLocalStorage) {
        observer.next(isOwnerLocalStorage === 'true');
        observer.complete();
      } else {
        const takeUserEmailLocalStorage = localStorage.getItem('user_email');
        const takeCompanyIdLocalStorage = localStorage.getItem('companyId');
        this.authBackendService.getCompanyMemberships(Number(takeCompanyIdLocalStorage)).subscribe(
          (membershipList: Membership[]) => {
            const filterMembershipByEmail = membershipList.filter(membership => membership.user.email === takeUserEmailLocalStorage && membership.role === 'owner');
            const userIsOwner = filterMembershipByEmail.length > 0;
            localStorage.setItem('is_owner', userIsOwner ? 'true' : 'false');
            observer.next(userIsOwner);
            observer.complete();
          },
          err => console.error(err)
        );
      }
    });
  }

  setOwner(company: any) {
    const takeUserEmailLocalStorage = localStorage.getItem('user_email');
    const filterMembershipByEmail = company.memberships.filter(membership => {
      return membership.user.email === takeUserEmailLocalStorage && membership.role === 'owner';
    });
    const isOwner = filterMembershipByEmail.length > 0;
    localStorage.setItem('is_owner', isOwner ? 'true' : 'false');
  }
}
