import {Action, createReducer, on} from '@ngrx/store';
import {clearDocsCounter, setDocsCounter} from './docs-counter.actions';
import {DocsCounterProps} from './docs-counter.model';

export const INITIAL_STATE: DocsCounterProps = {
  active: false,
  allDocsCount: 0,
  signedDocsCount: 0,
  refusedDocsCount: 0,
  ongoingDocsCount: 0,
  mySignaturesCount: 0,
  current_status_filter: 'all',
};

const _docsCounterReducer = createReducer(
  INITIAL_STATE,
  on(setDocsCounter, (state, params: DocsCounterProps) => {
    return {...state, ...params};
  }),
  on(clearDocsCounter, () => INITIAL_STATE),
);

export function docsCounterReducer(state: DocsCounterProps, action: Action) {
  return _docsCounterReducer(state, action);
}
