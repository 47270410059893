import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../app.state';
import {
  PlanSubscriptions,
  CreditsSubscriptions,
  SubscriptionProps
} from '../../../../client/settings-payment/settings-payment.model';
import { useSelectorPlans } from '../../../../app.state.selectors';
import {
  setPlans,
  setPlansAPI,
  setSelectPlan,
  setExtraSeats,
  setCredits,
  setPlansStripe,
  setPlansAddon,
  setPlansAddonStrip,
  setAddonsSubscriptions, addAddonSubscription
} from '../../../../client/settings-payment/store/plans.actions';

@Injectable({
  providedIn: 'root'
})
export class PlansReduxService {
  constructor(private store: Store<AppState>) {}

  setListPlansBr(listPlans: PlanSubscriptions[]) {
    this.store.dispatch(setPlans({ payload: listPlans }));

    return this.getListPlans();
  }

  setListPlansAPI(apiPlans: PlanSubscriptions[]) {
    this.store.dispatch(setPlansAPI({ payload: apiPlans }));
  }

  setListPlansStripe(listPlansStripe: PlanSubscriptions[]) {
    this.store.dispatch(setPlansStripe({ payload: listPlansStripe }));
  }

  setListPlansAddon(listPlansAddon: PlanSubscriptions[]) {
    this.store.dispatch(setPlansAddon({ payload: listPlansAddon }));
  }

  setListPlansAddonStripe(listPlansAddonStripe: PlanSubscriptions[]) {
    this.store.dispatch(setPlansAddonStrip({ payload: listPlansAddonStripe }));
  }

  setSelectPlan(selectPlan: PlanSubscriptions) {
    this.store.dispatch(setSelectPlan({ payload: selectPlan }));
  }

  setExtraSeats(extraSeats: number) {
    this.store.dispatch(setExtraSeats({ payload: extraSeats }));
  }
  setCredits(credits: CreditsSubscriptions) {
    this.store.dispatch(setCredits({ payload: credits }));
  }
  setAddonSubscriptions(sub: SubscriptionProps[]) {
    this.store.dispatch(setAddonsSubscriptions({ payload: sub }));
  }
  addAddonSubscription(sub: SubscriptionProps) {
    this.store.dispatch(addAddonSubscription({ payload: sub }));
  }

    getListPlans() {
    return this.store.select(useSelectorPlans);
  }
}
