<div class="phone-input" [formGroup]="phoneNumberFormGroup">
  <zs-text-input
    #ddi
    class="ddi"
    width="66px"
    placeholder="+55"
    maskInput="+099"
    type="tel"
    (focusin)="focusBothInputs()"
    (focusout)="focusOut()"
    formControlName="country"
    [dropSpecialCharacters]="true"
    [disabled]="disabled"

  ></zs-text-input>
  <input type="hidden" formControlName="ddd">
  <input type="hidden" formControlName="number">
  <zs-text-input
    #phoneNumber
    class="phone-number"
    type="tel"
    [label]="label"
    (focusin)="focusBothInputs()"
    (focusout)="focusOut()"
    [maskInput]="this.phoneNumberFormGroup.get('country').value === '55' ? '(00) 00000-0009' : '999999999999999'"
    [dropSpecialCharacters]="true"
    [disabled]="disabled"
    formControlName="numberWithDDD"
  >
  </zs-text-input>
</div>
