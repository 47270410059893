import {Component, Input, OnInit} from '@angular/core';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {Store} from '@ngrx/store';
import {Language} from '@translation/translation';
import {AppState} from '../../app.state';
import {changeDocsFilter} from '@client/page-my-documents/store/change-docs-filter/change-docs-filter.action';
import {User} from '@helpers/model/user';
import {ActionsTrash} from '@shared/services/trash-documents/trash-documents';
import {TrashDocumentsService} from '@shared/services/trash-documents/trash-documents.service';
import {DocBackendService} from '../doc.backend.service';
import {TranslatorService} from '../translator.service';
import {WarningService} from '../warning.service';
import {UserIsOwnerValidationService} from "@shared/services/user-is-owner-validation/user-is-owner-validation.service";

@Component({
  selector: 'app-page-document-trash',
  templateUrl: './page-document-trash.component.html',
  styleUrls: ['./page-document-trash.component.scss']
})
export class PageDocumentTrashComponent implements OnInit {
  docs = [];
  private readonly lang = localStorage.getItem('lang') as Language || 'pt-br';
  checkboxSelectDeselectAll = false;
  allDocsSelected = 0;
  loadingDocumentsInTrash = false;
  skeletonList = new Array(3);
  roleMembershipIsOwner: boolean;
  @Input() user: User;

  constructor(
    private docBackendService: DocBackendService,
    private warningService: WarningService,
    private translatorService: TranslatorService,
    private trashDocumentsService: TrashDocumentsService,
    private store: Store<AppState>,
    private userIsOwner: UserIsOwnerValidationService,
  ) {}

  ngOnInit(): void {
    this.getDeletedDocuments();

    this.userIsOwner.getIsOwner().subscribe((isOwner: boolean) => {
      this.roleMembershipIsOwner = isOwner;
    });
  }

  private getDeletedDocuments() {
    this.loadingDocumentsInTrash = true;
    this.docBackendService.getDeletedDocuments().subscribe(
      data => this.docs = data,
      () => {
        this.warningService.toastrError(this.t('Você não possui permissão para ver os documentos excluídos.'));
        this.store.dispatch(changeDocsFilter({status_filter: 'all'}));
      },
      () => this.loadingDocumentsInTrash = false
    );
  }

  t(ptText: string, replacements?: string[]) {
    return this.translatorService.translate(ptText, this.lang, replacements);
  }

  private getQuantityDocsSelected() {
    this.allDocsSelected = this.docs.filter(doc => doc.selected).length;
  }

  private getTokensDocsSelected(): string[] {
    return this.docs.filter(doc => doc.selected).map(({token}) => token);
  }


  private updateMultiDeletedDocuments(tokens: string[]) {
    if (!this.confirmExcludeMultiDocuments()) { return; }

    this.trashDocumentsService.trashMultiDocuments(tokens).subscribe(
      () => {
        this.getDeletedDocuments();
        this.warningService.toastrSuccess(this.t('Documentos excluídos com sucesso!'));
      },
      () => this.warningService.toastrError(this.t('Ocorreu algum problema. Tente novamente mais tarde.')),
      () => this.allDocsSelected = 0
    );
  }

  updateDoc(token: string, action: ActionsTrash, company=null) {

    this.trashDocumentsService.updateRecoverAndExcludeDocument(token, action, company).subscribe(
      () => {
        this.getDeletedDocuments();
        if (action === 'recover') {
          this.warningService.toastrSuccess(this.t('Documento recuperado com sucesso!'));
        } else {
          this.warningService.toastrSuccess(this.t('Documento excluído com sucesso.'));
        }
      },
      () => this.warningService.toastrError(this.t('Ocorreu algum problema. Tente novamente mais tarde.')),
      () => this.allDocsSelected = 0
    );
  }

  exclude() {
    if (this.allDocsSelected === 0) {
      return;
    }
    if (this.allDocsSelected > 1) {
      this.deletedMultiDocs();
    } else {
      const [tokenDocSelected] = this.getTokensDocsSelected();
      this.updateDoc(tokenDocSelected, 'exclude');
    }
  }

  deletedMultiDocs() {
    const filterAllDocsFromToken = this.getTokensDocsSelected();
    this.updateMultiDeletedDocuments(filterAllDocsFromToken);
  }


  checkUncheckAll($event: MatCheckboxChange) {
    this.docs.forEach((doc) => doc.selected = $event.checked);
    this.checkboxSelectDeselectAll = this.docs.every((item) => item.selected);
    this.getQuantityDocsSelected();
  }

  selectedDocument($event: MatCheckboxChange, index: number) {
    this.docs[index].selected = $event.checked;
    this.getQuantityDocsSelected();
  }

  recoverDocuments() {
    if (this.allDocsSelected === 0) {
      return;
    }
    if (this.allDocsSelected > 1) {
      this.recoverMultiDocs();
    } else {
      const [tokenDocSelected] = this.getTokensDocsSelected();
      this.updateDoc(tokenDocSelected, 'recover', this.user.company);
    }
  }

  private recoverMultiDocs() {
    const filterAllDocsFromToken = this.getTokensDocsSelected();
    this.recoverMultipleDocuments(filterAllDocsFromToken);
  }

  private recoverMultipleDocuments(tokens: string[]) {
    if (!this.confirmRecoverMultiDocuments()) { return; }

    this.trashDocumentsService.recoverMultiDocuments(tokens).subscribe(
      () => {
        this.getDeletedDocuments();
        this.warningService.toastrSuccess(this.t('Documentos recuperados com sucesso!'));
      },
      () => this.warningService.toastrError(this.t('Ocorreu algum problema. Tente novamente mais tarde.')),
      () => this.allDocsSelected = 0
    );
  }


  private confirmExcludeMultiDocuments() {
    return confirm(this.t('Deseja excluir $0 documentos? Só o proprietário da conta poderá recuperá-los da lixeira!', [this.allDocsSelected.toString()]));
  }

  private confirmRecoverMultiDocuments() {
    return confirm(this.t('Deseja recuperar $0 documentos?', [this.allDocsSelected.toString()]));
  }

  isLocalhostOrHomolog() {
    return window.location.href.includes('localhost') || window.location.href.includes('homolog.app.zapsign.com.br');
  }
}
