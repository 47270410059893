import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {WebcamImage, WebcamInitError} from 'ngx-webcam';
import {Store} from '@ngrx/store';
import {AppState} from '../../../app.state';
import {WarningService} from '../../warning.service';
import {UtilsService} from '@shared/services/utils/utils.service';
import {NgxImageCompressService} from 'ngx-image-compress';
import {useBase64Photo, usePhotoDocument, useUserAgent} from '../../../app.state.selectors';
import {PhotoDocumentBase64Front, PhotoDocumentBase64Verse} from '../store/PhotoDocument/photo-document.action';
import {Subscription} from 'rxjs';
import {
  ValidateImageTensorflowService,
  ValidatorImageTensorflow
} from '@shared/services/validate-image-tensorflow-service/validate-image-tensorflow.service';
import {LaunchDarkly} from '@shared/singleton/launch-darkly.singleton';
import {disableNextSignStep} from '@components/modal-camera/store/SelfieValidation/selfieValidation.actions';
import {base64Photo, hideModalWebcam} from '@components/modal-camera/store/PhotoSelfie/photo.actions';
import {ConsumerTranslatorService} from '@shared/services/consumer-translator/consumer-translator.service';


@Component({
  selector: 'app-modal-documents',
  templateUrl: './modal-documents.component.html',
  styleUrls: ['./modal-documents.component.scss']
})
export class ModalDocumentsComponent implements OnDestroy {

  trigger: Subject<void> = new Subject<void>();
  webcamImagePhotoDocument: WebcamImage = null;

  allowCameraSwitch = true;
  modalOpen = false;
  videoOptions: MediaTrackConstraints = {
    width: {
      ideal: 1024
    },
    height: {
      ideal: 576
    }
  };

  errors: WebcamInitError[] = [];
  userAgent = {
    isMobile: null,
    isTablet: null,
    isDesktop: null
  };

  @Input() comeFromUploadSignature = false;
  @Output() emmitHandleImageSignature = new EventEmitter<boolean>();
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
  deviceId;
  modalType = 'Front';
  private subscriptions: Subscription[] = [];
  private tensorFlowFlag: boolean;
  webcamImage: WebcamImage = null;
  loadingImage = false;
  blazeface = require('@tensorflow-models/blazeface');

  constructor(
    private store: Store<AppState>,
    public warningService: WarningService,
    public utilsService: UtilsService,
    private _imageCompress: NgxImageCompressService,
    private validateImageWithTensorflowService: ValidateImageTensorflowService,
    private t: ConsumerTranslatorService
  ) {
    const client = LaunchDarkly.getClient();
    client.on('ready', () => {
      this.tensorFlowFlag = client.variation('zexp-17-autenticacao-avancada-selfie', false);
    });

    const userAgentSubscription = store.select(useUserAgent).subscribe(({ isMobile, isTablet, isDesktop }) => {
      this.userAgent.isDesktop = isDesktop;
      this.userAgent.isMobile = isMobile;
      this.userAgent.isTablet = isTablet;
    });
    const usePhotoDocumentSubscription = store.select(usePhotoDocument).subscribe(({ viewModalDocumentFront, viewModalDocumentVerse }) => {
      this.modalOpen = viewModalDocumentFront || viewModalDocumentVerse;
      if (this.modalOpen) {
        if (viewModalDocumentFront) {
          this.modalType = 'Front';
        } else {
          this.modalType = 'Verse';
        }
      }
    });

    const userBase64PhotoSubscription = store.select(useBase64Photo).subscribe(({selfieModal}) => {
      this.modalOpen = selfieModal;
      if (this.modalOpen) {
        this.modalType = 'Selfie';
      }
    });

    this.subscriptions = [userAgentSubscription, usePhotoDocumentSubscription, userBase64PhotoSubscription];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  closeModal() {
    if (this.modalType === 'Front') {
      this.utilsService.closeModalDocumentFront('modal-document-front');
    } else if (this.modalType === 'Verse') {
      this.utilsService.closeModalDocumentVerse('modal-document-verse');
    } else {
      this.utilsService.closeModalWebcam('webcam');
    }
  }
  openModalNotHaveCamera() {
    if (this.modalType === 'Front') {
      this.utilsService.openModalNoCameraAvailable('modal-document-front');
    } else if (this.modalType === 'Front') {
      this.utilsService.openModalNoCameraAvailable('modal-document-verse');
    } else {
      this.utilsService.openModalNoCameraAvailable('webcam');
    }
    this.closeModal();
  }
  takeSnapshot(): void {
    this.trigger.next();
    this.closeModal();
  }

  handleInitError(error: WebcamInitError): void {
    const { mediaStreamError } = error;
    const { name } = mediaStreamError;

    if (mediaStreamError && name === 'NotAllowedError') {
      this.errors.push(error);
    }
  }

  handleImage(image: WebcamImage): void {
    if (this.modalType === 'Selfie') {
      if (this.tensorFlowFlag === true) {
        this.handleImageWithTensorFlowMS(image);
      } else {
        this.handleImageWithoutTensorFlowMS(image);
      }
    } else {
      this.handleImageDocument(image);
    }
  }

  handleImageDocument(image: WebcamImage): void {
    this.webcamImagePhotoDocument = image;
    const imageAsDataUrl = image.imageAsDataUrl;
    const { imageAsDataUrl: noCompressedImage } = this.webcamImagePhotoDocument;

    this._imageCompress.compressFile(imageAsDataUrl, 1, 70, 100).then(
      imageCompressed => {
        const isCompressedImage = imageCompressed ? imageCompressed : noCompressedImage;
        if (this.modalType === 'Front') {
          this.store.dispatch(new PhotoDocumentBase64Front(isCompressedImage));
        } else {
          this.store.dispatch(new PhotoDocumentBase64Verse(isCompressedImage));
        }
        console.warn(
          `(Doc ${this.modalType}) Antes da compressão -> ${this._imageCompress.byteCount(noCompressedImage)} Bytes\nDepois da compressão -> ${this._imageCompress.byteCount(imageCompressed)} Bytes`
        );
        if (this.comeFromUploadSignature) {
          this.emmitHandleImageSignature.emit(true);
        }
      }).catch(() => {
        if (this.modalType === 'Front') {
          this.store.dispatch(new PhotoDocumentBase64Front(noCompressedImage));
        } else {
          this.store.dispatch(new PhotoDocumentBase64Verse(noCompressedImage));
        }
        if (this.comeFromUploadSignature) {
          this.emmitHandleImageSignature.emit(true);
      }});
  }
  get observableCamera(): Observable<void> {
    return this.trigger.asObservable();
  }

  get nextWebcamObservable(): Observable<string | boolean> {
    return this.nextWebcam.asObservable();
  }

  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }

  private validateImageWithTensorflow(image: string): Observable<ValidatorImageTensorflow> {
    return this.validateImageWithTensorflowService.validateImage(image);
  }

  private successfulLoadImage(htmlImageElement: HTMLImageElement): Promise<Observable<ValidatorImageTensorflow>> {
    return new Promise((resolve) => {
      htmlImageElement.onload = () => {
        const sourceImage = htmlImageElement.getAttribute('src');
        const responseImageValidate = this.validateImageWithTensorflow(sourceImage);
        resolve(responseImageValidate);
      };
    });
  }

  handleImageWithTensorFlowMS(image: WebcamImage): void {
    this.webcamImage = image;
    const imageAsDataUrl = image.imageAsDataUrl;
    const {imageAsDataUrl: noCompressedImage} = this.webcamImage;
    this.loadingImage = true;
    this._imageCompress.compressFile(imageAsDataUrl, 1, 70, 90).then(
      async imageCompressed => {
        const isCompressedImage = imageCompressed ? imageCompressed : noCompressedImage;

        const htmlImageElement = document.createElement('img');
        await htmlImageElement.setAttribute('src', imageAsDataUrl);
        await htmlImageElement.setAttribute('style', 'width:300px;height:300px;');

        const predictionsImage = await this.successfulLoadImage(htmlImageElement);
        this.store.dispatch(disableNextSignStep({payload: {disableNextSignStep: true, result: '', loading: true}}));
        predictionsImage.subscribe(() => {
          this.store.dispatch(disableNextSignStep({payload: {disableNextSignStep: false, result: ''}}));
        }, error => {
          this.store.dispatch(disableNextSignStep({payload: {disableNextSignStep: true, result: error.result}}));
          if (error.result !== '') {
            alert(this.t.translate(error.result));
          }
        });

        this.loadingImage = false;

        this.store.dispatch(base64Photo({payload: isCompressedImage}));
        this.store.dispatch(hideModalWebcam({payload: 'modal-camera'}));
      }
    ).catch((e) => {
        console.log(e);
        this.loadingImage = false;
        this.store.dispatch(base64Photo({payload: noCompressedImage}));
        this.store.dispatch(hideModalWebcam({payload: 'modal-camera'}));
      }
    );
  }

  handleImageWithoutTensorFlowMS(image: WebcamImage): void {
    this.webcamImage = image;
    const imageAsDataUrl = image.imageAsDataUrl;
    const {imageAsDataUrl: noCompressedImage} = this.webcamImage;
    this.loadingImage = true;
    this._imageCompress.compressFile(imageAsDataUrl, 1, 70, 90).then(
      async imageCompressed => {
        const isCompressedImage = imageCompressed ? imageCompressed : noCompressedImage;

        const model = await this.blazeface.load();
        const returnTensors = false; // Pass in `true` to get tensors back, rather than values.
        const htmlImageElement = document.createElement('img');
        await htmlImageElement.setAttribute('src', imageAsDataUrl);
        await htmlImageElement.setAttribute('style', 'width:300px;height:300px;');
        const predictions = await model.estimateFaces(htmlImageElement, returnTensors);
        this.loadingImage = false;

        if (predictions.length === 0 || predictions.length > 1 || predictions[0].probability < 0.98) {
          const result = this.t.translate('Por favor, tire uma foto mostrando todo o seu rosto, em uma região bem iluminada.');
          this.store.dispatch(disableNextSignStep({payload: {disableNextSignStep: true, result}}));
          alert(result);
          return;
        }

        this.store.dispatch(base64Photo({payload: isCompressedImage}));
        this.store.dispatch(hideModalWebcam({payload: 'modal-camera'}));
      }
    ).catch((e) => {
        this.loadingImage = false;
        this.store.dispatch(base64Photo({payload: noCompressedImage}));
        this.store.dispatch(hideModalWebcam({payload: 'modal-camera'}));
      }
    );
  }

}
