import {createAction, props} from '@ngrx/store';
import {ExtraDoc, Signer} from '../../../../../helpers/model/docs';


export const setSignerAction = createAction(
  '[Set Signer] - Save signer properties from backend', props<{ payload: Signer }>()
);


export const setExtraDocAction = createAction(
  '[Set Extra Doc] - Save signer`s extra doc changed in index', props<{ payload: ExtraDoc, index: number }>()
);


