import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Signer}  from '../../helpers/model/docs';
import { Router } from '@angular/router';
import { SignatureFilters } from '../../client/page-my-signatures/page-my-signatures.component';
import { PaywallData } from '../../helpers/model/paywallData';
import { PaginationService } from '../../pagination.service';
import { ConsumerTranslatorService } from '../../shared/services/consumer-translator/consumer-translator.service';
import { UserGroupPermissionValidatorService } from '../../shared/services/user-group-permission-validator/user-group-permission-validator.service';
import { PagamentosBackendService } from '../pagamentos.backend.service';
import { Subscription } from 'rxjs';
import { MixpanelService } from 'src/app/shared/services/mixpanel.service';
import {activityData} from "@components/modal-ask-business-segment/modal-ask-business-segment.data";

@Component({
  selector: 'app-minhas-assinaturas-header',
  templateUrl: './minhas-assinaturas-header.component.html',
  styleUrls: ['./minhas-assinaturas-header.component.scss']
})
export class MinhasAssinaturasHeaderComponent implements OnChanges, OnInit {
  @Output() pageResized = new EventEmitter<number>();

  constructor(
    private router: Router,
    private consumerTranslatorService: ConsumerTranslatorService,
    private pagamentosBackend: PagamentosBackendService,
    private userGroupPermissionValidator: UserGroupPermissionValidatorService,
    private mixpanelService: MixpanelService,
  ) {}

  private subscriptions: Subscription[] = [];

  @Input() end_date;
  @Input() name_filter;
  @Input() paginationService: PaginationService;
  @Input() start_date;
  @Input() statusFilter;
  @Input() user: any;

  companyHasBatchSignature: boolean;
  loading = false;
  signers: Signer[] = [];
  userHasBatchSignature: boolean;
  documentsQuantity: number;

  @Output() emitterFilters = new EventEmitter<SignatureFilters>();

  protected readonly name = name;

  ngOnInit() {
    this.documentsQuantity = this.paginationService.count;
    this.loading = true;
    const userGroupPermissionSubs = this.userGroupPermissionValidator.hasPermissionForPage('batch_signature')
      .subscribe((permitted) => {
        this.userHasBatchSignature = permitted;
        this.loading = false;
      });
    this.subscriptions.push(userGroupPermissionSubs);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  get hasDocuments() {
    this.documentsQuantity = this.paginationService.count;
    return this.documentsQuantity !== undefined && this.documentsQuantity > 0;
  }

  get canBatchSignature() {
    return this.companyHasBatchSignature && this.userHasBatchSignature;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.user && changes?.user?.currentValue) {
      this.getPaywall();
    }
  }

  getPaywall() {
    this.loading = true;
    const paywallSubscription = this.pagamentosBackend.getPaywall(this.user.company.id)
      .subscribe(
        (data: PaywallData) => {
          this.companyHasBatchSignature = data.can_batch_signature;
          this.loading = false;
        },
        err => {
          console.error(err);
        }
      );
    this.subscriptions.push(paywallSubscription);
  }

  t(ptText: string) {
    return this.consumerTranslatorService.translate(ptText);
  }

  goToRedirectBatchSignature($event: PointerEvent) {
    $event.stopPropagation();
    if (this.loading) { return; }
    if (this.companyHasBatchSignature && !this.userHasBatchSignature) { return; }
    this.router.navigateByUrl(`/conta/assinatura-lote`);

    const mixPanelServiceSubscription = this.mixpanelService
      .sendEventMixpanel('My signatures - Sign', {
        total_documents: this.documentsQuantity,
      })
      .subscribe();
    this.subscriptions.push(mixPanelServiceSubscription);
  }

  changedFiltersOutput($filters) {
    this.emitterFilters.emit($filters);
  }

  resizePage($event: number) {
    this.pageResized.emit($event)
  }
}
