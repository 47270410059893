<section class="zs-content-extra-doc">
  <div class="zs-content-titulo">
    <h1 class="zs-titulo">
      {{'Envelope com $0 documentos'|translate :[doc.extra_docs.length + 1]}}
    </h1>
  </div>
  <div class="zs-content-list-docs">
    <div *ngIf="doc.name" class="zs-card-doc">
      <p class="zs-doc-name">
        {{ doc.name }}
      </p>
      <div class="zs-content-btn">
        <zs-button
          class="zs-btn-primary"
          label="Ler documento"
          typeButton="primary"
          icon="icon_documents"
          iconDirection="left"
          size="large"
          (click)="viewPDF(getPdf(doc))"
        ></zs-button>
        <zs-button
          class="zs-btn-icon"
          *ngIf="permissionToDownload()"
          typeButton="icon"
          icon="download-02"
          size="large"
          (click)="download(doc)"
        ></zs-button>
      </div>
    </div>
    <div *ngFor="let item of extraDocs; let i = index;" [attr.data-index]="i + 1" class="zs-card-doc">
      <p class="zs-doc-name">
        {{ item.name }}
      </p>
      <div class="zs-content-btn">
        <zs-button
          class="zs-btn-primary"
          label="Ler documento"
          typeButton="primary"
          icon="icon_documents"
          iconDirection="left"
          size="large"
          (click)="viewPDF(getPdf(item))"
        ></zs-button>
        <zs-button
          class="zs-btn-icon"
          *ngIf="permissionToDownload()"
          typeButton="icon"
          icon="download-02"
          size="large"
          (click)="download(item)"
        ></zs-button>
      </div>
    </div>
  </div>
</section>
