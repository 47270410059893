import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Language } from 'src/translation/translation';
import { CookieService } from '../cookie.service';
import {AffiliateService} from "../../client/affiliate.service";

const COOLDOWN_COOKIE_NAME = 'affiliatePopupCooldown';

@Component({
  selector: 'app-modal-afilliate',
  templateUrl: './modal-afilliate.component.html',
  styleUrls: ['./modal-afilliate.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({opacity: 0}),
        animate('.3s ease-out', style({opacity: 1}))
      ])
    ])
  ]
})
export class ModalAfilliateComponent implements OnInit {
  lang: Language = localStorage.getItem('lang') as Language || 'pt-br'
  modalOpened: boolean = true

  constructor(
    private cookieService: CookieService,
    public affiliateService: AffiliateService
  ) {
  }

  ngOnInit(): void {
    this.modalOpened = false
    try {
      if (this. cookieService.existsCookie(COOLDOWN_COOKIE_NAME)) {
        this.modalOpened = false;
      } else {
        this.modalOpened = true;
        this.insertCooldownCookie();
      }
    } catch {
      this.modalOpened = false;
    }
  }

  insertCooldownCookie() {
    const cooldownHours = 48;
    this.cookieService.setCookie(COOLDOWN_COOKIE_NAME, '1', cooldownHours);
  }

  closeModalAfilliate() {
    this.modalOpened = false;
  }
}
