<div class="header content-slide_tag">
  <h2 class="header-title" id="auth-2fa-title-test-id"> {{'Autenticação de dois fatores' | translate}} </h2>
  <mat-slide-toggle [(ngModel)]="toggleControl2FA" (ngModelChange)="activate2FAuthentication()"></mat-slide-toggle>
</div>

<div class="two-factor-content">
  <p class="secondary_text">
    {{'Com essa função ativada, você receberá um código de verificação via SMS sempre que fizer login na sua conta, para garantir que somente você tenha acesso à sua conta' | translate}}
  </p>
</div>

<div class="two-factor-content-note" *ngIf="!user.two_factor_authentication_enabled">
  <p class="two-factor-content-text">{{'Funcionalidade premium disponível apenas para o Plano Completo, API ou Corporativo' | translate}}</p>
  <zs-button class="two-factor-content-button" typeButton="tertiary"  [label]="'Ver planos'" (click)="goToNewTemplate()"></zs-button>
</div>

<app-modal-two-fa-authenticate
  [modalCloseIconNotVisible]="false"
  [user]="user" *ngIf="openModalTwoFaAuthentication && !user.two_factor_authentication_enabled"
  (emmiterCloseModal)="closeModalTwoFAuthenticate()">
</app-modal-two-fa-authenticate>
