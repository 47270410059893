import { Injectable } from '@angular/core';
import {getTranslationForTruoraSign, Language} from "../../translation/translation";
import { Locale } from 'src/translation/translation';
import {environment} from "../../environments/environment";
import {DICTIONARYLOCALE} from "../../translation/dictionaryLocale";

@Injectable({
  providedIn: 'root'
})
export class TranslateLocaleService {

  constructor() { }

  translateLocale(brText:string,toLocale:Locale|string, replacements:string[]=[]): string {
    let text = ''
    if (toLocale !== 'br' && toLocale !== 'global') toLocale = 'br'
    if (toLocale === 'br') {
      text = brText
    } else {
      const translatedText = DICTIONARYLOCALE[brText]
      if (!translatedText) {
        console.error('Translation not found for text: ' + brText)
        text = brText // fallback em português
      } else {
        text = translatedText[toLocale]
      }
    }

    if(replacements.length > 0){
      text = this.doReplacements(text,replacements)
    }
    if (window.location.host.includes(environment.TRUORA_HOST)){
      text = getTranslationForTruoraSign(text);
    }
    return text
  }

  private doReplacements(text,replacements){
    for(let i = 0; i < replacements.length; i++){
      text = text.replaceAll(`$${i}`,`${replacements[i]}`)
    }
    return text
  }
}
