<div [ngClass]="showModal ? 'zs_modal_select' : 'zs_modal_hidden'" (click)="closeModal()">
  <div class="zs_modal_content" (click)="preventModalFromClosing($event)">
    <div class="zs_modal_option_select" (click)="isMultiCompany ? toogleShowProfile():''">
      <div class="zs_modal_icon_and_title">
        <mat-icon class="zs_modal_icon" *ngIf="showProfile"  svgIcon="icon_arrow_down"></mat-icon>
        <mat-icon class="zs_modal_icon" *ngIf="!showProfile" svgIcon="icon_arrow_right"></mat-icon>
        <strong class="zs_modal_title">{{'Minha conta' | translate}}</strong>
      </div>
      <mat-icon class="zs_modal_icon" *ngIf="!showCredits" svgIcon="icon_user_new"></mat-icon>
      <div class="zs_modal_balance_credits" *ngIf="showCredits">
        <span>{{'Saldo:' | translate}}</span>
        <strong class="zs_modal_credits" (click)="redirectCreditsPlanAndPrices()">{{credits}} {{labelCredits() | translate}}</strong>
      </div>
    </div>
    <ul class="zs_modal_list_select" [ngClass]="showProfile && !showCompanies? 'zs_modal_list_open': 'zs_modal_list_close'">
      <li class="zs_modal_list_element" *ngFor="let navbarElement of optionsNavbar ; let i = index" [ngClass]="'zs_modal_list_elements_length_'+optionsNavbar.length" (click)="navigateTo(navbarElement)">
        <div class="zs_modal_list_element_icon_and_name">
          <mat-icon class="zs_modal_icon" [svgIcon]="navbarElement.icon"></mat-icon>
          <span>{{navbarElement.value}}</span>
        </div>
      </li>
    </ul>

    <div *ngIf="isMultiCompany">
      <div class="zs_modal_option_select" (click)="toogleShowCompanies()">
        <div class="zs_modal_icon_and_title">
          <mat-icon class="zs_modal_icon" *ngIf="showCompanies"  svgIcon="icon_arrow_down"></mat-icon>
          <mat-icon class="zs_modal_icon" *ngIf="!showCompanies" svgIcon="icon_arrow_right"></mat-icon>
          <strong class="zs_modal_title">{{'Minhas organizações' | translate}}</strong>
        </div>
        <mat-icon class="zs_modal_icon" svgIcon="icon_dataflow"></mat-icon>
      </div>
      <ul class="zs_modal_list_select" [ngClass]="showCompanies && !showProfile? 'zs_modal_list_open': 'zs_modal_list_close'">
        <li class="zs_modal_list_element" *ngFor="let membership of memberships;let i = index" (click)="chooseCompany(membership.company)" [ngClass]="(memberships.length === (i + 1)) ? 'zs_modal_list_element_company_length_'+(i + isMembershipsPage) : ''">
          <div class="zs_modal_list_element_icon_and_name">
            <span class="zs_company_name" [ngClass]="membership.company.id === currentCompany?'active':''">{{membership.company.name ? membership.company.name : translator.translate('Sem nome')}}</span>
          </div>
          <mat-icon class="zs_modal_icon" *ngIf="membership.company.id === currentCompany" svgIcon="icon_check"></mat-icon>
        </li>
        <li class="zs_modal_list_element" *ngIf="membershipsLength > 4" (click)="navigateOrganizations()">
          <mat-icon class="zs_modal_icon" svgIcon="icon_dots_grid"></mat-icon>
          <span class="zs_company_name">{{'Ver todos' | translate}}</span>
        </li>
      </ul>
    </div>
  </div>
</div>

