import {createAction, props} from '@ngrx/store';

export const catchNumberOfDocumentsInAccount = createAction('[Pega o número de documentos criados no periodo do plano]', props<{
  payload: number
}>());
export const catchDocumentsLimitInAccount = createAction('[Pega o número de documentos limite para o plano atual]', props<{
  payload: number
}>());
export const catchFreeDocsMonthInAccount = createAction('[Pega o número de documentos free na conta]', props<{
  payload: number
}>());
export const catchIfUserContainsOnePlan = createAction('[Pega a flag para saber se o usuário é free ou não]', props<{
  payload: boolean
}>());
export const subtractDocumentInAccount = createAction('[Subtraí 1 documento na conta]', props<{ payload: number }>());

