<!--<ng-container >-->
<ng-container *ngIf="isMobile && !isVisto && !isEmpty && expandSignature">
  <zs-button
    typeButton="link"
    label="Salvar assinatura"
    iconDirection="left"
    icon="check"
    (click)="saveSignature()"
    class="zs-button-open-mobile"
  >
  </zs-button>
  <app-no-camera-available></app-no-camera-available>
</ng-container>

<ng-container *ngIf="!isMobile && !isVisto">
  <zs-button
    typeButton="link"
    label="Abrir no celular"
    iconDirection="left"
    icon="qr-code-01"
    (click)="openModalNotHaveCamera()"
    class="zs-button-open-mobile"
  >
  </zs-button>
  <app-no-camera-available></app-no-camera-available>
</ng-container>
<ng-container *ngIf="!isEmpty">
  <zs-button
    typeButton="quaternary"
    label="Limpar"
    class="zs-button-clear-signature"
    (click)="emitterClearSignature()"
  ></zs-button>
</ng-container>

