import {Action, createReducer, on} from '@ngrx/store';
import { LoginUrlQueryParamsProps } from './login.model';
import { setLoginQueryParams, clearLoginQueryParams  } from './login.action';

export const INITIAL_STATE: LoginUrlQueryParamsProps = {
    planId: null,
};

const _loginUrlQueryParamsReducer = createReducer(
  INITIAL_STATE,
  on(setLoginQueryParams, (state, { planId }) => {
    return { ...state, planId };
  }),
  on(clearLoginQueryParams, () => {
    return INITIAL_STATE
  }),
);

export function loginUrlQueryParamsReducer(state: LoginUrlQueryParamsProps, action: Action) {
  return _loginUrlQueryParamsReducer(state, action);
}
