<input class="signer_link" disabled [value]="appUrl+'/verificar/'+signer.token">

<div class="container-signer-input-link-buttons">
  <button id="copy-link-test" mat-icon-button color="primary"
          (click)="copy(appUrl+'/verificar/'+signer.token)">
    {{'Copiar link' | translate}}
    <mat-icon>file_copy</mat-icon>
  </button>
  <a [href]="getWhatsAppSignerLink()" target="_blank" matTooltip="{{'Compartilhar link via WhatsApp' | translate}}" mat-icon-button color="primary"
     (click)="copy(appUrl+'/verificar/'+signer.token)">
    {{'Enviar por WhatsApp' | translate}}
    <mat-icon svgIcon="whatsapp"></mat-icon>
  </a>
</div>
