<!-- Modal -->
<div id="myModal" class="modal"
[style.display]="openModal ? 'block':'none'" style="overflow-y: auto">
  <!-- Modal content -->
  <div class="modal-content" style="position:relative; align-items: normal;">
    <div style="position:absolute;top:10px;right:10px;">
      <button mat-icon-button (click)='closeModal($event)'><mat-icon class="zs-icon-close">close</mat-icon></button>
    </div>
    <h2>
      {{'Registro de Webhooks' | translate}}
    </h2>
    <button mat-raised-button color="primary" *ngIf="!haveLoaded" (click)="getMyWebhooks()">
      {{"Carregar webhooks" | translate}}
    </button>
    <div *ngIf="haveLoaded && webhooks.length === 0">
      <p *ngIf="!loading">{{'Não há registros a mostrar' | translate}}.</p>
      <button mat-raised-button (click)="getMyWebhooks()" [disabled]="loading">
        <mat-icon>update</mat-icon> {{'Atualizar'| translate}}
      </button>
    </div>
    <div *ngIf="haveLoaded && webhooks.length > 0">
      <p style="margin:0;padding:16px 0;font-size:12px;">{{'Últimos webhooks emitidos. Os registros são apagados após 7 dias.' | translate}}</p>
      
      <div style="display:flex;flex-wrap:wrap;justify-content:space-between;align-items: baseline;">
        <button mat-raised-button (click)="getMyWebhooks()" [disabled]="loading" color="primary">
          <mat-icon>update</mat-icon> {{'Atualizar' | translate}}
        </button>
        <p>
          {{'Mostrar' | translate}}:
          <mat-form-field style="width:100%;max-width:150px;margin:0 8px">
              <mat-select [(value)]="onlyFailsFilter" (selectionChange)="getMyWebhooks()">
                  <mat-option [value]="0">
                      {{'Todas requisições' | translate}}
                  </mat-option>
                  <mat-option [value]="1">
                     {{'Somente falhas (status != 200)' | translate}}
                  </mat-option>
              </mat-select>
          </mat-form-field>
        </p>
        <p>
          {{"Mostrando $0-$1 de $2 webhooks" | translate: [showing_from, showing_to, webhooks_count]}}
        </p>
        <p>
          {{'Página' | translate}}
          <mat-form-field style="width:100%;max-width:50px;margin:0 8px">
              <mat-select [(value)]="current_page" (selectionChange)="getMyWebhooks()">
                  <mat-option *ngFor="let p of pages_array" [value]="p">
                      {{p}}
                  </mat-option>
              </mat-select>
          </mat-form-field>
          {{'de' | translate}}&nbsp;{{number_of_pages}}
        </p>
      </div>
      <table>
        <thead>
          <tr>
            <th scope="col"><small>#</small></th>
            <th scope="col"><small>{{'Data' | translate}}</small></th>
            <th scope="col"><small>URL</small></th>
            <th scope="col"><small>{{'Horário' | translate}}</small></th>
            <th scope="col"><small>Request body</small></th>
            <th scope="col"><small>Response Body</small></th>
            <th scope="col"><small>Status</small></th>
          </tr>
        </thead> 
        <tbody>
          <tr *ngFor="let webhook of webhooks">
            <td data-label="#">{{webhook.id}}</td>
            <td data-label="Data">{{webhook.created_at | date : 'dd/MM/yyyy'}}</td>
            <td data-label="Url">{{webhook.url}}</td>
            <td data-label="Horário" matTooltip="{{webhook.created_at | date : 'HH:mm:ssSSS'}}">{{webhook.created_at | date : 'HH:mm:ss'}}</td>
            <td data-label="Request" matTooltip="{{webhook.post_data}}" (click)="copyText(webhook.post_data)" style="cursor:pointer">{{webhook.post_data | slice:0:25}}</td>
            <td data-label="Response" matTooltip="{{webhook.body}}" (click)="copyText(webhook.body)" style="cursor:pointer">{{webhook.body | slice:0:25}}</td>
            <td data-label="Status">{{webhook.status}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <br>
    <button mat-raised-button color="danger" (click)="closeModal($event)"><mat-icon>close</mat-icon>
      {{'Fechar' | translate}}
    </button>
  </div>
</div>
