<div class="button-auth-container">
  <div class="button-auth-left-icon">
    <mat-icon [svgIcon]="icon"></mat-icon>
  </div>

  <div class="button-auth-inner">
    <div class="button-auth-description">
      <p class="button-auth-description-title">{{title | translate}}</p>
      <p class="button-auth-description-subtitle">{{subtitle | translate}}</p>
    </div>

    <div class="button-auth-final-icon"
         [ngClass]="[
            loading ? 'loading' : '',
            check ? 'check' : '',
            error ? 'error' : ''
            ]"
    >
      <mat-icon [svgIcon]="finalIcon"></mat-icon>
    </div>
  </div>

</div>
