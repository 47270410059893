
export interface SlackMessage {
  text: string;
  channel: string;
  attachments: SlackAttachment[];
}

export interface SlackAttachment {
  title: string;
  text: string;
  color: string;
  author_name: string;
}

export enum SlackMessageType {
  SUCCESS = 'good',
  WARNING = 'warning',
  ERROR = 'danger'
}
