<button
  [attr.data-cy]="dataCy"
  mat-raised-button
  [class]="'zs-button ' + size"
  [disabled]="disable"
  [ngClass]="[typeButton,iconDirection,loading ? 'loading' : '',!loading && check? 'check':'', colorClient && primaryColor ? 'colorClient' : '']"

  [matTooltip]="matTooltip ? (matTooltip | translate ): ''"
  [type]="type"
>
  <ng-template [ngIf]="!loading && !check || icon !== ''">
    {{label | translate}}
    <ng-content select="[renderHtmlButton]"></ng-content>
  </ng-template>
  <mat-icon *ngIf="icon !== '' && !loading && !check" [svgIcon]="icon"></mat-icon>
  <mat-icon class="zs-icon" *ngIf="loading" svgIcon="circular-loading"></mat-icon>
  <mat-icon *ngIf="!loading && check" svgIcon="check"></mat-icon>
</button>
