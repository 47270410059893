import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AuthService} from '../../auth/auth.service';
import {Router} from '@angular/router';
import {AnimationOptions} from 'ngx-lottie';
import {User} from '../../helpers/model/user';
import {TwoFaAuthenticateService} from './services/two-fa-authenticate.service';
import {WarningService} from '../warning.service';
import {HttpErrorResponse} from '@angular/common/http';
import {CountdownComponent, CountdownConfig, CountdownEvent} from 'ngx-countdown';
import {TranslatorService} from '../translator.service';
import {Language} from '../../../translation/translation';

@Component({
  selector: 'app-modal-two-fa-authenticate',
  templateUrl: './modal-two-fa-authenticate.component.html',
  styleUrls: ['./modal-two-fa-authenticate.component.scss'],


})
export class ModalTwoFaAuthenticateComponent implements OnInit {
  @Input() modalCloseIconNotVisible = true;
  readonly options: AnimationOptions = {
    path: 'assets/animations/padlock.json',
  };
  verificationCodeSms = [''];
  @Output() emmiterCloseModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() user: User;
  @ViewChild('cdSms', {static: false}) countdown: CountdownComponent;
  phoneWithAsteristics = '';
  disableResendButton = true;
  disabledConfirmSms = false;
  loadingResendSms = false;
  readonly configCountdown: CountdownConfig = {
    leftTime: 40
  };
  private readonly lang = localStorage.getItem('lang') as Language || 'pt-br';

  constructor(
    private authService: AuthService, private router: Router, private twoFaAuthenticateService: TwoFaAuthenticateService, private warningService: WarningService, private translatorService: TranslatorService) {
  }


  ngOnInit(): void {
    this.putAsterisksAtTheBeginningPhone();
    this.resendSMS(true);
  }

  resendSMS(calledByOnInit: boolean) {
    this.setLoadingResetSmsWhenNotCalledByOnInit(calledByOnInit);
    this.twoFaAuthenticateService.send2FAToken().subscribe((response: any) => {
      const successfulMessageReturnedFromAPI = response?.message as string;
      this.setMessageSuccessResendSms(calledByOnInit, successfulMessageReturnedFromAPI);
      this.loadingResendSms = false;
    }, (_: HttpErrorResponse | any) => {
      this.disableResendButton = true;
      this.resetCountdown();
    });
  }

  listenerCountdownResendSms(event: CountdownEvent) {
    this.disableResendButton = event.action === 'start';
    this.disableResendButton = event.action === 'restart';
    this.disableResendButton = event.action !== 'done';
  }

  t(text: string, replacements?: string[]) {
    return this.translatorService.translate(text, this.lang, replacements);
  }

  logoutZapsign() {
    this.authService.logout();
    this.router.navigate(['acesso', 'entrar']);
  }

  toUpperCaseVerificationCode($event) {
    this.verificationCodeSms = $event.target.value.toUpperCase();
  }

  checkSmsVerificationCode() {
    const decryptedUsername = atob(sessionStorage.getItem('2fau'));
    const decryptedPassword = atob(sessionStorage.getItem('2fap'));
    const provider = sessionStorage.getItem('2fapr') ? atob(sessionStorage.getItem('2fapr')) : null;

    const bodyVerificationCode = {
      two_factor_authentication_token: this.verificationCodeSms, username: decryptedUsername
    };

    this.twoFaAuthenticateService.validate2FAToken(bodyVerificationCode).subscribe(() => {
      this.disabledConfirmSms = true;
      if (window.location.href.includes('/acesso/2fa')) {
        this.handleLogin(decryptedUsername, decryptedPassword, provider);
      } else {
        this.user.two_factor_authentication_enabled = true;
        this.emmiterCloseModal.emit(false);
      }
    }, () => {
      this.disabledConfirmSms = false;
      this.warningService.toastrError(this.t('Ocorreu um erro ao validar. Por favor, verifique se os dados estão corretos.'));

      setTimeout(() => {
        // @ts-ignore
        window.location = '/acesso/entrar';
      }, 2000);

    });
  }

  closeModal() {
    this.emmiterCloseModal.emit(false);
  }

  private handleLogin(username: string, password: string, provider: string) {
    this.authService.login(provider ? provider : username, password).subscribe(() => {
      this.authService.afterLoginRouting(username, false);
    }, (err) => {
      this.disabledConfirmSms = false;
      this.warningService.toastrError(this.t('Ocorreu um erro ao validar. Por favor, verifique se os dados estão corretos.'));
      setTimeout(() => {
        // @ts-ignore
        window.location = '/acesso/entrar';
      }, 2000);
    });
  }

  private putAsterisksAtTheBeginningPhone() {
    const lastFourNumbers = this.user.phone_number.slice(-4);
    this.phoneWithAsteristics = lastFourNumbers.padStart(this.user.phone_number.length, '*');
  }

  private setLoadingResetSmsWhenNotCalledByOnInit(calledByOnInit: boolean) {
    if (!calledByOnInit) {
      this.loadingResendSms = true;
    }
  }

  resetCountdown() {
    setTimeout(() => this.countdown.restart(), 1000);
  }

  private setMessageSuccessResendSms(calledByOnInit: boolean, message: string) {
    if (!calledByOnInit) {
      this.warningService.toastrSuccess(this.t(message));
      this.disableResendButton = true;
      this.resetCountdown();
    }
  }
}
