import {SignPageFooterProps} from './sign-page-footer.model';
import {Action, createReducer, on} from '@ngrx/store';
import * as actions from './sign-page-footer.actions';

export const INITIAL_STATE: SignPageFooterProps = Object.freeze({
  backButton: {
    showButton: false
  },
  clickBackButton: false,
  primaryButton: {
    showButton: false,
    label: 'Continuar'
  },
  clickPrimaryButton: false,
  refuseButton: {
    showButton: false
  },
  clickRefuseButton: false,
  showNoteMessage: false,
  featureFlag: false,
  zcre323launchDarkly: false,
  loadingFeatureFlag: true,
  showFooter: false,
  routeBeforeLoading: '',
});

const _signPageFooterReducer = createReducer(INITIAL_STATE,
  on(actions.setSignerFooterButtons, (state, { payload }) => ({
    ...state,
    backButton: payload.backButton,
    primaryButton: payload.primaryButton,
    refuseButton: payload.refuseButton
  })),
  on(actions.signerFooterPrimaryButton, (state, { payload }) => ({
    ...state,
    primaryButton: payload
  })),
  on(actions.signerFooterBackButton, (state, { payload }) => ({
    ...state,
    backButton: payload
  })),
  on(actions.signerFooterRefuseButton, (state, { payload }) => ({
    ...state,
    refuseButton: payload
  })),
  on(actions.signerFooterButtonClick, (state, { payload }) => ({
    ...state,
    clickPrimaryButton: payload
  })),
  on(actions.signerFooterBackButtonClick, (state, { payload }) => ({
    ...state,
    clickBackButton: payload
  })),
  on(actions.signerFooterRefuseButtonClick, (state, { payload }) => ({
    ...state,
    clickRefuseButton: payload
  })),
  on(actions.signerFooterShowNoteMessage, (state, { payload }) => ({
    ...state,
    showNoteMessage: payload
  })),
  on(actions.signerFooterShow, (state, { payload }) => ({
    ...state,
    showFooter: payload,
  })),
  on(actions.signerFeatureFlag, (state, { payload }) => ({
    ...state,
    featureFlag: payload,
    loadingFeatureFlag: false
  })),
  on(actions.signerRouteBeforeLoading, (state, { payload }) => ({
    ...state,
    routeBeforeLoading: payload
  })),
  on(actions.signerFeatureFlagzcre323, (state, { payload }) => ({
    ...state,
    zcre323launchDarkly: payload,
  })),
);

export default function signPageFooterReducer(state: SignPageFooterProps, action: Action) {
  return _signPageFooterReducer(state, action);
}
