<!-- The Modal -->
<div id="myModal" class="modal"
     (click)="closeModal()"
     [style.display]="modalOpen ? 'block':'none'">
  <!-- Modal content -->
  <div class="modal-content" (click)="$event.stopPropagation();">
    <p>{{'Você pode assinar com seu celular. Para isso, escaneie o' | translate}} <abbr title="Quick Response">{{'qr' | translate}}</abbr> {{'code abaixo com a câmera do seu celular.' | translate}}</p>
    <qrcode [qrdata]="qrCode" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>

    <aside>
      <button mat-button color="accent" (click)="closeModal()">
        {{getButtonText() | translate}}
      </button>
    </aside>
  </div>
</div>
