import { Doc } from './../helpers/model/docs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Injectable, EventEmitter } from '@angular/core';
import { Signer } from '../helpers/model/docs';
import {PaywallData} from "../helpers/model/paywallData";
import {HelpersGlobalService} from 'src/app/helpers-global.service';


@Injectable({
  providedIn: 'root'
})
export class DataContainerService {
  data: DataContainer = {}
  data$: EventEmitter<any> = new EventEmitter();

  moveItemInArray: boolean = false

  constructor(private helpersGlobalService: HelpersGlobalService) {}

  emitData(){
    this.data$.emit(this.data)
  }

  refreshPaywall(paywall:PaywallData){
    this.data.paywall = paywall

    if(this.data.paywall.can_batch_signature) {
      localStorage.setItem('has_assinatura_lote','1')
    }else{
      localStorage.setItem('has_assinatura_lote','0')
    }

    this.emitData()
  }

  cdkDropListDropped(event: CdkDragDrop<Array<string>>, signers: Signer[]){
    moveItemInArray(signers, event.previousIndex, event.currentIndex)
    this.moveItemInArray = true
  }

  isEmailValid(email: string) {
    if (!email || email.length < 1) { return false; }
    return this.helpersGlobalService.isEmailValid(email)
  }

  getSignerColor(signer:Signer, doc: Doc){
    let index = doc.signers.indexOf(signer)
    switch(index % 5){
      case 0:
        return 'rgba(0,150,150,0.1)'
      case 1:
        return 'rgba(0,255,0,0.1)'
      case 2:
        return 'rgba(255,0,0,0.1)'
      case 3:
        return 'rgba(255,255,0,0.1)'
      case 4:
        return 'rgba(0,0,0,0.1)'
    }
  }

}


export class DataContainer {
  paywall?: PaywallData
}

