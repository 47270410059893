<div class="zs-trash-header">
  <div class="zs-trash-title">
    <h2>{{'Lixeira' | translate}}</h2>
    <div class="zs-trash-title-right">
    <span class="text-show-quantity" *ngIf="allDocsSelected === 0">
        {{'$0 documentos' | translate: [docs.length]}}
    </span>
    <span class="text-show-quantity" *ngIf="allDocsSelected === 1">{{allDocsSelected}} {{"documento selecionado" | translate}}</span>
    <span class="text-show-quantity" *ngIf="allDocsSelected > 1">{{allDocsSelected}} {{"documentos selecionados" | translate}}</span>
    </div>
  </div>
  <div class="zs-trash-help-text">
    <mat-icon class="zs-trash-help-text-icon" svgIcon="info_square"></mat-icon>
    <p>
      {{'Apenas o proprietário da conta pode eliminar definitivamente os documentos da lixeira.' | translate}}
    </p>
  </div>
</div>


