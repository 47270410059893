import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {UserGroupState, UserGroup} from '../store/modal-user-groups.models';
import {AuthBackendService} from "../../../auth/backend.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../../app.state";
import {useSelectorUserGroups} from "../../../app.state.selectors";
import {Subscription} from "rxjs";
import {setUserGroupAction} from "../../../client/settings-organization/store/user-group/user-group.actions";
import {animate, style, transition, trigger} from '@angular/animations';
import {WarningService} from '../../warning.service';
import {TranslatorService} from '../../translator.service';
import {Language} from 'src/translation/translation';
import {MenuModel} from "@client/page-single-document/page-single-document.model";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";


@Component({
  selector: 'app-modal-user-group-list',
  templateUrl: './modal-user-group-list.component.html',
  styleUrls: ['./modal-user-group-list.component.scss'],
  animations: [
    trigger('inOut', [
      transition('void => *', [
        style({opacity: 0}),
        animate('500ms',
          style({opacity: 1})
        )
      ]),
      transition('* => void', [
        animate('500ms',
          style({opacity: 0})
        )
      ])
    ])
  ],
})
export class ModalUserGroupListComponent implements OnInit {
  lang: Language = localStorage.getItem('lang') as Language || 'pt-br';

  confirmDelete = false;
  confirmDeleteIndex: number;
  emailInUserGroup: string[];
  showMembers: boolean = false;
  showMembersIndex: number;
  deleteMemberIcon: boolean = true;
  deleteMemberText: boolean = false;
  memberIndex: number;
  modalOpen = false;
  actualUserGroup: UserGroup;
  clickedUserGroup: UserGroup;
  userGroups: UserGroup[] = [];
  @Input() state: UserGroupState;
  @Output() goToEdit = new EventEmitter<UserGroup>();
  @Output() goToNew = new EventEmitter();
  @Output() deletedEmitter = new EventEmitter();
  storeSubscriptions: Subscription[];
  showRemoveUserGroup = false;
  isMobile = false;
  showModalRemoveUsers = false;
  actualGroupId = 0;

  optionMenuGroup: MenuModel[] = [
    {
      option: () => this.editUserGroup(this.actualUserGroup),
      textOption: 'Editar grupo',
      iconOption: 'edit-05'
    },
    {
      option: () => this.openModalDeleteUserGroup(),
      textOption: 'Eliminar grupo',
      iconOption: 'delete-contact-icon'
    }
  ];

  constructor(
    private authBackend: AuthBackendService,
    private store: Store<AppState>,
    private warningService: WarningService,
    private translatorService: TranslatorService,
    private breakpointObserver: BreakpointObserver,
    private _store: Store<AppState>,) {
    this.breakpointObserver.observe([Breakpoints.XSmall]).subscribe((result) => {
      this.isMobile = result.matches;
    });
  }

  ngOnInit(): void {
    const userGroupsSupscription = this.store.select(useSelectorUserGroups).subscribe((state) => {
      this.userGroups = state.userGroups;
    });
    this.storeSubscriptions = [userGroupsSupscription];
  }

  t(ptText: string) {
    return this.translatorService.translate(ptText, this.lang);
  }

  changeState() {
    this.goToNew.emit();
  }

  async getCompanyUserGroup(): Promise<void> {
    try {
      const {userGroups} = await this.authBackend.getCompanysUserGroups().toPromise();
      this._store.dispatch(setUserGroupAction({payload: userGroups}));
    } catch (error) {
      console.error(error);
    }
  }

  editUserGroup(userGroup: UserGroup) {
    this.goToEdit.emit(userGroup);
    this.modalOpen = false;
  }

  async showMembersUserGroup(userGroupId: number, index: number) {
    await this.authBackend.getEmailsUserGroup(userGroupId).subscribe(({userGroups}) => {
      this.emailInUserGroup = userGroups.email_in_user_group;
      this.showMembers = !this.showMembers;
      this.showMembersIndex = index;
      this.actualGroupId = userGroupId;
    })
  }

  showConfirmDelete(index: number) {
    this.confirmDelete = true;
    this.confirmDeleteIndex = index;
  }

  deleteUserGroup(userGroupId: number) {
    this.authBackend.removeUserGroup(userGroupId).subscribe(() => {
      const userGroups = this.userGroups.filter((userGroup) => {
        return userGroup.id !== userGroupId;
      });
      this.store.dispatch(setUserGroupAction({payload: userGroups}));
      this.confirmDelete = false;
      this.deletedEmitter.emit();
      this.warningService.toastrSuccess(this.t('Grupo deletado com sucesso.'));
    }, err => {
      this.warningService.toastrError(this.t(err.error));
    })
    this.showRemoveUserGroup = false;
  }

  changeStateDeleteMember(index: number) {
    this.deleteMemberText = !this.deleteMemberText;
    this.deleteMemberIcon = !this.deleteMemberIcon;
    this.memberIndex = index
  }

  deleteMember(userGroupId: number, email: string) {
    this.authBackend.removeMemberFromUserGroup(userGroupId, email).subscribe(() => {
        this.authBackend.getEmailsUserGroup(userGroupId).subscribe(({userGroups}) => {
          this.emailInUserGroup = userGroups.email_in_user_group;
          this.warningService.toastrSuccess(this.t('Membro deletado do grupo com sucesso.'));
          this.getCompanyUserGroup()
        });
        this.deletedEmitter.emit();
      },
      err => {
        if (err) {
          console.error(err)
          this.warningService.toastrError(this.t(err.error));
        }
      })
  }

  openModal(userGroup: UserGroup) {
    this.actualUserGroup = userGroup;
    this.clickedUserGroup = userGroup;
    this.modalOpen = true;
  }

  closeModal() {
    this.modalOpen = false;
  }

  toggleModal(userGroup: UserGroup) {
    if (this.modalOpen && this.clickedUserGroup === userGroup) {
      this.closeModal();
    } else {
      this.openModal(userGroup);
    }
  }

  openModalDeleteUserGroup() {
    this.showRemoveUserGroup = !this.showRemoveUserGroup;
    this.modalOpen = false;
  }

  closeModalDeleteUserGroup() {
    this.showRemoveUserGroup = false;
  }

  async openModalRemoveUsers(userGroup: UserGroup, i: number) {
    await this.showMembersUserGroup(userGroup.id, i);
    this.showModalRemoveUsers = true;
    this.actualUserGroup = userGroup;
  }

  closeModalRemoveUsers() {
    this.showModalRemoveUsers = false;
    this.emailInUserGroup = [];
  }
}
