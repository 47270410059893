<div class="container-phone-international" [style.width]="widthContainerPhone"
     [ngClass]="{'mat-form-field-invalid': isValid}">
  <mat-form-field class="phone_number" floatLabel="always" [appearance]="appearance">
    <mat-label>{{'Telefone (DDD + número)' | translate}}</mat-label>

    <ngx-mat-intl-tel-input
      matInput
      placeholder="Telefone"
      #phoneInternationalInput
      [preferredCountries]="preferredCountries"
      [enablePlaceholder]="true"
      [enableSearch]="true"
      [(ngModel)]="number"
      [disabled]="disabled"
      (ngModelChange)="handleChange()"
      (countryChanged)="handleChange()"
      class="phone-international-test-id"
    >
    </ngx-mat-intl-tel-input>
    <mat-hint *ngIf="!disabled">{{'Exemplo' | translate}}
      . {{ phoneInternationalInput?.selectedCountry.placeHolder }}</mat-hint>
  </mat-form-field>
</div>
