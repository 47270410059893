<mat-form-field floatLabel="never" appearance="outline" style="max-width: 100%;">
  <mat-label>{{placeholder | translate}}</mat-label>
  <input matInput
         style="width: 100%;"
         [(ngModel)]="data"
         (ngModelChange)="handleChange($event)"
         [readonly]="true"
         [matDatepicker]="picker"
         (click)="picker.open()"
  >
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker [calendarHeaderComponent]="customHeader" (formdata)="data"></mat-datepicker>
</mat-form-field>
