import {Action, createReducer, on} from '@ngrx/store';
import {setLoadingModalActivateAccount, setOpenActiveAccountModal} from './modal-activate-account.action';
import {ModalActivateAccountProps} from './modal-activate-account.model';

export const INITIAL_STATE: ModalActivateAccountProps = {
  loadingModalActivateAccount: false,
  openActiveAccountModal: false
};

const _modalActivateAccountReducer = createReducer(
  INITIAL_STATE,
  on(setLoadingModalActivateAccount, (state, { payload }) => {
    return {...state, loadingModalActivateAccount: payload};
  }),
  on(setOpenActiveAccountModal, (state, { payload }) => {
    return {...state, openActiveAccountModal: payload};
  }),
);

export function modalActivateAccountReducer(state: ModalActivateAccountProps, action: Action): ModalActivateAccountProps {
  return _modalActivateAccountReducer(state, action);
}
