import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-menu-bottomsheet',
  templateUrl: './menu-bottomsheet.component.html',
  styleUrls: ['./menu-bottomsheet.component.scss']
})
export class MenuBottomsheetComponent {
  @Input() actions = [];
  @Input() title = '';
  @Output() closeModalEmitter = new EventEmitter<any>();
  close() {
    this.closeModalEmitter.emit();
  }
}
