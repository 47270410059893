<button
  mat-raised-button
  [class]="'zs-button ' + size"
  [disabled]="disable"
  [ngClass]="[typeButton,iconDirection,loading ? 'loading' : '',!loading && check? 'check':'',
  border === 'solid'?'zs-solid-border':'zs-dashed-border']"
  [matTooltip]="matTooltip ? (matTooltip | translate ): ''"
  [type]="type"
  class="zs-download-buttom"
>
  <div class="zs-icon-div" [ngClass]="withBackground ? 'zs-backgroung-icon' : ''">
    <mat-icon [ngClass]="iconSize ? iconSize : 'icon-medium'"
              *ngIf="icon !== '' && !loading && !check" [svgIcon]="icon"></mat-icon>
    <mat-icon class="zs-icon" *ngIf="loading" svgIcon="circular-loading"></mat-icon>
    <mat-icon *ngIf="!loading && check" svgIcon="check"></mat-icon>
  </div>
  <ng-template [ngIf]="!loading && !check || icon !== ''">
    {{label | translate}}
  </ng-template>
</button>
