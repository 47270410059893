import {Injectable} from '@angular/core';
import {SubscriptionProps} from '@client/settings-payment/settings-payment.model';

@Injectable({
  providedIn: 'root'
})
export class GetPlanNameService {

  constructor() { }


  takePlan(allPlans: SubscriptionProps[]) {
    const [ searchMainPlan ] = allPlans.map(p => p.plan).filter(plans => plans.tipo === 'main');
    return searchMainPlan.name;
  }
}
