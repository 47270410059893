import {ActionsRequestMemberships, ActionTypesMemberships} from '../actions/memberships.actions';
import {MembershipsModel} from '../models/memberships.model';

export const INITIAL_STATE: MembershipsModel = {
  Memberships: []
};

export const requestMembershipsReducer = (state = INITIAL_STATE, action: ActionsRequestMemberships) => {
  switch (action.type) {
    case ActionTypesMemberships.ADDMEMBERSHIPS: {
      return  {
        ...state,
        Memberships: action.payload,
      };
    }
    case ActionTypesMemberships.CLEANMEMBERSHIPS: {
      return  {
        ...state,
        Memberships: null,
      };
    }
    default: return state;
  }
};
