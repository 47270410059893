// TODO todas as chaves e valores no dicionario devem ser minúsculas, formatação de case deve ser gerenciada pelo CSS com text-transformation, isso é importante inclusive pra acessibilidade
// ex: Finalizar e FINALIZAR deveriam estar na mesma chave "finalizar", não duplicados
// ps: antes de criar um indice novo no dicionario, sempre verificar se já não existe o indice pois diferentemente da estrutura em array, a estrutura em JSON não permite indices duplicados
// noinspection JSNonASCIINames

// @ts-ignore
export const DICTIONARY = {
  'Carregando documento': {
    es: 'Cargando documento',
    en: 'Loading document',
    fr: 'Document de chargement',
  },
  'Aguarde ou': {
    es: 'Espera o',
    en: 'Wait or',
    fr: 'Attendre ou',
  },
  'baixe aqui': {
    es: 'descargar aqui',
    en: 'download here',
    fr: 'télécharger ici',
  },
  'Redefinir zoom': {
    es: 'Restablecer zoom',
    en: 'Reset zoom',
    fr: 'Réinitialiser le zoom',
  },
  'Ajustar à largura': {
    es: 'Ajustar el ancho',
    en: 'Fit to the width',
    fr: 'Ajusté à la largeur',
  },
  'Aumentar o zoom': {
    es: 'Aumentar zoom',
    en: 'Zoom in',
    fr: 'Agrandir',
  },
  'Diminuir o zoom': {
    es: 'Diminuir zoom',
    en: 'Zoom out',
    fr: 'Dézoomer',
  },
  'Baixar documento': {
    es: 'Descargar documento',
    en: 'Download document',
    fr: 'Document de téléchargement',
  },
  Assinar: {
    es: 'Firmar',
    en: 'Sign',
    fr: 'Signe',
  },
  Voltar: {
    es: 'Regresar',
    en: 'Back',
    fr: 'Retour',
  },
  'O PDF': {
    es: 'El PDF',
    en: 'The PDF',
    fr: 'Le PDF',
  },
  'Confirme seus dados.': {
    es: 'Confirma tus datos.',
    en: 'Confirm your details.',
    fr: 'Confirmez vos coordonnées.',
  },
  'Nome completo': {
    es: 'Nombre completo',
    en: 'Full Name',
    fr: 'Votre nom',
  },
  'E-mail': {
    es: 'Correo electrónico',
    en: 'E-mail',
    fr: 'Adresse Email',
  },
  País: {
    es: 'País',
    en: 'Country',
    fr: 'Pays',
  },
  Celular: {
    es: 'Celular',
    en: 'Mobile',
    fr: 'Numéro de portable',
  },
  'Celular (DDD + Número)': {
    es: 'Celular',
    en: 'Mobile',
    fr: 'Numéro de portable',
  },
  continuar: {
    es: 'continuar',
    en: 'continue',
    fr: 'continuer',
  },
  'Assine abaixo.': {
    es: 'Firma abajo.',
    en: 'Sign below.',
    fr: 'Signe ci-dessous.',
  },
  Finalizar: {
    es: 'Finalizar',
    en: 'Finish',
    fr: 'Finition',
  },
  'Por favor, digite o celular': {
    es: 'Por favor, ingresa tu número de celular',
    en: 'Please, enter your cell phone',
    fr: 'S\'il vous plaît, entrez votre téléphone portable',
  },
  'Só são aceitos arquivos no formato PDF ou Word.': {
    es: 'Sólo se aceptan archivos en formato PDF o Word.',
    en: 'Only files in PDF or Word format are accepted.',
    fr: 'Seuls les fichiers en PDF ou en format Word sont acceptés.',
  },
  'Só são aceitos arquivos no formato PDF.': {
    es: 'Solo se aceptan archivos en formato PDF.',
    en: 'Only files in PDF format are accepted.',
    fr: 'Seuls les fichiers au format PDF sont acceptés.',
  },
  'contém mais de $0MB. Comprima ou divida o PDF em partes menores': {
    es: 'contienge más de $0 MB. Comprime o divide el PDF en partes más pequeñas',
    en: 'contains more than $0MB. Compress or split the PDF into smaller parts',
    fr: 'Contient plus de $0MB. Compresser ou diviser le PDF en pièces plus petites à l\'aide du site Web ilovepdf.com',
  },
  'Por favor, digite um e-mail válido': {
    es: 'Por favor, introduce un correo válido',
    en: 'Please, type a valid email',
    fr: 'S\'il vous plaît, saisissez un e-mail valide',
  },
  Email: {
    es: 'Correo',
    en: 'Email',
    fr: 'Adresse Email',
  },
  'Por favor, digite seu nome completo': {
    es: 'Por favor, ingresa tu nombre completo',
    en: 'Please, type your fullname',
    fr: 'Merci d\'entrer votre nom en entier',
  },
  'Por favor, preencha todos os dados de identificação.': {
    es: 'Por favor, diligencia todos los datos de identificación.',
    en: 'Please fill in all information.',
    fr: 'Veuillez remplir toutes les informations.',
  },
  'Uma vez inserido pelo signatário ou remetente do documento, o e-mail não pode mais ser alterado.': {
    es: 'Una vez escrito por el firmante o remitente del documento, el e-mail no puede ser alterado',
    en: 'Once entered by the signer or sender of the document, the email can no longer be changed.',
    fr: 'Une fois entré par le signataire ou l\'expéditeur du document, l\'e-mail ne peut plus être modifié.',
  },
  'Insira o código de 6 letras que enviamos para': {
    es: 'Digita el código de 6 letras que enviamos para',
    en: 'Enter the 6-letter code we sent to',
    fr: 'Saisissez le code à 6 chiffres que nous vous envoyons sur votre',
  },
  'Insira o código de 6 letras que enviamos por email para': {
    es: 'Digita el código de 6 letras que te enviamos por correo electrónico',
    en: 'Enter the 6-letter code that we emailed to',
    fr: 'Entrez le code à 6 lettres auquel nous avons envoyé un e-mail',
  },
  'Insira o código de 6 letras que enviamos por SMS para': {
    es: 'Digita el código de 6 letras que te enviamos por SMS',
    en: 'Enter the 6-letter code we send by SMS to',
    fr: 'Entrez le code à 6 lettres que nous envoyons par SMS à',
  },
  'Insira o código de 6 letras que enviamos por WhatsApp para': {
    es: 'Digita el código de 6 letras que te enviamos por WhatsApp a',
    en: 'Enter the 6 letter code that we send via WhatsApp to',
    fr: 'Entrez le code de 6 lettres que nous envoyons via WhatsApp à',
  },
  'Não é possível desabilitar o telefone devido ao método de autenticação. E-mail foi habilitado novamente.': {
    es: 'No es posible deshabilitar el teléfono debido al método de autenticación. El correo electrónico se ha habilitado nuevamente.',
    en: 'It\'s not possible to disable the phone due to the authentication method. Email has been enabled again.',
    fr: 'Il n\'est pas possible de désactiver le téléphone en raison de la méthode d\'authentification. Le courrier électronique a à nouveau été activé.',
  },
  'Reenviar código': {
    es: 'Reenviar código',
    en: 'Resend code',
    fr: 'Renvoyer le code',
  },
  'Aguarde 30 segundos antes de reenviar.': {
    es: 'Espera 30 segundos antes de reenviar.',
    en: 'Wait 30 seconds before resending.',
    fr: 'Attendez 30 secondes avant de le renverser.',
  },
  'Aguarde 10 segundos antes de reenviar.': {
    es: 'Espera 10 segundos antes de reenviar.',
    en: 'Wait 10 seconds before resending.',
    fr: 'Attendez 10 secondes avant de le renverser.',
  },
  'Verifique se o e-mail está correto.': {
    es: 'Verifica si el correo está correcto.',
    en: 'Check if the email is correct.',
    fr: 'Vérifiez si l\'e-mail est correct.',
  },
  'zs-text-uppercase': {
    es: 'Verifica si el teléfono está correcto.',
    en: 'Check if the mobile number is correct.',
    fr: 'Vérifiez si le numéro de mobile est correct.',
  },
  'Ocorreu algum problema. Tente novamente mais tarde.': {
    es: 'Ocurrió algún problema. Intenta más tarde',
    en: 'Something wrong happen. Try again later.',
    fr: 'Quelque chose de mal se produit. Réessayez plus tard.',
  },
  'Código enviado com sucesso.': {
    es: 'Código enviado exitosamente.',
    en: 'Code sent successfully.',
    fr: 'Code envoyé avec succès.',
  },
  'Houve algum erro para enviar o código. Tente mais tarde.': {
    es: 'Hubo algún problema para enviar el código. Intenta más tarde.',
    en: 'There was an error sending the code. Try later.',
    fr: 'Il y a eu une erreur envoyant le code. Essayer plus tard.',
  },
  'Assinado com sucesso!': {
    es: 'Firmado exitosamente.',
    en: 'Successfully signed!',
    fr: 'Signé avec succès!',
  },
  'Você pode baixar o documento original e assinado.': {
    es: 'Puedes descargar el documento original y firmado.',
    en: 'You can download the original and signed document.',
    fr: 'Vous pouvez télécharger le document original et signé.',
  },
  'BAIXAR ORIGINAL': {
    es: 'DESCARGAR ORIGINAL',
    en: 'DOWNLOAD ORIGINAL',
    fr: 'Télécharger Original',
  },
  'BAIXAR ASSINADO': {
    es: 'DESCARGAR FIRMADO',
    en: 'DOWNLOAD SIGNED',
    fr: 'Télécharger signé',
  },
  BAIXAR: {
    es: 'DESCARGAR',
    en: 'DOWNLOAD',
    fr: 'TÉLÉCHARGER',
  },
  'Carregando...': {
    es: 'Cargando...',
    en: 'Loading...',
    fr: 'Chargement...',
  },
  'BAIXAR ASSINADO (carregando...)': {
    es: 'DESCARGAR FIRMADO (cargando...)',
    en: 'DOWNLOAD SIGNED (loading...)',
    fr: 'Télécharger signé (Chargement ...)',
  },
  'Baixar assinado (carregando...)': {
    es: 'Documento firmado (cargando...)',
    en: 'Document (loading...)'
  },
  'Ocorreu algum problema na atualização. Tente atualizar a tela.': {
    es: 'Hubo algún problema con la actualización. Intenta actualizar la pantalla.',
    en: 'There was some problem with the update. Try refreshing the screen.',
    fr: 'Il y avait un problème avec la mise à jour. Essayez de rafraîchir l\'écran.',
  },
  'Ocorreu algum problema. Tente atualizar a tela.': {
    es: 'Hubo algún problema. Intenta actualizar la pantalla.',
    en: 'There was some problem. Try refreshing the screen.',
    fr: 'Il y avait un problème. Essayez de rafraîchir l\'écran.',
  },
  'Envie documentos para assinatura pelo celular.': {
    es: 'Envia documentos para firmar por celular.',
    en: 'Send documents for mobile signature.',
    fr: 'Envoyer des documents pour la signature mobile.',
  },
  'Conhecer ZapSign': {
    es: 'Conoce ZapSign',
    en: 'Learn about ZapSign',
    fr: 'Renseignez-vous sur ZapSign',
  },
  'Envie documentos para assinatura': {
    es: 'Envía documentos para firmas',
    en: 'Send documents for signature',
    fr: 'Envoyer des documents pour signature',
  },
  'Ver Próximo': {
    es: 'Ver Siguiente',
    en: 'See Next',
    fr: 'Voir ensuite',
  },
  'Fotografar selfie': {
    es: 'Tomar foto del rostro',
    en: 'Take a selfie',
    fr: 'Prendre un selfie',
  },
  'Remover foto selfie': {
    es: 'Eliminar foto selfie',
    en: 'Remove selfie photo',
    fr: 'Supprimer la photo de selfie',
  },
  'Fotografar documento pessoal': {
    es: 'Tomar una foto del documento de identidad',
    en: 'Photograph personal document',
    fr: 'Photographiez un document personnel',
  },
  'Remover foto documento': {
    es: 'Eliminar foto documento',
    en: 'Remove document photo',
    fr: 'Supprimer la photo du document',
  },
  'Fotografe seu documento pessoal com CPF como RG ou CNH.': {
    es: 'Toma una foto de tu documento de identidad.',
    en: 'Take a photo of your identification document.',
    fr: 'Prenez une photo de votre pièce d\'identité.',
  },
  'Fotografe seu rosto em um local bem iluminado.': {
    es: 'Toma una foto de tu rosto en un lugar iluminado',
    en: 'Take a photo of your face in a well-lit area',
    fr: 'Prenez une photo de votre visage dans un endroit bien éclairé',
  },
  'Ao prosseguir, concordo com a ': {
    es: 'Al continuar, acepto la ',
    en: 'By proceeding, I agree with the ',
    fr: 'En continuant, vous acceptez la ',
  },
  'Gire a tela se quiser mais espaço.': {
    es: 'Gira la pantalla si necesitas más espacio.',
    en: 'Rotate the screen if you want more space.',
    fr: 'Faites pivoter l\'écran si vous voulez plus d\'espace.',
  },
  'política de privacidade': {
    es: 'politica de privacidad',
    en: 'privacy policy',
    fr: 'politique de confidentialité',
  },
  'Assinatura salva (para alterar, entre em ': {
    es: 'Firma guardada (para cambiar, entra en ',
    en: 'Signature saved (to change, go to ',
    fr: 'Signature enregistrée (pour changer, aller à',
  },
  'seu perfil': {
    es: 'tu perfil',
    en: 'your profile',
    fr: 'votre profil',
  },
  'Você receberá o documento assinado no seu e-mail assim que encerradas as assinaturas ou conforme definido pelo proprietário.': {
    es: 'Recibirás el documento firmado a tu correo electrónico tan pronto como se cumpleten todas las firmas.',
    en: 'You will receive the signed document in your email as soon as signatures are closed or as defined by the owner.',
    fr: 'Vous recevrez le document signé dans votre e-mail dès que les abonnements seront fermés ou définis par le propriétaire.',
  },
  'Tudo bem se não ficar 100% idêntico ao papel.': {
    es: 'Todo bien no estar 100% igual en papel',
    en: 'It is ok if it does not look 100% like the paper',
    fr: 'C\'est ok s\'il ne ressemble pas à 100% comme le papier',
  },
  'Limpar assinatura': {
    es: 'Borrar firma',
    en: 'Clear signature',
    fr: 'Signature claire',
  },
  'Nome da organização': {
    es: 'Nombre de la Organización',
    en: 'Organization name',
    fr: 'Nom de l\'organisation',
  },
  // Start Excel
  'ZapSign | Assinatura eletrônica com validade jurídica': {
    es: 'ZapSign | Firma electrónica con validez jurídica',
    en: 'ZapSign | Legally Binding Electronic Signature',
    fr: 'ZapSign | Signature électronique de liaison légalement',
  },
  'ZapSign Logo': {
    es: 'Logo de ZapSign',
    en: 'ZapSign Logo',
    fr: 'Logo ZapSign',
  },
  'Ícone ZapSign': {
    es: 'Ícono de ZapSign',
    en: 'ZapSign Icon',
    fr: 'Icône zapsign',
  },
  'Ícone Home': {
    es: 'Ícono de Inicio',
    en: 'Home Icon',
    fr: 'Icône de la maison',
  },
  'Ícone Ajuda': {
    es: 'Ícono de ayuda',
    en: 'Help icon',
    fr: 'Icône d\'aide',
  },
  'Ícone Preços': {
    es: 'Ícono de precios',
    en: 'Pricing Icon',
    fr: 'Icône de tarification',
  },
  'Ícone Blog': {
    es: 'Ícono de blog',
    en: 'Blog icon',
    fr: 'Icône de blog',
  },
  'Ícone Área Cliente': {
    es: 'Ícono del área de clientes',
    en: 'Client Area Icon',
    fr: 'Icône de la zone client',
  },
  'Conversa com cliente realizando assinatura eletrônica por WhatsApp utilizando ZapSign': {
    es: 'Chatea con tus usuarios y realiza el proceso de firma electrónica vía WhatsApp usando ZapSign',
    en: 'Chat with your users and make the electronic signature process via WhatsApp using ZapSign',
    fr: 'Discuter avec vos utilisateurs et faire le processus de signature électronique via WhatsApp à l\'aide de Zapsign',
  },
  'Driely, vendedora': {
    es: 'Driely, vendedor',
    en: 'Driely, seller',
    fr: 'Driely, vendeur',
  },
  'Experiência de assinatura eletrônica por smartphone no ZapSign': {
    es: 'Experiencia de firma electrónica a través de tu dispositivo móvil en ZapSign',
    en: 'ZapSign has the best electronic signature experience using your smartphone',
    fr: 'Zapsign possède la meilleure expérience de signature électronique en utilisant votre smartphone',
  },
  Check: {
    es: 'Consultar',
    en: 'Check',
    fr: 'Vérifier',
  },
  'Suba o documento': {
    es: 'Carga tu documento',
    en: 'Upload your document',
    fr: 'Téléchargez votre document',
  },
  'Compartilhe o link': {
    es: 'Compartir link',
    en: 'Share link',
    fr: 'Partager le lien',
  },
  'Aguarde a assinatura eletrônica': {
    es: 'Esperar la firma electrónica',
    en: 'Wait for the electronic signature',
    fr: 'Attendez la signature électronique',
  },
  'Ícone Menu': {
    es: 'Ícono del menú',
    en: 'Menu icon',
    fr: 'Icône de menu',
  },
  'Colete assinaturas de forma simples e compatível com celular. Economize tempo, papel e dinheiro, tudo isso com validade jurídica.': {
    es: 'Simplifica los procesos de firma de tu empresa. Crea, envía, firma y almacena documentos con validez legal',
    en: 'Collect signatures in a simple and seamless way. Save time, paper and money, all with legal validity.',
    fr: 'La signature électronique la plus conviviale. Économisez du temps, du papier et de l\'argent, le tout avec une validité légale.',
  },
  'Lei Geral de Proteção de Dados': {
    es: 'Ley General de Protección de Datos',
    en: 'General Data Protection Regulation',
    fr: 'Règlement général sur la protection des données',
  },
  Início: {
    es: 'Inicio',
    en: 'Start',
    fr: 'Commencer',
  },
  Ajuda: {
    es: 'Ayuda',
    en: 'Help',
    fr: 'Aide',
  },
  'Preciso de ajuda': {
    es: 'Necesito ayuda',
    en: 'I need help',
    fr: 'j\'ai besoin d\'aide',
  },
  'Olá, preciso de ajuda, gostaria de falar com a equipe de suporte da ZapSign.': {
    es: 'Hola, necesito ayuda, me gustaría hablar con el equipo de soporte de ZapSign.',
    en: 'Hi, I need help, I would like to speak to the ZapSign support team.',
    fr: 'Salut, j\'ai besoin d\'aide, je voudrais parler à l\'équipe de support Zapsign.',
  },
  'no menu lateral': {
    es: 'en el menú lateral',
    en: 'in the side menu',
    fr: 'dans le menu',
  },
  'Experimente grátis': {
    es: 'Prueba ZapSign gratis',
    en: 'Try ZapSign for free',
    fr: 'Essayez ZapSign gratuitement',
  },
  'a ZapSign com os documentos de cortesia liberados em sua conta todo mês': {
    es: 'con los documentos de cortesía que se envían a tu cuenta cada mes',
    en: 'with the courtesy documents released to your account each month',
    fr: 'avec les documents de courtoisie libérés sur votre compte chaque mois',
  },
  'Caso tenha interesse em tomar controle do seu processo de assinatura por completo com documentos ilimitados, múltiplos usuários e suas cores e marca, confira nossos': {
    es: 'Si estás interesado en tomar el control total de tu proceso de firma de documentos ilimitados, múltiples usuarios, y usar tus colores y tu marca, consulta nuestros',
    en: 'If you are interested in taking full control of your signature process with unlimited documents, multiple users and their colors and brand, please consult our',
    fr: 'Si vous êtes intéressé à prendre le contrôle total de votre processus d\'abonnement avec des documents illimités, plusieurs utilisateurs et leurs couleurs et marque, veuillez consulter notre',
  },
  Preços: {
    es: 'Precios',
    en: 'Pricing',
    fr: 'Prix',
  },
  Blog: {
    es: 'Blog',
    en: 'Blog',
    fr: 'Blog',
  },
  Entrar: {
    es: 'Iniciar sesión',
    en: 'Sign in',
    fr: 'Se connecter',
  },
  'A assinatura eletrônica sem mistérios': {
    es: 'La firma electrónica sin misterios',
    en: 'The electronic signature without mysteries',
    fr: 'La signature électronique sans mystères',
  },
  'Colete assinaturas de forma simples e compatível com celular.': {
    es: 'Recoge firmas de forma sencilla y compatible con los dispositivos móviles.',
    en: 'Collect signatures in a simple and smartphone-compatible way.',
    fr: 'Collectez les signatures d\'une manière simple et compatible pour les smartphones.',
  },
  'Economize tempo, papel e dinheiro,': {
    es: 'Ahorre tiempo, papel y dinero.',
    en: 'Save time, paper and money.',
    fr: 'Économisez du temps, du papier et de l\'argent.',
  },
  'tudo isso com validade jurídica.': {
    es: 'todo con validez jurídica',
    en: 'all of this with legal validity.',
    fr: 'Tout cela avec une validité légale.',
  },
  'Verifique e assine eletronicamente o documento. Economize tempo, papel e dinheiro, tudo isso com validade jurídica.': {
    es: 'Revisa y firma el documento electrónicamente. Ahorre tiempo, papel y dinero, todo ello con validez legal.',
    en: 'Check and electronically sign the document. Save time, paper and money, all with legal validity.',
    fr: 'Vérifiez et signez électroniquement le document. Économisez du temps, du papier et de l\'argent, le tout avec une validité légale.',
  },
  'ZapSign | Assinatura eletrônica de documentos': {
    es: 'ZapSign | Firma electrónica de documentos',
    en: 'ZapSign | Electronic signature for documents',
    fr: 'ZapSign | Signature électronique pour les documents',
  },
  'Continuar com o ': {
    es: 'Continuar con el ',
    en: 'Continue with',
    fr: 'Continue avec',
  },
  'Entrar com Google': {
    es: 'Iniciar sesión con Google',
    en: 'Sign in with Google',
    fr: 'Connectez-vous avec Google',
  },
  'Entrar com Facebook': {
    es: 'Iniciar sesión con Facebook',
    en: 'Sign in with Facebook',
    fr: 'Connectez-vous avec Facebook',
  },
  'Entre na sua conta': {
    es: 'Iniciar sesión',
    en: 'Log in to your account',
    fr: 'Connectez-vous à votre compte',
  },
  'Bem-vindo(a) de volta!': {
    es: 'Bienvenido de nuevo.',
    en: 'Welcome back!',
    fr: 'Content de te revoir!',
  },
  ou: {
    es: 'o',
    en: 'or',
    fr: 'ou',
  },
  Senha: {
    es: 'Contraseña',
    en: 'Password',
    fr: 'Mot de passe',
  },
  ENTRAR: {
    es: 'INICIAR SESIÓN',
    en: 'SIGN IN',
    fr: 'SE CONNECTER',
  },
  'Esqueci minha senha': {
    es: 'He olvidado mi contraseña',
    en: 'I forgot my password',
    fr: 'Mot de passe oublié',
  },
  'Não tem uma conta ainda?': {
    es: '¿Aún no tienes una cuenta?',
    en: 'Don\'t you have an account?',
    fr: 'Vous n\'avez pas de compte?',
  },
  'CRIAR CONTA GRÁTIS': {
    es: 'CREAR UNA CUENTA GRATUITA',
    en: 'CREATE FREE ACCOUNT',
    fr: 'CRÉER UN COMPTE GRATUITEMENT',
  },
  'Criar conta': {
    es: 'Crear cuenta',
    en: 'Create account',
    fr: 'Créez votre compte',
  },
  'Experimente grátis com até 5 documentos todo mês.': {
    es: 'Prueba gratis con hasta 5 documentos al mes.',
    en: 'Try it for free with up to 5 documents per month.',
    fr: 'Essai gratuit avec un maximum de 5 documents par mois.',
  },
  'Ex: Comercial 2021-07': {
    es: 'Ej: Comercial 2021-07',
    en: 'Eg: Commercial 2021-07',
    fr: 'Par exemple: Commercial 2021-07',
  },
  NOVO: {
    es: 'NUEVO',
    en: 'NEW',
    fr: 'NOUVEAU',
  },
  'para adicionar seu primeiro documento para assinatura.': {
    es: 'para agregar tu primer documento para firmar.',
    en: 'to add your first document for signature.',
    fr: 'Pour ajouter votre premier document pour la signature.',
  },
  'Prefere aprender sobre a plataforma com um vídeo? Assista nosso tutorial abaixo!': {
    es: '¿Prefieres conocer la plataforma con un video? ¡Mira nuestro tutorial a seguir!',
    en: 'Prefer to learn about the platform with a video? Watch our tutorial below!',
    fr: 'Vous préférez en savoir plus sur la plate-forme avec une vidéo? Regardez notre tutoriel ci-dessous!',
  },
  'Se tiver dúvidas, clique em': {
    es: 'Si tienes alguna duda, haz clic en ',
    en: 'If in doubt, click on',
    fr: 'En cas de doute, cliquez sur',
  },
  Documentos: {
    es: 'Documentos',
    en: 'Documents',
    fr: 'Documents',
  },
  Modelos: {
    es: 'Plantillas',
    en: 'Templates',
    fr: 'Modèles',
  },
  Configurações: {
    es: 'Ajustes',
    en: 'Settings',
    fr: 'Paramètres',
  },
  'Adicionar nova pasta': {
    es: 'Agregar nueva carpeta',
    en: 'Add new folder',
    fr: 'Ajouter un nouveau dossier',
  },
  'Nome da pasta': {
    es: 'Nombre de la carpeta',
    en: 'Folder name',
    fr: 'Nom de dossier',
  },
  'Adicionar pasta': {
    es: 'Agregar carpeta',
    en: 'Add folder',
    fr: 'Ajouter le dossier',
  },
  'Criar pasta': {
    es: 'Crear carpeta',
    en: 'Create folder',
    fr: 'Créer dossiers',
  },
  'Exportar documentos para Excel': {
    es: 'Exportar documentos a Excel',
    en: 'Export documents to Excel',
    fr: 'Exporter des documents vers Excel',
  },
  'Baixe a lista de documentos da pasta atual em um arquivo Excel. O Excel possuirá duas planilhas (Documentos e Signatários), navegue entre as duas usando as abas inferiores.': {
    es: 'Descarga la lista de documentos de la carpeta actual en un archivo Excel. Excel tendrá dos hojas de trabajo (Documentos y Firmantes), navegue entre las dos utilizando las pestañas inferiores.',
    en: 'Download the list of documents from the current folder into an Excel file. The Excel file will have two sheets (Documents and Signers), navigate between the two using the bottom tabs.',
    fr: 'Téléchargez la liste des documents à partir du dossier actuel dans un fichier Excel. Le fichier Excel aura deux feuilles (documents et signataires), naviguera entre les deux à l\'aide des onglets inférieurs.',
  },
  Cancelar: {
    es: 'Cancelar',
    en: 'Cancel',
    fr: 'Annuler',
  },
  'Baixar Excel': {
    es: 'Descargar Excel',
    en: 'Download Excel',
    fr: 'Télécharger Excel',
  },
  'Dicas:': {
    es: 'Consejos:',
    en: 'Tips:',
    fr: 'Conseils:',
  },
  '1. Para listar os documentos de todas as pastas, clique em "Buscar" e escolha "Buscar na pasta" > "Todas pastas".': {
    es: '1. Para listar los documentos de todas las carpetas, haz click en "Buscar" y selecciona "Buscar en la carpeta" > "Todas las carpetas".',
    en: '1. To list documents from all folders, click "Browse" and select "Search Folder" > "All Folders".',
    fr: '1. Pour répertorier les documents de tous les dossiers, cliquez sur "Parcourir" et sélectionnez "Rechercher le dossier"> "Tous les dossiers".',
  },
  '2. Aprenda como fazer o download em massa de todos documentos.': {
    es: '2. Aprende a descargar de forma masiva todos los documentos.',
    en: '2. Learn how to bulk download all documents.',
    fr: '2. Apprenez à télécharger en vrac tous les documents.',
  },
  'Novo documento': {
    es: 'Nuevo documento',
    en: 'New document',
    fr: 'Nouveau document',
  },
  Novo: {
    es: 'Nuevo',
    en: 'New',
    fr: 'Nouveau',
  },
  novo: {
    es: 'nuevo',
    en: 'new',
    fr: 'nouveau',
  },
  Buscar: {
    es: 'Buscar',
    en: 'Search',
    fr: 'Recherche',
  },
  'Nova pasta': {
    es: 'Nueva carpeta',
    en: 'New folder',
    fr: 'Nouveau dossier',
  },
  'Editar pasta': {
    es: 'Editar carpeta',
    en: 'Edit folder',
    fr: 'Modifier le dossier',
  },
  secreta: {
    es: 'secreta',
    en: 'secret',
    fr: 'secrète',
  },
  Secreta: {
    es: 'Secreta',
    en: 'Secret',
    fr: 'Secrète',
  },
  pública: {
    es: 'pública',
    en: 'public',
    fr: 'publique',
  },
  Pública: {
    es: 'Pública',
    en: 'Public',
    fr: 'Publique',
  },
  pastas: {
    es: 'carpeta',
    en: 'folders',
    fr: 'Dossiers',
  },
  '$0 pastas': {
    es: '$0 carpetas',
    en: '$0 folders',
    fr: 'Dossiers à $0',
  },
  'Buscar:': {
    es: 'Buscar:',
    en: 'Search:',
    fr: 'Recherche:',
  },
  'Buscar documento por número, nome ou signatário': {
    es: 'Buscar documentos por número, nombre o firmante',
    en: 'Search document by number, name or signer',
    fr: 'Rechercher le document par numéro, nom ou signataire',
  },
  Todos: {
    es: 'Todos',
    en: 'All',
    fr: 'Tous',
  },
  Status: {
    es: 'Estado',
    en: 'Status',
    fr: 'Statut',
  },
  'Apenas atual': {
    es: 'Sólo actual',
    en: 'only current',
    fr: 'uniquement courant',
  },
  'Buscar na pasta': {
    es: 'Buscar en la carpeta',
    en: 'Search in folder',
    fr: 'Recherche dans le dossier',
  },
  'Usuário que criou o documento': {
    es: 'Usuario que creó el documento',
    en: 'User who created the document',
    fr: 'Utilisateur qui a créé le document',
  },
  'Mostrando 1-1 de 1 documentos': {
    es: 'Viendo 1-1 de 1 documentos',
    en: 'Showing 1-1 of 1 documents',
    fr: 'Montrant 1-1 sur 1 documents',
  },
  DOCUMENTOS: {
    es: 'DOCUMENTOS',
    en: 'DOCUMENTS',
    fr: 'Documents',
  },
  DOCUMENTO: {
    es: 'DOCUMENTO',
    en: 'DOCUMENT',
    fr: 'Document',
  },
  'Mostrando 1-1 de 1 webhooks': {
    es: 'Viendo 1-1 de 1 webhooks',
    en: 'Showing 1-1 of 1 webhooks',
    fr: 'Montrant 1-1 de 1 webhooks',
  },
  Exportar: {
    es: 'Exportar',
    en: 'Export',
    fr: 'Exporter',
  },
  Página: {
    es: 'Página',
    en: 'Page',
    fr: 'Page',
  },
  'de 1': {
    es: 'de 1',
    en: 'of 1',
    fr: 'de 1',
  },
  aaaa: {
    es: 'aaaa',
    en: 'yyyy',
    fr: 'yyyy',
  },
  'Realizar busca': {
    es: 'Realizar búsqueda',
    en: 'Perform search',
    fr: 'Effectuer la recherche',
  },
  'Buscar documentos': {
    es: 'Buscar documentos',
    en: 'Search documents',
    fr: 'Rechercher des documents',
  },
  'Crie pastas para organizar seus documentos': {
    es: 'Crea carpetas para organizar tus documentos',
    en: 'Create folders to organize your documents',
    fr: 'Créer des dossiers pour organiser vos documents',
  },
  'Exporte os documentos para um Excel': {
    es: 'Exportar los documentos a Excel',
    en: 'Export documents to Excel',
    fr: 'Exporter des documents vers Excel',
  },
  'Ver todos': {
    es: 'Ver todos',
    en: 'See all',
    fr: 'Voir tous',
  },
  'ver todos': {
    es: 'ver todos',
    en: 'see all',
    fr: 'voir tous',
  },
  Pasta: {
    es: 'Carpeta',
    en: 'Folder',
    fr: 'Dossier',
  },
  'Renomear pasta': {
    es: 'Cambiar el nombre de la carpeta',
    en: 'Rename folder',
    fr: 'Renommer le dossier',
  },
  'Excluir pasta': {
    es: 'Eliminar carpeta',
    en: 'Delete folder',
    fr: 'Supprimer le dossier',
  },
  'Não abriu': {
    es: 'No abrió',
    en: 'Did not open',
    fr: 'N\'a pas ouvert',
  },
  'Meu perfil': {
    es: 'Mi perfil',
    en: 'My profile',
    fr: 'Mon profil',
  },
  Organizações: {
    es: 'Organizaciones',
    en: 'Organizations',
    fr: 'Organisations',
  },
  Sair: {
    es: 'Cerrar sesión',
    en: 'Logout',
    fr: 'Se déconnecter',
  },
  'End Recording (Alt+Shift+R)': {
    es: 'End Recording (Alt+Shift+R)',
    en: 'End Recording (Alt+Shift+R)',
    fr: 'Enregistrement final (Alt + Shift + R)',
  },
  'Mouse Pointer': {
    es: 'Mouse Pointer',
    en: 'Mouse Pointer',
    fr: 'Pointeur de souris',
  },
  'Pen (Alt+P)': {
    es: 'Pen (Alt+P)',
    en: 'Pen (Alt+P)',
    fr: 'Pen (Alt + P)',
  },
  'Rectangle (Alt+R)': {
    es: 'Rectangle (Alt+R)',
    en: 'Rectangle (Alt+R)',
    fr: 'Rectangle (alt + r)',
  },
  'Stickers (Alt+S)': {
    es: 'Stickers (Alt+S)',
    en: 'Stickers (Alt+S)',
    fr: 'Autocollants (alt + s)',
  },
  'Eraser (Alt+E)': {
    es: 'Eraser (Alt+E)',
    en: 'Eraser (Alt+E)',
    fr: 'Eraser (Alt + E)',
  },
  'Pause Recording': {
    es: 'Pause Recording',
    en: 'Pause Recording',
    fr: 'Enregistrement de pause',
  },
  'Redefinir senha': {
    es: 'Restablecer contraseña',
    en: 'Reset password',
    fr: 'Réinitialiser le mot de passe',
  },
  'Você receberá um e-mail de recuperação na sua caixa de entrada.': {
    es: 'Recibirás un correo electrónico para recuperar tu contraseña.',
    en: 'You will receive a password recovery email. Please check your inbox',
    fr: 'Vous recevrez un e-mail de récupération de mot de passe. Veuillez cocher votre boîte de réception',
  },
  'REDEFINIR SENHA': {
    es: 'RESTABLECER CONTRASEÑA',
    en: 'RESET PASSWORD',
    fr: 'RÉINITIALISER LE MOT DE PASSE',
  },
  'Dúvidas ou problemas? Entre em contato conosco em support@zapsign.com.br': {
    es: '¿Dudas o problemas? Comunícate con support@zapsign.com.br',
    en: 'Questions or problems? Contact support@zapsign.com.br',
    fr: 'Avez-vous des questions ou des problèmes? Contactez-nous à support@zapsign.com.br',
  },
  '- $0 (Você)': {
    es: '- $0 (Tú)',
    en: '- $0 (You)',
    fr: '- $0 (vous)',
  },
  'Proprietário da conta': {
    es: 'Propietario de la cuenta',
    en: 'Account owner',
    fr: 'Propriétaire du compte',
  },
  'Nível de acesso': {
    es: 'Nivel de acceso',
    en: 'Access level',
    fr: 'Niveau d\'accès',
  },
  'Trocar proprietário': {
    es: 'Cambiar propietario',
    en: 'Change owner',
    fr: 'Changer le propriétaire',
  },
  'Não é possível remover o proprietário da conta': {
    es: 'No se puede eliminar el propietario de la cuenta',
    en: 'Unable to remove account owner',
    fr: 'Impossible de supprimer le propriétaire du compte',
  },
  'e outras coisas': {
    es: 'y otras cosas',
    en: 'and other things',
    fr: 'et d\'autres choses',
  },
  'Insira o nome da sua empresa ou marca': {
    es: 'Introduce el nombre de tu empresa o marca',
    en: 'Enter your company name or brand',
    fr: 'Entrez le nom ou la marque de votre entreprise',
  },
  'Insira o e-mail do novo usuário': {
    es: 'Introduce el correo electrónico del nuevo usuario',
    en: 'Enter new user email',
    fr: 'Entrez un nouvel e-mail utilisateur',
  },
  'Ex: ABC Advogados': {
    es: 'Ej: ABC Abogados',
    en: 'E.g.: ABC Lawyers',
    fr: 'Par exemple: avocats ABC',
  },
  'Selo de Sustentabilidade ZapSign': {
    es: 'Sello de sostenibilidad ZapSign',
    en: 'ZapSign Sustainability Stamp',
    fr: 'Sceau de durabilité zapsign',
  },
  Organização: {
    es: 'Organización',
    en: 'Organization',
    fr: 'Organisation',
  },
  Plano: {
    es: 'Plan',
    en: 'Plan',
    fr: 'Plan',
  },
  Integração: {
    es: 'Integración',
    en: 'Integration',
    fr: 'L\'intégration',
  },
  Lixeira: {
    es: 'Papelera',
    en: 'Trash',
    fr: 'Poubelle',
  },
  'Organização sem nome': {
    es: 'Organización sin nombre',
    en: 'Undefined organization',
    fr: 'Organisation non définie',
  },
  'API desabilitada.': {
    es: 'API deshabilitada.',
    en: 'API disabled.',
    fr: 'API désactivée.',
  },
  'Usuário removido com sucesso.': {
    es: 'Usuario eliminado correctamente.',
    en: 'User removed successfully.',
    fr: 'L\'utilisateur a supprimé avec succès.',
  },
  'Agora você está na': {
    es: 'Ahora estas en',
    en: 'Now you are in',
    fr: 'Maintenant tu es dans',
  },
  Usuários: {
    es: 'Usuarios',
    en: 'Users',
    fr: 'Utilisateurs',
  },
  'Adicione e gerencie usuários da sua organização.': {
    es: 'Añade y gestiona a los usuarios de tu organización.',
    en: 'Add and manage users from your organization.',
    fr: 'Ajouter et gérer les utilisateurs de votre organisation.',
  },
  'Adicionar usuário': {
    es: 'Agregar usuario',
    en: 'Add user',
    fr: 'Ajouter des utilisateurs',
  },
  'Grupos de Usuários': {
    es: 'Grupos de Usuarios',
    en: 'User Groups',
    fr: 'Groupes d\'utilisateurs',
  },
  'Novo Grupo': {
    es: 'Nuevo Grupo',
    en: 'New Group',
    fr: 'Nouveau groupe',
  },
  'Novo grupo': {
    es: 'Nuevo grupo',
    en: 'New group',
    fr: 'Nouveau groupe',
  },
  'Parece que você ainda não possui nenhum grupo de usuários definido. Clique em': {
    es: 'Todavía no tienes ningún grupo de contactos definido. Haz clic en',
    en: 'It looks like you don\'t have any user groups defined yet. Click',
    fr: 'Il semble que vous n\'ayez pas encore de groupes d\'utilisateurs définis. Cliquez sur',
  },
  'para criar um grupo e personalizar o nível de acesso dele.': {
    es: 'para crear un grupo y selecciona los contactos que van a hacer parte de éste.',
    en: 'to create a group and customize its access level.',
    fr: 'Pour créer un groupe et personnaliser son niveau d\'accès.',
  },
  'Nome do grupo': {
    es: 'Nombre del grupo',
    en: 'Group\'s name',
    fr: 'Nom du groupe',
  },
  'Permissões de acesso aos documentos': {
    es: 'Permisos para acceder a los documentos',
    en: 'Permissions to access documents',
    fr: 'Permissions d\'accès aux documents',
  },
  'Próprios documentos': {
    es: 'Documentos propios',
    en: 'Own documents',
    fr: 'Propres documents',
  },
  'Documentos do grupo': {
    es: 'Documentos del grupo',
    en: 'Group documents',
    fr: 'Documents de groupe',
  },
  'Todos os documentos': {
    es: 'Todos los documentos',
    en: 'All documents',
    fr: 'Tous les documents',
  },
  'Excluir documentos próprios': {
    es: 'Eliminar documentos propios',
    en: 'Delete own documents',
    fr: 'Supprimer ses propres documents',
  },
  'Excluir documentos do grupo': {
    es: 'Eliminar documentos del grupo',
    en: 'Delete documents from the group',
    fr: 'Supprimer les documents du groupe',
  },
  'Excluir documentos da organização': {
    es: 'Eliminar documentos de la organización',
    en: 'Delete organization documents',
    fr: 'Supprimer les documents de l\'organisation',
  },
  'Criar Grupo': {
    es: 'Crear grupo',
    en: 'Create group',
    fr: 'Créer groupe',
  },
  'Tem certeza que deseja excluir este grupo? Essa ação não poderá ser desfeita. Todos os usuários pertencentes ao grupo ele serão automaticamente configurados como Limitados.': {
    es: '¿Estás seguro de eliminar este grupo? Esta acción no se puede deshacer. Todos los usuarios pertenecientes al grupo se configurarán automáticamente como Limitados.',
    en: 'Are you sure you want to delete this group? This action cannot be undone. All users belonging to the group it will be automatically configured as Limited.',
    fr: 'Êtes-vous sûr de vouloir supprimer ce groupe? Cette action ne peut pas être annulée. Tous les utilisateurs appartenant au groupe, il sera automatiquement configuré comme limité.',
  },
  'Manter grupo': {
    es: 'Mantener el grupo',
    en: 'Keep group',
    fr: 'Tenir le groupe',
  },
  'Excluir grupo (sem volta)': {
    es: 'Eliminar grupo (sin retorno)',
    en: 'Delete group (no return)',
    fr: 'Supprimer le groupe (pas de retour)',
  },
  'Suas cores e marca': {
    es: 'Tus colores y tu marca',
    en: 'Your colors and brand',
    fr: 'Vos couleurs et votre marque',
  },
  'Personalize a experiência da sua organização. Escolha a logomarca e a cor dos botões que aparecerão na tela de assinatura dos seus documentos.': {
    es: 'Personaliza la experiencia de tu organización. Elige el logo y el color de los botones que aparecerán en la pantalla de firma de tus documentos',
    en: 'Customize your organization\'s experience. Choose the logo and button colors that will appear on the signature screen for your documents.',
    fr: 'Personnalisez l\'expérience de votre organisation. Choisissez le logo et les couleurs des boutons qui apparaîtront à l\'écran de signature pour vos documents.',
  },
  'Personalize sua logomarca e cor': {
    es: 'Personaliza el logo y el color',
    en: 'Customize your logo and color',
    fr: 'Personnalisez votre logo et votre couleur',
  },
  'Subir logomarca': {
    es: 'Cargar logo',
    en: 'Upload logo',
    fr: 'Télécharger le logo',
  },
  'A logomarca será ajustada à altura de 48 pixels.': {
    es: 'El logo se ajustará a una altura de 48 píxeles.',
    en: 'The logo will be adjusted to a height of 48 pixels.',
    fr: 'Le logo sera ajusté à une hauteur de 48 pixels.',
  },
  'Escolha a cor do botão': {
    es: 'Elige el color del botón',
    en: 'Choose button color',
    fr: 'Choisissez la couleur du bouton',
  },
  'Nome da sua marca': {
    es: 'Nombre de tu marca',
    en: 'Your brand name',
    fr: 'Votre nom de marque',
  },
  'Não foi possível adicionar um slug para sua empresa.': {
    es: 'No pudimos agregar un slug para tu empresa.',
    en: 'We were unable to add a slug for your company.',
    fr: 'Nous n\'avons pas pu ajouter une limace pour votre entreprise.',
  },
  'E-mails enviados aos signatários terão a sua marca.': {
    es: 'Los correos electrónicos enviados a los firmantes llevarán el nombre de tu marca.',
    en: 'Emails sent to signers will include your brand name.',
    fr: 'Les e-mails envoyés aux signataires incluront votre nom de marque.',
  },
  'Pré-visualização': {
    es: 'Previsualización',
    en: 'Preview',
    fr: 'Aperçu',
  },
  ASSINAR: {
    es: 'FIRMAR',
    en: 'SIGN',
    fr: 'SIGNE',
  },
  'E-mails enviados por:': {
    es: 'Correos electrónicos enviados por:',
    en: 'Emails sent by:',
    fr: 'Emails envoyés par:',
  },
  'SALVAR ALTERAÇÕES': {
    es: 'GUARDAR CAMBIOS',
    en: 'SAVE CHANGES',
    fr: 'Enregistrer les modifications',
  },
  Sustentabilidade: {
    es: 'Sostenibilidad',
    en: 'Sustainability',
    fr: 'Durabilité',
  },
  'Mostre que a sua empresa é sustentável. A ZapSign fornece um selo e uma página de sustentabilidade da sua empresa que você pode compartilhar nos seus documentos, site, rede sociais etc.': {
    es: 'Demuestra que tu empresa es sostenible. ZapSign proporciona un sello y una página de sostenibilidad para tu empresa que puedes compartir en tus documentos, sitio web, redes sociales, etc.',
    en: 'Show that your company is sustainable. ZapSign provides a stamp and a old-sustainability page for your company to share on your documents, website, social media, etc.',
    fr: 'Montrez que votre entreprise est durable. Zapsign fournit un sceau et une page de durabilité à votre entreprise pour partager sur vos documents, votre site Web, vos médias sociaux, etc.',
  },
  'Baixar imagem': {
    es: 'Descargar imagen',
    en: 'Download image',
    fr: 'Télécharger l\'image',
  },
  'Insira no seu site:': {
    es: 'Inserta en tu página web:',
    en: 'Insert on your website:',
    fr: 'Insérer sur votre site Web:',
  },
  'Página de sustentabilidade': {
    es: 'Página de sostenibilidad',
    en: 'Sustainability page',
    fr: 'Page de durabilité',
  },
  'Código da sua página (ex. sua-empresa)': {
    es: 'Código de tu página (ej. su-empresa)',
    en: 'Your page code (e.g. your-company)',
    fr: 'Votre code de page (par exemple votre accompagnement)',
  },
  'Utilize apenas letras minúsculas e hífens.': {
    es: 'Utiliza sólo letras minúsculas y guiones.',
    en: 'Use only lowercase letters and hyphens.',
    fr: 'Utilisez uniquement des lettres en minuscules et des traits de traits.',
  },
  Salvar: {
    es: 'Guardar',
    en: 'Save',
    fr: 'Enregistrer',
  },
  'Copiar link': {
    es: 'Copiar enlace',
    en: 'Copy link',
    fr: 'Copier le lien',
  },
  'Visualizar registros dos webhooks disparados': {
    es: 'Ver los registros de los webhooks activados',
    en: 'View logs for triggered webhooks',
    fr: 'Afficher les journaux pour les webhooks déclenchés',
  },
  'Para desenvolvedores que desejam integrar a ZapSign em seu software.': {
    es: 'Para los desarrolladores que desean integrar ZapSign en el software.',
    en: 'For developers who want to integrate ZapSign into their software.',
    fr: 'Pour les développeurs qui souhaitent intégrer Zapsign dans leur logiciel.',
  },
  'Editar token da API': {
    es: 'Editar el API token ',
    en: 'Edit API Token',
    fr: 'Modifier le jeton API',
  },
  'O plano gratuito não possui acesso aos modelos. Conheça nossos planos pagos.': {
    es: 'El plan gratuito no tiene acceso a las plantillas. Consulta nuestros planes pagos.',
    en: 'The free plan does not have access to the templates. Discover our paid plans.',
    fr: 'Le plan gratuit n\'a pas accès aux modèles. Découvrez nos plans payants.',
  },
  'VER PLANOS': {
    es: 'VER PLANES',
    en: 'VIEW PLANS',
    fr: 'VOIR LES PLANS',
  },
  'Como funcionam os Modelos?': {
    es: '¿Cómo funcionan las plantillas?',
    en: 'How do Templates work?',
    fr: 'Comment fonctionnent les modèles?',
  },
  '(ex: dados pessoais e do contrato)': {
    es: '(por ejemplo, datos personales y contractuales)',
    en: '(ex: personal and contract data)',
    fr: '(Ex: données personnelles et contractuelles)',
  },
  'dos seus contratos. É possível cadastrar modelos com ou sem': {
    es: 'de tus contratos. Es posible registrar plantillas con o sin',
    en: 'of their contracts. It is possible to register templates with or without',
    fr: 'de vos contrats. Il est possible d\'enregistrer des modèles avec ou sans',
  },
  'Por favor, aguarde um instante': {
    es: 'Por favor, espera un momento',
    en: 'Please wait a moment',
    fr: 'Patientez s\'il-vous-plait',
  },
  'Crie documentos automaticamente a partir de': {
    es: 'Crea documentos automáticamente usando',
    en: 'Create documents automatically using',
    fr: 'Créez des documents automatiquement en utilisant des',
  },
  'modelos pré-cadastrados': {
    es: 'plantillas re-utilizables',
    en: 'reusable templates',
    fr: 'modèles pré-enregistrés',
  },
  'e quer ganhar velocidade': {
    es: 'y quieren ganar velocidad',
    en: 'and want to gain speed',
    fr: 'et qui souhaitent gagner en rapidité',
  },
  'em vez de criar e subir na ZapSign um documento por vez': {
    es: 'en lugar de crear y cargar en ZapSign un documento a la vez',
    en: 'instead of creating and uploading to ZapSign one document at a time',
    fr: 'au lieu de créer et de télécharger un document à la fois sur ZapSign.',
  },
  'Veja um exemplo da': {
    es: 'Revisa un ejemplo de la',
    en: 'See an example of the',
    fr: 'Voir un exemple du',
  },
  'crie modelos usando seus arquivos': {
    es: 'crea plantillas usando tus archivos',
    en: 'create templates using your',
    fr: '-Créez des modèles en utilisant vos',
  },
  'possível que seu vendedor': {
    es: 'permite que tu vendedor ',
    en: 'possible that your seller',
    fr: 'permettre à votre fournisseur',
  },
  'pelo signatário (ex: dados pessoais e do contrato)': {
    es: 'por el firmante (por ejemplo, datos personales y contractuales)',
    en: 'by the signer (eg personal and contract data)',
    fr: 'par le signataire (par exemple, données personnelles et contractuelles).',
  },
  'de um modelo com campos para preenchimento': {
    es: 'en una plantilla con campos para diligenciar',
    en: 'of a template with fields to fill',
    fr: 'd\'un modèle avec champs à remplir',
  },
  'E veja como você pode criar um modelo dinâmico:': {
    es: 'Y ver cómo puedes crear una plantilla dinámica:',
    en: 'And see how you can create a dynamic template:',
    fr: 'Et voir comment vous pouvez créer un modèle dynamique:',
  },
  'Vantagens dos Modelos:': {
    es: 'Ventajas de las plantillas:',
    en: 'Advantages of using templates:',
    fr: 'Avantages des modèles :',
  },
  'campos para preenchimento': {
    es: 'campos para diligenciar',
    en: 'fields to fill',
    fr: 'champs à remplir',
  },
  '1 (uma) transação': {
    es: '1 (una) transacción',
    en: '1 (one) transaction',
    fr: '1 (une) transaction',
  },
  '200 transações/mês': {
    es: '200 transacciones/mes',
    en: '200 transactions/month',
    fr: '200 transactions / mois',
  },
  '20 transações/mês': {
    es: '20 transacciones/mes',
    en: '20 transactions/month',
    fr: '20 transactions / mois',
  },
  'ou 2400/ano, se plano anual': {
    es: 'o 2400/año, si es un plan anual',
    en: 'or 2400/year, if annual plan',
    fr: 'ou 2400 / an, si le plan annuel',
  },
  'ou 240/ano, se plano anual': {
    es: 'o 240/año, si es un plan anual',
    en: 'or 240/year, if annual plan',
    fr: 'ou 240 / an, si le plan annuel',
  },
  'Ver Planos': {
    es: 'Ver planes',
    en: 'View Plans',
    fr: 'Voir les plans',
  },
  'Ver planos': {
    es: 'Ver planes',
    en: 'View Plans',
    fr: 'Voir les plans',
  },
  'mais velocidade': {
    es: 'más velocidad',
    en: 'more speed',
    fr: 'plus de rapidité',
  },
  'link único': {
    es: 'enlace único',
    en: 'single link',
    fr: 'lien unique',
  },
  'ganhe um': {
    es: 'genera un',
    en: 'get one',
    fr: 'prends en un',
  },
  'e defina os campos de preenchimento que quiser': {
    es: 'y define los campos a diligenciar como desees',
    en: 'and define the fill fields as you like',
    fr: 'mis à jour et définissez les champs à remplir comme vous le souhaitez.',
  },
  'Word/PDF atuais': {
    es: 'Word/PDF actuales',
    en: 'current Word/PDF files',
    fr: 'fichiers Word/PDF',
  },
  'Vantagens dos Modelos': {
    es: 'Ventajas de las plantillas',
    en: 'Advantages of templates',
    fr: 'Avantages des modèles',
  },
  'para fazer um teste': {
    es: 'para hacer una prueba',
    en: 'to try it out',
    fr: 'pour l\'essayer',
  },
  'clicar aqui': {
    es: 'hacer clic aquí',
    en: 'click here',
    fr: 'Cliquez ici',
  },
  'experiência de assinatura': {
    es: 'experiencia de firma',
    en: 'signature experience',
    fr: 'expérience de signature',
  },
  'Cada documento criado com o uso de um Modelo conta como': {
    es: 'Cada documento creado con una Plantilla cuenta como',
    en: 'Each document created using a Template counts as',
    fr: 'Chaque document créé à l\'aide d\'un modèle compte comme',
  },
  'assinatura de um documento idêntico por diversos signatários (ex. ata de reunião), sem campos de preenchimento': {
    es: 'firma de un documento idéntico por varios firmantes (por ejemplo, actas de reuniones), sin diligenciar los campos',
    en: 'signature of an identical document by several signers (eg meeting minutes), without filling fields',
    fr: '-Signature d\'un document identique par plusieurs signataires (par exemple, procès-verbaux de réunions) sans remplir les champs.',
  },
  'Casos de uso mais comuns (exemplos)': {
    es: 'Casos de uso más comunes (ejemplos)',
    en: 'Most common use cases (examples)',
    fr: 'Cas d\'utilisation les plus courants (exemples)',
  },
  'menos trabalho manual e menos erros': {
    es: 'menos trabajo manual y menos errores',
    en: 'less manual work and fewer errors',
    fr: 'moins de travail manuel et moins d\'erreurs',
  },
  'Você também pode': {
    es: 'Tú también puedes',
    en: 'You can also',
    fr: 'Vous pouvez aussi',
  },
  'pré-preencha alguns campos': {
    es: 'diligencie previamente algunos campos',
    en: 'pre-fill some fields',
    fr: 'de pré-remplir certains champs',
  },
  'para compartilhar com diversos signatários, mas cada documento é criado e assinado separadamente': {
    es: 'para compartir con varios firmantes, pero cada documento se crea y se firma por separado',
    en: 'to share with multiple signers, but each document is created and signed separately',
    fr: 'à partager avec plusieurs signataires, car chaque document est créé et signé séparément.',
  },
  'compartilhando um modelo com diversos signatários': {
    es: 'compartiendo una plantilla con múltiples firmantes',
    en: 'sharing a template with multiple signers',
    fr: 'en partageant un modèle avec plusieurs signataires',
  },
  'Ideal para quem possui': {
    es: 'Ideal para los que poseen',
    en: 'Ideal for those who own',
    fr: 'Idéal pour ceux qui ont des',
  },
  'contratos padronizados': {
    es: 'contratos estandarizados',
    en: 'standardized contracts',
    fr: 'contrats standardisés',
  },
  'Casos de uso mais comuns (exemplos):': {
    es: 'Casos de uso más comunes (ejemplos):',
    en: 'Most common use cases (examples):',
    fr: 'Cas d\'utilisation les plus courants',
  },
  'assinatura de contratos em que o signatário preenche seus dados pessoais': {
    es: 'firma de contratos donde el firmante diligencia sus datos',
    en: 'contracts in which the signer fills in his/her personal data',
    fr: 'Signature de contrats dans lesquels le signataire remplit ses données personnelles',
  },
  'compartilhamento do link do modelo em seu site e fluxos de venda': {
    es: 'comparte el enlace de la plantilla en tu sitio web y flujos de venta',
    en: 'share the template link on your website and sales flow',
    fr: 'Partagez le lien de modèle sur votre site Web et le flux de vente',
  },
  'Limite de transações': {
    es: 'Límite de transacciones',
    en: 'Transaction limit',
    fr: 'Limite de transaction',
  },
  '$0/$1': {
    es: '$0/$1',
    en: '$0/$1',
    fr: '$0/$1',
  },
  mês: {
    es: 'mes',
    en: 'month',
    fr: 'mois',
  },
  semestre: {
    es: 'semestre',
    en: 'semester',
    fr: 'semestre',
  },
  'Insira um e-mail válido do novo usuário.': {
    es: 'Ingresa un correo electrónico válido para el nuevo usuario.',
    en: 'Enter a valid email for the new user.',
    fr: 'Entrez un e-mail valide pour le nouvel utilisateur.',
  },
  'Admin (Configurações e Documentos)': {
    es: 'Admin (Ajustes y Documentos)',
    en: 'Admin (Settings and Documents)',
    fr: 'Admin (paramètres et documents)',
  },
  'Membro (Documentos)': {
    es: 'Miembro (Documentos)',
    en: 'Member (Documents)',
    fr: 'Membre (documents)',
  },
  'Limitado (Apenas seus Documentos)': {
    es: 'Limitado (Sólo sus documentos)',
    en: 'Limited (Only own documents)',
    fr: 'Limité (seuls les propres documents)',
  },
  Atenção: {
    es: 'Atención',
    en: 'Attention',
    fr: 'Attention',
  },
  'Demuestre que sua empresa é sostenible. ZapSign proporciona un sello y una página de sostenibilidad para su empresa que puede compartir en sus documentos, sitio web, redes sociales': {
    es: 'Demuestra que tu empresa es sostenible. ZapSign proporciona un sello y una página de sostenibilidad para tu empresa que puedes compartir en tus documentos, sitio web, redes sociales',
    en: 'Demonstrate that your company is sustainable. ZapSign provides a stamp and a old-sustainability page for your company to share in its documents, website, social networks',
    fr: 'Démontrez que votre entreprise est durable. ZapSign fournit un sceau de durabilité et une page de durabilité pour votre entreprise que vous pouvez partager dans vos documents, sur votre site web et sur les réseaux sociaux.',
  },
  ', etc.': {
    es: ', etc.',
    en: ', etc.',
    fr: ', etc.',
  },
  Planos: {
    es: 'Planes',
    en: 'Plans',
    fr: 'Plans',
  },
  'Ver planos de API': {
    es: 'Ver los planes API',
    en: 'View API plans',
    fr: 'Voir plans API',
  },
  'planos disponíveis': {
    es: 'planes disponibles',
    en: 'available plans',
    fr: 'plans disponibles',
  },
  'Você ainda pode criar $0 documentos no plano grátis, considerando seu consumo dos últimos 30 dias.': {
    es: 'Aún puedes crear $0 documentos en el plan gratuito, considerando tu consumo en los últimos 30 días.',
    en: 'You can still create $0 documents in the free plan, considering your consumption in the last 30 days.',
    fr: 'Vous pouvez toujours créer des documents de $0 dans le plan gratuit, compte tenu de votre consommation au cours des 30 derniers jours.',
  },
  Atenciosamente: {
    es: 'Atentamente',
    en: 'Best regards',
    fr: 'Cordialement',
  },
  Equipe: {
    es: 'Equipo',
    en: 'Team',
    fr: 'Équipe',
  },
  'Tome controle do seu processo de assinatura por completo com um de nossos planos.': {
    es: 'Toma el control de tu proceso de firma por completo con uno de nuestros planes.',
    en: 'Take complete control of your signature process with one of our plans.',
    fr: 'Prenez le contrôle total de votre processus d\'abonnement avec l\'un de nos plans.',
  },
  'Plano Completo: documentos ilimitados, 11 usuários, sua marca e acesso aos modelos.': {
    es: 'Plan Completo: documentos ilimitados, 11 usuarios, tu marca y acceso a plantillas.',
    en: 'Complete Plan: unlimited documents, 11 users, your brand and access to templates.',
    fr: 'Plan complet: documents illimités, 11 utilisateurs, votre marque et accès aux modèles.',
  },
  'Plano Profissional: 20 documentos/mês, 6 usuários, sua marca e acesso aos modelos.': {
    es: 'Plan Profesional: 20 documentos/mes, 6 usuarios, tu marca y acceso a plantillas.',
    en: 'Professional Plan: 20 documents/month, 6 users, your brand and access to templates.',
    fr: 'Plan professionnel: 20 documents / mois, 6 utilisateurs, votre marque et l\'accès aux modèles.',
  },
  'Cancele quando quiser. Sem multas.': {
    es: 'Cancela cuando quieras. Sin multas.',
    en: 'Cancel whenever you want. No fines.',
    fr: 'Annuler quand vous le souhaitez. Pas d\'amendes.',
  },
  'Todos os planos são pré-pagos, então caso você cancele, poderá aproveitar o período restante contratado (mensal ou anual). A renovação automática é interrompida com o cancelamento a qualquer momento antes do final do período.': {
    es: 'Todos los planes son prepago, si cancelas podrás disfrutar del periodo contratado restante (mensual o anual). La renovación automática se interrumpe con la cancelación del plan.',
    en: 'All plans are prepaid, so if you unsubscribe, you can enjoy the remaining contracted period (monthly or annual). Automatic renewal stops upon plan cancellation.',
    fr: 'Tous les plans sont prépayés, donc si vous vous désinscrivez, vous pouvez profiter de la période contractée restante (mensuelle ou annuelle). Le renouvellement automatique s\'arrête lors de l\'annulation du plan.',
  },
  'Ainda com dúvidas? Fale com nossos especialistas': {
    es: '¿Todavía tienes preguntas? Habla con nuestros especialistas',
    en: 'Any questions? Talk to our experts',
    fr: 'Vous avez encore des questions ? Parlez-en à nos spécialistes.',
  },
  'Fale com nossos especialistas': {
    es: 'Habla con nuestros especialistas',
    en: 'Talk to our experts',
    fr: 'Parlez à nos experts',
  },
  'Ainda com dúvidas?': {
    es: '¿Todavía tienes preguntas?',
    en: 'Any questions?',
    fr: 'Vous avez encore des questions ?',
  },
  'Indique e ganhe': {
    es: 'Recomienda y gana',
    en: 'Affiliate Program',
    fr: 'Recommandez et gagnez',
  },
  'Indique e ganhe!': {
    es: '¡Recomienda y gana!',
    en: 'Affiliate Program!',
    fr: 'Recommandez et gagnez',
  },
  'https://zapsign.com.br/?recomenda=': {
    es: 'https://zapsign.co/es/?recomienda=',
    en: 'https://zapsign.co/?recommends=',
    fr: 'https://zapsign.co/?recomnds=',
  },
  'Compartilhar no': {
    es: 'Compartir',
    en: 'Share',
    fr: 'Partager',
  },
  'API permite que desenvolvedores de software integrem a ZapSign a outros sistemas.': {
    es: 'La API permite a los desarrolladores de software integrar ZapSign con otros sistemas.',
    en: 'API allows software developers to integrate ZapSign with other systems.',
    fr: 'L\'API permet aux développeurs de logiciels d\'intégrer ZapSign à d\'autres systèmes.',
  },
  'Veja a nossa documentação e conheça os planos de API.': {
    es: 'Consulta nuestra documentación y conoce los planes API.',
    en: 'See our Docs and learn about API plans.',
    fr: 'Voir nos documents et découvrir les plans API.',
  },
  'Token de acesso': {
    es: 'Token de acceso',
    en: 'Access token',
    fr: 'Token d\'accès',
  },
  'Registro de Webhooks': {
    es: 'Registro de Webhooks',
    en: 'Register Webhooks',
    fr: 'Enregistrer les webhooks',
  },
  'Carregar webhooks': {
    es: 'Cargar webhooks',
    en: 'upload webhooks',
    fr: 'Télécharger les webhooks',
  },
  'Todas requisições': {
    es: 'Todas las solicitudes',
    en: 'All requests',
    fr: 'Toutes les demandes',
  },
  Fechar: {
    es: 'Cerrar',
    en: 'Close',
    fr: 'Fermer',
  },
  Webhooks: {
    es: 'Webhooks',
    en: 'Webhooks',
    fr: 'Webhooks',
  },
  'Logs Webhooks': {
    es: 'Registros de webhooks',
    en: 'Webhooks logs',
    fr: 'Logs de webhooks',
  },
  'Habilitar Webhooks': {
    es: 'Habilitar los Webhooks',
    en: 'Enable Webhooks',
    fr: 'Activer les webhooks',
  },
  'Webhooks permitem que outros sistemas sejam notificados quando ocorrerem eventos na ZapSign. Você receberá uma requisição POST em cada uma das URLs cadastradas. Ver documentação.': {
    es: 'Los webhooks permiten notificar a otros sistemas cuando se producen eventos en ZapSign. Recibirás una petición POST en cada una de las URLs registradas. Consulta la documentación.',
    en: 'Webhooks allow other systems to be notified when events occur at ZapSign. You will receive a POST request on each of the registered URLs. See documentation.',
    fr: 'WebHooks permet à d\'autres systèmes d\'être informés lorsque les événements se produisent chez ZapSign. Vous recevrez une demande de poste sur chacune des URL enregistrées. Voir la documentation.',
  },
  'Documentos excluídos': {
    es: 'Documentos eliminados',
    en: 'Deleted documents',
    fr: 'Documents supprimés',
  },
  'Apenas o(a) proprietário(a) da conta possui acesso aos documentos excluídos. É possível recuperar os documentos ou excluir definitivamente.': {
    es: 'Sólo el propietario de la cuenta tiene acceso a los documentos eliminados. Es posible recuperar los documentos o eliminarlos definitivamente.',
    en: 'Only the account owner has access to deleted documents. It is possible to recover the documents or to permanently delete them.',
    fr: 'Seul le propriétaire du compte a accès à des documents supprimés. Il est possible de récupérer les documents ou de les supprimer en permanence.',
  },
  'Ver documentos': {
    es: 'Ver documentos',
    en: 'View documents',
    fr: 'Voir documents',
  },
  'Ler tutoriais de ajuda': {
    es: 'Leer tutoriales de ayuda',
    en: 'Need help? Read our tutorials',
    fr: 'Besoin d\'aide? Lisez nos tutoriels',
  },
  'Nossas perguntas mais frequentes': {
    es: 'Nuestras preguntas más frecuentes',
    en: 'Our most frequently asked questions',
    fr: 'Nos questions les plus fréquemment posées',
  },
  'Assistir vídeo-tutoriais': {
    es: 'Ver vídeos tutoriales',
    en: 'Watch video tutorials',
    fr: 'Regarder des tutoriels vidéo',
  },
  'Assista a ZapSign no Youtube': {
    es: 'Ver ZapSign en Youtube',
    en: 'Watch ZapSign on Youtube',
    fr: 'Regardez ZapSign sur YouTube',
  },
  'Pedir ajuda no WhatsApp': {
    es: 'Pedir ayuda por WhatsApp',
    en: 'Ask for help on WhatsApp',
    fr: 'Demandez de l\'aide sur WhatsApp',
  },
  WhatsApp: {
    es: 'WhatsApp',
    en: 'WhatsApp',
    fr: 'Whatsapp',
  },
  voltar: {
    es: 'Regresar',
    en: 'Back',
    fr: 'Retour',
  },
  Horário: {
    es: 'Calendario',
    en: 'Schedule',
    fr: 'Calendrier',
  },
  'Seg. a sex. horário comercial': {
    es: 'De lunes a viernes, horas de trabajo (GMT-3)',
    en: 'Mon. to Friday, business hours (GMT-3)',
    fr: 'Lun. à vendredi, heures de bureau (GMT-3)',
  },
  'Enviar e-mail': {
    es: 'Enviar correo electrónico',
    en: 'Send email',
    fr: 'Envoyer un e-mail',
  },
  'Alertas de incidentes': {
    es: 'Alertas de incidente',
    en: 'Incident alerts',
    fr: 'Alertes incidentes',
  },
  'Veja o status do nosso sistema': {
    es: 'Ver el estado de nuestro sistema',
    en: 'See the status of our system',
    fr: 'Voir l\'état de notre système',
  },
  'support@zapsign.com.br': {
    es: 'support@zapsign.com.br',
    en: 'support@zapsign.com.br',
    fr: 'support@zapsign.com.br',
  },
  'Sugestões de melhoria': {
    es: 'Sugerencias de mejora',
    en: 'Improvement suggestions',
    fr: 'Suggestions d\'amélioration',
  },
  'Sugira e vote como melhorar a ZapSign': {
    es: 'Sugiere cómo mejorar ZapSign',
    en: 'Suggest and vote how to improve ZapSign',
    fr: 'Suggérer et voter comment améliorer le ZapSign',
  },
  'API Starter: 120 documentos/mês + R$ 0,77 por doc adicional + R$ 0,10 por SMS.': {
    es: 'API Starter: 120 documentos/mes + R$ 0,77 por documento adicional + R$ 0,10 por SMS.',
    en: 'API Starter: 120 documents/month + R$0.77 per additional doc + R$0.10 per SMS.',
    fr: 'Démarrer de l\'API: 120 documents / mois + R$0,77 par DOC supplémentaire + R$0,10 par SMS.',
  },
  'API Basic: 500 documentos/mês + R$ 0,62 por doc adicional + R$ 0,10 por SMS.': {
    es: 'API Basic: 500 documentos/mes + R$ 0,62 por documento adicional + R$ 0,10 por SMS.',
    en: 'API Basic: 500 documents/month + R$ 0.62 per additional doc + R$ 0.10 per SMS.',
    fr: 'API Basic: 500 documents / mois + R 0,62 $ par DOC supplémentaire + R 0,10 $ par SMS.',
  },
  'API Pro: 1500 documentos/mês + R$ 0,47 por doc adicional + R$ 0,10 por SMS.': {
    es: 'API Pro: 1500 documentos/mes + R$ 0,47 por documento adicional + R$ 0,10 por SMS.',
    en: 'API Pro: 1500 documents/month + R$ 0.47 per additional doc + R$ 0.10 per SMS.',
    fr: 'API Pro: 1500 documents / mois + R 0,47 $ par DOC supplémentaire + R 0,10 $ par SMS.',
  },
  'Documentos que criei': {
    es: 'Documentos que he creado',
    en: 'Documents I created',
    fr: 'Documents que j\'ai créés',
  },
  'Receber notificações de': {
    es: 'Recibir notificaciones de',
    en: 'Receive notifications from',
    fr: 'Recevoir des notifications de',
  },
  'Quick Response': {
    es: 'Quick Response',
    en: 'Quick Response',
    fr: 'Réponse rapide',
  },
  'Preferências de notificação': {
    es: 'Preferencias de notificaciones',
    en: 'Notification preferences',
    fr: 'Préférences en matière de notification par courriel',
  },
  'Escolha como você quer ser notificado pela ZapSign.': {
    es: 'Elige cómo quieres ser notificado por ZapSign.',
    en: 'Choose how you want to be notified by ZapSign.',
    fr: 'Choisissez comment vous souhaitez être informé par ZapSign.',
  },
  por: {
    es: 'por',
    en: 'per',
    fr: 'par',
  },
  'Minha assinatura': {
    es: 'Mi firma',
    en: 'My signature',
    fr: 'Ma signature',
  },
  'Crie sua assinatura abaixo usando o mouse (computador) ou o dedo (celular):': {
    es: 'Crea tu firma abajo el mouse (computador) o el dedo (dispositivo móvil):',
    en: 'Create your signature below using your mouse (computer) or your finger (mobile):',
    fr: 'Créez votre signature ci-dessous à l\'aide de votre souris (ordinateur) ou de votre doigt (mobile):',
  },
  qr_code: {
    es: 'qr_code',
    en: 'qr_code',
    fr: 'QR Code',
  },
  'Abrir no celular': {
    es: 'Abrir en el celular',
    en: 'Open on smartphone',
    fr: 'Ouvrir sur mon portable',
  },
  'Imagem da pessoa': {
    es: 'Imagen de persona',
    en: 'Person image',
    fr: 'Image de la personne',
  },
  'Você pode assinar com seu celular. Para isso, escaneie o': {
    es: 'Para firmar con tu teléfono, escanea el ',
    en: 'You can sign with your cell phone. To do this, scan the',
    fr: 'Vous pouvez signer avec votre téléphone portable. Pour ce faire, scannez le',
  },
  qr: {
    es: 'QR',
    en: 'QR',
    fr: 'QR',
  },
  'code abaixo com a câmera do seu celular.': {
    es: 'Código seguiente con la cámara de tu teléfono.',
    en: 'code below with your cell phone camera.',
    fr: 'Code ci-dessous avec votre appareil photo de téléphone portable.',
  },
  'Você pode assinar com seu celular. Para isso, escaneie o QR code abaixo com a câmera do seu celular.': {
    es: 'Puedes firmar con tu celular. Escanea el siguiente código QR con la cámara de tu teléfono.',
    en: 'You can sign with your smartphone. To do this, scan the QR code below with your phone camera.',
    fr: 'Vous pouvez signer avec votre smartphone. Pour ce faire, scannez le code QR ci-dessous avec votre appareil photo de téléphone.',
  },
  'SALVAR ASSINATURA': {
    es: 'GUARDAR FIRMA',
    en: 'SAVE SIGNATURE',
    fr: 'SAUVEGARER SIIGNATURE',
  },
  'Salvar assinatura': {
    es: 'Guardar firma',
    en: 'Save Signature',
    fr: 'Sauvegarer signature',
  },
  'Seus dados': {
    es: 'Tus datos',
    en: 'Your data',
    fr: 'Vos données',
  },
  Nome: {
    es: 'Nombre',
    en: 'Name',
    fr: 'Prénom',
  },
  Surname: {
    es: 'Apellido',
    en: 'Last name',
    fr: 'Nom de famille',
  },
  'Verificar e-mail': {
    es: 'Comprobar el correo electrónico',
    en: 'Verify email address',
    fr: 'Vérifier l\'adresse e-mail',
  },
  'Verifique seu e-mail para ter acesso a todas funcionalidades da ZapSign.': {
    es: 'Comprueba tu correo electrónico para acceder a todas las funciones de ZapSign.',
    en: 'Verify your email to access all features providedby ZapSign.',
    fr: 'Vérifiez votre e-mail pour accéder à toutes les fonctionnalités fournies par Zapsign.',
  },
  'Não verificado': {
    es: 'No verificado',
    en: 'Not verified',
    fr: 'non vérifié',
  },
  verificar: {
    es: 'verificar',
    en: 'verify',
    fr: 'vérifier',
  },
  'Assinatura via API': {
    es: 'Firma a través de API',
    en: 'Signature via api',
    fr: 'Signature via API',
  },
  'Antes, verifique seu e-mail.': {
    es: 'Primero, comprueba tu correo electrónico ',
    en: 'First, verify your email.',
    fr: 'Tout d\'abord, vérifiez votre e-mail.',
  },
  'verifique seu e-mail': {
    es: 'comprueba tu correo electrónico',
    en: 'verify your email',
    fr: 'Vérifiez votre e-mail',
  },
  'Todos documentos': {
    es: 'Todos los documentos',
    en: 'All documents',
    fr: 'Tous les documents',
  },
  'Nenhum documento': {
    es: 'Ningún documento',
    en: 'No documents',
    fr: 'Aucun document',
  },
  'Confira a caixa de entrada (ou spam) do seu e-mail.': {
    es: 'Revisa tu bandeja de entrada o spam del correo electrónico.',
    en: 'Check your email inbox (or spam).',
    fr: 'Cochez votre boîte de réception (ou spam).',
  },
  'Código de verificação': {
    es: 'Código de verificación',
    en: 'Verification code',
    fr: 'Le code de vérification',
  },
  'O código parece incorreto. Veja se inseriu corretamente.': {
    es: 'El código parece incorrecto. Comprueba que lo has introducido correctamente.',
    en: 'The code looks incorrect. Make sure you entered it correctly.',
    fr: 'Le code semble incorrect. Assurez-vous que vous l\'avez entré correctement.',
  },
  'Salvar alterações': {
    es: 'Guardar cambios',
    en: 'Save changes',
    fr: 'Enregistrer les modifications',
  },
  'Alterações salvas com sucesso.': {
    es: 'Los cambios se han guardado correctamente.',
    en: 'Changes saved successfully.',
    fr: 'Changements sauvegardés avec succès.',
  },
  'O seu e-mail foi verificado com sucesso!': {
    es: '¡Tu correo electrónico ha sido verificado con éxito!',
    en: 'Your email has been successfully verified!',
    fr: 'Votre e-mail a été vérifié avec succès!',
  },
  'Seu acesso': {
    es: 'tu acceso',
    en: 'Your access',
    fr: 'Votre accès',
  },
  Verificado: {
    es: 'Verificado',
    en: 'Verified',
    fr: 'Vérifié',
  },
  'assinatura via API': {
    es: 'Firma API',
    en: 'API signature',
    fr: 'Signature via l\'API',
  },
  '(integração). Necessário um plano de API. Para sua segurança, não compartilhe este código com ninguém.': {
    es: '(integración). Se requiere un plan API. Por tu seguridad, no compartas este código con nadie.',
    en: '(integration). An API plan required. For your safety, do not share this code with anyone.',
    fr: '(l\'intégration). Un plan API requis. Pour votre sécurité, ne partagez ce code avec personne.',
  },
  'Você pode habilitar aqui a sua assinatura via API (integração). Necessário um plano de API. Para sua segurança, não compartilhe este código com ninguém.': {
    es: 'Puedes activar tu suscripción a través de API (integración) aquí. Se requiere un plan API. Por tu seguridad, no compartas este código con nadie.',
    en: 'You can enable your subscription via API (integration) here. API plan required. For your safety, do not share this code with anyone.',
    fr: 'Vous pouvez activer votre abonnement via l\'API (intégration) ici. Plan API requis. Pour votre sécurité, ne partagez ce code avec personne.',
  },
  'Você pode habilitar aqui a sua': {
    es: 'Puedes habilitar tu',
    en: 'You can enable your',
    fr: 'Vous pouvez activer votre',
  },
  'Habilitar assinatura por API': {
    es: 'Habilitar firma por API',
    en: 'Enable API Signing',
    fr: 'Activer la signature de l\'API',
  },

  'Você pode utilizar sua assinatura salva em qualquer documento sempre que estiver com o login ativado:': {
    es: 'Una vez inicias sesión, puedes utilizar tu firma guardada en cualquier documento: ',
    en: 'Once you login, you can use your saved signature in any document:',
    fr: 'Une fois que vous vous êtes connecté, vous pouvez utiliser votre signature enregistrée dans n\'importe quel document:',
  },
  'Você pode utilizar sua assinatura salva em qualquer documento': {
    es: 'Puedes usar tu firma guardada en cualquier documento',
    en: 'You can use your signature saved in any document',
    fr: 'Vous pouvez utiliser votre signature enregistrée dans n\'importe quel document',
  },
  'sempre que estiver com o login ativado': {
    es: 'siempre que el inicio de sesión esté habilitado',
    en: 'whenever login is enabled',
    fr: 'chaque fois que la connexion est activée',
  },
  'Sua assinatura salva (para alterar, entre em': {
    es: 'Tu firma guardada (para cambiar, ingresa',
    en: 'Your saved signature (to change, enter',
    fr: 'Votre signature enregistrée (pour changer, entrez',
  },
  'Puedes registrarte con tu teléfono móvil. Para ello, escanee el siguiente código': {
    es: 'Puedes registrarte con tu celular. Para ello, escanea el siguiente código',
    en: 'You can register with your smartphone. Scan the following code',
    fr: 'Vous pouvez vous inscrire auprès de votre smartphone. Scannez le code suivant',
  },
  'con la cámara de su teléfono móvil.': {
    es: 'con la cámara de tu celular.',
    en: 'with your phone camera.',
    fr: 'avec votre appareil photo de téléphone.',
  },
  'Atualizado com sucesso!': {
    es: '¡Actualizado con éxito!',
    en: 'Updated successfully!',
    fr: 'Mis à jour avec succés!',
  },
  'Assinatura por API habilitada/atualizada com sucesso.': {
    es: 'Firma por API activada/actualizada con éxito.',
    en: 'Signature by API enabled/updated successfully.',
    fr: 'Signature par API activée / mise à jour avec succès.',
  },
  'Token de usuário': {
    es: 'Token de usuario',
    en: 'User Token',
    fr: 'Token d\'utilisateur',
  },
  'Atualizar token': {
    es: 'Actualizar token',
    en: 'Update token',
    fr: 'Mise à jour du token',
  },
  Desabilitar: {
    es: 'Desactivar',
    en: 'Disable',
    fr: 'Désactiver',
  },
  'Atenção: preencha o nome e sobrenome acima para funcionamento correto desta funcionalidade.': {
    es: 'Atención: completa el nombre y apellidos arriba para que esta funcionalidad funcione correctamente.',
    en: 'Attention: fill in the name and surname above for this functionality to work correctly.',
    fr: 'Attention: remplissez le nom et le nom de famille ci-dessus pour que cette fonctionnalité fonctionne correctement.',
  },
  Sobrenome: {
    es: 'Apellido',
    en: 'Last name',
    fr: 'Nom de famille',
  },
  'Assinatura por API desabilitada.': {
    es: 'Firma por API desactivada.',
    en: 'API signature disabled.',
    fr: 'Signature de l\'API désactivée.',
  },
  'Você possui acesso apenas a uma organização.': {
    es: 'Sólo tienes acceso a una organización.',
    en: 'You only have access to one organization.',
    fr: 'Vous n\'avez accès qu\'à une seule organisation.',
  },
  'Você possui acesso a mais de uma organização.': {
    es: 'Tienes acceso a más de una organización.',
    en: 'You have access to more than one organization.',
    fr: 'Vous avez accès à plus d\'une organisation.',
  },
  'Proprietário(a):': {
    es: 'Propietario:',
    en: 'Owner:',
    fr: 'Propriétaire:',
  },
  'Organização $0': {
    es: 'Organización $0',
    en: 'Organization $0',
    fr: 'Organisation $0',
  },
  'Qual organização você quer usar agora?': {
    es: '¿Qué organización quieres utilizar ahora?',
    en: 'Which organization do you want to use now?',
    fr: 'Quelle organisation souhaitez-vous utiliser maintenant?',
  },
  'Agora você está na Organização sem nome': {
    es: 'Ahora estás en la Organización sin nombre',
    en: 'You are now in the nameless Organization',
    fr: 'Vous êtes maintenant dans l\'organisation sans nom',
  },
  'Sem nome': {
    es: 'Nombre no definido',
    en: 'Name not defined',
    fr: 'Nom non défini',
  },
  'Qual tipo escolher?': {
    es: '¿Qué tipo elegir?',
    en: 'Which type to choose?',
    fr: 'Quel type choisir ?',
  },
  'modelo PDF': {
    es: 'plantilla PDF',
    en: 'PDF template',
    fr: 'Modèle PDF',
  },
  'não possui campos para preenchimento e é ideal para documentos em que não há variações, como termos de adesão, aceite, livros de presença e atas de reunião.': {
    es: 'no tiene campos para completar y es ideal para documentos donde no hay variaciones, como términos de adhesión, aceptación, libros de asistencia y actas de reuniones.',
    en: 'has no fields to fill out and is ideal for documents where there are no variations, such as terms of adhesion, acceptance, attendance books and meeting minutes.',
    fr: 'n\'a pas de champs à remplir et est idéal pour les documents lorsqu\'il n\'y a pas de variations, telles que les termes d\'adhésion, d\'acceptation, de livres de fréquentation et de procès-verbal de la réunion.',
  },
  ' pode ou não ter campos a serem preenchidos (pelo signatário ou antes do envio). Esses campos são posicionados em caixas durante a criação do modelo e são ideais quando o documento possui tabelas ou formatos complexos como formulários.': {
    es: ' puede tener o no campos para diligenciar (por el firmante o antes de enviarlo). Estos campos se posicionan en cajas durante la creación de la plantilla y es ideal cuando el documento tiene tablas o formatos complejos como formularios.',
    en: ' may or may not have fields to fill in (by the signer or before sending it). These fields are positioned in boxes during the template creation and are ideal when the document contains tables or complex formats like forms.',
    fr: ' peut comporter ou non des champs à remplir (par le signataire ou avant l\'envoi). Ces champs sont placés dans des cases lors de la création du modèle et sont idéaux lorsque le document comporte des tableaux ou des formats complexes tels que des formulaires. ',
  },
  Ativo: {
    es: 'Activo',
    en: 'Active',
    fr: 'Actif',
  },
  Inativo: {
    es: 'Inactivo',
    en: 'Inactive',
    fr: 'Inactif',
  },
  'Em curso': {
    es: 'En curso',
    en: 'Ongoing',
    fr: 'En cours',
  },
  Assinado: {
    es: 'Firmado',
    en: 'Signed',
    fr: 'Signé',
  },
  'Todas pastas': {
    es: 'Todas las carpetas',
    en: 'All folders',
    fr: 'Tous les dossiers',
  },
  // tslint:disable-next-line:max-line-length
  '$0Já conhece a ZapSign? É uma plataforma de assinatura eletrônica totalmente segura e muito fácil de usar!%0aCom ela você pode enviar documentos para o seu cliente assinar pelo celular, sem precisar de papel, motoboy ou impressora. Sabe o que é melhor? Com total validade jurídica. %0aQuer começar a economizar tempo e dinheiro? Entre no site: $1$2 %0aSe cadastrando agora você pode enviar grátis $3 documentos por mês.': {
    // tslint:disable-next-line:max-line-length
    es: '$0 ¿Conoces a ZapSign? ¡Es una plataforma de firma electrónica completamente segura y muy fácil de usar!%0a Con ella puedes enviar documentos para que tu cliente los firme por teléfono celular, sin necesidad de papel, mensajería o impresora. ¿Sabes que es mejor? Con plena vigencia legal. %0a¿Desea empezar a ahorrar tiempo y dinero? Ingresa al sitio web: $1$2 %0aSi te registras ahora, puede enviar $3 documentos por mes de forma gratuita.',
    // tslint:disable-next-line:max-line-length
    en: '$0Have you met ZapSign? It\'s a completely secure and very easy-to-use electronic signature platform!%0aWith it you can send documents for your client to sign by cell phone, without the need for paper, courier or printer. Do you know what is better? With full legal validity. %0aWant to start saving time and money? Enter the website: $1$2 %0aBy signing up now you can send $3 documents per month for free.',
    // tslint:disable-next-line:max-line-length
    fr: '$0, vous avez rencontré ZapSign? C\'est une plate-forme de signature électronique complètement sécurisée et très facile à utiliser!% 0AWith Vous pouvez envoyer des documents pour que votre client soit signé par téléphone portable, sans avoir besoin de papier, de messagerie ou d\'imprimante. Savez-vous ce qui est mieux? Avec une validité juridique complète. % 0Awant pour commencer à économiser du temps et de l\'argent? Entrez le site Web: $1$2 ABSE INSCRIPTION DES MAINTENANTS Vous pouvez envoyer $3 documents par mois gratuitement.',
  },
  'Nenhum documento aqui.': {
    es: 'No hay ningún documento aquí.',
    en: 'No documents here.',
    fr: 'Aucun document ici.',
  },
  'Aguarde...': {
    es: 'Espera...',
    en: 'Wait...',
    fr: 'Attendez...',
  },
  'Criado em $0': {
    es: 'Creado el $0',
    en: 'Created at $0',
    fr: 'Créé le $0',
  },
  'por $0': {
    es: 'por $0',
    en: 'by $0',
    fr: 'par $0',
  },
  Editar: {
    es: 'Editar',
    en: 'Edit',
    fr: 'Modifier',
  },
  'Quero adicionar documentos extras no envelope ou editar signatários': {
    es: 'Quiero agregar documentos adicionales o editar firmantes',
    en: 'I want to add extra documents to the envelope or edit signers',
    fr: 'Je veux ajouter des documents supplémentaires à l\'enveloppe ou éditer les signataires',
  },
  'Não abriu o link': {
    es: 'El enlace no se abrió',
    en: 'Link was not opened',
    fr: 'Le lien n\'a pas été ouvert',
  },
  'Editar signatário(a)': {
    es: 'Editar firmante',
    en: 'Edit signer',
    fr: 'Modifier le signataire',
  },
  Signatário: {
    es: 'Firmante',
    en: 'Signer',
    fr: 'Signataire',
  },
  'E-mail do signatario': {
    es: 'Correo del firmante',
    en: 'Signer\'s email',
    fr: 'Email du signataire',
  },
  'Assinatura na tela': {
    es: 'Firma en la pantalla',
    en: 'Signature on screen',
    fr: 'Signature à l\'écran',
  },
  'Método de autenticação': {
    es: 'Método de autenticación',
    en: 'Authentication method',
    fr: 'Méthode d\'authentification',
  },
  'E-mail do signatário': {
    es: 'Correo electrónico',
    en: 'Signer\'s email',
    fr: 'Email du signataire',
  },
  'E-mail do signatário (opcional)': {
    es: 'Correo electrónico (opcional)',
    en: 'Email (optional)',
    fr: 'Adresse Email',
  },
  'Celular do signatário': {
    es: 'Celular del firmante',
    en: 'Signer\'s phone number',
    fr: 'Numéro de téléphone du signataire',
  },
  'Celular(opcional)': {
    es: 'Celular(opcional)',
    en: 'Mobile (optional)',
    fr: 'Mobile (facultatif)',
  },
  'Função do signatário': {
    es: 'Rol del firmante',
    en: 'Signer\'s role',
    fr: 'Rôle du signataire',
  },
  Selfie: {
    es: 'Selfie',
    en: 'Selfie',
    fr: 'Selfie',
  },
  'Doc. identidade': {
    es: 'Documento de identidad',
    en: 'Identity document',
    fr: 'Carte d\'identité',
  },
  'Cancelar alterações': {
    es: 'Cancelar los cambios',
    en: 'Cancel changes',
    fr: 'Annuler les modifications',
  },
  'Remover signatário': {
    es: 'Eliminar firmante',
    en: 'Remove signer ',
    fr: 'Supprimer le signataire',
  },
  'Atualizar dados': {
    es: 'Actualizar datos',
    en: 'Update data',
    fr: 'Mettre à jour les données',
  },
  'Reposicionar assinaturas': {
    es: 'Reposicionar firmas',
    en: 'Reposition signatures',
    fr: 'Signatures de repositionnement',
  },
  'Adicionar signatário(a)': {
    es: 'Agregar firmante',
    en: 'Add signer',
    fr: 'Ajouter le signataire',
  },
  'Documento principal': {
    es: 'Documento principal',
    en: 'Main document',
    fr: 'Document principal',
  },
  'Documentos complementares': {
    es: 'Documentos complementarios',
    en: 'Additional documents',
    fr: 'Documents supplémentaires',
  },
  'Mover para pasta': {
    es: 'Mover a la carpeta',
    en: 'Move to folder',
    fr: 'Déplacer vers un dossier',
  },
  'Excluir documento': {
    es: 'Eliminar documento',
    en: 'Delete document',
    fr: 'Supprimer le document',
  },
  'Alterar data limite de assinatura': {
    es: 'Cambiar fecha límite de firma',
    en: 'Change signature deadline',
    fr: 'Changer la date limite de signature',
  },
  'Compartilhar link via WhatsApp': {
    es: 'Compartir el enlace por WhatsApp',
    en: 'Share link via WhatsApp',
    fr: 'Partager Link via WhatsApp',
  },
  'Compartilhar link': {
    es: 'Compartir el enlace',
    en: 'Share link',
    fr: 'Lien de partage',
  },
  'Enviar por e-mail': {
    es: 'Enviar por email',
    en: 'Send by email',
    fr: 'Envoyé par courriel',
  },
  'Enviar em lote': {
    es: 'Enviar en lote',
    en: 'Send in batch',
    fr: 'Envoi par lots',
  },
  'Disponível após assinaturas': {
    es: 'Disponible después de firmas',
    en: 'Available after signatures',
    fr: 'Disponible après les signatures',
  },

  'Enviar lembrete via e-mail': {
    es: 'Enviar recordatorio por correo electrónico',
    en: 'Send reminder via email',
    fr: 'Envoyer un rappel par e-mail',
  },
  'Não é possível remover o único signatário do documento. Adicione outro antes de remover este.': {
    es: 'No se puede eliminar al único firmante del documento. Añade otro antes de eliminar este.',
    en: 'It is not possible to remove the only signer from the document. Add another one before removing this one.',
    fr: 'Il n\'est pas possible de supprimer le seul signataire du document. Ajoutez un autre avant de retirer celui-ci.',
  },
  'Pedir que o signatário tire uma selfie (foto do rosto) ao assinar': {
    es: 'Pedir al firmante que se tome un selfie (foto de la cara) al firmar',
    en: 'Ask the signer to take a selfie (face photo) when signing',
    fr: 'Demandez au signataire de prendre un selfie (photo de visage) lors de la signature',
  },
  'Pedir que o signatário tire uma foto do documento de identidade (ex. RG, CNH) ao assinar': {
    es: 'Pide al firmante que tome una foto de su documento de identidad cuando firme',
    en: 'Ask the signer to take a photo of the identity document when signing',
    fr: 'Demandez au signataire de prendre une photo du document d\'identité lors de la signature',
  },
  'Desbloqueado. Clique para bloquear alterações': {
    es: 'Desbloqueado. Haz clic para bloquear los cambios',
    en: 'Unlocked. Click to lock changes',
    fr: 'Débloqué. Cliquez pour verrouiller les modifications',
  },
  'Assinar como testemunha': {
    es: 'Firmar como testigo',
    en: 'Sign as a witness',
    fr: 'Signer comme témoin',
  },
  'Assinar para aprovar': {
    es: 'Firmar para aprobar',
    en: 'Sign to approve',
    fr: 'Signe pour approuver',
  },
  'Assinar para acusar recebimento': {
    es: 'Firmar para confirmar la recepción',
    en: 'Sign to acknowledge receipt',
    fr: 'Signer pour accuser réception',
  },
  'Código por e-mail': {
    es: 'Código por e-mail',
    en: 'Code via email',
    fr: 'Code par e-mail',
  },
  'Código por SMS': {
    es: 'Código por SMS',
    en: 'Code via SMS',
    fr: 'Code par SMS',
  },
  'Assinatura + E-mail': {
    es: 'Firma + Correo',
    en: 'Signature + Email',
    fr: 'Signature + e-mail',
  },
  Assinatura: {
    es: 'Firma',
    en: 'Signature',
    fr: 'Signature',
  },
  Visto: {
    es: 'Iniciales',
    en: 'Initials',
    fr: 'Initiales',
  },
  'Assinatura + SMS': {
    es: 'Firma + SMS',
    en: 'Signature + SMS',
    fr: 'Signature + SMS',
  },
  'Signatário atualizado com sucesso.': {
    es: 'Firmante actualizado con éxito.',
    en: 'Signer updated successfully.',
    fr: 'Le signataire a mis à jour avec succès.',
  },
  'Nome do novo Signatário(a)': {
    es: 'Nombre del nuevo firmante',
    en: 'New signer\'s name',
    fr: 'Nom du nouveau signataire',
  },
  Adicionar: {
    es: 'Agregar',
    en: 'Add',
    fr: 'Ajouter',
  },
  'Signatário(a) adicionado com sucesso.': {
    es: 'Firmante añadido con éxito.',
    en: 'Signer successfully added',
    fr: 'Le signataire a ajouté avec succès',
  },
  'Nome do signatário *': {
    es: 'Nombre del firmante *',
    en: 'Signer name *',
    fr: 'Nom du signataire *',
  },
  'Deseja mesmo remover o signatário?': {
    es: '¿Estás seguro de eliminar al firmante?',
    en: 'Do you really want to remove the signer?',
    fr: 'Voulez-vous vraiment supprimer le signataire?',
  },
  'Sim, remover': {
    es: 'Sí, eliminar',
    en: 'Yes, remove',
    fr: 'Oui, supprimez',
  },
  'Manter signatário': {
    es: 'Mantener signatario',
    en: 'Keep signer',
    fr: 'Garder le signataire',
  },
  'Baixar arquivo de exemplo': {
    es: 'Descargar archivo de muestra',
    en: 'Download sample file',
    fr: 'Télécharger le fichier d\'exemple',
  },
  'Após um signatário assinar com certificado digital, não é mais possível editar assinaturas ou signatários do documento.': {
    es: 'Después de que un firmante firma con un certificado digital, ya no es posible editar las firmas o los firmantes del documento.',
    en: 'After a signer signs with a digital certificate, it is no longer possible to edit the document\'s signatures or signers.',
    fr: 'Une fois qu\'un signataire a signalé un certificat numérique, il n\'est plus possible de modifier les signatures ou les signataires du document.',
  },
  'Após emitir a certificação NOM151, não é mais possível editar assinaturas ou signatários do documento.': {
    es: 'Después de emitir la certificación NOM151, ya no es posible editar las firmas o los firmantes del documento.',
    en: 'After issuing the NOM151 certification, it is no longer possible to edit document signatures or signers.',
    fr: 'Après avoir émis la certification NOM151, il n\'est plus possible de modifier des signatures de documents ou des signataires.',
  },
  'Signatário removido com sucesso. Aguarde um instante...': {
    es: 'El firmante ha sido eliminado con éxito. Por favor, espera un momento...',
    en: 'Signer successfully removed. Wait a minute...',
    fr: 'Le signataire a réussi à supprimer. Attends une minute...',
  },
  'Email enviado com sucesso para $0.': {
    es: 'Correo electrónico enviado con éxito a $0.',
    en: 'Email successfully sent to $0.',
    fr: 'E-mail avec succès envoyé à $0.',
  },
  'Link copiado!': {
    es: 'Enlace copiado',
    en: 'Link copied!',
    fr: 'Lien copié!',
  },
  'Aguarde $0 minutos e $1 segundos para enviar outra solicitação de assinatura.': {
    es: 'Por favor, espera $0 minutos y $1 segundos para enviar otra solicitud de firma.',
    en: 'Wait $0 minutes and $1 seconds to send another signature request.',
    fr: 'Attendez $0 de minutes et $1 pour envoyer une autre demande de signature.',
  },
  'Mover documento para pasta:': {
    es: 'Mover el documento a la carpeta:',
    en: 'Move document to folder:',
    fr: 'Déplacer le document vers le dossier :',
  },
  'Sem pasta': {
    es: 'Sin carpeta',
    en: 'No folder',
    fr: 'Pas de dossier',
  },
  'Documento movido com sucesso.': {
    es: 'El documento se ha movido con éxito.',
    en: 'Document moved successfully.',
    fr: 'Le document s\'est déplacé avec succès.',
  },
  'Pré-visualização:': {
    es: 'Pre-visualización:',
    en: 'Preview:',
    fr: 'Aperçu:',
  },
  'Deseja excluir $0 documentos? Só o proprietário da conta poderá recuperá-los da lixeira!': {
    es: '¿Quieres eliminar $0 documentos? ¡Solo el propietario de la cuenta puede recuperarlos de la papelera!',
    en: 'Do you want to delete $0 documents? Only the account owner can retrieve them from the trash!',
    fr: 'Voulez-vous supprimer des documents de $0? Seul le propriétaire du compte peut les récupérer de la poubelle!',
  },
  'Deseja excluir $0 modelos?': {
    es: '¿Quieres eliminar $0 plantillas?',
    en: 'Do you want to delete $0 templates?',
    fr: 'Voulez-vous supprimer des modèles de $0?',
  },
  'Não é possível excluir pastas com documentos ou pastas dentro.': {
    es: 'No es posible eliminar carpetas con documentos o carpetas en su interior.',
    en: 'It is not possible to delete folders with documents or folders inside.',
    fr: 'Il n\'est pas possible de supprimer des dossiers avec des documents ou des dossiers à l\'intérieur.',
  },
  'Tem certeza de que deseja excluir o documento?': {
    es: '¿Estás seguro de eliminar el documento?',
    en: 'Are you sure you want to delete the document?',
    fr: 'Êtes-vous sûr de vouloir supprimer le document?',
  },
  'Excluir documento (sem volta)': {
    es: 'Eliminar documento',
    en: 'Delete document',
    fr: 'Supprimer le document',
  },
  'Manter documento': {
    es: 'Conservar el documento',
    en: 'Keep document',
    fr: 'Garder un document',
  },
  'Data limite para assinar:': {
    es: 'Fecha límite para firmar:',
    en: 'Deadline for signing:',
    fr: 'Date limite pour la signature:',
  },
  'Clique para editar': {
    es: 'Haz clic para editar',
    en: 'Click to edit',
    fr: 'Cliquez pour modifier',
  },
  'Por favor insira uma data válida': {
    es: 'Por favor introduce una fecha válida',
    en: 'Please enter a valid date',
    fr: 'Veuillez entrer une date valide',
  },
  'Ocorreu algum erro para salvar alterações. Por favor, atualize a tela.': {
    es: 'Se produjo un error al guardar los cambios. Actualiza la pantalla.',
    en: 'An error occurred while saving changes. Please refresh the screen.',
    fr: 'Une erreur s\'est produite lors de l\'enregistrement des modifications. Veuillez rafraîchir l\'écran',
  },
  'Escolha uma data': {
    es: 'Elige una fecha',
    en: 'Choose a date',
    fr: 'Choisissez une date',
  },
  'Data limite de assinatura do documento:': {
    es: 'Fecha límite para firmar el documento:',
    en: 'Deadline for signing the document:',
    fr: 'Date limite pour signer le document:',
  },
  'Remover data': {
    es: 'Eliminar fecha',
    en: 'Remove date',
    fr: 'Supprimer la date',
  },
  'Data limite de assinatura removida com sucesso.': {
    es: 'Fecha límite de firma eliminada con éxito.',
    en: 'Signature deadline successfully removed.',
    fr: 'La date limite de signature a été supprimée avec succès.',
  },
  'Renomear a pasta:': {
    es: 'Cambia el nombre de la carpeta:',
    en: 'Rename folder:',
    fr: 'Renommer le dossier:',
  },
  'Novo nome da pasta': {
    es: 'Nuevo nombre de carpeta',
    en: 'New folder name',
    fr: 'Nouveau nom de dossier',
  },
  Renomear: {
    es: 'Renombrar',
    en: 'Rename',
    fr: 'Renommer',
  },
  'Pasta atualizada com sucesso.': {
    es: 'Carpeta actualizada con éxito.',
    en: 'Folder successfully updated',
    fr: 'Dossier mis à jour avec succès',
  },
  '$0-$1 de $2 documentos': {
    es: '$0-$1 de $2 documentos',
    en: '$0-$1 of $2 documents',
    fr: '$0-$1 sur $2 documents',
  },
  Assinou: {
    es: 'Firmado',
    en: 'Signed',
    fr: 'Signé',
  },
  Desselecionar: {
    es: 'Deseleccionar',
    en: 'Deselect',
    fr: 'Désélectionner',
  },
  'documentos selecionados': {
    es: 'documentos seleccionados',
    en: 'selected documents',
    fr: 'Documents sélectionnés',
  },
  'Deletar documentos': {
    es: 'Eliminar documentos',
    en: 'Delete documents',
    fr: 'Supprimer des documents',
  },
  Deletar: {
    es: 'Eliminar',
    en: 'Delete',
    fr: 'Supprimer',
  },
  'Deseja realmente deletar esse contato': {
    es: '¿Estás seguro de eliminar este contacto?',
    en: 'Do you really want to delete this contact',
    fr: 'Voulez-vous vraiment supprimer ce contact',
  },
  'Para qual pasta deseja mover?': {
    es: '¿A cuál carpeta lo quieres mover?',
    en: 'To which folder do you want to move?',
    fr: 'À quel dossier voulez-vous déplacer?',
  },
  'Sin carpeta': {
    es: 'Sin carpeta',
    en: 'Without folder',
    fr: 'Sans dossier',
  },
  'Documentos movidos com sucesso.': {
    es: 'Las plantillas se movieron con éxito.',
    en: 'Templates successfully moved.',
    fr: 'Les modèles ont réussi à se déplacer.',
  },
  'Modelos movidos com sucesso.': {
    es: 'Los documentos se movieron con éxito.',
    en: 'Documents successfully moved.',
    fr: 'Les documents ont été déplacés avec succès.',
  },
  'Pasta adicionada com sucesso!': {
    es: 'Carpeta añadida con éxito',
    en: 'Folder successfully added!',
    fr: 'Dossier a ajouté avec succès!',
  },
  'Novo documento Ajuda?': {
    es: 'Nuevo documento ¿Ayuda?',
    en: 'New Help document?',
    fr: 'Nouveau document d\'aide?',
  },
  'Será inserido em': {
    es: 'Se guardará en',
    en: 'To be inserted in',
    fr: 'À insérer dans',
  },
  'Novo documento será inserido na pasta': {
    es: 'El nuevo documento se guardará en la carpeta',
    en: 'The new document will be inserted in the folder',
    fr: 'Le nouveau document sera inséré dans le dossier',
  },
  'Você sempre poderá movê-lo para outras pastas.': {
    es: 'Siempre podrás moverlo a otras carpetas.',
    en: 'You can always move it to other folders.',
    fr: 'Vous pouvez toujours le déplacer vers d\'autres dossiers.',
  },
  'Clique para remover da pasta': {
    es: 'Haz clic para eliminar la carpeta',
    en: 'Click to remove from the folder',
    fr: 'Cliquez pour supprimer du dossier',
  },
  'Qual documento será assinado?': {
    es: '¿Qué documento se firmará?',
    en: 'Which document will be signed?',
    fr: 'Quel document sera signé?',
  },
  'SELECIONAR DOCUMENTO': {
    es: 'SELECCIONAR EL DOCUMENTO',
    en: 'SELECT DOCUMENT',
    fr: 'Sélectionner un document',
  },
  'Quem irá assinar?': {
    es: '¿Quién firmará?',
    en: 'Who will sign?',
    fr: 'Qui signera?',
  },
  'Autenticação avançada': {
    es: 'Autenticación avanzada',
    en: 'Advanced authentication',
    fr: 'Authentification avancée',
  },
  'Nome do signatário': {
    es: 'Nombre del firmante',
    en: 'Signer name',
    fr: 'Nom du signataire',
  },
  'Meus contatos': {
    es: 'Mis contactos',
    en: 'My contacts',
    fr: 'Mes contacts',
  },
  Contatos: {
    es: 'Contactos',
    en: 'Contacts',
    fr: 'Contacts',
  },
  'Contatos e grupos': {
    es: 'Contactos y grupos',
    en: 'Contacts & groups',
    fr: 'Contacts et groupes',
  },
  'Buscar contatos': {
    es: 'Buscar contactos',
    en: 'Search contacts',
    fr: 'Rechercher des contacts',
  },
  'Digite o nome ou e-mail e aperte enter': {
    es: 'Escribe tu nombre o correo electrónico y pulsa Enter',
    en: 'Enter name or email and press enter',
    fr: 'Entrez le nom ou l\'e-mail et appuyez sur ENTER',
  },
  'Busque por nome, e-mail ou telefone': {
    es: 'Busca por nombre, correo o teléfono',
    en: 'Search by name, email or phone',
    fr: 'Recherche par nom, e-mail ou téléphone',
  },
  'Eu vou assinar esse documento': {
    es: 'Voy a firmar este documento',
    en: 'I will sign this document',
    fr: 'Je vais signer ce document',
  },
  Português: {
    es: 'Portugués',
    en: 'Portuguese',
    fr: 'Portugais',
  },
  Idioma: {
    es: 'Idioma',
    en: 'Language',
    fr: 'Langue',
  },
  'Suba um documento para continuar': {
    es: 'Subir un documento para continuar',
    en: 'Upload a document to continue',
    fr: 'Téléchargez un document pour continuer',
  },
  Carregando: {
    es: 'Cargando',
    en: 'Loading',
    fr: 'Chargement',
  },
  'Só mais um instante...': {
    es: 'Sólo un momento más...',
    en: 'Just one moment...',
    fr: 'Juste un moment de plus ...',
  },
  'Bem-vindo(a) à ZapSign!': {
    es: '¡Bienvenido a ZapSign!',
    en: 'Welcome to ZapSign!',
    fr: 'Bienvenue à ZapSign!',
  },
  'Clique em': {
    es: 'Haz clic',
    en: 'Click',
    fr: 'Cliquez sur',
  },
  'Abrir documento': {
    es: 'Abrir documento',
    en: 'Open document',
    fr: 'Ouvrir un document',
  },
  'Remover documento': {
    es: 'Eliminar documento',
    en: 'Remove document',
    fr: 'Supprimer le document',
  },
  'Adicionar documentos complementares': {
    es: 'Agregar documentos adicionales',
    en: 'Add extra documents',
    fr: 'Ajouter des documents supplémentaires',
  },
  'Serão criados $0 documentos': {
    es: 'Se crearán $0 documentos',
    en: '$0 documents will be created',
    fr: 'Des documents de 0 $ seront créés',
  },
  'Serão criados $0 documento que serão assinados em conjunto, consumindo $0 documentos do seu plano. Cada documento poderá ser baixado separadamente.': {
    es: 'Se crearán $0 documentos que se firmarán juntos, consumiendo $0 documentos de tu plan. Cada documento puede descargarse por separado.',
    en: '$0 documents will be created and signed together, consuming $0 documents from your plan. Each document can be downloaded separately.',
    fr: 'Des documents de 0 $ seront créés et signés ensemble, consommant des documents de 0 $ de votre plan. Chaque document peut être téléchargé séparément.',
  },
  'Ao continuar, você criará $0 documentos a serem assinados em conjunto, consumindo $0 documentos do seu plano. Cada documento poderá ser baixado separadamente.': {
    es: 'Al continuar, crearás $0 documentos que se firmarán juntos, consumiendo $0 documentos de tu plan. Cada documento puede descargarse por separado.',
    en: 'As you proceed, you will create $0 documents to be signed together, consuming $0 documents from your plan. Each document can be downloaded separately.',
    fr: 'Au fur et à mesure que vous procéderez, vous créerez des documents de 0 $ à signer ensemble, consommant des documents de 0 $ de votre plan. Chaque document peut être téléchargé séparément.',
  },
  'Você pode subir no máximo $0 documentos.': {
    es: 'Puedes subir un máximo de $0 documentos.',
    en: 'You can upload a maximum of $0 documents.',
    fr: 'Vous pouvez télécharger un maximum de documents de 0 $.',
  },
  'Você pode subir': {
    es: 'puedes subir',
    en: 'you can go up',
    fr: 'tu peux monter',
  },
  '': {
    es: '',
    en: '',
    fr: '',
  },
  'Não é possível subir vários PDFs de uma vez caso haja signatários com certificado digital. Por favor, mantenha apenas 1 PDF.': {
    es: 'No es posible cargar varios PDF a la vez si hay firmantes con un certificado digital. Conserve solo 1 PDF.',
    en: 'It is not possible to upload multiple PDFs at once if there are signers with a digital certificate. Please keep only 1 PDF.',
    fr: 'Il n\'est pas possible de télécharger plusieurs PDF à la fois s\'il y a des signataires avec un certificat numérique. Veuillez garder un seul PDF.',
  },
  'Nenhum resultado': {
    es: 'No hay resultados',
    en: 'No results',
    fr: 'Aucun résultat',
  },
  'Buscando...': {
    es: 'Buscando...',
    en: 'Searching...',
    fr: 'Recherche...',
  },
  'Após preencher um campo, clique no': {
    es: 'Después de diligenciar un campo, haz clic en el botón',
    en: 'After filling in a field, click on',
    fr: 'Après avoir rempli un champ, cliquez sur',
  },
  'se quiser impedir alterações pelo signatário.': {
    es: 'si quieres desactivar cambios por parte del firmante.',
    en: 'if you want to disable changes by the signer.',
    fr: 'Si vous souhaitez désactiver les modifications par le signataire.',
  },
  SIGNATÁRIO: {
    es: 'FIRMANTE',
    en: 'SIGNER',
    fr: 'Signataire',
  },
  Teste: {
    es: 'Prueba',
    en: 'Test',
    fr: 'Test',
  },
  Contratar: {
    es: 'Comprar',
    en: 'Subscribe',
    fr: 'S\'abonner',
  },
  'Número do CNPJ': {
    es: 'Número del CNPJ',
    en: 'CNPJ number',
    fr: 'Numéro CNPJ',
  },
  CNPJ: {
    es: 'CNPJ',
    en: 'CNPJ',
    fr: 'CNPJ',
  },
  'Razão Social': {
    es: 'Razón Social',
    en: 'Corporate Name',
    fr: 'Nom de la SOCIETE',
  },
  'Você está contratando o plano Teste.': {
    es: 'Estás suscrito al plan de pruebas.',
    en: 'You are contracting the Test plan.',
    fr: 'Vous contractez le plan de test.',
  },
  'Você está contratando o plano': {
    es: 'Estas contratando el plan',
    en: 'You are hiring the plan',
    fr: 'Vous embauchez le plan',
  },
  'Dados pessoais': {
    es: 'Datos personales',
    en: 'Personal data',
    fr: 'Données personnelles',
  },
  'E-mail para receber faturas': {
    es: 'Correo electrónico para recibir facturas',
    en: 'Email to receive invoices',
    fr: 'E-mail pour recevoir des factures',
  },
  Documento: {
    es: 'Documento',
    en: 'Document',
    fr: 'Document',
  },
  'Número do CPF': {
    es: 'Número do CPF',
    en: 'CPF number',
    fr: 'Numéro CPF',
  },
  CPF: {
    es: 'CPF',
    en: 'CPF',
    fr: 'CPF',
  },
  'Telefone (DDD)': {
    es: 'Teléfono',
    en: 'Telephone',
    fr: 'Numéro de Portable',
  },
  'Telefone (DDD + número)': {
    es: 'Teléfono',
    en: 'Phone',
    fr: 'Numéro de Portable',
  },
  'Telefone (Número)': {
    es: 'Teléfono',
    en: 'Phone',
    fr: 'Numéro de Portable',
  },
  Telefone: {
    es: 'Teléfono',
    en: 'Phone',
    fr: 'Numéro de Portable',
  },
  Endereço: {
    es: 'Dirección',
    en: 'Address',
    fr: 'Adresse',
  },
  CEP: {
    es: 'CÓDIGO POSTAL',
    en: 'ZIP CODE',
    fr: 'CODE POSTAL',
  },
  Rua: {
    es: 'Calle',
    en: 'Road',
    fr: 'Route',
  },
  Estado: {
    es: 'Estado',
    en: 'State',
    fr: 'État',
  },
  Número: {
    es: 'Número',
    en: 'Number',
    fr: 'Nombre',
  },
  Complemento: {
    es: 'Complemento',
    en: 'Complement',
    fr: 'Complément',
  },
  Bairro: {
    es: 'Barrio',
    en: 'Neighborhood',
    fr: 'Quartier',
  },
  Cidade: {
    es: 'Ciudad',
    en: 'City',
    fr: 'Ville',
  },
  SP: {
    es: 'SP',
    en: 'SP',
    fr: 'Sp',
  },
  reCAPTCHA: {
    es: 'reCAPTCHA',
    en: 'reCAPTCHA',
    fr: 'reCAPTCHA',
  },
  Pagamento: {
    es: 'Pago',
    en: 'Payment',
    fr: 'Paiement',
  },

  'recaptcha challenge': {
    es: 'recaptcha challenge',
    en: 'recaptcha challenge',
    fr: 'Challenge de recaptcha',
  },
  'Boleto (até 2 dias úteis para confirmar)': {
    es: 'Boleto (hasta 2 días laborales para confirmar)',
    en: 'Boleto (up to 2 business days to confirm)',
    fr: 'Boleto (jusqu\'à 2 jours ouvrables pour confirmer)',
  },
  'MM/AA': {
    es: 'MM/AA',
    en: 'MM/YY',
    fr: 'MM / YY',
  },
  'Forma de pagamento': {
    es: 'Forma de pago',
    en: 'Payment method',
    fr: 'Mode de paiement',
  },
  'Cartão de crédito (imediato)': {
    es: 'Tarjeta de crédito (inmediata)',
    en: 'Credit card (immediate)',
    fr: 'Carte de crédit (immédiate)',
  },
  'Como você deseja?': {
    es: '¿Cómo lo quieres?',
    en: 'How would you like it?',
    fr: 'Comment l\'aimerais-tu?',
  },
  'Número do cartão': {
    es: 'Número de tarjeta',
    en: 'Card number',
    fr: 'Numéro de carte',
  },
  'Data de expiração (MM/AA)': {
    es: 'Fecha de caducidad (MM/AA)',
    en: 'Expiration date (MM/YY)',
    fr: 'Date d\'expiration (mm / yy)',
  },
  'Código de segurança (CVV)': {
    es: 'Código de seguridad (CVV)',
    en: 'Security Code (CVV)',
    fr: 'Code de sécurité (CVV)',
  },
  'Nome do titular': {
    es: 'Nombre del titular',
    en: 'Cardholder Name',
    fr: 'Nom du titulaire',
  },
  'Transação criptografada pelo protocolo SSL e processada com segurança pela Pagar.me.': {
    es: 'Transacción encriptada por protocolo SSL y procesada de forma segura por Pagar.me.',
    en: 'Transaction encrypted by SSL protocol and securely processed by Pagar.me.',
    fr: 'Transaction cryptée par le protocole SSL et en toute sécurité traitée par Pagar.me.',
  },
  'Cancele quando quiser. Sem período de fidelidade mínimo.': {
    es: 'Cancela cuando quieras. No hay período mínimo de suscripción.',
    en: 'Cancel anytime. There is no minimum subscription period.',
    fr: 'Annuler à tout moment. Il n\'y a pas de période d\'abonnement minimum.',
  },
  Total: {
    es: 'Total',
    en: 'Total',
    fr: 'Total',
  },
  'Escolhendo o boleto, será emitido um boleto com vencimento para daqui a 7 dias. Confirmaremos o seu pagamento em até 2 dias úteis.': {
    es: 'Al elegir el boleto, se le emitirá un boleto con vencimiento en 7 días. Le confirmaremos el pago en un plazo de 2 días laborables.',
    en: 'By choosing the bank slip, a bank slip will be issued with expiration in 7 days. We will confirm your payment within 2 business days.',
    fr: 'En choisissant le bordereau de banque, un bordereau bancaire sera émis avec expiration en 7 jours. Nous confirmerons votre paiement dans les 2 jours ouvrables.',
  },
  'EMITIR BOLETO': {
    es: 'EMITIR FACTURA',
    en: 'ISSUE BILL',
    fr: 'Émission de factures',
  },
  'Emitindo boleto...': {
    es: 'Emitiendo factura',
    en: 'Issuing bill...',
    fr: 'Émettre une facture ...',
  },
  'Boleto emitido com sucesso!': {
    es: 'Factura emitida con éxito',
    en: 'Bill issued successfully!',
    fr: 'Bill a été publié avec succès!',
  },
  'Plano assinado com sucesso!': {
    es: 'Plan comprado con éxito',
    en: 'Plan successfully subscribed',
    fr: 'Planifiez avec succès signé!',
  },
  'Você poderá gerenciá-lo a qualquer momento nesta tela.': {
    es: 'Puedes gestionarlo en cualquier momento en esta pantalla.',
    en: 'You can manage it at any time on this screen.',
    fr: 'Vous pouvez le gérer à tout moment sur cet écran.',
  },
  'Agora só falta imprimir o boleto, pagar e aguardar a confirmação, em até 2 dias úteis. Não quer aguardar? Envie o comprovante de pagamento do boleto para support@zapsign.com.br': {
    es: 'Ahora sólo debes imprimir el boleto, pagar y esperar la confirmación, en un plazo máximo de 2 días laborables. ¿No quieres esperar? Enviar el comprobante de pago del boleto a support@zapsign.com.br',
    en: 'Now all that\'s left is to print the bank slip, pay and wait for confirmation, within 2 business days.Don\'t want to wait? Send proof of payment for the bank slip to support@zapsign.com.br',
    fr: 'Maintenant, il ne reste plus qu\'à imprimer le bordereau bancaire, à payer et à attendre la confirmation, dans les 2 jours ouvrables. Je ne veux pas attendre? Envoyer une preuve de paiement pour le banc bancaire à support@zapsign.com.br',
  },
  'Não quer aguardar? Envie o comprovante': {
    es: '¿No quieres esperar? Envia el comprobante',
    en: 'Don\'t want to wait? send proof',
    fr: 'Vous ne voulez pas attendre? envoyer une preuve',
  },
  'de pagamento do boleto para support@zapsign.com.br': {
    es: 'comprobante de pago a support@zapsign.com.br',
    en: 'payment slip to support@zapsign.com.br',
    fr: 'Signon de paiement à support@zapsign.com.br',
  },
  'Agora só falta': {
    es: 'Ahora sólo queda',
    en: 'Now it\'s just left',
    fr: 'Maintenant c\'est juste à gauche',
  },
  'imprimir o boleto, pagar e aguardar a confirmação': {
    es: 'imprime la factura, paga y espera la confirmación',
    en: 'print the bill, pay and wait for confirmation',
    fr: 'Imprimez la facture, payez et attendez la confirmation',
  },
  ', em até 2 dias úteis. ': {
    es: ', dentro de 2 días hábiles.',
    en: ', within 2 business days.',
    fr: ', dans les 2 jours ouvrables.',
  },
  'Imprimir boleto': {
    es: 'Imprimir factura',
    en: 'Print bill',
    fr: 'Facture imprimée',
  },
  'Visualizar nota fiscal': {
    es: 'Ver factura',
    en: 'View invoice',
    fr: 'Voir la facture',
  },
  'Meu plano': {
    es: 'Mi plan',
    en: 'My plan',
    fr: 'Mon plan',
  },
  'MEU PLANO': {
    es: 'MI PLAN',
    en: 'MY PLAN',
    fr: 'MON PLAN',
  },
  'Notas Fiscais': {
    es: 'Facturas',
    en: 'Invoices',
    fr: 'Factures',
  },
  'Registro de notas fiscais': {
    es: 'Registro de facturas',
    en: 'Invoice registration',
    fr: 'Inscription à la facture',
  },
  'As notas serão emitidas em até 24 horas da confirmação do pagamento.': {
    es: 'Las facturas se emitirán en las 24 horas siguientes a la confirmación del pago.',
    en: 'Notes will be issued within 24 hours of payment confirmation.',
    fr: 'Les billets seront émis dans les 24 heures suivant la confirmation du paiement.',
  },
  Data: {
    es: 'Fecha',
    en: 'Date',
    fr: 'Date',
  },
  Valor: {
    es: 'Valor',
    en: 'Value',
    fr: 'Valeur',
  },
  Download: {
    es: 'Descargar',
    en: 'Download',
    fr: 'Télécharger',
  },
  'Não pago': {
    es: 'No pago',
    en: 'Unpaid',
    fr: 'Non payé',
  },
  'Período de teste': {
    es: 'Periodo de prueba',
    en: 'Trial period',
    fr: 'Période d\'essai',
  },
  'Mudar plano': {
    es: 'Cambiar plan',
    en: 'Change plan',
    fr: 'Plan de changement',
  },
  Mudar: {
    es: 'Cambiar',
    en: 'Change',
    fr: 'Changement',
  },
  'Pagar plano': {
    es: 'Pagar plan',
    en: 'Pay plan',
    fr: 'Plan de paie',
  },
  'Se você pagou com boleto, mas o plano não consta como pago, aguarde até 2 dias úteis devido a compensação bancária ou envie o comprovante de pagamento para support@zapsign.com.br': {
    es: 'Si has pagado con un boleto, pero el plan no aparece como pago, por favor espera hasta 2 días hábiles debido a la liquidación bancaria o envíe el comprobante de pago a support@zapsign.com.br',
    en: 'If you paid with a bank slip, but the plan is not shown as paid, wait up to 2 business days due to bank compensation or send proof of payment to support@zapsign.com.br',
    fr: 'Si vous avez payé avec un bordereau bancaire, mais que le plan n\'est pas indiqué comme payé, attendez jusqu\'à 2 jours ouvrables en raison d\'une compensation bancaire ou envoyez une preuve de paiement à support@zapsign.com.br',
  },
  'Digite sua mensagem': {
    es: 'Escribe tu mensaje',
    en: 'Enter your message',
    fr: 'entrez votre message',
  },
  'Cancelar plano': {
    es: 'Cancelar el plan',
    en: 'Cancel plan',
    fr: 'Plan d\'annuler',
  },
  'Para nos ajudar a melhorar a ZapSign, por favor compartilhe o motivo do cancelamento abaixo.': {
    es: 'Para ayudarnos a mejorar ZapSign, por favor, comparte el motivo de tu cancelación a continuación.',
    en: 'To help us improve ZapSign, please share your reason for cancellation below.',
    fr: 'Pour nous aider à améliorer ZapSign, veuillez partager votre raison de l\'annulation ci-dessous.',
  },
  'Atenção: Caso queira recontratar a ZapSign no futuro, pode ser que o Teste tenha mudado de preço ou condições. Garanta o preço atual permanecendo no plano.': {
    es: 'Ten en cuenta que si quieres volver a contratar ZapSign en el futuro, es posible que la prueba haya cambiado de precio o de condiciones. Garantizar el precio actual permaneciendo en el plan.',
    en: 'Attention: If you want to re-hire ZapSign in the future, the Test may have changed its price or conditions. Secure the current price by staying on the plan.',
    fr: 'ATTENTION: Si vous souhaitez recruter ZapSign à l\'avenir, le test a peut-être changé son prix ou ses conditions. Sécuriser le prix actuel en restant sur le plan.',
  },
  'Voltar (permanecer no plano)': {
    es: 'Volver (permanecer en el plan)',
    en: 'Back (stay on plan)',
    fr: 'Retour (restez sur le plan)',
  },
  'Confirmar cancelamento': {
    es: 'Confirmar cancelación',
    en: 'Confirm cancellation',
    fr: 'Confirmer l\'annulation',
  },
  'Favor preencher o motivo do cancelamento.': {
    es: 'Por favor, diligencia el motivo de la cancelación.',
    en: 'Please fill in the reason for cancellation.',
    fr: 'Veuillez remplir la raison de l\'annulation.',
  },
  'Mudar de plano': {
    es: 'Cambiar de plan',
    en: 'Change plan',
    fr: 'Plan de changement',
  },
  'Voltar (permanecer no plano atual)': {
    es: 'Volver (permanecer en el plan actual)',
    en: 'Back (stay on current plan)',
    fr: 'Retour (restez sur le plan actuel)',
  },
  'Como funciona a mudança de plano?': {
    es: '¿Cómo funciona el cambio de plan?',
    en: 'How does the change of plan work?',
    fr: 'Comment fonctionne le changement de plan?',
  },
  'A mudança de um plano para outro é imediata. A cobrança depende da sua forma de pagamento atual.': {
    es: 'El cambio de un plan a otro es inmediato. El cargo depende de tu método de pago actual.',
    en: 'Switching from one plane to another is immediate. The charge depends on your current payment method.',
    fr: 'Le passage d\'un plan à un autre est immédiat. La charge dépend de votre mode de paiement actuel.',
  },
  '- Cartão de crédito: caso o novo plano tenha valor maior, será cobrado o valor da diferença entre os planos, proporcional ao tempo restante do plano atual.': {
    es: '- Tarjeta de crédito: si el nuevo plan tiene un valor mayor, se le cobrará la diferencia entre los planes, proporcional al tiempo restante del plan actual.',
    en: '- Credit card: if the new plan has a higher value, the difference between the plans will be charged, proportional to the remaining time of the current plan.',
    fr: '- Carte de crédit: Si le nouveau plan a une valeur plus élevée, la différence entre les plans sera facturée, proportionnelle au temps restant du plan actuel.',
  },
  'Cartão de crédito': {
    es: 'Tarjeta de crédito',
    en: 'Credit card',
    fr: 'Carte de crédit',
  },
  'Boleto bancário': {
    es: 'Comprobante bancario',
    en: 'Bank slip',
    fr: 'bordereau de banque',
  },
  'Adicione os dados do seu cartão': {
    es: 'Añade los datos de tu tarjeta',
    en: 'Add your card details',
    fr: 'Ajoutez les détails de votre carte',
  },
  'Compra aprovada em até 2 dias úteis após o pagamento': {
    es: 'Compra aprobada dentro de los 2 días hábiles posteriores al pago',
    en: 'Purchase approved within 2 business days after payment',
    fr: 'Achat approuvé dans les 2 jours ouvrables suivant le paiement',
  },
  'Será emitido um boleto com vencimento em 7 dias': {
    es: 'Se emitirá un billete con vencimiento en 7 días',
    en: 'A ticket will be issued with maturity in 7 days',
    fr: 'Un billet recevra une échéance en 7 jours',
  },
  'Insira dado válido': {
    es: 'Introduce datos válidos',
    en: 'Enter valid data',
    fr: 'Entrez des données valides',
  },
  Validade: {
    es: 'Valido hasta',
    en: 'Valid Thru',
    fr: 'Valide jusqu\'au',
  },
  'Data têm que ser maior que hoje': {
    es: 'La fecha debe ser mayor que hoy',
    en: 'Date must be greater than today',
    fr: 'La date doit être plus grande qu\'aujourd\'hui',
  },
  '- Cartão de crédito:': {
    es: '- Tarjeta de crédito:',
    en: '- Credit card:',
    fr: '- Carte de crédit:',
  },
  // tslint:disable-next-line:max-line-length
  '- Boleto (mais complicado...): será gerado um boleto no valor TOTAL do novo plano para pagamento à vista, mas o período do seu novo plano será estendido pelos dias não usufruídos do plano anterior. Atenção: você ficará sem um plano enquanto o novo boleto não for pago (até 2 dias úteis ou com o envio do comprovante para nós), então prepare-se para a transição. Não é possível contratar planos anuais parcelados com boleto (será emitida uma única fatura, não 12x parcelas). Tudo isso ficou muito confuso? Entre em contato com support@zapsign.com.br, ou você pode cancelar o plano atual e contratar um novo quando quiser.': {
    // tslint:disable-next-line:max-line-length
    es: '- Boleto (más complicado...): se generará un boleto por el valor TOTAL del nuevo plan para el pago en efectivo, pero el período de tu nuevo plan se extenderá por los días no utilizados del plan anterior. Atención: estarás sin plan hasta que se pague el nuevo boleto (hasta 2 días laborables o cuando se nos envíe el recibo), así que prepárate para la transición. No es posible contratar planes anuales a plazos con boleto (se emitirá una única factura, no 12x plazos). ¿Es todo esto demasiado confuso? Ponte en contacto con support@zapsign.com.br, o puedes cancelar tu plan actual y contratar uno nuevo cuando quieras.',
    // tslint:disable-next-line:max-line-length
    en: '- Boleto (more complicated...): a boleto for the TOTAL amount of the new plan will be generated for cash payment, but the period of your new plan will be extended by the days not used in the previous plan. Please note: you will be without a plan until the new ticket is paid (within 2 business days or with the receipt sent to us), so be prepared for the transition. It is not possible to contract annual installment plans with payment slip (a single invoice will be issued, not 12x installments). Has all this gotten too confusing? Contact support@zapsign.com.br, or you can cancel the current plan and hire a new one whenever you want.',
    // tslint:disable-next-line:max-line-length
    fr: '- Boleto (plus compliqué ...): Un boleto pour le montant total du nouveau plan sera généré pour le paiement en espèces, mais la période de votre nouveau plan sera prolongée par les jours non utilisés dans le plan précédent. Veuillez noter: vous serez sans plan jusqu\'à ce que le nouveau billet soit payé (dans les 2 jours ouvrables ou avec le reçu qui nous est envoyé), alors préparez-vous à la transition. Il n\'est pas possible de contracter des plans de versement annuels avec un bordereau de paiement (une seule facture sera émise, et non des versements de 12x). Tout cela est-il devenu trop déroutant? Contactez support@zapsign.com.br, ou vous pouvez annuler le plan actuel et en embaucher un nouveau quand vous le souhaitez.',
  },
  'valor total': {
    es: 'valor total',
    en: 'amount',
    fr: 'montant',
  },
  'do novo plano para pagamento à vista, mas o período do seu novo plano será estendido pelos dias não usufruídos do plano anterior.': {
    es: 'el nuevo plan para el pago en efectivo, pero el período de tu nuevo plan se extenderá por los días no tomados por el plan anterior.',
    en: 'the new plan for cash payment, but the period of your new plan will be extended for the days not taken by the previous plan.',
    fr: 'Le nouveau plan de paiement en espèces, mais la période de votre nouveau plan sera prolongée pour les jours non pris par le plan précédent.',
  },
  '- Boleto (mais complicado...):': {
    es: '- Boleto (más complicado...):',
    en: '- Boleto (more complicated...):',
    fr: '- Boleto (plus compliqué ...):',
  },
  'caso o novo plano tenha valor maior, será cobrado o': {
    es: 'si el nuevo plan tiene un valor mayor, el',
    en: 'if the new plan has a higher value, the',
    fr: 'Si le nouveau plan a une valeur plus élevée, le',
  },
  'valor da diferença': {
    es: 'valor de diferencia',
    en: 'difference value',
    fr: 'valeur de différence',
  },
  'entre os planos, proporcional ao tempo restante do plano atual.': {
    es: 'entre planes, proporcional al tiempo restante del plan actual.',
    en: 'between plans, proportional to the time remaining in the current plan.',
    fr: 'Entre les plans, proportionnel au temps restant dans le plan actuel.',
  },
  // tslint:disable-next-line:max-line-length
  ': você ficará sem um plano enquanto o novo boleto não for pago (até 2 dias úteis ou com o envio do comprovante para nós), então prepare-se para a transição. Não é possível contratar planos anuais parcelados com boleto (será emitida uma única fatura, não 12x parcelas). Tudo isso ficou muito confuso? Entre em contato com support@zapsign.com.br, ou você pode cancelar o plano atual e contratar um novo quando quiser.': {
    // tslint:disable-next-line:max-line-length
    es: ': No tendrá un plan hasta que se pague el nuevo comprobante de pago (dentro de 2 días hábiles o con el recibo enviado), así que prepárese para la transición. No es posible contratar planes de cuotas anuales con comprobante de pago (se emitirá una única factura, no 12 cuotas). ¿Todo esto se ha vuelto demasiado confuso? Póngase en contacto con support@zapsign.com.br, o puede cancelar el plan actual y contratar uno nuevo cuando lo desee.',
    // tslint:disable-next-line:max-line-length
    en: ': You will be without a plan until the new payment slip is paid (within 2 business days or with the receipt sent to us), so be prepared for the transition. It is not possible to contract annual installment plans with payment slip (a single invoice will be issued, not 12x installments). Has all this gotten too confusing? Contact support@zapsign.com.br, or you can cancel the current plan and hire a new one whenever you want.',
    // tslint:disable-next-line:max-line-length
    fr: ': Vous serez sans plan jusqu\'à ce que le nouveau bordereau de paiement soit payé (dans les 2 jours ouvrables ou avec le reçu qui nous est envoyé), alors préparez-vous à la transition. Il n\'est pas possible de contracter des plans de versement annuels avec un bordereau de paiement (une seule facture sera émise, et non des versements de 12x). Tout cela est-il devenu trop déroutant? Contactez support@zapsign.com.br, ou vous pouvez annuler le plan actuel et en embaucher un nouveau quand vous le souhaitez.',
  },
  'será gerado um boleto no': {
    es: 'se generará una palanquilla en el',
    en: 'a billet will be generated in the',
    fr: 'Une billette sera générée dans le',
  },
  'Plano atual': {
    es: 'Plan actual',
    en: 'Current plan',
    fr: 'Plan actuel',
  },
  'NOVO MODELO': {
    es: 'NUEVA PLANTILLA',
    en: 'NEW TEMPLATE',
    fr: 'NOUVEAU MODÈLE',
  },
  'Aguardando pagamento': {
    es: 'A la espera del pago',
    en: 'Pending payment',
    fr: 'En attente de paiement',
  },
  Cancelado: {
    es: 'Cancelado',
    en: 'Canceled',
    fr: 'Annulé',
  },
  Pago: {
    es: 'Pago',
    en: 'Paid',
    fr: 'Payé',
  },
  'Gerenciar plano': {
    es: 'Gestionar plan',
    en: 'Manage plan',
    fr: 'Gérer le plan',
  },
  'Processando pagamento...': {
    es: 'Procesando el pago...',
    en: 'Processing payment...',
    fr: 'Paiement de traitement ...',
  },
  'Pagamento confirmado com sucesso!': {
    es: '¡Pago confirmado con éxito!',
    en: 'Payment confirmed successfully!',
    fr: 'Paiement confirmé avec succès!',
  },
  'Atualizado com sucesso.': {
    es: 'Actualizado con éxito.',
    en: 'Updated successfully.',
    fr: 'Mis à jour avec succés.',
  },
  'Usuário adicionado com sucesso! Instruções foram enviadas ao usuário por e-mail.': {
    es: '¡Usuario añadido con éxito! Las instrucciones se han enviado al usuario por correo electrónico.',
    en: 'User successfully added! Instructions were sent to the user via email.',
    fr: 'L\'utilisateur a ajouté avec succès! Les instructions ont été envoyées à l\'utilisateur par e-mail.',
  },
  'Remover usuário': {
    es: 'Eliminar usuario',
    en: 'Remove user',
    fr: 'Supprimer l\'utilisateur',
  },
  'Deseja remover?': {
    es: '¿Quieres eliminarlo?',
    en: 'Do you want to remove?',
    fr: 'Voulez-vous supprimer?',
  },
  'Trocar proprietário da conta': {
    es: 'Cambiar el propietario de la cuenta',
    en: 'Change account owner',
    fr: 'Propriétaire du compte de modification',
  },
  'Atenção, ao trocar o proprietário da conta, você se tornará administrador e só o novo proprietário poderá desfazer essa ação.': {
    es: 'Ten en cuenta que al cambiar el propietario de la cuenta, se convertirá en administrador y sólo el nuevo propietario podrá deshacer esta acción.',
    en: 'Attention, when changing the account owner, you will become an administrator and only the new owner can undo this action.',
    fr: 'Attention, lorsque vous modifiez le propriétaire du compte, vous deviendrez administrateur et seul le nouveau propriétaire peut annuler cette action.',
  },
  'Tornar proprietário': {
    es: 'Cambiar propietario',
    en: 'Make owner',
    fr: 'Faire du propriétaire',
  },
  'Selecionar usuário': {
    es: 'Seleccionar usuario',
    en: 'Select User',
    fr: 'Sélectionner l\'utilisateur',
  },
  Confirmar: {
    es: 'Confirmar',
    en: 'Confirm',
    fr: 'Confirmer',
  },
  'Atenção, ao trocar o proprietário da organização, seu perfil se tornará de administrador.': {
    es: 'Ten en cuenta que cuando cambies el propietario de la organización, tu perfil pasará a ser de administrador.',
    en: 'Please note, when changing the organization owner, your profile will become an administrator.',
    fr: 'Veuillez noter que lorsque vous modifiez le propriétaire de l\'organisation, votre profil deviendra administrateur.',
  },
  'Proprietário alterado com sucesso.': {
    es: 'El propietario ha cambiado con éxito.',
    en: 'Owner successfully changed.',
    fr: 'Le propriétaire a changé avec succès.',
  },
  'Não é possível remover a si próprio': {
    es: 'No puedes elimiarte a ti mismo',
    en: 'Cannot remove itself',
    fr: 'Ne peut pas se retirer',
  },
  'Remover logomarca': {
    es: 'Eliminar el logotipo',
    en: 'Remove logo',
    fr: 'Supprimer le logo',
  },
  'A logomarca foi ajustada à altura de 48 pixels.': {
    es: 'El logotipo se ajustó a una altura de 48 píxeles.',
    en: 'The logo was adjusted to the height of 48 pixels.',
    fr: 'Le logo a été ajusté à la hauteur de 48 pixels.',
  },
  'via ZapSign': {
    es: 'via ZapSign',
    en: 'via ZapSign',
    fr: 'via zapsign',
  },
  'Página cadastrada com sucesso!': {
    es: '¡Página registrada con éxito!',
    en: 'Successfully registered page!',
    fr: 'Page enregistrée avec succès!',
  },
  Ativada: {
    es: 'Activado',
    en: 'Activated',
    fr: 'Activé',
  },
  'Acesse sua página:': {
    es: 'Accede a tu página:',
    en: 'Access your page:',
    fr: 'Accédez à votre page:',
  },
  'Desabilitar API': {
    es: 'Deshabilitar la API',
    en: 'Disable API',
    fr: 'Désactiver l\'API',
  },
  'Renovar Token de Acesso': {
    es: 'Renovar el token de acceso',
    en: 'Renew Access Token',
    fr: 'Renouveler Token',
  },
  'Renovar Token': {
    es: 'Renovar el Token',
    en: 'Renew Token',
    fr: 'Renouveler Token',
  },
  'Tem certeza de que deseja renovar o token de acesso à API? As integrações que você fez deixarão de funcionar.': {
    es: '¿Estás seguro de renovar el token de acceso API? Las integraciones que hayas realizado dejarán de funcionar.',
    en: 'Are you sure you want to renew the API access token? The integrations you have made will stop working.',
    fr: 'Êtes-vous sûr de vouloir renouveler le jeton d\'accès API? Les intégrations que vous avez faites cesseront de fonctionner.',
  },
  'Tem certeza de que deseja desativar a API? As integrações que você fez deixarão de funcionar.': {
    es: '¿Estás seguro de deshabilitar la API? Las integraciones que hayas realizado dejarán de funcionar.',
    en: 'Are you sure you want to disable the API? The integrations you have made will stop working.',
    fr: 'Êtes-vous sûr de vouloir désactiver l\'API? Les intégrations que vous avez faites cesseront de fonctionner.',
  },
  'Você perderá acesso a API': {
    es: 'Perderás acceso a la API',
    en: 'You will lose API access',
    fr: 'Vous perdrez l\'accès à l\'API',
  },
  'Você receberá um novo token. Sem volta': {
    es: 'Recibirás un nuevo token. Sin retorno.',
    en: 'You will receive a new token. No return.',
    fr: 'Vous recevrez un nouveau jeton. Non-retour.',
  },
  'Cancelar edição': {
    es: 'Cancelar la edición',
    en: 'Cancel edit',
    fr: 'Annuler Modifier',
  },
  'Não há registros a mostrar': {
    es: 'No hay registros para mostrar',
    en: 'There are no records to show.',
    fr: 'Il n\'y a aucun enregistrement à montrer.',
  },
  'Conteúdo copiado!': {
    es: '¡Contenido copiado!',
    en: 'Content copied!',
    fr: 'Contenu copié!',
  },
  'Carregado com sucesso!': {
    es: '¡Subido con éxito!',
    en: 'Uploaded successfully!',
    fr: 'Téléchargé avec succès!',
  },
  'Ocorreu um erro para coletar os logs. Por favor tente novamente mais tarde.': {
    es: 'Se produjo un error al recopilar registros. Por favor, inténtalo de nuevo más tarde.',
    en: 'An error occurred to collect logs. Please try again later.',
    fr: 'Une erreur s\'est produite pour collecter des journaux. Veuillez réessayer plus tard.',
  },
  'Últimos webhooks emitidos. Os registros são apagados após 7 dias.': {
    es: 'Últimos webhooks publicados. Los registros se eliminan después de 7 días.',
    en: 'Latest webhooks issued. Records are deleted after 7 days.',
    fr: 'Les derniers webhooks émis. Les enregistrements sont supprimés après 7 jours.',
  },
  Mostrar: {
    es: 'Mostrar',
    en: ' Show',
    fr: ' Montrer',
  },
  'Somente falhas (status != 200)': {
    es: 'Solo fallos (estado! = 200)',
    en: 'Only failures (status != 200)',
    fr: 'Seuls échecs (statut! = 200)',
  },
  Atualizar: {
    es: 'Actualización',
    en: 'Update',
    fr: 'Mise à jour',
  },
  'Adicionar URL webhook': {
    es: 'Agregar la URL del webhook',
    en: 'Add webhook URL',
    fr: 'Ajouter une URL de webhook',
  },
  'Adicionar novo webhook': {
    es: 'Agregar nuevo webhook',
    en: 'add new webhook',
    fr: 'Ajouter un nouveau webhook',
  },
  'Webhook adicionado.': {
    es: 'Se ha añadido un webhook.',
    en: 'Webhook added.',
    fr: 'Webhook ajouté.',
  },
  'Fechar edição': {
    es: 'Cerrar la edición',
    en: 'Close edition',
    fr: 'Édition rapprochée',
  },
  'Remover webhook': {
    es: 'Eliminar el webhook',
    en: 'Remove webhook',
    fr: 'Supprimer webhook',
  },
  'Webhook removido.': {
    es: 'Se ha eliminado el webhook.',
    en: 'Webhook removed.',
    fr: 'Webhook supprimé.',
  },
  'Você não possui permissão para ver os documentos excluídos.': {
    es: 'No tienes permiso para ver los documentos borrados.',
    en: 'You do not have permission to view deleted documents.',
    fr: 'Vous n\'avez pas l\'autorisation d\'afficher des documents supprimés.',
  },
  'Não há documentos na lixeira.': {
    es: 'No hay documentos en la papelera.',
    en: 'There are no documents in the trash.',
    fr: 'Il n\'y a pas de documents dans la poubelle.',
  },
  'Tem certeza de que deseja excluir a pasta': {
    es: '¿Estás seguro de eliminar la carpeta',
    en: 'Are you sure you want to delete the folder',
    fr: 'Êtes-vous sûr de vouloir supprimer le dossier',
  },
  'Excluir pasta (sem volta)': {
    es: 'Eliminar carpeta (sin retorno)',
    en: 'Delete folder (no return)',
    fr: 'Supprimer le dossier (pas de retour)',
  },
  'Manter pasta': {
    es: 'Mantener la carpeta',
    en: 'Keep folder',
    fr: 'Garder le dossier',
  },
  'Pasta excluída com sucesso.': {
    es: 'Carpeta eliminada con éxito.',
    en: 'Folder deleted successfully.',
    fr: 'Dossier supprimé avec succès.',
  },
  'Documento excluído com sucesso.': {
    es: 'Documento eliminado con éxito.',
    en: 'Document deleted  successfully',
    fr: 'Document supprimé avec succès',
  },
  'Documento excluído com sucesso e disponível na lixeira.': {
    es: 'Documento eliminado con éxito y disponible en la papelera.',
    en: 'Document successfully deleted and available in the trash.',
    fr: 'Documez avec succès supprimé et disponible dans la corbeille.',
  },
  'documento selecionado': {
    es: 'documento seleccionado',
    en: 'selected document',
    fr: 'document sélectionné',
  },
  'Caso este seja um email de usuário válido, você receberá um link para recuperação da senha via e-mail!': {
    es: 'Si este es un email de usuario válido, recibirás un correo eletrónico de recuperación de contraseña en tu bandeja de entrada.',
    en: 'If this is a valid user email, you will receive a password recovery link via email. Please check your inbox!',
    fr: 'S\'il s\'agit d\'un e-mail utilisateur valide, vous recevrez un lien de récupération de mot de passe par e-mail. Veuillez cocher votre boîte de réception!',
  },
  Cadastro: {
    es: 'Registro',
    en: 'Registration',
    fr: 'Inscription',
  },
  'Crie sua conta grátis': {
    es: 'Crea tu cuenta gratis',
    en: 'Create your free account',
    fr: 'Créez votre compte gratuit',
  },
  'Criar conta com Google': {
    es: 'Crear cuenta con Google',
    en: 'Create with Google',
    fr: 'Créer avec Google',
  },
  'Criar conta com Facebook': {
    es: 'Crear cuenta con Facebook',
    en: 'Create with Facebook',
    fr: 'Créer avec Facebook',
  },
  'Senha (mín. 6 caracteres)': {
    es: 'Contraseña (mínimo 6 caracteres)',
    en: 'Password (min. 6 characters)',
    fr: 'Mot de passe (min. 6 caractères)',
  },
  'CRIAR CONTA': {
    es: 'CREAR CUENTA',
    en: 'CREATE AN ACCOUNT',
    fr: 'CRÉER UN COMPTE',
  },
  'Ao prosseguir, você aceita os termos de uso e a política de privacidade da ZapSign.': {
    es: 'Al continuar, aceptas las condiciones de uso y la política de privacidad de ZapSign.',
    en: 'By proceeding, you accept ZapSign\'s terms of use and privacy policy.',
    fr: 'En procédant, vous acceptez les conditions d\'utilisation des conditions de Zapsign et la politique de confidentialité.',
  },
  'Já possuo uma conta': {
    es: 'Ya tengo una cuenta',
    en: 'I already have an account',
    fr: 'Je ai déjà un compte',
  },
  'Já existe uma conta com esse e-mail.': {
    es: 'Ya existe una cuenta con ese correo electrónico.',
    en: 'There is already an account with this email.',
    fr: 'Il y a déjà un compte avec cet e-mail.',
  },
  'Criando sua conta...': {
    es: 'Creando tu cuenta...',
    en: 'Creating your account...',
    fr: 'Création de votre compte...',
  },
  'Login realizado com sucesso.': {
    es: 'Inicio de sesión exitoso.',
    en: 'Login successful.',
    fr: 'Connexion réussie.',
  },
  'criado por $0 em $1': {
    es: 'creado por $0 el $1',
    en: 'created by $0 on $1',
    fr: 'créé par $0 sur $1',
  },
  'Contato criado por $0 em $1': {
    es: 'Contacto creado por $0 el $1',
    en: 'Contact created by $0 on $1',
    fr: 'Contact créé par $0 sur $1',
  },
  'excluído por $0 em $1': {
    es: 'borrado por $0 el $1',
    en: 'deleted by $0 on $1',
    fr: 'supprimé par $0 sur $1',
  },
  'excluir definitivamente': {
    es: 'eliminar definitivamente',
    en: 'permanently delete',
    fr: 'Effacé définitivement',
  },
  recuperar: {
    es: 'recuperar',
    en: 'recover',
    fr: 'récupérer',
  },
  'Documento recuperado com sucesso!': {
    es: '¡Documento recuperado con éxito!',
    en: 'Document retrieved successfully!',
    fr: 'Document récupéré avec succès!',
  },
  'Documentos deletados com sucesso.': {
    es: 'Documentos eliminados con éxito.',
    en: 'Documents successfully deleted.',
    fr: 'Documents avec succès supprimé.',
  },
  ano: {
    es: 'año',
    en: 'year',
    fr: 'année',
  },
  'Novo modelo': {
    es: 'Nueva plantilla',
    en: 'New template',
    fr: 'Nouveau modèle',
  },
  'Escolha o tipo de modelo': {
    es: 'Elige el tipo de plantilla',
    en: 'Choose the template type',
    fr: 'Choisir le type de modèle',
  },
  'Modelo PDF': {
    es: 'Plantilla PDF',
    en: 'PDF Template',
    fr: 'Modèle PDF',
  },
  'Envie um documento idêntico, sem variações, para vários signatários assinarem separadamente.': {
    es: 'La plantilla PDF puede tener o no campos dinámicos. Estos campos se posicionan en el archivo PDF durante la creación.',
    en: 'Submit an identical document, with no variations, for multiple signers to sign separately.',
    fr: 'Le modèle PDF peut comporter ou non des champs dynamiques. Ces champs sont positionnés dans le fichier PDF lors de sa création.',
  },
  'Modelo Docx': {
    es: 'Plantilla Docx',
    en: 'Docx Template',
    fr: 'Modèle Docx',
  },
  'Somente PDF': {
    es: 'Solo PDF',
    en: 'PDF only',
    fr: 'PDF uniquement',
  },
  'arquivo Word (.docx)': {
    es: 'archivo Word (.docx)',
    en: 'Word file (.docx)',
    fr: 'Word en .docx',
  },
  'inscrito no CPF sob o n.': {
    es: 'registrado en la CPF con el n.',
    en: 'registered with the CPF under n.',
    fr: 'enregistré auprès du CPF sous N.',
  },
  'NOME COMPLETO': {
    es: 'NOMBRE COMPLETO',
    en: 'FULL NAME',
    fr: 'VOTRE NOM',
  },
  'Substituir todos': {
    es: 'Reemplaza todos los',
    en: 'Replace',
    fr: 'Remplacer tous les',
  },
  'Ser salvo como um': {
    es: 'Guardarla como un ',
    en: 'Be saved as a',
    fr: 'Sauvegarder sous forme de',
  },
  'modelo docx': {
    es: 'plantilla docx',
    en: 'docx template',
    fr: 'modèle docx',
  },
  'Atenção: Como configurar o modelo docx': {
    es: 'Atención: cómo configurar la plantilla docx',
    en: 'Attention: How to set up the docx template',
    fr: 'Attention: comment configurer le modèle docx',
  },
  'Ex: "O presente termo, firmado entre XPTO Ltda. e': {
    es: 'Ej .: "Este término, firmado entre XPTO Ltda. Y',
    en: 'E.g.: "This term, signed between XPTO Ltda. and',
    fr: 'Ex : Cette clause, signée entre XPTO Ltd. et',
  },
  'assets/modelo/Exemplo-Modelo-Dinamico.docx': {
    es: 'assets/modelo/Ejemplo-plantilla-dinamica.docx',
    en: 'assets/modelo/Example-dynamic-template.docx',
    fr: 'Assets / Modelo / Example-Dynten-Template.docx',
  },

  'residente em': {
    es: 'residente en',
    en: 'live at',
    fr: 'vivre à',
  },
  'sem variações, para vários signatários assinarem separadamente': {
    es: 'sin variaciones, para que varias personas firmen por separado',
    en: 'no variations, for multiple signers to sign separately',
    fr: 'Aucune variation, pour que plusieurs signataires puissent signer séparément',
  },
  O: {
    es: 'La',
    en: 'The',
    fr: 'Le',
  },
  'documento dinâmico': {
    es: 'documento dinámico',
    en: 'dynamic document',
    fr: 'document dynamique',
  },
  'Verifique seu arquivo': {
    es: 'Revisa tu archivo',
    en: 'Check your file',
    fr: 'Vérifiez votre fichier',
  },
  'Não conseguimos encontrar': {
    es: 'No pudimos encontrar',
    en: 'We couldn\'t find',
    fr: 'Nous n\'avons pas pu trouver',
  },
  'Não foi possível encontrar campos dinâmicos. Lembre-se de que o arquivo deve ser .docx e os campos dinâmicos devem estar entre': {
    es: 'No hemos podido encontrar ningún campo dinámico. Recuerda que el archivo debe ser .docx y los campos dinámicos deben estar entre',
    en: 'We could not find any dynamic fields. Remember that the file must be .docx and the dynamic fields must be between',
    fr: 'Nous n\'avons pu trouver aucun champ dynamique. N\'oubliez pas que le fichier doit être .docx et que les champs dynamiques doivent être entre',
  },
  'Não conseguimos encontrar {{CAMPOS DINÂMICOS}} no seu Word (.docx). Verifique novamente': {
    es: 'No pudimos encontrar {{CAMPOS DINÁMICOS}} en tu documento Word (.docx). Revisa nuevamente',
    en: 'We couldn\'t find {{DYNAMIC FIELDS}} in your Word (.docx). Check again',
    fr: 'Nous n\'avons pas pu trouver {{Dynamic Fields}} dans votre mot (.docx). Revérifier',
  },
  '{{CAMPO DINÂMICO}}': {
    es: '{{CAMPO DINÁMICO}}',
    en: '{{DYNAMIC FIELD}}',
    fr: '{{Champ dynamique}}',
  },
  'no seu Word (.docx)': {
    es: 'en tu documento (.docx)',
    en: 'in your Word (.docx)',
    fr: 'dans ta parole (.docx)',
  },
  'Envie um': {
    es: 'Envía un',
    en: 'Send a',
    fr: 'Envoyer un',
  },
  'documento idêntico': {
    es: 'documento idéntico',
    en: 'identical document',
    fr: 'document identique',
  },
  'a ser preenchido conforme informações de cada signatário e contrato': {
    es: 'debe completarse de acuerdo a la información y el contrato de cada firmante',
    en: 'to be completed according to each signer\'s information and contract',
    fr: 'à terminer en fonction des informations et du contrat de chaque signataire',
  },
  'O modelo PDF não possui campos para preenchimento e é ideal para documentos em que não há variações, como termos de adesão, aceite, livros de presença e atas de reunião.': {
    es: 'La plantilla PDF no tiene campos que diligenciar y es ideal para documentos en los que no hay variaciones, como las condiciones de membresía, los libros de asistencia y las actas de reuniones.',
    en: 'The PDF template has no fields to fill out and is ideal for documents where there are no variations, such as terms of membership, attendance books and meeting minutes.',
    fr: 'Le modèle PDF n\'a pas de champs à remplir et est idéal pour les documents où il n\'y a pas de variations, telles que les termes d\'adhésion, les livres de fréquentation et les procès-verbaux de la réunion.',
  },
  'possui campos para preenchimento e se adequa a documentos nos quais há variações como os dados pessoais do signatário, como contratos e procurações.': {
    es: 'tiene campos para diligenciar por el firmante o antes de enviarlo. Estos campos deben estar especificados dentro del documento antes de subirlo a la plataforma. Es ideal cuando el documento tiene en su mayoría texto como contratos de trabajo.',
    en: 'has fields to be filled out by the signer or before sending it. These fields must be specified within the document before uploading it to the platform. It is ideal when the document consists mostly of text, such as employment contracts.',
    fr: 'a des champs à remplir par le signataire ou avant de l\'envoyer. Ces champs doivent être spécifiés dans le document avant de le télécharger sur la plate-forme. Il est idéal lorsque le document a principalement des SMS tels que des contrats de travail.',
  },
  'Escolha o nome do modelo': {
    es: 'Elige el nombre de tu plantilla',
    en: 'Type the template name',
    fr: 'Choisissez le nom de votre modèle',
  },
  'Documento indisponível': {
    es: 'Documento no disponible',
    en: 'Document unavailable',
    fr: 'Document indisponible',
  },
  'Este link não está mais disponível. Fale com quem o enviou': {
    es: 'Este enlace ya no está disponible. Habla con quien lo envió',
    en: 'This link is no longer available. Talk to whoever sent it',
    fr: 'Ce lien n\'est plus disponible. Parlez à celui qui l\'a envoyé',
  },
  'Qual seu nome completo?': {
    es: '¿Cuál es tu nombre completo?',
    en: 'What is your full name?',
    fr: 'Quel est ton nom complet?',
  },
  'Seu Nome': {
    es: 'Nombre Completo',
    en: 'Your Name',
    fr: 'votre nom',
  },
  'Digite aqui...': {
    es: 'Escribe aquí...',
    en: 'Type here...',
    fr: 'Écrivez ici...',
  },
  'Formulário pré-preenchido criado com sucesso!': {
    es: '¡Formulario pre-diligenciado creado correctamente!',
    en: 'Successfully created pre-filled form!',
    fr: 'Formulaire pré-rempli créé avec succès!',
  },
  'Manter modelo': {
    es: 'Mantener la plantilla',
    en: 'Keep template',
    fr: 'Garder le modèle',
  },
  'Excluir modelo (sem volta)': {
    es: 'Eliminar plantilla (sin devolución)',
    en: 'Delete template (no return)',
    fr: 'Supprimer le modèle (pas de retour)',
  },
  'Tem certeza de que deseja excluir o modelo?': {
    es: '¿Está seguro de eliminar la plantilla?',
    en: 'Are you sure you want to delete the template?',
    fr: 'Êtes-vous sûr de vouloir supprimer le modèle?',
  },
  'Obrigatório enviar': {
    es: 'Requerido para enviar',
    en: 'Required to send',
    fr: 'Requis pour envoyer',
  },
  'Notificar próximo signatário por e-mail automaticamente após anterior assinar': {
    es: 'Notificar automáticamente al próximo firmante por email después de que el anterior firmante firme',
    en: 'Automatically notify next signer by email after previous signer signs',
    fr: 'Informer automatiquement le signataire suivant par e-mail après les panneaux de signataires précédents',
  },
  '$0 removido (limite 6 destinatários atingidos)': {
    es: '$0 eliminado (límite de 6 destinatarios alcanzado)',
    en: '$0 removed (limit 6 recipients reached)',
    fr: '$0 supprimé (limiter 6 destinataires atteints)',
  },
  'Por favor, o link deve começar com \'http://\' ou \'https://\' como nos exemplos.': {
    es: 'Por favor, el enlace debe comenzar con \'http: //\' o \'https: //\' como en los ejemplos.',
    en: 'Please, the link must start with \'http://\' or \'https://\' as in the examples.',
    fr: 'S\'il vous plaît, le lien doit commencer avec «http: //» ou «https: //» comme dans les exemples.',
  },
  'Opções para seleção. Separe-as com ponto e vírgula (;)': {
    es: 'Opciones de selección. sepárelos con punto y coma (;)',
    en: 'Options for selection. separate them with a semicolon (;)',
    fr: 'Options de sélection. séparez-les avec un point-virgule (;)',
  },
  'Exemplo: Plano Profissional; Plano Completo': {
    es: 'Ejemplo: plan profesional; plan completo',
    en: 'Example: Professional Plan; Complete Plan',
    fr: 'Exemple: plan professionnel; Plan complet',
  },
  'Opção A; Opção B; Opção C': {
    es: 'Opción A; Opción B; Opción C',
    en: 'Option A; Option B; Option C',
    fr: 'Option A; Option B; Option C',
  },
  '$0 removido (e-mail inválido)': {
    es: '$0 eliminado (correo electrónico no válido)',
    en: '$0 removed (invalid email)',
    fr: '$0 supprimé (e-mail non valide)',
  },
  'Ex: fulano@empresa.com.br, siclano@empresa.com.br': {
    es: 'Ex: fulano@empresa.com.br, siclano@empresa.com.br',
    en: 'Ex: fulano@empresa.com.br, siclano@empresa.com.br',
    fr: 'Ex: fulano@empresa.com.br, siclano@empresa.com.br',
  },
  'Analisando modelo dinâmico...': {
    es: 'Analizando la plantilla dinámica...',
    en: 'Analyzing dynamic template...',
    fr: 'Analyse du modèle dynamique ...',
  },
  'Campos dinâmicos encontrados com sucesso': {
    es: 'Campos dinámicos encontrados con éxito',
    en: 'Dynamic fields found successfully',
    fr: 'Les champs dynamiques ont été trouvés avec succès',
  },
  'Modelo criado com sucesso! Você já pode compartilhá-lo.': {
    es: 'Plantilla creada con éxito! Ahora puedes compartirla.',
    en: 'Template created successfully! You can now share it.',
    fr: 'Modèle créé avec succès! Vous pouvez maintenant le partager.',
  },
  'Ex: Comprovante de Residência': {
    es: 'Ej: Prueba de residencia',
    en: 'E.g.: Proof of Residence',
    fr: 'Par exemple: preuve de résidence',
  },
  'Ex: fulano@empresa.com.br': {
    es: 'Ej: juan@empresa.com',
    en: 'Ex: johnny@company.com',
    fr: 'Ex: Johnny@company.com',
  },
  'https://www.meusiteaqui.com/agradecimento': {
    es: 'https://www.site.com/gracias',
    en: 'https://www.site.com/thankspage',
    fr: 'https://www.site.com/thankspage',
  },
  'Total documentos:': {
    es: 'Total de documentos:',
    en: 'Total documents:',
    fr: 'Documents totaux:',
  },
  Ativado: {
    es: 'Activado',
    en: 'Activated',
    fr: 'Activé',
  },
  ATIVADO: {
    es: 'ACTIVADO',
    en: 'ACTIVATED',
    fr: 'ACTIVÉ',
  },
  'Compartilhe o link público abaixo no seu site, WhatsApp, e-mail ou qualquer outro canal que você preferir.': {
    es: 'Comparte el enlace público que aparece a continuación en tu página web, WhatsApp, correo electrónico o cualquier otro canal que prefieras.',
    en: 'Share the public link below on your website, WhatsApp, email or any other channel you prefer.',
    fr: 'Partagez le lien public ci-dessous sur votre site Web, WhatsApp, e-mail ou tout autre canal que vous préférez.',
  },
  'Método de autenticação do signatário': {
    es: 'Método de autentificación del firmante',
    en: 'Signer authentication method',
    fr: 'Méthode d\'authentification des signataires',
  },
  'Foto selfie (rosto)': {
    es: 'Foto selfie (cara)',
    en: 'selfie photo (face)',
    fr: 'Photo de selfie (visage)',
  },
  'Foto documento pessoal': {
    es: 'Foto documento identidad',
    en: 'Photo personal document',
    fr: 'Photo Document personnel',
  },
  'Posicionar campos': {
    es: 'Posicionar campos',
    en: 'Place fields',
    fr: 'Champs de positionnement',
  },
  'Personalização dos campos dinâmicos': {
    es: 'Personalización de los campos dinámicos',
    en: 'Customization of dynamic fields',
    fr: 'Personnalisation des champs dynamiques',
  },
  Obrigatório: {
    es: 'Obligatorio',
    en: 'Required',
    fr: 'Requis',
  },
  'Título da pergunta': {
    es: 'Título de la pregunta',
    en: 'Question title',
    fr: 'Titre de question',
  },
  'A data será automaticamente detectada pelo sistema, então a pergunta não aparecerá no formulário.': {
    es: 'La fecha será detectada automáticamente por el sistema, por lo que la pregunta no aparecerá en el formulario.',
    en: 'The date will be automatically detected by the system, so the question will not appear on the form.',
    fr: 'La date sera automatiquement détectée par le système, de sorte que la question n\'apparaîtra pas sur le formulaire.',
  },
  'Resposta curta': {
    es: 'Respuesta corta',
    en: 'Short answer',
    fr: 'Réponse courte',
  },
  Tipo: {
    es: 'Tipo',
    en: 'Type',
    fr: 'Taper',
  },
  Texto: {
    es: 'Texto',
    en: 'Text',
    fr: 'Texte',
  },
  'Texto de ajuda': {
    es: 'Texto de ayuda',
    en: 'Help text',
    fr: 'Texte d\'aide',
  },
  'Pasta de destino': {
    es: 'Carpeta destino',
    en: 'Destination Folder',
    fr: 'Dossier de destination',
  },
  'Editar modelo': {
    es: 'Editar plantilla',
    en: 'Edit template',
    fr: 'Modifier le modèle',
  },
  'Os documentos criados a partir deste modelo serão inseridos na pasta abaixo. Para subpastas, separe-as com barra / (ex. pasta 1/subpasta a/subpasta b)': {
    es: 'Los documentos creados a partir de esta plantilla se guardarán en la siguiente carpeta. Para las subcarpetas, sepáralas con una barra / (por ejemplo, carpeta 1/subcarpeta a/subcarpeta b)',
    en: 'Documents created from this template will be placed in the folder below. For subfolders, separate them with a slash / (eg folder 1/subfolder a/subfolder b)',
    fr: 'Les documents créés à partir de ce modèle seront enregistrés dans le dossier sélectionné.',
  },
  'Caso não exista, a pasta será criada automaticamente.': {
    es: 'Si no existe, la carpeta se creará automáticamente.',
    en: 'If it doesn\'t exist, the folder will be created automatically.',
    fr: 'S\'il n\'existe pas, le dossier sera créé automatiquement.',
  },
  'Pedir que o signatário suba documentos': {
    es: 'Pedir al firmante que cargue los documentos',
    en: 'Ask the signer to upload documents',
    fr: 'Demander au signataire de télécharger les documents',
  },
  'Deseja pedir que o signatário anexe arquivos no final do formulário? Ex: comprovantes, documentos etc.': {
    es: '¿Quieres solicitar que el firmante adjunte archivos al final del formulario? Por ejemplo, vales, documentos, etc.',
    en: 'Do you want to ask the signer to attach files to the end of the form? Ex: vouchers, documents etc.',
    fr: 'Voulez-vous demander au signataire d\'attacher des fichiers à la fin du formulaire? Ex: bons, documents, etc.',
  },
  'Digite o nome do documento que deseja': {
    es: 'Introduce el nombre del documento',
    en: 'Enter the name of the document',
    fr: 'Indiquez le nom du document que vous souhaitez',
  },
  'Você pode adicionar automaticamente um segundo signatário padrão no documento. Obs: também é possível adicionar signatários manualmente, após a criação de cada documento.': {
    es: 'Puedes agregar automáticamente un segundo firmante por defecto al documento. Nota: también puedes agregar firmantes manualmente, después de la creación de cada documento.',
    en: 'You can automatically add a second default signer to the document. Note: It is also possible to add signers manually, after creating each document.',
    fr: 'Vous pouvez automatiquement ajouter un deuxième signataire par défaut au document. Remarque: il est également possible d\'ajouter des signataires manuellement, après avoir créé chaque document.',
  },
  'Segundo Signatário (e-mail)': {
    es: 'Segundo firmante (correo electrónico)',
    en: 'Second Signer (email)',
    fr: 'Deuxième signataire (e-mail)',
  },
  'Notificações externas': {
    es: 'Notificaciones externas',
    en: 'External notifications',
    fr: 'Notifications externes',
  },
  'Sempre que este Modelo for assinado por todos signatários, alguém mais deve receber a cópia do documento assinado?': {
    es: 'Si esta plantilla está firmada por todos los firmantes, ¿debe recibir alguien más la copia del documento firmado?',
    en: 'Whenever this Template is signed by all signers, should anyone else receive a copy of the signed document?',
    fr: 'Si ce modèle est signé par tous les signataires, quelqu\'un d\'autre doit-il recevoir une copie du document signé ?',
  },
  'E-mails destinatários (limite de 6). Separe-os com vírgula:': {
    es: 'Correos electrónicos de los destinatarios (límite de 6). Sepáralos con iuna coma:',
    en: 'Recipient emails (limit of 6). Separate them with a comma:',
    fr: 'Emails des destinataires (limite de 6). Séparez-les avec une virgule:',
  },
  'Link para redirecionamento após assinatura': {
    es: 'Link de redirección después de la firma',
    en: 'Redirect link after signature',
    fr: 'Lien de redirection après la signature',
  },
  'Defina o link para o qual o usuário será direcionado após assinar o documento (ex: página de agradecimento do seu site).': {
    es: 'Define el enlace al que se dirigirá el usuario después de firmar el documento (por ejemplo, la página de agradecimiento de tu sitio web).',
    en: 'Define the link the user will be directed to after signing the document (eg your website\'s thank you page).',
    fr: 'Définit le lien vers lequel l\'utilisateur sera dirigé après avoir signé le document (par exemple, la page de remerciement de votre site web).'
  },
  Link: {
    es: 'Enlace',
    en: 'Link',
    fr: 'Lien',
  },
  'Deve começar com http:// ou https:// e máximo de 510 caracteres.': {
    es: 'Debe empezar por http:// o https:// y tener un máximo de 510 caracteres.',
    en: 'Must start with http:// or https:// and maximum 510 characters.',
    fr: 'Il doit commencer par http:// ou https:// et comporter au maximum 510 caractères.',
  },
  'Enquanto o modelo estiver ativo, seu link estará disponível para criação de novos documentos.': {
    es: 'Mientras la plantilla esté activa, tu enlace estará disponible para crear nuevos documentos.',
    en: 'As long as the template is active, the link will be available for creating new documents.',
    fr: 'Tant que le modèle est actif, le lien sera disponible pour créer de nouveaux documents.',
  },
  'Baixar modelo': {
    es: 'Descargar plantilla',
    en: 'Download template',
    fr: 'Télécharger le modèle',
  },
  'Editar o nome do modelo': {
    es: 'Editar el nombre de la plantilla',
    en: 'Edit template name',
    fr: 'Modifier le nom du modèle',
  },
  'Excluir modelo': {
    es: 'Borrar plantilla',
    en: 'Delete template',
    fr: 'Supprimer',
  },
  Excluir: {
    es: 'Borrar',
    en: 'Delete',
    fr: 'Supprimer',
  },
  Mover: {
    es: 'Mover',
    en: 'Move',
    fr: 'Se déplacer',
  },
  'Defina como o signatário deve se autenticar para assinar o modelo. Ainda não é possível inserir mais de um signatário.': {
    es: 'Define cómo debe autenticarse el firmante para firmar la plantilla. Todavía no es posible introducir más de un firmante.',
    en: 'Define how the signer must authenticate to sign the template. It is not yet possible to enter more than one signer.',
    fr: 'Définissez comment le signataire doit s\'authentifier pour signer le modèle. Il n\'est pas encore possible d\'entrer plus d\'un signataire.',
  },
  'Escolha como o signatário irá ver os campos dinâmicos para preenchimento, com títulos e textos de ajuda mais amigáveis.': {
    es: 'Elige cómo verá el firmante los campos dinámicos para diligenciar, con títulos y texto de ayuda más fáciles de usar.',
    en: 'Choose how the signer will see the dynamic fields to fill, with friendly titles and help texts.',
    fr: 'Choisissez comment le signataire verra le formulaire en définissant l\'ordre, les titres et les textes d\'aide de manière plus conviviale.',
  },
  'campos dinâmicos': {
    es: 'campos dinámicos',
    en: 'dynamic fields',
    fr: 'champs dynamiques',
  },
  'Somente Word em .docx': {
    es: 'solo Word en .docx',
    en: 'only Word in .docx',
    fr: 'Seulement mot dans .docx',
  },
  'Seu modelo docx deve': {
    es: 'Para tu planitlla docx debes',
    en: 'Your docx dynamic must',
    fr: 'Votre dynamique Docx doit',
  },
  'Mover campo para cima': {
    es: 'Mover el campo hacia arriba',
    en: 'Move go up field',
    fr: 'Déplacer le champ monte',
  },
  'Mover campo para baixo': {
    es: 'Mover el campo hacia abajo',
    en: 'move field down',
    fr: 'Déplacer le champ vers le bas',
  },
  'Atenção: link está inacessível devido ao modelo estar desativado.': {
    es: 'Nota: el enlace es inaccesible porque la plantilla está desactivada.',
    en: 'Attention: link is inaccessible due to template being disabled.',
    fr: 'Attention: le lien est inaccessible en raison de la désactivation du modèle.',
  },
  'Preencha os campos que quiser...': {
    es: 'Diligencia los campos que quieras...',
    en: 'Fill in the fields you want...',
    fr: 'Remplissez les champs que vous voulez ...',
  },
  'Formulário pré-preenchido:': {
    es: 'Formulario pre-diligenciado:',
    en: 'Pre-filled form:',
    fr: 'Formulaire pré-rempli:',
  },
  'Criar formulário pré-preenchido': {
    es: 'Crear form pre-diligenciado',
    en: 'Create pre-filled form',
    fr: 'Créer un formulaire pré-rempli',
  },
  'Nome completo do Signatário': {
    es: 'Nombre completo del firmante',
    en: 'Signer\'s full name',
    fr: 'Nom complet du signataire',
  },
  'Múltipla escolha': {
    es: 'Opción múltiple',
    en: 'Multiple choice',
    fr: 'Choix multiple',
  },
  'Caixas de seleção': {
    es: 'Cajas de selección',
    en: 'Check boxes',
    fr: 'Cocher les cases',
  },
  'Telefone (Brasil)': {
    es: 'Teléfono (Brasil)',
    en: 'Phone number (Brazil)',
    fr: 'Numéro de Portable (Brésil)',
  },
  'Dinheiro (R$)': {
    es: 'Efectivo (R$)',
    en: 'Money (BRL - R$)',
    fr: 'Money (brl - r $)',
  },
  'Data de assinatura (por extenso)': {
    es: 'Fecha de la firma (por escrito)',
    en: 'Signature date (full)',
    fr: 'Date de signature (complet)',
  },
  'Data de assinatura': {
    es: 'Fecha de la firma',
    en: 'Signature date',
    fr: 'Date de la signature',
  },
  'Data de assinatura (dd/mm/aaaa)': {
    es: 'Fecha de la firma (dd/mm/aaaa)',
    en: 'Signature date (mm/dd/yyyy)',
    fr: 'Date de signature (mm / dd / yyyy)',
  },
  'Adicionar novo documento': {
    es: 'Agregar nuevo documento',
    en: 'Add new document',
    fr: 'Ajouter un nouveau document',
  },
  'Por favor, insira o nome do documento.': {
    es: 'Introduce el nombre del documento.',
    en: 'Please enter the document\'s name',
    fr: ' Indiquez le nom du document',
  },
  'Adicionado com sucesso!': {
    es: '¡Añadido con éxito!',
    en: 'Successfully added!',
    fr: 'Ajouté avec succès!',
  },
  'Remover documento: \'aaa\'': {
    es: 'Eliminar el documento: "aaa"',
    en: 'Remove document: "aaa"',
    fr: 'Supprimer le document: "AAA"',
  },
  'Obrigatório enviar: \'aaa\'': {
    es: 'Obligatorio enviar "aaa"',
    en: 'Required:   "aaa"',
    fr: 'Requis: "AAA"',
  },
  'Por favor, o link deve começar com "http://" ou "https://" como nos exemplos.': {
    es: 'Por favor, el enlace debe empezar por "http://" o "https://" como en los ejemplos.',
    en: 'Please, the link must start with "http://" or "https://" as in the examples.',
    fr: 'S\'il vous plaît, le lien doit commencer avec "http:// " ou "https://" comme dans les exemples.',
  },
  'removido (e-mail inválido)': {
    es: 'eliminado (correo electrónico no válido)',
    en: 'removed (invalid email)',
    fr: 'supprimé (e-mail non valide)',
  },
  'Removido com sucesso!': {
    es: 'Se ha eliminado con éxito.',
    en: 'Successfully removed!',
    fr: 'Supprimé avec succès!',
  },
  Opcional: {
    es: 'Opcional',
    en: 'Optional',
    fr: 'Facultatif',
  },
  'Posicione as assinaturas no documento  (Opcional)': {
    es: 'Posiciona las firmas en el documento (Opcional)',
    en: 'Place signatures on the document  (Optional)',
    fr: 'Apposer les signatures sur le document (Facultatif)',
  },
  'Posicione as assinaturas no documento': {
    es: 'Posiciona las firmas en el documento',
    en: 'Place signatures on the document',
    fr: 'Apposer les signatures sur le document',
  },
  'Como funciona:': {
    es: 'Cómo funciona:',
    en: 'How it works:',
    fr: 'Comment ça marche :',
  },
  '1. Clique sobre os campos de assinatura': {
    es: '1. Haz clic en la posición dentro del documento',
    en: '1. Click on the position within the documen',
    fr: '1. Cliquez sur les champs où vous voulez attribuer une signature',
  },
  'do seu documento.': {
    es: 'donde quieres posicionar las firmas, iniciales o textos.',
    en: 'where you want to place the signatures, initials, or text.',
    fr: 'pour placer les signatures, initiales ou textes.',
  },
  '2. Selecione o signatário': {
    es: '2. Selecciona el firmante',
    en: '2. Select the signer',
    fr: '2. Sélectionnez le signataire',
  },
  'e arraste as assinaturas nos locais desejados.': {
    es: 'y el campo a posicionar. Puedes cambiar el tamaño, la posición o duplicar los campos.',
    en: 'and the field to position. You can change the size, position, or duplicate the fields.',
    fr: 'et faites glisser les signatures aux positions souhaitées.',
  },
  'Signatário(a)': {
    es: 'Firmante',
    en: 'Signer',
    fr: 'Signataire',
  },
  'Alterar signatário': {
    es: 'Cambiar firmante',
    en: 'Change signer',
    fr: 'Changer de signataire',
  },
  'Posicione o item selecionado no(s) seu(s) documento(s)': {
    es: 'Posiciona el elemento seleccionado en tu(s) documento(s)',
    en: 'Place the selected item in your document(s)',
    fr: 'Placez l\'élément sélectionné dans votre ou vos documents',
  },
  'Onde se posicionará o item': {
    es: 'Dónde se colocará el elemento',
    en: 'Where will the item be placed',
    fr: 'Où l\'article sera-t-il placé',
  },
  'Onde a assinatura ficará': {
    es: 'Dónde quedará la firma',
    en: 'Where will the signature be',
    fr: 'Où sera la signature',
  },
  'Copiar nas próximas páginas': {
    es: 'Copiar en las próximas páginas',
    en: 'Copy on next pages',
    fr: 'Copier aux pages suivantes',
  },
  'Remover campo de assinatura': {
    es: 'Eliminar campo de firma',
    en: 'Remove signature field',
    fr: 'Supprimer le champ de signature',
  },
  '/assets/img/line-signature.png': {
    es: '/assets/img/line-signature-es.png',
    en: '/assets/img/line-signature-en.png',
    fr: '/Assets/img/line-Signature-en.png',
  },
  '/assets/img/line-visto.png': {
    es: '/assets/img/line-visto-es.png',
    en: '/assets/img/line-visto-en.png',
    fr: '/assets/img/line-visto-en.png',
  },
  'Documento possui apenas um signatário.': {
    es: 'El documento sólo tiene un firmante.',
    en: 'Document has only one signer.',
    fr: 'Le document n\'a qu\'un seul signataire.',
  },
  'Remover visto': {
    es: 'Eliminar iniciales',
    en: 'Remove initials',
    fr: 'Supprimer les initiales',
  },
  'o visto': {
    es: 'las iniciales',
    en: 'the initials',
    fr: 'les initiales',
  },
  'a assinatura': {
    es: 'la firma',
    en: 'the signature',
    fr: 'la signature',
  },
  'Por favor, posicione $0 100% dentro de alguma página do documento, exceto a última página.': {
    es: 'Posiciona $0 al 100% dentro de cualquier página del documento, excepto la última página.',
    en: 'Please place $0 100% within any page of the document, except the last page.',
    fr: 'Veuillez placer $0 100% dans n\'importe quelle page du document, à l\'exception de la dernière page.',
  },
  'Não foi possível copiar $0 nas próximas páginas, pois essa é a última página do documento.': {
    es: 'No fue posible copiar $0 en las páginas siguientes, ya que ésta es la última página del documento.',
    en: 'It was not possible to copy $0 on the following pages, as this is the last page of the document.',
    fr: 'Il n\'était pas possible de copier $0 sur les pages suivantes, car il s\'agit de la dernière page du document.',
  },
  'Assinaturas posicionadas com sucesso': {
    es: 'Firmas posicionadas con éxito',
    en: 'Signatures successfully placed',
    fr: 'Les signatures placées avec succès',
  },
  Assinaturas: {
    es: 'Firmas',
    en: 'Signatures',
    fr: 'Signatures',
  },
  'Carregando documento...': {
    es: 'Cargando documento ...',
    en: 'Loading document...',
    fr: 'Document de chargement ...',
  },
  Baixar: {
    es: 'Descargar',
    en: 'Download',
    fr: 'Télécharger',
  },
  'Confirmo que visualizei todos documentos': {
    es: 'Confirmo que he visto todos los documentos.',
    en: 'I confirm that I have viewed all documents.',
    fr: 'Je confirme que j\'ai vu tous les documents.',
  },
  Visualizar: {
    es: 'Ver',
    en: 'View',
    fr: 'Voir',
  },
  'Impedir que signatários baixem o documento assinado': {
    es: 'Evitar descargar el documento firmado',
    en: 'Prevent signers from downloading the signed document',
    fr: 'Empêcher les signataires de télécharger le document signé',
  },
  'Bloqueado. Clique para permitir alterações': {
    es: 'Bloqueado. Haz clic para permitir los cambios',
    en: 'Blocked. Click to allow changes',
    fr: 'Bloqué. Cliquez pour autoriser les modifications',
  },
  Espanhol: {
    es: 'Español',
    en: 'Spanish',
    fr: 'Espagnol',
  },
  Inglês: {
    es: 'Inglés',
    en: 'English',
    fr: 'Anglais',
  },
  'Continuar sem posicionar': {
    es: 'Continuar sin posicionar',
    en: 'Continue without positioning',
    fr: 'Continuez sans positionnement',
  },
  Continuar: {
    es: 'Continuar',
    en: 'Continue',
    fr: 'Continuer',
  },
  Avançar: {
    es: 'Continuar',
    en: 'Continue',
    fr: 'Continuer',
  },
  'Salvar e continuar': {
    es: 'Guardar y continuar',
    en: 'Save & Continue',
    fr: 'Enregistrer continuer',
  },
  'URL deve conter http:// ou https://': {
    es: 'La URL debe contener http: // o https: //',
    en: 'URL must contain http:// or https://',
    fr: 'L\'URL doit contenir http: // ou https: //',
  },
  're simples': {
    es: 're simple',
    en: 're simple',
    fr: 're simple',
  },
  'Para prosseguir sem posicionar assinaturas, antes remova os posicionamentos inseridos abaixo.': {
    es: 'Para proceder sin posicionar las firmas, primero hay que eliminar las posiciones insertadas abajo',
    en: 'To proceed without placing signatures, first remove the placements entered below.',
    fr: 'Pour continuer sans placer les signatures, supprimez d\'abord les placements saisis ci-dessous.',
  },
  'A assinatura foi copiada na última página do documento.': {
    es: 'La firma se copió en la última página del documento.',
    en: 'The signature was copied on the last page of the document.',
    fr: 'La signature a été copiée sur la dernière page du document.',
  },
  'O visto foi copiado': {
    es: 'Las iniciales se copiaron',
    en: 'The initials were copied',
    fr: 'Les initiales ont été copiées',
  },
  'nas próximas $0 páginas do documento': {
    es: 'en las siguientes $0 del documento',
    en: 'on the following $0 pages of the document',
    fr: 'sur les $0 pages suivantes du document',
  },
  'Envie o documento': {
    es: 'Enviar el documento',
    en: 'Send the document',
    fr: 'Envoyer le document',
  },
  'por WhatsApp, e-mail ou qualquer outro canal que o signatário preferir': {
    es: 'Puedes hacerlo por WhatsApp, correo electrónico o cualquier otro canal que prefieras.',
    en: 'via WhatsApp, email or any other channel that the signer prefers.',
    fr: 'via WhatsApp, e-mail ou tout autre canal que le signataire préfère.',
  },
  'Certificado digital': {
    es: 'Certificado digital',
    en: 'Digital certificate',
    fr: 'Certificat numérique',
  },
  'segue link para assinatura eletrônica do documento. Qualquer dúvida por favor avise.': {
    es: 'siga el enlace para la firma electrónica del documento. Cualquier pregunta por favor háganoslo saber.',
    en: 'follow link for electronic signature of the document. Any questions please let us know.',
    fr: 'Suivez le lien pour la signature électronique du document. Toutes les questions, veuillez nous le faire savoir.',
  },

  Eu: {
    es: 'Yo',
    en: 'Me',
    fr: 'Moi',
  },
  'Você pode': {
    es: 'Puedes',
    en: 'You may',
    fr: 'Vous pouvez',
  },
  'Falta apenas você assinar': {
    es: 'Todo lo que tienes que hacer es firmar',
    en: 'All you have to do is sign',
    fr: 'Tout ce que vous avez à faire est de signer',
  },
  'Como você é o único signatário, basta clicar no botão': {
    es: 'Como eres el único firmante, haz clic en el botón',
    en: 'As you are the only signer, just click the button',
    fr: 'Comme vous êtes le seul signataire, cliquez simplement sur le bouton',
  },
  'Assinar agora': {
    es: 'Firmar',
    en: 'Sign now',
    fr: 'Signer maintenant',
  },
  'Você pode compartilhar o link por Whatsapp, e-mail ou qualquer canal que o signatário preferir. Experimente também ativar o envio automatico por e-mail.': {
    es: 'Puedes compartir el enlace por Whatsapp, correo electrónico o cualquier canal que prefiera el firmante. También puedes intentar activar el envío automático de correos electrónicos.',
    en: 'You can share the link via Whatsapp, email or any channel that the signer prefers. Also try turning on automatic emailing.',
    fr: 'Vous pouvez partager le lien via WhatsApp, e-mail ou n\'importe quel canal que le signataire préfère. Essayez également d\'allumer les e-mails automatique.',
  },
  'compartilhar o link por Whatsapp, e-mail ou qualquer canal': {
    es: 'comparte el enlace por Whatsapp, correo electrónico o cualquier canal',
    en: 'share the link by Whatsapp, email or any channel',
    fr: 'Partagez le lien par WhatsApp, Email ou n\'importe quelle chaîne',
  },
  'que o signatário preferir. Experimente também ativar o envio automatico por e-mail.': {
    es: 'que prefiera el firmante. También puedes activar el envío automático de correos.',
    en: 'which the signer prefers. Also try turning on automatic emailing.',
    fr: 'que le signataire préfère. Essayez également d\'allumer les e-mails automatique.',
  },
  'Ativar envio automático dos links': {
    es: 'Habilitar el envío automático',
    en: 'Enable automatic sending',
    fr: 'Activer l\'envoi automatique',
  },
  'É necessário que sua conta tenha $0 créditos para o envio das mensagens. Compre mais em Configurações > Plano.': {
    es: 'Tu cuenta debe tener $0 de crédito para enviar mensajes. Compra más en Ajustes > Plan.',
    en: 'Your account must have $0 credits to send messages. Buy more in Settings > Plan.',
    fr: 'Votre compte doit avoir des crédits de $0 pour envoyer des messages. Achetez plus dans les paramètres> Plan.',
  },
  'Defina quem deverá assinar primeiro, segundo etc.': {
    es: 'Define quién debe firmar primero, segundo, etc.',
    en: 'Define who should sign first, second, etc.',
    fr: 'Définissez qui doit signer en premier, deuxième, etc.',
  },
  'Ativar ordenação de assinaturas': {
    es: 'Activar el orden de firmas',
    en: 'Enable signature ordering',
    fr: 'Activer la commande de signature',
  },
  'Data limite para assinatura do documento': {
    es: 'Fecha límite para firmar el documento',
    en: 'Deadline for signing the document',
    fr: 'Date limite pour signer le document',
  },
  'Ative caso queira que cada signatário receba o convite de assinatura apenas depois do signatário anterior assinar, criando uma ordem de primeiro, segundo, terceiro a assinar, assim por diante.': {
    es: 'Activa esta opción si quieres que cada firmante reciba la invitación a firmar sólo después de que el anterior haya firmado, creando un orden de primero, segundo, tercero en firmar, y así sucesivamente.',
    en: 'Activate if you want each signer to receive the signature invitation only after the previous signer has signed, creating an order of first, second, third to sign, and so on.',
    fr: 'Activez si vous souhaitez que chaque signataire ne reçoive l\'invitation de signature qu\'après que le signataire précédent a signé, créant une commande de premier, deuxième, troisième à signer, etc.',
  },
  'Ordenar assinaturas': {
    es: 'Ordenar las firmas',
    en: 'Order Signatures',
    fr: 'Commandez des signatures',
  },
  'Todos signatários serão convidados a assinar imediatamente.': {
    es: 'Se invitará a todos los firmantes a firmar inmediatamente.',
    en: 'All signers will be asked to sign immediately.',
    fr: 'Tous les signataires seront invités à signer immédiatement.',
  },
  'Ordem desativada': {
    es: 'Orden desactivado',
    en: 'Order deactivated',
    fr: 'Commander désactivé',
  },
  '(Opcional) Mensagem personalizada': {
    es: '(Opcional) Mensaje Personalizado',
    en: '(Optional) Custom Message',
    fr: 'Message personnalisé (facultatif)',
  },
  'Mensagem será enviada no corpo do e-mail.': {
    es: 'El mensaje se enviará en el cuerpo del correo electrónico.',
    en: 'Message will be sent in the body of the email.',
    fr: 'Le message sera envoyé dans le corps de l\'e-mail.',
  },
  'Ativar lembretes automáticos': {
    es: 'Activar los recordatorios automáticos',
    en: 'Enable automatic reminders',
    fr: 'Activer les rappels automatiques',
  },
  'Enviar e finalizar': {
    es: 'Enviar y finalizar',
    en: 'send and finish',
    fr: 'Envoyer et terminer',
  },
  'Enquanto não assinarem, serão enviados aos signatários até 3 lembretes por e-mail ou WhatsApp. Cada lembrete por WhatsApp tem um custo adicional se não estiver incluso em seu plano': {
    es: 'Si no firman el documento se enviarán hasta 3 recordatorios a los firmantes por correo electrónico.',
    en: 'As long as they do not sign, up to 3 reminders will be sent to signatories by email',
    fr: 'Tant qu\'ils ne s\'inscrivent pas, jusqu\'à 3 rappels seront envoyés aux signataires par e-mail',
  },
  'Cada signatário somente será notificado após o anterior assinar.': {
    es: 'Cada firmante sólo será notificado después de que el anterior haya firmado.',
    en: 'Each signer will only be notified after the previous signer',
    fr: 'Chaque signataire ne sera informé que après le signataire précédent',
  },
  'Ordem ativada': {
    es: 'Orden activado',
    en: 'Order activated',
    fr: 'Commande activée',
  },
  'Ordenação ativada': {
    es: 'Orden activado',
    en: 'Sorting enabled',
    fr: 'Tri activé',
  },
  'Concluir ordenação': {
    es: 'Concluir orden',
    en: 'Complete ordering',
    fr: 'Commande complète',
  },
  'a cada': {
    es: 'cada',
    en: 'every',
    fr: 'chaque',
  },
  dias: {
    es: 'días',
    en: 'days',
    fr: 'jours',
  },
  'Criado em': {
    es: 'Creado el',
    en: 'Created on',
    fr: 'Créé le',
  },
  Signatários: {
    es: 'Firmantes',
    en: 'Signers',
    fr: 'Signataires',
  },
  'Assinatura pendente': {
    es: 'Pendiente de firma',
    en: 'Signature pending',
    fr: 'Signature en attente',
  },
  'Tirar foto': {
    es: 'Tomar foto',
    en: 'Take picture',
    fr: 'Prendre une photo',
  },
  'Para registrar uma foto, clique em "Permitir" logo acima para dar ao navegador acesso a sua câmera.': {
    es: 'Para tomar una foto, haz clic en “Permitir” el uso de cámara al lado derecho del navegador.',
    en: 'To take a photo, click on "Allow" to grant access to the camera on the right side of the browser.',
    fr: 'Pour prendre une photo, cliquez sur "Autoriser" pour accorder l\'accès à la caméra sur le côté droit du navigateur.',
  },
  'Documento frente': {
    es: 'Documento (frente)',
    en: 'Document (front)',
    fr: 'Document (devant)',
  },
  'Documento verso': {
    es: 'Documento (atrás)',
    en: 'Document (back)',
    fr: 'Document (verso)',
  },
  'Fotografe seu documento pessoal com': {
    es: 'Toma una foto de tu documento de identidad con',
    en: 'Photograph your personal document with',
    fr: 'Photographiez votre document personnel avec',
  },
  'Ao prosseguir, concordo com a política de privacidade': {
    es: 'Al continuar, acepto la política de privacidad',
    en: 'By proceeding, I agree with the privacy policy',
    fr: 'En procédant, je suis d\'accord avec la politique de confidentialité',
  },
  'Tire uma foto do seu rosto': {
    es: 'Toma una foto de tu rostro',
    en: 'Take a picture of your face',
    fr: 'Prenez une photo de votre visage',
  },
  'Algum problema para tirar sua selfie?': {
    es: '¿Algún problema para tomarte la foto?',
    en: 'Is there a problem taking your selfie?',
    fr: 'Y a-t-il un problème à prendre votre selfie?',
  },
  'Tire uma foto do verso do documento': {
    es: 'Toma una foto del reverso del documento',
    en: 'Take a photo of the back of the document',
    fr: 'Prenez une photo du dos du document',
  },
  'Algum problema para tirar sua foto?': {
    es: '¿Algún problema para tomar la foto?',
    en: 'Is there a problem taking your photo?',
    fr: 'Y a-t-il un problème à prendre votre photo?',
  },
  'Tire uma foto da frente do seu documento': {
    es: 'Toma una foto del frente de tu documento',
    en: 'Take a picture of the front of your document',
    fr: 'Prenez une photo de l\'avant de votre document',
  },
  'Imagem frontal do documento pessoal': {
    es: 'Imagen frontal del documento personal',
    en: 'Front image of personal document',
    fr: 'Image avant du document personnel',
  },
  'Remover frente': {
    es: 'Eliminar parte del frente',
    en: 'Remove front',
    fr: 'Retirer le devant',
  },
  'Imagem verso do documento pessoal': {
    es: 'Imagen del reverso del documento',
    en: 'Back image of personal document',
    fr: 'Image arrière du document personnel',
  },
  'Remover verso': {
    es: 'Eliminar parte de atrás',
    en: 'Remove back',
    fr: 'Enlever le dos',
  },
  abaixo: {
    es: 'abajo',
    en: 'allow',
    fr: 'permettre',
  },
  'Salvar assinatura neste dispositivo': {
    es: 'Guardar la firma en este dispositivo',
    en: 'Save signature on this device',
    fr: 'Enregistrer la signature sur cet appareil',
  },
  'Muito bem! Agora faça seu visto.': {
    es: '¡Bien hecho! Ahora firma tus iniciales.',
    en: 'Very well! Now draw your initials.',
    fr: 'Maintenant, tracez vos initiales',
  },
  'Assinatura salva (para alterar, entre em seu perfil):': {
    es: 'Firma guardada (para cambiarla, ve a tu perfil):',
    en: 'Your saved signature (to change it, enter to your profile):',
    fr: 'Signature enregistrée (pour le changer, entrez votre profil):',
  },
  'Documento com problemas técnicos': {
    es: 'Documento con problemas técnicos',
    en: 'Document with technical problems',
    fr: 'Document avec des problèmes techniques',
  },
  'Fora do prazo de assinatura do documento': {
    es: 'Fuera del plazo de firma del documento',
    en: 'Out of date for signing the document',
    fr: 'Obsolète pour la signature du document',
  },
  'Documento não encontrado': {
    es: 'Documento no encontrado',
    en: 'Document not found',
    fr: 'Document introuvable',
  },
  Não: {
    es: 'No',
    en: 'Not',
    fr: 'Pas',
  },
  'Ops! Não encontramos um documento disponível nesse link. O link está incorreto ou o documento pode ter sido excluído.': {
    es: 'Oops! No encontramos un documento disponible en este enlace. El enlace es incorrecto o el documento pude ser eliminado.',
    en: 'Oops! We did not find a document available on this link. The link is incorrect or the document may have been deleted.',
    fr: 'Oops! Nous n\'avons pas trouvé de document disponible sur ce lien. Le lien est incorrect ou le document peut avoir été supprimé.',
  },
  'Solicite ao proprietário para alterar a data limite de assinatura.': {
    es: 'Pídale al propietario que cambie la fecha límite de firma.',
    en: 'Ask the owner to change the subscription deadline.',
    fr: 'Demandez au propriétaire de modifier la date limite d\'abonnement.',
  },
  'Ops!': {
    es: '¡UPS!',
    en: 'Oops!',
    fr: 'Oops!',
  },
  'Prazo de assinatura expirado.': {
    es: 'Caducó el plazo de firma.',
    en: 'Subscription term expired.',
    fr: 'Terme d\'abonnement a expiré.',
  },
  'Esse documento já foi fechado.': {
    es: 'Este documento ya ha sido cerrado.',
    en: ' This document has already been closed.',
    fr: ' Ce document a déjà été fermé.',
  },
  'Voltar para a página inicial': {
    es: 'Volver a la página de inicio',
    en: 'Back to home page',
    fr: 'Retour à la page d\'accueil',
  },
  'Apenas visualizou': {
    es: 'Sólo visualizó',
    en: 'Just viewed',
    fr: 'Je viens de voir',
  },
  'Assinou em $0': {
    es: 'Firmado el $0',
    en: 'Signed on $0',
    fr: 'Signé sur $0',
  },
  'Visualizou 1 vez o documento': {
    es: 'Ha visto este documento 1 vez',
    en: 'Viewed the document once',
    fr: 'A vu le document une fois',
  },
  'Visualizou $0 vezes o documento': {
    es: 'Visualizo el documento $0 veces',
    en: 'Viewed the document $0 times',
    fr: 'Affiché le document $0 Times',
  },
  'Última visualização em $0': {
    es: 'Vio por última vez el $0',
    en: 'Last viewed on $0',
    fr: 'Dernière vue sur $0',
  },
  'Erro: Código inválido. Verifique se inseriu corretamente.': {
    es: 'Error: Código inválido. Comprueba que lo has introducido correctamente.',
    en: 'Error: Invalid code. Check that you have entered it correctly.',
    fr: 'Erreur: code non valide. Vérifiez que vous l\'avez entré correctement.',
  },
  'Código inválido': {
    es: 'Coóigo inválido',
    en: 'Invalid code',
    fr: 'Code invalide',
  },
  'Por favor, preencha o e-mail.': {
    es: 'Por favor, escribe el correo',
    en: 'Please fill in the email.',
    fr: 'Veuillez remplir l\'e-mail.',
  },
  'Por favor, preencha o nome de todos signatários': {
    es: 'Por favor, escribe el nombre de todos los firmantes',
    en: 'Please fill in the names of all signatories',
    fr: 'Veuillez remplir les noms de tous les signataires',
  },
  'Por favor, preencha o e-mail de $0': {
    es: 'Por favor, escribe el email de $0',
    en: 'Please fill in $0\'s email',
    fr: 'Veuillez remplir l\'e-mail de $0',
  },
  'Por favor, preencha o número de $0': {
    es: 'Por favor, escribe el número de $0',
    en: 'Please fill in $0\'s number',
    fr: 'Veuillez remplir le numéro de $0',
  },
  'E-mails enviados com sucesso!': {
    es: 'Correos electrónicos enviados con éxito!',
    en: 'Emails sent successfully!',
    fr: 'Emails envoyés avec succès!',
  },
  'Mensagens enviadas com sucesso!': {
    es: 'Mensajes enviados con éxito!',
    en: 'Messages sent successfully!',
    fr: 'Messages envoyés avec succès!',
  },
  'Por favor, preencha os campos abaixo.': {
    es: 'Por favor, completa los campos a continuación.',
    en: 'Please fill in the fields below.',
    fr: 'Veuillez remplir les champs ci-dessous.',
  },
  Arquivos: {
    es: 'Archivos',
    en: 'Files',
    fr: 'Des dossiers',
  },
  'Por favor, digite uma resposta.': {
    es: 'Por favor, introduce una respuesta.',
    en: 'Please enter an answer.',
    fr: 'Veuillez saisir une réponse.',
  },
  'Por favor, informe um telefone válido': {
    es: 'Por favor ingresa un número de teléfono válido.',
    en: 'Please enter a valid phone number.',
    fr: 'S\'il vous plaît entrer un numéro de téléphone valide.',
  },
  'Por favor, informe um e-mail válido': {
    es: 'Por favor ingresa un e-mail válido.',
    en: 'Please enter a valid e-mail number.',
    fr: 'Veuillez saisir un numéro de courrier électronique valide.',
  },
  'Para continuar, responda todos os campos obrigatórios (*) do formulário.': {
    es: 'Para continuar, completa todos los campos obligatorios (*) del formulario.',
    en: 'To continue, please answer all required (*) fields on the form.',
    fr: 'Pour continuer, veuillez répondre à tous les champs requis (*) sur le formulaire.',
  },
  '$0 de $1 arquivos carregados...': {
    es: '$0 de $1 archivos subidos...',
    en: '$0 of $1 files uploaded...',
    fr: '$0 de fichiers à $1 téléchargés ...',
  },
  'Arquivos carregados com sucesso! Só mais um instante...': {
    es: 'Archivos cargados con éxito! Sólo un momento más...',
    en: 'Files uploaded successfully! Just one moment...',
    fr: 'Fichiers téléchargés avec succès! Juste un moment de plus ...',
  },
  'O verificador de autenticidade da ZapSign garante transparência, imutabilidade e irrevogabilidade para transações de assinatura eletrônica ocorridas na plataforma.': {
    es: 'El verificador de autenticidad de ZapSign garantiza la transparencia, inmutabilidad e irrevocabilidad de las transacciones de firma electrónica que se producen en la plataforma.',
    en: 'ZapSign\'s authenticity checker guarantees transparency, immutability and irrevocability for electronic signature transactions taking place on the platform.',
    fr: 'Le vérificateur d\'authenticité de ZapSign garantit la transparence, l\'immuabilité et l\'irrévocabilité pour les transactions de signature électronique qui se déroulent sur la plate-forme.',
  },
  'Verifique rapidamente se o documento foi assinado via ZapSign informando o número do documento.': {
    es: 'Comprueba rápidamente si el documento fue firmado a través de ZapSign introduciendo el número de documento.',
    en: 'Quickly verify that the document has been signed via ZapSign by entering the document number.',
    fr: 'Vérifiez rapidement que le document a été signé via ZapSign en entrant le numéro de document.',
  },
  'Número do documento': {
    es: 'Número de documento',
    en: 'Document number',
    fr: 'Numéro de document',
  },
  'Verificar autenticidade': {
    es: 'Verificar la autenticidad',
    en: 'Verify authenticity',
    fr: 'Vérifiez l\'authenticité',
  },
  'Autenticidade reconhecida pela ZapSign.': {
    es: 'Autenticidad reconocida por ZapSign.',
    en: 'Authenticity recognized by ZapSign.',
    fr: 'Authenticité reconnue par ZapSign.',
  },
  'Dados do documento:': {
    es: 'Datos del documento:',
    en: 'Document data:',
    fr: 'Données de documentation:',
  },
  'Nome: sample.pdf': {
    es: 'Nombre: muestra.pdf',
    en: 'Name: sample.pdf',
    fr: 'Nom: Sample.pdf',
  },
  'Data de criação:': {
    es: 'Fecha de creación:',
    en: 'Creation date:',
    fr: 'Date de création:',
  },
  'Hash do documento original (SHA256):': {
    es: 'Hash del documento original (SHA256):',
    en: 'Original document hash (SHA256):',
    fr: 'Hash de document original (SHA256):',
  },
  'Verificar outro documento': {
    es: 'Comprobar otro documento',
    en: 'Check another document',
    fr: 'Vérifiez un autre document',
  },
  'Documento não encontrado. Você inseriu o identificador corretamente?': {
    es: 'Documento no encontrado. ¿Has introducido el identificador correctamente?',
    en: 'Document not found. Did you enter the identifier correctly?',
    fr: 'Document introuvable. Avez-vous entré correctement l\'identifiant?',
  },
  'Por favor, digite o número do documento inteiro.': {
    es: 'Por favor, introduce el número de documento completo.',
    en: 'Please enter the entire document number.',
    fr: 'Veuillez saisir l\'intégralité du numéro de document.',
  },
  'Imagem ilustrativa da Mata Atlântica': {
    es: 'Imagen ilustrativa del Bosque Atlántico',
    en: 'Illustrative image of the Atlantic Forest',
    fr: 'Image illustrative de la forêt atlantique',
  },
  'Sustentabilidade ZapSign': {
    es: 'Sostenibilidad de ZapSign',
    en: 'ZapSign Sustainability',
    fr: 'Durabilité ZapSign',
  },
  'Certificamos que $0 opta pelo serviço de assinatura eletrônica da ZapSign. Com isso, ela contribui para a preservação do meio ambiente.': {
    es: 'Certificamos que $0 ha elegido el servicio de firma electrónica de ZapSign. Con ello, contribuye a la conservación del medio ambiente.',
    en: 'We certify that $0 opts for ZapSign\'s electronic signature service. With that, it contributes to the preservation of the environment.',
    fr: 'Nous certifions que $0 opte pour le service de signature électronique de Zapsign. Avec cela, il contribue à la préservation de l\'environnement.',
  },
  'Impacto até o momento': {
    es: 'Impacto hasta ahora',
    en: 'Impact so far',
    fr: 'Impact jusqu\'à présent',
  },
  '$0 litros de água economizados': {
    es: '$0 litros de agua ahorrados',
    en: '$0 liters of water saved',
    fr: '$0 litres d\'eau économisée',
  },
  '$0 árvore(s) salva(s)': {
    es: '$0 árbols salvado',
    en: '$0 trees saved',
    fr: '$0 arbres économisés',
  },
  '$0kg de CO² não emitidos na atmosfera': {
    es: '$0kg de CO² no emitidos a la atmósfera',
    en: '$0kg of CO² not emitted into the atmosphere',
    fr: '$0kg de co² non émis dans l\'atmosphère',
  },
  'Múltiplos contatos presenciais evitados todos os dias': {
    es: 'Múltiples encuentros presenciales evitados',
    en: 'Multiple face-to-face contacts avoided',
    fr: 'Plusieurs contacts en face à face évités',
  },
  'Contribua você também': {
    es: 'Contribuye tu también',
    en: 'Contribute you too',
    fr: 'Vous contribuer aussi',
  },
  'Junte-se a $0 e outras +50mil empresas e utilize a assinatura eletrônica para reduzir os impactos ambientais da produção de papel. Compartilhe o link com seus amigos para ajudar também.': {
    es: 'Únete a $0 y a otras +50 mil empresas y utiliza firma electrónica para reducir el impacto medioambiental de la producción de papel. Comparte el enlace con tus amigos para que también ayuden.',
    en: 'Join $0 and other +50,000 companies and use electronic signatures to reduce the environmental impacts of paper production. Share the link with your friends to help too.',
    fr: 'Rejoignez $0 et d\'autres sociétés de +50 000 et utilisez des signatures électroniques pour réduire les impacts environnementaux de la production papier. Partagez le lien avec vos amis pour vous aider aussi.',
  },
  'Junte-se a $0 50mil empresas e utilize a assinatura eletrônica ZapSign para reduzir os impactos ambientais da produção de papel. Conheça em $1': {
    es: 'Únete a $0 a otras 50mil empresas y utiliza firma electrónica para reducir el impacto medioambiental de la producción de papel.',
    en: 'Join $0 and 50mil other companies and use e-signatures to reduce the environmental impact of paper production.',
    fr: 'Rejoignez $0 et 50 millions d\'autres sociétés et utilisez des signatures électroniques pour réduire l\'impact environnemental de la production papier.',
  },
  'Junte-se a $0 50mil empresas e utilize a assinatura eletrônica ZapSign para reduzir os impactos ambientais da produção de papel. Quer começar a economizar tempo e dinheiro? Entre no site: https://zapsign.com.br/?utm_source=wp-sustentabilidade Se cadastrando agora você pode enviar grátis $1 documentos por mês.': {
    es: 'Únete a $0 a otras 50mil empresas y utiliza firma electrónica para reducir el impacto medioambiental de la producción de papel. ¿Quieres empezar a ahorrar tiempo y dinero? Entra a https://zapsign.co/es/?utm_source=wp-sustentabilidade Si te registras ahora, puede enviar $1 documentos por mes de forma gratuita.',
    en: 'Join $0 50mil other companies and use e-signatures to reduce the environmental impact of paper production. Want to start saving time and money? Enter the website: https://zapsign.co/?utm_source=wp-sustentabilidade By signing up now you can send $1 documents per month for free.',
    // tslint:disable-next-line:max-line-length
    fr: 'Rejoignez $0 50 mil autres sociétés et utilisez des signatures électroniques pour réduire l\'impact environnemental de la production papier. Vous voulez commencer à économiser du temps et de l\'argent? Entrez le site Web: https://zapsign.co/?utm_source=wp-stentabilidade en vous inscrivant maintenant, vous pouvez envoyer des documents de $1 par mois gratuitement.',
  },

  'esta e': {
    es: 'esta y',
    en: 'this and',
    fr: 'Ceci et',
  },
  Menu: {
    es: 'Menú',
    en: 'Menu',
    fr: 'Menu',
  },
  'Nova senha': {
    es: 'Nueva contraseña',
    en: 'New Password',
    fr: 'nouveau mot de passe',
  },
  'Defina a nova senha da sua conta.': {
    es: 'Define la nueva contraseña de tu cuenta.',
    en: 'Set the new password for your account.',
    fr: 'Définissez le nouveau mot de passe pour votre compte.',
  },
  'Senha (min. 6 caracteres)': {
    es: 'Contraseña (mínimo 6 caracteres)',
    en: 'Password (min. 6 characters)',
    fr: 'Mot de passe (min. 6 caractères)',
  },
  SALVAR: {
    es: 'GUARDAR',
    en: 'SAVE',
    fr: 'Enregistrer',
  },
  'Não foi possível fazer o login.': {
    es: 'No fue posible iniciar sesión.',
    en: 'Unable to login.',
    fr: 'Connection impossible.',
  },
  'Não foi possível fazer o login. Verifique se os dados estão corretos.': {
    es: 'No fue posible iniciar sesión. Verifica si los datos están correctos.',
    en: 'Unable to login. Check if your data ir correct.',
    fr: 'Connection impossible. Vérifiez si vos données IR sont correctes.',
  },
  'Assinatura em lote': {
    es: 'Firma en lote',
    en: 'Batch signing',
    fr: 'Signer par lot',
  },
  'Assinar em lote': {
    es: 'Firmar en lote',
    en: 'Batch signnature',
    fr: 'Signature par lot',
  },
  'Para habilitar a assinatura em lote': {
    es: 'Para activar firma en lote',
    en: 'To enable batch signatures',
    fr: 'Pour activer les signatures par lots',
  },
  'Parabéns! Você já assinou todos seus documentos.': {
    es: 'Felicitaciones. Has firmado todos tus documentos.',
    en: 'Congratulations! You have already signed all your documents.',
    fr: 'Toutes nos félicitations! Vous avez déjà signé tous vos documents.',
  },
  'Verifique os documentos com a sua assinatura pendente.': {
    es: 'Verifica los documentos con tu firma pendiente.',
    en: 'Check documents with your signature pending.',
    fr: 'Vérifiez les documents avec votre signature en attente.',
  },
  'Desabilite os documentos que NÃO deseja assinar em lote:': {
    es: 'Desactiva los documentos que NO desea firmar masivamente:',
    en: 'Disable documents you DO NOT want to sign in batch:',
    fr: 'Désactiver les documents que vous ne souhaitez pas vous connecter:',
  },
  'Para assinar, confirme seus dados:': {
    es: 'Para firmar, confirma tus datos:',
    en: 'To sign, confirm your full name and mobile:',
    fr: 'Pour signer, confirmez votre nom complet et votre mobile:',
  },
  'Assinar os $0 documentos em lote': {
    es: 'Firmar los $0 documentos en lote',
    en: 'Sign the $0 batch documents',
    fr: 'Signer les documents de lot de $0',
  },
  'documentos em lote': {
    es: 'documentos en lote',
    en: 'batch documents',
    fr: 'documents de lot',
  },
  'Selecione documentos e preencha o formulário para habilitar': {
    es: 'Seleccione documentos y complete el formulario para habilitar',
    en: 'Select documents and fill out the form to enable',
    fr: 'Sélectionnez les documents et remplissez le formulaire pour activer',
  },
  'Ver documento': {
    es: 'Ver documento',
    en: 'View document',
    fr: 'Afficher le document',
  },
  'Desabilitar assinatura em lote deste documento': {
    es: 'Desactivar la firma por lotes de este documento',
    en: 'Disable batch signing of this document',
    fr: 'Désactiver la signature par lots de ce document',
  },
  'Documentos assinados com sucesso!': {
    es: '¡Documentos firmados con éxito!',
    en: 'Documents successfully signed!',
    fr: 'Documents signés avec succès !',
  },
  'Por favor, permita o uso da câmera para fotografar sua selfie. Siga as instruções abaixo.': {
    es: 'Por favor, permite el uso de la cámara para tomar una foto del rostro. Sigue las instrucciones a continuación.',
    en: 'Please allow the use of the camera to take your selfie. Follow the instructions below.',
    fr: 'Veuillez permettre à l\'utilisation de la caméra de prendre votre selfie. Suivez les instructions ci-dessous.',
  },
  'QR Code': {
    es: 'QR Code',
    en: 'QR Code',
    fr: 'QR Code',
  },
  'Escaneie o QR Code abaixo': {
    es: 'Escanea el siguiente código QR',
    en: 'Scan the QR Code below',
    fr: 'Scannez le code QR ci-dessous',
  },
  'Você pode assinar com seu celular. Para isso, abra a câmera do seu celular e escaneie o QR code abaixo.': {
    es: 'Puedes firmar con el celular. Para ello, abre la cámara del celular y escanea el código QR de abajo.',
    en: 'You can sign with your cell phone. To do this, open your phone\'s camera and scan the QR code below.',
    fr: 'Vous pouvez signer avec votre téléphone portable. Pour ce faire, ouvrez la caméra de votre téléphone et scannez le code QR ci-dessous.',
  },
  'Instruções para não aceitação de permissão da webcam': {
    es: 'Instrucciones para dar permisos a la cámara web',
    en: 'Instructions for granting permissions to the webcam',
    fr: 'Instructions pour accorder des autorisations à la webcam',
  },
  Etapa: {
    es: 'Etapa',
    en: 'Step',
    fr: 'Étape',
  },
  'Abra uma nova guia do seu navegador e acesse: chrome://settings/content/camera': {
    es: 'Abre una nueva pestaña del navegador y ve a: chrome://settings/content/camera',
    en: 'Open a new tab in your browser and go to: chrome://settings/content/camera',
    fr: 'Ouvrez un nouvel onglet dans votre navigateur et accédez à: Chrome: // Paramètres / Contenu / Caméra',
  },
  Próximo: {
    es: 'Siguiente',
    en: 'Next',
    fr: 'Suivant',
  },
  'Na parte escrita "Block", clique na lixeira para resetar suas permissões de acesso a câmera.': {
    es: 'En la parte escrita "Bloqueo", haz clic en la papelera para restablecer los permisos de acceso a la cámara.',
    en: 'In the part written "Block", click on the trash to reset your camera access permissions.',
    fr: 'Dans la pièce écrite "Block", cliquez sur la poubelle pour réinitialiser les autorisations d\'accès à votre appareil photo.',
  },
  'Recarregue essa página da Zapsign e clique em "Fotografar selfie" novamente.': {
    es: 'Vuelve a cargar la página de Zapsign y haz clic en "Tomar selfie" de nuevo.',
    en: 'Reload this Zapsign page and click "Shoot Selfie" again.',
    fr: 'Recharger cette page ZapSign et cliquez à nouveau "tirer sur Selfie".',
  },
  'Permita a permissão da sua webcam clicando no botão "Allow".': {
    es: 'Habilita el permiso de tu cámara web haciendo clic en el botón "Permitir".',
    en: 'Allow permission for your webcam by clicking the "Allow" button.',
    fr: 'Autoriser la permission pour votre webcam en cliquant sur le bouton "Autoriser".',
  },
  'Recarregue o navegador e clique novamente em "Fotografar selfie"': {
    es: 'Vuelve a cargar el navegador y haz clic de nuevo en "Tomar selfie".',
    en: 'Reload your browser and click on "Shoot selfie" again',
    fr: 'Recharger votre navigateur et cliquez sur "tirer sur Selfie"',
  },
  'Aceite as permissões, clicando no botão "Allow". Não se esqueça de relembrar sua decisão, clicando em "Remember this decision".': {
    es: 'Acepta los permisos haciendo clic en el botón "Permitir". No olvides recordar éste permiso haciendo clic en "Recordar esta decisión".',
    en: 'Accept the permissions by clicking the "Allow" button. Don\'t forget to remember your decision by clicking on "Remember this decision".',
    fr: 'Acceptez les autorisations en cliquant sur le bouton "Autoriser". N\'oubliez pas de vous souvenir de votre décision en cliquant sur "Rappelez - vous cette décision".',
  },
  'Fale conosco em support@zapsign.com.br': {
    es: 'Ponte en contacto con nosotros en support@zapsign.com.br',
    en: 'Contact us at support@zapsign.com.br',
    fr: 'Contactez-nous à support@zapsign.com.br',
  },
  'Computador com problemas técnicos :x': {
    es: 'Computador con problemas técnicos :x',
    en: 'Computer with technical problems :x',
    fr: 'Ordinateur avec des problèmes techniques: x',
  },
  'ZapSign | Página não encontrada': {
    es: 'ZapSign | Página no encontrada',
    en: 'ZapSign | Page not found',
    fr: 'ZapSign | Page non trouvée',
  },
  'Página não encontrada!': {
    es: 'Página no encontrada.',
    en: 'Page not found!',
    fr: 'Page non trouvée!',
  },
  'Ops! Me desculpe, mas não encontramos nada por aqui... Talvez o link que você buscou esteja incorreto ou mudou de lugar.': {
    es: 'Oops! Lo sentimos, no pudimos encontrar nada aquí... Tal vez el enlace que has buscado es incorrecto o no existe.',
    en: 'Oops! Sorry, but we didn\'t find anything around here...Maybe the link you searched for is incorrect or has been moved.',
    fr: 'Oops! Désolé, mais nous n\'avons rien trouvé ici ... peut-être que le lien que vous avez cherché est incorrect ou a été déplacé.',
  },
  'Ao prosseguir, você aceita os': {
    es: 'Al continuar, aceptas la',
    en: 'By proceeding, you accept the',
    fr: 'En procédant, vous acceptez le',
  },
  'Por favor, digite uma resposta': {
    es: 'Por favor introduce una respuesta',
    en: 'Please enter an answer',
    fr: 'Veuillez saisir une réponse',
  },
  'termos de uso': {
    es: 'terminos de uso',
    en: 'terms of use',
    fr: 'conditions d\'utilisation',
  },
  'Documentos aguardando sua assinatura': {
    es: 'Documentos a la espera de tu firma',
    en: 'Documents awaiting your signature',
    fr: 'Documents en attente de votre signature',
  },
  e: {
    es: 'y',
    en: 'and',
    fr: 'et',
  },
  'Baixe a lista de': {
    es: 'Descarga la lista de',
    en: 'Download the list of',
    fr: 'Télécharger la liste de',
  },
  documentos: {
    es: 'documentos',
    en: 'documents',
    fr: 'documents',
  },
  'da pasta atual em um arquivo Excel': {
    es: 'de la carpeta actual a un archivo de Excel',
    en: 'from the current folder into an Excel file',
    fr: 'du dossier actuel dans un fichier Excel',
  },
  'O Excel possuirá duas planilhas (Documentos e Signatários), navegue entre as duas usando as abas inferiores.': {
    es: 'Excel tendrá dos hojas de cálculo (Documentos y Firmantes), navega entre las dos usando las pestañas inferiores.',
    en: 'Excel will have two sheets (Documents and Signers), navigate between the two using the bottom tabs.',
    fr: 'Excel aura deux feuilles (documents et signataires), naviguez entre les deux à l\'aide des onglets inférieurs.',
  },
  Dicas: {
    es: 'Consejos',
    en: 'Tips',
    fr: 'Conseils',
  },
  'Para listar os documentos de': {
    es: 'Para enumerar los documentos de',
    en: 'To list the documents of',
    fr: 'Pour répertorier les documents de',
  },
  'todas as pastas': {
    es: 'todas las carpetas',
    en: 'all folders',
    fr: 'tous les dossiers',
  },
  'clique em "Buscar" e escolha "Buscar na pasta" > "Todas pastas"': {
    es: 'haz clic en "Buscar" y selecciona "Carpeta de búsqueda"> "Todas las carpetas"',
    en: 'click "Search" and choose "Search Folder" > "All Folders"',
    fr: 'Cliquez sur "Rechercher" et choisissez "Rechercher le dossier"> "Tous les dossiers"',
  },
  'Aprenda como fazer o': {
    es: 'Aprende a hacer el',
    en: 'Learn how to do the',
    fr: 'Apprenez à faire le',
  },
  como: {
    es: 'igual que',
    en: 'like',
    fr: 'comme',
  },
  CNH: {
    es: 'CNH',
    en: 'CNH',
    fr: 'CNH',
  },

  'download em massa de todos documentos': {
    es: 'descarga masiva de todos los documentos',
    en: 'bulk download of all documents',
    fr: 'Téléchargement en vrac de tous les documents',
  },
  Antecedentes: {
    es: 'Antecedentes',
    en: 'Background',
    fr: 'Arrière-plan',
  },
  de: {
    es: 'en',
    en: 'in',
    fr: 'dans',
  },
  'Habilitar API': {
    es: 'Habilitar API',
    en: 'Enable API',
    fr: 'Activer l\'API',
  },
  'Ver documentação': {
    es: 'Ver documentación',
    en: 'See documentation',
    fr: 'Voir documentation',
  },
  'Webhooks permitem que outros sistemas sejam notificados quando ocorrerem eventos na ZapSign. Você receberá uma requisição POST em cada uma das URLs cadastradas.': {
    es: 'Los webhooks permiten que otros sistemas sean notificados cuando ocurren eventos en ZapSign. Recibirás una solicitud POST en cada una de las URL registradas',
    en: 'Webhooks allow other systems to be notified when events occur at ZapSign. You will receive a POST request on each of the registered URLs.',
    fr: 'Les Webhooks permettent à d\'autres systèmes d\'être notifiés lorsque des événements se produisent dans ZapSign. Vous recevrez une requête POST sur chacune des URL enregistrées.',
  },
  API: {
    es: 'API',
    en: 'API',
    fr: 'API',
  },
  'Veja a': {
    es: 'Revisa',
    en: 'See',
    fr: 'Voir le',
  },
  'nossa documentação': {
    es: 'nuestra documentación',
    en: 'our documentation',
    fr: 'Notre documentation',
  },
  'Fechar modal': {
    es: 'Cerrar modal',
    en: 'Close modal',
    fr: 'Fermer Modal',
  },
  'Compartilhe a ZapSign com o link:': {
    es: 'Comparte la aplicación de ZapSign con el siguiente enlace: ',
    en: 'Share ZapSign with the link:',
    fr: 'Partagez ZapSign avec le lien:',
  },
  'e a cada contratação de um plano pago da ZapSign pelo indicado, você ganha 1 mês do Plano Completo!': {
    es: 'y por cada referido que compre nuestros servicios, !tendrás 1 mes de ZapSign gratis!',
    en: 'and for each contracting of a ZapSign paid plan by the nominee, you earn 1 month of a paid plan!',
    fr: 'Et pour chaque contrat d\'un plan payant ZapSign par le candidat, vous gagnez 1 mois d\'un plan payant!',
  },
  'e conheça os': {
    es: 'y conoce nuestros',
    en: 'and explore the',
    fr: 'et explorez les',
  },
  'planos de API': {
    es: 'planes de API',
    en: 'API plans',
    fr: 'plans API',
  },
  'API habilitada. Token de acesso criado.': {
    es: 'API habilitada. Token de acceso creado.',
    en: 'API enabled. Access token created.',
    fr: 'API activé. Token d\'accès créé.',
  },
  'Você deve adicionar mais um usuário para poder trocar o proprietário da conta.': {
    es: 'Debes agregar un usuario más para cambiar el propietario de la cuenta.',
    en: 'You must add one more user to change the account owner.',
    fr: 'Vous devez ajouter un autre utilisateur pour modifier le propriétaire du compte.',
  },
  Whatsapp: {
    es: 'Whatsapp',
    en: 'Whatsapp',
    fr: 'Whatsapp',
  },
  'Busca de antecedentes legais (89 créditos)': {
    es: 'Búsqueda de antecedentes legales (89 créditos)',
    en: 'Legal Background Search (89 credits)',
    fr: 'Recherche de fond juridique (89 crédits)',
  },
  Créditos: {
    es: 'Créditos',
    en: 'Credits',
    fr: 'Crédits',
  },
  CRÉDITOS: {
    es: 'CRÉDITOS',
    en: 'CREDITS',
    fr: 'CRÉDITS',
  },
  'Ver histórico': {
    es: 'Ver historial',
    en: 'View history',
    fr: 'Voir l\'historique',
  },
  'Você possui': {
    es: 'Tienes',
    en: 'You have',
    fr: 'Vous avez',
  },
  'da ZapSign, você consome créditos:': {
    es: 'de ZapSign, consume créditos:',
    en: 'from ZapSign, you consume credits:',
    fr: 'De ZapSign, vous consommez des crédits:',
  },
  'funcionalidades opcionais': {
    es: 'características opcionales',
    en: 'optional features',
    fr: 'caractéristiques optionnelles',
  },
  'Para usar algumas': {
    es: 'Para usar algunos',
    en: 'To use some',
    fr: 'Pour utiliser certains',
  },
  'Avançar para a próxima etapa': {
    es: 'Vaya al siguiente paso',
    en: 'Go to the next step',
    fr: 'Passez à l\'étape suivante',
  },
  'Histórico de créditos': {
    es: 'Histórico de créditos',
    en: 'Credit history',
    fr: 'Histoire de credit',
  },
  'Carregar histórico': {
    es: 'Cargar historial',
    en: 'Load history',
    fr: 'Historique des charges',
  },
  'Se você pagou com boleto, mas o plano não consta como pago,': {
    es: 'Si pagó con un comprobante bancario, pero el plan no se muestra como pagado,',
    en: 'If you paid with a bank slip, but the plan is not shown as paid,',
    fr: 'Si vous avez payé avec un bordereau, mais le plan n\'est pas affiché comme payé,',
  },
  'aguarde até 2 dias úteis devido a compensação bancária ou envie o comprovante de pagamento para support@zapsign.com.br': {
    es: 'espera hasta 2 días hábiles debido a la compensación bancaria o envíe el comprobante de pago a support@zapsign.com.br',
    en: 'wait up to 2 business days due to bank clearing or send proof of payment to support@zapsign.com.br',
    fr: 'Attendez jusqu\'à 2 jours ouvrables en raison de la compensation bancaire ou envoyez une preuve de paiement à support@zapsign.com.br',
  },
  'Motivo do cancelamento': {
    es: 'Razón de la cancelación',
    en: 'Reason for cancellation',
    fr: 'Raison pour l\'annulation',
  },
  'Será que nossa equipe pode ajudar com esse problema?': {
    es: '¿Puede nuestro equipo ayudar con este problema?',
    en: 'Can our team help with this problem?',
    fr: 'Notre équipe peut-elle aider ce problème?',
  },
  'Fale com a gente agora mesmo': {
    es: 'Habla con nosotros ahora mismo',
    en: 'Talk to us right now',
    fr: 'Parlez-nous maintenant',
  },
  'Atenção:': {
    es: 'Aviso:',
    en: 'Heads up:',
    fr: 'La tête haute:',
  },
  'Caso queira recontratar a ZapSign no futuro, pode ser que o $0 tenha mudado de preço ou condições. Garanta o preço atual permanecendo no plano.': {
    es: 'Si desea contratar ZapSign en el futuro, es posible que los $0 hayan cambiado en precio o términos. Asegure el precio actual permaneciendo en el plan.',
    en: 'If you want to hire ZapSign in the future, it may be that the $0 has changed in price or terms. Secure the current price by staying on the plan.',
    fr: 'Si vous souhaitez embaucher ZapSign à l\'avenir, il se peut que les $0 aient changé de prix ou de termes. Sécuriser le prix actuel en restant sur le plan.',
  },
  'Nenhum plano encontrado! Tem certeza de que o código está correto?': {
    es: '¡No se encontraron planes! ¿Estás seguro de que el código es correcto?',
    en: 'No plans found! Are you sure the code is correct?',
    fr: 'Aucun plan trouvé! Êtes-vous sûr que le code est correct?',
  },
  'Código:': {
    es: 'Código:',
    en: 'Code:',
    fr: 'Code:',
  },
  'Plano Completo': {
    es: 'Business Plan',
    en: 'Business Plan',
    fr: 'Plan d\'affaires',
  },
  'Plano Profissional': {
    es: 'Professional Plan',
    en: 'Professional Plan',
    fr: 'Professional Plan',
  },
  'Plano Completo ': {
    es: 'Business Plan',
    en: 'Business Plan',
    fr: 'Business Plan',
  },
  'Plano Profissional ': {
    es: 'Professional Plan',
    en: 'Professional Plan',
    fr: 'Professional Plan',
  },
  'Planos adicionais': {
    es: 'Planes adicionales',
    en: 'Additional plans',
    fr: 'Plans supplémentaires',
  },
  ': 240 documentos por ano, sua marca, modelos.': {
    es: ': 25 documentos al mes por usuario, personalización de la marca y plantillas re-utilizables.',
    en: ': 25 documents per month per user, brand customization, and reusable templates.',
    fr: ': 25 documents par mois par utilisateur, personnalisation de la marque et modèles réutilisables.',
  },
  ': documentos ilimitados, sua marca, modelos e biometria (autenticação por selfie, documento identidade e reconhecimento facial).': {
    es: ': Beneficios del plan professional + envío masivo de documentos y solicitar selfie o documento de identidad al firmante',
    en: ': Benefits of the professional plan + bulk document sending and requesting a selfie or identity document from the signer.',
    fr: ': Avantages du plan Pprofessional + envoi en masse de documents et demande d\'un selfie ou d\'un document d\'identité auprès du signataire.',
  },
  '/mês': {
    es: '/mes',
    en: '/month',
    fr: '/mois',
  },
  '/ano': {
    es: '/año',
    en: '/year',
    fr: '/année',
  },
  ': $0 documentos/mês, $1 usuários, sua marca e acesso aos modelos.': {
    es: ': $0 documentos / mes, $1 usuarios, tu marca y acceso a plantillas.',
    en: ': $0 documents/month, $1 users, your brand and access to templates.',
    fr: ': $0 Documents / mois, $1 utilisateurs, votre marque et l\'accès aux modèles.',
  },
  'API Starter': {
    es: 'API Starter',
    en: 'API Starter',
    fr: 'Démarreur API',
  },
  'API Basic': {
    es: 'API Basic',
    en: 'API Basic',
    fr: 'API BASIC',
  },
  'API Pro': {
    es: 'API Pro',
    en: 'API Pro',
    fr: 'API Pro',
  },
  ': $0 documentos/mês + R$ $1 por doc adicional + R$ $2 por SMS.': {
    es: ': $0 documentos/mes + R$ $1 por documento adicional + R$ $2 por SMS.',
    en: ': $0 documents/month + R$ $1 per additional doc + R$ $2 per SMS.',
    fr: ': $0 Documents / mois + R$ $1 par doc supplémentaire + R$ $2 par SMS.',
  },
  ': $0 documentos/mês (ou $1 documentos/ano, se plano anual).': {
    es: ': $0 documentos/mes (o $1 documentos/año, si plan anual).',
    en: ': $0 documents/month (or $1 documents/year, if annual plan).',
    fr: ': $0 Documents / mois (ou $1 Documents / an, si le plan annuel).',
  },
  'Equivale a $0/mês': {
    es: 'Equivalente a $0/mes',
    en: 'Equivalent to $0/month',
    fr: 'Équivalent à $0 / mois',
  },
  'Somente no cartão': {
    es: 'Solo tarjeta',
    en: 'Card only',
    fr: 'Carte seulement',
  },
  'Opções de parcelamento*': {
    es: 'Opciones de pago *',
    en: 'Installment Options*',
    fr: 'Options de versement *',
  },
  'x de': {
    es: 'x de',
    en: 'x of',
    fr: 'x de',
  },
  '$0x de': {
    es: '$0x de',
    en: '$0x of',
    fr: '$0x de',
  },
  '* Parcelamento com tarifa de $0% a.m.': {
    es: '* Cuota a una tasa de $0% p.m.',
    en: '* Installment at a rate of $0% p.m.',
    fr: '* Tapison à un taux de 0% à 0 $',
  },
  Desativada: {
    es: 'Desactivado',
    en: 'Disabled',
    fr: 'Désactivé',
  },
  'Como usar certificado digital': {
    es: 'Cómo utilizar el certificado digital',
    en: 'How to use digital certificate',
    fr: 'Comment utiliser le certificat numérique',
  },
  'Não será possível posicionar a assinatura deste signatário no PDF. Os signatários com certificado digital devem ser os últimos a assinar.': {
    es: 'No será posible posicionar la firma de este firmante en el PDF. Los firmantes con certificado digital deben ser los últimos en firmar.',
    en: 'It will not be possible to place this signer\'s signature on the PDF. Signers with a digital certificate must be the last to sign.',
    fr: 'Il ne sera pas possible de placer la signature de ce signataire sur le PDF. Les signataires avec un certificat numérique doivent être les derniers à signer.',
  },
  'da sua conta ($0 créditos = R$ $1). Veja em Configurações -> Plano.': {
    es: 'de tu cuenta ($0 créditos = R$ $1). Consulta Ajustes -> Plan.',
    en: 'from your account ($0 credits = R$ $1). See Settings -> Plan.',
    fr: 'à partir de votre compte (crédits de $0 = R$ $1). Voir Paramètres -> Plan.',
  },
  '$0 créditos': {
    es: '$0 créditos',
    en: '$0 credits',
    fr: '$0 crédits',
  },
  'Quando o signatário assinar, serão consumidos': {
    es: 'Cuando el firmante firme, serán consumidos',
    en: 'When the signer signs, they will be consumed',
    fr: 'Lorsque le signataire signe, ils seront consommés',
  },
  'O signatário precisa ter um': {
    es: 'El firmante debe tener un',
    en: 'The signer must have a',
    fr: 'Le signataire doit avoir un',
  },
  'e-CPF ou e-CNPJ': {
    es: 'e-CPF o e-CNPJ',
    en: 'e-CPF or e-CNPJ',
    fr: 'E-CPF ou E-CNPJ',
  },
  'para assinar, conhecidos como': {
    es: 'para firmar, conocido como',
    en: 'to sign, known as',
    fr: 'signer, connu sous le nom',
  },
  'certificado A1 ou A3': {
    es: 'Certificado A1 o A3',
    en: 'A1 or A3 certificate',
    fr: 'Certificat A1 ou A3',
  },
  até: {
    es: 'Hasta que',
    en: 'Until',
    fr: 'Jusqu\'à',
  },
  'O arquivo': {
    es: 'El archivo',
    en: 'The file',
    fr: 'Le fichier',
  },
  cada: {
    es: 'cada',
    en: 'each',
    fr: 'chaque',
  },
  'com no máximo': {
    es: 'con un máximo',
    en: 'with at most',
    fr: 'avec au plus',
  },
  'apenas um arquivo': {
    es: 'solo un archivo',
    en: 'just a file',
    fr: 'Juste un fichier',
  },
  'Ocorreu um erro. Por favor, atualize a página.': {
    es: 'Ocurrio un error. Actualiza la página.',
    en: 'An error has occurred. Please refresh the page.',
    fr: 'Il y a eu une erreur inattendue. Veuillez actualiser la page.',
  },
  'Adicione um slug a sua página.': {
    es: 'Coloca un slug en tu página.',
    en: 'Place a slug to your page.',
    fr: 'Placez une limace sur votre page.',
  },
  'Editar acesso à pasta': {
    es: 'Editar acceso a la carpeta',
    en: 'Edit folder access',
    fr: 'Modifier l\'accès au dossier',
  },
  'Selecione quais usuários terão permissões para visualizar e acessar a pasta.': {
    es: 'Selecciona qué usuarios tendrán permisos para ver y acceder a la carpeta.',
    en: 'Select which users will have permissions to view and access the folder.',
    fr: 'Sélectionnez les utilisateurs qui auront des autorisations pour afficher et accéder au dossier.',
  },
  'Obs: o proprietário da conta sempre terá acesso a todas as pastas.': {
    es: 'Nota: el propietario de la cuenta siempre tendrá acceso a todas las carpetas.',
    en: 'Note: the account owner will always have access to all folders.',
    fr: 'Remarque: Le propriétaire du compte aura toujours accès à tous les dossiers.',
  },
  'Apenas o proprietário da conta está com acesso à pasta.': {
    es: 'Sólo el propietario de la cuenta tiene acceso a la carpeta.',
    en: 'Only the account owner has access to the folder.',
    fr: 'Seul le propriétaire du compte a accès au dossier.',
  },
  'Tornar pasta pública': {
    es: 'Hacer carpeta pública',
    en: 'Make folder public',
    fr: 'Rendre le dossier public',
  },
  'Tornar pasta secreta': {
    es: 'Hacer carpeta secreta',
    en: 'Make folder secret',
    fr: 'Rendre le dossier secret',
  },
  'Ocorreu um erro ao tornar a pasta secreta. Por favor, atualize a página e tente novamente.': {
    es: 'Se produjo un error al volver la carpeta secreta. Actualiza la página y vuelve a intentarlo.',
    en: 'An error occurred while making the folder secret. Please refresh the page and try again.',
    fr: 'Une erreur s\'est produite tout en rendant le dossier secret. Veuillez actualiser la page et réessayer.',
  },
  'Ocorreu um erro ao remover o participante. Por favor, atualize a página e tente novamente.': {
    es: 'Se produjo un error al eliminar al participante. Actualiza la página y vuelve a intentarlo.',
    en: 'An error occurred while removing the participant. Please refresh the page and try again.',
    fr: 'Une erreur s\'est produite lors du retrait du participant. Veuillez actualiser la page et réessayer.',
  },
  'Todas as restrições de acesso a pasta foram retiradas com sucesso.': {
    es: 'Todas las restricciones sobre el acceso a la carpeta se han eliminado correctamente.',
    en: 'All restrictions on folder access have been successfully removed.',
    fr: 'Toutes les restrictions sur l\'accès au dossier ont été supprimées avec succès.',
  },
  'Ocorreu um erro ao processar o pedido. Por favor, atualize a página e tente novamente.': {
    es: 'Hubo un error al procesar la solicitud. Actualiza la página y vuelva a intentarlo.',
    en: 'There was an error processing the request. Please refresh the page and try again.',
    fr: 'Il ya eu une erreur lors du traitement de la demande. Veuillez actualiser la page et réessayer.',
  },
  'Usuário adicionado com sucesso.': {
    es: 'Usuario agregado exitosamente.',
    en: 'User successfully added.',
    fr: 'L\'utilisateur a ajouté avec succès.',
  },
  'Por favor, verifique se o usuário já está na pasta ou atualize a página e tente novamente.': {
    es: 'Verifica si el usuario ya está en la carpeta o actualiza la página y vuelve a intentarlo.',
    en: 'Please check if the user is already in the folder or refresh the page and try again.',
    fr: 'Veuillez vérifier si l\'utilisateur est déjà dans le dossier ou actualiser la page et réessayer.',
  },
  'Não é possível posicionar assinaturas com certificado digital, nem inserir um segundo signatário no modelo.': {
    es: 'No es posible posicionar firmas con certificado digital, ni insertar un segundo firmante en la plantilla.',
    en: 'It is not possible to place signatures with digital certificate, nor to insert a second signer in the template.',
    fr: 'Il n\'est pas possible de placer des signatures avec un certificat numérique, ni d\'insérer un deuxième signataire dans le modèle.',
  },
  'Para validade jurídica, você precisará disponibilizar uma cópia do documento para o signatário por outro meio.': {
    es: 'Para validez jurídica, deberás poner a disposición del firmante una copia del contrato por otros medios.',
    en: 'For legal validity, you will need to make a copy of the contract available to the signer by other means.',
    fr: 'Pour une validité légale, vous devrez mettre une copie du contrat à la disposition du signataire par d\'autres moyens.',
  },
  'Foram omitidos do envio automático os signatários que não possuem e-mail.': {
    es: 'Los firmantes que no tienen correo electrónico fueron excluidos del envío automático.',
    en: 'Signers without email addresses were excluded from automatic sending.',
    fr: 'Les signataires sans adresses e-mail ont été exclus de l\'envoi automatique.',
  },
  'Atenção: não receberá o link automaticamente, pois não possui e-mail.': {
    es: 'Atención: no recibirás el enlace automáticamente, ya que no tienes correo.',
    en: 'Attention: will not receive the link automatically, as does not have an email.',
    fr: 'ATTENTION: ne recevra pas le lien automatiquement, comme cela ne dispose pas d\'un e-mail.',
  },
  'Não possui e-mail': {
    es: 'No tiene correo',
    en: 'Does not have email',
    fr: 'N\'a pas de courrier électronique',
  },
  'E-mail será exibido no documento final. Clique para ocultar.': {
    es: 'El correo electrónico aparecerá en el documento final. Haz clic para ocultarlo.',
    en: 'Email will be shown in the final document. Click to hide.',
    fr: 'Le courrier électronique sera affiché dans le document final. Cliquez pour masquer.',
  },
  'E-mail será ocultado no documento final. Clique para exibir.': {
    es: 'El correo electrónico se ocultará en el documento final. Haz clic para demostrarlo.',
    en: 'Email will be hidden in the final document. Click to show.',
    fr: 'Le courrier électronique sera caché dans le document final. Cliquez pour afficher.',
  },
  'E-mail será solicitado. Clique caso signatário não possua e-mail.': {
    es: 'Se solicitará un correo electrónico. Haz clic si el firmante no tiene correo electrónico.',
    en: 'Email will be requested. Click if signer does not have email.',
    fr: 'Un e-mail sera demandé. Cliquez sur si le signataire n\'a pas de courrier électronique.',
  },
  'E-mail não será solicitado. Clique caso signatário possua e-mail.': {
    es: 'No se solicitará correo electrónico. Haz clic si el firmante tiene correo electrónico.',
    en: 'Email will not be requested. Click if the signer has email.',
    fr: 'Le courrier électronique ne sera pas demandé. Cliquez si le signataire a un e-mail.',
  },
  'Telefone será exibido no documento final. Clique para ocultar.': {
    es: 'El teléfono aparecerá en el documento final. Haz clic para ocultarlo.',
    en: 'Phone will be shown in the final document. Click to hide.',
    fr: 'Le téléphone sera affiché dans le document final. Cliquez pour masquer.',
  },
  'Telefone será ocultado no documento final. Clique para exibir.': {
    es: 'El teléfono se ocultará en el documento final. Haz clic para demostrarlo.',
    en: 'Phone will be hidden in the final document. Click to show.',
    fr: 'Le téléphone sera caché dans le document final. Cliquez pour afficher.',
  },
  'Telefone será solicitado. Clique para não solicitar telefone.': {
    es: 'El celular será solicitado. Haz clic para no solicitarlo.',
    en: 'Phone will be requested. Click to not request phone.',
    fr: 'Le téléphone sera demandé. Cliquez pour ne pas demander le téléphone.',
  },
  'Telefone não será solicitado. Clique para solicitar telefone.': {
    es: 'No se solicitará teléfono. Haz clic para solicitarlo.',
    en: 'Phone will not be requested. Click to request phone.',
    fr: 'Le téléphone ne sera pas demandé. Cliquez pour demander le téléphone.',
  },
  'Não é possível desabilitar e-mail e telefone ao mesmo tempo. Telefone foi habilitado de volta.': {
    es: 'No es posible desactivar el correo electrónico y el teléfono al mismo tiempo. El teléfono se ha habilitado nuevamente.',
    en: 'It is not possible to disable email and phone at the same time. Phone has been enabled back.',
    fr: 'Il n\'est pas possible de désactiver les e-mails et le téléphone en même temps. Le téléphone a été activé.',
  },
  'Não é possível desabilitar e-mail e telefone ao mesmo tempo. E-mail foi habilitado de volta.': {
    es: 'No es posible desactivar el e-mail y el teléfono al mismo tiempo. El e-mail se ha habilitado nuevamente.',
    en: 'It is not possible to disable email and phone at the same time. Email has been enabled back.',
    fr: 'Il n\'est pas possible de désactiver les e-mails et le téléphone en même temps. Le courrier électronique a été activé.',
  },
  'Verificador de Autenticidade': {
    es: 'Verificador de Autenticidad',
    en: 'Document Validation',
    fr: 'Validation du document',
  },
  'Dados do documento': {
    es: 'Datos del documento',
    en: 'Document information',
    fr: 'Documer des informations',
  },
  'Data de criação': {
    es: 'Fecha de creación',
    en: 'Creation date',
    fr: 'Date de création',
  },
  'Trocar de organização': {
    es: 'Cambiar organización',
    en: 'Switch organization',
    fr: 'Commutation de l\'organisation',
  },
  '{{NOME DO CAMPO}}': {
    es: '{{NOMBRE DEL CAMPO}}',
    en: '{{FIELD NAME}}',
    fr: '{{NOM DE DOMAINE}}',
  },
  'Pasta secreta': {
    es: 'Carpeta secreta',
    en: 'Private folder',
    fr: 'Dossier secret',
  },
  'Novo Contato': {
    es: 'Nuevo contacto',
    en: 'New contact',
    fr: 'Nouveau contact',
  },
  'Carregando seus contatos...': {
    es: 'Cargando tus contactos ...',
    en: 'Loading your contacts...',
    fr: 'Chargement de vos contacts ...',
  },
  'Editar contato': {
    es: 'Editar contacto',
    en: 'Edit contact',
    fr: 'Modifier le contact',
  },
  'Salvar como contato favorito': {
    es: 'Guardar como contacto favorito',
    en: 'Save as favorite contact',
    fr: 'Enregistrer comme contact préféré',
  },
  'O nome do contato é obrigatório': {
    es: 'El nombre del contacto es obligatorio',
    en: 'Contact name is required',
    fr: 'Le nom de contact est requis',
  },
  'Salvar contato': {
    es: 'Guardar contacto',
    en: 'Save contact',
    fr: 'Enregistrer le contact',
  },
  'Contato $0 adicionado com sucesso': {
    es: 'Contacto $0 agregado exitosamente',
    en: 'Contact $0 added successfully',
    fr: 'Contacter $0 ajouté avec succès',
  },
  'Foram encontrados $0 contato(s).': {
    es: 'Se encontraron $0 contacto(s).',
    en: 'Found $0 contact(s).',
    fr: 'Contact (s) de $0.',
  },
  'Descartar alterações': {
    es: 'Descartar cambios',
    en: 'Discard changes',
    fr: 'Annuler les modifications',
  },
  'Mostrando $0-$1 de $2 contatos': {
    es: 'Mostrando $0-$1 de $2 contactos',
    en: 'Showing $0-$1 of $2 contacts',
    fr: 'Affichage de $0-$1 sur $2 contacts',
  },
  Ações: {
    es: 'Acciones',
    en: 'Actions',
    fr: 'Actions',
  },
  'Você ainda não adicionou nenhum contato': {
    es: 'Aún no has agregado ningún contacto',
    en: 'You haven\'t added any contacts yet',
    fr: 'Vous n\'avez pas encore ajouté de contacts',
  },
  'Salvar signatários em meus contatos': {
    es: 'Guardar firmantes en mis contactos',
    en: 'Save signers to my contacts',
    fr: 'Enregistrer les signataires sur mes contacts',
  },
  'Deletar contato': {
    es: 'Borrar contacto',
    en: 'Delete contact',
    fr: 'Effacer le contact',
  },
  Favorito: {
    es: 'Favorito',
    en: 'Favorite',
    fr: 'Favoris',
  },
  'Novo contato': {
    es: 'Nuevo contacto',
    en: 'New contact',
    fr: 'Nouveau contact',
  },
  'Não favorito': {
    es: 'No favorito',
    en: 'Not favorite',
    fr: 'Pas favoris',
  },
  'Contato editado com sucesso': {
    es: 'Contacto editado correctamente',
    en: 'Contact edited successfully',
    fr: 'Contactez édité avec succès',
  },
  'Contato não favoritado': {
    es: 'Contacto no favorito',
    en: 'Unfavorite Contact',
    fr: 'Contact pas favoris',
  },
  'Signatário adicionado!': {
    es: '¡Firmante agregado!',
    en: 'Signatory Added!',
    fr: 'Signatoire ajouté!',
  },
  'Você não possui contatos ainda': {
    es: 'Aún no has agregado contactos.',
    en: 'You haven\'t added contacts yet.',
    fr: 'Vous n\'avez pas encore ajouté de contacts.',
  },
  'Buscar por nome, e-mail ou telefone': {
    es: 'Buscar por nombre, correo electrónico o teléfono',
    en: 'Search by name, email or phone',
    fr: 'Recherche par nom, e-mail ou téléphone',
  },
  'Apagar filtro': {
    es: 'Eliminar filtro',
    en: 'Delete filter',
    fr: 'Supprimer le filtre',
  },
  Classificação: {
    es: 'Clasificación',
    en: 'Classification',
    fr: 'Classification',
  },
  'Realizar pesquisa': {
    es: 'Investigacion de conducta',
    en: 'Conduct research',
    fr: 'De mener des recherches',
  },
  'Apagar pesquisa': {
    es: 'Eliminar búsqueda',
    en: 'Delete search',
    fr: 'Supprimer la recherche',
  },
  'Contato favoritado': {
    es: 'Contacto favorito',
    en: 'Favorite contact',
    fr: 'Contact favori',
  },
  'A senha parece incorreta. Você esqueceu sua senha?': {
    es: 'La contraseña parece incorrecta. ¿Olvidaste tu contraseña?',
    en: 'The password seems incorrect. Did you forget your password?',
    fr: 'Le mot de passe semble incorrect. Avez-vous oublié votre mot de passe?',
  },
  'Captcha inválido. Por favor, atualize a página e tente novamente.': {
    es: 'Captcha inválido. Por favor, actualiza la página y vuelve a intentarlo.',
    en: 'Invalid captcha. Please refresh the page and try again.',
    fr: 'Captcha invalide. Veuillez actualiser la page et réessayer.',
  },
  'Sua senha expirou porque sua ultima redefinição foi a mais de 3 meses, por favor redefina sua senha clicando em "Esqueci minha senha"': {
    es: 'Tu contraseña ha caducado porque tu última restablecimiento fue hace más de 3 meses. Por favor, restablece tu contraseña haciendo clic en "Olvidé mi contraseña".',
    en: 'Your password has expired because your last reset was more than 3 months ago. Please reset your password by clicking on "Forgot my password".',
    fr: 'Votre mot de passe a expiré car votre dernier réinitialisation remonte à plus de 3 mois. Veuillez réinitialiser votre mot de passe en cliquant sur "Mot de passe oublié".',
  },
  'Não foi possível carregar o Google Captcha. Por favor, atualize a página.': {
    es: 'Google Captcha no se pudo cargar. Por favor, actualiza la página.',
    en: 'Google Captcha failed to load. Please refresh the page.',
    fr: 'Google Captcha n\'a pas réussi à se charger. Veuillez actualiser la page.',
  },
  'Você criou $0 documentos desde $1': {
    es: 'Creaste $0 documentos desde $1',
    en: 'You created $0 documents since $1',
    fr: 'Vous avez créé $0 de documents depuis $1',
  },
  'Você utilizará $0 créditos para criar o documento caso prossiga.': {
    es: 'Si continuas, utilizarás $0 créditos para crear el documento.',
    en: 'You will use $0 credits to create the document if you proceed.',
    fr: 'Vous utiliserez des crédits de $0 pour créer le document si vous continuez.',
  },
  'Documento acima do limite no Plano Profissional ($0 créditos)': {
    es: 'Documento extra después del límite establecido ($0 créditos)',
    en: 'Additional document after the established limit ($0 credits)',
    fr: 'Document supplémentaire après la limite établie (crédits de $0)',
  },
  'Escolha seu plano inicial': {
    es: 'Eliga tu plan inicial',
    en: 'Choose your initial plan',
    fr: 'Choisissez votre plan initial',
  },
  'Mais vendido': {
    es: 'Más vendido',
    en: 'Best seller',
    fr: 'Best-seller',
  },
  Essencial: {
    es: 'Essential',
    en: 'Essential',
    fr: 'Essentiel',
  },
  Completo: {
    es: 'Business',
    en: 'Business',
    fr: 'Entreprise',
  },
  Profissional: {
    es: 'Professional',
    en: 'Professional',
    fr: 'Professionnel',
  },
  'Está começando ou quer apenas experimentar': {
    es: 'Empezando o solo quieres probar',
    en: 'Starting out or just experimenting',
    fr: 'Commencer ou simplement expérimenter',
  },
  'Equipes com baixo volume de documentos': {
    es: 'Equipos con poco volumen de documentos',
    en: 'Teams with low volume of documents',
    fr: 'Équipes avec un faible volume de documents',
  },
  'Equipes com alto volume de documentos': {
    es: 'Equipos con alto volumen de documentos',
    en: 'Teams with high volume of documents',
    fr: 'Équipes avec un volume élevé de documents',
  },
  'R$ $0/$1': {
    es: 'R$ $0/$1',
    en: 'R$ $0/$1',
    fr: 'R$ $0/$1',
  },
  'Você +$0 usuários': {
    es: 'Tu +$0 usuarios',
    en: 'You +$0 users',
    fr: 'Vous +$0 utilisateurs',
  },
  'sem limite': {
    es: 'sin limite',
    en: 'unlimited',
    fr: 'illimité',
  },
  '240 docs/ano': {
    es: '240 docs/año',
    en: '240 docs/year',
    fr: '240 docs / an',
  },
  '5 docs/mês': {
    es: '5 docs/mes',
    en: '5 docs/month',
    fr: '5 docs / mois',
  },
  '20 docs/mês': {
    es: '20 docs/mes',
    en: '20 docs/month',
    fr: '20 docs / mois',
  },
  Grátis: {
    es: 'Gratis',
    en: 'Free',
    fr: 'Gratuit',
  },
  'Somente 1 usuário': {
    es: 'Solamente 1 usuario',
    en: 'Only 1 user',
    fr: 'Seulement 1 utilisateur',
  },
  'A partir de': {
    es: 'Desde',
    en: 'From',
    fr: 'Depuis',
  },
  'Documentos ilimitados': {
    es: 'Documentos ilimitados',
    en: 'Unlimited documents',
    fr: 'Documents illimités',
  },
  'Documentos personalizados': {
    es: 'Documentos personalizados',
    en: 'Custom documents',
    fr: 'Documents personnalisés',
  },
  'Deseja mesmo remover o relatório?': {
    es: '¿Realmente desea eliminar el informe?',
    en: 'Do you really want to remove the report?',
    fr: 'Voulez-vous vraiment supprimer le rapport?',
  },
  'Manter relatório': {
    es: 'Mantener informe',
    en: 'Keep report',
    fr: 'Tenir le rapport',
  },
  'Antecedentes excluídos': {
    es: 'Antecedentes eliminados',
    en: 'Deleted backgrounds',
    fr: 'Arrière-plans supprimés',
  },
  'Apenas o(a) proprietário(a) da conta possui acesso aos relatórios de antecedentes excluídos. É possível recuperar os relatórios ou excluir definitivamente.': {
    es: 'Solo el propietario de la cuenta tiene acceso a los informes eliminados. Puedes recuperar los informes o eliminarlos de forma permanente.',
    en: 'Only the account owner has access to deleted reports. You can recover the reports or permanently delete them.',
    fr: 'Seul le propriétaire du compte a accès à des rapports supprimés. Vous pouvez récupérer les rapports ou les supprimer définitivement.',
  },
  'Ver relatórios': {
    es: 'Ver los informes',
    en: 'View reports',
    fr: 'Afficher les rapports',
  },
  'Não há relatórios na lixeira.': {
    es: 'No hay informes en la papelera.',
    en: 'There are no reports in the trash.',
    fr: 'Il n\'y a aucun rapport dans la poubelle.',
  },
  'Relatório excluído com sucesso.': {
    es: 'Informe eliminado correctamente.',
    en: 'Report deleted successfully.',
    fr: 'Rapport supprimé avec succès.',
  },
  'Ocorreu um erro ao excluir o relatório. Por favor recarregue a página e tente novamente mais tarde.': {
    es: 'Hubo un error al eliminar el informe. Vuelve a cargar la página y inténtalo nuevamente.',
    en: 'There was an error deleting when deleting the report. Please reload the page and try again later.',
    fr: 'Il y a eu une erreur de suppression lors de la suppression du rapport. Veuillez recharger la page et réessayer plus tard.',
  },
  'Você não possui permissão para ver os relatórios excluídos.': {
    es: 'No tienes permiso para ver informes eliminados.',
    en: 'You don\'t have permission to view deleted reports.',
    fr: 'Vous n\'avez pas la permission de voir les rapports supprimés.',
  },
  'O relatório foi excluído com sucesso.': {
    es: 'El informe se eliminó correctamente.',
    en: 'The report was successfully deleted.',
    fr: 'Le rapport a été supprimé avec succès.',
  },
  'Ocorreu um erro ao excluir o relatório.': {
    es: 'Se produjo un error al eliminar el informe.',
    en: 'An error occurred while deleting the report.',
    fr: 'Une erreur s\'est produite lors de la suppression du rapport.',
  },
  'Sim, remover (sem volta)': {
    es: 'Sí, eliminar (sin retorno)',
    en: 'Yes, remove (no return)',
    fr: 'Oui, supprimer (non de retour)',
  },
  ' e gostaria de falar com o suporte': {
    es: ' y me gustaría hablar con el soporte',
    en: ' and I would like to speak to support',
    fr: ' Et je voudrais parler pour soutenir',
  },
  ' e gostaria de falar com vendas para comprar ': {
    es: ' y me gustaría hablar con ventas para comprar',
    en: ' and I would like to speak with sales to purchase '
  },
  'Olá, sou ': {
    es: 'Hola, soy ',
    en: 'Hi, I am ',
    fr: 'Salut je suis',
  },
  ' e estou pensando em cancelar meu plano na ZapSign porque: ': {
    es: ' y estoy pensando en cancelar mi plan en ZapSign porque: ',
    en: ' and I\'m thinking about canceling my plan at ZapSign because: ',
    fr: ' Et je pense à l\'annulation de mon plan chez ZapSign parce que:',
  },
  '. Pode me ajudar?': {
    es: '. ¿Puedes ayudarme?',
    en: '. Can you help me?',
    fr: '. Pouvez-vous m\'aider?',
  },
  'Renomear documento': {
    es: 'Renombrar documento',
    en: 'Rename document',
    fr: 'Renommer le document',
  },
  'Editar configurações': {
    es: 'Editar configuración',
    en: 'Edit settings',
    fr: 'Modifier les paramètres',
  },
  'Nome do documento': {
    es: 'Nombre del documento',
    en: 'Document name',
    fr: 'Nom du document',
  },
  'Ocorreu um erro durante o processo de alteração do nome do documento. Por favor recarregue a página e tente novamente mais tarde.': {
    es: 'Ocurrió un error durante el proceso de cambio de nombre del documento. Vuelve a cargar la página e inténtalo nuevamente.',
    en: 'An error occurred during the document name change process. Please reload the page and try again later.',
    fr: 'Une erreur s\'est produite pendant le processus de changement de nom de document. Veuillez recharger la page et réessayer plus tard.',
  },
  'Você criou $0 documentos nos últimos $1 dias.': {
    es: 'Creó $0 documentos en los últimos $1 días',
    en: 'You created $0 documents in the last $1 days.',
    fr: 'Vous avez créé des documents de $0 au cours des derniers jours.',
  },
  'Limite atingido': {
    es: 'Límite alcanzado',
    en: 'Limit reached',
    fr: 'Limite atteinte',
  },
  'Confira nossos planos para criar documentos ilimitadamente.': {
    es: 'Consulta nuestros planes para crear documentos de forma ilimitada.',
    en: 'Check out our plans to create documents unlimitedly.',
    fr: 'Consultez nos plans pour créer des documents illimités.',
  },
  'No Plano Completo, existe um limite gratuito de documentos criados via Modelos, de 200 documentos/mês': {
    es: 'En el plan de documentos ilimitados, hay un límite gratuito de documentos creados a través de Plantillas, de 200 documentos/mes',
    en: 'In the unlimited documents plan, there is a free limit of documents created via Templates, of 200 documents/month',
    fr: 'Dans le plan de documents illimité, il y a une limite libre de documents créés via des modèles, de 200 documents / mois',
  },
  'Acima disso há um custo automático de 5 créditos (R$ 0,50) por documento adicional criado via Modelo.': {
    es: 'Por encima de eso, contáctenos en support@zapsign.com.br',
    en: 'Above that, contact us at support@zapsign.com.br',
    fr: 'Ci-dessus, contactez-nous à support@zapsign.com.br',
  },
  'No Plano Profissional, o limite é de 20 documentos/mês': {
    es: 'En el Plan Profesional, el límite es de 20 documentos/mes',
    en: 'In the Professional Plan, the limit is 20 documents/month',
    fr: 'Dans le plan professionnel, la limite est de 20 documents / mois',
  },
  ' e cada documento adicional custará 25 créditos (R$ 2,50)': {
    es: '', // vazio mesmo
    en: '', // vazio mesmo
    fr: '',
  },
  'Esta organização ultrapassou o limite de usuários. Por favor, fale com o proprietário da conta para atualizar o plano ou contratar usuários adicionais.': {
    es: 'Esta organización ha superado el límite de usuarios. Habla con el propietario de la cuenta para actualizar el plan o contratar usuarios adicionales ',
    en: 'This organization has exceeded the user limit. Please speak with the account owner to upgrade the plan or hire additional users.',
    fr: 'Cette organisation a dépassé la limite de l\'utilisateur. Veuillez parler avec le propriétaire du compte pour mettre à niveau le plan ou embaucher des utilisateurs supplémentaires.',
  },
  'Sua conta ultrapassou o limite de usuários. Por favor, atualize seu plano ou contrate usuários adicionais.': {
    es: 'Tu cuenta ha superado el límite de usuarios. Actualiza el plan o compra usuarios adicionales.',
    en: 'Your account has exceeded the user limit. Please update your plan or hire additional users.',
    fr: 'Votre compte a dépassé la limite de l\'utilisateur. Veuillez mettre à jour votre plan ou embaucher des utilisateurs supplémentaires.',
  },
  'Sem acesso': {
    es: 'Sin acceso',
    en: 'No access',
    fr: 'Pas d\'accès',
  },
  'Cresça e profissionalize seu negócio.': {
    es: 'Profesionaliza y desarrolla tu negocio.',
    en: 'Grow and professionalize your business.',
    fr: 'Grandir et professionnaliser votre entreprise.',
  },
  Moeda: {
    es: 'Moneda',
    en: 'Currency',
    fr: 'Devise',
  },
  'No reconhecimento facial, o signatário irá se filmar seguindo instruções de "vire o rosto". O signatário também deverá fornecer fotos frente e verso de seu RG ou CNH. O rosto filmado será comparado com a foto do documento oficial.': {
    es: 'En el reconocimiento facial, el firmante se filmará a sí mismo siguiendo las instrucciones para "volver la cara". El signatario también debe proporcionar fotografías a dos caras de su documento de identidad. El rostro filmado se comparará con la foto del documento oficial.',
    en: 'In face recognition, the signer will film himself following instructions to "turn face". The signer must also provide two-sided photos of their identity document. The filmed face will be compared with the photo from the official document.',
    fr: 'En reconnaissance faciale, le signataire se filmera à la suite d\'instructions pour "tourner le visage". Le signataire doit également fournir des photos bilatérales de leur document d\'identité. Le visage filmé sera comparé à la photo du document officiel.',
  },
  'Reconhecimento facial': {
    es: 'Reconocimiento facial',
    en: 'Facial recognition',
    fr: 'Reconnaissance faciale',
  },
  'Custo adicional': {
    es: 'Costo adicional',
    en: 'Additional charge',
    fr: 'Frais supplémentaires',
  },
  'O reconhecimento facial tem um custo adicional de 15 créditos caso utilizado (15 créditos = R$ 1,50).': {
    es: 'El reconocimiento facial tiene un costo adicional. Ponte en contacto con support@zapsign.com.br si estás interesado.',
    en: 'Facial recognition has an additional cost if used. Contact support@zapsign.com.br if you are interested.',
    fr: 'La reconnaissance faciale a un coût supplémentaire s\'il est utilisé. Contactez support@zapsign.com.br si vous êtes intéressé.',
  },
  'Preparando sistema. Mais um instante...': {
    es: 'Preparando el sistema. Un momento más...',
    en: 'Preparing system. Just a moment...',
    fr: 'Système de préparation. Juste un moment...',
  },
  'Inicie a validação de reconhecimento facial.': {
    es: 'Iniciar la validación del reconocimiento facial.',
    en: 'Start facial recognition validation.',
    fr: 'Démarrer la validation de la reconnaissance faciale.',
  },
  'Você será redirecionado...': {
    es: 'Serás redirigido...',
    en: 'You will be redirected...',
    fr: 'Vous serez redirigé ...',
  },
  'Erro no reconhecimento facial. Tente novamente.': {
    es: 'Error de validación de identidad: Por favor, actualiza la página e intenta nuevamente.',
    en: 'Face recognition error. Try again.',
    fr: 'Erreur de reconnaissance du visage. Essayer à nouveau.',
  },
  'Validado com sucesso!': {
    es: '¡Validado con éxito!',
    en: 'Successfully validated!',
    fr: 'Validé avec succès!',
  },
  'Salvar signatários como contatos': {
    es: 'Guardar firmantes como contactos',
    en: 'Save signers as contacts',
    fr: 'Enregistrer les signataires en contacts',
  },
  'Verificando reconhecimento facial. Mais um instante...': {
    es: 'Analizando el reconocimiento facial. Un momento más...',
    en: 'Analyzing facial recognition. Just a moment...',
    fr: 'Analyse de la reconnaissance faciale. Juste un moment...',
  },
  'Por favor, faça login novamente. Tempo de sessão esgotado.': {
    es: 'Ingresa nuevamente. Se agotó el tiempo de espera de la sesión.',
    en: 'Please login again. Session timed out.',
    fr: 'Veuillez vous connecter à nouveau. Session expirée.',
  },
  'O nome do documento não pode ser vazio.': {
    es: 'El nombre del documento no puede estar vacío.',
    en: 'The document name cannot be empty.',
    fr: 'Le nom du document ne peut pas être vide.',
  },
  'Nenhum contato encontrado': {
    es: 'Ningún contacto encontrado',
    en: 'No contacts found',
    fr: 'Aucun contact trouvé',
  },
  'Plano ativo até ': {
    es: 'Plan activo hasta el ',
    en: 'Plan active until ',
    fr: 'Planifier actif jusqu\'à',
  },
  'Para renovação ou em caso de dúvida contate support@zapsign.com.br': {
    es: 'Para renovar el plan o en caso de duda contactar a support@zapsign.com.br',
    en: 'To renew the subscription or in case of doubt, contact support@zapsign.com.br',
    fr: 'Pour renouveler l\'abonnement ou en cas de doute, contactez support@zapsign.com.br',
  },
  'Você atingiu o limite de documentos do seu plano. Confira suas Configurações > Plano.': {
    es: 'Superaste el límite de documentos de tu plan. Verifica en Ajustes > Plan.',
    en: 'You\'ve reached the documents limit of your plan. Check out your Settings > Plan.',
    fr: 'Vous avez atteint la limite des documents de votre plan. Consultez vos paramètres> Plan.',
  },
  'Você atingiu o limite de requisições OTP do seu Plano. Tente mudar o metodo de autenticação ou confira suas Configurações > Plano.': {
    es: 'Superaste el límite de solicitudes de OTP de tu Plan. Intenta cambiar el método de autenticación o verifica en Ajustes > Plan.',
    en: 'You\'ve reached your Plan\'s OTP request limit. Try changing the authentication method or check out your Settings > Plan.',
    fr: 'Vous avez atteint la limite de demande OTP de votre plan. Essayez de modifier la méthode d\'authentification ou consultez vos paramètres> Plan.',
  },
  'Fuso horário': {
    es: 'Zona horaria',
    en: 'Time zone',
    fr: 'Fuseau horaire',
  },
  Preferências: {
    es: 'Preferencias',
    en: 'Preferences',
    fr: 'Préférences',
  },
  Integrações: {
    es: 'Integraciones',
    en: 'Integrations',
    fr: 'Intégrations',
  },
  'Planos e preços': {
    es: 'Planes y precios',
    en: 'Plans and prices',
    fr: 'Plans et prix',
  },
  'Configure preferências da sua conta.': {
    es: 'Establece las preferencias de tu cuenta',
    en: 'Set your account preferences.',
    fr: 'Définissez vos préférences de compte.',
  },
  Internacionalização: {
    es: 'Internacionalización',
    en: 'Internationalization',
    fr: 'Internationalisation',
  },
  'Continuar no documento já prenchido?': {
    es: '¿Continuar con el documento diligenciado?',
    en: 'Continue with the pre-filled document',
    fr: 'Continuez avec le document déjà rempli?',
  },
  'Deseja continuar no documento que você já havia preenchido ou começar um novo documento?': {
    es: '¿Quieres continuar con el documento que ya habías diligenciado o comenzar uno nuevo?',
    en: 'Do you want to continue on the document you have already filled out or start a new document?',
    fr: 'Voulez-vous continuer le document que vous avez déjà rempli ou démarrer un nouveau document?',
  },
  'Sim, continuar': {
    es: 'Sí, continuar',
    en: 'Yes, continue',
    fr: 'Oui, continuez',
  },
  'Não, começar um novo': {
    es: 'No, empezar uno nuevo',
    en: 'No, start a new one',
    fr: 'Non, commencez un nouveau',
  },
  'Extensões de imagens aceitas: .png ou .jpeg': {
    es: 'Formatos de imagen compatibles: .png o .jpeg',
    en: 'Supported image extensions: .png or .jpeg',
    fr: 'Extensions d\'image prises en charge: .png ou .jpeg',
  },
  'https://zapsign.com.br/': {
    es: 'https://zapsign.co/es/',
    en: 'https://zapsign.co/',
    fr: 'https://zapsign.co/',
  },
  'Integre a ZapSign a +1000 de aplicativos sem precisar escrever uma linha de código. Confira alguns exemplos:': {
    es: 'Integra ZapSign con más de 1000 aplicaciones, sin tener que escribir una línea de código. Mira algunos ejemplos:',
    en: 'Integrate ZapSign to +1000 apps, without a single line of code. Check out some examples:',
    fr: 'Intégrez ZapSign à +1000 applications, sans une seule ligne de code. Découvrez quelques exemples:',
  },
  'Integre a ZapSign a mais de 2.000 plataformas sem uma linha de código via Pluga, Make ou Zapier. Selecione a plataforma para vincular o aplicativo.': {
    es: 'Integra ZapSign a más de 2.000 plataformas sin una línea de código por medio de Pluga, Make o Zapier. Selecciona la plataforma para vincular la aplicación.',
    en: 'Integrate ZapSign to 2,000 platforms, without a single line of code with Pluga, Make, or Zapier. Select the platform to link the application.',
    fr: 'Intégrez ZapSign à 2 000 plates-formes, sans une seule ligne de code avec Pluga, Make ou Zapier. Sélectionnez la plate-forme pour lier l\'application.',
  },
  'Habilitar Integromat': {
    es: 'Habilitar Integromat',
    en: 'Enable Integromat',
    fr: 'Activer Integromat',
  },
  Aplicativos: {
    es: 'Aplicaciones',
    en: 'Apps',
    fr: 'applications',
  },
  Ativar: {
    es: 'Activar',
    en: 'Activate',
    fr: 'Activer',
  },
  'Atualizar modelo': {
    es: 'Actualizar plantilla',
    en: 'Update template',
    fr: 'Modèle de mise à jour',
  },
  'Faça upload da nova versão do modelo (.docx)': {
    es: 'Sube la nueva versión de la plantilla (.docx)',
    en: 'Upload the new template version (.docx)',
    fr: 'Téléchargez la nouvelle version de modèle (.docx)',
  },
  'Faça upload do arquivo .docx contendo as alterações pertencentes ao modelo.': {
    es: 'Carga el archivo .docx que contiene los cambios relacionados con la plantilla.',
    en: 'Upload the .docx file containing the changes pertaining to the template.',
    fr: 'Téléchargez le fichier .docx contenant les modifications relatives au modèle.',
  },
  'Campos dinâmicos mantidos': {
    es: 'Se mantienen los campos dinámicos',
    en: 'Dynamic fields maintained',
    fr: 'Champs dynamiques maintenus',
  },
  'Campos dinâmicos faltantes': {
    es: 'Faltan campos dinámicos',
    en: 'Missing dynamic fields',
    fr: 'Champs dynamiques manquants',
  },
  'Campos dinâmicos adicionados': {
    es: 'Campos dinámicos adicionados',
    en: 'Added dynamic fields',
    fr: 'Ajout de champs dynamiques',
  },
  'Não é permitido remover nem adicionar novos campos dinâmicos.': {
    es: 'No está permitido eliminar o agregar nuevos campos dinámicos.',
    en: 'It is not allowed to remove and add new dynamic fields.',
    fr: 'Il n\'est pas autorisé à supprimer et à ajouter de nouveaux champs dynamiques.',
  },
  'Para contratar algum plano de API em moeda diferente de USD, contatar support@zapsign.com.br.': {
    es: 'Para contratar un plan API en una moneda diferente al USD, comunícate con support@zapsign.com.br.',
    en: 'To subscribe to an API plan in a currency other than USD, contact support@zapsign.com.br.',
    fr: 'Pour vous abonner à un plan API dans une devise autre que USD, contactez support@zapsign.com.br.',
  },
  'Ative para definir métodos de autenticação e opções avançadas dos signatários (ex. e-mail, telefone, selfie, foto de documento, reconhecimento facial).': {
    es: 'Habilita esta opción para configurar los métodos de autenticación del firmante y las opciones avanzadas (por ejemplo, correo electrónico, teléfono, selfie, foto de documento, reconocimiento facial).',
    en: 'Enable to set signer authentication methods and advanced options (eg. email, phone, selfie, document photo, face recognition).',
    fr: 'Activer de définir des méthodes d\'authentification des signataires et des options avancées (par exemple, e-mail, téléphone, selfie, photo de document, reconnaissance du visage).',
  },
  'Mural de novidades': {
    es: 'Tablero de noticias',
    en: 'News board',
    fr: 'Comité de presse',
  },
  'Confira abaixo as últimas novidades que a ZapSign preparou para você:': {
    es: 'Consulta las últimas novedades que ZapSign ha preparado para ti:',
    en: 'Check out the latest news that ZapSign has prepared for you below:',
    fr: 'Découvrez les dernières nouvelles que ZapSign vous a préparée ci-dessous:',
  },
  'Visualizar últimas novidades': {
    es: 'Ver las últimas noticias',
    en: 'View latest news',
    fr: 'Voir les dernières nouvelles',
  },
  'Não encontramos nenhum plano ativo em sua conta': {
    es: 'No encontramos ningún plan activo en tu cuenta.',
    en: 'We didn\'t find any active plans in your account.',
    fr: 'Nous n\'avons trouvé aucun plan actif dans votre compte.',
  },
  'Não há plano ativo': {
    es: 'No hay plan activo',
    en: 'There is no active plan',
    fr: 'Il n\'y a pas de plan actif',
  },
  'Não conseguimos achar um plano ativo. Por isso, você não pode acessar esse modelo.': {
    es: 'No podemos encontrar un plan activo. Por lo tanto, no puedes acceder a esta plantilla.',
    en: 'We are unable to find an active plan. Therefore, you cannot access this template.',
    fr: 'Nous ne pouvons pas trouver de plan actif. Par conséquent, vous ne pouvez pas accéder à ce modèle.',
  },
  'Modelo com problemas técnicos': {
    es: 'Plantilla con problemas técnicos',
    en: 'Template with technical problems',
    fr: 'Modèle avec des problèmes techniques',
  },
  'Modelo não encontrado': {
    es: 'Plantilla no encontrada',
    en: 'Template not found',
    fr: 'Modèle introuvable',
  },
  'Ops! Não encontramos um Modelo disponível nesse link. O link está incorreto ou o Modelo pode ter sido excluído.': {
    es: 'Oops! No encontramos una plantilla disponible en este enlace. El enlace es incorrecto o la plantilla fue eliminada.',
    en: 'Oops! We did not find a template available on this link. The link is incorrect or the template may have been deleted.',
    fr: 'Oops! Nous n\'avons pas trouvé de modèle disponible sur ce lien. Le lien est incorrect ou le modèle peut avoir été supprimé.',
  },
  'Erro: campo muito longo. Máximo de 255 caracteres.': {
    es: 'Error: campo demasiado largo. Máximo 255 caracteres.',
    en: 'Error: field too long. 255 characters maximum.',
    fr: 'Erreur: champ trop long. 255 caractères maximum.',
  },
  'Por favor, corrija os campos dinâmicos acima do limite de 255 caracteres antes de continuar.': {
    es: 'Corrige los campos dinámicos por encima del límite de 255 caracteres antes de continuar.',
    en: 'Please correct dynamic fields above the 255 character limit before proceeding.',
    fr: 'Veuillez corriger les champs dynamiques au-dessus de la limite de 255 caractères avant de continuer.',
  },
  'Só você assina': {
    es: 'Solo tu firmas',
    en: 'Only you sign',
    fr: 'Vous seul signe',
  },
  'E assine abaixo (para reutilizar sua assinatura, salve em': {
    es: 'Y firma abajo (para reutilizar tu firma, guarda en',
    en: 'And sign below (to reuse your signature, save to',
    fr: 'Et signe ci-dessous (pour réutiliser votre signature, sauvegarder sur',
  },
  'Deseja desabilitar a assinatura em lote deste documento?': {
    es: '¿Quieres deshabilitar la firma por lotes de este documento?',
    en: 'Do you want to disable batch signing of this document?',
    fr: 'Voulez-vous désactiver la signature par lots de ce document?',
  },
  'Sim, desabilitar somente agora': {
    es: 'Sí, deshabilitar solo ahora',
    en: 'Yes, disable only now',
    fr: 'Oui, désactiver seulement maintenant',
  },
  'Sim, desabilitar PERMANENTEMENTE': {
    es: 'Sí, deshabilitar PERMANENTEMENTE',
    en: 'Yes, disable PERMANENTLY',
    fr: 'Oui, désactiver en permanence',
  },
  'Não, obrigado': {
    es: 'No, gracias',
    en: 'No, thanks',
    fr: 'Non merci',
  },
  Relatórios: {
    es: 'Informes',
    en: 'Reports',
    fr: 'Rapports',
  },
  'Você tem acesso limitado aos relatórios desta conta.': {
    es: 'Tienes acceso limitado a los informes de esta cuenta.',
    en: 'You have limited access to this account\'s reports.',
    fr: 'Vous avez un accès limité aux rapports de ce compte.',
  },
  'Nenhum registro encontrado no periodo selecionado': {
    es: 'No se encontraron registros en el período seleccionado',
    en: 'No records found in the selected period',
    fr: 'Aucun enregistrement trouvé dans la période sélectionnée',
  },
  'Classificar por': {
    es: 'Ordenar por',
    en: 'Sort by',
    fr: 'Trier par',
  },
  'Ordenar por': {
    es: 'Ordenar por',
    en: 'Sort by',
    fr: 'Trier par',
  },
  'Documentos criados': {
    es: 'Documentos creados',
    en: 'Created Documents',
    fr: 'Documents créés',
  },
  'Documentos assinados': {
    es: 'Documentos firmados',
    en: 'Documents signed',
    fr: 'Documents signés',
  },
  'Documentos em curso': {
    es: 'Documentos en curso',
    en: 'Documents ongoing',
    fr: 'Documents en cours',
  },
  Aproveitamento: {
    es: 'Rendimiento',
    en: 'Fulfillment',
    fr: 'Accomplissement',
  },
  'Último dia': {
    es: 'Último día',
    en: 'Last day',
    fr: 'Dernier jour',
  },
  'Últimos $0 dias': {
    es: 'Últimos $0 días',
    en: 'Last $0 days',
    fr: 'Dernières $0',
  },
  Período: {
    es: 'Período',
    en: 'Time period',
    fr: 'Période de temps',
  },
  Categoria: {
    es: 'Categoría',
    en: 'Category',
    fr: 'Catégorie',
  },
  'Tempo para assinar': {
    es: 'Tiempo para firmar',
    en: 'Delay time for signature',
    fr: 'Retard Temps pour la signature',
  },
  'Ranking de usuários': {
    es: 'Ranking de usuarios',
    en: 'User ranking',
    fr: 'Classement des utilisateurs',
  },
  'Nova funcionalidade em fase de testes. Em caso de dúvidas ou sugestões contate nosso suporte.': {
    es: 'Nueva funcionalidad en fase de prueba. En caso de dudas o sugerencias, contacte con nuestro soporte.',
    en: 'New functionality in testing phase. In case of doubts or suggestions, contact our support.',
    fr: 'Nouvelles fonctionnalités en phase de test. En cas de doutes ou de suggestions, contactez notre soutien.',
  },
  'Filtrar por:': {
    es: 'Filtrado por:',
    en: 'Filter by:',
    fr: 'Filtrer par:',
  },
  Criados: {
    es: 'Creados',
    en: 'Created',
    fr: 'Créé',
  },
  Assinados: {
    es: 'Firmados',
    en: 'Signed',
    fr: 'Signé',
  },
  'Tipo de evento': {
    es: 'Tipo de evento',
    en: 'Event type',
    fr: 'Type d\'événement',
  },
  'Documento criado': {
    es: 'Documento creado',
    en: 'Document created',
    fr: 'Document créé',
  },
  'Documento assinado': {
    es: 'Documento firmado',
    en: 'Signed document',
    fr: 'Document signé',
  },
  'Documento removido': {
    es: 'Documento eliminado ',
    en: 'Document deleted',
    fr: 'Document supprimé',
  },
  'Não foi possível validar o nome completo.': {
    es: 'No se pudo validar el nombre completo.',
    en: 'Could not validate full name.',
    fr: 'Impossible de valider le nom complet.',
  },
  'Não foi possível validar o e-mail.': {
    es: 'No se pudo validar el correo electrónico.',
    en: 'Could not validate email.',
    fr: 'Impossible de valider les e-mails.',
  },
  'Não foi possível validar o telefone.': {
    es: 'No se pudo validar el teléfono.',
    en: 'Could not validate phone.',
    fr: 'Impossible de valider le téléphone.',
  },
  'É necessário gravar o liveness antes de continuar.': {
    es: 'Es necesario grabar la liveness antes de continuar.',
    en: 'It is necessary to record the liveness before proceeding.',
    fr: 'Il est nécessaire d\'enregistrer la liveness avant de continuer.',
  },
  'É necessário fotografar sua selfie (foto do rosto)': {
    es: 'Es necesario tomar una selfie (foto del rostro).',
    en: 'You need to take a selfie (face photo).',
    fr: 'Il est nécessaire de prendre un selfie (photo du visage).',
  },
  'É necessário fotografar a frente e verso do seu documento pessoal (ex: RG, CNH)': {
    es: 'Es necesario tomar fotos del anverso y reverso de su documento personal',
    en: 'You need to take photos of the front and back of your personal document',
    fr: 'Il est nécessaire de prendre des photos du recto et du verso de votre document personnel',
  },
  'Não foi possível validar o RG.': {
    es: 'No se pudo validar el DNI.',
    en: 'The ID could not be validated.',
    fr: 'La carte d\'identité n\'a pas pu être validée.',
  },
  'Não foi possível validar o CPF.': {
    es: 'No fue posible validar el CPF.',
    en: 'The CPF could not be validated.',
    fr: 'Le CPF n\'a pas pu être validé.',
  },
  'Você pode definir os signatários padrão do documento e o seu modo de autenticação.': {
    es: 'Puedes denifir los firmantes predeterminados del documento y el modo de autenticación',
    en: 'You can set the document\'s default signers and their authentication mode.',
    fr: 'Vous pouvez définir les signataires par défaut du document et leur mode d\'authentification.',
  },
  'Salvar alterações nos signatários': {
    es: 'Guardar cambios de los firmantes',
    en: 'Save changes',
    fr: 'Sauvegarder les changements de signataires',
  },
  'Você pode posicionar as assinaturas e vistos dos signatários no documento.': {
    es: 'Puedes posicionar las firmas e iniciales donde los firmantes van a firmar.',
    en: 'You can place the signatures of the signers on the document.',
    fr: 'Vous pouvez placer les signatures et les visas des signataires sur le document.',
  },
  'Esta é a pessoa que preenche o formulário e depois assina, variando para cada documento. Ex.: seu cliente.': {
    es: 'Esta es la persona que diligencia el formulario y luego lo firma, variando para cada documento. Por ejemplo: tu cliente.',
    en: 'This is the person who fills out the form and then signs it, varying for each document. E.g.: your customer.',
    fr: 'Vous pouvez définir les signataires par défaut du document et leur mode d\'authentification.',
  },
  'Escolha como o signatário irá ver o formulário, definindo a ordem, títulos e textos de ajuda mais amigáveis para seus campos dinâmicos.': {
    es: 'Elige cómo el firmante verá el formulario: define el orden, los títulos y textos de ayuda de una manera más amigable.',
    en: 'Customize how the signer views the form by setting the order, titles, and user-friendly help texts for your dynamic fields.',
    fr: 'Choisissez comment le signataire affichera le formulaire en définissant la commande, les titres et les textes d\'aide plus conviviaux pour vos champs dynamiques.',
  },
  'Este é o signatário n. $0, que será sempre o mesmo em todos documentos. Ex.: dono da empresa ou testemunha padrão.': {
    es: 'Este es el firmante n. $0, que siempre será el mismo en todos los documentos. Por ejemplo: propietario de la empresa o testigo estándar.',
    en: 'This is signer n. $0, which will always be the same in all documents. E.g.: company owner or standard witness.',
    fr: 'Ceci est le signataire n. $0, qui sera toujours le même dans tous les documents. Par exemple: propriétaire de l\'entreprise ou témoin standard.',
  },
  'Configurar signatários': {
    es: 'Configurar firmantes',
    en: 'Signers settings',
    fr: 'Configurer les signataires',
  },
  'Configurar formulário': {
    es: 'Configurar formulario',
    en: 'Form settings',
    fr: 'Configurer le formulaire',
  },
  'Opções avançadas': {
    es: 'Opciones avanzadas',
    en: 'Advanced settings',
    fr: 'Options avancées',
  },
  'Inserir texto': {
    es: 'Insertar texto',
    en: 'Insert Text',
    fr: 'Insérer le texte',
  },
  'Utilize para escrever sobre o documento. É aceito qualquer tipo de texto. O documento será editado.': {
    es: 'Usalo para escribir sobre el documento. Se acepta cualquier tipo de texto. El documento será editado.',
    en: 'Use to write over the document. Any type of text is accepted. The document will be edited.',
    fr: 'Utiliser pour écrire le document. Tout type de texte est accepté. Le document sera modifié.',
  },
  Remover: {
    es: 'Eliminar',
    en: 'Remove',
    fr: 'Retirer',
  },
  'Remover texto': {
    es: 'Eliminar texto',
    en: 'Remove text',
    fr: 'Supprimer le texte',
  },
  'E-mail enviado em': {
    es: 'E-mail enviado el',
    en: 'Email sent on',
    fr: 'E-mail envoyé',
  },
  'Mensagem enviada em': {
    es: 'Mensage enviado el',
    en: 'Message sent on',
    fr: 'Message envoyé',
  },
  'A ação de remover rubricas de textos é irreversível, por favor confira antes de prosseguir.': {
    es: 'La acción de eliminar los campos de textos es irreversible, verifica antes de continuar.',
    en: 'The action of removing rubrics from texts is irreversible, please check before proceeding.',
    fr: 'L\'action de retirer les rubriques des textes est irréversible, veuillez vérifier avant de continuer.',
  },
  'Este documento está sendo modificado neste momento, por favor confira se os participantes terminaram as alterações antes de assinar.': {
    es: 'Este documento se está modificando actualmente, verifica que los participantes hayan completado los cambios antes de firmar.',
    en: 'This document is currently being modified, please check that participants have completed the changes before signing.',
    fr: 'Ce document est actuellement en cours de modification, veuillez vérifier que les participants ont terminé les modifications avant la signature.',
  },
  'por usuário': {
    es: 'por usuario',
    en: 'per user',
    fr: 'par utilisateur',
  },
  'Os planos ilimitados estão sujeitos à política de uso justo, seguindo o uso normal e médio de um usuário empresarial. Caso o volume seja ultrapassado você pode ser contatado por nossa equipe comercial.': {
    es: 'Los planes ilimitados están sujetos a la política de uso justo, siguiendo el uso normal y promedio de un usuario comercial. Si se supera el volumen, puede ser contactado por nuestro equipo de ventas.',
    en: 'Unlimited plans are subject to fair usage policy, following a user\'s normal and average usage. If the volume is exceeded, you can be contacted by our sales team.',
    fr: 'Les plans illimités sont soumis à une politique d\'utilisation équitable, suite à l\'utilisation normale et moyenne d\'un utilisateur. Si le volume est dépassé, vous pouvez être contacté par notre équipe de vente.',
  },
  'Para volumes maiores, você pode contatar nossos vendedores em support@zapsign.com.br': {
    es: 'Para volúmenes más grandes puedes contactar a nuestros vendedores en support@zapsign.com.br',
    en: 'For bigger volumes, you can contact our sales team at support@zapsign.com.br',
    fr: 'Pour des volumes plus importants, vous pouvez contacter notre équipe de vente à support@zapsign.com.br',
  },
  'Experiência do signatário': {
    es: 'Experiencia del firmante',
    en: 'Signer experience',
    fr: 'Expérience de signataire',
  },
  'Na primeira etapa da assinatura, o signatário deve preferencialmente visualizar o documento assinado pelos outros signatários, em vez do documento original sem assinaturas.': {
    es: 'En el primer paso de la firma, el firmante debe ver el documento firmado por los otros firmantes, en lugar del documento original sin firmas.',
    en: 'In the first step of signing, the signer should view the document signed by the other signers, rather than the original document without signatures.',
    fr: 'Lors de la première étape de la signature, le signataire verra le document signé par d\'autres signataires, au lieu du document original sans signatures.',
  },
  'O documento foi editado pelo proprietário enquanto você preenchia seus dados. Por favor, recomece e revise o documento.': {
    es: 'El documento fue editado por el propietario mientras completabas tus datos. Comienza de nuevo y revise el documento.',
    en: 'The document was edited by the owner while you were filling in your data. Please start over and review the document.',
    fr: 'Le document a été modifié par le propriétaire pendant que vous remplissiez vos données. Veuillez recommencer et revoir le document.',
  },
  Customizáveis: {
    es: 'Personalizables',
    en: 'Customizables',
    fr: 'Personnalisables',
  },
  'Customize a sua integração via API ou Integromat': {
    es: 'Personaliza tu integración a través de API o Integromat',
    en: 'Customize your integration via API or Integromat',
    fr: 'Personnalisez votre intégration via API ou Integromat',
  },
  'Veja +1000 aplicativos disponíveis no Integromat': {
    es: 'Vea más de 1000 aplicaciones disponibles en Integromat',
    en: 'See 1000+ apps available on Integromat',
    fr: 'Voir plus de 1000 applications disponibles sur Integromat',
  },
  'Prontos para usar': {
    es: 'Listos para usar',
    en: 'Ready to use',
    fr: 'Prêt à l\'emploi',
  },
  'Quando um documento é assinado (por todos), ele é copiado para seu Google Drive': {
    es: 'Cuando un documento está firmado (por todos), se copia en Google Drive',
    en: 'When a document is signed (by everyone), it is copied to your Google Drive',
    fr: 'Lorsqu\'un document est signé (par tout le monde), il est copié sur votre Google Drive',
  },
  'Quando um documento é assinado (por todos), ele é copiado para seu Dropbox': {
    es: 'Cuando un documento está firmado (por todos), se copia en Dropbox',
    en: 'When a document is signed (by everyone), it is copied to your Dropbox',
    fr: 'Lorsqu\'un document est signé (par tout le monde), il est copié sur votre Dropbox',
  },
  'Quando um documento é assinado (por todos), ele é copiado para seu OneDrive': {
    es: 'Cuando un documento está firmado (por todos), se copia en OneDrive',
    en: 'When a document is signed (by everyone), it is copied to your OneDrive',
    fr: 'Lorsqu\'un document est signé (par tout le monde), il est copié sur votre OneDrive',
  },
  'Você não tem acesso às Configurações desta conta.': {
    es: 'No tienes acceso a los Ajustes de esta cuenta.',
    en: 'You do not have access to this account\'s Settings',
    fr: 'Vous n\'avez pas accès aux paramètres de ce compte',
  },
  'Assistir Tutorial: Como integrar a ZapSign com Google Drive': {
    es: 'Ver Tutorial: Cómo integrar ZapSign con Google Drive',
    en: 'Watch Tutorial: How to integrate ZapSign with Google Drive',
    fr: 'Regarder le tutoriel: comment intégrer Zapsign avec Google Drive',
  },
  'Volume de documentos ultrapassou o limite no mês. Por favor, converse com o dono da conta.': {
    es: 'El volumen de documentos excedió el límite en el mes. Por favor, habla con el propietario de la cuenta.',
    en: 'The monthly volume of documents has been exceeded. Please talk to the account owner.',
    fr: 'Le volume mensuel de documents a été dépassé. Veuillez parler au propriétaire du compte.',
  },
  'Preferência atualizada com sucesso': {
    es: 'Preferencia actualizada con éxito',
    en: 'Preference successfully updated',
    fr: 'Préférence avec succès mis à jour',
  },
  'Preferências de autenticação': {
    es: 'Preferencias de autenticación',
    en: 'Authentication preferences',
    fr: 'Préférences d\'authentification',
  },

  Desenhar: {
    es: 'Dibujar',
    en: 'Draw',
    fr: 'Conception',
  },
  Subir: {
    es: 'Subir',
    en: 'Upload',
    fr: 'Télécharger',
  },
  'Subir foto da assinatura': {
    es: 'Subir foto de la firma',
    en: 'Upload signature photo',
    fr: 'Télécharger la photo de signature',
  },
  'Finalizar seleção': {
    es: 'Finalizar recorte',
    en: 'End selection',
    fr: 'Sélection finale',
  },
  'Editar frase de início': {
    es: 'Editar frase inicial',
    en: 'Edit the introductory sentence',
    fr: 'Éditer la phrase d\'introduction',
  },
  '(Opcional) Mensagem de início': {
    es: '(Opcional) Mensaje de inicio',
    en: '(Optional) Start message',
    fr: '(Facultatif) Démarrer le message',
  },
  'A mensagem será exibida no início do formulário.': {
    es: 'El mensaje se mostrará en la parte superior del formulario.',
    en: 'The message will be displayed at the top of the form.',
    fr: 'Le message sera affiché en haut du formulaire.',
  },
  'Vídeo do Youtube (código)': {
    es: 'Vídeo de Youtube (código)',
    en: 'Youtube video (code)',
    fr: 'Vidéo YouTube (code)',
  },
  'Ex: Fi0qJgEjYAw': {
    es: 'Ej: Fi0qJgEjYAw',
    en: 'Ex: Fi0qJgEjYAw',
    fr: 'Ex:Fi0qJgEjYAw',
  },
  'Se o vídeo é https://www.youtube.com/watch?v=Fi0qJgEjYAw, o código é Fi0qJgEjYAw.': {
    es: 'Si el video es https://www.youtube.com/watch?v=Fi0qJgEjYAw, el código es Fi0qJgEjYAw.',
    en: 'If the video is https://www.youtube.com/watch?v=Fi0qJgEjYAw, the code is Fi0qJgEjYAw.',
    fr: 'Si la vidéo est https://www.youtube.com/watch?v=fi0qjgejyaw, le code est fi0qjgejyaw.',
  },
  'Saiba mais': {
    es: 'Conocer más',
    en: 'Learn more',
    fr: 'Apprendre encore plus',
  },
  'https://indique.buzz/zapsign': {
    es: 'https://zapsign.firstpromoter.com/signup/17179',
    en: 'https://zapsign.firstpromoter.com/signup/17179',
    fr: 'https://zapsign.firstpromoter.com/signup/17179',
  },
  'Quer ganhar dinheiro indicando a Zapsign? Veja como é fácil!': {
    es: '¡Genera ingresos extra recomendado ZapSign!',
    en: 'Earn extra income by recommending ZapSign!',
    fr: 'Gagnez un revenu supplémentaire en recommandant ZapSign!',
  },
  'todo mês': {
    es: 'cada mes',
    en: 'cada mes',
    fr: 'cada mes',
  },
  'via PIX': {
    es: 'via PIX',
    en: 'via PIX',
    fr: 'via pix',
  },
  'Para cada contratação, no seu link, você recebe comissões': {
    es: 'Por cada contratación, en tu enlace, recibes comisiones',
    en: 'For each hire, on your link, you receive commissions',
    fr: 'Pour chaque location, sur votre lien, vous recevez des commissions',
  },
  'Participe do nosso': {
    es: 'Únete a nuestro',
    en: 'Join our',
    fr: 'Rejoignez notre',
  },
  'programa de afiliados': {
    es: 'programa de afiliación',
    en: 'affiliate program',
    fr: 'Programme d\'affiliation',
  },
  'Basta se cadastrar e compartilhar o seu': {
    es: 'Solo regístrate y comparte tu',
    en: 'Just register and share your',
    fr: 'Enregistrez-vous et partagez simplement votre',
  },
  'link público': {
    es: 'enlace público',
    en: 'public link',
    fr: 'lien public',
  },
  'https://www.youtube.com/embed/PCLobZM9wLM?start=32': {
    es: 'https://www.youtube.com/embed/lwlzGG_BcN0',
    en: 'https://www.youtube.com/embed/Fi0qJgEjYAw',
    fr: 'https://www.youtube.com/embed/fi0qjgejyaw',
  },
  'clique aqui': {
    es: 'haz clic aquí',
    en: 'click here',
    fr: 'cliquez ici',
  },
  'para usar um arquivo de exemplo': {
    es: 'para usar un archivo de ejemplo',
    en: 'to use an example file',
    fr: 'Pour utiliser un exemple de fichier',
  },
  'Foi ativada a assinatura na tela para o(a) signatário(a)': {
    es: 'La firma en pantalla se ha activado para el firmante',
    en: 'Signature on screen has been activated for the signer',
    fr: 'La signature à l\'écran a été activée pour le signataire',
  },
  'A ZapSign pode enviar o seu modelo individualmente para cada um dos e-mails abaixo.': {
    es: 'Envia un documento individual a cada correo electrónico. En caso de tener campos dinámicos, el firmante debe diligenciarlos.',
    en: 'ZapSign can send your template individually to each of the emails below',
    fr: 'Zapsign peut envoyer votre modèle individuellement à chacun des e-mails ci-dessous',
  },
  'Essa funcionalidade ainda está em construção! Caso precise de ajuda, entre em contato com o nosso suporte.': {
    es: '¡Esta funcionalidad aún está en construcción! Si necesitas ayuda, pónte en contacto con soporte.',
    en: 'This feature is still under construction! If you need help, please contact our support.',
    fr: 'Cette fonctionnalité est toujours en construction! Si vous avez besoin d\'aide, veuillez contacter notre assistance.',
  },
  'Não é possível editar esse signatário, pois ele já assinou o documento. Por questão de integridade documental e validade jurídica, não é possível desfazer ou editar a assinatura.': {
    es: 'No se puede editar este firmante porque ya ha firmado el documento. En aras de la integridad documental y la validez legal, no es posible deshacer o editar la firma.',
    en: 'It is not possible to edit the signer once the signature is done. Due to document integrity and legal validity, it is not possible to undo or edit signatures.',
    fr: 'Il n\'est pas possible de modifier le signataire une fois la signature terminée. En raison de l\'intégrité des documents et de la validité juridique, il n\'est pas possible d\'annuler ou de modifier les signatures.',
  },
  'Caso queira que a pessoa corrija a assinatura, você pode criar um novo documento. Ou então, você pode adicionar a pessoa novamente ao documento usando o botão "Adicionar signatário", assim ela poderá assinar de novo.': {
    es: 'Si quieres que la persona corrija la firma, puedes crear un nuevo documento. O, puedes volver a agregar a la persona al documento con el botón "Agregar firmante", para que pueda firmar novamente.',
    en: 'If you want the person to fix the signature, you can create a new document. Or, you can add the person back to the document using the "Add signer" button, enabling the signer to sign again.',
    fr: 'Si vous voulez que la personne répare la signature, vous pouvez créer un nouveau document. Ou, vous pouvez ajouter la personne au document à l\'aide du bouton "Ajouter le signataire", permettant au signataire de signer à nouveau.',
  },
  'E-mail enviado com sucesso!': {
    es: '¡Email enviado con éxito!',
    en: 'Email successfully sent!',
    fr: 'Envoi avec courrier électronique!',
  },
  'Clique no botão "novo" e selecione o arquivo WORD ou PDF que deseja assinar': {
    es: 'Haz clic en el botón "nuevo" y selecciona el archivo WORD o PDF que quieres firmar',
    en: 'Click on the "new" button and select the WORD or PDF file you want to sign',
    fr: 'Cliquez sur le bouton "Nouveau" et sélectionnez le fichier Word ou PDF que vous souhaitez signer',
  },
  'Eu vou assinar documentos ✍️': {
    es: 'Voy a firmar documentos ✍️',
    en: 'I want to sign documents ✍️',
    fr: 'Signer documents ✍️',
  },
  'Coletar assinaturas de terceiros 🤝': {
    es: 'Voy a recolectar firmas de terceros 🤝',
    en: 'Collect third-party signatures 🤝',
    fr: 'Recueillir les signatures de tiers 🤝',
  },
  'Ver cópias de documentos já assinados 👀': {
    es: 'Ver copia de documentos firmados 👀',
    en: 'View a copy of signed documents 👀',
    fr: 'Afficher les documents signés 👀',
  },
  'Veja como é simples assinar documentos com a Zapsign': {
    es: 'Mira lo sencillo que es firmar documentos con Zapsign',
    en: 'See how simple it is to sign documents with Zapsign',
    fr: 'Voyez à quel point il est simple de signer des documents avec ZapSign',
  },
  'Adicione os signatários': {
    es: 'Agregar los firmantes',
    en: 'Add the signers',
    fr: 'Ajouter les signataires',
  },
  'Começar é simples:': {
    es: 'Comenzar es sencillo:',
    en: 'Getting started is simple:',
    fr: 'Le démarrage est simple:',
  },
  'Você pode solicitar autenticações avançadas como selfie e reconhecimento facial': {
    es: 'Puedes solicitar autenticaciones avanzadas como selfie y reconocimiento facial',
    en: 'You can request advanced authentications like selfie and facial recognition',
    fr: 'Vous pouvez demander des authentifications avancées comme les selfies et la reconnaissance faciale',
  },
  'Compartilhe o link e aguarde!': {
    es: '¡Comparta el enlace y espera!',
    en: 'Share the link and wait!',
    fr: 'Partagez le lien et attendez!',
  },
  'Basta compartilhar o link de assinatura através da comunicação de sua preferência (Whatsapp, e-mail, etc)': {
    es: 'Simplemente comparta el enlace de firma a través de la comunicación de tu elección (Whatsapp, correo electrónico, etc.)',
    en: 'Just share the signature link through the communication of your choice (Whatsapp, email, etc)',
    fr: 'Partagez simplement le lien de signature via la communication de votre choix (WhatsApp, Email, etc.)',
  },
  'Quero criar meu primeiro documento!': {
    es: '¡Quiero crear mi primer documento!',
    en: 'I want to create my first document!',
    fr: 'Je veux créer mon premier document!',
  },
  'Criar documento': {
    es: 'Crear documento',
    en: 'Create document',
    fr: 'Créer un document',
  },
  'Criar primeiro documento': {
    es: 'Crear primer documento',
    en: 'Create first document',
    fr: 'Créer le premier document',
  },
  'Bem-vindo(a) à ZapSign! Aprenda a usar a plataforma em 6min': {
    es: '¡Bienvenido a ZapSign! Aprenda a usar la plataforma en 6min',
    en: 'Welcome to ZapSign! Learn how to use the platform in 6min',
    fr: 'Bienvenue à ZapSign! Apprenez à utiliser la plate-forme en 6 minutes',
  },
  'https://app.zapsign.com.br/assets/img/selo-sustentabilidade.png': {
    es: '/assets/img/old-sustainability/selo-sustentabilidade-es.png',
    en: '/assets/img/old-sustainability/selo-sustentabilidade-en.png',
    fr: '/Assets/img/old-sustainability/selo-stentabilidade-en.png',
  },
  '/assets/img/selo-sustentabilidade.png': {
    es: '/assets/img/old-sustainability/selo-sustentabilidade-es.png',
    en: '/assets/img/old-sustainability/selo-sustentabilidade-en.png',
    fr: '/Assets/img/old-sustainability/selo-stentabilidade-en.png',
  },
  Cupom: {
    es: 'Cupón',
    en: 'Coupon',
    fr: 'Coupon',
  },
  'Insira o cupom': {
    es: 'Ingresar el cupón',
    en: 'Insert coupon',
    fr: 'Insérer le coupon',
  },
  'https://app.zapsign.com.br/': {
    es: 'https://app.zapsign.co/',
    en: 'https://app.zapsign.co/',
    fr: 'https://app.zapsign.co/',
  },
  'pt-br': {
    es: 'es',
    en: 'en',
    fr: 'en',
  },
  'Seu plano não possui acesso à assinatura em lote. Por favor, faça upgrade.': {
    es: 'Tu plan no tiene acceso a la firmas por lotes. Actualiza.',
    en: 'Your plan does not have access to the batch signatures. Please upgrade.',
    fr: 'Votre plan n\'a pas accès aux signatures par lots. Veuillez mettre à niveau.',
  },
  'Minhas assinaturas': {
    es: 'Mis firmas',
    en: 'My signatures',
    fr: 'Mes signatures',
  },
  'Assine todos os documentos pendentes com um clique': {
    es: 'Firma todos los documentos pendientes con un clic',
    en: 'Sign all pending documents with one click',
    fr: 'Signez tous les documents en attente en un seul clic',
  },
  'Salvar respostas do modelo': {
    es: 'Guardar respuestas de plantilla',
    en: 'Save template responses',
    fr: 'Enregistrer les réponses du modèle',
  },
  'Exporte as respostas dos modelos para um Excel': {
    es: 'Exportar las respuestas de la plantilla a Excel',
    en: 'Export template responses to Excel',
    fr: 'Réponses du modèle d\'exportation à Excel',
  },
  'Exportar respostas para Excel': {
    es: 'Exportar respuestas a Excel',
    en: 'Export answers to Excel',
    fr: 'Exporter des réponses à Excel',
  },
  'Exportar respostas': {
    es: 'Exportar respuestas',
    en: 'Export answers',
    fr: 'Réponses à l\'exportation',
  },
  respostas: {
    es: 'respuestas',
    en: 'answers',
    fr: 'réponses',
  },
  'dos modelos em um arquivo Excel': {
    es: 'de las plantillas en un archivo de Excel',
    en: 'of the templates in an Excel file',
    fr: 'des modèles dans un fichier Excel',
  },
  Modelo: {
    es: 'Plantilla',
    en: 'Template',
    fr: 'Modèle',
  },
  'Ocorreu um erro ao processar o pedido. Por favor verifique se foram gerados documentos com o modelo selecionado enquanto a opção de salvar respostas estava ativada.': {
    es: 'Hubo un error al procesar la solicitud. Verifica si los documentos se generaron con la plantilla seleccionada mientras la opción para guardar respuestas estaba habilitada.',
    en: 'There was an error processing the request. Please check if documents were generated with the selected template while the option to save responses was enabled.',
    fr: 'Il ya eu une erreur lors du traitement de la demande. Veuillez vérifier si les documents ont été générés avec le modèle sélectionné tandis que l\'option pour enregistrer les réponses a été activée.',
  },
  'Adicione pelo menos um email': {
    es: 'Adiciona por lo menos un correo',
    en: 'Please add at least one email address',
    fr: 'Veuillez ajouter au moins une adresse e-mail',
  },
  'Lista de e-mails': {
    es: 'Lista de correo',
    en: 'E-mail list',
    fr: 'Liste de diffusion',
  },
  'separe-os com vírgula': {
    es: 'separarlos con una coma',
    en: 'separate them with a comma',
    fr: 'séparer par une virgule',
  },
  'Enviar agora': {
    es: 'Enviar ahora',
    en: 'Send now',
    fr: 'Envoyer maintenant',
  },
  'Por favor, tire uma foto mostrando todo o seu rosto, em uma região bem iluminada.': {
    es: 'Toma una foto de tu rostro en un lugar bien iluminado.',
    en: 'Take a photo of your face in a well-lit area.',
    fr: 'Prenez une photo de votre visage dans un endroit bien éclairé.',
  },
  'Ocorreu algum erro. Por favor, atualize a página.': {
    es: 'Se ha producido un error. Por favor, actualice la página.',
    en: 'An error has occurred. Please refresh the page.',
    fr: 'Une erreur est survenue. Veuillez actualiser la page.',
  },
  'Ocorreu algum erro. Não foi possível alterar o plano.': {
    es: 'Se ha producido un error. No se puede cambiar de plan.',
    en: 'An error has occurred. Unable to change plan.',
    fr: 'Une erreur est survenue. Impossible de changer de plan.',
  },
  'Para habilitar esta página': {
    es: 'Para habilitar esta página',
    en: 'To enable this page',
    fr: 'Pour activer cette page',
  },
  'Há algum problema de conexão entre seu dispositivo e nosso servidor. Por favor, tente usar os dados móveis ou utilize outro wi-fi.': {
    es: 'Hay un problema de conexión entre tu dispositivo y nuestro servidor. Intenta usar datos móviles o use otra red wifi.',
    en: 'There is a connection problem between your device and our server. Please try using mobile data or use another wifi.',
    fr: 'Il y a un problème de connexion entre votre appareil et notre serveur. Veuillez essayer d\'utiliser des données mobiles ou utiliser un autre WiFi.',
  },
  'Você assinou o documento': {
    es: 'Tu firmaste el documento',
    en: 'You signed the document',
    fr: 'Vous avez signé le document',
  },
  Aplicar: {
    es: 'Aplicar',
    en: 'Apply',
    fr: 'Appliquer',
  },
  'Inserir cupom': {
    es: 'Insertar cupón',
    en: 'Insert coupon',
    fr: 'Insérer le coupon',
  },
  'Disponível para respostas a partir de 10/02/2022': {
    es: 'Disponible para respuestas a partir del 10/02/2022',
    en: 'Available for responses from 02/10/2022',
    fr: 'Disponible pour les réponses du 02/10/2022',
  },
  'Documentos expirados': {
    es: 'Documentos vencidos',
    en: 'Expired documents',
    fr: 'Documents expirés',
  },
  'Você consta como signatário dos documentos abaixo, porém a data limite para assinar foi ultrapassada.': {
    es: 'Apareces como firmante de los documentos a continuación, pero la fecha límite para firmar ha pasado.',
    en: 'You appear as a signatory of the documents below, but the deadline for signing has passed.',
    fr: 'Vous apparaissez comme un signataire des documents ci-dessous, mais la date limite de signature est passée.',
  },
  'Editar documento': {
    es: 'Editar documento',
    en: 'Edit document',
    fr: 'Modifier le document',
  },
  'Qual documento será editado?': {
    es: '¿Qué documento se editará?',
    en: 'Which document will be edited?',
    fr: 'Quel document sera modifié?',
  },
  'Não possui conta?': {
    es: '¿No tienes una cuenta?',
    en: 'Don\'t have an account?',
    fr: 'Vous n\'avez pas de compte?',
  },
  'Quero receber atualizações da plataforma por e-mail': {
    es: 'Quiero recibir actualizaciones de la plataforma por email',
    en: 'I want to receive platform updates by email',
    fr: 'Je souhaite recevoir des mises à jour de la plate-forme par e-mail',
  },
  'Aceito os': {
    es: 'Acepto el',
    en: 'I accept the',
    fr: 'J\'accepte le',
  },
  'É preciso aceitar os termos de uso para poder criar sua conta.': {
    es: 'Debes aceptar los términos de uso para poder crear tu cuenta.',
    en: 'You must accept the terms of use in order to create your account.',
    fr: 'Vous devez accepter les conditions d\'utilisation afin de créer votre compte.',
  },
  'Crie sua conta': {
    es: 'Crea tu cuenta',
    en: 'Create your account',
    fr: 'Créez votre compte',
  },
  'Já possui uma conta?': {
    es: '¿Ya tienes una cuenta?',
    en: 'Already have an account?',
    fr: 'Vous avez déjà un compte?',
  },
  'Entrar na conta': {
    es: 'Iniciar sesión',
    en: 'Log in',
    fr: 'Se connecter',
  },
  'Ícone Google': {
    es: 'Icono de google',
    en: 'Google icon',
    fr: 'Icône Google',
  },
  'Ícone Facebook': {
    es: 'Icono de facebook',
    en: 'Facebook icon',
    fr: 'Icône Facebook',
  },
  'Ícone Microsoft': {
    es: 'Icono de microsoft',
    en: 'Microsoft icon',
    fr: 'Icône Microsoft',
  },
  'Carregando seus dados...': {
    es: 'Cargando tus datos...',
    en: 'Loading your data...',
    fr: 'Chargement de vos données ...',
  },
  'Oops, parece que você ainda não ativou sua conta!': {
    es: 'Vaya, ¡parece que aún no has activado tu cuenta!',
    en: 'Oops, looks like you haven\'t activated your account yet!',
    fr: 'Oups, on dirait que vous n\'avez pas encore activé votre compte!',
  },
  'Insira o código de 6 dígitos que foi enviado para o seu e-mail': {
    es: 'Ingresa el código de 6 dígitos que se envió a tu correo electrónico',
    en: 'Enter the 6-digit code that was sent to your email',
    fr: 'Saisissez le code à 6 chiffres qui vous a été envoyé par courrier électronique',
  },
  'Imagem de um cadeado': {
    es: 'Imagen de un candado',
    en: 'Image of a padlock',
    fr: 'Image d\'un cadenas',
  },
  'Não recebeu nenhum e-mail?': {
    es: '¿No recibiste ningún correo electrónico?',
    en: 'Didn\'t receive any emails?',
    fr: 'Vous n\'avez pas reçu d\'e-mail ?',
  },
  'Reenviar E-mail': {
    es: 'Reenviar email',
    en: 'Resend Email',
    fr: 'Envoyer à nouveau',
  },
  'Precisa de ajuda? Envie um e-mail para': {
    es: '¿Necesitas ayuda? Enviar un correo electrónico a',
    en: 'Need help? Send an email to',
    fr: 'Besoin d\'aide ? Envoyez un courriel à',
  },
  'Sair da conta': {
    es: 'Cerrar sesión',
    en: 'Log out',
    fr: 'Se déconnecter',
  },
  'Ícone de email cancelado': {
    es: 'Icono de correo electrónico cancelado',
    en: 'Canceled email icon',
    fr: 'Icône de messagerie annulée',
  },
  'Ícone de headphone': {
    es: 'Icono de auriculares',
    en: 'Headphone icon',
    fr: 'Icône du casque',
  },
  'Ícone de logout': {
    es: 'Icono de cierre de sesión',
    en: 'Logout icon',
    fr: 'Icône de déconnexion',
  },
  'Verificar meu email': {
    es: 'Verificar correo electrónico',
    en: 'Verify e-mail',
    fr: 'Vérifier les courriels',
  },
  'Não é permitido realizar alterações de texto em um documento já assinado.': {
    es: 'No está permitido realizar cambios de texto en un documento ya firmado.',
    en: 'It\'s not allowed to make text changes to an already signed document.',
    fr: 'Il n\'est pas autorisé à apporter des modifications au texte à un document déjà signé.',
  },
  'Remover textos': {
    es: 'Eliminar textos',
    en: 'Remove texts',
    fr: 'Supprimer des textes',
  },
  'Não solicitei o nome do signatário': {
    es: 'No pedí el nombre del firmante.',
    en: 'I didn\'t ask for the signatory\'s name',
    fr: 'Je n\'ai pas demandé le nom du signataire',
  },
  'Não solicitei o email do signatário': {
    es: 'No pedí el correo del firmante.',
    en: 'I didn\'t ask for the signatory\'s email',
    fr: 'Je n\'ai pas demandé l\'adresse électronique du signataire',
  },
  'Não solicitei o telefone do signatário': {
    es: 'No pedí el celular del firmante.',
    en: 'I didn\'t ask for the signatory\'s phone',
    fr: 'Je n\'ai pas demandé le téléphone du signataire',
  },
  'Qual desses campos é o nome do signatário?': {
    es: '¿Cuál de estos campos es el nombre del firmante?',
    en: 'Which of these fields is the signer\'s name?',
    fr: 'Lequel de ces champs correspond au nom du signataire ?',
  },
  'Qual desses campos é o email do signatário?': {
    es: '¿Cuál de estos campos es el correo del firmante?',
    en: 'Which of these fields is the signer\'s email?',
    fr: 'Lequel de ces champs correspond à l\'adresse électronique du signataire ?',
  },
  'Qual desses campos é o telefone do signatário?': {
    es: '¿Cuál de estos campos es el celular del firmante?',
    en: 'Which of these fields is the signer\'s phone?',
    fr: 'Lequel de ces champs correspond au téléphone du signataire ?',
  },
  Limpar: {
    es: 'Borrar',
    en: 'Clear',
    fr: 'Supprimer',
  },
  'Criado a partir de': {
    es: 'Creado a partir de',
    en: 'Created from',
    fr: 'Créé à partir de',
  },
  'Criado até': {
    es: 'Creado hasta',
    en: 'Created until',
    fr: 'Créé jusqu\'à',
  },
  'Crie e envie até milhares de documentos automaticamente preenchidos a partir de um arquivo formato .csv': {
    es: 'Crea y envía hasta miles de documentos diligenciados desde un archivo .csv',
    en: 'Create and send up to thousands of filled-out documents from a .csv file',
    fr: 'Créez et envoyez jusqu\'à des milliers de documents autocomplets à partir d\'un fichier .csv.',
  },
  'Baixe o CSV em branco abaixo e preencha-o com as informações dos documentos:': {
    es: 'Descarga el archivo a continuación y diligencialo con la información de los campos dinámicos:',
    en: 'Download the blank CSV below and fill it with the documents information:',
    fr: 'Téléchargez le fichier .csv vierge qui suit et complétez-le avec les informations figurant dans les documents :',
  },
  Enviar: {
    es: 'Enviar',
    en: 'Submit',
    fr: 'Envoyer',
  },
  Gerenciar: {
    es: 'Administrar',
    en: 'Manage',
    fr: 'Gérer',
  },
  'Modelo-Criacao-Em-Massa.csv': {
    es: 'Plantilla-Creacion-en-lote.csv',
    en: 'Template-Batch-creation.csv',
    fr: 'Template-batch-création.csv',
  },
  'Agora é só subir o arquivo preenchido:': {
    es: 'Ahora simplemente carga el archivo completo:',
    en: 'Now just upload the filled file:',
    fr: 'Il suffit maintenant de télécharger le fichier complet :',
  },
  'Isso pode demorar um pouco...': {
    es: 'Esto puede tardar un rato...',
    en: 'This may take a while...',
    fr: 'Cela peut prendre un peu de temps...',
  },
  'Os documentos serão criados e aparecerão na seção "Documentos".': {
    es: 'Los documentos se crearán y se mostrarán en la sección "Documentos".',
    en: 'The documents will be created and appear in the "Documents" section.',
    fr: 'Les documents seront créés et apparaîtront dans la section "Documents".',
  },
  'Ocorreu um erro ao criar os documentos. Confira os dados do arquivo, regarregue a página ou tente novamente mais tarde.': {
    es: 'Ocurrió un error al crear los documentos. Comprueba los datos del archivo, vuelve a cargar la página o inténtalo más tarde.',
    en: 'An error occurred while creating the documents. Check the file data, reload the page or try again later.',
    fr: 'Une erreur s\'est produite lors de la création des documents. Vérifiez les données du fichier, rechargez la page ou réessayez plus tard.',
  },
  'Não foi possível identificar os valores das variáveis do modelo.': {
    es: 'No fue posible identificar los valores de las variables de la plantilla.',
    en: 'It was not possible to identify the values of the template variables.',
    fr: 'Il n\'a pas été possible d\'identifier les valeurs des variables de modèle.',
  },
  'Outra pessoa assinou enquanto você assinava, ocorrendo um conflito. Por favor, assine novamente.': {
    es: 'Alguien más firmó mientras tú firmaste, lo que provocó un conflicto. Por favor, firma de nuevo.',
    en: 'Someone else signed while you signed, causing a conflict. Please sign again.',
    fr: 'Quelqu\'un d\'autre a signé pendant que vous avez signé, provoquant un conflit. Veuillez signer à nouveau.',
  },
  'Cadastre-se': {
    es: 'Inscríbete',
    en: 'Sign up',
    fr: 'S\'inscrire',
  },
  'Confirma a criação e envio por e-mail de $0 documentos? Essa ação não poderá ser desfeita.': {
    es: '¿Confirma la creación y el envío por correo electrónico de documentos de $0? Esta acción no se puede deshacer.',
    en: 'Do you confirm the creation and emailing of $0 documents? This action cannot be undone.',
    fr: 'Confirmez-vous la création et le courrier électronique de documents de $0? Cette action ne peut pas être annulée.',
  },
  'Para criar mais do que $0 documentos de uma vez, entre em contato com nossa equipe comercial.': {
    es: 'Para crear más de $0 documentos a la vez, póngase en contacto con nuestro equipo comercial.',
    en: 'To create more than $0 documents at once, please contact our commercial team.',
    fr: 'Pour créer plus de $0 de documents à la fois, veuillez contacter notre équipe commerciale.',
  },
  'Selo de sustentabilidade': {
    es: 'Sello de sostenibilidad',
    en: 'Sustainability stamp',
    fr: 'Sceau de durabilité',
  },
  'Você pode editar o código de acesso ou desativá-la a qualquer momento.': {
    es: 'Puedes editar el código de acceso o desactivarlo en cualquier momento',
    en: 'You can edit the passcode or disable it at any time',
    fr: 'Vous pouvez modifier le mot de passe ou le désactiver à tout moment',
  },
  'Sua página está ativa!': {
    es: '¡Tu página está activa!',
    en: 'Your page is active!',
    fr: 'Votre page est active!',
  },
  'Sua página encontra-se desativada. Você pode reativá-la a qualquer momento': {
    es: 'Tu página está deshabilitada. Puedes reactivarlo en cualquier momento.',
    en: 'Your page is disabled. You can reactivate it at any time.',
    fr: 'Votre page est désactivée. Vous pouvez le réactiver à tout moment.',
  },
  Acessar: {
    es: 'Acceso',
    en: 'Access',
    fr: 'Accéder',
  },
  'Acessar sua página de sustentabilidade': {
    es: 'Accede a tu página de sostenibilidad',
    en: 'Access your old-sustainability page',
    fr: 'Accédez à votre page de durabilité',
  },
  'Ative no botão ao lado para editar o slug da sua empresa.': {
    es: 'Activa el botón del lateral para editar el slug de tu empresa.',
    en: 'Activate the button on the side to edit your company\'s slug.',
    fr: 'Activez le bouton sur le côté pour modifier la limace de votre entreprise.',
  },
  'Slug salva com sucesso.': {
    es: 'Slug guarda con éxito.',
    en: 'Slug saves successfully.',
    fr: 'Slug sauve avec succès.',
  },
  'Não foi possível cadastrar o slug. Ele pode conter apenas letras minúsculas e hífens.': {
    es: 'No se pudo registrar el slug. Solo puede contener letras minúsculas y guiones.',
    en: 'Could not register the slug. It can only contain lowercase letters and hyphens.',
    fr: 'Impossible d\'enregistrer la limace. Il ne peut contenir que des lettres en minuscules et des traits de traits.',
  },
  'Ocorreu um erro ao cadastrar o slug. Confira se contém apenas letras minúsculas e hífens. Pode ser que alguém já tenha cadastrado esta slug.': {
    es: 'Hubo un error al registrar el slug. Asegúrese de que solo contenga letras minúsculas y guiones. Quizás alguien ya ha registrado este slug.',
    en: 'There was an error registering the slug. Make sure it contains only lowercase letters and hyphens. Maybe someone has already registered this slug.',
    fr: 'Il y a eu une erreur d\'enregistrement de la limace. Assurez-vous qu\'il ne contient que des lettres en minuscules et des traits de traits. Peut-être que quelqu\'un a déjà enregistré cette limace.',
  },
  'compartilhar com seus clientes!': {
    es: 'comparte con tus clientes',
    en: 'share with your customers',
    fr: 'Partagez avec vos clients',
  },
  'Concluir edição': {
    es: 'Terminar de editar',
    en: 'Finish editing',
    fr: 'Édition de finition',
  },
  'Não editar': {
    es: 'No editar',
    en: 'Do not edit',
    fr: 'Ne pas modifier',
  },
  'Limpar filtros': {
    es: 'Borrar filtros',
    en: 'Clear filters',
    fr: 'Filtres à claire',
  },
  'Limpar visto': {
    es: 'Borrar iniciales',
    en: 'Clear visa',
    fr: 'Visa clair',
  },
  'Assinatura ou visto do signatário': {
    es: 'Firma del firmante o iniciales',
    en: 'Signer\'s signature or visa',
    fr: 'Signature ou visa du signataire',
  },
  Outros: {
    es: 'Otros',
    en: 'Other',
    fr: 'Autre',
  },
  '(Você)': {
    es: '(Tú)',
    en: '(You)',
    fr: '(Toi)',
  },
  '{{NOME COMPLETO}}': {
    es: '{{NOMBRE COMPLETO}}',
    en: '{{FULL NAME}}',
    fr: '{{NOM COMPLET}}',
  },
  '{{ENDEREÇO COMPLETO}}': {
    es: '{{DIRECCIÓN COMPLETA}}',
    en: '{{ADDRESS}}',
    fr: '{{ADRESSE COMPLÈTE}}',
  },
  'Contribuição em andamento': {
    es: 'Contribución en progreso',
    en: 'Contribution in progress',
    fr: 'Contribution en cours',
  },
  'Página desabilitada': {
    es: 'Página inhabilitada',
    en: 'Page disabled',
    fr: 'Page désactivée',
  },
  'Ops! Não foi possível acessar os dados de sustentabilidade, pois ela se encontrada desativada no momento.': {
    es: '¡UPS! No se puede acceder a los datos de sostenibilidad porque actualmente está desactivado.',
    en: 'Oops! Unable to access old-sustainability data as it is currently deactivated.',
    fr: 'Oops! Impossible d\'accéder aux données de durabilité car il est actuellement désactivé.',
  },
  'Slug já cadastrado. Por favor escolha outra.': {
    es: 'Slug ya registrado. Por favor, elige otro.',
    en: 'Slug already registered. Please choose another.',
    fr: 'Slug déjà enregistré. Merci d\'en choisir un autre.',
  },
  'O campo precisa ter no mínino $0 letras': {
    es: 'El campo debe tener al menos $0 letras',
    en: 'The field must have at least $0 letters',
    fr: 'Le champ doit avoir au moins $0 de lettres',
  },
  'Oops! Confira se o email é valido': {
    es: '¡UPS! Comprobar si el correo electrónico es válido',
    en: 'Oops! Check if the email is valid',
    fr: 'Oops! Vérifiez si l\'e-mail est valide',
  },
  'O campo email precisa ser preenchido.': {
    es: 'El campo de correo electrónico debe ser completado.',
    en: 'The email field needs to be filled in.',
    fr: 'Le champ de messagerie doit être rempli.',
  },
  'E-mail não encontrado. Será que você utilizou esse e-mail?': {
    es: 'Dirección de correo electrónico no encontrada. ¿Ha utilizado este correo electrónico?',
    en: 'Email address not found, have you used this email address?',
    fr: 'Adresse e-mail introuvable, avez-vous utilisé cette adresse e-mail?',
  },
  'Faça login': {
    es: 'Acceder',
    en: 'Sign in',
    fr: 'Se connecter',
  },
  'Logotipo Zapsign': {
    es: 'Logotipo de Zapsign',
    en: 'Zapsign Logo',
    fr: 'Logo ZapSign',
  },
  'Essa ação não pôde ser processada. Consulte o remetente do documento.': {
    es: 'Esta acción no se pudo procesar. Consultar al remitente del documento.',
    en: 'This operation could not be processed. Check with the document`s sender.',
    fr: 'Cette opération n\'a pas pu être traitée. Vérifiez avec l\'expéditeur du document.',
  },
  'Lembre-me depois': {
    es: 'Recuérdame más tarde',
    en: 'Remind me later',
    fr: 'Rappelle-moi plus tard',
  },
  'Ativar página de sustentabilidade': {
    es: 'Habilitar página de sostenibilidad',
    en: 'Activate old-sustainability page',
    fr: 'Activer la page de durabilité',
  },
  'Página de sustentabilidade não encontrada': {
    es: 'Página de sostenibilidad no encontrada',
    en: 'Sustainability page not found',
    fr: 'Page de durabilité introuvable',
  },
  'Ops! Não foi possível encontrar a página de sustentabilidade. Por favor, confira se a slug está correta.': {
    es: '¡UPS! No se pudo encontrar la página de sostenibilidad. Por favor, comprueba que el slug es correcto.',
    en: 'Oops! The old-sustainability page could not be found. Please check that the slug is correct.',
    fr: 'Oops! La page de durabilité n\'a pas pu être trouvée. Veuillez vérifier que la limace est correcte.',
  },
  'Plano insuficiente': {
    es: 'Plan insuficiente',
    en: 'Insufficient plan',
    fr: 'Plan insuffisant',
  },
  'Seu plano atual não permite personalizar suas cores e marca. Confira nossos planos.': {
    es: 'Tu plan actual no permite personalizar los colores y marca. Consulta nuestros planes.',
    en: 'Your current plan does not allow you to customize your colors and branding. Check out our plans.',
    fr: 'Votre plan actuel ne vous permet pas de personnaliser vos couleurs et votre marque. Découvrez nos plans.',
  },
  'Bloquear que signatários assinem fora da ordem, caso ordem de assinaturas esteja ativada.': {
    es: 'Bloquear a los firmantes para que no firmen fuera del orden, si el orden de firma está activado.',
    en: 'Block signers from signing out of order, if signing order is enabled.',
    fr: 'Bloquez les signataires de la signature de l\'ordre, si l\'ordre de signature est activé.',
  },
  'Vou recontratar (ex. troca de plano, boleto, cartão ou nota fiscal)': {
    es: 'Voy a volver a contratar (por ejemplo, cambio de plan, boleto, tarjeta o factura)',
    en: 'I will rehire (eg change of plan, ticket, card or invoice)',
    fr: 'Je vais réembaucher (par exemple, le changement de plan, le billet, la carte ou la facture)',
  },
  'Está muito caro': {
    es: 'Esta muy caro',
    en: 'It\'s too expensive',
    fr: 'C\'est trop cher',
  },
  'Estou usando pouco': {
    es: 'estoy usando poco',
    en: 'I\'m using little',
    fr: 'J\'utilise peu',
  },
  'Preciso de mais funcionalidades': {
    es: 'necesito mas caracteristicas',
    en: 'I need more features',
    fr: 'J\'ai besoin de plus de fonctionnalités',
  },
  'Minha empresa ou clientes não conseguiram usar a plataforma direito': {
    es: 'Mi empresa o clientes no pudieron usar la plataforma correctamente',
    en: 'My company or clients were unable to use the platform properly',
    fr: 'Mon entreprise ou mes clients n\'ont pas pu utiliser la plate-forme correctement',
  },
  'A plataforma apresentou erros/mal funcionamento': {
    es: 'La plataforma tenía errores/mal funcionamiento',
    en: 'The platform had errors/malfunctions',
    fr: 'La plate-forme avait des erreurs / dysfonctionnements',
  },
  'Contratei outra plataforma': {
    es: 'Contraté otra plataforma',
    en: 'I hired another platform',
    fr: 'J\'ai embauché une autre plate-forme',
  },
  'Não gostei da experiência de suporte/vendas que recebi': {
    es: 'No me gustó la experiencia de soporte/ventas que obtuve',
    en: 'Didn\'t like the support/sales experience I got',
    fr: 'Je n\'aimais pas l\'expérience de soutien / vente que j\'ai obtenu',
  },
  'Equipe ZapSign': {
    es: 'El equipo de Zapsign',
    en: 'The Zapsign Team',
    fr: 'L\'équipe ZapSign',
  },
  'Plano $0 cancelado com sucesso.': {
    es: 'Plan $0 cancelado con éxito.',
    en: 'Plan $0 canceled successfully.',
    fr: 'Planifiez $0 annulé avec succès.',
  },
  'Uma das opções acima precisam ser selecionadas': {
    es: 'Se debe seleccionar una de las opciones anteriores',
    en: 'One of the above options need to be selected',
    fr: 'L\'une des options ci-dessus doit être sélectionnée',
  },
  'Parece que você ainda não está liberado para assinar este documento porque ainda há outras pessoas que devem assiná-lo antes de você. Não se preocupe, você será notificado quando a sua vez chegar.': {
    es: 'Parece que aún no puedes firmar este documento porque todavía hay otras personas que deben firmarlo antes que tú. No te preocupes, se te notificará cuando llegue tu turno.',
    en: 'It seems that you are not yet able to sign this document because there are still other people who must sign it before you. Don\'t worry, you will be notified when your turn arrives.',
    fr: 'Il semble que vous n\'êtes pas encore en mesure de signer ce document car il y a encore d\'autres personnes qui doivent le signer devant vous. Ne vous inquiétez pas, vous serez informé lorsque votre tour arrivera.',
  },
  'Ainda não é sua vez de assinar': {
    es: 'Aún no es tu turno de firmar',
    en: 'It\'s not your turn to sign yet',
    fr: 'Ce n\'est pas à vous de signer',
  },
  'Participe do programa': {
    es: 'Únete al programa',
    en: 'Join the program',
    fr: 'Rejoignez le programme',
  },
  'e ganhe até': {
    es: 'y ganar hasta',
    en: 'and win until',
    fr: 'et gagner jusqu\'à',
  },
  'por indicação': {
    es: 'con cita',
    en: 'by appointment',
    fr: 'sur rendez-vous',
  },
  Exemplo: {
    es: 'Ejemplo',
    en: 'Example',
    fr: 'Exemple',
  },
  'Envio automático pelo WhatsApp (5 créditos por envio)': {
    es: 'Envío automático por WhatsApp (5 créditos por envío)',
    en: 'Automatic sending by WhatsApp (5 credits per send)',
    fr: 'Envoi automatique par WhatsApp (5 crédits par envoi)',
  },
  'O campo acima precisa ser preenchido.': {
    es: 'El campo de arriba debe ser diligenciado.',
    en: 'The field above needs to be filled in.',
    fr: 'Le champ ci-dessus doit être rempli.',
  },
  'O campo senha precisa ser preenchido.': {
    es: 'El campo de contraseña debe ser diligenciado.',
    en: 'The field password needs to be filled in.',
    fr: 'Le mot de passe du champ doit être rempli.',
  },
  'Alguém mais deve receber notificações por e-mail ao final das assinaturas deste documento?': {
    es: '¿Alguien más debería recibir notificaciones por correo electrónico sobre la firma del documento?',
    en: 'Should anyone else receive email notifications about the document signing?',
    fr: 'Est-ce que quelqu\'un d\'autre devrait recevoir des notifications par e-mail concernant la signature du document ?',
  },
  'E-mails dos destinatários (limite de 20).': {
    es: 'Correos electrónicos del destinatario (límite 20).',
    en: 'Recipient emails (limit 20).',
    fr: 'Emails du destinataire (limite 20).',
  },
  Observadores: {
    es: 'Observadores',
    en: 'Viewers',
    fr: 'Les spectateurs',
  },
  'Limite máximo de 20 observadores.': {
    es: 'Límite máximo de 20 observadores.',
    en: 'Limit of 20 observers.',
    fr: 'Limite de 20 observateurs.',
  },
  'Os campos acima precisam ser preenchidos.': {
    es: 'Los campos anteriores deben ser completados.',
    en: 'The fields above must be completed.',
    fr: 'Les champs ci-dessus doivent être achevés.',
  },
  'Crie sua nova senha': {
    es: 'Crea tu nueva contraseña',
    en: 'Create your new password',
    fr: 'Créez votre nouveau mot de passe',
  },
  'Digite sua nova senha. Ela precisa ter no mínimo 12 caracteres, uma letra maiuscula, uma letra minuscula, um digito numérico e um simbolo.': {
    es: 'Introduce la nueva contraseña. Debe tener al menos 12 caracteres, una letra mayúscula, una letra minúscula, un dígito numérico y un símbolo.',
    en: 'Enter your new password. It must have at least 12 characters, an uppercase letter, a lowercase letter, a numeric digit and a symbol.',
    fr: 'Entrez votre nouveau mot de passe. Il doit comporter au moins 12 caractères, une lettre majuscule, une lettre minuscule, un chiffre numérique et un symbole.',
  },
  'Criar Conta': {
    es: 'Crea una cuenta',
    en: 'Create an account',
    fr: 'Créer un compte',
  },
  'Criar conta grátis': {
    es: 'Crear cuenta gratuita',
    en: 'Sign up for free',
    fr: 'Créez votre compte grauitement',
  },
  'Autenticação de dois fatores': {
    es: 'Autenticación de dos factores',
    en: 'Two-factor authentication',
    fr: 'Authentification à deux facteurs',
  },
  Premium: {
    es: 'Premium',
    en: 'Premium',
    fr: 'Prime',
  },
  'A autenticação de dois fatores protege sua conta ao exigir um código de validação enviado por SMS quando você realiza login na ZapSign.': {
    es: 'La autenticación de dos factores protege tu cuenta al solicitar un código de validación enviado por SMS cuando inicias sesión en ZapSign.',
    en: 'Two-factor authentication secures your account by requiring a validation code sent via SMS when you log in to ZapSign.',
    fr: 'Si cette fonction est activée, vous recevrez un code de vérification par SMS chaque fois que vous vous connecterez à votre compte, afin de vous assurer que vous êtes le seul à avoir accès à votre compte.',
  },
  'Ativar 2FA': {
    es: 'Habilitar 2FA',
    en: 'Enable 2FA',
    fr: 'Activer 2FA',
  },
  'Desativar 2FA': {
    es: 'Deshabilitar 2FA',
    en: 'Disable 2FA',
    fr: 'Désactiver 2fa',
  },
  'Autenticação de dois fatores apenas está disponível no Plano Completo, API ou Corporativo': {
    es: 'Actualice a un plan profesional, comercial o API para usar la autenticación de dos factores.',
    en: 'Two-factor authentication is only available on paid plans.',
    fr: 'La fonctionnalité Premium n\'est disponible que pour les plans Business, API ou Enterprise.',
  },
  'Não é possível utilizar a autenticação de dois fatores sem um telefone cadastrado. Por favor, preencha o "celular" e "país" acima.': {
    es: 'No es posible utilizar la autenticación de dos factores sin un teléfono registrado. Ingresa el celular.',
    en: 'It is not possible to use two-factor authentication without a registered phone. Please fill in "mobile" and "country" above.',
    fr: 'Il n\'est pas possible d\'utiliser l\'authentification à deux facteurs sans téléphone enregistré. Veuillez remplir "mobile" et "pays" ci-dessus.',
  },
  'Insira o código de 6 dígitos que foi enviado para o seu celular': {
    es: 'Ingresa el código de 6 dígitos que se envió a tu móvil.',
    en: 'Enter the 6-digit code that was sent to your mobile.',
    fr: 'Entrez le code à 6 chiffres qui a été envoyé à votre mobile.',
  },
  'Não recebeu nenhum código?': {
    es: '¿No recibiste ningún código?',
    en: 'Didn\'t receive any code?',
    fr: 'Vous n\'avez pas reçu le code ?',
  },
  'Reenviar SMS': {
    es: 'Reenviar SMS',
    en: 'Resend SMS',
    fr: 'Repenser les SMS',
  },
  'Você está quase lá': {
    es: 'Ya casi has llegado',
    en: 'You are almost there',
    fr: 'Tu es presque là',
  },
  'Faça upgrade para um Plano Completo, API ou Corporativo para usar a autenticação de dois fatores.': {
    es: 'Actualice a un plan Business, API o Enterprise para usar la autenticación de dos factores.',
    en: 'Upgrade to a Business, API, or Enterprise plan to use two-factor authentication.',
    fr: 'PLAN PLAN D\'UNE BUSINESS, API ou ENTREPRISE PLAN UTILISER UTILISER AUTHENTIFICATION DU TOUT FACTOR.',
  },
  'Ocorreu um erro ao validar. Por favor, verifique se os dados estão corretos.': {
    es: 'Ocurrió un error al validar. Por favor, comprueba que los datos están correctos.',
    en: 'An error occurred while validating. Please, check that the data it is correct.',
    fr: 'Une erreur s\'est produite lors de la validation. S\'il vous plaît, vérifiez que les données sont correctes.',
  },
  'Token validado com sucesso.': {
    es: 'Token validado con éxito.',
    en: 'Token validated successfully.',
    fr: 'Le jeton validé avec succès.',
  },
  'A autenticação de dois fatores já está habilitada.': {
    es: 'La autenticación de dos factores ya está habilitada.',
    en: 'Two-factor authentication is already enabled.',
    fr: 'L\'authentification à deux facteurs est déjà activée.',
  },
  'Código de SMS enviado com sucesso.': {
    es: 'Código SMS enviado con éxito.',
    en: 'SMS code sent successfully.',
    fr: 'Le code SMS a été envoyé avec succès.',
  },
  'Não foi possível enviar o código de SMS.': {
    es: 'No se puede enviar el código SMS.',
    en: 'Unable to send SMS code.',
    fr: 'Impossible d\'envoyer du code SMS.',
  },
  'e a': {
    es: 'y la',
    en: 'and the',
    fr: 'et le',
  },
  'Campos precisam ser preenchidos.': {
    es: 'Los campos deben ser diligenciados.',
    en: 'Fields need to be filled in.',
    fr: 'Les champs doivent être remplis.',
  },
  'Por favor, digite um numero de telefone válido.': {
    es: 'Por favor, ingresa un número de teléfono válido.',
    en: 'Please, enter a valid phone number.',
    fr: 'S\'il vous plaît entrer un numéro de téléphone valide.',
  },
  'Validar código': {
    es: 'Validar código',
    en: 'Validate code',
    fr: 'Valider le code',
  },
  'Ocorreu um erro ao acessar sua conta. Por favor, tente novamente.': {
    es: 'Ocurrió un error al iniciar sesión en tu cuenta. Por favor, inténtalo de nuevo.',
    en: 'An error has ocurred when trying to access your account. Please, try again.',
    fr: 'Une erreur s\'est produite lorsque vous essayez d\'accéder à votre compte. Veuillez réessayer.',
  },
  'Autenticação de dois fatores desativada com sucesso.': {
    es: 'La autenticación de dos factores se deshabilitó correctamente.',
    en: 'Two-factor authentication successfully disabled.',
    fr: 'L\'authentification à deux facteurs a réussi à désactiver.',
  },
  'Ícone da Zapsign no Youtube': {
    es: 'Icono de Zapsign en Youtube',
    en: 'Zapsign Icon on Youtube',
    fr: 'Icône ZapSign sur YouTube',
  },
  'Ícone da Zapsign no Whatsapp': {
    es: 'Icono de Zapsign en Whatsapp',
    en: 'Zapsign Icon on Whatsapp',
    fr: 'Icône ZapSign sur WhatsApp',
  },
  'Ícone de assinatura': {
    es: 'Icono de suscripción',
    en: 'Subscription icon',
    fr: 'Icône d\'abonnement',
  },
  'Ícone de visto': {
    es: 'Icono de iniciales',
    en: 'Visa icon',
    fr: 'Icône de visa',
  },
  'Ícone de texto': {
    es: 'Icono de texto',
    en: 'Text icon',
    fr: 'Icône de texte',
  },
  'Exibir quem criou o documento nos e-mails que são enviados para os signatarios.': {
    es: 'Mostrar la persona que creó el documento en los correos electrónicos que se envían a los firmantes.',
    en: 'Display the email of the person who created the document in emails that are sent to the signers.',
    fr: 'Affichez l\'e-mail de la personne qui a créé le document dans les e-mails envoyés aux signataires.',
  },
  'Separar relatórios de assinaturas dos documentos assinados.': {
    es: 'Separar los informes de firmas de los documentos firmados.',
    en: 'Separate signature reports from signed documents.',
    fr: 'Rapports de signature séparés des documents signés.',
  },
  'Código validado.': {
    es: 'Código validado.',
    en: 'Validated code.',
    fr: 'Code validé.',
  },
  'Não foi possível validar o código ou o código já foi validado.': {
    es: 'No se pudo validar el código o el código ya se validó.',
    en: 'Could not validate code or code has already been validated.',
    fr: 'Impossible de valider le code ou le code a déjà été validé.',
  },
  'Imagem da minha assinatura': {
    es: 'Imagen de mi firma',
    en: 'Image of my signature',
    fr: 'Image de ma signature',
  },
  'Seu Nome Aqui': {
    es: 'Your name here',
    en: 'Nombre aquí',
    fr: 'Nombre Aquí',
  },
  Escrever: {
    es: 'Escribir',
    en: 'Write',
    fr: 'Écrire',
  },
  'Alterar fonte': {
    es: 'Cambiar fuente',
    en: 'Change font',
    fr: 'Changer la police',
  },
  'Altere a fonte da sua assinatura': {
    es: 'Cambia la fuente de tu firma',
    en: 'Change your signature font',
    fr: 'Changez votre police de signature',
  },
  'Pré visualização': {
    es: 'Avance',
    en: 'Preview',
    fr: 'Aperçu',
  },
  'Ao assinar o documento, concordo que a assinatura será uma representação eletrônica da minha assinatura escrita em papel': {
    es: 'Al firmar el documento, acepto que la firma será una representación electrónica de mi firma escrita en papel.',
    en: 'By signing the document, I agree that the signature will be an electronic representation of my signature written on paper.',
    fr: 'En signant le document, je suis d\'accord que la signature sera une représentation électronique de ma signature écrite sur papier.',
  },
  'Extensões de arquivo permitidas: .png ou .jpeg': {
    es: 'Extensiones de archivo permitidas: .png o .jpeg',
    en: 'Allowed file extensions: .png or .jpeg',
    fr: 'Extensions de fichiers autorisés: .png ou .jpeg',
  },
  'Subir assinatura': {
    es: 'Subir firma',
    en: 'Upload signature',
    fr: 'Télécharger l\'abonnement',
  },
  '(atualizando...)': {
    es: 'actualizando...',
    en: 'updating...',
    fr: 'Mise à jour ...',
  },

  'Pré visualização da sua foto da assinatura': {
    es: 'Vista previa de la foto de tu firma',
    en: 'Preview your signature photo',
    fr: 'Aperçu votre photo de signature',
  },

  'Permitir que o signatário assine com “Subir”. Isto é, suba uma foto ou imagem de sua assinatura': {
    es: 'Permitir que el firmante firme con cargar. Es decir, sube una foto o imagen de su firma',
    en: 'Allow signer to sign with “Up”. That is, upload a photo or image of your signature',
    fr: 'Permettez au signataire de signer avec «Up». C\'est-à-dire télécharger une photo ou une image de votre signature',
  },
  'Permitir que o signatário assine com “Escrever”. Isto é, escolha uma assinatura cursiva pronta, criada a partir do seu nome. Caso escolhida, será pedida autenticação adicional por código por e-mail ou, na ausência de e-mail, por SMS.': {
    es: 'Permitir que el firmante firme con "Escribir". Es decir, selecciona una firma cursiva ya creada a partir de su nombre. Si se elige, se solicitará una autenticación adicional mediante código por correo electrónico o por SMS.',
    en: 'Allow signer to sign with “Write”. That is, choose a ready-made cursive signature created from your name. If chosen, additional authentication will be requested by code by e-mail or, in the absence of e-mail, by SMS.',
    fr: 'Permettez au signataire de signer avec «Écrire». Autrement dit, choisissez une signature cursive prête à l\'emploi créée à partir de votre nom. S\'il est choisi, une authentification supplémentaire sera demandée par code par e-mail ou, en l\'absence de courrier électronique, par SMS.',
  },
  'https://zapsign.com.br?utm_source=zapsign&utm_medium=product&utm_campaign=signer-conhecer': {
    es: 'https://zapsign.co/es/?utm_source=zapsign&utm_medium=product&utm_campaign=signer-conhecer',
    en: 'https://zapsign.co/?utm_source=zapsign&utm_medium=product&utm_campaign=signer-conhecer',
    fr: 'https://zapsign.co/?utm_source=zapsign&utm_medium=product&utm_campaign=signer-Conhecer',
  },
  'Você receberá o documento assinado no seu e-mail assim que encerrar as assinaturas ou conforme definido pelo proprietário.': {
    es: 'Recibirás el documento firmado en tu correo electrónico una vez que las firmas estén completas o según lo defina el propietario.',
    en: 'You will receive the signed document in your email once signatures are complete or as defined by the owner.',
    fr: 'Vous recevrez le document signé dans votre e-mail une fois les signatures terminées ou définies par le propriétaire.',
  },
  'correios e motoboys.': {
    es: 'y mensajeros.',
    en: 'mail and couriers.',
    fr: 'courrier et courriers.',
  },
  'Sem impressoras,': {
    es: 'Sin impresoras,',
    en: 'No printers,',
    fr: 'Pas d\'imprimantes,',
  },
  'Documento salvo': {
    es: 'Documento guardado',
    en: 'Document saved',
    fr: 'Document enregistré',
  },
  'são assinados em menos de 1 hora.': {
    es: 'se firman en menos de 1 hora.',
    en: 'are signed in less than 1 hour.',
    fr: 'sont signés en moins de 1 heure.',
  },
  '92% dos documentos': {
    es: '92% de los documentos',
    en: '92% of documents',
    fr: '92% des documents',
  },
  'ou qualquer canal de comunicação.': {
    es: 'o cualquier canal de comunicación.',
    en: 'or any communication channel.',
    fr: 'ou tout canal de communication.',
  },
  'WhatsApp, e-mail, SMS, Telegram': {
    es: 'WhatsApp, Correo electrónico, SMS, Telegram',
    en: 'WhatsApp, Email, SMS, Telegram',
    fr: 'WhatsApp, e-mail, SMS, télégramme',
  },
  'Compatível com': {
    es: 'Compatible con',
    en: 'Compatible with',
    fr: 'Compatible avec',
  },
  'Colete assinaturas pelo': {
    es: 'Captura firmas con',
    en: 'Collect signatures in',
    fr: 'Collecter des signatures',
  },
  'Para mais de 30 signatários com imagens, por favor, entre em contato com nosso suporte.': {
    es: 'En caso de más de 30 firmantes con imágenes, póngase en contacto con nuestro soporte.',
    en: 'In case of more than 30 signers with images, please contact our support.',
    fr: 'En cas de plus de 30 signataires avec des images, veuillez contacter notre support.',
  },
  'Para mais de 30 signatários com selfie ou foto do documento de identidade, por favor, entre em contato com nosso suporte.': {
    es: 'Para más de 30 firmantes con selfie o identificación con foto, comuníquese con nuestro soporte.',
    en: 'For more than 30 signers with selfie or photo ID, please contact our support.',
    fr: 'Pour plus de 30 signataires avec Selfie ou ID Photo, veuillez contacter notre support.',
  },
  'Salvar uma cópia': {
    es: 'Guardar una copia',
    en: 'Save a copy',
    fr: 'Sauvegarder une copie',
  },
  'Crie uma conta para salvar uma cópia deste documento e acessar quando e onde quiser.': {
    es: 'Crea una cuenta para guardar una copia de este documento y acceder a él cuando y donde quieras.',
    en: 'Create an account to save a copy of this document and access it whenever and wherever you want.',
    fr: 'Créez un compte pour enregistrer une copie de ce document et y accéder quand et où vous le souhaitez.',
  },
  'Documentos extras': {
    es: 'Documentos adicionales',
    en: 'Extra documents',
    fr: 'Documents supplémentaires',
  },
  'Acessar minha cópia': {
    es: 'Acceder a mi copia',
    en: 'Access my copy',
    fr: 'Accéder à ma copie',
  },
  'Salvamos uma cópia deste documento em sua conta ZapSign': {
    es: 'Guardamos una copia de este documento en tu cuenta de ZapSign',
    en: 'We save a copy of this document to your ZapSign account',
    fr: 'Nous enregistrons une copie de ce document sur votre compte Zapsign',
  },
  'Agora você já pode visualizar e baixar o documento': {
    es: 'Consultar y descargar el documento',
    en: 'View and download the',
    fr: 'Vous pouvez maintenant afficher et télécharger le',
  },
  'Não é possível alterar o email': {
    es: 'No se puede cambiar el correo electrónico',
    en: 'Unable to change email',
    fr: 'Impossible de modifier le courrier électronique',
  },
  'Apenas os que assinei': {
    es: 'Solo los que firmé',
    en: 'Only the ones i signed',
    fr: 'Seuls ceux que j\'ai signés',
  },
  'Apenas os que não assinei': {
    es: 'Solo los que no firmé',
    en: 'Only the ones I didn\'t sign',
    fr: 'Seuls ceux que je n\'ai pas signés',
  },
  'Navegar para $0': {
    es: 'Navegar a $0',
    en: 'Navigate to $0',
    fr: 'Accédez à $0',
  },
  'E-mail já existe na Zapsign.': {
    es: 'El correo electrónico ya existe en Zapsign.',
    en: 'Email already exists on Zapsign.',
    fr: 'Le courrier électronique existe déjà sur ZapSign.',
  },
  'E-mail pode ser utilizado': {
    es: 'Se puede utilizar el correo electrónico',
    en: 'Email can be used',
    fr: 'Le courrier électronique peut être utilisé',
  },
  'Ocorreu um erro ao processar o documento.': {
    es: 'Ocurrió un error al procesar el documento.',
    en: 'An error occurred while processing the document.',
    fr: 'Une erreur s\'est produite lors du traitement du document.',
  },
  'Modelos e Forms': {
    es: 'Plantillas y Forms',
    en: 'Templates & Forms',
    fr: 'Modèles et formulaires',
  },
  '.zapsign.com.br': {
    es: '.zapsign.co',
    en: '.zapsign.co',
    fr: '.zapsign.co',
  },
  'Quem irá assinar o documento?': {
    es: '¿Quién firmará el documento?',
    en: 'Who will sign the document?',
    fr: 'Qui signera le document?',
  },
  'Defina o $0 do primeiro signatário do documento.': {
    es: 'Establece el $0 del primer firmante del documento.',
    en: 'Set the $0 of the document\'s first signer.',
    fr: 'Définissez les $0 du premier signataire du document.',
  },
  'É obrigatório inserir o e-mail do segundo signatário em diante.': {
    es: 'Es obligatorio insertar el correo electrónico del segundo firmante en adelante.',
    en: 'It is mandatory to insert the e-mail from the second signatory onwards.',
    fr: 'Il est obligatoire d\'insérer l\'e-mail à partir du deuxième signataire.',
  },
  'O título do campo não pode ser vazio': {
    es: 'El título del campo no puede estar vacio',
    en: 'The field title cannot be empty',
    fr: 'Le titre de terrain ne peut pas être vide',
  },
  'Nome completo do signatário': {
    es: 'Nombre completo del firmante',
    en: 'Signer\'s full name',
    fr: 'Nom complet du signataire',
  },
  '(Opcional) Pré-preencha os campos que quiser.': {
    es: '(Opcional) Completa previamente los campos',
    en: '(Optional) Pre-fill as many fields as you like.',
    fr: '(Facultatif) Pré-suppose autant de champs que vous le souhaitez.',
  },
  'Pré-preencha': {
    es: 'Pre-diligencia',
    en: 'Pre-fill',
    fr: 'Pré-populer',
  },
  'os campos que quiser.': {
    es: 'los campos que quieras.',
    en: 'the fields you want.',
    fr: 'les champs que vous voulez.',
  },
  'Deseja enviar para várias pessoas?': {
    es: '¿Quieres enviar a varias personas?',
    en: 'Want to send to multiple people?',
    fr: 'Vous souhaitez envoyer plusieurs personnes ?',
  },
  'Compartilhe o': {
    es: 'Compartir el',
    en: 'Share the',
    fr: 'Partage le',
  },
  'link geral': {
    es: 'enlace general',
    en: 'general link',
    fr: 'lien général',
  },
  'do formulário com várias pessoas no seu site, WhatsApp, e-mail ou qualquer outro canal que você preferir.': {
    es: 'con los firmantes en tu sitio web, WhatsApp, correo electrónico o cualquier otro canal que prefieras.',
    en: 'with multiple people on your website, WhatsApp, email, or any other preferred channel',
    fr: 'général avec plusieurs personnes sur votre site web, sur WhatsApp, par courrier électronique ou par tout autre moyen que vous préférez.',
  },
  'Deseja enviar para uma pessoa?': {
    es: 'Envia el documento a una persona específica',
    en: 'Send the document to a specific person',
    fr: 'Envoyer le document à une personne spécifique',
  },
  'Crie um documento a partir deste formulário para gerar um': {
    es: 'Crea un documento a partir de esta plantilla para enviar al firmante.',
    en: 'Create a document from this template to send to the signer.',
    fr: 'Créez un document à partir de ce modèle pour l\'envoyer au signataire.',
  },
  'de preenchimento e assinatura.': {
    es: '',
    en: '',
    fr: '',
  },
  '(Opcional) Mensagem Personalizada': {
    es: '(Opcional) Mensaje personalizado',
    en: '(Optional) Custom Message',
    fr: '(Facultatif) Message personnalisé',
  },
  'Deseja editar as respostas do formulário?': {
    es: '¿Quieres editar las respuestas del formulario?',
    en: 'Want to edit form responses?',
    fr: 'Vous voulez modifier les réponses du formulaire?',
  },
  'Editar respostas do formulário': {
    es: 'Editar respuestas de formulario',
    en: 'Edit form responses',
    fr: 'Modifier les réponses du formulaire',
  },
  'Preencha os campos que desejar e os demais deverão ser preenchidos pelo signatário.': {
    es: 'Diligenciada los campos y los restantes deberán ser diligenciados por el firmante.',
    en: 'Fill in the fields you want and the others must be filled in by the signatory.',
    fr: 'Remplissez les champs que vous souhaitez et les autres doivent être remplis par le signataire.',
  },
  'Signatário ainda não respondeu ou confirmou o formulário.': {
    es: 'El firmante aún no ha respondido o confirmado el formulario.',
    en: 'Signatory has not yet responded or confirmed the form.',
    fr: 'Signatoire n\'a pas encore répondu ni confirmé le formulaire.',
  },
  Formulário: {
    es: 'Forma',
    en: 'Form',
    fr: 'Formulaire',
  },
  '+ Formulário/Modelo': {
    es: '+ Formulario/Plantilla',
    en: '+ Form/Template',
    fr: '+ Formulaire / modèle',
  },
  'veja aqui +': {
    es: 'conoce aqui +',
    en: 'learn more +',
    fr: 'en savoir plus +',
  },
  'Editar respostas ao formulário': {
    es: 'Editar respuestas de formulario',
    en: 'Edit form responses',
    fr: 'Modifier les réponses du formulaire',
  },
  'Preencha os dados acima!': {
    es: 'Diligencia los detalles de arriba!',
    en: 'Fill in the details above!',
    fr: 'Remplissez les détails ci-dessus!',
  },
  'Ei, ZapSigner! Queremos te conhecer melhor': {
    es: '¡Hola, ZapSigner! Queremos conocerte mejor',
    en: 'Hey ZapSigner! We want to get to know you better',
    fr: 'Hey Zapsigner! Nous voulons mieux vous connaître',
  },
  'Qual o motivo de criar a conta?': {
    es: '¿Para qué quieres crear una cuenta en ZapSign?',
    en: 'Why create the account?',
    fr: 'Comment utiliserez-vous ZapSign ?',
  },
  Selecionar: {
    es: 'Seleccione',
    en: 'Select',
    fr: 'Sélectionner',
  },
  'Olá! Que ótimo ter você aqui!': {
    es: '¡Hola! ¡Qué bueno tenerte aquí!',
    en: 'Hello! How great to have you here!',
    fr: 'Nous nous réjouissons de vous voir parmis nous !',
  },
  'Show de bola! Agora falta pouco pra começarmos.': {
    es: '¡Buenísimo! Ahora falta poco para que empecemos.',
    en: 'Cool! Now it\'s not long before we start.',
    fr: 'Cool! Maintenant, il ne faut plus longtemps avant de commencer.',
  },
  'Para que sua experiência seja incrível, precisamos personalizar a sua conta. Vamos lá?': {
    es: 'Para que tu experiencia sea increíble, vamos a personalizar tu cuenta',
    en: 'To make your experience amazing, we need to customize your account. Let\'s get started!',
    fr: 'Pour optimiser votre expérience, nous devons personnaliser votre compte',
  },
  'Qual é o seu ramo de atividade?': {
    es: '¿Cuál es la actividad de tu empresa?',
    en: 'What is your company\'s business activity?',
    fr: 'Quelle est l\'activité de votre entreprise ?',
  },
  'Pra finalizar, qual a quantidade de funcionários?': {
    es: 'Por último, ¿Cuántos empleados tiene tu empresa?',
    en: 'How many employees are there in your company?',
    fr: 'Enfin, combien d\'employés?',
  },
  'Quantos documentos sua empresa envia por mês?': {
    es: '¿Cuántos documentos envía tu empresa al mes?',
    en: 'How many docs does your company send each month?',
    fr: 'Combien de documents envoyez-vous chaque mois ?',
  },
  'Quero enviar documentos para outras pessoas assinarem': {
    es: 'Quiero enviar documentos para que otros los firmen',
    en: 'I want to send documents for others to sign',
    fr: 'Je veux envoyer des documents pour que les autres signent',
  },
  'Coletar assinaturas de terceiros': {
    es: 'Quiero enviar documentos para que otros los firmen',
    en: 'I want to send documents for others to sign',
    fr: 'Je veux envoyer des documents pour que les autres signent',
  },
  'Eu vou assinar documentos': {
    es: 'Solo quiero firmar un documento / uso personal',
    en: 'I just want to sign a document',
    fr: 'Je veux juste signer un document',
  },
  'Ver cópias de documentos assinados': {
    es: 'Quiero ver una copia de los documentos que firme en ZapSign',
    en: 'I want to see a copy of the documents that I signed on ZapSign',
    fr: 'Je veux voir une copie des documents que j\'ai signés sur Zapsign',
  },
  'Só quero assinar um documento': {
    es: 'Solo quiero firmar un documento',
    en: 'I just want to sign a document',
    fr: 'Je veux juste signer un document',
  },
  'Só quero ver cópia dos documentos que já assinei na ZapSign': {
    es: 'Ver una copia de los documentosfirmados en ZapSign',
    en: 'I just want to see a copy of the documents I already signed on ZapSign',
    fr: 'Je veux juste voir une copie des documents que j\'ai déjà signés sur Zapsign',
  },
  'Advocacia/Jurídico': {
    es: 'Abogacía/Legal',
    en: 'Legal',
    fr: 'Plaidoyer / juridique',
  },
  Autônomo: {
    es: 'Independiente',
    en: 'Freelance',
    fr: 'Indépendant',
  },
  Comércio: {
    es: 'Comercio de bienes/servicios',
    en: 'Sale of goods/services',
    fr: 'Vente de biens/services',
  },
  Consultoria: {
    es: 'Consultoría',
    en: 'Consultancy',
    fr: 'Conseil',
  },
  Contabilidade: {
    es: 'Contabilidad',
    en: 'Accounting',
    fr: 'Comptabilité',
  },
  'Educação privada': {
    es: 'Educación',
    en: 'Education',
    fr: 'Enseignement',
  },
  'Financeiro e Seguros': {
    es: 'Finanzas y Banca',
    en: 'Finance and insurance',
    fr: 'Finance et banque',
  },
  'Governo, fundação': {
    es: 'Gobierno, Fundación',
    en: 'Government, Foundation',
    fr: 'Gouvernement, fondation',
  },
  Imobiliário: {
    es: 'Bienes Raíces',
    en: 'Real Estate',
    fr: 'Immobilier',
  },
  Indústria: {
    es: 'Industria manufactura',
    en: 'Manufacturing',
    fr: 'Industrie',
  },
  'Marketing e Publicidade': {
    es: 'Marketing y publicidad',
    en: 'Marketing and Advertising',
    fr: 'Marketing et publicité',
  },
  'RH e Recrutamento': {
    es: 'RH y Reclutamiento',
    en: 'RH and Recruitment',
    fr: 'RH et recrutement',
  },
  Saúde: {
    es: 'Salud',
    en: 'Health',
    fr: 'Santé',
  },
  'Seguros e Previdência': {
    es: 'Seguros y Pensiones',
    en: 'Insurance and Pensions',
    fr: 'Assurance et pensions',
  },
  'Tecnologia, Software': {
    es: 'Tecnología, software',
    en: 'Technology, software',
    fr: 'Technologie, logiciel',
  },
  'Telecomunicações e mídia': {
    es: 'Telecomunicaciones',
    en: 'Telecommunications',
    fr: 'Télécommunications',
  },
  'Terceiro Setor': {
    es: 'Sector de servicios',
    en: 'Service sector',
    fr: 'Secteur des services',
  },
  'Turismo e Lazer': {
    es: 'Turismo y Ocio',
    en: 'Tourism and Leisure',
    fr: 'Tourisme et divertissement',
  },
  'Outro ramo não listado': {
    es: 'Otra actividad no listada',
    en: 'Other activity not listed',
    fr: 'Autres secteurs d\'activité',
  },
  'Até 5': {
    es: 'Hasta 5',
    en: 'Up to 5',
    fr: 'Jusqu\'à 5',
  },
  'Até 50': {
    es: 'Hasta 50',
    en: 'Up to 50',
    fr: 'Jusqu\'à 50',
  },
  '6-100': {
    es: '6-100',
    en: '6-100',
    fr: '6-100',
  },
  '51-200': {
    es: '51-200',
    en: '51-200',
    fr: '51-200',
  },
  '201 a 1000': {
    es: '201 a 1000',
    en: 'Between 201 and 1000',
    fr: 'Entre 201 et 1000',
  },
  '21 a 100': {
    es: '21 a 100',
    en: 'Between 21 and 100',
    fr: 'Entre 21 et 100',
  },
  '101-500': {
    es: '101-500',
    en: '101-500',
    fr: '101-500',
  },
  '500-3000': {
    es: '500-3000',
    en: '500-3000',
    fr: '500-3000',
  },
  'Acima de 3000': {
    es: 'Más de 3000',
    en: 'More than 3000',
    fr: 'Plus de 3000',
  },
  'Acima de 1000': {
    es: 'Más de 1000',
    en: 'More than 1000',
    fr: 'Plus de 1000',
  },
  'Indicações de terceiros': {
    es: 'Recomendaciones de terceros',
    en: 'Third-party referrals',
    fr: 'Références tierces',
  },
  'Perguntas respondidas com sucesso': {
    es: '¡Preguntas respondidas con éxito!',
    en: 'Questions successfully answered!',
    fr: 'Les questions ont répondu avec succès!',
  },
  'Não quero responder': {
    es: 'Omitir',
    en: 'Skip',
    fr: 'Sauter',
  },
  'Imagem de uma garota segurando um envelope em cima de uma montanha pensando em cálculos, configurações e gráficos': {
    es: 'Imagen de una niña sosteniendo un sobre en la cima de una montaña pensando en cálculos, configuraciones y gráficos',
    en: 'Image of a girl holding an envelope on top of a mountain thinking about calculations, settings and graphs?',
    fr: 'Image d\'une fille tenant une enveloppe au-dessus d\'une montagne en pensant aux calculs, aux paramètres et aux graphiques?',
  },
  'Plano disponível somente no cartão': {
    es: 'Plan disponible solo en tarjeta',
    en: 'Plan available only on card',
    fr: 'Plan disponible uniquement sur la carte',
  },
  'Ver galeria': {
    es: 'Ver galería',
    en: 'See gallery',
    fr: 'Voir la galerie',
  },
  'Utilize modelos dinâmicos prontos': {
    es: 'Use plantillas dinámicas listas para usar',
    en: 'Use ready-made dynamic templates',
    fr: 'Utilisez des modèles dynamiques prêts à l\'emploi',
  },
  'Não encontrou o modelo que precisava? Feche a galeria e clique em +NOVO MODELO para criar o seu customizado.': {
    es: '¿No encontraste el modelo que necesitabas? Cierra la galería y haz clic en +NUEVA PLANTILLA para crear una personalizada.',
    en: 'Didn\'t find the model you needed? Close the gallery and click on +NEW TEMPLATE to create your custom one.',
    fr: 'Vous n\'avez pas trouvé le modèle dont vous aviez besoin? Fermez la galerie et cliquez sur + nouveau modèle pour créer votre personnalité.',
  },

  'Selecione um dos arquivos prontos abaixo para utilizar como modelo dinâmico': {
    es: 'Seleccione uno de los archivos listos para usar a continuación para usarlo como plantilla dinámica',
    en: 'Select one of the ready-made files below to use as a dynamic template',
    fr: 'Sélectionnez l\'un des fichiers prêts à l\'emploi ci-dessous pour utiliser comme modèle dynamique',
  },
  'Galeria de Modelos': {
    es: 'Galería de modelos',
    en: 'Model gallery',
    fr: 'Galerie de modèles',
  },
  'Criar modelo': {
    es: 'Crear template',
    en: 'Create template',
    fr: 'Créer un modèle',
  },
  'Pré visualização do documento $0': {
    es: 'Vista previa del documento $0',
    en: 'Document preview $0',
    fr: 'Aperçu du document $0',
  },
  'Ver documento: $0': {
    es: 'Ver documento: $0',
    en: 'See document: $0',
    fr: 'Voir document: $0',
  },
  'Baixar: $0': {
    es: 'Descargar: $0',
    en: 'Download: $0',
    fr: 'Télécharger: $0',
  },
  Recibo: {
    es: 'Recibo',
    en: 'Receipt',
    fr: 'Reçu',
  },
  'Termo de compromisso': {
    es: 'Plazo de compromiso',
    en: 'Term of commitment',
    fr: 'Termes d\'engagement',
  },
  Procuração: {
    es: 'Carta de abogado',
    en: 'Letter of attorney',
    fr: 'Lettre d\'avocat',
  },
  'Contrato de prest. de serviços': {
    es: 'Contrato de prestacion de servicios',
    en: 'Contract for services',
    fr: 'Contrat pour les services',
  },
  'Ficha de cadastro': {
    es: 'Formulario de inscripción',
    en: 'Registration form',
    fr: 'Formulaire d\'inscription',
  },
  'O processo de assinatura via reconhecimento facial foi cancelado.': {
    es: 'Se ha anulado el proceso de firma por reconocimiento facial.',
    en: 'The signature process via facial recognition has been canceled.',
    fr: 'Le processus de signature via la reconnaissance faciale a été annulé.',
  },
  'Ocorreu algum erro durante o processamento de identidade. Tente novamente!': {
    es: 'Se ha producido un error durante el procesamiento de la identidad. Por favor, inténtalo de nuevo',
    en: 'Some error occurred during identity processing. Please try again!',
    fr: 'Une erreur s\'est produite pendant le traitement de l\'identité. Veuillez réessayer!',
  },
  'O processo de identificação expirou. Tente novamente!': {
    es: 'El proceso de identificación ha expirado. Por favor, inténtalo de nuevo',
    en: 'The identification process has expired. Please try again!',
    fr: 'Le processus d\'identification a expiré. Veuillez réessayer!',
  },
  'Ops, você não tem acesso a este recurso ...': {
    es: 'Ups, No tienes acceso a esta función ...',
    en: 'Oops, you do not have access to this feature ...',
    fr: 'Oups, vous n\'avez pas accès à cette fonctionnalité ...',
  },
  'Você atingiu o limite de 5 (cinco) autenticações avançadas do nosso plano essencial.': {
    es: 'Has alcanzado el límite de 5 (cinco) autenticaciones avanzadas de nuestro plan esencial.',
    en: 'You have reached the limit of 5 (five) advanced authentications of our essential plan.',
    fr: 'Vous avez atteint la limite de 5 (cinq) authentifications avancées de notre plan essentiel.',
  },
  'É preciso ter um plano mensal ou anual ativo para poder utilizar esta funcionalidade.': {
    es: 'Debes tener un plan mensual o anual activo para usar esta función.',
    en: 'It is required to have an monthly or anually plan to have access to this feature.',
    fr: 'Il est nécessaire d\'avoir un plan mensuel ou annuellement pour avoir accès à cette fonctionnalité.',
  },
  'Que tal fazer um upgrade e aproveitar o que a ZapSign tem de melhor?': {
    es: '¿Qué tal actualizar y disfrutar de lo mejor de Zapsign?',
    en: 'What about make an upgrade and enjoy the best of zapsign?',
    fr: 'Qu\'en est-il de faire une mise à niveau et de profiter du meilleur de ZapSign?',
  },
  'Talvez mais tarde': {
    es: 'Talvez más tarde',
    en: 'Maybe later',
    fr: 'Peut-être plus tard',
  },
  'Se tiver sugestões de modelo, envie um e-mail para support@zapsign.com.br': {
    es: 'Si tiene sugerencias de plantillas, envíe un correo electrónico a support@zapsign.com.br',
    en: 'If you have template suggestions, please send an email to support@zapsign.com.br',
    fr: 'Si vous avez des suggestions de modèles, veuillez envoyer un e-mail à support@zapsign.com.br',
  },
  'CPF do signatário (opcional)': {
    es: 'CPF del firmante (opcional)',
    en: 'Signatory\'s CPF (optional)',
    fr: 'CPF de signataire (facultatif)',
  },
  'Por favor, digite seu CPF': {
    es: 'Por favor ingresa tu CPF',
    en: 'Please enter your CPF',
    fr: 'Veuillez saisir votre CPF',
  },
  'O CPF informado não é válido': {
    es: 'El CPF introducido no es válido',
    en: 'The CPF entered is not valid',
    fr: 'Le CPF entré n\'est pas valide',
  },
  'Continuar sem posicionar assinaturas?': {
    es: '¿Continuar sin poner firmas?',
    en: 'Continue without placing signatures?',
    fr: 'Continuez sans placer des signatures?',
  },
  '/assets/img/assinatura-ilustracao.png': {
    es: '/assets/img/assinatura-ilustracao-es.png',
    en: '/assets/img/assinatura-ilustracao-en.png',
    fr: '/Assets/img/assinatura-lustracao-en.png',
  },
  'Reparamos que você ainda não posicionou assinaturas no corpo do documento, então elas ficarão apenas no relatório de assinaturas. Para posicionar, é só clicar sobre o local desejado no documento. Deseja continuar mesmo assim?': {
    es: 'Notamos que aún no haz posicionado firmas en el cuerpo del documento, por lo que solo estarán en el informe de firmas. Para posicionar, simplemente haz clic en la ubicación deseada en el documento. ¿Quieres continuar de todos modos?',
    en: 'We\'ve noticed that you haven\'t placed signatures within the document body yet, so they will only appear in the signatures report. To position them, simply click on the desired location in the document. Would you like to proceed anyway?',
    fr: 'du document, elles apparaîtront donc uniquement dans le rapport des signatures. Pour les positionner, cliquez simplement à l\'emplacement souhaité dans le document. Souhaitez-vous tout de même continuer?',
  },
  'POSICIONAR AGORA': {
    es: 'POSICIONAR AHORA',
    en: 'POSITION NOW',
    fr: 'Position maintenant',
  },
  CONTINUAR: {
    es: 'CONTINUAR',
    en: 'CONTINUE',
    fr: 'CONTINUER',
  },
  'Desabilitar link de assinatura': {
    es: 'Desactivar enlace de firma',
    en: 'Disable signature link',
    fr: 'Désactiver le lien d\'abonnement',
  },
  'dias após o signatário assinar.': {
    es: 'días siguientes a la firma.',
    en: 'days after the signatory signs.',
    fr: 'jours après les signes signataires.',
  },
  'Link de assinatura expirado': {
    es: 'Link de firma expirado',
    en: 'Expired signature link',
    fr: 'Le lien de signature a expiré',
  },
  'Figura em formato de desenho de um homem de camiseta azul segurando uma paleta cinza de escritório escrevendo com uma caneta cinza': {
    es: 'Figura de dibujos animados de un hombre con una camiseta azul sosteniendo una paleta de oficina gris escribiendo con un bolígrafo gris',
    en: 'Cartoon figure of a man in a blue t-shirt holding a gray office palette writing with a gray pen',
    fr: 'Cartoon Figure d\'un homme dans un t-shirt bleu tenant une palette de bureaux gris écrivant avec un stylo gris',
  },
  'Enviamos um novo link de acesso em seu e-mail $0. Uma cópia do documento também está disponível em sua conta ZapSign. Em caso de dúvidas ou problemas, contate a pessoa que te enviou o documento.': {
    es: 'Hemos enviado un nuevo enlace de acceso a tu correo electrónico $0. Una copia del documento también está disponible en tu cuenta de ZapSign. Si tienes alguna pregunta o problema, comunícate con la persona que le envió el documento.',
    en: 'We have sent a new access link to your email $0. A copy of the document is also available in your ZapSign account. If you have any questions or problems, contact the person who sent you the document.',
    fr: 'Nous avons envoyé un nouveau lien d\'accès à votre e-mail $0. Une copie du document est également disponible dans votre compte Zapsign. Si vous avez des questions ou des problèmes, contactez la personne qui vous a envoyé le document.',
  },
  'Por favor, contate a pessoa que te enviou o documento para acessar sua cópia.': {
    es: 'Pónte en contacto con la persona que envió el documento para acceder a una copia.',
    en: 'Please contact the person who sent you the document to access your copy.',
    fr: 'Veuillez contacter la personne qui vous a envoyé le document pour accéder à votre copie.',
  },
  'Imagem de assinatura': {
    es: 'imagen de la firma',
    en: 'Signature image',
    fr: 'Image de signature',
  },
  'O CPF será solicitado ao signatário e constará no Relatório de Assinaturas como um dos pontos de autenticação.': {
    es: 'El CPF se solicitará al firmante y aparecerá en el Informe de firma como uno de los puntos de autenticación.',
    en: 'The CPF will be requested from the signatory and will appear in the Signature Report as one of the authentication points.',
    fr: 'Le CPF sera demandé au signataire et apparaîtra dans le rapport Signature comme l\'un des points d\'authentification.',
  },
  Popular: {
    es: 'Popular',
    en: 'Popular',
    fr: 'Populaire',
  },
  'Suas cores e marcas': {
    es: 'Tus colores y marcas',
    en: 'Your colors and brands',
    fr: 'Vos couleurs et marques',
  },
  'Assinatura com selfie e documento de identidade': {
    es: 'Firma con selfie y documento de identidad',
    en: 'Signature with selfie and ID document',
    fr: 'Signature avec selfie et document d\'identification',
  },
  'Ver tabela comparativa': {
    es: 'Ver cuadro comparativo',
    en: 'See comparative table',
    fr: 'Voir table comparative',
  },
  Inclui: {
    es: 'Incluye',
    en: 'Includes',
    fr: 'Comprend',
  },
  'usuários grátis': {
    es: 'usuarios gratuitos',
    en: 'free users',
    fr: 'utilisateurs gratuits',
  },
  '240 docs/ano (20 docs/mês se plano mensal)': {
    es: '240 docs/año (20 docs/mes si plan mensual)',
    en: '240 docs/year (20 docs/month if monthly plan)',
    fr: '240 docs / an (20 docs / mois si le plan mensuel)',
  },
  '(somente no cartão)': {
    es: '(solo en la tarjeta)',
    en: '(only on the card)',
    fr: '(seulement sur la carte)',
  },
  '(via cartão ou boleto)': {
    es: '(mediante tarjeta o billete)',
    en: '(via card or ticket)',
    fr: '(via la carte ou le billet)',
  },
  '(equivale a $0/mês)': {
    es: '(equivalente a $0/mes)',
    en: '(equivalent to $0/month)',
    fr: '(équivalent à $0/mois)',
  },
  'Plano Completo (anual parcelado)': {
    es: 'Plan Completo (cuotas anuales)',
    en: 'Complete Plan (annual installment)',
    fr: 'Plan complet (versement annuel)',
  },
  'Plano Completo (anual à vista)': {
    es: 'Plan Completo (anual en efectivo)',
    en: 'Complete Plan (annual in cash)',
    fr: 'Plan complet (annuel en espèces)',
  },
  'Plano Completo (mensal)': {
    es: 'Plan Completo (mensual)',
    en: 'Complete Plan (monthly)',
    fr: 'Plan complet (mensuellement)',
  },
  'Plano Profissional (anual parcelado)': {
    es: 'Plan Profesional (cuotas anuales)',
    en: 'Professional Plan (annual installments)',
    fr: 'Plan professionnel (versements annuels)',
  },
  'Plano Profissional (anual à vista)': {
    es: 'Plan Profesional (anual en efectivo)',
    en: 'Professional Plan (annual in cash)',
    fr: 'Plan professionnel (annuel en espèces)',
  },
  'Plano Profissional (mensal)': {
    es: 'Plan Profesional (mensual)',
    en: 'Professional Plan (monthly)',
    fr: 'Plan professionnel (mensuellement)',
  },
  '12x de': {
    es: '12x de',
    en: '12x of',
    fr: '12x de',
  },
  'Adicione um documento complementar ao modelo principal.': {
    es: 'Agrega un documento complementario a la plantilla principal.',
    en: 'Attach an additional document to the main template.',
    fr: 'Ajoutez un document compagnon au modèle principal.',
  },
  'Ele pode ser um documento comum ou um documento dinâmico, contanto que os campos de preenchimento sejam iguais ou menos do que o modelo principal.': {
    es: 'Puede ser un documento con o sin campos dinámico, siempre que los campos a diligenciar sean iguales o menores que la plantilla principal.',
    en: 'It can be a document with or without dynamic fields, as long as the fields to be filled in are the same or fewer than the main template.',
    fr: 'Il peut s\'agir d\'un document avec ou sans champs dynamiques, tant que les champs à remplir sont les mêmes ou moins nombreux que le modèle principal.',
  },
  'Editar o nome do documento': {
    es: 'Editar nombre del documento',
    en: 'Edit document name',
    fr: 'Modifier le nom du document',
  },
  'Abrir o documento': {
    es: 'Abre el documento',
    en: 'Open the document',
    fr: 'Ouvrir le document',
  },
  'Remover o documento': {
    es: 'Eliminar documento',
    en: 'Remove document',
    fr: 'Supprimer le document',
  },
  'Documento complementar cadastrado com sucesso!': {
    es: '¡Documento complementario registrado con éxito!',
    en: 'Extra document successfully registered!',
    fr: 'Document supplémentaire enregistré avec succès!',
  },
  'Ocorreu um erro ao cadastrar o documento complementar.': {
    es: '¡Documento complementario registrado con éxito!',
    en: 'Extra document successfully registered!',
    fr: 'Document supplémentaire enregistré avec succès!',
  },
  'Esse documento possui variáveis diferentes do que o documento principal!': {
    es: '¡Este documento tiene diferentes variables que el documento principal!',
    en: 'This document has different variables than the main document!',
    fr: 'Ce document a des variables différentes de celle du document principal!',
  },
  'Não é possivel passar dois documentos iguais!': {
    es: '¡No es posible pasar dos documentos idénticos!',
    en: 'It is not possible to pass two identical documents!',
    fr: 'Il n\'est pas possible de passer deux documents identiques!',
  },
  'Documento complementar editado com sucesso!': {
    es: '¡Documento complementario editado con éxito!',
    en: 'Supplementary document successfully edited!',
    fr: 'Document supplémentaire a réussi à éditer!',
  },
  'Documento complementar apagado com sucesso!': {
    es: '¡Documento complementario eliminado con éxito!',
    en: 'Extra document successfully deleted!',
    fr: 'Document supplémentaire Supprimé avec succès!',
  },
  'Código por WhatsApp': {
    es: 'Código por WhatsApp',
    en: 'Code by WhatsApp',
    fr: 'Code par WhatsApp',
  },
  'Assinatura + Whatsapp': {
    es: 'Firma + Whatsapp',
    en: 'Siganture + Whatsapp',
    fr: 'Abonnement + WhatsApp',
  },
  'BAIXAR RELATÓRIO': {
    es: 'DESCARGAR INFORME',
    en: 'DOWNLOAD REPORT',
    fr: 'Rapport de téléchargement',
  },
  'Baixar relatório': {
    es: 'Descargar informe',
    en: 'Download report',
    fr: 'Télécharger le rapport'
  },
  'Baixar relatório (carregando...)': {
    es: 'Descargar informe (cargando...)',
    en: 'Download report (loading...)',
    fr: 'Télécharger le rapport (chargement...)'
  },
  'https://zapsign.com.br/termos-de-uso/': {
    es: 'https://zapsign.co/es/termos-de-uso/',
    en: 'https://zapsign.co/termos-de-uso/',
    fr: 'https://zapsign.co/termos-de-uso/',
  },
  'https://zapsign.com.br/politica-de-privacidade/': {
    es: 'https://zapsign.co/es/politica-de-privacidade/',
    en: 'https://zapsign.co/politica-de-privacidade/',
    fr: 'https://zapsign.co/politica-de-privacidade/',
  },
  'Adicionando $0 documento complementar...': {
    es: 'Agregando $0 documento suplementario...',
    en: 'Adding $0 supplementary document...',
    fr: 'Ajout d\'un document supplémentaire de $0 ...',
  },
  'Adicionando documento complementar...': {
    es: 'Agregando documento suplementario...',
    en: 'Adding extra document...',
    fr: 'Ajout d\'un document supplémentaire ...',
  },
  'Exibir no relatório de assinaturas o e-mail do usuário que criou o documento na ZapSign.': {
    es: 'Mostrar email del creador del documento en el informe de firmas',
    en: 'Show document`s owner e-mail in signatures report.',
    fr: 'Afficher le rapport sur le propriétaire du document dans le rapport de signatures.',
  },
  'Como nos descobriu? Conta pra gente!': {
    es: 'Cuéntanos ¿Cómo nos conociste?',
    en: 'Where did you learn about us?',
    fr: 'Comment nous avez-vous trouvés ?',
  },
  'Pesquisa no Google/Bing': {
    es: 'Buscadores (Google, Bing…)',
    en: 'Search engine (Google, Bing…)',
    fr: 'Moteur de recherche (Google, Bing…)',
  },
  'Redes sociais': {
    es: 'Redes sociales',
    en: 'Social media',
    fr: 'Réseaux sociaux',
  },
  'Indicações de conhecidos/amigos': {
    es: 'Referencia de conocido/amigo',
    en: 'Recommended by colleague or friend',
    fr: 'Recommandé par un ami ou une connaissance',
  },
  'Assinando um documento': {
    es: 'Firmando un documento',
    en: 'Signing a document',
    fr: 'Signer un document',
  },
  Outro: {
    es: 'Otro',
    en: 'Other',
    fr: 'Autre',
  },
  'Limite de Envios': {
    es: 'Límite de envío',
    en: 'Shipping Limit',
    fr: 'Limite d\'expédition',
  },
  'Tenha acesso ilimitado!': {
    es: '¡Consigue acceso ilimitado!',
    en: 'Get unlimited access!',
    fr: 'Obtenez un accès illimité!',
  },
  'Melhore sua conta': {
    es: 'Mejora tu cuenta',
    en: 'Improve your account',
    fr: 'Améliorez votre compte',
  },
  'Permitir que o signatário se recuse a assinar o documento. Nesse caso, todo o documento será rejeitado e não será enviado ao próximo signatário.': {
    es: 'Permitir que el firmante rechace firmar el documento. En este caso, se rechazará el documento completo y no se eviará al próximo firmante.',
    en: 'Allow the signer to refuse to sign the document. In this case, the entire document will be rejected and will not be sent to the next signer.',
    fr: 'Permettez au signataire de refuser de signer le document. Dans ce cas, l\'ensemble du document sera rejeté et ne sera pas envoyé au prochain signataire.',
  },
  'Rejeitar assinatura': {
    es: 'Rechazar firma',
    en: 'Reject signature',
    fr: 'Refuser document',
  },
  'Opções do documento': {
    es: 'Opciones de documento',
    en: 'Document options',
    fr: 'Options de documents',
  },
  'Você quer se recusar a assinar os documentos?': {
    es: '¿Quieres negarte a firmar los documentos?',
    en: 'Do you want to refuse to sign the documents?',
    fr: 'Voulez-vous refuser de signer les documents?',
  },

  'Se você selecionar “Rejeitar”, não poderá assinar o documento posteriormente e notificaremos o remetente.': {
    es: 'Si seleccionas “Rechazar”, no podrás firmar el documento más adelante y notificaremos al remitente.',
    en: 'If you select “Refuse”, you will not be able to sign the document later and we will notify the sender.',
    fr: 'Si vous sélectionnez «Refuser», vous ne pourrez pas signer le document plus tard et nous informerons l\'expéditeur.',
  },
  'Não. Voltar para o documento.': {
    es: 'No. Volver al documento.',
    en: 'No. Go back to the document.',
    fr: 'Non. Retournez au document.',
  },
  Sim: {
    es: 'Si',
    en: 'Yes',
    fr: 'Oui',
  },
  'Por favor, escreva o motivo pelo qual você se recusa a assinar o documento': {
    es: 'Por favor, escribe el motivo por el cual rechazas firmar el documento',
    en: 'Please write the reason why you refuse to sign the document',
    fr: 'Veuillez indiquer la raison pour laquelle vous refusez de signer le document',
  },
  'Permitir recusar o documento': {
    es: 'Permitir rechazar el documento',
    en: 'Allow the signer to decline the document',
    fr: 'Permettez au signataire de refuser le document',
  },
  Recusado: {
    es: 'Rechazado',
    en: 'Rejected',
    fr: 'Rejeté',
  },
  Recusados: {
    es: 'Rechazados',
    en: 'Rejected',
    fr: 'Rejeté',
  },
  'Escreva o motivo para a recusa do seu documento aqui': {
    es: 'Escribe aquí el motivo de rechazo del documento',
    en: 'Write the reason for the refusal of your document here',
    fr: 'Indiquez le motif du rejet du document',
  },
  'Ícone do Whatsapp cinza': {
    es: 'Icono gris de whatsapp',
    en: 'Gray whatsapp icon',
    fr: 'Icône gris whatsapp',
  },
  'Visualizou o documento e se recusou a assinar. Rejeitado em $0': {
    es: 'Vió el documento y se negó a firmar. Rechazado en $0',
    en: 'View the document and refused to sign. Rejected in $0',
    fr: 'Affichez le document et refusé de signer. Rejeté en $0',
  },
  'Motivo de Rejeição da Assinatura:': {
    es: 'Motivo del rechazo de la firma:',
    en: 'Signature rejection reason:',
    fr: 'Raison de rejet de signature:',
  },
  'Notificamos o remetente sobre o status do documento. Qualquer dúvida, entre em contato com o remetente.': {
    es: 'Notificamos al remitente el estado del documento. Cualquier pregunta, por favor póngase en contacto con el remitente.',
    en: 'We notify the sender of the status of the document. Any questions, please contact the sender.',
    fr: 'Nous informerons l\'expéditeur du statut du document. Toutes les questions, veuillez contacter l\'expéditeur.',
  },
  'Documento não assinado': {
    es: 'Documento sin firmar',
    en: 'Unsigned document',
    fr: 'Document non signé',
  },
  'Esse documento foi recusado!': {
    es: 'Este documento fue rechazado',
    en: 'This document was refused!',
    fr: 'Ce document a été refusé',
  },
  'Em qual pasta este documento será criado?': {
    es: '¿En qué carpeta se creará este documento?',
    en: 'In which folder will this document be created?',
    fr: 'Dans quel dossier ce document sera-t-il créé?',
  },
  'Ops, seu plano venceu': {
    es: 'Ups, tu plan expiró',
    en: 'Oops, your plan expired',
    fr: 'Oups, votre plan a expiré',
  },
  'Falar com especialista': {
    es: 'Hablar con ventas',
    en: 'Talk to sales',
    fr: 'Parler aux vendeurs',
  },
  'Renovar meu plano': {
    es: 'Renovar mi plan',
    en: 'Renew my plan',
    fr: 'Renouveler mon plan',
  },
  'Olá, ZapSigner! Verificamos que o pagamento de renovação do seu $0 ainda não foi efetuado. Que tal fazer sua renovação agora? Em caso de dúvidas, entre em contato com a gente! Estamos à sua disposição.': {
    es: '¡Hola ZapSigner! Hemos verificado que aún no se ha realizado el pago de la renovación de tu $0. ¿Qué tal si haces tu renovación ahora? Si tienes dudas, ¡ponte en contacto con nosotros! Estamos a su disposición.',
    en: 'Hi ZapSigner! We have verified that the payment for the renewal of your $0 has not yet been made. How about doing your renovation now? If in doubt, get in touch with us! We are at your disposal.',
    fr: 'Salut Zapsigner! Nous avons vérifié que le paiement du renouvellement de votre $0 n\'a pas encore été effectué. Que diriez-vous de faire votre rénovation maintenant? En cas de doute, contactez-nous! Nous sommes à votre disposition.',
  },
  'Os usuários visualizam apenas os documentos que eles mesmos criaram': {
    es: 'Los usuarios solo ven los documentos que ellos mismos crearon',
    en: 'Users only view documents created by themselves',
    fr: 'Les utilisateurs affichent uniquement les documents créés par eux-mêmes',
  },
  'Os usuários visualizam documentos criados por todos os integrantes do grupo': {
    es: 'Los usuarios ven los documentos creados por todos los miembros del grupo',
    en: 'Users view documents created by all group members',
    fr: 'Les utilisateurs affichent des documents créés par tous les membres du groupe',
  },
  'Os usuários visualizam todos os documentos da organização': {
    es: 'Los usuarios ven todos los documentos de la organización',
    en: 'Users view all organization documents',
    fr: 'Les utilisateurs voient tous les documents de l\'organisation',
  },
  'Deseja remover do grupo?': {
    es: '¿Quieres eliminar del grupo?',
    en: 'Do you want to remove from the group?',
    fr: 'Voulez-vous retirer du groupe?',
  },
  Procurar: {
    es: 'Buscar',
    en: 'Search',
    fr: 'Chercher...',
  },
  'Procurar usuários': {
    es: 'Buscar usuarios',
    en: 'Search users',
    fr: 'Rechercher les utilisateurs',
  },
  'Essa funcionalidade só está disponível para organizações do Plano Completo ou Corporativo. Faça upgrade do seu plano agora mesmo para começar a utilizar Grupos de Usuários.': {
    es: 'Esta funcionalidad solo está disponible para organizaciones con plan Completo o Comercial. Actualice su plan ahora para comenzar a usar grupos de usuarios.',
    en: 'This functionality is only available to Complete or Enterprise Plan organizations. Upgrade your plan now to start using User Groups.',
    fr: 'Cette fonctionnalité n\'est disponible que pour compléter ou des organisations de plan d\'entreprise. Mettez à niveau votre plan maintenant pour commencer à utiliser des groupes d\'utilisateurs.',
  },
  'Este grupo ainda não possui membros.': {
    es: 'Este grupo aún no tiene miembros.',
    en: 'There is no members in this group yet.',
    fr: 'Il n\'y a pas encore de membres dans ce groupe.',
  },
  'Grupo criado com sucesso.': {
    es: 'Grupo creado con éxito.',
    en: 'Group created successfully.',
    fr: 'Groupe créé avec succès.',
  },
  'Grupo atualizado com sucesso.': {
    es: 'Grupo actualizado correctamente.',
    en: 'Group updated successfully.',
    fr: 'Groupe mis à jour avec succès.',
  },
  'Membro deletado do grupo com sucesso.': {
    es: 'Miembro del grupo eliminado con éxito.',
    en: 'Group member deleted successfully.',
    fr: 'Un membre du groupe a supprimé avec succès.',
  },
  'Grupo deletado com sucesso.': {
    es: 'Grupo eliminado con éxito.',
    en: 'Group deleted successfully.',
    fr: 'Groupe supprimé avec succès.',
  },
  'Você não tem permissão para criar um grupo de usuário.': {
    es: 'No tiene permiso para crear un grupo de usuarios.',
    en: 'You do not have permission to create a user group.',
    fr: 'Vous n\'avez pas la permission de créer un groupe d\'utilisateurs.',
  },
  'Os usuários podem excluir apenas os documentos que eles mesmos criaram.': {
    es: 'Los usuarios solo pueden eliminar documentos que hayan creado ellos mismos.',
    en: 'Users can only delete documents they created themselves.',
    fr: 'Les utilisateurs ne peuvent supprimer que des documents qu\'ils ont créés eux-mêmes.',
  },
  'Os usuários podem excluir documentos criados por todos os integrantes do grupo.': {
    es: 'Los usuarios pueden eliminar documentos creados por todos los miembros del grupo.',
    en: 'Users can delete documents created by all group members.',
    fr: 'Les utilisateurs peuvent supprimer des documents créés par tous les membres du groupe.',
  },
  'Os usuários podem excluir todos os documentos da organização.': {
    es: 'Los usuarios pueden eliminar todos los documentos de la organización.',
    en: 'Users can delete all organization documents.',
    fr: 'Les utilisateurs peuvent supprimer tous les documents de l\'organisation.',
  },
  'Participantes do grupo': {
    es: 'Participantes del grupo',
    en: 'Group members',
    fr: 'Les membres du groupe',
  },
  'Editar grupo': {
    es: 'Editar grupo',
    en: 'Edit group',
    fr: 'Éditer du groupe',
  },
  'Excluir grupo': {
    es: 'Eliminar grupo',
    en: 'Delete group',
    fr: 'Supprimer le groupe',
  },
  'Todos os usuários': {
    es: 'Todos los usuarios',
    en: 'All users',
    fr: 'Tous les utilisateurs',
  },
  'Grupo de usuários': {
    es: 'Grupo de usuario',
    en: 'User group',
    fr: 'Groupe d\'utilisateurs',
  },
  'Você não tem acesso aos modelos e forms. Em caso de dúvidas, entre em contato com o proprietário da organização.': {
    es: 'No tienes acceso a Plantillas y Forms. Si tiene alguna pregunta, comuníquese con el propietario de la organización.',
    en: 'You do not have access to templates & forms. If you have any questions, please contact the organization owner.',
    fr: 'Vous n\'avez pas accès aux modèles et aux formulaires. Si vous avez des questions, veuillez contacter le propriétaire de l\'organisation.',
  },
  'Você não tem acesso aos relatórios. Em caso de dúvidas, entre em contato com o proprietário da organização.': {
    es: 'No tiene acceso a los informes. Si tiene alguna pregunta, comuníquese con el propietario de la organización.',
    en: 'You do not have access to reports. If you have any questions, please contact the organization owner.',
    fr: 'Vous n\'avez pas accès aux rapports. Si vous avez des questions, veuillez contacter le propriétaire de l\'organisation.',
  },
  'Você não tem acesso aos contatos. Em caso de dúvidas, entre em contato com o proprietário da organização.': {
    es: 'No tienes acceso a contactos. Si tiene alguna pregunta, comuníquese con el propietario de la organización.',
    en: 'You do not have access to contacts. If you have any questions, please contact the organization owner.',
    fr: 'Vous n\'avez pas accès aux contacts. Si vous avez des questions, veuillez contacter le propriétaire de l\'organisation.',
  },
  'Você não tem acesso aos antecedentes. Em caso de dúvidas, entre em contato com o proprietário da organização.': {
    es: 'No tienes acceso a los antecedentes. Si tiene alguna pregunta, comuníquese con el propietario de la organización.',
    en: 'You do not have access to background. If you have any questions, please contact the organization owner.',
    fr: 'Vous n\'avez pas accès à l\'arrière-plan. Si vous avez des questions, veuillez contacter le propriétaire de l\'organisation.',
  },
  'Você não tem acesso às configurações. Em caso de dúvidas, entre em contato com o proprietário da organização.': {
    es: 'No tienes acceso a los ajustes. Si tiene alguna pregunta, comuníquese con el propietario de la organización.',
    en: 'You do not have access to settings. If you have any questions, please contact the organization owner.',
    fr: 'Vous n\'avez pas accès aux paramètres. Si vous avez des questions, veuillez contacter le propriétaire de l\'organisation.',
  },
  'Você não tem permissão para editar um grupo de usuário.': {
    es: 'No tiene permiso para editar un grupo de usuarios.',
    en: 'You do not have permission to edit a user group.',
    fr: 'Vous n\'avez pas la permission de modifier un groupe d\'utilisateurs.',
  },
  'Você não tem permissão para criar um grupo de usuário': {
    es: 'No tiene permiso para crear un grupo de usuarios.',
    en: 'You do not have permission to create a user group.',
    fr: 'Vous n\'avez pas la permission de créer un groupe d\'utilisateurs.',
  },
  'Você não tem permissão para deletar um membro.': {
    es: 'No se le permite eliminar un miembro.',
    en: 'You are not allowed to delete a member.',
    fr: 'Vous n\'êtes pas autorisé à supprimer un membre.',
  },
  'Suas permissões foram modificadas!': {
    es: '¡Tus permisos han sido modificados!',
    en: 'Your permissions have been modified!',
    fr: 'Vos autorisations ont été modifiées!',
  },
  'Atualmente você está no grupo $0. Em caso de dúvidas, entre em contato com o proprietário da organização.': {
    es: 'Actualmente se encuentra en el grupo de $ 0. Si tiene alguna pregunta, comuníquese con el propietario de la organización.',
    en: 'You are currently in the $0 group. If you have any questions, please contact the organization owner.',
    fr: 'Vous êtes actuellement dans le groupe à $0. Si vous avez des questions, veuillez contacter le propriétaire de l\'organisation.',
  },
  'Usuário atualizado com sucesso.': {
    es: 'Usuario actualizado con éxito.',
    en: 'User updated successfully.',
    fr: 'L\'utilisateur a mis à jour avec succès.',
  },
  'Erro para atualizar usuário.': {
    es: 'Error al actualizar usuario.',
    en: 'Error updating user..',
    fr: 'Erreur mise à jour de l\'utilisateur ..',
  },
  'grupo: ': {
    es: 'grupo: ',
    en: 'group: ',
    fr: 'groupe:',
  },
  'Permissões para exclusão de documentos': {
    es: 'Permisos para borrar documentos',
    en: 'Permissions to delete documents',
    fr: 'Permissions pour supprimer des documents',
  },
  'Permissões de acesso gerais': {
    es: 'Permisos generales de acceso',
    en: 'General access permissions',
    fr: 'Autorisations d\'accès général',
  },
  'Ainda precisa de ajuda?': {
    es: '¿Todavía necesitas ayuda?',
    en: 'Do you still need help?',
    fr: 'Besoin d\'aide ?',
  },
  'Sim, preciso de ajuda': {
    es: 'Si, necesito ayuda',
    en: 'Yes, I need help',
    fr: 'Oui, j\'ai besoin d\'aide',
  },
  'Dúvida resolvida': {
    es: 'Duda resuelta',
    en: 'Resolved doubt',
    fr: 'Doute résolu',
  },
  'Ajuda: Como funcionam modelos e forms?': {
    es: 'Ayuda: ¿Cómo funcionan las plantillas y los formularios?',
    en: 'Help: How do templates and forms work?',
    fr: 'AIDE: Comment fonctionnent vos modèles ?',
  },
  'Os modelos e forms servem para criar contratos automaticamente na ZapSign, sem que você precise preencher em seu computador e subir posteriormente.': {
    es: 'Las plantillas y formularios se utilizan para crear contratos automáticamente en ZapSign, sin que tengas que completarlos en tu computadora y cargarlos más tarde.',
    en: 'The templates and forms are used to automatically create contracts on ZapSign, without you having to fill them in on your computer and upload them later.',
    fr: 'Les modèles et les formulaires sont utilisés pour créer automatiquement des contrats sur Zapsign, sans que vous ayez à les remplir sur votre ordinateur et à les télécharger plus tard.',
  },
  'Modelos e Forms: o que são e como utilizar': {
    es: 'Plantillas y Formularios: qué son y cómo utilizarlos.',
    en: 'Templates and Forms: what they are and how to use them.',
    fr: 'Modèles et formulaires: ce qu\'ils sont et comment les utiliser.',
  },
  'Como adiciono usuários à minha conta?': {
    es: '¿Cómo agrego usuarios a mi cuenta?',
    en: 'How do I add users to my account?',
    fr: 'Comment ajouter des utilisateurs à mon compte?',
  },
  'Adicione o e-mail da pessoa no campo abaixo e será enviado ao e-mail dela as instruções para criação de login e senha.': {
    es: 'Agrega el correo electrónico de la persona en el campo a continuación y se le enviarán las instrucciones para crear una cuenta y contraseña.',
    en: 'Add the person\'s email in the field below and instructions for creating a login and password will be sent to their email.',
    fr: 'Ajoutez l\'e-mail de la personne dans le domaine ci-dessous et les instructions pour créer une connexion et un mot de passe seront envoyées à leur e-mail.',
  },
  'Como adicionar um novo usuário?': {
    es: '¿Cómo agregar un nuevo usuario?',
    en: 'How to add a new user?',
    fr: 'Comment ajouter un nouvel utilisateur?',
  },
  'Como tenho a segunda via do boleto ou troco cartão?': {
    es: '¿Cómo obtengo un duplicado de billete o cambio de tarjeta?',
    en: 'How do I get a duplicate ticket or change my card?',
    fr: 'Comment obtenir un billet en double ou changer ma carte?',
  },
  'Toque no botão ao lado "Gerenciar Plano" e role a página até a opçao "Pagar com boleto" para emitir a segunda via, ou "Pagar com cartão" se quiser pagar com um novo cartão de crédito.': {
    es: 'Agrega el correo electrónico de la persona en el campo a continuación y se le enviarán instrucciones para crear una cuenta y contraseña a su correo electrónico.',
    en: 'Touch the button next to \'Manage Plan\' and scroll down to the option \'Pay with boleto\' to issue a duplicate, or \'Pay with card\' if you want to pay with a new credit card.',
    fr: 'Appuyez sur le bouton à côté de «Gérer le plan» et faites défiler jusqu\'à l\'option «Payer avec Boleto» pour émettre un double, ou «payer avec carte» si vous souhaitez payer avec une nouvelle carte de crédit.',
  },
  'Onde posso encontrar os boletos e notas fiscais do meu plano?': {
    es: '¿Dónde puedo encontrar los comprobantes y facturas de mi plan?',
    en: 'Where can I find my plan\'s slips and invoices?',
    fr: 'Où puis-je trouver les bordereaux et les factures de mon plan?',
  },
  'Como funcionam os contatos?': {
    es: '¿Cómo funcionan los contactos?',
    en: 'How do contacts work?',
    fr: 'Comment fonctionnent les contacts?',
  },
  'Ao inserir um signatário em algum documento, a ZapSign salva as informações desta pessoa em uma lista para que, em uma próxima oportunidade, você as tenha facilmente em mãos e não precise preenchê-las novamente.': {
    es: 'Al adicionar un firmante en un documento, ZapSign guarda la información de esta persona en una lista para que, en la próxima oportunidad, la tengas fácilmente a mano y no tengas que volver a diligenciarla.',
    en: 'When inserting a signer into a document, ZapSign saves this person\'s information in a list so that, at the next opportunity, you have it easily at hand and don\'t have to fill it out again.',
    fr: 'Lors de l\'insertion d\'un signataire dans un document, Zapsign enregistre les informations de cette personne dans une liste afin que, à la prochaine opportunité, vous l\'avez facilement à portée de main et que vous n\'avez pas à le remplir à nouveau.',
  },
  'Função \'Contatos': {
    es: 'Función "Contactos"',
    en: '"Contacts" function',
    fr: 'Fonction "Contacts"',
  },
  'Como funcionam os relatórios?': {
    es: '¿Cómo funcionan los contactos?',
    en: 'How do reports work?',
    fr: 'Comment fonctionnent les rapports?',
  },
  'Utilize os Relatórios para obter um monitoramento de sua conta e filtre os documentos criados por usuários, período e tempo de assinatura.': {
    es: 'Al insertar un firmante en un documento, ZapSign guarda la información de esta persona en una lista para que, en la próxima oportunidad, la tengas fácilmente a mano y no tengas que volver a diligenciarla.',
    en: 'When inserting a signer into a document, ZapSign saves this person\'s information in a list so that, at the next opportunity, you have it easily at hand and don\'t have to fill it out again.',
    fr: 'Lors de l\'insertion d\'un signataire dans un document, Zapsign enregistre les informations de cette personne dans une liste afin que, à la prochaine opportunité, vous l\'avez facilement à portée de main et que vous n\'avez pas à le remplir à nouveau.',
  },
  'Função "Relatórios"': {
    es: 'Función "Informes"',
    en: '"Reports" function',
    fr: 'Fonction "Rapports"',
  },
  'Para quê serve a seção "Minhas assinaturas"?': {
    es: '¿Para qué sirve la sección "Mis suscripciones"?',
    en: 'What is the "My Signatures" section for?',
    fr: 'À quoi sert la section "mes abonnements"?',
  },
  'Nesta seção, ficarão todos os documentos que você já assinou e também os que estão com sua assinatura pendente. Atenção: só aparecerão aqui os documentos que o seu E-MAIL CADASTRADO foi adicionado como signatário.': {
    es: 'En este apartado estarán todos los documentos que ya has firmado y también los que están pendientes de firmar. Atención: aquí solo aparecerán los documentos a los que se agregó su CORREO ELECTRÓNICO REGISTRADO como firmante.',
    en: 'In this section will be all the documents that you have already signed and also those that are pending signature. Attention: only documents to which your REGISTERED E-MAIL was added as a signatory will appear here.',
    fr: 'Dans cette section, tous les documents que vous avez déjà signés et aussi ceux qui sont en attente de signature. ATTENTION: Seuls les documents auxquels votre e-mail enregistré a été ajouté en tant que signataire apparaîtra ici.',
  },
  'Função "Minhas assinaturas"': {
    es: 'Función "Mis firmas"',
    en: '"My Signatures" function',
    fr: 'Fonction "mes abonnements"',
  },
  'Como subir um novo documento?': {
    es: '¿Cómo subir un nuevo documento?',
    en: 'How to upload a new document?',
    fr: 'Comment télécharger un nouveau document?',
  },
  'Suba os arquivos Word ou PDF que serão assinados. Depois, insira o nome das pessoas que irão assinar o documento. Se você é um dos signatários, use o botão "+ Eu vou assinar". Os métodos de autenticação avançados são todos OPCIONAIS.': {
    es: 'Carga los archivos de Word o PDF que se firmarán. Luego ingresa el nombre de las personas que firmarán el documento. Si eres uno de los firmantes, utiliza el botón "+ Firmaré". Los métodos de autenticación avanzada son todos OPCIONALES.',
    en: 'Upload the Word or PDF files that will be signed. Then enter the name of the people who will sign the document. If you are one of the signers, use the "+ I will sign" button. Advanced authentication methods are all OPTIONAL.',
    fr: 'Téléchargez les fichiers Word ou PDF qui seront signés. Entrez ensuite le nom des personnes qui signeront le document. Si vous êtes l\'un des signataires, utilisez le bouton "+ je signerai". Les méthodes d\'authentification avancées sont toutes facultatives.',
  },
  'Como posicionar assinaturas?': {
    es: '¿Cómo colocar las firmas?',
    en: 'How to position signatures?',
    fr: 'Comment positionner les signatures?',
  },
  'Clique no seu documento abaixo e irão aparecer as opções de posicionar assinaturas, vistos e textos.': {
    es: 'Haz clic en el documento y se mostrarán las opciones para posicionar firmas, iniciales y textos.',
    en: 'Click on your document below and the options for placing signatures, initials and texts will appear.',
    fr: 'Cliquez sur votre document ci-dessous et les options de placement des signatures, des chèques et des textes apparaîtront.',
  },
  'Como solicitar assinaturas?': {
    es: '¿Cómo solicitar firmas?',
    en: 'How to request Signatures?',
    fr: 'Comment demander des abonnements?',
  },
  'Compartilhe o link de assinatura com os signatários usando o botão de copiar/colar, ou compartilhe por WhatsApp ou e-mail. Somente depois, clique em "Finalizar".': {
    es: 'Comparte el enlace de la firma con los firmantes usando el botón copiar/pegar, o comparte a través de WhatsApp o correo electrónico.',
    en: 'Share the document link with signers using the copy/paste button, or share it via WhatsApp or email.',
    fr: 'Partagez le lien de signature avec les signataires à l\'aide du bouton Copier / Coller ou partager via WhatsApp ou Email.',
  },
  'Como enviar um documento para assinatura?': {
    es: '¿Cómo enviar un documento para firma?',
    en: 'How to send a document for signature?',
    fr: 'Comment envoyer un document pour signature?',
  },
  'Como posicionar assinatura e vistos no documento?': {
    es: '¿Cómo posicionar firma e iniciales en el documento?',
    en: 'How to position signature and initials in the document?',
    fr: 'Comment positionner la signature et les visas dans le document?',
  },
  'Saiba mais sobre em nosso material': {
    es: 'Obtén más información en nuestro artículo',
    en: 'Learn more about this feature in our article',
    fr: 'En savoir plus sur notre matériel',
  },
  'da Central de ajuda.': {
    es: 'del Centro de ayuda.',
    en: 'from the Help Center.',
    fr: 'du centre d\'aide.',
  },
  'https://clients.zapsign.com.br/help': {
    es: 'https://clients.zapsign.com.br/es/help',
    en: 'https://clients.zapsign.com.br/en/help',
    fr: 'https://clients.zapsign.com.br/en/help',
  },
  'https://clients.zapsign.com.br/help/modelos-o-que-s%C3%A3o-e-como-usar': {
    es: 'https://clients.zapsign.com.br/es/help/plantillas-que-son-e-como-utilizar',
    en: 'https://clients.zapsign.com.br/en/help/templates',
    fr: 'https://clients.zapsign.com.br/en/help/templates',
  },
  'https://clients.zapsign.com.br/help/como-adicionar': {
    es: 'https://clients.zapsign.com.br/es/help/c%C3%B3mo-agregar-usuarios-a-mi-cuenta',
    en: 'https://clients.zapsign.com.br/en/help/how-to-add-a-new-user',
    fr: 'https://clients.zapsign.com.br/en/help/how-to-add-a-new-user',
  },
  'https://clients.zapsign.com.br/help/onde-posso-encontrar-os-boletos-e-notas-fiscais-do-meu-plano': {
    es: 'https://clients.zapsign.com.br/es/help/planes-y-pagos',
    en: 'https://clients.zapsign.com.br/en/help/plans-and-payments',
    fr: 'https://clients.zapsign.com.br/en/help/plans-and-payments',
  },
  'https://clients.zapsign.com.br/help/%C3%A9-poss%C3%ADvel-salvar-contatos-na-plataforma': {
    es: 'https://clients.zapsign.com.br/es/help/funci%C3%B3n-contactos',
    en: 'https://clients.zapsign.com.br/en/help/is-it-possible-to-save-contacts-on-the-platform',
    fr: 'https://clients.zapsign.com.br/en/help/is-it-possible-to-save-ntacts-on-the-platform',
  },
  'https://clients.zapsign.com.br/help/fun%C3%A7%C3%A3o-relat%C3%B3rios': {
    es: 'https://clients.zapsign.com.br/es/help/herramienta-informes',
    en: 'https://clients.zapsign.com.br/en/help/reports-function',
    fr: 'https://clients.zapsign.com.br/en/help/reports-function',
  },
  'https://clients.zapsign.com.br/help/fun%C3%A7%C3%A3o-minhas-assinaturas': {
    es: 'https://clients.zapsign.com.br/es/help/funci%C3%B3n-mis-firmas',
    en: 'https://clients.zapsign.com.br/en/help/my-signatures-function',
    fr: 'https://clients.zapsign.com.br/en/help/my-signatures-function',
  },
  'https://clients.zapsign.com.br/help/como-solicitar-assinaturas': {
    es: 'https://clients.zapsign.com.br/es/help/como-solicitar-firmas',
    en: 'https://clients.zapsign.com.br/en/help/how-to-request-signatures',
    fr: 'https://clients.zapsign.com.br/en/help/how-to-request-signatures',
  },
  'https://clients.zapsign.com.br/help/como-posicionar-assinaturas': {
    es: 'https://clients.zapsign.com.br/es/help/c%C3%B3mo-posicionar-firmas-y-iniciales-en-el-documento',
    en: 'https://clients.zapsign.com.br/en/help/how-to-positionate-signatures',
    fr: 'https://clients.zapsign.com.br/en/help/how-to-poseate-signatures',
  },
  'Alterar pasta': {
    es: 'Cambiar carpeta',
    en: 'Change folder',
    fr: 'Changer de dossier',
  },
  'Alterar plano': {
    es: 'Cambiar plan',
    en: 'Change plan',
    fr: 'Plan de changement',
  },
  '$0 de $1 usuários': {
    es: '$0 de $1 usuarios',
    en: '$0 out of $1 users',
    fr: '$0 sur $1 utilisateurs',
  },
  '$0 de $1 documentos': {
    es: '$0 de $1 documentos',
    en: '$0 out of $1 documents',
    fr: '$0 sur les documents de $1',
  },
  '$0 documento$1 criado$1': {
    es: '$0 documento$1 creado$1',
    en: '$0 document$1 created',
    fr: '$0 Document $1 Créé',
  },
  'Usuários na organização': {
    es: 'Usuarios de la organización',
    en: 'Organization users',
    fr: 'Utilisateurs de l\'organisation',
  },
  'Criação de documentos': {
    es: 'Documentos creados',
    en: 'Created documents',
    fr: 'Documents créés',
  },
  'Cancelar assinatura': {
    es: 'Cancelar',
    en: 'Cancel',
    fr: 'Annuler',
  },
  'Notas Fiscais emitidas': {
    es: 'Notas fiscales emitidas',
    en: 'Issued invoices',
    fr: 'Factures émises',
  },
  'Erro: Sua assinatura em tela está vazia, por favor utilize um método de assinatura diferente. Ou atualize a tela e tente novamente': {
    es: 'Error: Tu firma está vacía, utiliza un método de firma diferente o actualiza la pantalla e inténtalo de nuevo',
    en: 'Error: Your screen signature is empty, please use a different signature method. Or refresh the screen and try again',
    fr: 'Erreur: votre signature d\'écran est vide, veuillez utiliser une autre méthode de signature. Ou rafraîchir l\'écran et réessayer',
  },
  'Você pode selecionar onde os documentos serão criados': {
    es: 'Puedes seleccionar dónde guardar los documentos',
    en: 'You can choose where to save documents',
    fr: 'Vous pouvez sélectionner où des documents seront créés',
  },
  Pastas: {
    es: 'Carpetas',
    en: 'Folders',
    fr: 'Dossiers',
  },
  'Salve em uma pasta já criada ou crie uma nova': {
    es: 'Guardar en una carpeta creada o crear una nueva',
    en: 'Save in an existing folder or create a new one',
    fr: 'Enregistrer dans un dossier existant ou en créer un nouveau',
  },
  'Você não pode ter apenas espaços no campo acima': {
    es: 'No puedes dejar vacio este campo',
    en: 'You can\'t just have spaces in the field above',
    fr: 'Vous ne pouvez pas simplement avoir des espaces sur le terrain ci-dessus',
  },
  'Saiba mais sobre autenticação avançada': {
    es: 'Más información sobre la autenticación avanzada',
    en: 'Learn more about advanced authentication',
    fr: 'En savoir plus sur l\'authentification avancée',
  },
  'Recusar assinatura': {
    es: 'Rechazar',
    en: 'Decline',
    fr: 'Refuser',
  },
  'Recuse assinatura se não deseja assinar este documento. Comunicaremos sua decisão ao remetente': {
    es: 'Rechaza firmar el documento. Comunicaremos tu decisión al remitente',
    en: 'Decline signature if you do not wish to sign this document. We will communicate your decision to the sender',
    fr: 'Débrancher la signature si vous ne souhaitez pas signer ce document. Nous communiquerons votre décision à l\'expéditeur',
  },
  'Sim, rejeitar': {
    es: 'Sí, rechazar',
    en: 'Yes, refuse',
    fr: 'Oui, refuser',
  },
  'Não, voltar': {
    es: 'No, volver',
    en: 'No',
    fr: 'Non',
  },
  'Plano Essencial': {
    es: 'Plan Esencial',
    en: 'Essential Plan',
    fr: 'Plan essentiel',
  },
  'Integrações via Pluga (Recomendado)': {
    es: 'Integraciones a través de Pluga (Recomendado)',
    en: 'Pluga´s Integrations (Recommended)',
    fr: 'Les intégrations de Pluga (recommandées)',
  },
  'Integrações via Make': {
    es: 'Integraciones a través de Make',
    en: 'Make´s Integrations',
    fr: 'Faire des intégrations',
  },
  'Vincular com': {
    es: 'Vincular con',
    en: 'Bind with',
    fr: 'Se lier avec',
  },
  'Aplicativos disponíveis': {
    es: 'Aplicaciones disponibles',
    en: 'Available apps',
    fr: 'Applications disponibles',
  },
  'Armazenamento e documentos': {
    es: 'Almacenamiento y documentos',
    en: 'Storage and documents',
    fr: 'Stockage et documents',
  },
  'Vendas e CRM': {
    es: 'Ventas y CRM',
    en: 'Sales and CRM',
    fr: 'Ventes et gestion de la relation client',
  },
  'Salve uma cópia em uma plataforma de nuvem quando um documento é assinado. Selecione a plataforma que você deseja vincular à sua conta ZapSign.': {
    es: 'Guarda una copia en una plataforma en la nube cuando un docuemento es firmado.  Haz clic sobre la plataforma que quieres vincular a tu cuenta de ZapSign.',
    en: 'Save a copy on a cloud platform when a document is signed. Click on the platform you want to link to your ZapSign account.',
    fr: 'Enregistrez une copie sur une plate-forme cloud lorsqu\'un document est signé. Cliquez sur la plate-forme que vous souhaitez lier vers votre compte Zapsign.',
  },
  'Automatize a criação e envio de documentos com informações de contato do CRM.': {
    es: 'Automatiza la creación y envío de documentos con información de contactos del CRM.',
    en: 'Automate the creation and sending of documents with CRM contact information.',
    fr: 'Automatisez la création et l\'envoi de documents avec les coordonnées CRM.',
  },
  'Encontre mais plataformas para integrar de acordo com as necessidades da sua empresa. Integre a ZapSign com plataformas de e-commerce, sites, ERP e muito mais.': {
    es: 'Encuentra más plataformas para integrar según las necesidades de tu empresa. Integra ZapSign con plataformas de e-commerce, páginas web, ERP y mucho más.',
    en: 'Find more platforms to integrate according to the needs of your company. Integrate ZapSign with e-commerce platforms, websites, ERP and much more.',
    fr: 'Trouvez plus de plateformes à intégrer en fonction des besoins de votre entreprise. Intégrez ZapSign avec des plates-formes de commerce électronique, des sites Web, ERP et bien plus encore.',
  },
  'Aplicativos prontos para usar': {
    es: 'Aplicaciones nativas',
    en: 'Native applications',
    fr: 'Applications natives',
  },
  'Integrações nativas com as seguintes plataformas. Basta instalar a ZapSign e começar!': {
    es: 'Integraciones nativas con las siguientes plataformas. ¡Sólo debes instalar ZapSign y empezar!.',
    en: 'Native integrations with the following platforms. Just install ZapSign and get started!',
    fr: 'Intégrations natives avec les plateformes suivantes. Installez simplement ZapSign et vous êtes prêt à partir.',
  },
  'No CRM Hubspot de cada empresa, crie documentos e compartilhe-os para coletar assinaturas.': {
    es: 'En el CRM Hubspot dentro de cada negocio, crea documentos y compartelos para recolectar firmas.',
    en: 'In the Hubspot CRM within each deal, create documents and share them to collect signatures.',
    fr: 'Dans le CRM HubSpot de chaque entreprise, créez des documents et partagez-les pour recueillir des signaturesIntégration de l\'application',
  },
  'Nos fluxos automatizados de WhatsApp você pode incluir a ZapSign para assinar documentos ou templates.': {
    es: 'En los flujos automatizados de WhatsApp puedes incluir a ZapSign para que firmen documento o plantillas.',
    en: 'In the automated WhatsApp flows you can include ZapSign to sign documents or templates.',
    fr: 'Dans nos flux WhatsApp automatisés, vous pouvez inclure ZapSign pour signer des documents ou des modèle ',
  },
  'Na plataforma IXC para provedores de telecomunicações, crie documentos e compartilhe-os para coletar assinaturas.': {
    es: 'En la plataforma IXC para proveedores de telecomunicaciones, crea documentos y comparteles para recolectar firmas.',
    en: 'On the IXC platform for telecom providers, create documents and share them to collect signatures.',
    fr: 'Sur la plateforme IXC pour les fournisseurs de télécommunications, créez des documents et partagez-les pour recueillir des signaturesIntégration de l\'application',
  },
  'Instalar aplicativo': {
    es: 'Instalar aplicación',
    en: 'Install appication',
    fr: 'Intégrer l\'application',
  },
  'Imagem do Logotipo da Zapsign com ondas azuis de fundo e uma frase sobre a marca': {
    es: 'Imagen del Logo de Zapsign con ondas azules de fondo y una frase sobre la marca',
    en: 'Image of the Zapsign Logo with blue waves in the background and a phrase about the brand',
    fr: 'Image du logo ZapSign avec des vagues bleues en arrière-plan et une phrase sur la marque',
  },
  'Selecionar documento': {
    es: 'Selecciona el documento',
    en: 'Select document',
    fr: 'Sélectionner le document',
  },
  'Adicionar signatários': {
    es: 'Añadir firmantes',
    en: 'Add signers',
    fr: 'Ajouter des signataires',
  },
  'Enviar documento': {
    es: 'Enviar documento',
    en: 'Send document',
    fr: 'Envoyer un document',
  },
  'Formato de Documento Portátil': {
    es: 'Formato de Documento Portable',
    en: 'Portable Document Format',
    fr: 'Portable Document Format',
  },
  'Aceitamos arquivos em formato docx ou': {
    es: 'Aceptamos archivos en formato docx o',
    en: 'We accept files in docx or',
    fr: 'Nous acceptons les fichiers dans docx',
  },
  'Pronto!': {
    es: '¡Listo!',
    en: 'Ready!',
    fr: 'Prêt!',
  },
  'Documento finalizado e aguardando assinaturas.': {
    es: 'Documento finalizado y en espera de firmas.',
    en: 'Document finalized and waiting for signatures.',
    fr: 'Document finalisé et attendant les signatures.',
  },
  'Como enviar o documento para assinatura?': {
    es: '¿Cómo enviar el documento para firma?',
    en: 'How to send the document for signature?',
    fr: 'Comment envoyer le document pour signature?',
  },
  'para copiar o link de assinatura e compartilhe com cada signatário por qualquer canal de comunicação. Você também pode compartilhar ativando o envio automático por e-mail ou WhatsApp.': {
    es: 'para copiar el enlace de firma y compartir con cada firmante a través de cualquier canal de comunicación. También puedes compartir activando el envío automático por correo electrónico o WhatsApp.',
    en: 'to copy the signature link and share it with each signer through any communication channel. You can also share it by enabling automatic email or WhatsApp delivery.',
    fr: 'pour copier le lien de signature et le partager avec chaque signataire via n\'importe quel canal de communication. Vous pouvez également le partager en activant la livraison automatique par e-mail ou WhatsApp',
  },
  'Enviar por WhatsApp': {
    es: 'Enviar por WhatsApp',
    en: 'Send via Whatsapp',
    fr: 'Envoyer via WhatsApp',
  },
  'Selo de conservação (NOM151)': {
    es: 'Sello de conservación (NOM151)',
    en: 'Constancy of Conservation (NOM151)',
    fr: 'Constance de la conservation (NOM151)',
  },
  'Para verificar a constância de conservação baixe o documento assinado e a constância. Logo após, suba os arquivos no verificador de certificação.': {
    es: 'Para verificar la constancia de conservación descarga el documento y la constancia, y sube los dos documentos en el verificador de Prestador de Servicios de Certificación.',
    en: 'To verify the constancy of conservation, download the signed document and the constancy. After that, upload the files to the certification checker.',
    fr: 'Pour vérifier la constance de la conservation, téléchargez le document signé et la constance. Après cela, téléchargez les fichiers sur le vérificateur de certification.',
  },
  'Baixar constância': {
    es: 'Descargar constancia',
    en: 'Download constancia',
    fr: 'Télécharger Constancia',
  },
  'Verificar constância': {
    es: 'Verificar constancia',
    en: 'Verify constancia',
    fr: 'Vérifiez Constancia',
  },
  'Emitido por: ': {
    es: 'Emitido por: ',
    en: 'Issued by: ',
    fr: 'Délivré par:',
  },
  'Selecionar todos': {
    es: 'Seleccionar todos',
    en: 'Select all',
    fr: 'Tout sélectionner',
  },
  Recuperar: {
    es: 'Recuperar',
    en: 'Recover',
    fr: 'Récupérer',
  },
  'Documentos excluídos com sucesso!': {
    es: '¡Documentos eliminados con éxito!',
    en: 'Documents deleted successfully!',
    fr: 'Documents supprimés avec succès!',
  },
  'Documentos recuperados com sucesso!': {
    es: '¡Documentos recuperados con éxito!',
    en: 'Documents retrieved successfully!',
    fr: 'Documents récupérés avec succès!',
  },
  'Deseja recuperar $0 documentos?': {
    es: '¿Quieres recuperar $0 documentos?',
    en: 'Do you want to recover $0 documents?',
    fr: 'Voulez-vous récupérer des documents de $0?',
  },
  'Apenas o(a) proprietário(a) da conta possui acesso aos documentos. É possível recuperar os documentos ou excluir definitivamente.': {
    es: 'Solo el propietario de la cuenta tiene acceso a los documentos. Es posible recuperar los documentos o eliminarlos definitivamente.',
    en: 'Only the account owner has access to documents. It is possible to recover the documents or permanently delete them.',
    fr: 'Seul le propriétaire du compte a accès à des documents. Il est possible de récupérer les documents ou de les supprimer définitivement.',
  },
  'Apenas o proprietário da conta pode eliminar definitivamente os documentos da lixeira.': {
    es: 'Solo el propietario de la cuenta puede eliminar definitivamente los documentos de la papelera.',
    en: 'Only the account owner has the permission to delete the documents definitely.',
    fr: 'Seul le propriétaire du compte peut supprimer définitivement les documents de la corbeille.',
  },
  'Carregando documentos excluídos...': {
    es: 'Cargando documentos eliminados...',
    en: 'Loading deleted documents...',
    fr: 'Chargement des documents supprimés ...',
  },
  'Mostrando $0 documentos': {
    es: 'Mostrando $0 documentos',
    en: 'Showing $0 documents',
    fr: 'Affichage des documents de $0',
  },
  Eliminar: {
    es: 'Eliminar',
    en: 'Remove',
    fr: 'Retirer',
  },
  'Obrigado por responder nossa pesquisa!': {
    es: '¡Gracias por participar en nuestra encuesta!',
    en: 'Thanks for taking our survey!',
    fr: 'Merci d\'avoir répondu à notre enquête!',
  },
  'Você não tem permissão para deletar um grupo de usuário.': {
    es: 'No se le permite eliminar un grupo de usuarios.',
    en: 'You are not allowed to delete a user group.',
    fr: 'Vous n\'êtes pas autorisé à supprimer un groupe d\'utilisateurs.',
  },
  'Atenção: você perderá acesso às Configurações desta conta!': {
    es: 'Atención: perderás acceso a los Ajustes de esta cuenta',
    en: 'Attention: you will lose access to the Settings of this account',
    fr: 'Attention: vous perdrez l\'accès aux paramètres de ce compte',
  },
  'Editar grupo de usuários': {
    es: 'Editar grupo de usuarios',
    en: 'Edit users group',
    fr: 'Modifier le groupe des utilisateurs',
  },
  'Compartilhe os links de assinatura abaixo.': {
    es: 'Comparte los enlaces a los firmantes.',
    en: 'Share the links below',
    fr: 'Partagez les liens ci-dessous',
  },
  'Contrate pelo seu cartão de crédito ou boleto. Cancele quando quiser e sem multas.': {
    es: 'Compra con tarjeta de crédito, cancela en cualquier momento y sin cláusula de permanencia.',
    en: 'Buy with credit card, cancel your subscription at any time and without a minimum stay clause.',
    fr: 'Achetez avec une carte de crédit, annulez votre abonnement à tout moment et sans une clause de séjour minimale.',
  },
  'Todos os nossos planos são pré-pagos, então, caso queira cancelar você ainda poderá aproveitar o período restante contratado (mensal ou anual).': {
    es: 'Todos nuestros planes son pre-pagos con renovación automática. Si cancelas un plan mensual o anual podrás seguir usando el servicio por el tiempo que queda del periodo contratado y no se cobrará el siguiente.',
    en: 'All our plans are prepaid, when you cancel a subscription you will still be able to use the platform for the time you have already paid (monthly or anually).',
    fr: 'Si vous souhaitez interrompre le renouvellement automatique, vous devez résilier votre plan avant qu\'un nouveau prélèvement ne soit effectué. En cas d\'annulation du plan de paiement par tranches annuelles, les tranches suivantes seront facturées normalement.',
  },
  'Lembre-se, você pode usar cartão de crédito ou boleto.': {
    es: 'Recuerda, puedes usar una tarjeta de crédito o débito.',
    en: 'Remember, you can use a credit or debit card.',
    fr: 'N\'oubliez pas que vous pouvez utiliser une carte de crédit ou de débit.',
  },
  'Caso queira interromper a renovação automática é só cancelar o seu plano antes do final do período. No plano anual parcelado, em caso de cancelamento, serão cobradas as parcelas subsequentes normalmente.': {
    es: 'Si quieres detener la renovación automática, cancela el plan antes de que finalice el período. En el plan de cuotas anuales, en caso de cancelación, las cuotas posteriores se cobrarán con normalidad.',
    en: 'If you want to stop automatic renewal, just cancel your plan before the end of the period. In the annual installment plan, in case of cancellation, the subsequent installments will be charged normally.',
    fr: 'Si vous souhaitez arrêter le renouvellement automatique, annulez simplement votre plan avant la fin de la période. Dans le plan de versement annuel, en cas d\'annulation, les versements ultérieurs seront facturés normalement.',
  },
  'veja mais': {
    es: 'ver más',
    en: 'more',
    fr: 'plus',
  },
  'ver menos': {
    es: 'ver menos',
    en: 'see less',
    fr: 'Voir moins',
  },
  'Possui um cupom?': {
    es: '¿Tiene un cupón?',
    en: 'Have a coupon?',
    fr: 'Avez-vous un coupon?',
  },
  'Autenticação padrão': {
    es: 'Autenticación estándar',
    en: 'Standard authentication',
    fr: 'Authentification standard',
  },
  'Foto do documento de identidade': {
    es: 'Foto del documento de identidad',
    en: 'Identity Document Photo',
    fr: 'Photo de Document d\'Identité',
  },
  'Qual usar?': {
    es: '¿Cuál usar?',
    en: 'Which one to use?',
    fr: 'Lequel utiliser?',
  },
  'Assinatura na tela: Assinatura padrão manuscrita ou digitada feita pelo celular ou computador': {
    es: 'Firma manuscrita realizada en la pantalla del celular o computador',
    en: 'Handwritten signature made on the phone or computer screen',
    fr: 'Signature manuscrite réalisée sur l\'écran du téléphone ou de l\'ordinateur',
  },
  'Certificado digital: O signatário precisa ter um certificado A1 ou A3 para assinar': {
    es: 'El firmante necesita tener un certificado A1 o A3 para firmar',
    en: 'The signer needs to have an A1 or A3 certificate to sign',
    fr: 'e signataire doit avoir un certificat A1 ou A3 à signer',
  },
  'Código por e-mail: Enviaremos um código de confirmação para o e-mail do signatário. Ele deverá inserir o código ao assinar o documento. Quanto mais autenticações avançadas você usar, maior será sua segurança jurídica!': {
    es: 'Enviaremos un código de confirmación al correo del firmante. Debe introducir el código al firmar el documento. ¡Cuantas más autenticaciones avanzadas utilice, mayor será su seguridad jurídica!',
    en: 'We will send a confirmation code to the signer\'s email. He must enter the code when signing the document. The more advanced authentications you use, the greater your legal security!',
    fr: 'Nous enverrons un code de confirmation à l\'e-mail du signataire. Il doit entrer le code lors de la signature du document. Plus vous utilisez d\'authentifications avancées, plus votre sécurité juridique est grande!',
  },
  'Código por Whatsapp: Enviaremos um código de confirmação para o Whatsapp do signatário. Ele deverá inserir o código ao assinar o documento. Quanto mais autenticações avançadas você usar, maior será sua segurança jurídica!': {
    es: 'Enviaremos un código de confirmación al WhatsApp del firmante. Deberá ingresar el código al momento de firmar el documento. ¡Cuanto más avanzadas sean las autenticaciones que utilice, mayor será su seguridad jurídica!',
    en: 'We will send a confirmation code to the signatory\'s WhatsApp. They will need to enter the code when signing the document. The more advanced authentications you use, the higher your legal security will be!',
    fr: 'Nous enverrons un code de confirmation au WhatsApp du signataire. Ils devront entrer le code lors de la signature du document. Plus vous utilisez d\'authentifications avancées, plus votre sécurité juridique sera élevée!',
  },
  'Selfie: Pedir que o signatário tire uma selfie (foto do rosto) ao assinar. A foto constará no relatório de assinaturas como um dos pontos de autenticação. Quanto mais autenticações avançadas você usar, maior será sua segurança jurídica!': {
    es: 'El firmante debe tomar una foto de su rostro para firmar (ZapSign no válida la foto). Esta foto quedará en el informe de firmas. ¡Cuantos más métodos avanzados uses, mayor será la seguridad jurídica!',
    en: 'The signer must take a photo of his/her face to sign (ZapSign does not validate the photo). This photo will be in the signature report. The more advanced methods you use, the greater the legal security!',
    fr: 'Le signataire doit prendre une photo de son visage pour signer (Zapsign ne valide pas la photo). Cette photo sera dans le rapport de signature. Plus vous utilisez de méthodes plus avancées, plus la sécurité juridique est grande!',
  },
  'Reconhecimento facial: Solicitar para que o signatário grave um vídeo seguindo as instruções de “vire o rosto”. Deverá também fornecer fotos frente e verso de seu RG ou CNH. O rosto filmado será comparado com a foto do documento oficial': {
    es: 'El firmante debe tomar una foto de su rostro y del documento de identidad por ambos lados. Se compara la foto del rostro con la del documento para poder firmar. Estas fotos quedarán en el informe de firmas. Disponible para México, Colombia y Brasil.',
    en: 'The signer must take a photo of his/her face and the ID card on both sides. The photo of the face is compared with the photo of the document to be able to sign. These photos will remain in the signature report.Available for Mexico, Colombia and Brazil.',
    fr: 'Le signataire doit prendre une photo de son visage et de la carte d\'identité des deux côtés. La photo du visage est comparée à la photo du document pour pouvoir signer. Ces photos resteront dans le rapport de signature. Sellable pour le Mexique, la Colombie et le Brésil.',
  },
  'Documento de identidade: Pedir para que o signatário tire uma foto do documento de identidade (ex RG, CNH) ao assinar. Constará no relatório de assinaturas como um dos pontos de autenticação. Quanto mais autenticações avançadas você usar, maior será sua segurança jurídica!': {
    es: 'El firmante debe tomar una foto al documento de identidad por ambos lados para firmar (ZapSign no válida la foto). Esta foto quedará en el informe de firmas. ¡Cuantos más métodos avanzados uses, mayor será la seguridad jurídica!',
    en: 'The signer must take a photo of the identity card on both sides to sign (ZapSign does not validate the photo). This photo will be in the signature report. The more advanced methods you use, the greater the legal security!',
    fr: 'Le signataire doit prendre une photo de la carte d\'identité des deux côtés à signer (Zapsign ne valide pas la photo). Cette photo sera dans le rapport de signature. Plus vous utilisez de méthodes plus avancées, plus la sécurité juridique est grande!',
  },
  'CPF: O número do CPF será solicitado ao signatário e validado junto à receita federal. Constará no relatório de assinaturas como um dos pontos de autenticação. Quanto mais autenticações avançadas você usar, maior será sua segurança jurídica!': {
    es: 'El número de CPF será solicitado al firmante y validado con el Servicio de Ingresos Federales. Aparecerá en el informe de firma como uno de los puntos de autenticación. ¡Cuanto más autenticación avanzada utilice, mayor será tu seguridad jurídica!',
    en: 'The CPF number will be requested from the signatory and validated with the Federal Revenue Service. It will appear in the signature report as one of the points of authentication. The more advanced authentication you use, the greater your legal security!',
    fr: 'Le numéro CPF sera demandé au signataire et validé avec le Federal Revenue Service. Il apparaîtra dans le rapport de signature comme l\'un des points d\'authentification. Plus vous utilisez d\'authentification avancée, plus votre sécurité juridique est grande!',
  },
  'Nome da variável': {
    es: 'Nombre de la variable',
    en: 'Variable name',
    fr: 'Nom de variable',
  },
  'Inserir nome do campo': {
    es: 'Insertar nombre de campo',
    en: 'Insert field name',
    fr: 'Insérer le nom du champ',
  },
  'Por favor, o título do campo': {
    es: 'Título del campo por favor',
    en: 'Field title please',
    fr: 'Titre sur le terrain s\'il vous plaît',
  },
  'Configuração do campo': {
    es: 'Configuración de campo',
    en: 'Field settings',
    fr: 'Paramètres de champ',
  },
  'Editar variável': {
    en: 'Edit variable',
    es: 'Editar variable',
    fr: 'Variable EDITA',
  },
  'Mais Filtros': {
    es: 'Más filtros',
    en: 'More Filters',
    fr: 'Plus de filtres',
  },
  'Menos Filtros': {
    es: 'Menos filtros',
    en: 'Less Filters',
    fr: 'Moins de filtres',
  },
  'Aguarde mais 10 segundos enquanto suas fotos são carregadas': {
    es: 'Espera 10 segundos más mientras se cargan las fotos',
    en: 'Please wait more 10 seconds while your photos are uploading',
    fr: 'Veuillez patienter plus de 10 secondes pendant que vos photos téléchargement',
  },
  'Por favor, preencha o e-mail do signatário': {
    es: 'Por favor escribe el correo electrónico del firmante',
    en: 'Please complete the signer\'s email',
    fr: 'Veuillez remplir l\'e-mail du signataire',
  },
  'Data assinatura': {
    es: 'Fecha de firma',
    en: 'Signature date',
    fr: 'Date de la signature',
  },
  'Por favor, preencha o método de autenticação padrão de todos signatários': {
    es: 'Por favor define el método de autenticación estándar de todos firmantes',
    en: 'Please fill in the standard authentication method for all signers',
    fr: 'Veuillez remplir la méthode d\'authentification standard pour tous les signataires',
  },
  'Impedir que signatários baixem o documento original': {
    es: 'Evitar descargar el documento original',
    en: 'Prevent signers from downloading the original document',
    fr: 'Empêcher les signataires de télécharger le document original',
  },
  'Código por SMS: Enviaremos um código de confirmação para o número de celular do signatário. Ele deverá inserir o código ao assinar o documento.Quanto mais autenticações avançadas você usar, maior será sua segurança jurídica!': {
    es: 'Enviaremos un código de confirmación para el número de celular del firmante. Debe introducir el código al firmar el documento. !Cuantas más autenticaciones avanzadas utilices, mayor será tu seguridad jurídica!',
    en: 'Cell phone number. He/She must enter the code when signing the document. The more advanced authentications you use, the greater your legal security!',
    fr: 'numéro de téléphone portable. Il / elle doit entrer le code lors de la signature du document. Plus vous utilisez d\'authentifications avancées, plus votre sécurité juridique est grande!',
  },
  'https://clients.zapsign.com.br/help/m%C3%A9todos-de-autentica%C3%A7%C3%A3o': {
    es: 'https://clients.zapsign.com.br/es/help/m%C3%A9todos-de-autenticaci%C3%B3n-qu%C3%A9-son',
    en: 'https://clients.zapsign.com.br/en/help/authentication-methods-what-are-they',
    fr: 'https://clients.zapsign.com.br/en/help/authentication-methods-what-are- ils',
  },
  assinado: {
    es: 'firmado',
    en: 'signed',
    fr: 'signé',
  },
  'Não foi possivel deletar esses modelos. Tente atualizar a tela.': {
    es: 'No se pueden eliminar estas plantillas. Intenta actualizar la pantalla.',
    en: 'Unable to delete these templates. Try refreshing the screen.',
    fr: 'Impossible de supprimer ces modèles. Essayez de rafraîchir l\'écran.',
  },
  'Modelos deletados com sucesso.': {
    es: 'Plantillas eliminadas con éxito.',
    en: 'Templates deleted successfully.',
    fr: 'Modèles supprimés avec succès.',
  },
  'Não foi possível mover os documentos': {
    es: 'No se pueden mover documentos',
    en: 'Unable to move documents',
    fr: 'Impossible de déplacer des documents',
  },
  'Não foi possível mover os modelos': {
    es: 'No se pueden mover las plantillas',
    en: 'Unable to move templates',
    fr: 'Impossible de déplacer des modèles',
  },
  'modelo selecionado': {
    es: 'plantilla seleccionada',
    en: 'selected template',
    fr: 'modèle sélectionné',
  },
  'modelos selecionados': {
    es: 'plantillas seleccionadas',
    en: 'selected templates',
    fr: 'modèles sélectionnés',
  },
  'Mostrando $0 modelo$1': {
    es: 'Mostrando $0 plantilla',
    en: 'Showing $0 template',
    fr: 'Affichage du modèle de $0',
  },
  De: {
    es: 'Desde',
    en: 'From',
    fr: 'Depuis',
  },
  Até: {
    es: 'Hasta',
    en: 'Until',
    fr: 'Jusqu\'à',
  },

  'Duplicar campo': {
    es: 'Campo duplicado',
    en: 'Duplicate field',
    fr: 'Champ en double',
  },
  'Adicionar signatário': {
    es: 'Añadir firmante',
    en: 'Add signer',
    fr: 'Ajouter le signataire',
  },
  'Posicionar assinaturas': {
    es: 'Posicionar firmas',
    en: 'Position signatures',
    fr: 'Signatures de position',
  },
  'É necessário realizar o reconhecimento facial antes de continuar': {
    es: 'Se requiere reconocimiento facial',
    en: 'Facial recognition is required',
    fr: 'La reconnaissance faciale est requise',
  },
  'Abrindo a câmera. Aguarde um instante...': {
    es: 'Abriendo la cámara. Por favor, espere un momento...',
    en: 'Opening the camera. Please wait a moment...',
    fr: 'Ouverture de l\'appareil photo. Veuillez patienter un instant...',
  },
  'Seu dispositivo não tem nenhuma câmera. Acesse o QR code abaixo para continuarmos o processo': {
    es: 'Tu dispositivo no tiene ninguna cámara. Accede al código QR a continuación para continuar con el proceso',
    en: 'Your device does not have any cameras. Access the QR code below to continue the process',
    fr: 'Votre appareil n\'a pas de caméras. Accéder au code QR ci-dessous pour poursuivre le processus',
  },
  'Todos os contatos': {
    es: 'Todos los contactos',
    en: 'All contacts',
    fr: 'Tous les contacts',
  },
  'Grupos de contatos': {
    es: 'Grupos de contactos',
    en: 'Contact groups',
    fr: 'Groupes de contact',
  },
  'Novo grupo de contatos': {
    es: 'Nuevo grupo de contacto',
    en: 'New contact group',
    fr: 'Nouveau groupe de contacts',
  },
  'Editar grupo de contatos': {
    es: 'Editar grupo de contactos',
    en: 'Edit contact groups',
    fr: 'Modifier le groupe de contacts',
  },
  'Contatos do grupo': {
    es: 'Contactos del grupo',
    en: 'Group contacts',
    fr: 'Contacts de groupe',
  },
  'Parece que você ainda não possui nenhum grupo de contatos definido.': {
    es: 'Parece que todavía no tienes ningún grupo de contactos definido.',
    en: 'It looks like you don\'t have any contact groups defined yet.',
    fr: 'Il semble que vous n\'ayez pas encore de groupes de contact définis.',
  },
  'Tem certeza que deseja excluir este grupo? Essa ação não poderá ser desfeita. Os contatos continuarão salvos individualmente, porém o grupo será excluido.': {
    es: '¿Estás seguro de eliminar este grupo? Esta acción no se puede deshacer. Los contactos se seguirán guardando individualmente, pero el grupo se eliminará.',
    en: 'Are you sure you want to delete this group? This action cannot be undone. Contacts will continue to be saved individually, but the group will be deleted.',
    fr: 'Êtes-vous sûr de vouloir supprimer ce groupe? Cette action ne peut pas être annulée. Les contacts continueront d\'être enregistrés individuellement, mais le groupe sera supprimé.',
  },
  'Buscar contatos e grupos': {
    es: 'Buscar contactos y grupos',
    en: 'Search contacts and groups',
    fr: 'Recherche contacts et groupes',
  },
  'Essa funcionalidade só está disponível para organizações de planos pagos. Faça upgrade do seu plano agora mesmo para começar a utilizar Grupos de Contatos': {
    es: 'Esta funcionalidad solo está disponible para organizaciones con planes pagos. Actualice su plan ahora para comenzar a usar Grupos de contacto',
    en: 'This functionality is only available for organizations on paid plans. Upgrade your plan now to start using Contact Groups',
    fr: 'Cette fonctionnalité n\'est disponible que pour les organisations sur des plans payants. Mettez à niveau votre plan maintenant pour commencer à utiliser les groupes de contact',
  },
  Logradouro: {
    es: 'Calle',
    en: 'Street',
    fr: 'Rue',
  },
  Localidade: {
    es: 'Localidad',
    en: 'City',
    fr: 'Ville',
  },
  UF: {
    es: 'Estado',
    en: 'State',
    fr: 'État',
  },
  'CEP + Endereço': {
    es: 'Código postal + Dirección',
    en: 'ZIP CODE + Address',
    fr: 'Code postal + adresse',
  },
  'Ao inserir o CEP no formulário, as demais informações de endereço (logradouro, bairro, cidade, UF) serão automaticamente preenchidas pelo sistema.': {
    es: 'Al ingresar el código postal en el formulario, el sistema completará automáticamente la otra información de la dirección (calle, barrio, ciudad, estado).',
    en: 'When entering the zip code in the form, the other address information (street, neighborhood, city, state) will be automatically filled in by the system.',
    fr: 'Lors de la saisie du code postal dans le formulaire, les autres informations sur l\'adresse (rue, quartier, ville, état) seront automatiquement remplies par le système.',
  },
  'Altere o arquivo do modelo': {
    es: 'Cambiar el archivo de plantilla',
    en: 'Change the template file',
    fr: 'Modifier le fichier modèle',
  },
  'A assinatura eletrônica mais fácil de usar': {
    es: 'La firma electrónica más fácil de usar',
    en: 'Collect electronic signatures in a simple and seamless way',
    fr: 'La signature électronique la plus conviviale',
  },
  'Mais de 1 milhão de usuários confiam na ZapSign': {
    es: 'Más de 1 millón de usuarios confían en ZapSign',
    en: 'More than 1 million users trust ZapSign',
    fr: 'Plus 1 million d\'utilisateurs font confiance à ZapSign',
  },
  'Ative sua conta': {
    es: 'Activa tu cuenta',
    en: 'Activate your account',
    fr: 'Activez votre compte',
  },
  'Voltar para a homepage': {
    es: 'Ir para homepage',
    en: 'Go to homepage',
    fr: 'Retour au site',
  },
  'Faça upload do novo arquivo .docx contendo as alterações pertencentes ao modelo. Não é permitido remover nem adicionar novos campos dinâmicos.': {
    es: 'Carga el nuevo archivo .docx que contiene los cambios relacionados con la plantilla. No se permite eliminar o agregar nuevos campos dinámicos.',
    en: 'Upload the new .docx file containing the changes pertaining to the template. Removing or adding new dynamic fields is not allowed.',
    fr: 'Téléchargez le fichier .docx contenant les modifications relatives au modèle. Il n\'est pas permis de supprimer ou d\'ajouter de nouveaux champs dynamiques.',
  },
  'As configurações a seguir são opcionais e você pode alterá-las quando quiser': {
    es: 'Los siguientes ajustes son opcionales y puedes cambiarlos en cualquier momento.',
    en: 'The following settings are optional and you can change them at any time.',
    fr: 'Les paramètres suivants sont facultatifs et vous pouvez les modifier à tout moment.',
  },
  'Contato removido com sucesso!': {
    es: '¡Contacto eliminado con éxito!',
    en: 'Contact removed successfully!',
    fr: 'Contact supprimé avec succès!',
  },
  'Ocorreu um erro ao remover o contato.': {
    es: 'Ocurrió un error al eliminar el contacto',
    en: 'An error occurred while removing the contact.',
    fr: 'Une erreur s\'est produite lors du retrait du contact.',
  },
  'Ocorreu algum erro.': {
    es: 'Ocurrió un error.',
    en: 'An error occurred.',
    fr: 'Une erreur s\'est produite.',
  },
  'Alterações salvas com sucesso!': {
    es: '¡Cambios guardados con éxito!',
    en: 'Changes successfully saved!',
    fr: 'Change avec succès enregistré!',
  },
  'Grupo de contatos criado com sucesso!': {
    es: '¡Grupo de contacto creado con éxito!',
    en: 'Contact group successfully created!',
    fr: 'Contactez le groupe créé avec succès!',
  },
  'Grupo de contatos excluído com sucesso!': {
    es: '¡Grupo de contacto creado con éxito!',
    en: 'Contact group successfully created!',
    fr: 'Contactez le groupe créé avec succès!',
  },
  'Ocorreu um erro ao remover o grupo.': {
    es: 'Se produjo un error al eliminar el grupo.',
    en: 'An error occurred while removing the group.',
    fr: 'Une erreur s\'est produite lors du retrait du groupe.',
  },
  'Ao inserir o CEP no formulário, as demais informações de endereço (logradouro, complemento, bairro, cidade, UF) serão automaticamente preenchidas pelo sistema, mas não constarão no documento final.': {
    es: 'Al insertar el CEP en el formulario, los demás datos de la dirección (calle, complemento, barrio, ciudad, Estado) serán llenados automáticamente por el sistema, pero no aparecerán en el documento final.',
    en: 'When inserting the zip code in the form, the other address information (street, complement, neighborhood, city, state) will be automatically filled in by the system, but will not appear in the final document.',
    fr: 'Lors de l\'insertion du code postal dans le formulaire, les autres informations sur l\'adresse (rue, complément, quartier, ville, état) seront automatiquement remplies par le système, mais n\'apparaîtront pas dans le document final.',
  },
  'Você pode alterar o arquivo, definir os signatários padrão do documento e também posicionar campos': {
    es: 'Modificar el archivo, definir los firmantes, posicionar las firmas y los métodos de autenticación.',
    en: 'Modify the file, define the signers, position the signatures, and select authentication methods.',
    fr: 'Modifier le fichier, définir les signataires, les signatures de position et les méthodes d\'authentification.',
  },
  'Os documentos criados a partir deste modelo serão inseridos na pasta selecionada.': {
    es: 'Los documentos creados a partir de esta plantilla serán guardados en la carpeta seleccionada.',
    en: 'The documents created from this template will be saved in the selected folder.',
    fr: 'Les documents créés à partir de ce modèle seront enregistrés dans le dossier sélectionné.',
  },
  'Escolha como o signatário verá o formulário, definindo a ordem, títulos e textos de ajuda para seus campos dinâmicos.': {
    es: 'En caso que el firmante diligencie el formulario define las configuraciones de los campos.',
    en: 'If the signer is going to fill out the form, define the settings for the dynamic fields.',
    fr: 'Si le signataire remplit le formulaire, il définit les paramètres du champ.',
  },
  'Solicite que o signatário anexe arquivos, ative notificações externas, defina link de redirecionamento, altere idioma etc.': {
    es: 'Solicita anexos al firmante, notifica a alguien externo, redirecciona a un link después de la firma.',
    en: 'Request attachments from the signer, notify an external person, redirect to a link after the signature.',
    fr: 'Demande des pièces jointes au signataire, notifie une partie externe, redirige vers un lien après la signature.',
  },
  'Indique e ganhe até R$500': {
    es: 'Recomienda y gana',
    en: 'Affiliate Program',
    fr: 'Recommandez et gagnez',
  },
  'Ganhe até R$ 500,00': {
    es: 'Gana hasta 200 dólares',
    en: 'Earn up to $200',
    fr: 'Gagnez jusqu\'à 200$',
  },
  'para cada indicação que você fizer. Crie seu link em menos de 1 minuto!': {
    es: 'por cada referido que compre un plan en ZapSign. Crea tu link y compártelo a tus amigos y conocidos.',
    en: 'for each referral who purchases a plan on ZapSign. Create your link and share with your friends.',
    fr: 'pour chaque parrain qui achète un plan ZapSign. Créez votre lien et partagez-le avec vos amis et connaissances.',
  },
  'Inscreva-se em nosso programa de recompensas': {
    es: '¡Ingresa aquí y empieza a referir!',
    en: 'Enter here and start referring',
    fr: 'Je veux participer',
  },
  'Esse documento foi integrado via ZapSwitch': {
    es: 'Este documento fue integrado a través de ZapSwitch',
    en: 'This document was integrated by ZapSwitch',
    fr: 'Ce document a été intégré par Zapswitch',
  },
  Integrado: {
    es: 'Integrado',
    en: 'Integrated',
    fr: 'Intégré',
  },
  'Ambiente de Sandbox sem validade jurídica': {
    es: 'Entorno sandbox sin validez legal',
    en: 'Sandbox environment without legal validity',
    fr: 'Environnement de bac à sable sans validité légale',
  },
  'Arquivos privados: expiram e geram novos links para download dos documentos a cada 60 minutos.': {
    es: 'Archivos privados: Expiran y se generan nuevos enlaces de descarga de documentos cada 60 minutos.',
    en: 'Private files: expire and create new links for download every 60 minutes.',
    fr: 'Fichiers privés: expirer et créer de nouveaux liens à télécharger toutes les 60 minutes.',
  },
  'Não é possível desativar essa preferencia.': {
    es: 'No es posible desactivar esta preferencia.',
    en: 'It\'s not possible to deactivate this preference.',
    fr: 'Il n\'est pas possible de désactiver cette préférence.',
  },
  'Obs.: por segurança e privacidade, os links de download expiram em 60 minutos. Você deverá atualizar a pagina para acessar o link atualizado com mais 60 minutos de disponibilidade.': {
    es: 'Obs.: por seguridad y privacidad, los links de descarga vencen en 60 minutos. Deberás volver a descargar el documento para actualizar el link.',
    en: 'Obs.: for security and privacy reasons, the download links expire in 60 minutes. You will need to download the document again to update the link.',
    fr: 'Obs.: Pour la sécurité et la confidentialité, les liens de téléchargement expirent en 60 minutes. Vous devrez mettre à jour votre page pour obtenir un lien mis à jour avec plus de 60 minutes de discipabilité.',
  },
  'Você atingiu o limite de usuários da sua conta. Adicione mais fazendo': {
    es: 'Has alcanzado el límite de usuarios de tu cuenta. Agrega más',
    en: 'You have reached your account user limit. Add more by',
    fr: 'Vous avez atteint la limite de l\'utilisateur de votre compte. Ajouter plus',
  },
  'upgrade de plano': {
    es: 'actualizando tu plan',
    en: 'upgrading your plan',
    fr: 'Mise à niveau de votre plan',
  },
  'ou contrate usuários extras em Configurações > Plano > Planos adicionais ou': {
    es: 'o registrándose para usuarios adicionales en Ajustes > Plan > Planes adicionales o',
    en: 'or signing up for extra users in Settings > Plan > Additional Plans or',
    fr: 'ou s\'inscrire à des utilisateurs supplémentaires dans Paramètres> Plan> Plans supplémentaires ou',
  },
  'clicando aqui': {
    es: 'haciendo clic aquí',
    en: 'by clicking here',
    fr: 'en cliquant ici',
  },
  'Você selecionou um método de autenticação premium que requer créditos para ser utilizado. Se você não possuir créditos, o signatário não poderá assinar.': {
    es: 'Has seleccionado un método de autenticación premium que requiere créditos para su uso. Si no tienes créditos, el firmante no podrá firmar.',
    en: 'You have selected a premium authentication method that requires credits to use. If you don\'t have credits, the signer will not be able to sign.',
    fr: 'Vous avez sélectionné une méthode d\'authentification premium qui nécessite des crédits à utiliser. Si vous n\'avez pas de crédits, le signataire ne pourra pas signer.',
  },
  'clicando aqui.': {
    es: 'haciendo clic aquí.',
    en: 'clicking here.',
    fr: 'cliquez ici.',
  },
  'Você possui $0 créditos. Adicione mais em Configurações > Plano': {
    es: 'Tienes $0 créditos. Agrega más en Ajustes > Plan',
    en: 'You have $0 credits. Add more in Settings > Plan',
    fr: 'Vous avez $0 de crédits. Ajouter plus dans Paramètres> Plan',
  },
  'Créditos insuficientes': {
    es: 'Créditos insuficientes',
    en: 'Insufficient credits',
    fr: 'Crédits insuffisants',
  },
  'Precisa de ajuda?': {
    es: '¿Necesitas ayuda?',
    en: 'Need help?',
    fr: 'Besoin d\'aide ?',
  },
  'Escolha abaixo a forma que prefere aprender sobre a ZapSign:': {
    es: 'Elige a continuación la forma en que prefieres aprender sobre ZapSign',
    en: 'Choose below the way you prefer to learn about ZapSign',
    fr: 'Choisissez ci-dessous comme vous préférez en savoir plus sur Zapsign',
  },
  'Aumente seu limite': {
    es: 'Aumenta tu límite',
    en: 'increase your limit',
    fr: 'Augmentez votre limite',
  },
  'Documentos disponíveis': {
    es: 'Documentos disponibles',
    en: 'Available documents',
    fr: 'Documents disponibles',
  },
  'Você atingiu o limite': {
    es: 'Has alcanzado el límite',
    en: 'you reached the limit',
    fr: 'Vous avez atteint la limite',
  },
  'Documentos enviados': {
    es: 'Documentos enviados',
    en: 'Documents',
    fr: 'Documents envoyés',
  },
  'Documento recusado': {
    es: 'Documento rechazado',
    en: 'Document refused',
    fr: 'Document refusé',
  },
  'Remover contato deste grupo': {
    es: 'Eliminar contacto de este grupo',
    en: 'Remove contact from this group',
    fr: 'Supprimer le contact de ce groupe',
  },
  'Mais recentes': {
    es: 'Más recientes',
    en: 'Most recent',
    fr: 'Le plus récent',
  },
  'Mais antigos': {
    es: 'Más antiguos',
    en: 'Oldest',
    fr: 'Le plus vieux',
  },
  'Proprietário(a)': {
    es: 'Propietario(a)',
    en: 'Owner',
    fr: 'Propriétaire',
  },
  'Filtros de busca': {
    es: 'Filtros de búsqueda',
    en: 'Search filters',
    fr: 'Rechercher des filtres',
  },
  'Buscar documento': {
    es: 'Buscar documento',
    en: 'Search document',
    fr: 'Chercher…',
  },
  'Filtrar busca': {
    es: 'Filtro de búsqueda',
    en: 'Filter search',
    fr: 'Filtre de recherche ',
  },
  'Autor do documento': {
    es: 'Autor del documento',
    en: 'Document author',
    fr: 'Auteur du document',
  },
  Todas: {
    es: 'Todas',
    en: 'All',
    fr: 'Tous',
  },
  'Definir período': {
    es: 'Definir fechas',
    en: 'Time range',
    fr: 'Période fixée',
  },
  'Pasta atual': {
    es: 'Carpeta actual',
    en: 'Current Folder',
    fr: 'Dossier en cours',
  },
  'Aplicar filtro': {
    es: 'Aplicar filtro',
    en: 'Apply filter',
    fr: 'Appliquer le filtre',
  },
  'Todos os autores': {
    es: 'Todos los autores',
    en: 'All authors',
    fr: 'Tous les auteurs',
  },
  'Até hoje': {
    es: 'Hasta hoy',
    en: 'Until today',
    fr: 'Jusqu\'à aujourd\'hui',
  },
  'Todas as pastas': {
    es: 'Todas',
    en: 'All folders',
    fr: 'Tous les dossiers',
  },
  'Habilite esta funcionalidade para obter mais recursos': {
    es: 'Habilita esta funcionalidad para obtener más prestaciones',
    en: 'Enable this functionality for more benefits',
    fr: 'Activer cette fonctionnalité pour plus d\'avantages',
  },
  usuários: {
    es: 'usuarios',
    en: 'users',
    fr: 'utilisateurs',
  },
  'Documentos mensais': {
    es: 'Documentos mes',
    en: 'Monthly documents',
    fr: 'Documents mensuels',
  },
  'Documentos por ano': {
    es: 'Documentos por año',
    en: 'Documents per year',
    fr: 'Documents par an',
  },
  Anual: {
    es: 'Anual',
    en: 'Annual',
    fr: 'Annuel',
  },
  anual: {
    es: 'Anual',
    en: 'Annual',
    fr: 'Annuel',
  },
  Parcelado: {
    es: 'Cuotas',
    en: 'Installments',
    fr: 'Versements',
  },
  parcelado: {
    es: 'Cuotas',
    en: 'Installments',
    fr: 'Versements',
  },
  'Anual parcelado': {
    es: 'Cuotas anuales',
    en: 'Annual installments',
    fr: 'Versements annuels',
  },
  Mensal: {
    es: 'Mensual',
    en: 'Monthly',
    fr: 'Mensuel',
  },
  mensal: {
    es: 'Mensual',
    en: 'Monthly',
    fr: 'Mensuel',
  },
  'Parcela única $0 $1/ ano': {
    es: 'Cobro anual $0 $1 año',
    en: '$0 $1 billed annualy',
    fr: '$0 $1 facturé annuel',
  },
  '12 parcelas mensais': {
    es: '12 cuotas mensuales',
    en: '12 monthly installments',
    fr: '12 versements mensuels',
  },
  'Plano mensal. Sem fidelidade.': {
    es: 'Plan mensual. Sin multas.',
    en: 'Monthly plan. No loyalty.',
    fr: 'Plan mensuel. Pas de fidélité.',
  },
  '/ mês': {
    es: '/ mes',
    en: '/ month',
    fr: '/ mois',
  },
  'Serviços adicionais': {
    es: 'Servicios adicionales',
    en: 'Additional services',
    fr: 'Des services supplémentaires',
  },
  'Serviços Adicionais': {
    es: 'Servicios Adicionales',
    en: 'Additional Services',
    fr: 'Services Additionnels'
  },
  '$0 $1 por documento adicional;': {
    es: '$0 $1 por documento adicional;',
    en: '$0 $1 per additional document;',
    fr: '$0 $1 par document supplémentaire;',
  },
  '$0 $1 por SMS enviado;': {
    es: '$0 $1 por SMS enviado;',
    en: '$0 $1 per SMS sent;',
    fr: '$0 $1 par SMS envoyé;',
  },
  'Planos WEB': {
    es: 'Planes WEB',
    en: 'WEB plans',
    fr: 'Plans Web',
  },
  'Planos API': {
    es: 'Planes API',
    en: 'API plans',
    fr: 'Plans API',
  },
  PLANOS: {
    es: 'PLANES',
    en: 'PLANS',
    fr: 'PLANS',
  },
  'Exportar lista': {
    es: 'Exportar lista',
    en: 'Export list',
    fr: 'Liste d\'exportation',
  },
  selecionado: {
    es: 'seleccionado',
    en: 'selected',
    fr: 'choisi',
  },
  selecionados: {
    es: 'seleccionados',
    en: 'selected',
    fr: 'choisi',
  },
  'ZapSign | Assinatura eletrônica e digital sem mistérios': {
    es: 'ZapSign | La firma electrónica más fácil de usar ',
    en: 'ZapSign | The easy-to-use electronic signature',
    fr: 'ZapSign | La signature électronique facile à utiliser',
  },
  Atual: {
    es: 'Actual',
    en: 'Current',
    fr: 'Actuel',
  },
  'Benefícios para assinantes': {
    es: 'Beneficios para los planes ZapSign',
    en: 'Benefits of ZapSign plans',
    fr: 'Avantages pour les plans ZapSign',
  },
  'Aproveite funcionalidades exclusivas': {
    es: 'Funciones exclusivas',
    en: 'Exclusive features',
    fr: 'Fonctions exclusives',
  },
  'Personalize suas cores e sua marca': {
    es: 'Personaliza tu marca',
    en: 'Customize your brand',
    fr: 'Personnalisez votre marque',
  },
  'Formulários e modelos de documentos prontos': {
    es: 'Plantillas y formularios',
    en: 'Templates and forms',
    fr: 'Modèles et formulaires',
  },
  'Autenticação avançada para validar o signatário': {
    es: 'Autenticación es avanzadas para validar firmante',
    en: 'Advanced authentication to validate signer',
    fr: 'Authentification avancée pour valider le signataire',
  },
  'Consulta de antecedentes legais de um indivíduo': {
    es: 'Firma con selfie y documento de identidad',
    en: 'Real-time audit trail',
    fr: 'Sentier d\'audit en temps réel',
  },
  'Registro para auditoria em tempo real': {
    es: 'Registro de auditoría en tiempo real',
    en: 'Real-time audit trail',
    fr: 'Piste d\'audit en temps réel',
  },
  'Envio de documento automático por whatsapp': {
    es: 'Firma en la pantalla',
    en: 'Signature on the screen',
    fr: 'Signature à l\'écran',
  },
  'Contrate pelo cartão de crédito ou boleto, cancele quando quiser sem multas': {
    es: 'Contrata con tarjeta de crédito o recibo bancario, cancela cuando quieras sin multas',
    en: 'Hire by credit card or bank slip, cancel whenever you want without fines',
    fr: 'Embaucher par carte de crédit ou banc de banc, annuler quand vous le souhaitez sans amendes',
  },
  'Todos os nossos planos são pré-pagos. Caso queira cancelar sua assinatura, você poderá aproveitar todos os benefícios contratados até o final do período contratado, mensal ou anual.': {
    es: 'Todos nuestros planes son prepago. Si desea cancelar la suscripción, podrás disfrutar de todos los beneficios contratados hasta el final del período contratado, mensual o anual.',
    en: 'All our plans are prepaid. If you want to cancel your subscription, you will be able to enjoy all the contracted benefits until the end of the contracted period, monthly or annual.',
    fr: 'Tous nos plans sont prépayés. Si vous souhaitez annuler votre abonnement, vous pourrez profiter de tous les avantages contractuels jusqu\'à la fin de la période contractée, mensuelle ou annuelle.',
  },
  'Caso queira interromper a renovação automática, é preciso cancelar o seu plano antes de gerar uma nova cobrança. Em caso de cancelamento para plano anual parcelado, serão cobradas as parcelas subsequentes normalmente.': {
    es: 'Si quieres detener la renovación automática, debes cancelar el plan antes de generar un nuevo cargo. ',
    en: 'If you want to stop automatic renewal, you must cancel your plan before generating a new charge.',
    fr: 'Si vous souhaitez arrêter le renouvellement automatique, vous devez annuler votre plan avant de générer de nouvelles frais.',
  },
  'Válido para todos os planos': {
    es: 'Válido para todos los planes pagos',
    en: 'Valid for all paid plans',
    fr: 'Valable pour tous les plans payants',
  },
  'Utilize créditos para serviços adicionais': {
    es: 'Aumentar la validez legal',
    en: 'Use credits for additional services',
    fr: 'Utiliser des crédits pour des services supplémentaires',
  },
  'Autenticações premium para validar o signatário': {
    es: 'Autenticaciones premium para validar al firmante',
    en: 'Premium authentications to validate the signer',
    fr: 'Authentications premium pour valider le signataire',
  },
  'Envio de token por e-mail': {
    es: 'Envío de token por correo electrónico',
    en: 'Sending token by email',
    fr: 'Envoi de jeton par e-mail',
  },
  'Autenticação por foto selfie': {
    es: 'Autenticación por foto selfie',
    en: 'Authentication by selfie photo',
    fr: 'Authentification par photo de selfie',
  },
  'Autenticação por documento de identidade': {
    es: 'Autenticación por documento de identidad',
    en: 'Authentication by identity document',
    fr: 'Document d\'authentification par identité',
  },
  'Autenticação por reconhecimento facial': {
    es: 'Autenticación de reconocimiento facial',
    en: 'Facial recognition authentication',
    fr: 'Authentification de reconnaissance faciale',
  },
  'Autenticação por certificado digital': {
    es: 'Autenticación de certificados digitales',
    en: 'Digital certificate authentication',
    fr: 'Authentification du certificat numérique',
  },
  'Envio de token por whatsapp': {
    es: 'Enviar ficha por whatsapp',
    en: 'Send token by whatsapp',
    fr: 'Envoyer un jeton par WhatsApp',
  },
  'Suporte por e-mail e WhatsApp': {
    es: 'Soporte por e-mail y WhatsApp',
    en: 'Support via email and WhatsApp',
    fr: 'Assistance par courriel et WhatsApp',
  },
  'Utilize o campo ao lado para concluir a assinatura do seu plano customizado': {
    es: 'Usa el campo al costado para completar su suscripción a un plan personalizado',
    en: 'Use the field on the side to complete your custom plan subscription',
    fr: 'Utilisez le champ sur le côté pour terminer votre abonnement à plan personnalisé',
  },
  'Nenhum plano encontrado! Por favor, atualize o código aplicado': {
    es: '¡No se encontraron planes! Por favor actualice el código aplicado',
    en: 'No plans found! Please update the applied code',
    fr: 'Aucun plan trouvé! Veuillez mettre à jour le code appliqué',
  },
  'Selecione abaixo o plano desejado': {
    es: 'Selecciona el plan a continuación',
    en: 'Select your desired plan below',
    fr: 'Sélectionnez votre plan souhaité ci-dessous',
  },
  'Código de verificação por e-mail e SMS': {
    es: 'Código de verificación por e-mail o SMS',
    en: 'Verification code to email or SMS',
    fr: 'Code de vérification par e-mail et SMS',
  },
  'Os créditos funcionam como uma moeda virtual que você pode usar para comprar serviços adicionais de forma pontual, sem precisar atualizar seu plano para uma categoria superior': {
    es: 'Los créditos actúan como una moneda virtual que puedes usar para comprar servicios adicionales en el momento oportuno, sin tener que actualizar tu plan.',
    en: 'Credits act as a virtual currency that you can use to purchase additional services on a timely basis, without having to upgrade your plan to a higher tier.',
    fr: 'Les crédits agissent comme une monnaie virtuelle que vous pouvez utiliser pour acheter des services supplémentaires en temps opportun, sans avoir à mettre à niveau votre plan à un niveau plus élevé.',
  },
  'Compre com cartão de crédito, cancele sua assinatura a qualquer momento e sem cláusula de permanência mínima.': {
    es: 'Compra con tarjeta de crédito, cancela en cualquier momento y sin cláusula de permanencia.',
    en: 'Buy with credit card, cancel your subscription at any time and without a minimum stay clause.',
    fr: 'Achetez avec une carte de crédit, résiliez à tout moment et sans clause de blocage.',
  },
  'Você já possui um plano ativo. Você pode clicar em "Mudar plano" na seção "Meu plano" ou cancelar antes seu plano e contratar um novo.': {
    es: 'Ya tienes un plan activo. Puedes hacer clic en "Cambiar plan" en la sección "Mi plan" o cancelar tu plan primero y comprar uno nuevo.',
    en: 'You already have an active plan. You can click on "Change plan" in the "My plan" section or cancel your plan first and purchase a new one.',
    fr: 'Vous avez déjà un plan actif. Vous pouvez cliquer sur "Modifier le plan" dans la section "Mon plan" ou annuler d\'abord votre plan et en acheter un nouveau.',
  },
  'Aumentar a validade legal': {
    es: 'Aumentar la validez legal',
    en: 'Increase legal validity',
    fr: 'Renforcer la validité juridique',
  },
  'Indique a ZapSign e ganhe recompensas': {
    es: '¡Gana dinero por referir ZapSign!',
    en: 'Earn extra income by recommending ZapSign!',
    fr: 'Gagnez un revenu supplémentaire en recommandant ZapSign!',
  },
  'Até R$500 reais por cada indicação': {
    es: 'Gana hasta 200 dólares por cada referido que compre un plan',
    en: 'Earn up to $200 for each referral who purchases a plan',
    fr: 'Gagnez jusqu\'à 200 $ pour chaque référence qui achète un plan',
  },
  'Quero participar!': {
    es: 'Conocer más',
    en: 'Learn more',
    fr: 'Apprendre encore plus',
  },
  'Imagem de um usuario sentado recebendo $ atraves do celular': {
    es: 'Imagen de un usuario sentado recibiendo $ a través del celular',
    en: 'Image of a user sitting down receiving $ through the cell phone',
    fr: 'Image d\'un utilisateur assis à recevoir $ via le téléphone portable',
  },
  'Icone de x para que o usuario possa fechar a modal': {
    es: 'Imagen de un usuario sentado recibiendo $ a través del celular',
    en: 'Image of a user sitting down receiving $ through the cell phone',
    fr: 'Image d\'un utilisateur assis à recevoir $ via le téléphone portable',
  },
  'CPF (Sem validação na receita federal)': {
    es: 'seleccionados',
    en: 'selected',
    fr: 'choisi',
  },
  com: {
    es: 'con',
    en: 'with',
    fr: 'avec',
  },
  sem: {
    es: 'sin',
    en: 'without',
    fr: 'sans',
  },
  'Verifique se o telefone está correto.': {
    es: 'Verifica si el número es correcto',
    en: 'Verify that the number is correct',
    fr: 'Vérifiez que le nombre est correct',
  },
  'Seu modelo PDF deve': {
    es: 'Para crear la plantilla siga los siguientes pasos',
    en: 'To create the template follow the steps below',
    fr: 'Pour créer le modèle, suivez les étapes ci-dessous',
  },
  'Suba o arquivo': {
    es: 'Subir un archivo',
    en: 'Upload a PDF file',
    fr: 'Télécharger un fichier PDF',
  },
  'Posicione os campos de texto': {
    es: 'Posiciona las cajas',
    en: 'Position the boxes',
    fr: 'Positionner les cases',
  },
  'que devem ser diferenciados (opcional)': {
    es: 'que serán los campos dinámicos (opcional) ',
    en: 'that will be the dynamic fields (optional)',
    fr: 'Ce sera les champs dynamiques (facultatif)',
  },
  'Posicione as': {
    es: 'Posiciona las',
    en: 'Position the',
    fr: 'Positionner le',
  },
  'assinaturas e vistos': {
    es: 'firmas e iniciales de los firmantes',
    en: 'signatures and initials of the signers',
    fr: 'signatures et initiales des signataires',
  },
  'Configure e compartilhe o modelo.': {
    es: 'Envía el documento',
    en: 'Send the document',
    fr: 'Envoyer le document',
  },
  'Documentos (principal)': {
    es: 'Documentos (principal)',
    en: 'Documents (main)',
    fr: 'Documents (principal)',
  },
  'Solução personalizada': {
    es: 'Planes personalizados',
    en: 'Customized plans',
    fr: 'Plans personnalisés',
  },
  'Grandes volumes de documentos': {
    es: 'Mayor volumen de documentos',
    en: 'For large companies with a high document volume',
    fr: 'Augmentation du volume de documents',
  },
  'Aumento de recursos para garantir segurança jurídica': {
    es: 'Gestión avanzada de departamentos y usuarios',
    en: 'Advanced department and user management',
    fr: 'Gestion avancée des départements et des utilisateurs',
  },
  'Gestão avançada de departamentos e usuários': {
    es: 'Integraciones API, Google Drive, Slack...',
    en: 'Customize with integrations: API, Google Drive, Slack...',
    fr: 'Intégrations API, Google Drive, Slack...',
  },
  'Esse campo é obrigatório': {
    es: 'Este campo es obligatorio',
    en: 'This field is required',
    fr: 'Ce champ est obligatoire',
  },
  'O valor inserido é muito curto': {
    es: 'El valor ingresado es demasiado corto',
    en: 'The inserted value is too short',
    fr: 'La valeur insérée est trop courte',
  },
  'O valor inserido é muito longo': {
    es: 'El valor ingresado es demasiado largo',
    en: 'The inserted value is too long',
    fr: 'La valeur insérée est trop longue',
  },
  'Insira um e-mail válido': {
    es: 'Ingresa un correo electrónico válido',
    en: 'Enter a valid email',
    fr: 'Entrer un email valide',
  },
  'Sua assinatura foi confirmada com sucesso!': {
    es: '¡Tu suscripción ha sido confirmada con éxito!',
    en: 'Your subscription was successfully confirmed!',
    fr: 'Votre abonnement a été confirmé avec succès!',
  },
  'Você já pode aproveitar todas as funcionalidades da nossa plataforma de assinatura eletrônica e gestão de documentos digitais.': {
    es: 'Ya puedes empezar a usar las funcionalidades de nuestra plataforma de firma electrónica y gestión de documentos digitales.',
    en: 'You can now start using the features of our electronic signature and digital document management platform.',
    fr: 'Vous pouvez maintenant commencer à utiliser les fonctionnalités de notre plate-forme électronique de gestion des documents de signature et de documents numériques.',
  },
  'Agradecemos por ter escolhido a ZapSign como sua plataforma de assinatura eletrônica.': {
    es: 'Gracias por elegir ZapSign como tu plataforma de firma electrónica.',
    en: 'Thank you for choosing ZapSign as your electronic signature platform.',
    fr: 'Merci d\'avoir choisi Zapsign comme plate-forme de signature électronique.',
  },
  'O boleto também será enviado para o e-mail cadastrado.': {
    es: 'El recibo bancario también será enviado al correo electrónico registrado.',
    en: 'The bank slip will also be sent to the registered email.',
    fr: 'Le bordereau de banque sera également envoyé à l\'e-mail enregistré.',
  },
  'Os dados fornecidos serão utilizados para': {
    es: 'Los datos proporcionados serán utilizados para',
    en: 'The data provided will be used to',
    fr: 'Les données fournies seront utilisées pour',
  },
  'gerar a nota fiscal da compra': {
    es: 'generar la factura de compra',
    en: 'generate the purchase invoice',
    fr: 'générer la facture d\'achat',
  },
  'Tem certeza que deseja sair?': {
    es: '¿Seguro que quieres salir?',
    en: 'Are you sure you want to quit?',
    fr: 'Êtes-vous sûr de vouloir quitter?',
  },
  'Aguarde e tente novamente': {
    es: 'Espera e intenta nuevamente',
    en: 'Please wait and try again',
    fr: 'Veuillez patienter et réessayer',
  },
  'Você está no processo de assinatura da ZapSign, ao fechar essa tela você perderá todos os dados preenchidos e a compra será cancelada.': {
    es: 'Estás en proceso de suscripción a ZapSign, al cerrar esta pantalla perderás todos los datos introducidos y se cancelará la compra.',
    en: 'You are in the ZapSign subscription process, when you close this screen you will lose all the data entered and the purchase will be cancelled.',
    fr: 'Vous êtes dans le processus d\'abonnement ZapSign, lorsque vous fermez cet écran, vous perdrez toutes les données saisies et l\'achat sera annulé.',
  },
  'Aproveite todos os benefícios da Zapsign,': {
    es: 'Disfruta de todos los beneficios de ZapSign,',
    en: 'Enjoy all the benefits of ZapSign,',
    fr: 'Profitez de tous les avantages de ZapSign, ',
  },
  'comece a criar seus documentos': {
    es: 'empieza a crear tus documentos',
    en: 'start creating your documents,',
    fr: 'Commencez à créer vos documents,',
  },
  '$0 de $1 Assinaturas': {
    es: '$0 de $1 Firmas',
    en: '$0 of $1 Signatures',
    fr: '$0 de signatures de $1',
  },
  'dd/MM/yyyy': {
    es: 'dd/MM/yyyy',
    en: 'MM/dd/yyyy',
    fr: 'dd/MM/yyyy',
  },
  'dd/MM/yyyy HH:mm': {
    es: 'dd/MM/yyyy HH:mm',
    en: 'MM/dd/yyyy HH:mm',
    fr: 'Mm / dd / yyyy hh: mm',
  },
  'Este é um ambiente de compra seguro': {
    es: 'Ambiente de compra seguro',
    en: 'Secure purchasing environment',
    fr: 'Environnement d\'achat sécurisé',
  },
  'Crédito ZapSign': {
    es: 'Crédito ZapSign',
    en: 'ZapSign Credit',
    fr: 'Crédit zapsign',
  },
  'Titular da fatura': {
    es: 'Titular de la factura',
    en: 'Invoice holder',
    fr: 'Porte-facture',
  },
  'Vencimento em 7 dias': {
    es: 'Vencimiento en 7 días',
    en: 'Maturity in 7 days',
    fr: 'Maturité en 7 jours',
  },
  'Valor da parcela': {
    es: 'valor de la cuota',
    en: 'installment value',
    fr: 'valeur de versement',
  },
  'Qtd de parcelas': {
    es: 'cantidad de cuotas',
    en: 'amount of installments',
    fr: 'quantité de versements',
  },
  'Ao final do período de 30 dias, o plano mensal será renovado automaticamente com o valor integral. Caso deseje cancelar, é possível fazê-lo antes desse prazo.Clique para conhecer nossos planos.': {
    es: 'Al final del período de 30 días, el plan mensual se renovará automáticamente al precio completo. Si desea cancelar, puedes hacerlo antes de esta fecha límite. Haz clic para conocer nuestros planes.',
    en: 'At the end of the 30-day period, the monthly plan will automatically renew at the full price. If you wish to cancel, you can do so before this deadline. Click to know our plans.',
    fr: 'À la fin de la période de 30 jours, le plan mensuel se renouvellera automatiquement au prix fort. Si vous souhaitez annuler, vous pouvez le faire avant cette date limite. Cliquez pour connaître nos plans.',
  },
  'A compra será concluída assim que identificarmos o pagamento do boleto bancário (até 2 dias úteis).': {
    es: 'La compra se completará tan pronto como identifiquemos el recibo bancario (hasta 2 días hábiles).',
    en: 'The purchase will be completed as soon as we identify the bank slip payment (up to 2 business days).',
    fr: 'L\'achat sera achevé dès que nous identifierons le paiement bancaire (jusqu\'à 2 jours ouvrables).',
  },
  Disponíveis: {
    es: 'Disponible',
    en: 'Available',
    fr: 'Disponible',
  },
  'Foto de um x ilustrativo': {
    es: 'Foto de una x ilustrativa',
    en: 'Photo of an illustrative x',
    fr: 'Photo d\'un illustratif x',
  },
  'Excluir para sempre': {
    es: 'Eliminar para sempre',
    en: 'Delete forever',
    fr: 'Supprimer pour toujours',
  },
  'Esta ação não poderá ser desfeita, certifique-se de que está excluindo o documento correto.': {
    es: 'Esta acción no se puede deshacer, asegurate de que estás eliminando el documento correcto.',
    en: 'This action cannot be undone, make sure you are deleting the correct document.',
    fr: 'Cette action ne peut pas être annulée, assurez-vous de supprimer le bon document.',
  },
  'Gerenciar acesso': {
    es: 'Administrar el acceso',
    en: 'Manage access',
    fr: 'Gérer l\'accès',
  },
  'O reconhecimento facial ainda está sendo validado. Aguarde e tente novamente em alguns instantes': {
    es: 'El reconocimiento facial aún se está validando. Espera y vuelve a intentarlo en unos momentos.',
    en: 'Facial recognition is still being validated. Please wait and try again in a few moments.',
    fr: 'La reconnaissance faciale est toujours en cours de validation. Veuillez patienter et réessayer dans quelques instants.',
  },
  'Sua opinião é fundamental!': {
    es: '¡Tu opinión es fundamental!',
    en: 'Your opinion is fundamental!',
    fr: 'Votre opinion est fondamentale!',
  },
  'Conte-nos o que você pensa sobre o novo buscador.': {
    es: 'Cuéntanos qué te parece el nuevo motor de búsqueda.',
    en: 'Tell us what you think about the new search engine.',
    fr: 'Dites-nous ce que vous pensez du nouveau moteur de recherche.',
  },
  'Experimente a versão beta e participe da melhoria contínua da nossa plataforma!': {
    es: '¡Prueba la versión beta y participa en la mejora continua de nuestra plataforma!',
    en: 'Try the beta version and participate in the continuous improvement of our platform!',
    fr: 'Essayez la version bêta et participez à l\'amélioration continue de notre plateforme!',
  },
  'Resultado da busca': {
    es: 'Resultados de la búsqueda',
    en: 'Search results',
    fr: 'Résultats de recherche',
  },
  'Nenhum resultado encontrado': {
    es: 'Ningún resultado encontrado',
    en: 'no results found',
    fr: 'Aucun résultat trouvé',
  },
  'É preciso ter um plano mensal ou anual ativo para poder comprar créditos.': {
    es: 'Debes tener un plan mensual o anual activo para comprar créditos.',
    en: 'You must have an active monthly or annual plan to purchase credits.',
    fr: 'Vous devez avoir un plan mensuel ou annuel actif pour acheter des crédits.',
  },
  'Falar com suporte': {
    es: 'Hablar con soporte',
    en: 'Talk to support',
    fr: 'Contacter le Service Client',
  },
  'Falar com vendas': {
    es: 'Hablar con ventas',
    en: 'Talk to sales',
    fr: 'Parler avec les ventes'
  },
  'Os créditos são usados para acessar alguns recursos adicionais da ZapSign': {
    es: 'Los créditos se utilizan para acceder a algunas funciones adicionales de ZapSign',
    en: 'Credits are used to access some additional features in ZapSign.',
    fr: 'Les crédits sont utilisés pour accéder à certaines fonctions supplémentaires de ZapSign',
  },
  'Envio automático de documentos por WhatsApp: 5 créditos que equivalem a $0,1 USD': {
    es: 'Envío automático de los documentos por WhatsApp: 5 créditos que equivale a $0.1 USD',
    en: 'Automatic document sending via WhatsApp: 5 credits, equivalent to $0.1 USD.',
    fr: 'Envoi automatique de documents via WhatsApp : 5 crédits, équivalent à $0,1 USD.',
  },
  'Lembretes automáticos enviados pelo WhatsApp: 5 créditos equivalentes a US$ 0,1': {
    es: 'Recordatorios automáticos enviados por WhatsApp: 5 créditos que equivale a $0.1 USD',
    en: 'Automated reminders sent through WhatsApp: 5 credits, equivalent to $0.1 USD.',
    fr: 'Rappels automatisés envoyés par WhatsApp : 5 crédits, équivalents à $0,1 USD.',
  },
  'Código para WhatsApp como método de autenticação do signatário: 5 créditos, o que equivale a $ 0,1 USD': {
    es: 'Código por WhatsApp como método de autenticación al firmante: 5 créditos que equivale a $0.1 USD',
    en: 'WhatsApp code for signer authentication: 5 credits, equivalent to $0.1 USD.',
    fr: 'Code WhatsApp pour l\'authentification du signataire : 5 crédits, équivalent à $0,1 USD.',
  },
  'Reconhecimento facial durante a assinatura do documento: 15 créditos que equivalem a $0,3 USD': {
    es: 'Reconocimiento facial durante la firma del documento: 15 créditos que equivale a $0.3 USD',
    en: 'Facial recognition during document signing: 15 credits, equivalent to $0.3 USD.',
    fr: 'Reconnaissance faciale lors de la signature de document: 15 crédits, équivalent à $0,3 USD.',
  },
  'Reconhecimento facial durante a assinatura do documento: 25 créditos que equivalem a $0,5 USD': {
    es: 'Reconocimiento facial durante la firma del documento: 25 créditos que equivale a $0.5 USD',
    en: 'Facial recognition during document signing: 25 credits, equivalent to $0.5 USD.',
    fr: 'Reconnaissance faciale lors de la signature de document: 25 crédits, équivalent à $0,5 USD.',
  },
  'O reconhecimento facial ainda está sendo validado. Aguarde enquanto processamos.': {
    es: 'El reconocimiento facial está en proceso de validación. Por favor, espera unos segundos mientras finaliza.',
    en: 'The facial recognition is still being validated. Wait while we process it.',
    fr: 'La reconnaissance faciale est toujours en cours de validation. Attendez pendant que nous le traitons.',
  },
  'O pagamento do seu plano não foi concluído. Evite a suspensão do seu plano.': {
    es: 'No se ha completado el pago de tu plan. Evita suspender tu plan.',
    en: 'Your payment has not been completed. Prevent suspension of your plan.',
    fr: 'Votre paiement n\'est pas terminé. Empêchez la suspension de votre plan.',
  },
  'Pagar agora': {
    es: 'Pagar ahora',
    en: 'Pay now',
    fr: 'Payez maintenant',
  },
  'Cada envio por WhatsApp tem um custo de $0,10 USD (5 créditos). Compre créditos em Configurações > Planos > Créditos': {
    es: 'Cada envio por WhatsApp tiene un costo de $ 0,10 USD (5 créditos). Compra créditos en Ajustes > Planes > Créditos',
    en: 'Additional cost: Each WhatsApp message has a cost of $0.10 USD (5 credits). Purchase credits in Settings > Plans > Credits.',
    fr: 'Coût supplémentaire: chaque message WhatsApp a un coût de $0,10 USD (5 crédits). Achetez des crédits dans Paramètres> Plans> Crédits.',
  },
  'Atenção: cada envío automática via WhatsApp tem um custo adicional se não estiver incluso em seu plano': {
    es: 'Atención: cada envío automática por WhatsApp tiene un costo adicional si no esta incluido en el plan.',
    en: 'Attention: Each automatic WhatsApp message has an additional cost if it is not included in the plan.',
    fr: 'ATTENTION: Chaque message automatique WhatsApp a un coût supplémentaire s\'il n\'est pas inclus dans le plan.',
  },
  'para realizar o pagamento...': {
    es: 'para realizar el pago...',
    en: 'to make the payment...',
    fr: 'Pour effectuer le paiement ...',
  },
  'Continuar com SAML SSO': {
    es: 'Continuar con SAML SSO',
    en: 'Continue with SAML SSO',
    fr: 'Continuez avec Saml SSO',
  },
  'cobrança mensal': {
    es: 'cargo mensual',
    en: 'monthly charge',
    fr: 'frais mensuels',
  },
  'em 12 parcelas': {
    es: 'en 12 cuotas',
    en: 'in 12 installments',
    fr: 'en 12 versements',
  },
  'melhor custo benefício': {
    es: 'mejor costo beneficio',
    en: 'best cost benefit',
    fr: 'Meilleur avantage à coût',
  },
  'Equivale a ': {
    es: 'Equivalente a ',
    en: 'Equivalent to ',
    fr: 'Équivalent à',
  },
  'Autorizo e assino': {
    es: 'Autorizo y firmo',
    en: 'I authorize and sign',
    fr: 'J\'autorise et si signe',
  },
  'Declaro ter lido, concordo e assino os documentos abaixo': {
    es: 'Declaro haber leído, aceptado y firmado los siguientes documentos',
    en: 'I have read, agree and sign the documents below',
    fr: 'J\'ai lu, convenu et signé les documents ci-dessous',
  },
  'Por favor, assine abaixo:': {
    es: 'Por favor, firma abajo:',
    en: 'Please sign below:',
    fr: 'Veuillez signer ci-dessous: ',
  },
  'Assinar o documento': {
    es: 'Firmar el documento',
    en: 'Sign the document',
    fr: 'Signer le document',
  },
  Top: {
    es: 'Arriba',
    en: 'Top',
    fr: 'Haut',
  },
  'Assinatura concluída com sucesso!': {
    es: '¡Firma completada con éxito!',
    en: 'Signature completed successfully!',
    fr: 'La signature s\'est terminée avec succès!',
  },
  'Usuários com acesso': {
    es: 'Usuarios con acceso',
    en: 'Users with access',
    fr: 'Utilisateurs avec accès',
  },
  'Pessoas com acesso': {
    es: 'Personas con acceso',
    en: 'People with access',
    fr: 'Les personnes ayant accès',
  },
  '$0 usuário': {
    es: '$0 usuario',
    en: '$0 user',
    fr: '$0 utilisateur',
  },
  Título: {
    es: 'Título',
    en: 'Title',
    fr: 'Titre',
  },
  Usuário: {
    es: 'Usuario',
    en: 'User',
    fr: 'Utilisateur',
  },
  'Título da pasta': {
    es: 'Título de la carpeta',
    en: 'Folder title',
    fr: 'Titre du dossier',
  },
  'Adicione usuários': {
    es: 'Añadir usuarios',
    en: 'Add users',
    fr: 'Ajouter les utilisateurs',
  },
  'Proprietário da conta tem acesso a todas as pastas': {
    es: 'El propietario de la cuenta tiene acceso a todas las carpetas',
    en: 'Account owner has access to all folders',
    fr: 'Le propriétaire du compte a accès à tous les dossiers',
  },
  'meus documentos': {
    es: 'mis documentos',
    en: 'my documents',
    fr: 'mes documents',
  },
  'minhas pastas': {
    es: 'mis carpetas',
    en: 'my folders',
    fr: 'mes dossiers',
  },
  'Experimente a versão beta da organização das pastas e participe da melhoria contínua da nossa plataforma!': {
    es: '¡Prueba la versión beta de la organización de carpetas y participa en la mejora continua de nuestra plataforma!',
    en: 'Try the beta version of folder organization and participate in the continuous improvement of our platform!',
    fr: 'Essayez la version bêta de l\'organisation des dossiers et participez à l\'amélioration continue de notre plateforme!',
  },
  'Sua opinião é fundamental para aprimorarmos a experiência de uso.': {
    es: 'Tu opinión es fundamental para mejorar la experiencia de uso.',
    en: 'Your feedback is crucial for us to enhance the user experience.',
    fr: 'Vos commentaires sont cruciaux pour nous pour améliorer l\'expérience utilisateur.',
  },
  'Conte-nos o que você pensa!': {
    es: '¡Cuéntanos qué piensas!',
    en: 'Tell us what you think!',
    fr: 'Dites-nous ce que vous pensez!',
  },
  'https://forms.gle/7jhQ2duuaS5Fdse89': {
    es: 'https://forms.gle/hCL178umXQyyTdJy8',
    en: 'https://forms.gle/uaye5cWg8S8wYR5j6',
    fr: 'https://forms.gle/uaye5cwg8s8wyr5j6',
  },
  'assets/img/gifs/new-features/zcre-104-folders/pt-pastas.gif': {
    es: 'assets/img/gifs/new-features/zcre-104-folders/es-pastas.gif',
    en: 'assets/img/gifs/new-features/zcre-104-folders/en-pastas.gif',
    fr: 'assets/img/gifs/new-features/zcre-104-folders/fr-pastas.gif',
  },
  'Valorizamos seus feedbacks e melhoramos a navegação de pastas': {
    es: 'Sé uno de los primeros en probar nuestra interfaz beta.',
    en: 'Be one of the first to try out our beta interface.',
    fr: 'Soyez l\'un des premiers à essayer notre interface bêta.',
  },
  'Atualizamos a organização de pastas e documentos. Experimente agora mesmo e descubra como a árvore de pastas pode simplificar sua navegação. Essa melhoria reflete o nosso compromisso em atender às suas necessidades.': {
    es: 'Presentamos la visualización de documentos y carpetas en pestañas, para que puedas mantener todo organizado de forma simple y práctica.',
    en: 'We present the tabbed view of documents and folders, so you can keep everything organized in a simple and practical way.',
    fr: 'Nous présentons la vue sur les documents et les dossiers, vous pouvez donc tout garder organisé de manière simple et pratique.',
  },
  NOVIDADE: {
    es: 'NUEVO',
    en: 'NEWS',
    fr: 'NOUVELLES',
  },
  'Experimentar Versão Beta': {
    es: 'Experimentar Versión Beta',
    en: 'Try Beta Version',
    fr: 'Essayez la version bêta',
  },
  'Continuar com a Versão Atual': {
    es: 'Continuar con la Versión Actual',
    en: 'Continue with the Current Version',
    fr: 'Continuez avec la version actuelle',
  },
  'Sua empresa usa SSO (Single Sign On) para entrar na ZapSign. Por favor, digite o ID de login da sua organização.': {
    es: 'Tu empresa usa SSO (Single Sign On) para entrar a ZapSign. Por favor, escribe el  ID de login de la organización.',
    en: 'Your organization uses Single Sign On (SSO) with ZapSign. Enter your organization’s login ID.',
    fr: 'Votre organisation utilise un signe unique (SSO) avec ZapSign. Entrez l\'ID de connexion de votre organisation.',
  },
  'ID de login': {
    es: 'ID de login',
    en: 'Login ID',
    fr: 'Identifiant de connexion',
  },
  'Outras formas de entrar': {
    es: 'Otras formas de iniciar sesión',
    en: 'Other ways to sign in',
    fr: 'D\'autres façons de se connecter',
  },
  'Não foi possível encontrar este ID de login. Entre em contato com um administrador da sua equipe para obter ajuda.': {
    es: 'No fue posible encontrar el ID de login. Ponte en contacto con el administrador de tu empresa para obtener ayuda.',
    en: 'We couldn\'t find this login ID. Reach out to an admin from your organization for help.',
    fr: 'Nous n\'avons pas pu trouver cet identifiant de connexion. Tendez la main à un administrateur de votre organisation pour obtenir de l\'aide.',
  },
  'Não é possível redefinir senha de contas registradas via SAML SSO. Contate o seu administrador para mais informações.': {
    es: 'No es posible restablecer contraseñas para cuentas registradas a través de SAML SSO. Ponte en contacto con el administrador de tu empresa para obtener ayuda.',
    en: 'It is not possible to reset passwords for accounts registered via SAML SSO. Contact your company\'s administrator for more information.',
    fr: 'Il n\'est pas possible de réinitialiser les mots de passe pour les comptes enregistrés via SAML SSO. Contactez l\'administrateur de votre entreprise pour plus d\'informations.',
  },
  Ocultar: {
    es: 'Ocultar',
    en: 'Hide',
    fr: 'Cacher',
  },
  ocultar: {
    es: 'ocultar',
    en: 'hide',
    fr: 'cacher',
  },
  'Mostrar mais': {
    es: 'Mostrar más',
    en: 'Show more',
    fr: 'Montre plus',
  },
  'Novo grupo de usuários': {
    es: 'Nuevo grupo de usuarios',
    en: 'New user group',
    fr: 'Nouveau groupe d\'utilisateurs',
  },
  'Atenção: Como configurar o modelo': {
    es: 'Atención: cómo configurar la plantilla',
    en: 'Attention: How to set up the template',
    fr: 'Attention : comment configurer le modèle',
  },
  'link único ': {
    es: '',
    en: '',
    fr: '',
  },
  'Por favor, preencha o nome do primeiro signatário.': {
    es: 'Diligencia el nombre del firmante',
    en: 'Fill in the signer\'s name',
    fr: 'Remplissez le nom du signataire',
  },
  'Experimente a versão beta da seção de contatos e participe da melhoria contínua da nossa plataforma!': {
    es: '¡Experimenta la versión beta de la sección de contactos y participa en la mejora continua de nuestra plataforma!',
    en: 'Try out the beta version of the contacts section and take part in the continuous improvement of our platform!',
    fr: 'Essayez la version bêta de la section Contacts et participez à l\'amélioration continue de notre plateforme!',
  },
  'https://forms.gle/v9ehTow5Dda8YVPt9': {
    es: 'https://forms.gle/T6i9rdd1aFQkn7mr7',
    en: 'https://forms.gle/TrcEMYXfrCi48MP4A',
    fr: 'https://forms.gle/trcemyxfrci48mp4a',
  },
  'Eliminar contato': {
    es: 'Eliminar contacto',
    en: 'Delete contact',
    fr: 'Effacer le contact',
  },
  'Eliminar grupo': {
    es: 'Eliminar grupo',
    en: 'Delete group',
    fr: 'Supprimer le groupe',
  },
  'Contatos Salvos': {
    es: 'Contactos Guardados',
    en: 'Saved Contacts',
    fr: 'Contacts sauvés',
  },
  'Buscar contato': {
    es: 'Buscar contacto',
    en: 'Search contact',
    fr: 'Contact de recherche',
  },
  'Criar grupo': {
    es: 'Crear grupo',
    en: 'Create group',
    fr: 'Créer groupe',
  },
  NOME: {
    es: 'NOMBRE',
    en: 'NAME',
    fr: 'NOM',
  },
  TELEFONE: {
    es: 'TELÉFONO',
    en: 'PHONE NUMBER',
    fr: 'NUMÉRO DE PORTABLE',
  },
  'salve contato dos signatários.': {
    es: 'guardar contacto de los firmantes.',
    en: 'save contact of the signatories.',
    fr: 'Enregistrez le contact des signataires.',
  },
  'comece organizar seus contatos em grupos.': {
    es: 'comienza a organizar tus contactos en grupos.',
    en: 'start organizing your contacts into groups.',
    fr: 'Commencez à organiser vos contacts en groupes.',
  },
  'Conheça a nova interface de contatos e grupo de contatos.': {
    es: 'Descubre la nueva interfaz de contactos y grupos de contactos.',
    en: 'Introducing the new contacts and contact group interface.',
    fr: 'Présentation des nouveaux contacts et interface de groupe de contacts.',
  },
  'Agora, a visualização de contatos e grupos de contatos é muito mais agradável para você aproveitar o que a ZapSign oferece de melhor!': {
    es: '¡Ahora, la visualización de contactos y grupos de contactos es mucho más agradable para que disfrutes de lo mejor que ZapSign tiene para ofrecer!',
    en: 'Now, the visualization of contacts and contact groups is much more pleasant for you to enjoy the best of what ZapSign has to offer!',
    fr: 'Maintenant, la visualisation des contacts et des groupes de contacts est beaucoup plus agréable pour vous de profiter du meilleur de ce que Zapsign a à offrir!',
  },
  'CONTATOS SALVOS': {
    es: 'CONTACTOS GUARDADOS',
    en: 'SAVED CONTACTS',
    fr: 'Contacts enregistrés',
  },
  'GRUPO DE CONTATOS': {
    es: 'GRUPO DE CONTACTOS',
    en: 'CONTACT GROUPS',
    fr: 'Groupes de contact',
  },
  CONTATOS: {
    es: 'CONTACTOS',
    en: 'CONTACTS',
    fr: 'CONTACTS',
  },
  GRUPOS: {
    es: 'GRUPOS',
    en: 'GROUPS',
    fr: 'GROUPES',
  },
  'Criar contato': {
    es: 'Crear contacto',
    en: 'Create contact',
    fr: 'Créer un contact',
  },
  ' Contatos': {
    es: ' Contactos',
    en: ' Contacts',
    fr: ' Contacts',
  },
  'assets/img/gifs/new-features/zexp-93-contacts/contacts-list-pt.gif': {
    es: 'assets/img/gifs/new-features/zexp-93-contacts/contacts-list-es.gif',
    en: 'assets/img/gifs/new-features/zexp-93-contacts/contacts-list-en.gif',
    fr: 'Assets / IMG / GIFS / NEW-FEURURES / ZEXP-93 Contacts / Contacts-List-en.gif',
  },
  'Não é possível criar esse contato. Celular e e-mail já cadastrado em nosso sistema.': {
    es: 'No es posible crear este contacto. El celular y correo electrónico ya están registrados en nuestro sistema.',
    en: 'It is not possible to create this contact. The phone number and email are already registered in our system.',
    fr: 'Il n\'est pas possible de créer ce contact. Le numéro de téléphone et les e-mails sont déjà enregistrés dans notre système.',
  },
  'Não é possível criar esse contato. E-mail já cadastrado em nosso sistema.': {
    es: 'No es posible crear este contacto. El correo electrónico ya está registrado en nuestro sistema.',
    en: 'It is not possible to create this contact. The email is already registered in our system.',
    fr: 'Il n\'est pas possible de créer ce contact. L\'e-mail est déjà enregistré dans notre système.',
  },
  'Não é possível criar esse contato. Celular já cadastrado em nosso sistema.': {
    es: 'No es posible crear este contacto. El celular ya está registrado en nuestro sistema.',
    en: 'It is not possible to create this contact. The phone number is already registered in our system.',
    fr: 'Il n\'est pas possible de créer ce contact. Le numéro de téléphone est déjà enregistré dans notre système.',
  },
  'Ocorreu algum erro para criar o contato.': {
    es: 'Ha ocurrido un error al crear el contacto.',
    en: 'An error occurred while creating the contact.',
    fr: 'Une erreur s\'est produite lors de la création du contact.',
  },
  'Para equipes grandes ou crescendo. Para 30 ou mais usuários, fale conosco.': {
    es: 'Para equipos grandes o en crecimiento. Para 30 o más usuarios, contáctenos.',
    en: 'For large or growing teams. For 30 or more users, contact us.',
    fr: 'Pour les équipes grandes ou en pleine croissance. Pour 30 utilisateurs ou plus, contactez-nous.',
  },
  'Usuários adicionais': {
    es: 'Usuarios adicionales',
    en: 'Additional users',
    fr: 'Utilisateurs supplémentaires',
  },
  'Usuário adicional': {
    es: 'Usuario adicional',
    en: 'Additional user',
    fr: 'Utilisateur supplémentaire',
  },
  'Créditos ZapSign': {
    es: 'Créditos ZapSign',
    en: 'Credits ZapSign',
    fr: 'Crédits zapsign',
  },
  'Assinatura em lote ': {
    es: 'Firmas por lote',
    en: 'Batch Signatures',
    fr: 'Signatures par lots',
  },
  'Assinatura realizada com sucesso!': {
    es: 'Firma completada con éxito!',
    en: 'Signature completed successfully!',
    fr: 'Signature complétée avec succès',
  },
  'Limite de documento atingido! Documentos adicionais serão cobrados automaticamente ao finalizar o período.': {
    es: '¡Límite de documentos alcanzado! Documentos adicionales serán cobrados automáticamente al finalizar el período.',
    en: 'Document limit reached! Additional documents will be charged automatically upon the end of the period.',
    fr: 'Limite de document atteint! Des documents supplémentaires seront facturés automatiquement à la fin de la période.',
  },
  ' - Plano ativo até ': {
    es: ' - Plan activo hasta ',
    en: ' - Active plan until ',
    fr: ' - Plan actif jusqu\'à',
  },
  'Aguarde, você está sendo redirecionado...': {
    es: 'Por favor espera, estás siendo redirigido...',
    en: 'Please wait, you are being redirected...',
    fr: 'Veuillez patienter, vous êtes redirigé ...',
  },
  'Você irá fazer o download de uma planilha com dados de': {
    es: 'Descargarás una hoja de cálculo con datos de',
    en: 'You will download a spreadsheet with data from',
    fr: 'Vous téléchargerez une feuille de calcul avec des données de',
  },
  '$0 documento$1.': {
    es: '$0 documento$1.',
    en: '$0 document$1.',
    fr: '$0 Document$1.',
  },
  'Saiba como exportar todos os documentos da sua conta': {
    es: 'Aprende cómo exportar todos los documentos de tu cuenta.',
    en: 'Learn how to export all documents from your account.',
    fr: 'Apprenez à exporter tous les documents de votre compte.',
  },
  'https://clients.zapsign.com.br/help/download-em-massa-de-pdfs': {
    es: 'https://clients.zapsign.com.br/es/help/descarga-masiva-de-pdf',
    en: 'https://clients.zapsign.com.br/en/help/bulk-download-of-pdfs',
    fr: 'https://clients.zapsign.com.br/en/help/bulk-download-of-pdfs',
  },
  'Alertas dos documentos que criei' : {
    es: 'Alertas de los documentos que creé',
    en: 'Alerts from the documents I created',
    fr: 'Alertes pour les documents que j\'ai créés'
  },
  'Alertas de todos os documentos' : {
    es: 'Todas las alertas de documentos',
    en: 'All document alerts\n',
    fr: 'Alertes pour tous les documents'
  },
  'Não receber nenhum alerta' : {
    es: 'No recibir alertas',
    en: 'Not receive any alerts',
    fr: 'Ne recevez aucune alerte'
  },
  'Dados Pessoais': {
    es: 'Info personal',
    en: 'Personal Info',
    fr: 'Données personnelles'
  },
  Notificações: {
    es: 'Notificaciones',
    en: 'Notifications',
    fr: 'Notifications'
  },
  Segurança: {
    es: 'Seguridad',
    en: 'Security',
    fr: 'Sécurité'
  },
  'Preferências de notificação por e-mail': {
    es: 'Preferencias de notificación por correo electrónico',
    en: 'Email notification preferences',
    fr: 'Préférences de notification par e-mail'
  },
  'Quero participar': {
    es: 'Quiero participar',
    en: 'I want to participate',
    fr: 'Je veux participer'
  },
  'Ganhe até R$ 500,00 para cada indicação que você fizer. Crie seu link em menos de 1 minuto!': {
    es: 'Gana hasta 200 dólares por cada referido que compre un plan en ZapSign. Crea tu link y compártelo a tus amigos y conocidos.',
    en: 'Earn up to $200 for each referral who purchases a plan on ZapSign. Create your link and share with your friends.',
    fr: 'Gagnez jusqu\'à 200 dollars pour chaque référence qui achète un plan sur ZapSign. Créez votre lien et partagez-le avec vos amis et connaissances.'
  },
  'Salve uma assinatura que será sugerida sempre que você for assinar um documento': {
    es: 'Guarda tu firma como predeterminada para firmar los documentos que recibas.',
    en: 'Save your signature as the default for signing the documents you receive.',
    fr: 'Enregistrez votre signature comme signature par défaut pour les documents que vous recevez.'
  },
  USUÁRIOS: {
    es: 'USUARIOS',
    en: 'USERS',
    fr: 'UTILISATEURS',
  },
  PREFERÊNCIAS: {
    es: 'PREFERENCIAS',
    en: 'PREFERENCES',
    fr: 'Préférences',
  },
  PERSONALIZAÇÃO: {
    es: 'MARCA',
    en: 'BRANDING',
    fr: 'L\'IMAGE DE MARQUE',
  },
  'Nome, e-mail ou telefone': {
    es: 'Nombre, e-mail o teléfono',
    en: 'Name, e-mail or phone',
    fr: 'Nom, e-mail ou téléphone',
  },
  'Disponível para assinatura até': {
    es: 'Disponible para firma hasta el ',
    en: 'Available for signature until',
    fr: 'Disponible pour signature jusqu\'au',
  },
  'Eleve sua conta para uma solução completa': {
    es: 'Mejora tu cuenta a una solución completa',
    en: 'Upgrade your account to a comprehensive contract',
    fr: 'Améliorez votre compte vers une solution complète'
  },
  'de gerenciamento de contratos e assinaturas': {
    es: 'de gestión de contratos y firmas',
    en: 'and subscription management solution',
    fr: 'de gestion des contrats et des signatures'
  },
  'Assine diversos documentos com apenas 1 clique': {
    es: 'Firma varios documentos con solo 1 clic',
    en: 'Sign multiple documents with just 1 click',
    fr: 'Signez un grand nombre de documents en un seul clic'
  },
  'Garanta melhor eficiência para sua organização': {
    es: 'Asegura una mayor eficiencia para tu organización',
    en: 'Ensure better efficiency for your organization',
    fr: 'Ajouter des utilisateurs à l\'organisation pour gérer des documents'
  },
  'Desbloqueie recursos avançados de autenticação de signatários': {
    es: 'Desbloquea funciones avanzadas de autenticación de firmantes',
    en: 'Unlock advanced signatory authentication features',
    fr: 'Accès à des fonctionnalités supplémentaires pour la création et l\'envoi de documents'
  },
  'Fale com vendas para habilitar essa funcionalidade': {
    es: 'Habla con ventas para habilitar esta funcionalidad',
    en: 'Talk sales to enable this functionality',
    fr: 'Parlez aux ventes pour activer cette fonctionnalité'
  },
  Histórico: {
    es: 'Historial',
    en: 'History',
    fr: 'Historique'
  },
  'Gerenciar serviço adicional': {
    es: 'Administrar servicio adicional',
    en: 'Manage additional service',
    fr: 'Gérer le service additionnel'
  },
  'Política da Cancelamento': {
    es: 'Política de Cancelación',
    en: 'Cancellation Policy',
    fr: 'Politique d\'Annulation'
  },
  contratação: {
    es: 'Contratación',
    en: 'Hiring',
    fr: 'Souscription'
  },
  validade: {
    es: 'Validez',
    en: 'Expiration',
    fr: 'Validation'
  },
  'Gerenciar compra': {
    es: 'Administrar Compra',
    en: 'Manage Purchase',
    fr: 'Gérer l\'achat'
  },
  qtde: {
    es: 'cant',
    en: 'qty',
    fr: 'qua'
  },
  'https://zapsign.com.br/termos-de-uso': {
    es: 'https://zapsign.co/es/termos-de-uso',
    en: 'https://zapsign.co/termos-de-uso',
    fr: 'https://zapsign.co/termos-de-uso'
  },
  'Não é possível gerenciar esse plano': {
    es: 'No es posible gestionar este plan',
    en: 'It\'s not possible to manage this plan',
    fr: 'Il n\'est pas possible de gérer ce plan'
  },
  'Você não tem permissão para enviar documentos. Contate o proprietário da conta.': {
    es: 'No tienes permiso para enviar documentos. Ponte en contacto con el propietario de la cuenta.',
    en: 'You are not allowed to send documents. Contact the account owner.',
    fr: 'Vous n\'êtes pas autorisé à envoyer des documents. Contactez le propriétaire du compte.',
  },
  'Essa funcionalidade não está disponível na sua conta. Você pode contratá-la como um plano adicional': {
    es: 'Esta funcionalidad no está disponible en tu cuenta. Puedes contratarla como un plan adicional',
    en: 'This feature is not available in your account. You can purchase it as an additional plan',
    fr: 'Cette fonctionnalité n\'est pas disponible dans votre compte. Vous pouvez l\'ajouter en tant que plan complémentaire.'
  },
  'Funcionalidade premium disponível apenas para o Plano Completo, API ou Corporativo': {
    es: 'Funcionalidad premium disponible solo para el Plan Business, API o Enterprise',
    en: 'Premium functionality available only for the Business Plan, API, or Enterprise',
    fr: 'Fonctionnalité premium disponible uniquement pour le Plan Business, API ou Enterprise.'
  },
  'Com essa função ativada, você receberá um código de verificação via SMS sempre que fizer login na sua conta, para garantir que somente você tenha acesso à sua conta': {
    es: 'Con esta función activa, recibirás un código de verificación vía SMS cada vez que inicies sesión para asegurar de que solo tú tienes acceso',
    en: 'With this feature enabled, you will receive a verification code via SMS every time you log in to your account, ensuring that only you have access to it.',
    fr: 'Avec cette fonction activée, vous recevrez un code de vérification par SMS à chaque fois que vous vous connectez à votre compte, pour vous assurer que vous êtes le seul à y avoir accès.'
  },
  'Integre a ZapSign ao seu sistema e torne o seu processo ainda mais eficiente.': {
    es: 'Integra ZapSign en tu sistema y haz que tu proceso sea aún más eficiente.',
    en: 'Integrate ZapSign into your system and make your process even more efficient.',
    fr: 'Intégrez ZapSign à votre système et rendez votre processus encore plus efficace.'
  },
  'Funcionalidade premium disponível apenas para o Plano API ou Customizado': {
    es: 'Funcionalidad premium disponible solo para el Plan API o el Plan Enterprise\n',
    en: 'Premium functionality available only for the API Plan or Enterprise Plan',
    fr: 'Fonctionnalité premium disponible uniquement pour le Plan API ou Enterprise.'
  },
  'https://docs.zapsign.com.br/': {
    es: 'https://docs.zapsign.com.br/v/english/',
    en: 'https://docs.zapsign.com.br/v/english/',
    fr: 'https://docs.zapsign.com.br/v/english/'
  },
  'A autenticação de dois fatores requer um telefone. Adicione o seu em "Meu Perfil"': {
    es: 'La autenticación de dos factores requiere un teléfono. Añade el tuyo en "Mi Perfil".',
    en: 'Two-factor authentication requires a phone. Add yours in "My profile".',
    fr: 'FR: L\'authentification à deux facteurs nécessite un téléphone. Ajoutez le vôtre dans "Mon profil".'
  },
  'https://forms.gle/r4knYrD9QpKUd3UT7': {
    es: 'https://forms.gle/T6i9rdd1aFQkn7mr7',
    en: 'https://forms.gle/TrcEMYXfrCi48MP4A',
    fr: 'https://forms.gle/TrcEMYXfrCi48MP4A'
  },
  Gratuito: {
    es: 'Gratis',
    en: 'Free',
    fr: 'Gratuit'
  },
  'Assine agora': {
    es: 'Comprar plan',
    en: 'Purchase plan',
    fr: 'Plan d\'achat'
  },
  'Permita que os membros façam login usando o Provedor SSO da sua organização': {
    es: 'Permite que los miembros de la organización inicien sesión con un proveedor de identidad SSO',
    en: 'Allow members to log in using a Single Sign-On (SSO) identity provider.',
    fr: 'Permet aux membres de l\'organisation de se connecter avec un fournisseur d\'identité SSO'
  },
  'Baixar Metadata da ZapSign': {
    es: 'Descargar Metadatos de ZapSign',
    en: 'Download ZapSign Metadata',
    fr: 'Télécharger les métadonnées de ZaPSign'
  },
  'Adicione a Metadata às configurações SAML do provedor de identidade.': {
    es: 'Adiciona los metadatos de ZapSign en la configuración SAML de tu proveedor de identidad.',
    en: 'Upload ZapSign metadata to your identity provider\'s SAML configuration.',
    fr: 'Ajoutez les métadonnées ZapSign dans la configuration SAML de votre fournisseur d\'identité.'
  },
  'Inserir Metadata do Provider': {
    es: 'Carga los Metadatos del Proveedor',
    en: 'Upload the Provider\'s Metadata',
    fr: 'Télécharger les métadonnées du fournisseur'
  },
  'Escolha entre fazer o upload do arquivo ou adicionar a URL do provedor de identidade.': {
    es: 'Carga un archivo o adiciona una URL con los datos de tu proveedor de identidad.',
    en: 'Upload a file or add a URL with your identity provider\'s data',
    fr: 'Téléchargez un fichier ou ajoutez une URL contenant les coordonnées de votre fournisseur d\'identité.'
  },
  'Definir o Login ID da organização': {
    es: 'Definir el Login ID de la organización',
    en: 'Define the Organization\'s Login ID',
    fr: 'Définir l\'identifiant de connexion de l\'organisation'
  },
  'Configure o código de acesso para os membros da organização.': {
    es: 'Configura un código de acceso para los miembros de la organización.',
    en: 'Set up an access code for organization members.',
    fr: 'Créez un code d\'accès pour les membres de l\'organisation.'
  },
  'Link copiado com sucesso!': {
    es: 'Enlace copiado correctamente',
    en: 'Successfully copied!',
    fr: 'Lien copié avec succès !'
  },
  'Ao clicar em salvar configuração, a solicitação de SSO será enviada para análise.': {
    es: 'La solicitud de SSO está en análisis. En caso de dudas, contactanos al canal de soporte.',
    en: 'Clicking on save configuration, the SSO request will be sent for analysis.',
    fr: 'En cliquant sur Enregistrer la configuration, la demande SSO sera envoyée pour examen.'
  },
  'Salvar configuração': {
    es: 'Guardar cambios',
    en: 'Save settings',
    fr: 'Enregistrer les modifications'
  },
  'Este recurso não está incluído no seu plano atual.': {
    es: 'Esta funcionalidad no esta incluida en el plan actual. Contáctanos para solicitar la activación.',
    en: 'This functionality is not included in the current plan. Please contact us to request its activation.',
    fr: 'Cette fonctionnalité n\'est pas incluse dans le plan actuel. Contactez-nous pour demander l\'activation.'
  },
  'Solicitar SSO': {
    es: 'Solicitar SSO',
    en: 'Request SSO',
    fr: 'Solicitar SSO.'
  },
  'Este recurso está incluído no seu plano atual.': {
    es: 'Esta funcionalidad esta incluida en el plan actual.',
    en: 'This functionality is included in the current plan.',
    fr: 'Cette fonctionnalité est incluse dans votre plan actuel.'
  },
  'Faça a configuração para começar a utilizar.': {
    es: 'Configúralo para empezar a usarlo.',
    en: 'Set it up to start using it.',
    fr: 'Effectuez la configuration pour commencer à utiliser.'
  },
  'Configurar SSO': {
    es: 'Configurar SSO',
    en: 'Configure SSO',
    fr: 'Configurer SSO'
  },
  'Sua solicitação de SSO está em análise. Em caso de dúvidas, entre em contato com o suporte.': {
    es: 'La solicitud de SSO está en análisis. En caso de dudas, contactanos al canal de soporte.',
    en: 'The SSO request is under review. If you have any questions, please contact support@zapsign.com.br.',
    fr: 'La demande de SSO est en cours d\'analyse. En cas de doute, veuillez nous contacter par le biais du canal de support.'
  },
  'Sua solicitação de SSO foi aprovada e a funcionalidade está pronta pra uso. Aproveite!': {
    es: 'La solicitud de SSO fue aprobada y esta disponible.',
    en: 'The SSO request has been approved and is now available.',
    fr: 'L\'application SSO a été approuvée et est disponible.'
  },
  'Erro: Login ID já registrado. Por favor, escolha outro.': {
    es: 'Error: ID de inicio de sesión ya registrado. Por favor elige otro.',
    en: 'Error: Login ID already registered. Please choose another one.',
    fr: 'Erreur : Identifiant de connexion déjà enregistré. Veuillez en choisir un autre.'
  },
  'Não será possível alterar esse Login ID futuramente.': {
    es: 'Una vez guardados los cambios, no será posible cambiar el ID de inicio.',
    en: 'It will not be possible to change the Login ID again.',
    fr: 'Il ne sera pas possible de modifier cet identifiant de connexion ultérieurement.'
  },
  'Arquivos privados: expirar e gerar novos links de download dos documentos a cada 60 minutos.': {
    es: 'Archivos privados: enlaces de descarga de documentos con vencimiento de 60 minutos.',
    en: 'Private files: document download links with 60 minutes expiration.',
    fr: 'Fichiers privés : liens de téléchargement de documents expirant après 60 minutes.'
  },
  'Ativar arquivos privados': {
    es: 'Activar archivos privados',
    en: 'Activate private files',
    fr: 'Activer les fichiers privés',
  },
  'Ao confirmar a ativação, todos os documentos criados a partir de agora serão arquivos privados. Isso signifca que, por questão de segurança e privacidade os links de download expirarão a cada 60 minutos e será necessário baixar o documento novamente para acessar o link atualizado.': {
    es: 'Al confirmar la activación, todos los documentos creados a partir de este momento serán archivos privados. Esto significa que por razones de seguridad los links de descarga de documentos tendrán una vigencia de 60 minutos y después de este tiempo debes volver a descargar el documento.',
    en: 'When you confirm the activation, all documents created from this moment on will be private files. This means that for security reasons, the document download links will be valid for 60 minutes, and after this time, you must download the document again.',
    // tslint:disable-next-line:max-line-length
    fr: 'En confirmant l\'activation, tous les documents créés à partir de maintenant seront considérés comme des fichiers privés. Cela signifie que, pour des raisons de sécurité et de confidentialité, les liens de téléchargement expireront toutes les 60 minutes et il sera nécessaire de télécharger le document à nouveau pour accéder au lien mis à jour.',
  },
  'Atenção: essa ação não poderá ser desfeita.': {
    es: 'Atención: esta acción no podrá deshacerse.',
    en: 'Attention: this action cannot be undone.',
    fr: 'Attention : cette action ne pourra pas être annulée.',
  },
  'Ativar (sem volta)': {
    es: 'Activar (sin retorno)',
    en: 'Activate (no return)',
    fr: 'Activer (irréversible)',
  },
  'Deve conter nome e sobrenome': {
    es: 'Debe contener nombre y apellido',
    en: 'Must contain first name and last name',
    fr: 'Doit contenir prénom et nom de famille',
  },
  'Sua resposta': {
    es: 'Tu respuesta',
    en: 'Your answer',
    fr: 'Votre réponse',
  },
  Arquivo: {
    es: 'Archivo',
    en: 'File',
    fr: 'Fichier',
  },
  'Insira um CPF válido': {
    es: 'Por favor, ingresa un CPF válido',
    en: 'Please enter a valid CPF',
    fr: 'Veuillez entrer un CPF valide',
  },
  'Insira um CNPJ válido': {
    es: 'Por favor, ingresa un CNPJ válido',
    en: 'Please enter a valid CNPJ',
    fr: 'Veuillez entrer un CNPJ valide',
  },
  'Data inválida': {
    es: 'Fecha inválida',
    en: 'Invalid date',
    fr: 'Date invalide',
  },
  'Não foi achado nenhum CEP com esse valor': {
    es: 'No se encontró ningún código postal con este valor',
    en: 'No ZIP code found with this value',
    fr: 'Aucun code postal trouvé avec cette valeur',
  },
  'Exclusivo para assinantes': {
    es: 'Exclusivo para suscriptores',
    en: 'Exclusive for subscribers',
    fr: 'Réservé aux abonnés'
  },
  'https://forms.gle/vPsVRskXYTFDg34K7': {
    es: 'https://forms.gle/T6i9rdd1aFQkn7mr7',
    en: 'https://forms.gle/TrcEMYXfrCi48MP4A',
    fr: 'https://forms.gle/TrcEMYXfrCi48MP4A'
  },
  'Experimente a versão beta de Detalhes do Documento e participe da melhoria contínua da nossa plataforma!': {
    es: '¡Prueba la versión beta de Detalles del Documento y participa en la mejora continua de nuestra plataforma!',
    en: 'Try the beta version of Document Details and participate in the continuous improvement of our platform!',
    fr: 'Testez la version bêta de Détails du Document et participez à l\'amélioration continue de notre plateforme!'
  },
  'Conheça a nova interface de detalhes do documento': {
    es: 'Descubre la nueva interfaz de detalles del documento.',
    en: 'Explore the new document details interface.',
    fr: 'Découvrez la nouvelle interface des détails du document'
  },
  'Gerencie seu documento e acompanhe quantas vezes o documento foi visualizado pelo signatário\nRedesenhamos para simplificar ao máximo a sua experiência em nossa plataforma.': {
    es: 'Administra tu documento y mira cuántas veces el firmante lo visualizo. Hemos rediseñado para simplificar al máximo tu experiencia en nuestra plataforma.',
    en: 'Manage your document and track how many times the document has been viewed by the signer. We\'ve redesigned to simplify your experience on our platform to the fullest.',
    fr: 'Gérez votre document et suivez combien de fois le document a été consulté par le signataire. Nous avons repensé pour simplifier au maximum votre expérience sur notre plateforme.'
  },
  'Limite para assinatura': {
    es: 'Fecha límite',
    en: 'Date limit',
    fr: 'Date limite'
  },
  'Editar campos': {
    es: 'Editar campos',
    en: 'Edit fields',
    fr: 'Éditer champs'
  },
  'Documento original': {
    es: 'Documento original',
    en: 'Original document',
    fr: 'Document original'
  },
  'Relatório do documento': {
    es: 'Informe del documento',
    en: 'Document Report',
    fr: 'Rapport du document'
  },
  'DISPONÍVEL PARA ASSINATURA ATÉ:': {
    es: 'DISPONIBLE PARA FIRMA HASTA:',
    en: 'AVAILABLE FOR SIGNATURE UNTIL:',
    fr: 'DISPONIBLE POUR SIGNATURE JUSQU\'À'
  },
  '$0/$1 ASSINATURAS': {
    es: '$0/$1 FIRMAS',
    en: '$0/$1 SIGNATURES',
    fr: '$0/$1 ABONNEMENTS'
  },
  '$0/$1 Assinaturas': {
    es: '$0/$1 Firmas',
    en: '$0/$1 Signatures',
    fr: '$0/$1 Abonnements'
  },
  'Assinou o documento': {
    es: 'Firmado el documento',
    en: 'Signed the document',
    fr: 'Document signé'
  },
  'Recusou o documento': {
    es: 'Rechazó el documento',
    en: 'Refused the document',
    fr: 'A refusé le document'
  },
  COPIAR: {
    es: 'COPIAR',
    en: 'COPY',
    fr: 'COPIER'
  },
  'Visualizou o documento $0 vezes.': {
    es: 'Visualizó el documento $0 veces.',
    en: 'Viewed the document $0 times.',
    fr: 'A visualisé le document $0 fois.'
  },
  'Última visualização $0': {
    es: 'Última visualización $0',
    en: 'Last view $0',
    fr: 'CRÉÉ LE'
  },
  'CRIADO EM': {
    es: 'CREADO EL',
    en: 'CREATED AT',
    fr: 'CRÉÉ LE'
  },
  'AUTOR:': {
    es: 'AUTOR:',
    en: 'AUTHOR: ',
    fr: 'AUTEUR: '
  },
  'Não visualizou o documento': {
    es: 'No visualizó el documento',
    en: 'Did not view the document',
    fr: 'N\'a pas visualisé le document.'
  },
  'Editar signatário': {
    es: 'Editar firmante',
    en: 'Edit signer',
    fr: 'Modifier le signataire.'
  },
  'Visualizou o documento 1 vez': {
    es: 'Visualizó el documento 1 vez',
    en: 'Viewed the document 1 time',
    fr: 'A visualisé le document 1 fois.'
  },
  'Enviar lembrete por e-mail': {
    es: 'Enviar recordatorio por e-mail',
    en: 'Send reminder by email',
    fr: 'Envoyer un rappel par e-mail.'
  },
  'Justificativa da recusa': {
    es: 'Razón de rechazo',
    en: 'Reason for refusal',
    fr: 'Raison du refus'
  },
  'Não abriu o link.': {
    es: 'No se abrió el enlace.',
    en: 'The link was not opened.',
    fr: 'Le lien n\'a pas été ouvert.'
  },
  'O signatário ainda não respondeu ou confirmou o formulário.': {
    es: 'El firmante aún no ha respondido ni confirmado el formulario.',
    en: 'The signer has not yet responded or confirmed the form.',
    fr: 'Le signataire n\'a pas encore répondu ou confirmé le formulaire.'
  },
  'Editar formulário': {
    es: 'Editar formulario',
    en: 'Edit Form',
    fr: 'Modifier le formulaire'
  },
  'Documentos do envelope': {
    es: 'Documentos del sobre',
    en: 'Documents from the envelope',
    fr: 'Documents de l\'enveloppe'
  },
  'Compartilhe o documento por WhatsApp ou qualquer outro canal que o signatário preferir.': {
    es: 'Comparte el documento a través de WhatsApp o cualquier otro canal preferido por el firmante.',
    en: 'Share the document via WhatsApp or any other preferred channel by the signer.',
    fr: 'Partagez le document via WhatsApp ou tout autre canal préféré par le signataire.'
  },
  'Não há pastas dentro de ': {
    es: 'No hay carpetas dentro de ',
    en: 'There are no folders inside ',
    fr: 'Il n\'y a pas de dossiers à l\'intérieur de '
  },
  '$0 subpasta$1': {
    es: '$0 subcarpeta$1',
    en: '$0 subfolder$1',
    fr: '$0 sous-dossier$1'
  },
  'A partir de 05/09/2023, a versão beta será oficial na interface de contatos.': {
    es: 'A partir del 09/05/2023 la versión beta será oficial en la interfaz de contactos.',
    en: 'From 09/05/2023, the beta version will be official in the contacts interface.',
    fr: 'A partir du 09/05/2023, la version bêta sera officielle dans l\'interface contacts.'
  },
  'Seu documento está assinado!': {
    es: 'Documento firmado exitosamente',
    en: 'Document successfully signed',
    fr: 'Document signé avec succès'
  },
  'Uma cópia do documento foi enviada para seu e-mail.': {
    es: 'Enviamos una copia del documento a tu correo electrónico.',
    en: 'We have sent a copy of the document to your email',
    fr: 'Nous envoyons une copie du document à votre adresse électronique.'
  },
  'Você receberá uma cópia do documento por e-mail assim que todos tiverem finalizado sua assinatura.': {
    es: 'Recibirás una copia del documento por correo electrónico cuando todos firmen el documento.',
    en: 'You will receive a copy of the document by email once everyone has signed the document.',
    fr: 'Vous recevrez une copie du document par e-mail lorsque tout le monde aura signé le document.'
  },
  'Notificamos o autor do documento sobre a sua recusa. Qualquer dúvida, entre em contato com o remetente.': {
    es: 'Notificamos al autor del documento sobre tu rechazo. Cualquier duda, ponte en contacto con el remitente. ',
    en: 'We have notified the document\'s author about your rejection. If you have any questions, please get in touch with the sender.',
    fr: 'Nous informons l\'auteur du document de votre refus. Si vous avez des questions, veuillez contacter l\'expéditeur.'
  },
  'Baixar todos assinados': {
    es: 'Descargar todos firmados',
    en: 'Download all signed',
    fr: 'Télécharger tous les signés'
  },
  'Baixar documento assinado': {
    es: 'Descargar documento firmado',
    en: 'Download signed document',
    fr: 'Télécharger le document signé'
  },
  'Relatório de assinatura (carregando...)': {
    es: 'Informe de firma (cargando...)',
    en: 'Signature report (loading...)',
    fr: 'Rapport de signature (chargement...)'
  },
  'Relatório de assinatura': {
    es: 'Informe de firma',
    en: 'Signature report',
    fr: 'Rapport de signature'
  },
  'Acessar minha conta ZapSign': {
    es: 'Acceder a mi cuenta en ZapSign',
    en: 'Access my account on ZapSign',
    fr: 'Se connecter à mon compte ZapSign'
  },
  'Uma cópia foi salva na sua conta!': {
    es: 'Se guardó una copia en tu cuenta',
    en: 'A copy has been saved in your account',
    fr: 'Une copie a été enregistrée dans votre compte'
  },
  'Crie uma conta para salvar o e acessar os seus documentos quando precisar!': {
    es: 'Crea una cuenta gratis para guardar y acceder a los documentos  en cualquier momento.',
    en: 'Create a free account to save and access your documents at any time.',
    fr: 'Créez un compte gratuit pour enregistrer vos documents et y accéder à tout moment.'
  },
  'Documento complementar': {
    es: 'Documento complementario',
    en: 'Supplementary document',
    fr: 'Document complémentaire'
  },
  'Para fins de segurança e privacidade, os links de download expiram em 60 minutos.': {
    es: 'Por motivos de seguridad y privacidad, los enlaces de descarga expiran en 60 minutos.',
    en: 'For security and privacy reasons, download links expire in 60 minutes.',
    fr: 'Pour des raisons de sécurité et de confidentialité, les liens de téléchargement expirent dans les 60 minutes.'
  },
  'Descubra o que a ZapSign pode fazer pelo seu negócio!': {
    es: '¡Descubre lo que ZapSign puede hacer por tu negocio!',
    en: 'Discover what ZapSign can do for your business!',
    fr: 'Découvrez ce que ZapSign peut faire pour votre entreprise !'
  },
  'Aumente a eficiência da sua empresa ao coletar assinaturas digitais com validade. Agilize seus processos e ganhe tempo na criação de documentos.': {
    es: 'Aumenta la eficiencia de tu empresa al recopilar firmas digitales válidas. Agiliza tus procesos y ahorra tiempo en la creación de documentos.',
    en: 'Increase your company\'s efficiency by collecting valid digital signatures. Streamline your processes and save time creating documents.',
    fr: 'Augmentez l\'efficacité de votre entreprise en collectant des signatures numériques valides. Rationalisez vos processus et gagnez du temps dans la création de documents.'
  },
  'Testar grátis': {
    es: 'Probar gratis',
    en: 'Try for free',
    fr: 'Essayer gratuitement'
  },
  relatorio: {
    es: 'Informe',
    en: 'Report',
    fr: 'Rapports'
  },
  'assets/img/imagem-signatario.svg': {
    es: 'assets/img/imagem-signatarioES.svg',
    en: 'assets/img/imagem-signatarioEn.svg',
    fr: 'assets/img/imagem-signatarioEn.svg'
  },
  'Documento fora do prazo de assinatura!': {
    es: '¡Documento fuera del plazo de firma!',
    en: 'Document out of signature period!',
    fr: 'Document hors période de signature !'
  },
  'Ops! Esse documento já foi fechado. Solicite ao proprietário para alterar a data limite de assinatura.': {
    es: '¡Vaya! Este documento ya está cerrado. Solicita al propietario que cambie la fecha límite de firma.',
    en: 'Oops! This document is already closed. Ask the owner to change the signature deadline.',
    fr: 'Oops! Ce document est déjà fermé. Demandez au propriétaire de modifier la date limite de signature.'
  },
  'Suporte PRO': {
    es: 'Soporte PRO',
    en: 'PRO Suppport',
    fr: 'Assistance PRO'
  },
  'Tenha o suporte dedicado com um time especialista e humanizado': {
    es: 'Obten soporte dedicado de un equipo especializado',
    en: 'Get dedicated support from a specialist and humanized team',
    fr: 'Bénéficiez d\'un accompagnement dédié d\'une équipe spécialisée et humanisée'
  },
  'Buscar por': {
    es: 'Buscar por',
    en: 'Search by',
    fr: 'Rechercher par'
  },
  Envelope: {
    es: 'Sobre',
    en: 'Envelope',
    fr: 'Enveloppe'
  },
  'Envelopes criados': {
    es: 'Sobres creados',
    en: 'Envelopes created',
    fr: 'Enveloppes créés'
  },
  'Envelopes assinados': {
    es: 'Sobres firmados',
    en: 'Envelopes signed',
    fr: 'Enveloppes signés'
  },
  'Envelopes em curso': {
    es: 'Sobres en curso',
    en: 'Envelopes in progress',
    fr: 'Enveloppes en cours'
  },
  'Envelopes recusados': {
    es: 'Sobres rechazados',
    en: 'Envelopes refused',
    fr: 'Enveloppes refusées'
  },
  'Erro: Ocorreu um erro para reconhecer a sua assinatura, por favor utilize um método de assinatura diferente. Ou atualize a tela e tente novamente': {
    es: 'Error: Hubo un error al reconocer tu firma, por favor utiliza un método diferente o actualiza la pantalla e intenta nuevamente',
    en: 'Error: There was an error recognizing your signature, please use a different signing method. Or refresh the screen and try again',
    fr: 'Une erreur s\'est produite lors de la reconnaissance de votre signature, veuillez utiliser une méthode de signature différente. Ou actualisez l\'écran et réessayez'
  },
  'Erro: Ocorreu um erro para reconhecer o seu visto, por favor utilize um método de assinatura diferente. Ou atualize a tela e tente novamente': {
    es: 'Error: Hubo un error al reconocer tus iniciales, por favor utiliza un método diferente. O actualiza la pantalla e intenta nuevamente',
    en: 'Error: There was an error recognizing your initials, please use a different signing method. Or refresh the screen and try again',
    fr: 'Une erreur s\'est produite lors de la reconnaissance de Rvos initiales, veuillez utiliser une méthode de signature différente. Ou actualisez l\'écran et réessayez'
  },
  'Desativar assinatura em lote': {
    es: 'Desactivar firma en lote',
    en: 'Deactivate batch signing',
    fr: 'Désactiver la signature par lot'
  },
  'Assinar documento': {
    es: 'Firmar documento',
    en: 'Sign document',
    fr: 'Signer le document'
  },
  'Ler documento': {
    es: 'Leer documento',
    en: 'Read document',
    fr: 'Lire document'
  },
  'Parabéns, você assinou todos os seus documentos': {
    es: 'Felicidades, has firmado todos tus documentos',
    en: 'Congratulations, you have signed all your documents',
    fr: 'Félicitations, vous avez signé tous vos documents'
  },
  'Ver minhas assinaturas': {
    es: 'Ver mis firmas',
    en: 'See my signatures',
    fr: 'Voir mes signatures'
  },
  'busca nao encontrada': {
    es: 'No se encontraron resultados',
    en: 'No results found',
    fr: 'Aucun résultat trouvé'
  },
  'Aguarde, estamos registrando sua assinatura...': {
    es: 'Espera, estamos registrando tu firma...',
    en: 'Wait, we are registering your signature...',
    fr: 'Attendez, nous enregistrons votre signature...'
  },
  'A assinatura em lote será removida de $0 documentos, e essa ação não poderá ser desfeita.': {
    es: 'La firma en lote será desactivada permanentemente para $0 documentos y la acción no tiene vuelta atrás.',
    en: 'Batch signing will be removed from $0 documents, and this action cannot be undone.',
    fr: 'La signature en lot sera supprimée de $0 documents et cette action ne peut pas être annulée.'
  },
  'Desativar permanentemente': {
    es: 'Desactivar permanentemente',
    en: 'Deactivate permanently',
    fr: 'Désactiver définitivement'
  },
  'Atenção! Complete o seu cadastro para a utilização correta da assinatura via API': {
    es: 'Compra un plan API para utilizar correctamente la firma vía API.',
    en: 'Purchase an API plan to properly utilize the signature via API.',
    fr: 'Achetez un plan API pour utiliser correctement la signature via l\'API'
  },
  'Atualizar Token': {
    es: 'Actualizar token',
    en: 'Update token',
    fr: 'Mettre à jour le jeton'
  },
  'Não foi possível deletar': {
    es: 'No fue posible eliminarlo',
    en: 't was not possible to delete it',
    fr: 'Il n\'a pas été possible de le supprimer'
  },
  'Você não tem permissão para apagar este documento. Entre em contato com o autor do documento para prosseguir.' : {
    es: 'No tienes permiso para eliminar este documento. Ponte en contacto con el propietario de la cuenta para continuar.',
    en: 'You do not have permission to delete this document. Contact the document author to proceed.',
    fr: 'Vous n\'êtes pas autorisé à supprimer ce document. Contactez l\'auteur du document pour continuer.'
  },
  'Permite o envio de código via WhatsApp quando o signatário não recebe o código SMS. Tem um custo de 5 créditos para cada envio.': {
    es: 'Permitir enviar código por WhatsApp cuando el firmante no recibe el código SMS. Tiene un costo de 5 créditos por cada envío.',
    en: 'Allow to send WhatsApp code when the signer does not receive the SMS code. It has a cost of 5 credits for each sending.',
    fr: 'Permettre l\'envoi de code par WhatsApp lorsque le destinataire ne reçoit pas le code par SMS. Cela a un coût de 5 crédits par envoi.'
  },
  'Arraste e solte ou ': {
    es: 'Suelta el archivo aquí o',
    en: 'Drag and drop or ',
    fr: 'Glissez et déposez ou '
  },
  'busque pelo arquivo': {
    es: 'busca el archivo',
    en: 'search for the file',
    fr: 'recherchez le fichier'
  },
  'Buscar arquivo': {
    es: 'Buscar archivo',
    en: 'Search for file',
    fr: 'Chercher fichier'
  },
  'Escreva o motivo da recusa do documento': {
    es: 'Escribe el motivo de rechazar el documento',
    en: 'Write the reason for the document\'s rejection',
    fr: 'Indiquez le motif du rejet du document'
  },
  'Não vou assinar o documento': {
    es: 'No voy a firmar el documento',
    en: 'I will not sign the document',
    fr: 'Je ne signerai pas le document'
  },
  'Tem certeza que deseja recusar este documento?': {
    es: '¿Estás seguro que quieres rechazar este documento?',
    en: 'Are you sure you want to reject this document?',
    fr: 'Êtes-vous sûr de vouloir refuser ce document ?'
  },
  'Se você optar por recusar, não será possível realizar a assinatura no futuro e o autor do documento será informado.': {
    es: 'Si decides rechazar el documento, no será posible firmarlo a futuro y el autor del documento será informado.',
    en: 'If you choose to reject, it will not be possible to sign in the future, and the document\'s author will be notified.',
    fr: 'Si vous décidez de rejeter le document, il ne sera plus possible de le signer à l\'avenir et l\'auteur du document en sera informé.'
  },
  'Recusar o documento': {
    es: 'Rechazar el documento',
    en: 'Reject the document',
    fr: 'Refuser le document'
  },
  'Ambiente seguro ZapSign by Truora': {
    es: 'Ambiente seguro ZapSign por Truora',
    en: 'Secure Environment ZapSign by Truora',
    fr: 'Environnement sécurisé ZapSign par Truora'
  },
  'A partir de 02/10/2023, a versão beta será oficial na interface de documentos.': {
    es: 'A partir del 02/10/2023 la versión beta será oficial en la interfaz de documentos.',
    en: 'Starting from 02/10/2023, the beta version will become official in the document interface.',
    fr: 'À partir du 02/10/2023, la version bêta sera officielle dans l\'interface des documents.',
  },
  'Experimente a versão beta da seção de Meu Perfil e participe da melhoria contínua da nossa plataforma!': {
    es: '¡Prueba la versión beta de la sección Mi Perfil y participa en la mejora continua de nuestra plataforma!',
    en: 'Try the beta version of the My Profile section and participate in the continuous improvement of our platform!',
    fr: 'Essayez la version bêta de la section Mon Profil et participez à l\'amélioration continue de notre plateforme!',
  },
  '$0 USUÁRIOS': {
    es: '$0 USUARIOS',
    en: '$0 USERS',
    fr: '$0 UTILISATEURS'
  },
  '$0 USUÁRIO': {
    es: '$0 USUARIO',
    en: '$0 USER',
    fr: '$0 UTILISATEUR'
  },
  'TODOS OS USUÁRIOS': {
    es: 'TODOS LOS USUARIOS',
    en: 'ALL USERS',
    fr: 'TOUS LES UTILISATEURS',
  },
  'GRUPO DE USUÁRIOS': {
    es: 'GRUPO DE USUARIO',
    en: 'USER GROUP',
    fr: 'GROUPE D\'UTILISATEURS',
  },
  'https://forms.gle/BDVMrpWLVcJHiZq36': {
    es: 'https://forms.gle/T6i9rdd1aFQkn7mr7',
    en: 'https://forms.gle/TrcEMYXfrCi48MP4A',
    fr: 'https://forms.gle/syuHrxGYDEb7Mrx8A'
  },
  'Experimente a versão beta de usuários da organização e participe da melhoria contínua da nossa plataforma!': {
    es: '¡Prueba la versión beta de usuarios de la organización y participa en la mejora continua de nuestra plataforma!',
    en: 'Try the organization\'s user beta version and take part in the continuous improvement of our platform!',
    fr: 'Essayez la version bêta utilisateur de l\'organisation et participez à l\'amélioration continue de notre plateforme!'
  },
  'Conheça a nova interface de usuários.': {
    es: 'Descubre la nueva interfaz de usuario.',
    en: 'Discover the new user interface.',
    fr: 'Découvrez la nouvelle interface utilisateur.'
  },
  'Agora, a visualização de usuários é muito mais agradável para você aproveitar o que a ZapSign oferece de melhor!': {
    es: '¡Ahora, la visualización de usuarios es mucho más agradable para que aproveches al máximo lo que ZapSign tiene para ofrecer!',
    en: 'Now, user visualization is much more enjoyable for you to make the most of what ZapSign has to offer!',
    fr: 'Maintenant, la visualisation des utilisateurs est bien plus agréable pour vous permettre de profiter au mieux de ce que ZapSign a à offrir!'
  },
  'E-mail do usuário': {
    es: 'Correo del usuario',
    en: 'User\'s email',
    fr: 'E-mail de l\'utilisateur'
  },
  'Eliminar usuário': {
    es: 'Eliminar usuario',
    en: 'Delete user',
    fr: 'Supprimer l\'utilisateur'
  },
  'Eliminar usuário da organização': {
    es: 'Eliminar usuario de la organización',
    en: 'Remove user from the organization',
    fr: 'Supprimer l\'utilisateur de l\'organisation'
  },
  'O limite de usuários para o seu plano foi atingido': {
    es: 'Has alcanzado el límite de usuarios para tu plan',
    en: 'You have reached the user limit for your plan',
    fr: 'Vous avez atteint la limite d\'utilisateurs pour votre plan'
  },
  'Alterar proprietário': {
    es: 'Cambiar propietario',
    en: 'Change owner',
    fr: 'Changer de propriétaire'
  },
  ' USUÁRIOS': {
    es: ' USUARIOS',
    en: ' USERS',
    fr: ' UTILISATEURS'
  },
  'Restaurar versão original': {
    es: 'Restaurar a versión',
    en: 'Restore to the original version',
    fr: 'Restaurer à la version'
  },
  'organize seus usuários em grupos com diferentes níveis de acesso.': {
    es: 'organiza a los usuarios en grupos con diferentes niveles de acceso.',
    en: 'organize your users into groups with different levels of access.',
    fr: 'organisez les utilisateurs en groupes avec des autorisations différentes.'
  },
  '$0-$1 de $2 usuários': {
    es: '$0-$1 de $2 usuarios',
    en: '$0-$1 of $2 users',
    fr: '$0-$1 de $2 utilisateurs',
  },
  'Eliminar grupo de usuário': {
    es: 'Eliminar grupo de usuario',
    en: 'Delete user group',
    fr: 'Supprimer le groupe d\'utilisateurs'
  },
  'Admin (Todos os documentos e configurações)': {
    es: 'Administrador (Todos los documentos y ajustes)',
    en: 'Admin (All documents and settings)',
    fr: 'Administrateur (Tous les documents et paramètres)',
  },
  'Membro (Todos os documentos)': {
    es: 'Miembro (Todos los documentos)',
    en: 'Member (All documents)',
    fr: 'Membre (Tous les documents)',
  },
  'Limitado (Próprios documentos)': {
    es: 'Limitado (Documentos propios)',
    en: 'Limited (Own documents)',
    fr: 'Limité (Documents personnels)',
  },
  'Foto de um relógio para indicar que não é sua vez de assinar.': {
    es: 'Foto de un reloj para indicar que no te toca firmar.',
    en: 'Photo of a clock to indicate that it is not your turn to sign.',
    fr: 'Photo d\'une horloge pour indiquer que ce n\'est pas à vous de signer.',
  },
  'Não é a sua vez de assinar.': {
    es: 'Aún no es tu turno de firmar.',
    en: 'It\'s not your turn to sign.',
    fr: 'Ce n\'est pas à vous de signer.',
  },
  'Sua assinatura não está liberada para esse documento porque há outras pessoas que devem assiná-lo antes de você.': {
    es: 'El documento no esta disponible para que lo firmes porque hay otras personas que deben firmarlo antes que tu.',
    en: 'Your signature is not available for this document because there are other people who must sign it before you.',
    fr: 'Votre signature n\'est pas libérée pour ce document car d\'autres personnes doivent signer avant vous',
  },
  'Não se preocupe, enviaremos uma notificação quando sua vez chegar.': {
    es: 'No te preocupes, te notificaremos cuando sea tu turno.',
    en: 'Don\'t worry, you will be notified when your turn arrives.',
    fr: 'Ne vous inquiétez pas, vous serez averti lorsque ce sera votre tour de signer.',
  },
  'Assinar com o celular': {
    es: 'Firmar con el celular',
    en: 'Sign with the mobile phone',
    fr: 'Signez avec votre portable',
  },
  'Você pode usar seu celular para assinar. Para isso basta escanear o QR code abaixo com a sua câmera': {
    es: 'Puedes utilizar tu celular para firmar. Solo tienes que escanear el código QR de abajo con tu cámara',
    en: 'You can use your phone to sign. Simply scan the QR code below with your camera',
    fr: 'Vous pouvez utiliser votre téléphone portable pour signer. Il vous suffit de scanner le code QR ci-dessous avec l\'appareil photo de votre téléphone portable.',
  },
  'Enviar código por WhatsApp': {
    es: 'Enviar código vía WhatsApp',
    en: 'Send code via WhatsApp',
    fr: 'Envoyer le code via WhatsApp',
  },
  'Insira o código de 6 letras que foi enviado $0': {
    es: 'Escribe el código de 6 letras que enviamos $0',
    en: 'Enter the 6 letter code that was sent $0',
    fr: 'Saisissez le code à 6 chiffres que nous vous envoyons $0',
  },
  'para o seu email': {
    es: 'a tu correo',
    en: 'to your email',
    fr: 'votre email',
  },
  'via SMS': {
    es: 'via SMS',
    en: 'via SMS',
    fr: 'via SMS',
  },
  'para o seu WhatsApp': {
    es: 'a WhatsApp',
    en: 'to WhatsApp',
    fr: 'ton WhatsApp',
  },
  'Reenviar código por WhatsApp': {
    es: 'Reenviar código vía WhatsApp',
    en: 'Resend code via WhatsApp',
    fr: 'Renvoyer le code via WhatsApp',
  },
  'Preencha seu Nome Completo, Celular e Assinatura': {
    es: 'Diligencia su nombre completo, celular y firma',
    en: 'Fill in your full name, phone number, and signature',
    fr: 'Remplissez votre nom complet, votre numéro de téléphone et votre signature',
  },
  'Gerenciar documento': {
    es: 'Gestionar documento',
    en: 'Manage document',
    fr: 'Gérer le document',
  },
  'Compartilhar documento': {
    es: 'Compartir documento',
    en: 'Share document',
    fr: 'Partager le document',
  },
  'Eliminar documento': {
    es: 'Eliminar documento',
    en: 'Delete document',
    fr: 'Supprimer le document'
  },
  'Carimbo de tempo': {
    es: 'Marca de tiempo',
    en: 'Time stamp',
    fr: 'Horodatage',
  },
  'Adicione um carimbo comprovando o momento exato da assinatura': {
    es: 'Añade un sello que acredite el momento exacto de la firma',
    en: 'Add a stamp proving the exact moment of signing',
    fr: 'Ajouter un tampon prouvant le moment exact de la signature',
  },
  'Apenas alerta de conclusão': {
    es: 'Al finalizar el documento',
    en: 'At the end of the document',
    fr: 'À la fin du document',
  },
  'À cada assinatura e conclusão': {
    es: 'Por cada firmante y al finalizar',
    en: 'Each signature and at the end',
    fr: 'À chaque signature et à la fin',
  },
  'Adicionar e-mail': {
    es: 'Agregar correo electrónico',
    en: 'Add email',
    fr: 'Ajouter un e-mail',
  },
  'Alerta de conclusão do documento': {
    es: 'Alerta de finalización del documento',
    en: 'Document Completion Alert',
    fr: 'Alerte de fin de document',
  },
  'Alerta de cada assinatura e conclusão do documento': {
    es: 'Alerta de cada firma y finalización del documento',
    en: 'Alert for Each Signature and Document Completion',
    fr: 'Alerte pour Chaque Signature et Fin de Document',
  },
  'https://feedback-br.zapsign.com.br': {
    es: 'https://feedback-es.zapsign.com.br',
    en: 'https://feedback-en.zapsign.com.br',
    fr: 'https://feedback-en.zapsign.com.br',
  },
  'Bloquear salvar a assinatura no dispositivo ao assinar documentos.': {
    es: 'Bloquear el guardado de firma en el dispositivo al firmar documentos',
    en: 'Block saving signature on device when signing documents',
    fr: 'Enregistrer la signature sur ce dispositif pour signer des documents ultérieurs',
  },
  'Enviamos uma cópia dos documentos para seu e-mail': {
    es: 'Enviamos una copia de los documentos firmados a tu correo electrónico',
    en: 'We will send a copy of the documents to your email',
    fr: 'Nous vous enverrons une copie des documents à votre adresse e-mail',
  },
  'Seus documentos foram assinados com sucesso!': {
    es: '¡Tus documentos han sido firmados exitosamente!',
    en: 'Your documents have been signed successfully!',
    fr: 'Vos documents ont été signés avec succès!',
  },
  'Preencha o formulário e sua assinatura': {
    es: 'Diligencia el formulario y tu firma',
    en: 'Fill in the form and your signature',
    fr: 'Remplissez le formulaire et votre signature!',
  },
  'Abrir modal de exemplo': {
    es: 'Abrir modal de ejemplo',
    en: 'Open example',
    fr: 'Exemple ouvert',
  },
  'Gravar Liveness': {
    es: 'Grabar video',
    en: 'Record Liveness',
    fr: 'Enregistrer les Liveness'
  },
  'Remover Liveness': {
    es: 'Quitar Liveness',
    en: 'Remove Liveness',
    fr: 'Supprimer Liveness'
  },
  'Não conseguimos validar o Liveness, tente novamente.': {
    es: 'No pudimos validar el video, inténtalo de nuevo.',
    en: 'We couldn\'t validate the video, please try again.',
    fr: 'Nous n\'avons pas pu valider la vidéo, veuillez réessayer.'
  },
  'Grave seu rosto em um local bem iluminado\nseguindo as instruções da tela.': {
    es: 'Graba tu rostro en un lugar bien iluminado\nsiguiendo las instrucciones en pantalla.',
    en: 'Record your face in a well-lit location\nfollowing the on-screen instructions.',
    fn: 'Enregistrez votre visage dans un endroit bien éclairé\nen suivant les instructions à l\'écran.'
  },
  Compartilhar: {
    es: 'Compartir',
    en: 'Share',
    fr: 'Partager'
  },
  ' - POR': {
    es: ' - POR',
    en: ' - BY',
    fr: ' - PAR'
  },
  POR: {
    es: 'POR',
    en: 'BY',
    fr: 'PAR'
  },
  Relatório: {
    es: 'Informe',
    en: 'Report',
    fr: 'Rapport',
  },
  Original: {
    es: 'Original',
    en: 'Original',
    fr: 'Original'
  },
  'Selecionar pasta de destino': {
    es: 'Seleccionar carpeta de destino',
    en: 'Select destination folder',
    fr: 'Sélectionner le dossier de destination'
  },
  'Baixar todos os documentos': {
    es: 'Descargar todos los documentos',
    en: 'Download all documents',
    fr: 'Télécharger tous les documents'
  },
  'Atenção: Verificamos que você ainda não possui uma pasta. Favor dirigir-se à tela de documentos para criar uma.': {
    es: 'Atención: Hemos notado que aún no tienes una carpeta. Por favor, dirígete a la pantalla de documentos para crear una.',
    en: 'Attention: We have noticed that you do not yet have a folder. Please go to the document screen to create one.',
    fr: 'Attention : Nous avons remarqué que vous n\'avez pas encore de dossier. Veuillez vous rendre sur l\'écran des documents pour en créer un.'
  },
  'Por favor assine abaixo': {
    es: 'Por favor, firma a continuación',
    en: 'Please sign below',
    fr: 'Veuillez signer ci-dessous'
  },
  'Acessar minhas cópias': {
    es: 'Acceder a copias',
    en: 'Access my copies',
    fr: 'Accéder à mes copies'
  },
  'Remover assinatura': {
    es: 'Eliminar firma',
    en: 'Remove signature',
    fr: 'Supprimer la signature',
  },
  'Abrir camera': {
    es: 'Abrir cámara',
    en: 'Open camera',
    fr: 'Prendre une photo',
  },
  'Você receberá um código por e-mail para concluir a assinatura e garantir segurança do documento.': {
    es: 'Recibirás un código por correo electrónico para completar la firma y garantizar la seguridad del documento.',
    en: 'You will receive a code by email to complete the signature and ensure document security.',
    fr: 'Vous recevrez un code par e-mail pour garantir la sécurité de la signature électronique.'
  },
  'Salvar assinatura para assinar documentos futuros.': {
    es: 'Guardar firma para firmar documentos futuros.',
    en: 'Save signature to sign future documents.',
    fr: 'Enregistrer la signature pour signer les documents futurs.',
  },
  'Extensões de arquivo permitidas: .png ou.jpeg': {
    es: 'Extensiones de archivo permitidas: .png o .jpeg',
    en: 'Allowed file extensions: .png or .jpeg',
    fr: 'Fichiers au format: .png ou .jpeg',
  },
  upload: {
    es: 'Subir',
    en: 'Upload',
    fr: 'Télécharger',
  },
  Digitar: {
    es: 'Escribir',
    en: 'Type',
    fr: 'Taper',
  },
  'assets/img/fakeSignatures/Assine-aqui.svg': {
    es: 'assets/img/fakeSignatures/Firma-aqui.svg',
    en: 'assets/img/fakeSignatures/Sign-here.svg',
    fr: 'assets/img/fakeSignatures/Signez-ici.svg',
  },
  'Experimente a versão beta de preferências e participe da melhoria contínua da nossa plataforma!': {
    es: '¡Prueba la versión beta de preferencias y participa en la mejora continua de nuestra plataforma!',
    en: 'Try the beta version of preferences and take part in the continuous improvement of our platform!',
    fr: 'Essayez la version bêta des préférences et participez à l\'amélioration continue de notre plateforme !'
  },
  'https://forms.gle/uwYdXyPL5sLHDTNH9': {
    es: 'https://forms.gle/T6i9rdd1aFQkn7mr7',
    en: 'https://forms.gle/TrcEMYXfrCi48MP4A',
    fr: 'https://forms.gle/syuHrxGYDEb7Mrx8A'
  },
  'Idioma da organização': {
    es: 'Idioma de la organización',
    en: 'Organization Language',
    fr: 'Langues de l’entreprise'
  },
  'Aplicado para todos os usuários da organização': {
    es: 'Aplica a todos los usuarios de la organización',
    en: 'Applied to all organization users',
    fr: 'Appliqué à tous les utilisateurs de l\'organisation'
  },
  'Defina a experiência de quem receberá o documento para assinar': {
    es: 'Define la experiencia para aquellos que recibirán el documento para firmar',
    en: 'Define the experience for those who will receive the document to sign',
    fr: 'Définissez l\'expérience des personnes qui recevront le document à signer.'
  },
  'Assinar com “Upload de imagem”': {
    es: 'Firmar con “Carga de imagen”',
    en: 'Sign with “Upload Signature”',
    fr: 'Signature avec \'Chargement d\'image\''
  },
  'Isto é, selecionar uma foto ou imagem salva da sua assinatura': {
    es: 'Seleccionar una foto o imagen guardada de tu firma',
    en: 'Select a saved photo or image of your signature',
    fr: 'Sélectionnez une photo ou une image enregistrée de votre signature'
  },
  'Assinar com “Digitar”': {
    es: 'Firmar con “Escribir”',
    en: 'Sign with “Typing”',
    fr: 'Signature “écrite”'
  },
  'Isto é, escolher uma assinatura cursiva pronta, criada a partir do nome completo do signatário': {
    es: 'Elegir una firma cursiva predefinida creada a partir del nombre completo del firmante',
    en: 'Choose a predefined cursive signature created from the signer\'s full name',
    fr: 'Choisissez une signature cursive prédéfinie créée à partir du nom complet du signataire.'
  },
  'Isto é, não permitir que o signatário salve sua assinatura ao assinar o documento': {
    es: 'Es decir, no permitir que el firmante guarde su firma al firmar el documento.',
    en: 'That means, not allowing the signer to save their signature when signing the document',
    fr: 'Cela signifie, ne pas permettre au signataire d\'enregistrer sa signature lors de la signature du document'
  },
  'Neste caso, também será solicitada autenticação adicional por e-mail ou SMS': {
    es: 'En este caso, también se solicitará verificar el correo electrónico o SMS',
    en: 'In this case, email or SMS verification will also be requested',
    fr: 'Dans ce cas, une authentification supplémentaire sera également demandée par courriel ou SMS'
  },
  'Expirar link de assinatura automaticamente': {
    es: 'Caducar automáticamente el enlace de firma',
    en: 'Automatically expire the signature link',
    fr: 'Expiration automatique du lien de la signature'
  },
  'O link será automaticamente desativado após assinatura do signatário': {
    es: 'El enlace se desactivará automáticamente después de la firma del firmante',
    en: 'The link will be automatically deactivated after the signer\'s signature',
    fr: 'Le lien sera automatiquement désactivé après la signature du signataire.'
  },
  ATIVAR: {
    es: 'ACTIVAR',
    en: 'ACTIVATE',
    fr: 'ACTIVER'
  },
  'Informações do relatório': {
    es: 'Información del informe de firmas',
    en: 'Signature Report Information',
    fr: 'Informations tirées du rapport'
  },
  'Defina as informações que devem aparecer na página final do documento assinado': {
    es: 'Configura la información a mostrar en la página final del documento firmado',
    en: 'Configure the information to be displayed on the final page of the signed document',
    fr: 'Spécifiez les informations qui doivent figurer sur la dernière page du document signé.'
  },
  'Visualizar documento assinado pelos demais signatários': {
    es: 'Ver el documento firmado por otros firmantes',
    en: 'View the document signed by other signers',
    fr: 'Voir le document signé par d\'autres signataires'
  },
  'Na primeira etapa da assinatura, o signatário deve preferencialmente visualizar o documento assinado pelos outros signatários, em vez do documento original sem assinaturas': {
    es: 'Los firmantes verán el documento con las firmas de los otros firmantes que ya firmaron y no el documento original',
    en: 'Signers will see the document with the signatures of other signers who have already signed, not the original document',
    fr: 'Lors de la première étape de la signature, le signataire verra le document signé par d\'autres signataires, au lieu du document original sans signatures'
  },
  'Exibir autor do documento no e-mail para signatários': {
    es: 'Mostrar el autor del documento en el correo electrónico para los firmantes',
    en: 'Display the document author in the email to signatories',
    fr: 'Indiquer l\'auteur du document dans l\'e-mail des signataires'
  },
  'Isto é, exibir quem criou o documento nos e-mails que são enviados para os signatários': {
    es: 'Mostrar el creador del documento en los correos electrónicos enviados a los firmantes',
    en: 'Display the document creator in the emails sent to the signers',
    fr: 'Indiquer l\'auteur du document dans les courriels envoyés aux signataires'
  },
  'Exibir e-mail do autor no relatório do documento': {
    es: 'Mostrar el correo electrónico del autor en el informe del documento',
    en: 'Display the author\'s email in the document report',
    fr: 'Afficher l\'adresse électronique de l\'auteur dans le rapport du document'
  },
  'Isto é, exibir no relatório de assinaturas o e-mail do usuário que criou o documento na ZapSign': {
    es: 'Mostrar el correo electrónico del usuario que creó el documento en el informe de firma de ZapSign',
    en: 'Display the email of the user who created the document in the ZapSign signature report',
    fr: 'Afficher l\'adresse électronique de l\'utilisateur qui a créé le document dans le rapport de signature de ZapSign'
  },
  'Separar relatórios de assinatura dos documentos assinados': {
    es: 'Separar el informe de firma de los documentos firmados',
    en: 'Separate the signature report from the signed documents',
    fr: 'Séparer les rapports de signature des documents signés'
  },
  'Isto é, gerar um arquivo com o documento assinado e outro com o relatório de assinaturas': {
    es: 'Generar un archivo con el documento firmado y otro con el informe de firmas',
    en: 'Generate a file with the signed document and another one with the signature report',
    fr: 'Générer un fichier avec le document signé et un fichier avec le rapport de signature.'
  },
  'Fuso horário do relatório final': {
    es: 'Zona horaria del informe final',
    en: 'Time zone of the final report',
    fr: 'Fuseau horaire du rapport final'
  },
  'O fuso horário é aplicado no registro de data e hora da assinatura': {
    es: 'La zona horaria se aplica al registro de fecha y hora de la firma',
    en: 'The time zone is applied to the date and time stamp of the signature',
    fr: 'Le fuseau horaire est appliqué à la date et à l\'heure de la signature.'
  },
  ' dias': {
    es: ' días',
    en: ' days',
    fr: ' jours',
  },
  'Vou trocar de plano': {
    es: 'Voy a cambiar de planes',
    en: 'I\'m going to change plans',
    fr: 'Je vais changer de plan',
  },
  'Vou mudar a forma de pagamento': {
    es: 'Cambiaré el método de pago',
    en: 'I will change the payment method',
    fr: 'Je vais changer de mode de paiement',
  },
  'Não posso pagar no momento': {
    es: 'No puedo pagar en este momento',
    en: 'I can\'t pay at the moment',
    fr: 'Je ne peux pas payer pour le moment',
  },
  'Tenho pouca demanda de assinaturas': {
    es: 'Tengo poca demanda de firma de documentos',
    en: 'I have low demand for document signing',
    fr: 'J\'ai peu de demandes de signature de documents',
  },
  'Não estou utilizando o serviço': {
    es: 'No estoy usando el servicio',
    en: 'I\'m not using the service',
    fr: 'Je n\'utilise pas le service',
  },
  'A plataforma é difícil de usar / equipe não se adaptou à ZapSign': {
    es: 'La plataforma es difícil de usar/el equipo no se ha adaptado a ZapSign',
    en: 'Platform is difficult to use / team has not adapted to ZapSign',
    fr: 'La plateforme est difficile à utiliser / l\'équipe ne s\'est pas adaptée à ZapSign',
  },
  'A plataforma têm erros ou mal funcionamento': {
    es: 'La plataforma tiene errores o mal funcionamiento',
    en: 'The platform has errors or malfunctions',
    fr: 'La plateforme présente des erreurs ou des dysfonctionnements',
  },
  'A plataforma não resolveu meu problema': {
    es: 'La plataforma no resolvió mi problema',
    en: 'The platform did not solve my problem',
    fr: 'La plateforme n\'a pas résolu mon problème',
  },
  'Bloquear assinatura fora da ordem definida para signatários': {
    es: 'Bloquear firma fuera del orden definido para los firmantes',
    en: 'Block signature out of the defined order for signatories',
    fr: 'Verrouiller la signature hors de l\'ordre défini pour les signataires'
  },
  'Isto é, o acesso ao documento será concedido ao signatário atual, enquanto os demais signatários serão notificados por e-mail quando for a vez deles assinarem': {
    es: 'Esto significa que el acceso al documento se otorgará al firmante actual, mientras que los otros firmantes serán notificados por correo electrónico cuando sea su turno de firmar',
    en: 'This means that access to the document will be granted to the current signatory, while the other signatories will be notified by email when it\'s their turn to sign',
    fr: 'L\'accès au document sera accordé au signataire actuel, tandis que les autres signataires seront avertis par courrier électronique lorsque leur tour viendra de signer.'
  },
  'Liberar envio por WhatsApp após falha no envio por SMS': {
    es: 'Permitir el envío a través de WhatsApp después de un fallo en el envío por SMS',
    en: 'Allow sending via WhatsApp after a failure in sending via SMS',
    fr: 'Permettre l\'envoi via WhatsApp après l\'échec de l\'envoi de SMS'
  },
  'Esta configuração tem custo de 5 créditos por envio de código por WhatsApp': {
    es: 'Esta configuración tiene un costo de 5 créditos por el envío de un código a través de WhatsApp',
    en: 'This configuration has a cost of 5 credits per code sent via WhatsApp',
    fr: 'Cette configuration a un coût de 5 crédits pour l\'envoi d\'un code via WhatsApp.'
  },
  'Documentos como arquivos privados': {
    es: 'Documentos como archivos privados',
    en: 'Documents as private files',
    fr: 'Les documents en tant qu\'archives privées'
  },
  'Garanta a segurança e privacidade do documento com um link de download do documento original e assinado que expira a cada 60 minutos': {
    es: 'Garantice la seguridad y privacidad del documento con un enlace de descarga del documento original y firmado que caduca cada 60 minutos',
    en: 'Ensure the security and privacy of the document with a download link for the original and signed document that expires every 60 minutes',
    fr: 'Il garantit la sécurité et la confidentialité des documents grâce à un lien de téléchargement vers le document original signé, qui expire dans les 60 minutes.'
  },
  documento: {
    es: 'documento',
    en: 'document',
    fr: 'document'
  },
  'Confirme sua identidade para assinar o documento': {
    es: 'Confirma tu identidad para firmar el documento',
    en: 'Confirm your identity to sign the document',
    fr: 'Prenez une photo de votre visage et de votre carte d\'identité'
  },
  'Assine usando o desenho na tela do celular': {
    es: 'Experiencia de firma optimizado para celular',
    en: 'Mobile-Optimized Signing Experience',
    fr: 'Une expérience de signature optimisée pour les mobiles'
  },
  'Assinar pelo celular': {
    es: 'Firmar en el celular',
    en: 'Sign on the phone',
    fr: 'Signer sur votre portable'
  },
  'Adicionar selfie': {
    es: 'Adicionar selfie',
    en: 'Add a photo of your face',
    fr: 'Rajouter votre selfie'
  },
  'Fotografe o seu rosto em um lugar iluminado': {
    es: 'Toma la foto de tu rostro con buena ilumincación ',
    en: 'Take a photo of your face with good lighting',
    fr: 'Prenez une photo de votre visage sous un bon éclairage'
  },
  'Frente do documento de identidade (RG, CPF ou CNH)': {
    es: 'Frente del documento de identidad ',
    en: 'Front of the ID document',
    fr: 'Recto de la carte d\'identité'
  },
  'Verso do documento de identidade (RG, CPF ou CNH)': {
    es: 'Reverso del documento de identidad ',
    en: 'Back of the ID document',
    fr: 'Verso de la carte d\'identité'
  },
  'Retire o documento do plástico e fotografe com boa iluminação': {
    es: 'Toma la foto con buena ilumincación',
    en: 'Take the photo with good lighting',
    fr: 'Prenez la photo dans un bon éclairage'
  },
  'Frente do documento': {
    es: 'Frente del documento',
    en: 'Front of the ID',
    fr: 'Recto de la carte d\'identité'
  },
  'Verso do documento': {
    es: 'Reverso del documento',
    en: 'Back of the ID',
    fr: 'Verso de la carte d\'identité'
  },
  'Começar novo documento': {
    es: 'Empezar nuevo documento',
    en: 'Start a new document',
    fr: 'Commencer nouveau document'
  },
  'Continuar com o documento': {
    es: 'Continuar con el documento',
    en: 'Continue with the document',
    fr: 'Continuer avec le document'
  },
  'Insira o código de 6 letras que enviamos por $0 para ': {
    es: 'Digita el código de 6 letras que enviamos via $0 para ',
    en: 'Enter the 6-letter code we sent you via $0 to ',
    fr: 'Saisissez le code à 6 chiffres que nous vous envoyons sur votre $0 '
  },
  'Meu saldo': {
    es: 'Mi saldo',
    en: 'My balance',
    fr: 'Mon solde'
  },
  'Comprar créditos': {
    es: 'Comprar créditos',
    en: 'Purchase Credits',
    fr: 'Acheter des crédits'
  },
  'Consumo de crédito por funcionalidade': {
    es: 'Consumo de crédito por funcionalidad',
    en: 'Credit consumption per feature',
    fr: 'Consommation de crédit par fonctionnalité'
  },
  'Envio automático do documento e lembretes pelo WhatsApp': {
    es: 'Envío automático del documento y recordatorios por WhatsApp',
    en: 'Automatic document sending and reminders delivery via WhatsApp',
    fr: 'Envoi automatique de documents et rappel par WhatsApp'
  },
  'Histórico de uso de créditos': {
    es: 'Historial de uso de créditos',
    en: 'Credit usage history',
    fr: 'Historique de l\'utilisation du crédit'
  },
  '5 créditos correspondem a R$0,5 BRL': {
    es: '5 créditos equivalen a $0,1 USD',
    en: '5 credits equal $0.1 USD',
    fr: '5 crédits équivalent à 0,1 USD'
  },
  'Por favor aguarde, assinando seus documentos...': {
    es: 'Por favor espera, firmando tus documentos...',
    en: 'Please wait, signing your documents...',
    fr: 'Aguarde, enviando seus documentos...'
  },
  'Por favor, assine abaixo': {
    es: 'Por favor, firma abajo',
    en: 'Please sign below',
    fr: 'Veuillez signer ci-dessous',
  },
  'Busca não encontrada': {
    es: 'Búsqueda no encontrada',
    en: 'Search not found',
    fr: 'Recherche introuvable'
  },
  'Assinando o documento...': {
    es: 'Firmando el documento...',
    en: 'Signing the document...',
    fr: 'Signature en cours de traitement...'
  },
  'Garantindo a validade jurídica...': {
    es: 'Garantizando validez jurídica...',
    en: 'Ensuring legal validity...',
    fr: 'Assurer la validité légale...'
  },
  'Coletando dados...': {
    es: 'Analizando datos...',
    en: 'Analyzing data...',
    fr: 'Analyse des données...'
  },
  'Autenticando...': {
    es: 'Autenticando...',
    en: 'Authenticating...',
    fr: 'Authentication...'
  },
  'Assinado!': {
    es: '¡Firmado!',
    en: 'Signed!',
    fr: 'Signé !'
  },
  'Aguarde um instante, estamos assinando e validando seu documento': {
    es: 'Un momento, estamos firmando y validando tu documento',
    en: 'One moment, we are signing and validating your document.',
    fr: 'Un instant, nous signons et validons votre document'
  },
  'Falha na assinatura. Ocorreu um erro inesperado.': {
    es: 'Ocurrio un error inesperado.',
    en: 'An unexpected error occurred.',
    fr: 'Désolé, il y a eu une erreur inattendue'
  },
  'Falha na assinatura': {
    es: 'Falla en la firma ',
    en: 'Signature failure',
    fr: 'Erreur de la signature'
  },
  'Biometria facial - Prova de vida': {
    es: 'Biometría facial - Prueba de vida',
    en: 'Facial biometrics - Proof of life',
    fr: 'Biométrie faciale – Preuve de vie'
  },
  'A partir de 24/10/2023, a versão beta será oficial para preferências.': {
    es: 'A partir del 24/10/2023, la versión beta será oficial para las preferencias.',
    en: 'Starting on 10/24/2023, the beta version will be official for preferences.',
    fr: 'À partir du 24/10/2023, la version bêta sera officielle pour les préférences.'
  },
  'A partir de 24/10/2023, a versão beta será oficial para gerenciamento de usuários.': {
    es: 'A partir del 24/10/2023, la versión beta será oficial para la gestión de usuarios.',
    en: 'Starting from 10/24/2023, the beta version will be official for user management.',
    fr: 'À partir du 24/10/2023, la version bêta sera officielle pour la gestion des utilisateurs.'
  },
  'A partir de 24/10/2023, a versão beta será oficial em detalhes do documento.': {
    es: 'A partir del 24/10/2023, la versión beta será oficial en los detalles del documento.',
    en: 'Starting from 10/24/2023, the beta version will be official in the document details.',
    fr: 'À partir du 24/10/2023, la version bêta sera officielle dans les détails du document.'
  },
  'Assinar $0 documentos': {
    es: 'Firmar $0 documentos',
    en: 'Sign $0 documents',
    fr: 'Signer $0 documents'
  },
  'Envelope com $0 documentos': {
    es: 'Sobre con $0 documentos',
    en: 'Envelope with $0 documents',
    fr: 'Enveloppe avec $0 documents'
  },
  'Insira seu nome completo': {
    es: 'Introduce tu nombre completo',
    en: 'Enter your full name',
    fr: 'Entrez votre nom complet',
  },
  'Permita que os membros façam login usando o seu Provedor SSO': {
    es: 'Permite que los miembros inicien sesión usando un proveedor SSO',
    en: 'Allow members to log in using a SSO provider',
    fr: 'Utilisez un fournisseur d\'identité pour gérer les utilisateurs'
  },
  'Buscar na galeria': {
    es: 'Buscar en la galería',
    en: 'Search in the gallery',
    fr: 'Rechercher dans la galerie'
  },
  'Experimente a versão beta de integrações e participe da melhoria contínua da nossa plataforma!': {
    es: '¡Prueba la versión beta de las integraciones y participa en la mejora continua de nuestra plataforma!',
    en: 'Try the beta version of integrations and participate in the continuous improvement of our platform!',
    fr: 'Essayez la version bêta des intégrations et participez à l\'amélioration continue de notre plateforme !'
  },
  'Integrações nativas com as seguintes plataformas. Basta instalar a ZapSign e está pronto para começar a usar': {
    es: 'Integraciones nativas con las siguientes plataformas. Automatiza las funciones de ZapSign con las plataformas:',
    en: 'Native integrations with the following platforms. Just install ZapSign, and you\'re ready to start using it',
    fr: 'Intégrations natives avec les plateformes suivantes. Il vous suffit d\'installer ZapSign et vous êtes prêt à commencer à l\'utiliser'
  },
  'Nos fluxo automatizados de WhatsApp você pode incluir a ZapSign para assinar documentos ou templates': {
    es: 'Usa ZapSign para enviar documentos por medio de una línea propia de WhatsApp.',
    en: 'In our automated WhatsApp flows, you can include ZapSign to sign documents or templates',
    fr: 'Dans nos flux WhatsApp automatisés, vous pouvez inclure ZapSign pour signer des documents ou des modèles'
  },
  'Integrar aplicativo': {
    es: 'Integrar la aplicación',
    en: 'Integrate the application',
    fr: 'Intégrer l\'application'
  },
  'No CRM Hubspot de cada empresa, crie documentos e compartilhe-os para coletar assinaturas': {
    es: 'En el CRM de HubSpot de cada empresa, crea documentos y compártalos para que firmen el documento',
    en: 'In each company\'s HubSpot CRM, create documents and share them to collect signatures',
    fr: 'Dans le CRM HubSpot de chaque entreprise, créez des documents et partagez-les pour recueillir des signatures'
  },
  'Na plataforma IXC para provedores de telecomunicações, crie documentos e compartilhe-os para coletar assinaturas': {
    es: 'En la plataforma IXC para proveedores de telecomunicaciones, crea documentos y compártalos para recopilar firmas',
    en: 'On the IXC platform for telecommunications providers, create documents and share them to collect signatures',
    fr: 'Sur la plateforme IXC pour les fournisseurs de télécommunications, créez des documents et partagez-les pour recueillir des signatures'
  },
  'Aplicativos Plug\'nPlay': {
    es: 'Aplicaciones Plug\'nPlay',
    en: 'Plug\'nPlay Applications',
    fr: 'Applications Plug\'nPlay'
  },
  'Integre a ZapSign sem escrever nenhuma linha de código via': {
    es: 'Integra ZapSign sin escribir ninguna línea de código a través de',
    en: 'Integrate ZapSign without writing any lines of code via',
    fr: 'Intégrez ZapSign sans écrire la moindre ligne de code grâce à'
  },
  'Pluga, Make ou Zapier': {
    es: 'Pluga, Make o Zapier',
    en: 'Pluga, Make or Zapier',
    fr: 'Pluga, Make ou Zapier'
  },
  'Salve uma cópia em uma plataforma de nuvem quando um documento é assinado': {
    es: 'Guarde una copia en una plataforma en la nube cuando un documento es creado y/o firmado',
    en: 'Save a copy to a cloud platform when a document is signed',
    fr: 'Sauvegardez une copie sur une plateforme cloud lorsqu\'un document est signé'
  },
  'Selecione a plataforma que você deseja vincular à sua conta ZapSign': {
    es: 'Selecciona la plataforma que quieres vincular a tu cuenta ZapSign',
    en: 'Select the platform you want to link to your ZapSign account',
    fr: 'Sélectionnez la plateforme que vous souhaitez lier à votre compte ZapSign'
  },
  'Automatize a criação e envio de documentos com informações de contato do CRM': {
    es: 'Automatiza la creación y el envío de documentos con información de contacto del CRM',
    en: 'Automate the creation and sending of documents with CRM contact information',
    fr: 'Automatise la création et l\'envoi de documents contenant des informations de contact CRM.'
  },
  'Conheça outras plataformas para integrar de acordo com as necessidades da sua empresa': {
    es: 'Conoce otras plataformas para integrar de acuerdo a las necesidades de tu empresa',
    en: 'Explore other platforms to integrate according to your company\'s needs',
    fr: 'Découvrez d\'autres plateformes à intégrer pour répondre aux besoins de votre entreprise'
  },
  'Integre a ZapSign com plataformas de e-commerce, sites ERP e muito mais': {
    es: 'Integra ZapSign con plataformas de comercio electrónico, ERP y mucho más',
    en: 'Integrate ZapSign with e-commerce platforms, ERP sites, and much more',
    fr: 'Intégrez ZapSign à des plateformes de commerce électronique, des sites ERP et bien plus encore.'
  },
  'Tutorial como integrar': {
    es: 'Guía de cómo integrar',
    en: 'Integration tutorial',
    fr: 'Tutoriel sur l’intégration'
  },
  'Vincular com Make': {
    es: 'Vincular con Make',
    en: 'Link with Make',
    fr: 'Lier avec Make'
  },
  'Vincular com Pluga': {
    es: 'Vincular con Pluga',
    en: 'Link with Pluga',
    fr: 'Lier avec Pluga'
  },
  'Conhecer aplicativos': {
    es: 'Conocer aplicaciones',
    en: 'Discover applications',
    fr: 'Découvrir'
  },
  'Permite que desenvolvedores de software integrem a ZapSign a outros sistemas': {
    es: 'Permite que los desarrolladores de software integren ZapSign con otros sistemas',
    en: 'Allows software developers to integrate ZapSign with other systems',
    fr: 'Permet aux développeurs de logiciels d\'intégrer ZapSign à d\'autres systèmes'
  },
  'Planos de API': {
    es: 'Planes API',
    en: 'API Plans',
    fr: 'Plans API'
  },
  'Webhooks permitem que outros sistemas sejam notificados quando ocorrerem eventos na ZapSign. Você receberá uma requisição POST em cada uma das URLs cadastradas': {
    es: 'Los webhooks permiten que otros sistemas sean notificados cuando ocurran eventos en ZapSign. Recibirás una solicitud POST en cada una de las URL registradas',
    en: 'Webhooks allow other systems to be notified when events occur in ZapSign. You will receive a POST request at each of the registered URLs',
    fr: 'Les webhooks permettent à d\'autres systèmes d\'être notifiés lorsque des événements se produisent dans ZapSign. Vous recevrez une demande POST sur chacune des URL enregistrées'
  },
  'Eventos Webhook': {
    es: 'Eventos Webhook',
    en: 'Webhook Events',
    fr: 'Évènements Webhooks'
  },
  'As integrações feitas com o token atual vão parar de funcionar, será necessário atualizar o token de acesso em cada integração que já foi configurada.': {
    es: 'Las integraciones realizadas con el token actual dejarán de funcionar, será necesario actualizar el token de acceso en cada integración que ya haya sido configurada.',
    en: 'Integrations made with the current token will stop working, it will be necessary to update the access token in each integration that has already been configured.',
    fr: 'Les intégrations faites avec le jeton actuel cesseront de fonctionner, il sera nécessaire de mettre à jour le jeton d\'accès dans chaque intégration déjà configurée.'
  },
  'Desativar API': {
    es: 'Desactivar la API',
    en: 'Deactivate the API',
    fr: 'Désactiver l\'API'
  },
  'Atenção! Todas as integrações feitas deixarão de funcionar se você desativar API': {
    es: 'Atención: ¡Todas las integraciones realizadas dejarán de funcionar si desactivas la API!',
    en: 'Attention: All integrations made will stop working if you deactivate the API!',
    fr: 'Attention : Toutes les intégrations effectuées cesseront de fonctionner si vous désactivez l\'API !'
  },
  'Acesse a ZapSign através de um computador para gerenciar os Eventos Webhook': {
    es: 'Acceda a ZapSign a través de un computador para gestionar Eventos Webhook',
    en: 'Access ZapSign through a computer to manage Webhook Events',
    fr: 'Accédez à ZapSign via un ordinateur pour gérer les Événements Webhook'
  },
  'Solte aqui': {
    es: 'Soltar aquí',
    en: 'Drop here',
    fr: 'Déposer ici'
  },
  'Até $0 arquivos de $1Mb/cada': {
    es: 'Hasta $0 archivos de  $1Mb/cada uno',
    en: 'Up to $0 files of  $1Mb/each',
    fr: 'Jusqu\'à $0 fichiers de $1 Mb/chacun'
  },
  'Subir outro arquivo': {
    es: 'Sube otro archivo',
    en: 'Upload another file',
    fr: 'Téléchargez un autre fichier'
  },
  'Você atingiu o limite de arquivos': {
    es: 'Has alcanzado el límite de archivos',
    en: 'You have reached the file limit',
    fr: 'Vous avez atteint la limite de fichiers'
  },
  'https://clients.zapsign.com.br/help/como-integrar-a-zapsign-ao-ixc': {
    es: 'https://clients.zapsign.com.br/es/help/integración-entre-sistemas',
    en: 'https://clients.zapsign.com.br/en/help/systems-integration',
    fr: 'https://clients.zapsign.com.br/en/help/systems-integration'
  },
  'Ao ativar esta configuração, todos os documentos futuros serão privados e o link do documento original e assinado serão temporários, expirando a cada 60 minutos.': {
    es: 'Al activar esta configuración, todos los documentos futuros serán privados y el enlace al documento original y firmado será temporales, expirando cada 60 minutos.',
    en: 'By enabling this setting, all future documents will be private, and the link to the original signed document will be temporary, expiring every 60 minutes.',
    fr: 'En activant ce paramètre, tous les documents futurs seront privés et le lien vers le document original et signé sera temporaire, expirant toutes les 60 minutes.'
  },
  'Será necessário acessar o detalhes do documento para ter acesso ao novo link de download do arquivo.': {
    es: 'Será necesario acceder a los detalles del documento para obtener el nuevo enlace de descarga del archivo.',
    en: 'Accessing the document details will be required to obtain the new file download link.',
    fr: 'Il faudra accéder aux détails du document pour obtenir le nouveau lien de téléchargement du fichier.'
  },
  'Atenção, essa configuração não pode ser desfeita.': {
    es: 'Ten en cuenta que esta configuración no se puede deshacer.',
    en: 'Please note that this configuration cannot be undone.',
    fr: 'Veuillez noter que cette configuration est irréversible.'
  },
  'Ativar permanentemente': {
    es: 'Activar de forma permanente.',
    en: 'Activate permanently.',
    fr: 'Activer de manière permanente.'
  },
  Novidade: {
    es: 'Novedad',
    en: 'Change announcement',
    fr: 'NOUVELLES',
  },
  'A experiência de assinatura foi otimizada!': {
    es: '¡Optimizamos la experiencia de firma!',
    en: 'We\'ve upgraded the signing experience!',
    fr: 'L\'expérience d\'abonnement a été optimisée !',
  },
  'Entendemos que o processo de assinatura é uma parte essencial de todos os negócios. Por isso, a partir de hoje, os seus signatarios terão uma experiência de assinatura otimizada e intuitiva.': {
    es: 'Sabemos lo importante que es firmar documentos en cualquier negocio. Por eso, a partir de hoy, tus firmantes van a tener una experiencia de firma más fácil y amigable.',
    en: 'We understand how crucial signing documents is for any business. That\'s why, starting today, your signers will enjoy a more straightforward and user-friendly signing experience.',
    fr: 'Nous comprenons que le processus d\'abonnement est une partie essentielle de toute entreprise. C\'est pourquoi, à partir d\'aujourd\'hui, vos signataires bénéficieront d\'une expérience de signature optimisée et intuitive.',
  },
  'OK, entendi': {
    es: 'Ok, entiendo',
    en: 'Okay, got it',
    fr: 'D\'accord, j\'ai compris.',
  },
  'assets/img/gif_assinaura_1.gif': {
    es: 'assets/img/gif_firma_1.gif',
    en: 'assets/img/gif_signature_1.gif',
    fr: 'assets/img/gif_signature_1.gif',
  },
  'O número do documento será automaticamente detectado pelo sistema, então a pergunta não aparecerá no formulário.': {
    es: 'El número del documento de ZapSign será detectado automáticamente por el sistema, por lo que la pregunta no aparecerá en el formulario.',
    en: 'ZapSign\'s document number will be automatically detected by the system, so the question will not appear on the form.',
    fr: 'Le numéro du document sera automatiquement détecté par le système, donc la question ne s\'affichera pas dans le formulaire.'
  },
  'Confirmo ter lido': {
    es: 'Confirmo haber leído',
    en: 'I confirm that I have read',
    fr: 'Je confirme avoir lu'
  },
  'Recusar documento': {
    es: 'Rechazar documento',
    en: 'Reject document',
    fr: 'Refuser document',
  },
  'Envio do código de validação do signatário por WhatsApp': {
    es: 'Envío del código de validación del firmante via WhatsApp',
    en: 'Sending the signer\'s validation code via WhatsApp',
    fr: 'Envoi du code de validation du signataire via WhatsApp'
  },
  'Documento adicional criado via Modelos, acima do limite do plano': {
    es: 'Documento adicional creado mediante plantillas, por encima del límite del plan',
    en: 'Additional document created via Templates, above the plan limit',
    fr: 'Document additionnel créé via des modèles, au-dessus de la limite du plan'
  },
  'Método de autenticação do signatário: Certificado digital': {
    es: 'Método de autenticación del firmante: Certificado digital',
    en: 'Signer\'s authentication method: Digital Certificate',
    fr: 'Méthode d\'authentification du signataire : Certificat numérique'
  },
  'Método de autenticação do signatário: Reconhecimento Facial': {
    es: 'Método de autenticación del firmante: Reconocimiento Facial',
    en: 'Signer\'s authentication method: Facial Recognition',
    fr: 'Méthode d\'authentification \'reconnaissance faciale\''
  },
  'Método de autenticação do signatário: Biometria facial - Prova de vida': {
    es: 'Método de autenticación del firmante: Biométrica facial - Prueba de vida',
    en: 'Signer\'s authentication method: Facial Biometrics - Proof of life',
    fr: 'Méthode d\'authentification du signataire : Biométrie faciale - Preuve de vie'
  },
  'Documento adicional para planos limitados': {
    es: 'Documento adicional para planes limitados',
    en: 'Additional document for limited plans',
    fr: 'Document additionnel pour les plans limités'
  },
  'Busca por Antecedentes Legais e verificação do CPF': {
    es: 'Búsqueda de Antecedentes Legales y verificación del CPF',
    en: 'Search for Legal Background and CPF verification',
    fr: 'Recherche d\'Antécédents Judiciaires et vérification du CPF'
  },
  '5 por envio': {
    es: '5 por envío',
    en: '5 per delivery',
    fr: '5 par envoi'
  },
  '5 por documento': {
    es: '5 por documento',
    en: '5 per document',
    fr: '5 par document'
  },
  '5 por assinatura': {
    es: '5 por firma',
    en: '5 per signature',
    fr: '5 par signature'
  },
  '15 por assinatura': {
    es: '15 por firma',
    en: '15 per signature',
    fr: '15 par signature'
  },
  '25 por documento': {
    es: '25 por documento',
    en: '25 per document',
    fr: '25 par document'
  },
  '90 por busca': {
    es: '90 por búsqueda',
    en: '90 per search',
    fr: '90 par recherche'
  },
  'Minha conta': {
    es: 'Mi cuenta',
    en: 'My account',
    fr: 'Mon compte'
  },
  'Minhas organizações': {
    es: 'Mis Organizaciones',
    en: 'My Organizations',
    fr: 'Mes Organisations'
  },
  crédito: {
    es: 'crédito',
    en: 'credit',
    fr: 'crédit'
  },
  créditos: {
    es: 'créditos',
    en: 'credits',
    fr: 'crédits'
  },
  'Saldo:': {
    es: 'Saldo:',
    en: 'Balance:',
    fr: 'Solde:'
  },
  'exemplo@email.com': {
    es: 'ejemplo@email.com',
    en: 'example@email.com',
    fr: 'exemple@email.com'
  },
  'Experimente a versão beta de personalização e participe da melhoria contínua da nossa plataforma!': {
    es: '¡Prueba la versión beta de personalización y participa en la mejora continua de nuestra plataforma!',
    en: 'Try the beta version of personalization and participate in the continuous improvement of our platform!',
    fr: 'Testez la version bêta de personnalisation et participez à l\'amélioration continue de notre plateforme!'
  },
  'Fortaleça a imagem da sua marca': {
    es: 'Fortalece la imagen de tu marca',
    en: 'Strengthen your brand\'s image',
    fr: 'Renforcez l\'image de votre marque'
  },
  'Ferramenta de personalização fácil de usar para você enviar documentos com sua marca para seus clientes': {
    es: 'Herramienta de personalización fácil de usar para enviar documentos a tus clientes con tu marca',
    en: 'Easy-to-use customization tool to send documents with your brand to your clients',
    fr: 'Outil de personnalisation facile à utiliser pour envoyer des documents avec votre marque à vos clients'
  },
  'Nome da empresa': {
    es: 'Nombre de la empresa',
    en: 'Company name',
    fr: 'Nom de l\'entreprise'
  },
  'Adicione sua logomarca': {
    es: 'Añade tu logotipo',
    en: 'Add your logo',
    fr: 'Ajoutez votre logo'
  },
  'SVG, PNG ou JPG (tamanho 540-540px)': {
    es: 'SVG, PNG o JPG (tamaño 540-540px)',
    en: 'SVG, PNG, or JPG (size 540-540px)',
    fr: 'SVG, PNG ou JPG (taille 540-540px)'
  },
  'E-MAILS ENVIADOS POR': {
    es: 'CORREOS ELECTRÓNICOS ENVIADOS POR',
    en: 'EMAILS SENT BY',
    fr: 'E-MAILS ENVIÉS PAR'
  },
  'Botão exemplo': {
    es: 'Botón de ejemplo',
    en: 'Example button',
    fr: 'Bouton exemple'
  },
  'Restaurar padrão': {
    es: 'Restaurar estándar',
    en: 'Restore default',
    fr: 'Restaurer défaut'
  },
  'Relatório de impacto ambiental': {
    es: 'Informe de impacto ambiental',
    en: 'Environmental impact report',
    fr: 'Rapport d\'impact environnemental'
  },
  'Compartilhe como sua empresa tem contribuído na preservação do meio ambiente ao reduzir a quantidade de papel impresso nos processos da empresa': {
    es: 'Comparte cómo tu empresa ha contribuido a la conservación del medio ambiente al reducir la cantidad de papel impreso en los procesos de la empresa',
    en: 'Share how your company has contributed to environmental preservation by reducing the amount of printed paper in your company\'s processes',
    fr: 'Partagez comment votre entreprise a contribué à la préservation de l\'environnement en réduisant la quantité de papier imprimé dans les processus de l\'entreprise'
  },
  'Personalizar link': {
    es: 'Personalizar enlace',
    en: 'Customize link',
    fr: 'Personnaliser le lien'
  },
  'Acessar página': {
    es: 'Acceder a la página',
    en: 'Access the page',
    fr: 'Accéder à la page'
  },
  azul: {
    es: 'azul',
    en: 'blue',
    fr: 'bleu'
  },
  preto: {
    es: 'negro',
    en: 'black',
    fr: 'noir'
  },
  branco: {
    es: 'blanco',
    en: 'white',
    fr: 'blanc'
  },
  'Insira o código na sua página web': {
    es: 'Inserta el código en tu página web',
    en: 'Insert the code on your web page',
    fr: 'Insérez le code sur votre page web'
  },
  'Baixar selo empresa sustentável': {
    es: 'Descargar sello de empresa sostenible',
    en: 'Download sustainable company seal',
    fr: 'Télécharger le label d\'entreprise durable'
  },
  'Por favor, insira novos dados nos campos para salvar a personalização.': {
    es: 'Por favor, ingresa nuevos datos en los campos para guardar la personalización.',
    en: 'Please enter new data in the fields to save the customization.',
    fr: 'Veuillez entrer de nouvelles données dans les champs pour enregistrer la personnalisation.'
  },
  'assets/img/logo/zapsignSustainableLogos/sustainableBlueLogoPT.png': {
    es: 'assets/img/logo/zapsignSustainableLogos/sustainableBlueLogoES.png',
    en: 'assets/img/logo/zapsignSustainableLogos/sustainableBlueLogoEN.png',
    fr: 'assets/img/logo/zapsignSustainableLogos/sustainableBlueLogoFR.png'
  },
  'assets/img/logo/zapsignSustainableLogos/sustainableBlackLogoPT.png': {
    es: 'assets/img/logo/zapsignSustainableLogos/sustainableBlackLogoES.png',
    en: 'assets/img/logo/zapsignSustainableLogos/sustainableBlackLogoEN.png',
    fr: 'assets/img/logo/zapsignSustainableLogos/sustainableBlackLogoFR.png'
  },
  'assets/img/logo/zapsignSustainableLogos/sustainableWhiteLogoPT.png': {
    es: 'assets/img/logo/zapsignSustainableLogos/sustainableWhiteLogoES.png',
    en: 'assets/img/logo/zapsignSustainableLogos/sustainableWhiteLogoEN.png',
    fr: 'assets/img/logo/zapsignSustainableLogos/sustainableWhiteLogoFR.png'
  },
  'Leia o documento antes de assinar': {
    es: 'Lee el documento antes de firmar',
    en: 'Read the document before signing',
    fr: 'Lisez le document avant de signer'
  },
  'Aguarde, estamos carregando o seu documento para leitura...': {
    es: 'Espera, estamos cargando el documento...',
    en: 'Wait, we are loading your document to read...',
    fr: 'Attendez, nous chargeons votre document à lire...'
  },
  'Experimente a versão beta de relatórios e participe da melhoria contínua da nossa plataforma!': {
    es: '¡Prueba la versión beta de informes y únete a la mejora continua de nuestra plataforma!',
    en: 'Try the beta version of reports and take part in the continuous improvement of our platform!',
    fr: 'Essayez la version bêta des rapports et participez à l\'amélioration continue de notre plateforme !g'
  },
  'Criação de envelopes': {
    es: 'Creación de sobres',
    en: 'Envelope creation',
    fr: 'Création d\'enveloppes'
  },
  Gráficos: {
    es: 'Gráficos',
    en: 'Graphics',
    fr: 'Graphiques'
  },
  'Classificar por período': {
    es: 'Clasificar por período',
    en: 'Sort by period',
    fr: 'Trier par période'
  },
  'Últimos 30 dias': {
    es: 'Últimos 30 días',
    en: 'Last 30 days',
    fr: 'Les 30 derniers jours'
  },
  'Últimos 90 dias': {
    es: 'Últimos 90 días',
    en: 'Last 90 days',
    fr: 'Les 90 derniers jours'
  },
  'Últimos 180 dias': {
    es: 'Últimos 180 días',
    en: 'Last 180 days',
    fr: 'Les 180 derniers jours'
  },
  'Últimos 360 dias': {
    es: 'Últimos 360 días',
    en: 'Last 360 days',
    fr: 'Les 360 derniers jours'
  },
  Eficiência: {
    es: 'Eficiencia',
    en: 'Efficiency',
    fr: 'Efficacité'
  },
  'Documentos por dia na organização': {
    es: 'Documentos por día en la organización',
    en: 'Documents per day in the organization',
    fr: 'Documents par jour dans l\'organisation'
  },
  'Envelopes por dia na organização': {
    es: 'Sobres por día en la organización',
    en: 'Envelopes per day in the organization',
    fr: 'Enveloppes par jour dans l\'organisation'
  },
  'Nenhum registro encontrado no período selecionado': {
    es: 'No se encontraron registros en el período seleccionado',
    en: 'No records found in the selected period',
    fr: 'Aucun enregistrement trouvé dans la période sélectionnée'
  },
  'Tempo para assinatura de documentos': {
    es: 'Tiempo para la firma de documentos',
    en: 'Time for document signing',
    fr: 'Temps pour la signature de documents'
  },
  'Tempo para assinatura de envelopes': {
    es: 'Tiempo para la firma de sobres',
    en: 'Time for envelope signing',
    fr: 'Temps pour la signature d\'enveloppes'
  },
  'Tempo para assinatura': {
    es: 'Tiempo para firmar',
    en: 'Time to sign',
    fr: 'Temps pour signer'
  },
  'Acesse a ZapSign através de um computador para ver os gráficos de tempo para assinatura': {
    es: 'Acceda a ZapSign en una computadora para ver los gráficos de tiempo para firma',
    en: 'Access ZapSign on a computer to view the time to signature charts',
    fr: 'Accédez à ZapSign sur un ordinateur pour consulter les graphiques de temps pour signature'
  },
  Envelopes: {
    es: 'Sobres',
    en: 'Envelopes',
    fr: 'Enveloppes'
  }, 'Cancelar meu plano': {
    es: 'Cancelar mi plan',
    en: 'Cancel my plan',
    fr: 'Annuler mon forfait'
  },
  'Sua experiência ZapSign vai mudar': {
    es: 'Tu experiencia en ZapSign cambiará',
    en: 'Your ZapSign experience will change',
    fr: 'Votre expérience ZapSign va changer'
  },
  'Apenas 5 envios': {
    es: 'Solo 5 envíos',
    en: 'Only 5 submissions',
    fr: 'Seulement 5 envois'
  },

  'Sem opção de créditos': {
    es: 'Sin opción de créditos',
    en: 'No credit option',
    fr: 'Aucune option de crédit'
  },
  'Não poderá adquirir mais créditos para os recursos avançados.': {
    es: 'No podrás adquirir más créditos para las funciones avanzadas.',
    en: 'You will not be able to purchase more credits for advanced features.',
    fr: 'Vous ne pourrez pas acheter plus de crédits pour les fonctionnalités avancées.'
  },
  'Conversar com nossa equipe': {
    es: 'Hablar con nuestro equipo',
    en: 'Talk to our team',
    fr: 'Discuter avec notre équipe'
  },
  'Prosseguir com cancelamento': {
    es: 'Continuar con la cancelación',
    en: 'Proceed with cancellation',
    fr: 'Continuer l\'annulation'
  },
  'Voltar à conta': {
    es: 'Volver a la cuenta',
    en: 'Back to account',
    fr: 'Retour au compte'
  },
  'Antes de prosseguirmos, gostaríamos de compreender melhor suas necessidades e preocupações. Isso nos ajuda a melhorar nossos serviços e garantir sua satisfação contínua.': {
    es: 'Antes de continuar, nos gustaría comprender mejor sus necesidades y preocupaciones. Esto nos ayuda a mejorar nuestros servicios y garantizar su satisfacción continua.',
    en: 'Before we proceed, we would like to better understand your needs and concerns. This helps us improve our services and ensure your ongoing satisfaction.',
    fr: 'Avant de continuer, nous aimerions mieux comprendre vos besoins et préoccupations. Cela nous aide à améliorer nos services et à garantir votre satisfaction continue.'
  },
  'Ícone de alerta com pássaros em cima': {
    es: 'Ícono de alerta con pájaros arriba',
    en: 'Icon of alert with birds on top',
    fr: 'Icône d\'alerte avec des oiseaux dessus'
  },
  'Sua opinião importa': {
    es: '¡Tu opinión importa!',
    en: 'Your opinion matters!',
    fr: 'Votre opinion compte !'
  },
  'Procurando por uma solução mais acessível?': {
    es: '¿Buscando una solución más asequible?',
    en: 'Looking for a more affordable solution?',
    fr: 'Vous cherchez une solution plus abordable ?'
  },
  'Não desista ainda! Temos uma oferta especialmente para você.': {
    es: '¡No te rindas todavía! Tenemos una oferta especial para ti.',
    en: 'Don\'t give up yet! We have a special offer for you.',
    fr: 'N\'abandonnez pas encore ! Nous avons une offre spéciale pour vous.'
  },
  'Resgate sua oferta especial!': {
    es: '¡Canjea tu oferta especial!',
    en: 'Redeem your special offer!',
    fr: 'Profitez de votre offre spéciale !'
  },
  'Ícone de um pássaro voando e segurando uma carta de correio com seu bico': {
    es: 'Ícono de un pájaro volando y sosteniendo una carta con su pico',
    en: 'Icon of a bird flying and holding a mail letter with its beak',
    fr: 'Icône d\'un oiseau volant tenant une lettre avec son bec'
  },
  'Tem certeza que quer cancelar seu plano?': {
    es: '¿Estás seguro de que quieres cancelar tu plan?',
    en: 'Are you sure you want to cancel your plan?',
    fr: 'Êtes-vous sûr de vouloir annuler votre forfait ?'
  },
  'Cancelamento concluído. Agradecemos sua confiança!': {
    es: 'Cancelación completada. ¡Agradecemos su confianza!',
    en: 'Cancellation completed. Thank you for your trust!',
    fr: 'Annulation terminée. Merci pour votre confiance !'
  },
  'Que pena que não pudemos fazer alguma coisa. Estamos aqui para você a qualquer momento.': {
    es: 'Lamentablemente, no pudimos hacer nada. Estamos aquí para usted en cualquier momento.',
    en: 'Sorry we couldn\'t do something. We\'re here for you anytime.',
    fr: 'Désolé, nous n\'avons pas pu faire quelque chose. Nous sommes là pour vous à tout moment.'
  },
  'Se cancelar, sua conta passará para o plano gratuito': {
    es: 'Si cancelas, tu cuenta cambiará al plan gratuito',
    en: 'If you cancel, your account will move to the free plan',
    fr: 'Si vous annulez, votre compte passera au plan gratuit'
  },
  'Veja como será': {
    es: 'Mira cómo será',
    en: 'See how it will be',
    fr: 'Voyez comment cela sera'
  },
  'Você vai poder enviar apenas $0 documentos por mês no plano $1.': {
    es: 'Solo podrás enviar $0 documentos al mes en el plan $1.',
    en: 'You will only be able to send $0 documents per month on the $1 plan.',
    fr: 'Vous ne pourrez envoyer que $0 documents par mois sur le plan $1.'
  },
  'Preço alto/Não posso pagar no momento': {
    es: 'Precio alto/No puedo pagar en este momento',
    en: 'High price/Can\'t afford it at the moment',
    fr: 'Prix élevé/Je ne peux pas me le permettre pour le moment'
  },
  'Não estou utilizando com frequência': {
    es: 'No lo estoy usando con frecuencia',
    en: 'I\'m not using it frequently',
    fr: 'Je ne l\'utilise pas fréquemment'
  },
  'Encontrei outra alternativa': {
    es: 'Encontré otra alternativa',
    en: 'I found another alternative',
    fr: 'J\'ai trouvé une autre alternative'
  },
  'Problemas técnicos ou dificuldade de uso': {
    es: 'Problemas técnicos o dificultades de uso',
    en: 'Technical issues or usability problems',
    fr: 'Problèmes techniques ou de convivialité'
  },
  'Não gostei do atendimento de suporte': {
    es: 'No me gustó la atención al cliente',
    en: 'I didn\'t like the support service',
    fr: 'Je n\'ai pas aimé l\'expérience du service client'
  },
  'Outros (descrever)': {
    es: 'Otros (describir)',
    en: 'Others (describe)',
    fr: 'Autres (décrire)'
  },
  'A partir': {
    es: 'A partir',
    en: 'Starting from',
    fr: 'À partir'
  },
  'a conta passará para o plano gratuito e você perderá todos os benefícios contratados.': {
    es: 'la cuenta cambiará al plan gratuito y perderás todos los beneficios contratados.',
    en: 'the account will switch to the free plan and you will lose all contracted benefits.',
    fr: 'le compte passera au plan gratuit et vous perdrez tous les avantages souscrits.'
  },
  'Agradecemos seu contato!': {
    es: '¡Agradecemos su contacto!',
    en: 'We appreciate your contact!',
    fr: 'Nous vous remercions de votre contact !'
  },
  'Sua solicitação foi encaminhada com sucesso. Estamos engajados para te garantir a melhor experiência!': {
    es: '¡Tu solicitud ha sido enviada con éxito! ¡Estamos comprometidos a brindarte la mejor experiencia!',
    en: 'Your request has been successfully submitted. We are committed to ensuring the best experience for you!',
    fr: 'Votre demande a été transmise avec succès. Nous nous engageons à vous offrir la meilleure expérience !'
  },
  'Ícone de uma pessoa com camiseta azul escura apontando para o céu': {
    es: 'Ícono de una persona con una camiseta azul oscuro señalando al cielo',
    en: 'Icon of a person with dark blue t-shirt pointing to the sky',
    fr: 'Icône d\'une personne portant un tee-shirt bleu foncé pointant vers le ciel'
  },
  'do dia': {
    es: 'del día',
    en: 'of the day',
    fr: 'du jour'
  },
  'Não desista ainda! Temos a solução que você precisa. Fale com nosso time de especialistas!': {
    es: '¡No te rindas todavía! Tenemos la solución que necesitas. ¡Habla con nuestro equipo de especialistas!',
    en: 'Don\'t give up yet! We have the solution you need. Talk to our team of experts!',
    fr: 'N\'abandonnez pas encore ! Nous avons la solution dont vous avez besoin. Parlez à notre équipe d\'experts !'
  },
  'Não desista ainda. Vamos comparar sua oferta com nosso time de especialistas!': {
    es: '¡No te rindas todavía. Vamos a comparar tu oferta con nuestro equipo de expertos!',
    en: 'Don\'t give up yet. Let\'s compare your offer with our team of experts!',
    fr: 'N\'abandonnez pas encore. Comparons votre offre avec notre équipe d\'experts!'
  },
  'Podemos comparar para ver o que é melhor?': {
    es: '¿Podemos comparar para ver qué es mejor?',
    en: 'Can we compare to see what\'s best?',
    fr: 'Pouvons-nous comparer pour voir ce qui est le meilleur ?'
  },
  'Não desista ainda!': {
    es: '¡No te rindas todavía!',
    en: 'Don\'t give up yet!',
    fr: 'Ne baissez pas les bras !'
  },
  'Continue com a gente e tenha 3 meses do nosso Suporte PRO grátis!': {
    es: '¡Continúa con nosotros y obtén 3 meses de nuestro Soporte PRO gratis!',
    en: 'Keep going with us and get 3 months of our PRO Support for free!',
    fr: 'Continuez avec nous et obtenez 3 mois de notre Support PRO gratuitement!'
  },
  'Suporte PRO ZapSign': {
    es: 'Soporte PRO ZapSign',
    en: 'Support PRO ZapSign',
    fr: 'Support PRO ZapSign'
  },
  '3 meses grátis': {
    es: '3 meses gratis',
    en: '3 months free',
    fr: '3 mois gratuits'
  },
  'Benefícios exclusivos': {
    es: 'Beneficios exclusivos',
    en: 'Exclusive benefits',
    fr: 'Avantages exclusifs'
  },
  'Acompanhamento de um especialista via Google Meet, e-mail e WhatsApp.': {
    es: 'Acompañamiento de un experto a través de Google Meet, correo electrónico y WhatsApp.',
    en: 'Expert guidance via Google Meet, email, and WhatsApp.',
    fr: 'Accompagnement par un expert via Google Meet, e-mail et WhatsApp!'
  },
  'Reuniões exclusivas com o seu especialista para onboarding e dúvidas pertinentes do dia a dia.': {
    es: 'Reuniones exclusivas con su especialista para integración y preguntas diarias pertinentes.',
    en: 'Exclusive meetings with your specialist for onboarding and relevant day-to-day inquiries.',
    fr: 'Réunions exclusives avec votre spécialiste pour l\'intégration et les questions pertinentes au quotidien.'
  },
  'Videoconferências disponíveis para esclarecimentos de integração via API, garantindo uma experiência completa e eficiente no uso da plataforma.': {
    es: 'Videoconferencias disponibles para aclarar la integración a través de API, asegurando una experiencia completa y eficiente en el uso de la plataforma.',
    en: 'Video conferences available for integration clarifications via API, ensuring a complete and efficient experience in using the platform.',
    fr: 'Vidéoconférences disponibles pour des clarifications d\'intégration via API, garantissant une expérience complète et efficace dans l\'utilisation de la plateforme.'
  },
  'Ativar Suporte PRO': {
    es: 'Activar Soporte PRO',
    en: 'Activate PRO Support',
    fr: 'Activer le support PRO'
  },
  'Gostaríamos de ouvir sua opinião!': {
    es: '¡Nos encantaría escuchar tu opinión!',
    en: 'We would like to hear your opinion!',
    fr: 'Nous aimerions entendre votre avis!'
  },
  'Por favor, nos conte mais sobre seus motivos para cancelar.': {
    es: 'Por favor, cuéntanos más sobre tus motivos para cancelar.',
    en: 'Please tell us more about your reasons for canceling.',
    fr: 'S\'il vous plaît, dites-nous-en plus sur vos raisons d\'annulation.'
  },
  'Cancelamento concluído. Explore nossos planos disponíveis!': {
    es: '¡Cancelación completada. ¡Explore nuestros planes disponibles!',
    en: 'Cancellation completed. Explore our available plans!',
    fr: 'Annulation terminée. Explorez nos plans disponibles !'
  },
  'Confira agora nossas opções de plano. Ou, se preferir, podemos te ajudar com o processo. Converse com nosso time de especialistas!': {
    es: 'Echa un vistazo a nuestras opciones de plan. O, si lo prefieres, podemos ayudarte con el proceso. ¡Habla con nuestro equipo de especialistas!',
    en: 'Check out our plan options now. Or, if you prefer, we can help you with the process. Talk to our team of experts!',
    fr: 'Consultez dès maintenant nos options de plan. Ou, si vous préférez, nous pouvons vous aider dans le processus. Parlez à notre équipe d\'experts!'
  },
  'Falar com a gente': {
    es: 'Hablar con nosotros',
    en: 'Talk to us',
    fr: 'Parlez-nous'
  },
  'Cancelamento concluído. Atualize sua forma de pagamento facilmente!': {
    es: '¡Cancelación completada. ¡Actualice su forma de pago fácilmente!',
    en: 'Cancellation completed. Update your payment method easily!',
    fr: 'Annulation terminée. Mettez à jour votre mode de paiement facilement!'
  },
  'Nossos especialistas estão disponíveis para te ajudar com seu processo de mudança de pagamento!': {
    es: 'Nuestros especialistas están disponibles para ayudarte con tu proceso de cambio de pago!',
    en: 'Our experts are available to help you with your payment change process!',
    fr: 'Nos experts sont disponibles pour vous aider dans votre processus de changement de paiement!'
  },
  'Não desista ainda! Temos um time de especialistas dedicado ao nosso sistema!': {
    es: '¡No te rindas todavía! ¡Contamos con un equipo de especialistas dedicado a nuestro sistema!',
    en: 'Don\'t give up yet! We have a team of specialists dedicated to our system!',
    fr: 'Ne renoncez pas encore ! Nous avons une équipe d\'experts dédiée à notre système !'
  },
  'Estamos aqui para ajudar com seus problemas e dificuldades!': {
    es: 'Estamos aquí para ayudarte con tus problemas y dificultades',
    en: 'We\'re here to help with your problems and difficulties',
    fr: 'Nous sommes là pour aider avec vos problèmes et difficultés'
  },
  'Continue com a gente e tenha': {
    es: 'Continúa con nosotros y ten',
    en: 'Continue with us and have',
    fr: 'Continuez avec nous et ayez'
  },
  '3 meses': {
    es: '3 meses',
    en: '3 months',
    fr: '3 mois'
  },
  'do nosso Suporte PRO grátis!': {
    es: '¡de nuestro Soporte PRO gratis!',
    en: 'from our free PRO Support!',
    fr: 'de notre Support PRO gratuit !'
  },
  'Por favor, insira o título do campos de texto e ou checkbox.': {
    es: 'Por favor escribe el titulo del campo de texto o checkbox.',
    en: 'Please write the title of the text field or checkbox.',
    fr: 'Veuillez écrire le titre du champ de texte ou de la case à cocher.'
  },
  'Editar webhooks': {
    es: 'Editar webhooks',
    en: 'Edit webhooks',
    fr: 'Modifier les webhooks'
  },
  'Redimensionar assinatura': {
    es: 'Redimensionar firma',
    en: 'Resize signature',
    fr: 'Redimensionner la signature'
  },
  'Girar assinatura': {
    es: 'Girar firma',
    en: 'Rotate signature',
    fr: 'Faire pivoter la signature'
  },
  'A assinatura foi copiada': {
    es: 'La firma fue copiada',
    en: 'The signature was copied',
    fr: 'La signature a été copiée'
  },
  'Documento criado com sucesso!': {
    es: 'Documento creado con éxito!',
    en: 'Document successfully created!',
    fr: 'Document créé avec succès!'
  },
  'Token copiado para a área de transferência': {
    es: 'Token copiado',
    en: 'Token copied',
    fr: 'Token copié'
  },
  'Ocorreu algum erro imprevisto. Por favor, atualize a página.': {
    es: 'Se ha producido un error inesperado. Por favor, actualiza la página.',
    en: 'An unexpected error occurred. Please refresh the page.',
    fr: 'Il y a eu une erreur inattendue. Veuillez rafraîchir la page.'
  },
  'Documentos recusados': {
    es: 'Documentos rechazados',
    en: 'Rejected documents',
    fr: 'Documents refusés'
  },
  'Pasta Atual': {
    es: 'Carpeta actual',
    en: 'Current folder',
    fr: 'Dossier actuel'
  },
  'O reconhecimento facial tem um custo adicional de 25 créditos caso utilizado (25 créditos = $0,5 USD).': {
    es: 'El reconocimiento facial tiene un costo adicional de 25 créditos si es usado (25 créditos = $0.5 USD).',
    en: 'Facial recognition incurs an additional cost of 25 credits when used (25 credits = $0.5 USD).',
    fr: 'La reconnaissance faciale entraîne un coût supplémentaire de 25 crédits lorsqu\'elle est utilisée (25 crédits = 0,5 USD).'
  },
  'Carregando histórico...': {
    es: 'Cargando histórico...',
    en: 'Loading history...',
    fr: 'Chargement de l\'historique...'
  },
  'Formulário pré-preenchido gerado com sucesso!': {
    es: 'Documento pre-diligenciado generado con éxito!',
    en: 'Successfully generated pre-filled document!',
    fr: 'Document pré-rempli généré avec succès!'
  },
  'Por favor, inclua o e-mail do signatário editando-o, para depois enviar o lembrete.': {
    es: 'Por favor incluye el correo del firmante para enviar un recordatorio.',
    en: 'Please include the signer\'s email to send a reminder.',
    fr: 'Veuillez inclure l\'e-mail du signataire pour envoyer un rappel.'
  },
  nome: {
    es: 'nombre',
    en: 'name',
    fr: 'nom'
  },
  'Telefone (opcional)': {
    es: 'Celular',
    en: 'Phone',
    fr: 'Téléphone'
  },
  'Salvar ordenação': {
    es: 'Guardar orden',
    en: 'Save order',
    fr: 'Enregistrer l\'ordre'
  },
  'O próximo signatário só será notificado para assinar o documento após o anterior ter concluído sua assinatura. A mensagem será enviada automaticamente conforme as configurações definidas na criação do documento.': {
    es: 'El siguiente firmante solo será notificado para firmar el documento después de que el anterior haya completado su firma. El mensaje se enviará automáticamente según la configuración definida en la creación del documento.',
    en: 'The next signer will only be notified to sign the document after the previous one has completed their signature. The message will be sent automatically according to the settings defined in the document creation.',
    fr: 'Le prochain signataire ne sera notifié de signer le document qu\'après que le précédent ait terminé sa signature. Le message sera envoyé automatiquement selon les paramètres définis lors de la création du document.'
  },
  'Reordenar signatários': {
    es: 'Reordenar firmantes',
    en: 'Reorder signers',
    fr: 'Réorganiser les signataires'
  },
  'Compromisso <b>sustentável</b> com o futuro': {
    es: 'Compromiso <b>sostenible</b> con el futuro',
    en: '<b>Sustainable</b> commitment to the future',
    fr: 'Engagement <b>durable</b> envers l\'avenir'
  },
  'De redução de emissões de CO2': {
    es: 'De reducción de emisiones de CO2',
    en: 'CO2 emissions reduction',
    fr: 'Réduction des émissions de CO2'
  },
  'De economia de água': {
    es: 'De ahorro de agua',
    en: 'Water savings',
    fr: 'Économie d\'eau'
  },
  'De redução de uso de papel': {
    es: 'De reducción del uso de papel',
    en: 'Reduction in paper usage',
    fr: 'Réduction de l\'utilisation du papier'
  },
  'Certificado de Empresa Sustentável': {
    es: 'Certificado de Empresa Sostenible',
    en: 'Sustainable Company Certificate',
    fr: 'Certificat d\'Entreprise Durable'
  },
  'Certificamos que <b>$0</b> tem contribuído ativamente para a preservação do meio ambiente ao adotar a ZapSign em seus processos.': {
    es: 'Certificamos que <b>$0</b> ha contribuido activamente a preservar el medio ambiente adoptando ZapSign en sus procesos.',
    en: 'We certify that <b>$0</b> has actively contributed to preserving the environment by adopting ZapSign in its processes.',
    fr: "Nous certifions que <b>$0</b> a activement contribué à la préservation de l'environnement en adoptant ZapSign dans ses processus."
  },
  'Empresa sem nome': {
    es: 'Empresa sin nombre',
    en: 'Unnamed company',
    fr: 'Entreprise sans nom'
  },
  '$0L de água': {
    es: '$0L de agua',
    en: '$0L of water',
    fr: '$0L d\'eau'
  },
  Economizados: {
    es: 'Economizados',
    en: 'Saved',
    fr: 'Économisés'
  },
  '$0 árvore': {
    es: '$0 árbol',
    en: '$0 tree',
    fr: '$0 arbre'
  },
  '$0 árvores': {
    es: '$0 árboles',
    en: '$0 trees',
    fr: '$0 arbres'
  },
  Salvas: {
    es: 'Salvadas',
    en: 'Saved',
    fr: 'Sauvées'
  },
  Salva: {
    es: 'Guardada',
    en: 'Saved',
    fr: 'Sauvée'
  },
  '$0kg de CO2': {
    es: '$0 kg de CO2',
    en: '$0kg of CO2',
    fr: '$0 kg de CO2'
  },
  'Ao escolher a Zapsign, você está investindo em eficiência, economia e, acima de tudo, sustentabilidade. Nossos clientes estão economizando 5X mais ao optar pela assinatura eletrônica em vez de métodos tradicionais.': {
    es: 'Al elegir Zapsign, estás invirtiendo en eficiencia, ahorro y, sobre todo, sostenibilidad. Nuestros clientes están ahorrando 5 veces más al optar por la firma electrónica en lugar de los métodos tradicionales.',
    en: 'By choosing ZapSign, you are investing in efficiency, savings, and, above all, sustainability. Our clients are saving 5X more by opting for electronic signatures instead of traditional methods.',
    fr: 'En choisissant ZapSign, vous investissez dans l\'efficacité, l\'économie et, surtout, la durabilité. Nos clients économisent 5 fois plus en optant pour la signature électronique plutôt que les méthodes traditionnelles.'
  },
  'Junte-se a nós na jornada para um mundo mais <b>verde!</b>': {
    es: '¡Únete a nosotros en el viaje hacia un mundo más <b>verde!</b>',
    en: 'Join us on the journey to a <b>greener</b> world!',
    fr: 'Rejoignez-nous dans le voyage vers un monde plus <b>vert !</b>'
  },
  'Você pode fazer a diferença escolhendo a assinatura eletrônica em vez do papel. Junte-se a nós na jornada para um mundo paperless e ajude a preservar nosso planeta para as gerações futuras.': {
    es: 'Puedes marcar la diferencia eligiendo la firma electrónica en lugar del papel. Únete a nosotros en el viaje hacia un mundo sin papel y ayude a conservar nuestro planeta para las generaciones futuras.',
    en: 'You can make a difference by choosing electronic signature over paper. Join us on the journey to a paperless world and help preserve our planet for future generations.',
    fr: 'Vous pouvez faire la différence en choisissant la signature électronique plutôt que le papier. Rejoignez-nous dans le voyage vers un monde sans papier et aidez à préserver notre planète pour les générations futures.'
  },
  'Quero fazer parte da ZapSign': {
    es: 'Quiero ser parte de ZapSign',
    en: 'I want to be part of ZapSign',
    fr: 'Je veux faire partie de ZapSign'
  },
  'Ícone representando economia de água': {
    es: 'Ícono que representa ahorro de agua',
    en: 'Icon representing water savings',
    fr: 'Icône représentant des économies d\'eau'
  },
  'Ícone representando árvores salvas': {
    es: 'Ícono que representa árboles salvados',
    en: 'Icon representing saved trees',
    fr: 'Icône représentant des arbres sauvés'
  },
  'Ícone representando economia de CO2': {
    es: 'Ícono que representa ahorro de CO2',
    en: 'Icon representing CO2 savings',
    fr: 'Icône représentant des économies de CO2'
  },
  'Selo de certificado de compromisso com o meio ambiente ZapSign e imagem de árvore': {
    es: 'Sello de certificación de compromiso con el medio ambiente de ZapSign y imagen de un árbol',
    en: 'ZapSign\'s environmental commitment certificate seal and tree image',
    fr: 'Sceau de certification d\'engagement environnemental ZapSign et image d\'un arbre'
  },
  'Verificador de autenticidade': {
    es: 'Verificador de autenticidad',
    en: 'Authenticity verifier',
    fr: 'Vérificateur d\'authenticité'
  },
  'Verifique, de maneira rápida e segura, a autenticação das assinaturas em seus documentos com a ZapSign.': {
    es: 'Verifica de forma rápida y segura la autenticidad de las firmas en los documentos de ZapSign',
    en: 'Quickly and securely verify the authentication of signatures on your documents with ZapSign.',
    fr: 'Vérifiez rapidement et en toute sécurité l\'authentification des signatures sur vos documents avec ZapSign.'
  },
  'Valide documentos sem fricções': {
    es: 'Valida documentos sin fricciones',
    en: 'Validate documents without friction',
    fr: 'Validez des documents sans friction'
  },
  'Mitigue riscos relacionados à falsificação de documentos': {
    es: 'Mitiga riesgos relacionados con la falsificación de documentos',
    en: 'Mitigate risks related to document forgery',
    fr: 'Atténuez les risques liés à la contrefaçon de documents'
  },
  'Previna fraudes por manipulação de documentos': {
    es: 'Evita fraudes por manipulación de documentos',
    en: 'Prevent fraud through document manipulation',
    fr: 'Prévenez les fraudes liées à la manipulation de documents'
  },
  'Para verificar a autenticidade, insira o número do documento assinado no campo a abaixo:': {
    es: 'Para verificar la autenticidad, ingresa el número del documento firmado en el campo a continuación',
    en: 'To verify authenticity, enter the signed document number in the field below',
    fr: 'Pour vérifier l\'authenticité, saisissez le numéro du document signé dans le champ ci-dessous'
  },
  'Precisa de ajuda para encontrar o número? ': {
    es: '¿Necesitas ayuda para encontrar el número? ',
    en: 'Need help finding the number? ',
    fr: 'Besoin d\'aide pour trouver le numéro ? '
  },
  'Clique aqui e veja como encontrar': {
    es: 'Haz clic aquí',
    en: 'Click here and see how to find it',
    fr: 'Cliquez ici et découvrez comment le trouver'
  },
  'Autenticação reconhecida pela ZapSign': {
    es: 'Autenticación reconocida por ZapSign',
    en: 'Authentication recognized by ZapSign',
    fr: 'Authentification reconnue par ZapSign'
  },
  'Nome:': {
    es: 'Nombre:',
    en: 'Name:',
    fr: 'Nom :'
  },
  'Data da criação:': {
    es: 'Fecha de creación:',
    en: 'Creation date:',
    fr: 'Date de création :'
  },
  'Assinatura qualificada ICP-BRASIL': {
    es: 'Firma electrónica calificada ICP-BRASIL',
    en: 'Qualified electronic signature ICP-BRAZIL',
    fr: 'Signature électronique qualifiée ICP-BRÉSIL'
  },
  'Constância de Conservação (NOM151)': {
    es: 'Constancia de Conservación (NOM151)',
    en: 'Conservation Certificate (NOM151)',
    fr: 'Certificat de conservation (NOM151)'
  },
  'Para verificar a conservação da constância, faça o download do documento assinado e da constância. Em seguida, faça o upload dos arquivos para o verificador de certificação.': {
    es: 'Para verificar la integridad del documentodescarga el documento firmado y la constancia de conservación. Luego, carga ambos documentos.',
    en: 'To verify the conservation of the certificate, download the signed document and the certificate. Then, upload the files to the certification verifier.',
    fr: 'Pour vérifier la conservation du certificat, téléchargez le document signé et le certificat. Ensuite, téléversez les fichiers dans le vérificateur de certification.'
  },
  'Conforme:': {
    es: 'Conforme a:',
    en: 'As per:',
    fr: 'Conformément à :'
  },
  'Assinaturas:': {
    es: 'Firmas:',
    en: 'Signatures:',
    fr: 'Signatures :'
  },
  '$0 de $1 assinaturas': {
    es: '$0 de $1 firmas',
    en: '$0 of $1 signatures',
    fr: '$0 de signatures de $1',
  },
  'Assinatura concluída': {
    es: 'Firma completada',
    en: 'Signature completed',
    fr: 'Signature terminée',
  },
  'Documento não encontrado. Verifique o número do documento': {
    es: 'Documento no encontrado. Verifica el número del documento.',
    en: 'Document not found. Please check the document number.',
    fr: 'Document non trouvé. Veuillez vérifier le numéro du document.'
  },
  'Por favor, verifique se todos os números foram digitados': {
    es: 'Por favor, verifica si todos los números han sido ingresados.',
    en: 'Please check if all the numbers have been entered.',
    fr: 'Veuillez vérifier si tous les numéros ont été saisis.'
  },
  'A função de baixar o documento original está desabilitada pelo administrador': {
    es: 'La función de descargar el documento original está deshabilitada por el administrador',
    en: 'The function to download the original document is disabled by the administrator',
    fr: 'La fonction de téléchargement du document original est désactivée par l\'administrateur'
  },
  'A função de baixar o documento assinado está desabilitada pelo administrador': {
    es: 'La función de descargar el documento firmado está deshabilitada por el administrador',
    en: 'The function to download the signed document is disabled by the administrator',
    fr: 'La fonction de téléchargement du document signé est désactivée par l\'administrateur'
  },
  'Abra o documento a ser validado': {
    es: 'Abre el documento a ser validado',
    en: 'Open the document to be validated',
    fr: 'Ouvrez le document à valider'
  },
  'Agora, é só checar o topo da página. Como na imagem o número estará logo abaixo do nome do documento.': {
    es: 'En el informe de firmas, el número del documento se encuentra debajo del titulo',
    en: 'Now, just check the top of the page. In the image, the number will be right below the document\'s name.',
    fr: 'Maintenant, il suffit de vérifier le haut de la page. Dans l\'image, le numéro sera juste en dessous du nom du document.'
  },
  'Download do documento:': {
    es: 'Descarga del documento:',
    en: 'Document Download:',
    fr: 'Téléchargement du document:'
  },
  'Enviar documento por e-mail': {
    es: 'Enviar documento por correo electrónico',
    en: 'Send document by email',
    fr: 'Envoyer le document par e-mail'
  },
  'A ZapSign vai enviar o seu documento individualmente para cada um dos endereços abaixo. Limite de 50 e-mails separados com vírgulas.': {
    es: 'ZapSign va enviar un documento individual para cada correo electrónico que definas abajo. Límite de 50 correos separados por comas (,).',
    en: 'ZapSign will send an individual document for each email you define below. Limit of 50 emails separated by commas (,).',
    fr: 'ZapSign enverra un document individuel pour chaque email que vous définissez ci-dessous. Limite de 50 emails séparés par des virgules (,).'
  },
  'Mensagem personalizada': {
    es: 'Mensaje personalizado',
    en: 'Custom Message',
    fr: 'Message personnalisé'
  },
  'Estamos criando os documentos a partir deste modelo': {
    es: 'Estamos creandos tus documentos a partir de esta plantilla',
    en: 'We are creating your documents based on this template',
    fr: 'Nous créons vos documents à partir de ce modèle'
  },
  'Aguarde, estamos enviando os documentos para os signatários...': {
    es: 'Aguarda, estamos enviando los documentos para los firmantes...',
    en: 'Please wait, we are sending the documents to the signatories...',
    fr: 'Veuillez patienter, nous envoyons les documents aux signataires...'
  },
  'Documentos enviados com sucesso!': {
    es: '¡Documentos enviados con éxito!',
    en: 'Documents successfully sent!',
    fr: 'Documents envoyés avec succès!'
  },
  'Enviar novo documento': {
    es: 'Enviar nuevo documento',
    en: 'Send new document',
    fr: 'Envoyer un nouveau document'
  },
  'Ver documentos enviados': {
    es: 'Ver documentos enviados',
    en: 'View sent documents',
    fr: 'Voir les documents envoyés'
  },
  'Email enviado com sucesso! Você pode acompanhar o status do documento na pasta destino de todos os documentos criados a partir desse modelo.': {
    es: '¡Correo electrónico enviado exitosamente! Puedes seguir el estado del documento en la carpeta de destino de todos los documentos creados a partir de este modelo.',
    en: 'Email sent successfully! You can track the status of the document in the destination folder of all documents created from this template.',
    fr: 'E-mail envoyé avec succès ! Vous pouvez suivre le statut du document dans le dossier de destination de tous les documents créés à partir de ce modèle.'
  },
  '$0 emails foram enviados com sucesso. Você pode acompanhar o status de cada documento na pasta destino de todos os documentos criados a partir desse modelo.': {
    es: 'Se enviaron tres correos electrónicos con éxito. Puedes seguir el estado de cada documento en la carpeta de destino de todos los documentos creados a partir de este modelo.',
    en: 'Three emails were sent successfully. You can track the status of each document in the destination folder of all documents created from this template.',
    fr: 'Trois e-mails ont été envoyés avec succès. Vous pouvez suivre le statut de chaque document dans le dossier de destination de tous les documents créés à partir de ce modèle.'
  },
  'Falha no envio do documento. E-mail não encontrado.': {
    es: 'No se pudo enviar el documento: correo electrónico no encontrado.',
    en: 'Failed to send the document. Email not found.',
    fr: 'Échec de l\'envoi du document. Email non trouvé.'
  },
  'Cupom válido somente para primeira compra e planos WEB': {
    es: 'Cupón válido sólo para primera compra y planes WEB',
    en: 'Coupon valid only for first purchase and WEB plans',
    fr: 'Coupon valide uniquement pour le premier achat et les forfaits WEB'
  },
  'Preencher e enviar documentos em massa': {
    es: 'Diligenciar y enviar masivamente',
    en: 'Fill out and send in bulk',
    fr: 'Remplir et envoyer en masse'
  },
  '1) Baixe a planilha base e preencha com as informações dos campos dinâmico para cada signatário.': {
    es: '1) Descarga la plantilla base CSV y diligencia la información de los campos dinámicos. Cada fila será un documento que será enviado.',
    en: '1) Download the CSV base template and fill in the information for the dynamic fields. Each row will represent a document that will be sent.',
    fr: '1) Téléchargez le modèle CSV de base et remplissez les champs dynamiques. Chaque ligne correspondra à un document qui sera envoyé.'
  },
  '2) Suba a planilha preenchida e clique em enviar. A plataforma cria e envia os documentos para o e-mail de cada signatário.': {
    es: '2) Sube la plantilla y haz clic en Enviar. ZapSign creará y enviará los documentos para el correo de cada firmante.',
    en: '2) Upload the template and click Send. ZapSign will create and send the documents to each signer\'s email.',
    fr: '2) Téléchargez le modèle et cliquez sur Envoyer. ZapSign créera et enverra les documents à l\'adresse électronique de chaque signataire.'
  },
  'Preencha todos os campos na planilha antes de enviar o documento, pois o signatário não poderá adicionar informações e os campos vazios na planilha ficarão vazios no documento final.': {
    es: 'Diligencia todos los campos de la plantilla antes de enviar el documento ya que los campos vacíos no podrán ser diligenciados por el firmante y estarán vacíos en el documento final.',
    en: 'Fill in all the fields in the template before sending the document, as empty fields cannot be filled out by the signer and will remain empty in the final document.',
    fr: 'Remplissez tous les champs du modèle avant d\'envoyer le document, car les champs vides ne peuvent pas être remplis par le signataire et seront vides dans le document final.'
  },
  'Atenção! ': {
    es: 'Atención: ',
    en: 'Note: ',
    fr: 'Attention :'
  },
  ' documentos serão enviados': {
    es: ' documentos serán enviados',
    en: ' documents will be sent',
    fr: ' documents seront envoyés'
  },
  ' documento será enviado': {
    es: ' documento será enviado',
    en: ' document will be sent',
    fr: ' document seront envoyés'
  },
  'Baixar planilha base do documento': {
    es: 'Descargar plantilla base del documento',
    en: 'Download the base template of the document',
    fr: 'Téléchargez le modèle de base du document'
  },
  'Por favor, tente novamente.': {
    es: 'Por favor, intenta nuevamente.',
    en: 'Please, try again.',
    fr: 'Veuillez réessayer.'
  },
  'Não foi possível gerar o documento.': {
    es: 'No fue posible crear los documentos.',
    en: 'Unable to create the documents.',
    fr: 'Impossible de créer les documents.'
  },
  'Aguarde, estamos carregando o seu documento...': {
    es: 'Espera, estamos cargando tu documento...',
    en: 'Wait, we are loading your document...',
    fr: 'Attendez, nous chargeons votre document...'
  },
  'a partir do dia': {
    es: 'a partir del día',
    en: 'a partir do dia',
    fr: 'à partir du jour'
  },
  'Salvar nos contatos as informações preenchidas pelo signatário.': {
    es: 'Guardar en contactos la información diligenciada por el firmante.',
    en: 'Save in contacts the information provided by the signatory.',
    fr: 'Enregistrer dans les contacts les informations fournies par le signatai.'
  },
  'As informações sensíveis preenchidas pelo signatário (telefone, RG e CPF) serão salvas nos contatos.': {
    es: 'La información sensible diligenciada por el firmante (telefono) se guardará en contactos.',
    en: 'The sensitive information provided by the signatory (phone number) will be saved in contacts.',
    fr: 'Les informations sensibles fournies par le signataire (numéro de téléphone) seront enregistrées dans les contacts.'
  },
  'Mascarar os dados sensíveis do signatário.': {
    es: 'Enmascarar los datos confidenciales del firmante.',
    en: 'Mask the sensitive data of the signatory.',
    fr: 'Masquer les données sensibles du signataire.'
  },
  'Os dados confidenciais do signatário (telefone, RG e CPF) serão ocultados nos contatos. Os usuários com as funções de "Proprietário" e "Administrador" poderão ver todas as informações.': {
    es: 'Enmascarar los datos confidenciales del firmante. Los datos confidenciales del firmante (teléfono) estarán ocultos en contactos. Los usuarios con rol "Propietario" y "Administrador" podrán ver toda la información.',
    en: 'The signatory\'s confidential data (phone) will be hidden in contacts. Users with the roles of "Owner" and "Administrator" will be able to see all the information.',
    fr: 'Les données confidentielles du signataire (téléphone) seront masquées dans les contacts. Les utilisateurs ayant les rôles de "Propriétaire" et "Administrateur" pourront voir toutes les informations.'
  },
  'Editar envelope': {
    es: 'Editar sobre',
    en: 'Edit envelope',
    fr: 'Modifier l\'enveloppe'
  },
  'Ao reordenar, eliminar ou adicionar documentos ao envelope, será necessário reposicionar os campos de assinatura em cada documento.': {
    es: 'Al reordenar, eliminar o adicionar documentos al sobre será necesario reposicionar los campos de firma en cada documento.',
    en: 'After rearranging, deleting, or adding documents to the envelope, it will be necessary to reposition the signature fields on each document.',
    fr: 'Après avoir réorganisé, supprimé ou ajouté des documents à l\'enveloppe, il sera nécessaire de repositionner les champs de signature sur chaque document.'
  },
  'Você não deu acesso a sua câmera. Suba um arquivo da sua galeria para podermos continuarmos o processo de assinatura. Para isso, feche essa mensagem e clique em `Fotografar Selfie`.' : {
    es: 'No has dado acceso a tu cámara. Sube un archivo de tu galería para que podamos continuar el proceso de firma. Para ello, cierra este mensaje y haz clic en `Fotografiar Selfie`.',
    en: 'You have not given access to your camera. Upload a file from your gallery so we can continue the signing process. To do this, close this message and click on `Take Selfie`.',
    fr: 'Vous n\'avez pas donné accès à votre appareil photo. Téléchargez un fichier depuis votre galerie pour que nous puissions continuer le processus de signature. Pour ce faire, fermez ce message et cliquez sur `Prendre un selfie`.'
  },
  'Na parte escrita \'Block\', clique na lixeira para resetar suas permissões de acesso a câmera.': {
    es: 'En la parte escrita \'Block\', haz clic en el icono de la papelera para restablecer tus permisos de acceso a la cámara.',
    en: 'In the \'Block\' section, click on the trash can icon to reset your camera access permissions.',
    fr: 'Dans la section \'Block\', cliquez sur l\'icône de la corbeille pour réinitialiser vos autorisations d\'accès à la caméra.'
  },
  'Recarregue essa página da Zapsign e clique em \'Fotografar selfie\' novamente.': {
    es: 'Recarga esta página de Zapsign y haz clic en \'Fotografiar selfie\' de nuevo.',
    en: 'Reload this Zapsign page and click \'Take selfie\' again.',
    fr: 'Rechargez cette page Zapsign et cliquez à nouveau sur \'Prendre un selfie\'.'
  },
  'Permita a permissão da sua webcam clicando no botão \'Allow\'.': {
    es: 'Permite el acceso a tu cámara web haciendo clic en el botón \'Permitir\'.',
    en: 'Allow access to your webcam by clicking the \'Allow\' button.',
    fr: 'Autorisez l\'accès à votre webcam en cliquant sur le bouton \'Autoriser\'.'
  },
  'Recarregue o navegador e clique novamente em \'Fotografar selfie\'': {
    es: 'Recarga el navegador y haz clic de nuevo en \'Fotografiar selfie\'',
    en: 'Reload the browser and click \'Take selfie\' again',
    fr: 'Rechargez le navigateur et cliquez à nouveau sur \'Prendre un selfie\''
  },
  'Aceite as permissões, clicando no botão \'Allow\'. Não se esqueça de relembrar sua decisão, clicando em \'Remember this decision\'.': {
    es: 'Acepta los permisos haciendo clic en el botón \'Permitir\'. No olvides recordar tu decisión haciendo clic en \'Recordar esta decisión\'.',
    en: 'Accept the permissions by clicking the \'Allow\' button. Don\'t forget to remember your decision by clicking \'Remember this decision\'.',
    fr: 'Acceptez les autorisations en cliquant sur le bouton \'Autoriser\'. N\'oubliez pas de vous souvenir de votre décision en cliquant sur \'Se souvenir de cette décision\'.'
  },
  'Etapa 1': {
    es: 'Paso 1',
    en: 'Step 1',
    fr: 'Étape 1'
  },
  'Etapa 2': {
    es: 'Paso 2',
    en: 'Step 2',
    fr: 'Étape 2'
  },
  'Etapa 3': {
    es: 'Paso 3',
    en: 'Step 3',
    fr: 'Étape 3'
  },
  'Etapa 4': {
    es: 'Paso 4',
    en: 'Step 4',
    fr: 'Étape 4'
  },
  'Editar assinatura': {
    es: 'Editar firma',
    en: 'Edit signature',
    fr: 'Modifier la signature'
  },
  'Enviamos um novo link de acesso em seu email $0 Em caso de duvidas ou problemas, contate a pessoa que te enviou o documento.': {
    es: 'Enviamos un nuevo link de acceso a tu correo electrónico $0 Si tienes dudas o problemas, contacta a la persona que te envio el documento.',
    en: 'We have sent a new access link to your email $0 If you have any questions or issues, please contact the person who sent you the document.',
    fr: 'Nous avons envoyé un nouveau lien d\'accès à votre adresse e-mail $0 Si vous avez des questions ou des problèmes, veuillez contacter la personne qui vous a envoyé le document.',
  },
  'Uma cópia do documento está disponível em sua conta ZapSign.': {
    es: 'Una copia del documento está disponible en tu cuenta de ZapSign.',
    en: 'A copy of the document is available in your ZapSign account.',
    fr: 'Une copie du document est disponible dans votre compte ZapSign.'
  },
  'Ops! O link está incorreto ou o documento pode ter sido excluído. Por favor, entre em contato com o autor do documento.': {
    es: '¡Ups! El enlace es incorrecto o el documento puede haber sido eliminado. Por favor, ponte en contacto con el autor del documento.',
    en: 'Oops! The link is incorrect or the document may have been deleted. Please contact the author of the document.',
    fr: 'Oups ! Le lien est incorrect ou le document a peut-être été supprimé. Veuillez contacter l\'auteur du document.'
  },
  'Documento não encontrado!': {
    es: '¡Documento no encontrado!',
    en: 'Document not found!',
    fr: 'Document non trouvé !'
  },
  'Conhecer a ZapSign': {
    es: 'Conoce ZapSign',
    en: 'Get to know ZapSign',
    fr: 'Découvrez ZapSign'
  },
  'Sem recursos avançados': {
    es: 'Sin funciones avanzadas',
    en: 'No advanced features',
    fr: 'Aucune fonctionnalité avancée'
  },
  'Você perderá acesso a alguns recursos avançados da ferramenta, como autenticações avançadas.': {
    es: 'Perderá el acceso a algunas de las funciones avanzadas de la herramienta, como las autenticaciones avanzadas.',
    en: "You will lose access to some of the tool's advanced features, such as advanced authentications.",
    fr: "Vous perdrez l'accès à certaines fonctionnalités avancées de l'outil, telles que les authentifications avancées."
  },
  'Sua Organização': {
    es: 'Tu organización',
    en: "YYour Organization",
    fr: "VTon organisation"
  },
  'Seu Certificado de Empresa Sustentável está pronto!': {
    'es': '¡Tu Certificado de Empresa Sostenible está listo!',
    'en': 'Your Sustainable Company Certificate is ready!',
    'fr': "Votre certificat d'entreprise durable est prêt !"
  },
  'Ao escolher a Zapsign, você está investindo em eficiência, economia e, acima de tudo, sustentabilidade': {
    'es': 'Al elegir Zapsign, estás invirtiendo en eficiencia, ahorro y, sobre todo, sostenibilidad',
    'en': 'By choosing Zapsign, you are investing in efficiency, savings, and above all, sustainability',
    'fr': "En choisissant Zapsign, vous investissez dans l'efficacité, les économies et surtout, la durabilité"
  },
  'Confira essa conquista e compartilhe com a sua rede! Clique aqui': {
    'es': '¡Consulta este logro y compártelo con tu red! Haz clic aquí',
    'en': 'Check out this achievement and share it with your network! Click here',
    'fr': 'Découvrez cette réalisation et partagez-la avec votre réseau ! Cliquez ici'
  },
  'Sua senha é muito curta': {
    es: 'Tu contraseña es demasiado corta',
    en: 'Your password is too short',
    fr: 'Votre mot de passe est trop court'
  },
  'Sua senha não tem caracteres minusculos': {
    es: 'Tu contraseña no tiene caracteres minúsculos',
    en: 'Your password does not have lowercase characters',
    fr: 'Votre mot de passe ne contient pas de caractères minuscules'
  },
  'Sua senha não tem caracteres maiusculos': {
    es: 'Tu contraseña no tiene caracteres mayúsculos',
    en: 'Your password does not have uppercase characters',
    fr: 'Votre mot de passe ne contient pas de caractères majuscules'
  },
  'Sua senha não tem numeros': {
    es: 'Tu contraseña no tiene números',
    en: 'Your password does not have numbers',
    fr: 'Votre mot de passe ne contient pas de chiffres'
  },
  'Sua senha não tem caracteres especiais': {
    es: 'Tu contraseña no tiene caracteres especiales',
    en: 'Your password does not have special characters',
    fr: 'Votre mot de passe ne contient pas de caractères spéciaux'
  },
  'Limite de vezes atingido, tente novamente mais tarde.': {
    es: 'Límite de intentos alcanzado, inténtelo de nuevo más tarde.',
    en: 'Attempt limit reached, please try again later.',
    fr: 'Limite de tentatives atteint, veuillez réessayer plus tard.'
  },
  'Essa ação não pode ser desfeita. Os contatos permanecem salvos na agenda, mas o grupo será removido.': {
    es: 'Esta acción no se puede deshacer. Los contactos permanecerán guardados en la agenda, pero el grupo será eliminado.',
    en: 'This action cannot be undone. Contacts will remain saved in the address book, but the group will be removed.',
    fr: 'Cette action ne peut pas être annulée. Les contacts resteront sauvegardés dans le carnet d\'adresses, mais le groupe sera supprimé.'
  }
};

