<div class="container-upload-signature">
  <ng-container *ngIf="openSignatureCamera">
    <app-request-modal-old></app-request-modal-old>
    <app-no-camera-available></app-no-camera-available>
    <app-modal-documents-old
      *ngIf="userAgent.isDesktop"
      [comeFromUploadSignature]="true"
      (emmitHandleImageSignature)="handleImageSignature()">
    </app-modal-documents-old>
  </ng-container>
  <div *ngIf="userAgent.isMobile || userAgent.isTablet" class="zs-buttons-mobile-documents">
    <section class="container-button-upload-signature">
      <button mat-stroked-button type="button" (click)="fileInput.click()" class="button-upload-photo-signature zs-take-snapshot">
        <mat-icon>camera_alt</mat-icon><span>{{'Tirar foto' | translate}}</span>
      </button>
      <input hidden id="photo_file" (change)="onFileSelected(true)" #fileInput type="file" accept="image/x-png,image/jpeg" capture="environment">
    </section>
  </div>
  <ng-container *ngIf="!finishSignatureImageProcess">
    <section class="container-button-upload-signature">
      <div class="zs-snapshot-and-upload-signature">
        <button *ngIf="userAgent.isDesktop"
                mat-stroked-button type="button"
                (click)="openModalPhoto()"
                class="button-upload-photo-signature">
          <mat-icon>camera_alt</mat-icon>
          <span>{{'Tirar foto' | translate}}</span>
        </button>
        <button mat-stroked-button type="button" (click)="fileInput.click()" class="button-upload-photo-signature">
          <mat-icon class="button-icon-upload-photo-signature">cloud_upload</mat-icon>
          <span>{{'Subir assinatura' | translate}}</span>
        </button>
      </div>
      <input hidden (change)="onFileSelected(false)" #fileInput type="file" id="file" accept="image/x-png,image/jpeg">
      <small [style.marginBottom]="signature_image ? '1rem' : '0'">
        <span>{{'Extensões de arquivo permitidas: .png ou .jpeg' | translate}}</span>
      </small>
    </section>

    <section class="container-preview-photo" *ngIf="signature_image === ''">
      <div>
        <img id="preview-photo-upload-signature-test-id" src="assets/img/preview-upload-signature.svg"
             [alt]="'Pré visualização da sua foto da assinatura' | translate">
      </div>
    </section>
  </ng-container>
  <ng-container *ngIf="signature_image != '' && !finishSignatureImageProcess">
    <div class="signature_image">
      <angular-cropper
        #angularCropper
        [cropperOptions]="config"
        [imageUrl]="signature_image"
      ></angular-cropper>
    </div>

    <button id='finalize-section-button-test-id'class="finish-cropping-btn" mat-raised-button color="primary"
            (click)="finishCropping()">{{'Finalizar seleção'|translate}}</button>
  </ng-container>
  <div *ngIf="signature_image != '' && finishSignatureImageProcess">
    <img id="signature-image-preview" [src]="signature_image"
         alt="Signature photo user">
    <br>
    <button class="cancel-cropping-btn" mat-raised-button (click)="undoImage()">
      <mat-icon>close</mat-icon>
      {{'Cancelar'|translate}}</button>
  </div>
</div>
