import {Action, createReducer, on} from '@ngrx/store';
import * as actions from './users.action';
import {UserReducerProps} from './users.model';

export const INITIAL_STATE: UserReducerProps = {
  user: null
};

const _currentUserReducer = createReducer(
  INITIAL_STATE,
  on(actions.setUserReducer, (state, { payload }) => ({
    ...state,
    user: payload
  })),

);

export function currentUserReducer(state: UserReducerProps, action: Action) {
  return _currentUserReducer(state, action);
}
