<div id="myModal" class="modal" (click)="closeModal()">
  <div class="modal-content" (click)="preventModalFromClosing($event)">
    <div class="new-folder-header">
      <h2 *ngIf="!editFolder">{{'Nova pasta' | translate}}</h2>
      <h2 *ngIf="editFolder">{{'Editar pasta' | translate}}</h2>
      <div class="new-folder-header-right">
        <div class="new-folder-secret-toogle" *ngIf="canBeSecret">
          <span>{{'secreta' | translate}}</span>
          <mat-slide-toggle [(ngModel)]="secretFolder" (change)="changeSecretFolder($event.checked)"></mat-slide-toggle>
        </div>
        <zs-button typeButton="icon" (click)="closeModal()" icon="icon_x_close">
        </zs-button>
      </div>
    </div>
    <div class="new-folder-body">
      <form class="new-folder-form" [formGroup]="formGroup" (ngSubmit)="addFolder()">
        <zs-text-input
          [label]="'Título' | translate"
          [placeholder]="'Título da pasta' | translate"
          (keyup)="cleanNewFolderName()"
          [limitLength]="30"
          formControlName="title"
        ></zs-text-input>
        <app-select-to-input
          *ngIf="secretFolder && availableMembershipsToAdd && availableMembershipsToAdd.length > 0"
          [value]="selectedUser"
          [options]="availableMembershipsToAdd"
          label="Adicionar usuário"
          classRadius="all-radius"
          (valueChange)="addMemberToSecretFolder($event)"
        ></app-select-to-input>
        <div class="help-note" *ngIf="secretFolder">
          <span>{{'Proprietário da conta tem acesso a todas as pastas' | translate}}</span>
        </div>
      </form>
      <div class="new-folder-access-people" *ngIf="secretFolder">
        <div class="new-folder-access-people-header">
          <h3>{{'Pessoas com acesso' | translate}}</h3>
          <span>{{'$0 usuário' | translate : [folderMembership.length]}}{{folderMembership.length > 1 ? 's' : ''}}</span>
        </div>

        <ul class="access-people-list">
          <li class="access-people-row" *ngFor="let membership of folderMembership">
            <div class="access-people-row-left">
              <div class="initial_circular">{{membership.name[0]}}</div>
              <span>{{membership.name}}</span>
            </div>
            <zs-button *ngIf="membership.name !== userEmail"
                       typeButton="icon"
                       icon="icon_x_close"
                       (click)="removeMembership(membership.value)"
            >
            </zs-button>
          </li>
        </ul>
      </div>
    </div>
    <div class="new-folder-footer">
      <zs-button typeButton="quaternary" (click)="closeModal()" label="Cancelar">
      </zs-button>
      <zs-button typeButton="primary"
                 (click)="addFolder()"
                 [label]="!editFolder ? 'Criar pasta' : 'Salvar alterações'"
                 [disable]="disableButton"
      >
      </zs-button>
    </div>
  </div>
</div>
