<mat-form-field [ngStyle]="{'width': width}" [ngClass]="{'invalid-input': showError}">
  <mat-label>{{ label | translate }}</mat-label>
  <input
    matInput
    class="zs-text-input"
    [type]="hidePassword ? 'password' : 'text'"
    [placeholder]="placeholder | translate"
    [attr.disabled]="disabled"
    [formControl]="control"
  >
  <zs-button
    matSuffix
    typeButton="input_icon"
    type="button"
    [icon]="hidePassword ? 'eye-off' : 'eye'"
    (click)="toggleHidePassword()"
    [attr.aria-label]="'Hide password'"
    [attr.aria-pressed]="hidePassword"
  >
  </zs-button>
</mat-form-field>
