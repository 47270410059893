import {Injectable} from "@angular/core";
import {getCurrentUserEmail} from "../services/current-user";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class LaunchDarkly {
  static instance;

  static getInstance() {
    if (LaunchDarkly.instance == null) {
      LaunchDarkly.instance = require('launchdarkly-js-client-sdk');
    }
    return LaunchDarkly.instance;
  }

  static getClient() {
    const context = {
      kind: 'user',
      key: getCurrentUserEmail(true)
    };
    // TODO isolar o identificador do projeto em uma variavel de ambiente
    let launch_darkly_client_id;
    if(environment.production == true) {
      launch_darkly_client_id = '640b4797c419d91382489ed6';
    } else if(environment.sandbox == true) {
      launch_darkly_client_id = '64c2a1d70811eb147e6d2963';
    } else {
      launch_darkly_client_id = '640b4797c419d91382489ed5';
    }
    return LaunchDarkly.getInstance().initialize(launch_darkly_client_id, context);
  }
}
