import {Component} from '@angular/core';
import {TranslatorService} from '../translator.service';
import {Language} from '../../../translation/translation';
import {CookieService} from '../cookie.service';

@Component({
  selector: 'app-send-document-signature-card',
  templateUrl: './send-document-signature-card.component.html',
  styleUrls: ['./send-document-signature-card.component.scss']
})
export class SendDocumentSignatureCardComponent  {
  lang = localStorage.getItem('lang') as Language || 'pt-br';

  constructor(private translatorService: TranslatorService, private cookieService: CookieService) {
  }

  t(ptText: string, replacements: string[] = []) {
    return this.translatorService.translate(ptText, this.lang, replacements);
  }

  setKeyInSessionStorageToTrackUser() {
    this.cookieService.setCookie('origin_register', 'meet_zapsign_button', 168, '/');
  }
}
