import {Options} from 'dom-to-image';

type OptionsFonts =
  | 'Bad Script, cursive'
  | 'Chilanka, cursive'
  | 'Dancing Script, cursive'
  | 'Grand Hotel, cursive'
  | 'Inter, cursive'
  | 'Sacramento, cursive'
  | 'Satisfy, cursive';

export interface Fonts {
  font: OptionsFonts;
}

const scale = 2;

export function setOptionsConversionImage(element: HTMLDivElement): Options {
  return {
    quality: 1,
    bgcolor: 'transparent',
    imagePlaceholder: 'Image generated by font cursive',
    width: element.clientWidth * scale,
    height: element.clientHeight * scale
  };
}

