import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PhoneNumberService {
  locale = localStorage.getItem('locale') || 'br';
  lang = localStorage.getItem('lang') || 'pt-br';


  getDefaultPhoneCountry() {
    if (this.locale === 'global' && this.lang === 'en') {
      return '1';
    } else if (this.locale === 'global' && this.lang === 'es') {
      return '57'; // TODO pegar o pais atual pra tomar a decisão do prefixo, por enquanto padrão pra latam é colombia
    }

    return '55';
  }

}
