import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  setKey(key: string, value: string) {
    sessionStorage.setItem(key, value);
  }

  getKey(key: string): string {
    return sessionStorage.getItem(key);
  }

  removeKey(key: string) {
    sessionStorage.removeItem(key);
  }
}
