import {ActionReducerMap} from '@ngrx/store';
import {TranslationProps} from 'src/translation/translation';
import {translationReducer} from 'src/translation/translation.reducer';
import SaveEmailAuthenticationScreensProps from './auth/store/save-email-auth-unauthorized/save-email-unauthorized-model';
import {saveEmailUnauthorizedReducer} from './auth/store/save-email-auth-unauthorized/save-email-unauthorized.reducer';
import SavePasswordAuthenticationScreensProps from './auth/store/save-password-auth-unauthorized/save-password-unauthorized-model';
import {savePasswordUnauthorizedReducer} from './auth/store/save-password-auth-unauthorized/save-password-unauthorized.reducer';
import CheckStoreProps from './client/page-checks/store/check.model';
import {checkReducer} from './client/page-checks/store/check.reducer';
import {ExpiredSignerProps} from './client/page-expired-link/store/ExpiredEmail/expired-email.model';
import {expiredEmailReducer} from './client/page-expired-link/store/ExpiredEmail/expired-email.reducer';
import {ChangeDocsFilterProps} from './client/page-my-documents/store/change-docs-filter/change-docs-filter.model';
import {changeDocsFilterReducer} from './client/page-my-documents/store/change-docs-filter/change-docs-filter.reducer';
import {DocsCounterProps} from './client/page-my-documents/store/docs-counter/docs-counter.model';
import {docsCounterReducer} from './client/page-my-documents/store/docs-counter/docs-counter.reducer';
import {SetIsFirstUserProps} from './client/page-my-documents/store/first-user/set-is-first-user.model';
import {setIsFirstUserReducer} from './client/page-my-documents/store/first-user/set-is-first-user.reducer';
import {FolderWhereUserWasInDocumentListProps} from './client/page-my-documents/store/folder-where-user-was-in-document-list/folder-where-user-was-in-document-list-redux.model';
import {folderWhereUserWasInDocumentListReduxReducer} from './client/page-my-documents/store/folder-where-user-was-in-document-list/folder-where-user-was-in-document-list-redux.reducer';
import {LotDocsProps} from './client/page-my-documents/store/lot-docs/lot-docs.model';
import {docsLotReducer} from './client/page-my-documents/store/lot-docs/lot-docs.reducer';
import {CompanySelected} from './client/page-my-documents/store/using-company/using-company.model';
import {companySelectedReducer} from './client/page-my-documents/store/using-company/using-company.reducer';
import {NewUserProps} from './client/page-new-document/store/new-user.model';
import {newUserReducer} from './client/page-new-document/store/new-user.reducer';
import StepNewDocumentProps from './client/page-new-document/store/steps-new-document/steps-new-document.model';
import stepsNewDocumentReducer from './client/page-new-document/store/steps-new-document/steps-new-document.reducer';
import {PaywallReducerProps} from './client/page-plans-and-price-component/store/paywall/paywall.model';
import {currentPaywallReducer} from './client/page-plans-and-price-component/store/paywall/paywall.reducer';
import {SubscriptionReducerProps} from './client/page-plans-and-price-component/store/subscriptions/subscriptions.model';
import {currentSubscriptionReducer} from './client/page-plans-and-price-component/store/subscriptions/subscriptions.reducer';
import {UserReducerProps} from './client/page-plans-and-price-component/store/users/users.model';
import {currentUserReducer} from './client/page-plans-and-price-component/store/users/users.reducer';
import {GeneratedRubricasStates, SignatureClickPDF, UpdateSignedFileProps} from './client/page-posicionar-rubricas/store/signature/signature.model';
import {hasToUpdateSingedFileReducer, rubricaTextReducer, signatureReducer} from './client/page-posicionar-rubricas/store/signature/signature.reducer';
import SaveUserGroupProps from './client/settings-organization/store/user-group/user-group.model';
import {userGroupReducer} from './client/settings-organization/store/user-group/user-group.reducer';
import {PlansReducerProps} from './client/settings-payment/store/plans.model';
import plansReducer from './client/settings-payment/store/plans.reducer';
import {GetUserProps} from './client/store/get-user-properties/get-user-properties.model';
import {getUserPropertiesReducer} from './client/store/get-user-properties/get-user-properties.reducer';
import {advancedAuthTypesReducer} from './client/who-sign-list/services/take-advanced-authentication.reducer';
import {ModalActivateAccountProps} from './components/modal-activate-account/store/modal-activate-account.model';
import {modalActivateAccountReducer} from './components/modal-activate-account/store/modal-activate-account.reducer';
import {NoComputerCameraProps} from './components/modal-camera/store/NoComputerCamera/no-computer-camera.model';
import {noComputerCameraReducer} from './components/modal-camera/store/NoComputerCamera/no-computer-camera.reducer';
import {PhotoDocumentProps} from './components/modal-camera/store/PhotoDocument/photo-document.model';
import {photoDocumentReducer} from './components/modal-camera/store/PhotoDocument/photo-document.reducer';
import {PhotoWebcamProps} from './components/modal-camera/store/PhotoSelfie/photo.model';
import {reducer} from './components/modal-camera/store/PhotoSelfie/photo.reducer';
import {RequestModalCamera} from './components/modal-camera/store/RequestModal/request-modal.model';
import {requestModalReducer} from './components/modal-camera/store/RequestModal/request-modal.reducer';
import {SelfieValidationProps} from './components/modal-camera/store/SelfieValidation/selfieValidation.model';
import {selfieValidationReducer} from './components/modal-camera/store/SelfieValidation/selfieValidation.reducer';
import {CounterDocumentsProps} from './components/user-navbar/counter-documents/store/counter-documents/counter-documents.model';
import {counterDocumentsReducer} from './components/user-navbar/counter-documents/store/counter-documents/counter-documents.reducer';
import {reducerNewsletterModal} from './components/user-navbar/newsletter-modal/store/newsletter-modal-reducer';
import {NewsletterNotificationCount} from './components/user-navbar/user-navbar.model';
import {SlugTextType} from './helpers-global.service';
import {MembershipsModel} from './reducer/models/memberships.model';
import {UserModalProps} from './reducer/models/modalUser.model';
import {requestMembershipsReducer} from './reducer/reducers/memberships.reducer';
import {requestModalUserReducer} from './reducer/reducers/modal-user.reducer';
import {SetLoadingSignedFileReduxProps} from './signers/sign-page/steps-signature/store/setLoadingSignedFileRedux/set-loading-signed-file-redux.models';
import {setLoadingSignedFileReducer} from './signers/sign-page/steps-signature/store/setLoadingSignedFileRedux/set-loading-signed-file-redux.reducer';
import {setSignerReducer} from './signers/sign-page/steps-signature/store/setSignerRedux/set-signer-redux-reducer';
import {SetSignerProps} from './signers/sign-page/steps-signature/store/setSignerRedux/set-signer-redux.models';
import {CurrentTabActiveMethodsSignature} from './signers/sign-page/store/current-tab/current-tab-models';
import {currentTabReducer} from './signers/sign-page/store/current-tab/current-tab-reducer';
import {PrimaryColorRedux} from './signers/sign-page/store/primary-color/primary-color.models';
import {primaryColorReducer} from './signers/sign-page/store/primary-color/primary-color.reducer';
import {SignPageProps} from './signers/store/SignPage/signpage.model';
import {userAgentReducer} from './signers/store/SignPage/signpage.reducer';
import {SignPageFooterProps} from './signers/sign-page-footer/store/sign-page-footer.model';
import signPageFooterReducer from './signers/sign-page-footer/store/sign-page-footer.reducer';
import {SignPageHeaderProps} from './signers/sign-page-header/store/sign-page-header.model';
import signPageHeaderReducer from './signers/sign-page-header/store/sign-page-header.reducer';
import { LoginUrlQueryParamsProps } from './auth/login/store/login.model';
import { loginUrlQueryParamsReducer } from './auth/login/store/login.reducer';
import {LivenessValidationProps} from './components/modal-liveness/store/LivenessValidation/liveness-validation.model';
import {livenessValidationReducer} from './components/modal-liveness/store/LivenessValidation/liveness-validation.reducer';
import {Folder, FolderLinear} from './helpers/model/docs';
import {folderToBeMovedReducer} from './client/modal-folders/store/folder-to-be-moved.reducer';

export interface AppState {
  newsletterNotificationCount: NewsletterNotificationCount;
  base64: PhotoWebcamProps;
  expiredEmail: ExpiredSignerProps;
  userAgent: SignPageProps;
  signPageFooter: SignPageFooterProps;
  signPageHeader: SignPageHeaderProps;
  requestModalPermissionCamera: RequestModalCamera;
  modalUser: UserModalProps;
  memberships: MembershipsModel;
  base64_photo_document: PhotoDocumentProps;
  modalNoComputerCamera: NoComputerCameraProps;
  folderIamIn: FolderWhereUserWasInDocumentListProps;
  signature: SignatureClickPDF;
  lotDoc: LotDocsProps;
  companyId: CompanySelected;
  loginUrlQueryParamsReducer: LoginUrlQueryParamsProps;
  newUser: NewUserProps;
  translation: TranslationProps;
  docsCounter: DocsCounterProps;
  changeDocsFilter: ChangeDocsFilterProps;
  hasToGenerateRubricaTextImage: GeneratedRubricasStates;
  modalActivateAccountReducer: ModalActivateAccountProps;
  setIsFirstUserReducer: SetIsFirstUserProps;
  hasToUpdateSingedFile: UpdateSignedFileProps;
  saveEmailUnauthorizedReducer: SaveEmailAuthenticationScreensProps;
  savePasswordUnauthorizedReducer: SavePasswordAuthenticationScreensProps;
  getUserPropertiesReducer: GetUserProps;
  setSignerReducer: SetSignerProps;
  setLoadingSignedFileReducer: SetLoadingSignedFileReduxProps;
  primaryColorReducer: PrimaryColorRedux;
  currentTabReducer: CurrentTabActiveMethodsSignature;
  plansReducer: PlansReducerProps;
  counterDocumentsReducer: CounterDocumentsProps;
  stepsNewDocumentReducer: StepNewDocumentProps;
  userGroupReducer: SaveUserGroupProps;
  checkReducer: CheckStoreProps;
  currentUserReducer: UserReducerProps;
  currentSubscriptionReducer: SubscriptionReducerProps;
  currentPaywallReducer: PaywallReducerProps;
  advancedAuthTypes: SlugTextType[];
  selfieValidation: SelfieValidationProps;
  livenessValidation: LivenessValidationProps;
  folderToBeMoved: Folder | FolderLinear;
}

// @ts-ignore
export const appReducers: Readonly<ActionReducerMap<AppState>> = {
  base64: reducer,
  selfieValidation: selfieValidationReducer,
  livenessValidation: livenessValidationReducer,
  base64_photo_document: photoDocumentReducer,
  userAgent: userAgentReducer,
  signPageFooter: signPageFooterReducer,
  signPageHeader: signPageHeaderReducer,
  requestModalPermissionCamera: requestModalReducer,
  modalUser: requestModalUserReducer,
  memberships: requestMembershipsReducer,
  modalNoComputerCamera: noComputerCameraReducer,
  folderIamIn: folderWhereUserWasInDocumentListReduxReducer,
  signature: signatureReducer,
  hasToGenerateRubricaTextImage: rubricaTextReducer,
  lotDoc: docsLotReducer,
  companyId: companySelectedReducer,
  loginUrlQueryParamsReducer,
  newUser: newUserReducer,
  translation: translationReducer,
  docsCounter: docsCounterReducer,
  changeDocsFilter: changeDocsFilterReducer,
  modalActivateAccountReducer,
  setIsFirstUserReducer,
  hasToUpdateSingedFile: hasToUpdateSingedFileReducer,
  newsletterNotificationCount: reducerNewsletterModal,
  advancedAuthTypes: advancedAuthTypesReducer,
  saveEmailUnauthorizedReducer,
  savePasswordUnauthorizedReducer,
  getUserPropertiesReducer,
  setLoadingSignedFileReducer,
  primaryColorReducer,
  currentTabReducer,
  setSignerReducer,
  expiredEmail: expiredEmailReducer,
  plansReducer,
  counterDocumentsReducer,
  stepsNewDocumentReducer,
  userGroupReducer,
  checkReducer,
  currentUserReducer,
  currentSubscriptionReducer,
  currentPaywallReducer,
  folderToBeMoved: folderToBeMovedReducer
};
