import { Injectable } from '@angular/core';
import {AuthBackendService} from "../../../auth/backend.service";
import {WarningService} from "../../../components/warning.service";
import {Router} from "@angular/router";
import {TranslatorService} from "../../../components/translator.service";
import {UserIsOwnerValidationService} from "../user-is-owner-validation/user-is-owner-validation.service";
import {ConsumerTranslatorService} from "../consumer-translator/consumer-translator.service";

@Injectable({
  providedIn: 'root'
})
export class UserCompaniesService {

  constructor(
    private authBackend: AuthBackendService,
    private warningService: WarningService,
    private router: Router,
    private userIsOwner: UserIsOwnerValidationService,
    private translator: ConsumerTranslatorService
  ) { }

  chooseCompany(company: any) {
    this.authBackend.changeMyCompany(company.id).subscribe(
      data => {
        const nome = company.name ? company.name : this.translator.translate('Organização sem nome');
        localStorage.setItem('organization_name', nome);
        localStorage.setItem('companyId', company.id);
        this.userIsOwner.setOwner(company);
        this.warningService.toastrSuccess(this.translator.translate('Agora você está na') + ' ' + nome);
        this.router.navigate(['']);
      },
      err => {
        console.error(err),
          this.warningService.toastrError(this.translator.translate('Ocorreu algum problema. Tente novamente mais tarde.'))
      }
    );
  }
}
