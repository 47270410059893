<div class="zs_viewer" [class.have-scroll]="haveScroll">
  <div class="zs_viewer_tools" *ngIf="showTools">
    <!--<div *ngIf="pdf">
        Página {{page}} / {{pdf.numPages }}
    </div>-->

    <div class="zs_tools_btns">

      <button
        [disabled]="zoom <= 1"
        (click)="zoomOut()"
        mat-icon-button [matTooltip]="t('Diminuir o zoom')">
        <mat-icon>remove</mat-icon>
      </button>

      <button *ngIf="!originalSize || zoom > 1"
              (click)="redefineZoom()"
              mat-icon-button [matTooltip]="t('Redefinir zoom')">
        <mat-icon>zoom_out</mat-icon>
      </button>

      <button *ngIf="originalSize && zoom == 1"
              (click)="zoomIn()"
              mat-icon-button [matTooltip]="t('Ajustar à largura')">
        <mat-icon>zoom_in</mat-icon>
      </button>

      <button
        [disabled]="zoom >= 2 || (!originalSize)"
        (click)="zoom = zoom + 0.25"
        mat-icon-button [matTooltip]="t('Aumentar o zoom')">
        <mat-icon>add</mat-icon>
      </button>
    </div>

  </div>
  <div [class]="isSignerFlow ? 'zs_viewer_container' : ''">
    <pdf-viewer *ngFor="let src of pdfSrcList" class="pdf-viewer zs_extra_docs"
                [ngClass]="loadingPdf ? 'loading' : 'loaded'"
                [src]="src"
                [render-text]="false"
                [zoom]="zoom"
                [original-size]="originalSize"
                [fit-to-page]="true"
                [show-all]="true"
                [stick-to-page]="false"
                [(page)]="page"
                (after-load-complete)="afterLoadComplete($event)"
                (page-rendered)="pageRendered($event)"
                style="display: block"
    ></pdf-viewer>
  </div>
  <div class="zs_viewer_container" *ngIf="!pdfSrcList">
    <pdf-viewer class="pdf-viewer"
                [ngClass]="loadingPdf ? 'loading' : 'loaded'"
                [src]="pdfSrc"
                [render-text]="false"
                [zoom]="zoom"
                [original-size]="originalSize"
                [fit-to-page]="true"
                [show-all]="true"
                [stick-to-page]="false"
                [(page)]="page"
                (after-load-complete)="afterLoadComplete($event)"
                (page-rendered)="pageRendered($event)"
                style="display: block"
    ></pdf-viewer>
  </div>
</div>
