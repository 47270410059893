import {Action, createReducer, on} from '@ngrx/store';
import * as actions from './photo.actions';
import {PhotoWebcamProps} from './photo.model';


export const INITIAL_STATE: PhotoWebcamProps = {
  base64: '',
  selfieModal: false,
  whoCalledModalSelfie: ''
};

const photoReducer = createReducer(INITIAL_STATE,
  on(
    actions.base64Photo, (state, { payload }) => ({ ...state, base64: payload })
  ),
  on(actions.hideModalWebcam, (state, {payload}) => ({...state, selfieModal: false, whoCalledModalSelfie: payload})),
  on(actions.viewModalWebcam, (state, { payload }) => ({...state, selfieModal: true, whoCalledModalSelfie: payload})),
);
export function reducer(state: PhotoWebcamProps, action: Action) {
  return photoReducer(state, action);
}
