<section>
  <div class="container-out-of-signer">
    <div class="modal-out-of-order-header">
      <figure>
        <img src="assets/img/pallet.svg"
             alt="Imagem de uma palheta de documentos"/>
      </figure>
    </div>
    <div class="container-content-out-of-order">
      <h2>
        {{'Ainda não é sua vez de assinar' | translate}}
      </h2>
      <hr>
      <div class="modal-out-of-order-footer">
        <span>
          {{'Parece que você ainda não está liberado para assinar este documento porque ainda há outras pessoas que devem assiná-lo antes de você. Não se preocupe, você será notificado quando a sua vez chegar.' | translate}}
        </span>
      </div>
    </div>
  </div>
</section>
