import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {HandleErrorsRequestService} from '../handle-errors-request-service/handle-errors-request.service';
import {catchError} from 'rxjs/operators';

export interface ValidatorImageTensorflow {
  result: string;
  errorValidateImage: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ValidateImageTensorflowService {
  private readonly validateImageTensorflowUrl = environment.SELFIE_VALIDATION_TENSORFLOW_URL;

  constructor(private http: HttpClient, private handleErrorsRequestService: HandleErrorsRequestService) {
  }

  validateImage(image: string): Observable<ValidatorImageTensorflow> {
    return this.http.post<ValidatorImageTensorflow>(this.validateImageTensorflowUrl, {image})
      .pipe(catchError(this.handleErrorsRequestService.getErrorRequest));
  }
}
