import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {FlashMessageComponent} from './flash-message/flash-message.component';

@Injectable({
  providedIn: 'root'
})
export class WarningService {

  constructor(
    private toastr: ToastrService // https://www.npmjs.com/package/ngx-toastr
  ) {
    this.toastr.toastrConfig.preventDuplicates = true;
  }

  enableDuplicates() {
    this.toastr.toastrConfig.preventDuplicates = false;
  }

  disableDuplicates() {
    this.toastr.toastrConfig.preventDuplicates = true;
  }

  toastrSuccess(message: string, title: string = null) {
    this.toastr.success(message, title, {
      toastComponent: FlashMessageComponent,
    });
  }
  toastrInfo(message: string, title: string = null) {
    this.toastr.info(message, title, {
      toastComponent: FlashMessageComponent,
    });
  }
  toastrWarning(message: string, title: string = null) {
    this.toastr.warning(message, title, {
      toastComponent: FlashMessageComponent,
    });
  }
  toastrError(message: string, title: string = null) {
    this.toastr.error(message, title, {
        toastComponent: FlashMessageComponent,
      });
  }
}
