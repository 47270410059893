<div id="myModal" class="modal"
     (click)="closeModal()"
     [style.display]="modalOpen ? 'block':'none'">
  <!-- Modal content -->
  <div class="modal-content" (click)="$event.stopPropagation();">
    <section *ngIf="errors.length === 0; else noPermissionCam">
      <app-liveness-validator
        *ngIf="modalOpen"
        (validate)="handleValidation($event)"
        (handleError)="handleError($event)"
      ></app-liveness-validator>
    </section>
    <zs-button
      *ngIf="!userAgent.isMobile"
      typeButton="secundary"
      label="Abrir no celular"
      (click)="openModalNotHaveCamera()"
    ></zs-button>
    <ng-template #noPermissionCam>
      <app-no-permission-camera [userAgent]="userAgent"></app-no-permission-camera>
    </ng-template>
  </div>
</div>
