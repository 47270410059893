import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Signer} from '@helpers/model/docs';
import {TabsActiveMethodsSignature} from '@signers/sign-page/store/current-tab/current-tab-models';
import {Language} from '@translation/translation';
import {TranslatorService} from '../translator.service';

@Component({
  selector: 'app-select-document-signing-method',
  templateUrl: './select-document-signing-method.component.html',
  styleUrls: ['./select-document-signing-method.component.scss']
})
export class SelectDocumentSigningMethodComponent implements AfterViewInit {
  lang = localStorage.getItem('lang') as Language || 'pt-br';
  @Input() signer: Signer;
  @Output() emitterCurrentTabOptionsSignature = new EventEmitter<TabsActiveMethodsSignature>();
  @Input() hideDrawSignature: boolean;
  disabledTabDrawWhenFocus = false;
  disabledTabUploadWhenFocus = false;
  disabledTabCursiveWhenFocus = false;
  showDrawSignature = true;
  showUploadSignature = true;
  showWriteSignature = true;
  tabActive: TabsActiveMethodsSignature = 'draw';

  functionsBlock = {
    'drawing-upload': () => {
      this.hideDrawSignature = true;
      this.showUploadSignature = false;
      this.showWriteSignature = true;
      this.tabActive = 'write';
      this.setCurrentTab('write');
    },
    'writing-drawing': () => {
      this.hideDrawSignature = true;
      this.showWriteSignature = false;
      this.showUploadSignature = true;
      this.tabActive = 'upload';
      this.setCurrentTab('upload');
    },
    'writing-upload': () => {
      this.showUploadSignature = false;
      this.showWriteSignature = false;
      this.hideDrawSignature = false;
      this.tabActive = 'draw';
      this.setCurrentTab('draw');
    },
    drawing: () => {
      this.hideDrawSignature = true;
      this.showUploadSignature = true;
      this.showWriteSignature = true;
      this.tabActive = 'write';
      this.setCurrentTab('write');
    },
    upload: () => {
      this.showUploadSignature = false;
      this.hideDrawSignature = false;
      this.showWriteSignature = true;
      this.tabActive = 'draw';
      this.setCurrentTab('draw');
    },
    writing: () => {
      this.showWriteSignature = false;
      this.showUploadSignature = true;
      this.tabActive = 'draw';
      this.setCurrentTab('draw');
    }
  };
  constructor(
    private translatorService: TranslatorService,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    if (this.hideDrawSignature) {
      this.emitterCurrentTabOptionsSignature.emit('upload');
      this.cdr.detectChanges();
    }
    this.showUploadSignature =  this.signer.doc.company.signature_photo_active;
    this.showWriteSignature = this.signer.doc.company.cursive_signature_active;
    if (this.signer.doc.company.sign_mode_not_allowed) {
      this.functionsBlock[this.signer.doc.company.sign_mode_not_allowed]();
      this.cdr.detectChanges();
    }
  }

  t(text: string) {
    return this.translatorService.translate(text, this.lang);
  }


  setCurrentTab(tabActive: TabsActiveMethodsSignature) {
    this.emitterCurrentTabOptionsSignature.emit(tabActive);
  }

}
