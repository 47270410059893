<div id="myModal" class="modal"
     (click)="closeModal()"
     [style.display]="modalOpen ? 'block':'none'">
  <!-- Modal content -->
  <div class="modal-content" *ngIf="modalOpen" (click)="$event.stopPropagation();">
    <section *ngIf="errors.length === 0; else noPermissionCam">
      <webcam
        [height]="500"
        [width]="500"
        [trigger]="observableCamera"
        (imageCapture)="handleImage($event)"
        [allowCameraSwitch]="allowCameraSwitch"
        [switchCamera]="nextWebcamObservable"
        [videoOptions]="videoOptions"
        (initError)="handleInitError($event)"
      >
      </webcam>
      <div class="ball"></div>
    </section>
    <ng-template #noPermissionCam>
      <app-no-permission-camera [userAgent]="userAgent"></app-no-permission-camera>
    </ng-template>
    <section id='zs-selfie-buttons'>
      <button
        mat-raised-button
        *ngIf="errors.length == 0 && !loadingImage"
        (click)="takeSnapshot()"
        color="primary"
        matTooltip="{{'Tire uma foto do seu rosto' | translate}}"
      >
        {{'Tirar foto' | translate}}
      </button>
      <button
        mat-raised-button
        *ngIf="loadingImage"
        disabled
        (click)="takeSnapshot()"
        color="primary"
      >
        {{'Analisando imagem...' | translate}}
      </button>
      <button
        mat-raised-button
        *ngIf="errors.length == 0"
        (click)="openModalNotHaveCamera()"
        matTooltip="{{'Algum problema para tirar sua selfie?' | translate}}"
      >
        {{'Abrir no celular' | translate}}
      </button>
    </section>
  </div>
</div>
