import {ControlContainer, ControlValueAccessor, FormControl, FormControlDirective} from '@angular/forms';
import {Input, ViewChild} from '@angular/core';
import {FormGroupValidatorsService} from '../services/FormGroupValidators/form-group-validators.service';

export class ControlValueAccessorProxy implements ControlValueAccessor {
  @ViewChild(FormControlDirective, {static: true})
  formControlDirective: FormControlDirective;

  @Input()
  formControl: FormControl;

  @Input()
  formControlName: string;

  get control() {
    return (this.formControl || this.controlContainer.control.get(this.formControlName)) as FormControl;
  }

  constructor(
    protected formGroupValidatorsService: FormGroupValidatorsService,
    protected controlContainer: ControlContainer
  ) {
  }

  public get invalid(): boolean {
    return this.control ? this.control.invalid : false;
  }

  public get showError(): boolean {
    if (!this.control) {
      return false;
    }

    const {dirty, touched} = this.control;

    return this.invalid ? (dirty || touched) : false;
  }

  public get errorMessage(): string | null {
    const errors = Object.entries(
      this.control?.errors || {}
    );
    if (!errors.length) {
      return null;
    }
    const [key, value] = errors[0];
    return this.formGroupValidatorsService.ERROR_MESSAGES[key];
  }

  registerOnTouched(fn: any): void {
    this.formControlDirective?.valueAccessor.registerOnTouched(fn);
  }

  registerOnChange(fn: any): void {
    this.formControlDirective?.valueAccessor.registerOnChange(fn);
  }

  writeValue(obj: any): void {
    this.formControlDirective?.valueAccessor.writeValue(obj);
  }

  setDisabledState(isDisabled: boolean): void {
    this.formControlDirective?.valueAccessor.setDisabledState(isDisabled);
  }
}
