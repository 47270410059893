import {Action, createReducer, on} from '@ngrx/store';
import * as actions from './liveness-validation.actions';
import {LivenessValidationProps} from './liveness-validation.model';


export const INITIAL_STATE: LivenessValidationProps = {
  livenessModal: false,
  whoCalledModalLiveness: '',
  photo: {
    base64: '',
    url: '',
    file_name: '',
    file_size: 0,
    loading: false
  }
};

const _livenessValidationReducer = createReducer(INITIAL_STATE,
  on(
    actions.livenessPhoto, (state, {payload}) => {
      if (!payload) {
        payload = INITIAL_STATE.photo;
      }
      return ({...state, photo: payload});
    }
  ),
  on(actions.hideModalLiveness, (state, {payload}) => ({...state, livenessModal: false, whoCalledModalLiveness: payload})),
  on(actions.viewModalLiveness, (state, {payload}) => ({...state, livenessModal: true, whoCalledModalLiveness: payload})),
);

export function livenessValidationReducer(state: LivenessValidationProps, action: Action) {
  return _livenessValidationReducer(state, action);
}
