import {Action, createReducer, on} from '@ngrx/store';
import {setIsFirstDocOfUser} from './new-user.actions';
import {NewUserProps} from './new-user.model';

export const INITIAL_STATE: NewUserProps = {
  isFirstDoc: false
};

const _newUserReducer = createReducer(
  INITIAL_STATE,
  on(setIsFirstDocOfUser, (state, params: { isFirstDoc: boolean }) => {
    return {...state, ...params};
  }),
);

export function newUserReducer(state: NewUserProps, action: Action) {
  return _newUserReducer(state, action);
}
