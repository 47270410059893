export type Language = 'pt-br' | 'es' | 'en' | 'fr';
export type   Locale = 'br'|'global';

export interface TranslationProps {
  language: Language;
  locale: Locale;
}

export function containsZapSign(text: string): boolean {
  return text.includes('ZapSign');
}

export function getTranslationForTruoraSign(text: string): string {
  if (containsZapSign (text)) {
    return text.replace('ZapSign', 'TruoraSign');
  } else {
    return text;
  }
}


