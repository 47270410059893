import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TwoFaAuthenticateService {
  private readonly apiUrl: string = environment.API_URL;
  private readonly authApiUrl: string = `${this.apiUrl}/auth`;
  private readonly userApiUrl: string = `${this.apiUrl}/user`;

  constructor(private http: HttpClient) {
  }

  send2FAToken() {
    return this.http.post<void>(`${this.authApiUrl}/two-factor-authentication/`, {username: atob(sessionStorage.getItem('2fau'))});
  }

  disable2FAToken() {
    return this.http.post<void>(`${this.userApiUrl}/two-factor-authentication/disable/`, {});
  }

    validate2FAToken(bodyVerificationCode: { two_factor_authentication_token: string[]; username: string }) {
    return this.http.post<void>(`${this.authApiUrl}/two-factor-authentication/validate/`, bodyVerificationCode);
  }

}
