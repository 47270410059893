<div id="myModal" class="modal"
     (click)="closeModal()"
     [style.display]="modalOpen ? 'block':'none'">
  <!-- Modal content -->
  <div class="modal-content" *ngIf="modalOpen" (click)="$event.stopPropagation();">
    <section *ngIf="errors.length === 0; else noPermissionCam">
      <webcam
        [height]="405"
        [width]="536"
        [trigger]="observableCamera"
        (imageCapture)="handleImage($event)"
        [allowCameraSwitch]="allowCameraSwitch"
        [switchCamera]="nextWebcamObservable"
        [videoOptions]="videoOptions"
        (initError)="handleInitError($event)"
        (cameraSwitched)="cameraWasSwitched($event)"
      >
      </webcam>
      <div *ngIf="modalType === 'Selfie'" class="face"></div>
      <div *ngIf="modalType !== 'Selfie'" class="square"></div>
    </section>
    <ng-template #noPermissionCam>
      <app-no-permission-camera [userAgent]="userAgent"></app-no-permission-camera>
    </ng-template>
    <div class="zs-divider">
    </div>
    <section id="zs-selfie-buttons">
      <zs-button
        *ngIf="errors.length == 0"
        typeButton="secundary"
        label="Abrir no celular"
        (click)="openModalNotHaveCamera()"
      ></zs-button>
      <zs-button
        *ngIf="errors.length == 0"
        typeButton="primary"
        label="Tirar foto"
        (click)="takeSnapshot()"
      ></zs-button>
    </section>
  </div>
</div>
