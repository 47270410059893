import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-errors-form',
  templateUrl: './errors-form.component.html',
  styleUrls: ['./errors-form.component.scss']
})
export class ErrorsFormComponent {
  // tslint:disable-next-line:ban-types
  @Input() styles: Object;
}
