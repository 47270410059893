import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {concat} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthService} from 'src/app/auth/auth.service';
import {HandleErrorsRequestService} from 'src/app/shared/services/handle-errors-request-service/handle-errors-request.service';
import {environment} from 'src/environments/environment';
import {User} from '../../helpers/model/user';
import {MatDialog} from '@angular/material/dialog';
import {ModalAskBusinessSegmentComponent} from './modal-ask-business-segment.component';

export interface ModalAskBusinessFields {
  reason_create_account: 'Documentos para outras pessoas assinarem' | 'Só quero assinar um documento' | 'Só quero ver cópia dos documentos que já assinei na ZapSign';
  activity: 'Advocacia/Jurídico' | 'Autônomo' | 'Comércio' |  'Consultoria' | 'Contabilidade' | 'Educação privada' | 'Financeiro e Seguros' | 'Imobiliário' | 'Indústria' | 'Marketing e Publicidade' | 'RH e Recrutamento' | 'Saúde' | 'TecnologiA | Software' | 'Turismo e Lazer' | 'Outro ramo não listado';
  documents_per_month: 'Até 5 documentos/mês' | 'Entre 6 e 20 documentos/mês' | 'Entre 21 e 100 documentos/mês' | 'Entre 101 e 500 documentos/mês' | 'Acima de 500 documentos/mês';
  how_meet_us: 'Pesquisa no Google/Bing' | 'Redes sociais' | 'Indicações de conhecidos/amigos' | 'Assinando um documento' | 'Outro';

}

@Injectable({
  providedIn: 'root'
})
export class ModalAskBusinessService {
  private readonly usersApiUrl = `${environment.API_URL}/user`;


  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private handleErrorsRequests: HandleErrorsRequestService,
    private matDialog: MatDialog
  ) {
  }


  showModalWhenIsFirstUser(user: User) {
    if (user.show_business_segment_modal && !user.has_to_show_account_verification_modal) {
      this.openModal();
    }
  }

  openModal() {
    const dialogExist = this.matDialog.getDialogById('ModalAskBusinessSegmentComponent');

    if (dialogExist) {
      return;
    }

    this.matDialog.open(ModalAskBusinessSegmentComponent, {
      id: 'ModalAskBusinessSegmentComponent',
      disableClose: true,
      width: '700px'
    });
  }

  doingInformationsForCompany(informations: ModalAskBusinessFields) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<ModalAskBusinessFields>(`${this.usersApiUrl}/company/doing-questions/`, informations)
        .pipe(catchError(
          this.handleErrorsRequests.getErrorRequest
        ))
    );
  }


}
