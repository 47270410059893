import {AfterContentInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppState} from '../../../app.state';

import {PagamentosBackendService} from '../../pagamentos.backend.service';
import {
  catchDocumentsLimitInAccount,
  catchFreeDocsMonthInAccount,
  catchIfUserContainsOnePlan,
  catchNumberOfDocumentsInAccount
} from './store/counter-documents/counter-documents.actions';
import {useSelectorCounterDocumentsReducer} from '../../../app.state.selectors';
import {MixpanelService} from '../../../shared/services/mixpanel.service';
import {skipWhile} from 'rxjs/operators';

@Component({
  selector: 'app-counter-documents',
  templateUrl: './counter-documents.component.html',
  styleUrls: ['./counter-documents.component.scss']
})
export class CounterDocumentsComponent implements OnInit, AfterContentInit {
  userHasPlan = true;
  consumedDocs: number;
  docsLimit: number;

  subscriptions = [];

  loading = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store<AppState>,
    private pagamentosBackendService: PagamentosBackendService,
    private mixpanelService: MixpanelService,
  ) {
  }

  ngOnInit() {
    this.loading = true;
    this.store.select(useSelectorCounterDocumentsReducer)
      .pipe(skipWhile(result => Object.values(result).every(x => x === undefined)))
      .subscribe(
        ({
           isAllowEverthing,
           numberOfDocumentsInAccount,
           documentsLimitInAccount
         }) => {
          this.userHasPlan = isAllowEverthing;
          this.consumedDocs = numberOfDocumentsInAccount;
          this.docsLimit = documentsLimitInAccount;
          this.loading = false;
        });
  }

  ngAfterContentInit() {
    this.getMySubscriptions();
  }

  shouldShowDocumentsCounter(): boolean {
    if (this.loading) {
      return false;
    }
    if (this.userHasPlan) {
      return this.hitDocumentConsumptionThreshold();
    }
    return this.subscriptions?.length > 0 && this.getParsedActivePlanName() === this.getFreePlanName();
  }


  private hitDocumentConsumptionThreshold() {
    if (this.docsLimit === -1) {
      return false;
    }
    const threshold = 0.75;
    const maxDocsBeforeThreshold = this.docsLimit * threshold;
    return this.consumedDocs >= maxDocsBeforeThreshold;
  }

  notShowAccountUpgradeIfCurrentTabIsPlans(): boolean {
    return this.activatedRoute.snapshot.queryParams.tab === 'plans';
  }

  navigateViewPlans() {
    this.mixpanelService.sendEventMixpanel(
      'Sidebar Upgrade plan - Click',
      {
        'Actual plan': this.getActivePlanName()
      }
    );
    const extras = {queryParams: {tab: 'plans'}};
    this.router.navigate(['conta', 'configuracoes', 'plans'], extras);
  }

  private getMySubscriptions() {
    this.loading = true;
    this.pagamentosBackendService.getMySubscriptions().subscribe(
      data => {
        this.subscriptions = data;
        this.loading = false;
      },
      err => console.error(err)
    );
  }

  private getActivePlan() {
    const activePlans = this.subscriptions.filter(subscription => subscription.plan.tipo === 'main' && (subscription.status === 'paid' || subscription.status === 'pending_payment' || subscription.is_still_valid));
    return activePlans?.length > 0 ? activePlans[0]?.plan : null;
  }

  private getActivePlanName() {
    const activePlan = this.getActivePlan();
    return activePlan ? activePlan?.name : this.getFreePlanName();
  }


  getParsedActivePlanName() {
    const planName = this.getActivePlanName();
    return this.parsePlanNameBeforeParentheses(planName);
  }

  private getFreePlanName() {
    return 'Plano Essencial';
  }

  private parsePlanNameBeforeParentheses(planName: string) {
    const name = planName?.split('(')[0] ?? '';
    if (name?.length > 19) {
      return 'Plano Customizado';
    }
    return name;
  }


}
