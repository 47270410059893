import {createAction, on, props} from '@ngrx/store';
import {SignPageFooterProps, SignerButton} from './sign-page-footer.model';

export const setInitialSignerFooterButtons = createAction('[Set Signer footer buttons]', props<{ payload: null}>());
export const setSignerFooterButtons = createAction('[Set Signer footer buttons]', props<{ payload: SignPageFooterProps}>());
export const signerFooterPrimaryButton = createAction('[Set Signer footer primary button]', props<{ payload: SignerButton}>());
export const signerFooterBackButton = createAction('[Set Signer footer back button]', props<{ payload: SignerButton}>());
export const signerFooterRefuseButton = createAction('[Set Signer footer refuse button]', props<{ payload: SignerButton}>());
export const signerFooterButtonClick = createAction('[click Signer footer button]', props<{ payload: boolean}>());
export const signerFooterBackButtonClick = createAction('[click Signer footer button back]', props<{ payload: boolean}>());
export const signerFooterRefuseButtonClick = createAction('[click Signer footer button refuse]', props<{ payload: boolean}>());
export const signerFooterShowNoteMessage = createAction('[Signer footer show note message]', props<{ payload: boolean}>());
export const signerFooterShow = createAction('[Signer footer show]', props<{ payload: boolean}>());
export const signerFeatureFlag = createAction('[Signer feature flag]', props<{ payload: boolean}>());
export const signerRouteBeforeLoading = createAction('[Signer route before loading]', props<{ payload: string}>());
export const signerFeatureFlagzcre323 = createAction('[Signer feature flag br api]', props<{ payload: boolean}>());


