import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-modal-api-confirmation',
  templateUrl: './modal-api-confirmation.component.html',
  styleUrls: ['./modal-api-confirmation.component.scss']
})
export class ModalApiConfirmationComponent {

  @Input() openModal = false;
  @Input() isEdit = false;
  @Input() isDelete = false;
  @Output() modalClose: EventEmitter<boolean> = new EventEmitter();
  @Output() editApi: EventEmitter<any> = new EventEmitter();
  @Output() deleteApi: EventEmitter<any> = new EventEmitter();

  closeModal() {
    this.modalClose.emit(false);
  }

  confirmRenovateToken() {
    this.editApi.emit();
  }

  confirmDeleteToken() {
    this.deleteApi.emit();
  }
}
