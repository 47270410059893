import { Component, Input, Output, EventEmitter } from '@angular/core';
import { WarningService } from '../warning.service';
import { AuthBackendService } from 'src/app/auth/backend.service';
import { CopyService } from '../copy.service';
import { Language } from 'src/translation/translation';
import { TranslatorService } from '../translator.service';

@Component({
  selector: 'app-modal-log-webhooks',
  templateUrl: './modal-log-webhooks.component.html',
  styleUrls: ['./modal-log-webhooks.component.scss']
})


export class ModalLogWebhooksComponent {
  lang:Language = localStorage.getItem('lang') as Language || 'pt-br'

  @Input() openModal:boolean = false;
  @Output() modalClose: EventEmitter<any> = new EventEmitter();

  webhooks: any = []
  haveLoaded: boolean = false
  loading: boolean = false
  onlyFailsFilter: 0|1 = 0

  webhooks_count: number;
  page_size: number=20;
  current_page:number=1;
  number_of_pages:number = 1;
  pages_array:number[] = [1];
  showing_from:number = 1;
  showing_to:number = 20;

  
  constructor(
    private backendService: AuthBackendService,
    private _warningService: WarningService,
    private copyService: CopyService,
    private _translationService: TranslatorService
  ) { }

  t(pt_text: string) {
    return this._translationService.translate(pt_text, this.lang)
  }

  copyText(text:string){
    this.copyService.copyToClipboard(text,'Conteúdo copiado!')
  }

  getMyWebhooks(){
    this._warningService.toastrInfo(this.t('Carregando...'))
    this.haveLoaded = true
    this.loading = true
    this.backendService.getWebhooks(this.current_page,this.onlyFailsFilter).subscribe(
      data => {
        this.webhooks = data.results;
        this.webhooks_count = data.count;
        this.defineNumberOfPages()
        this.loading = false
        if(this.webhooks.length === 0) {
          this._warningService.toastrInfo(this.t('Não há registros a mostrar'))
        } else {
          this._warningService.toastrSuccess(this.t('Carregado com sucesso!'))
        }
      },
      error => {
        console.log(error);
        this.loading = false
        this._warningService.toastrError(this.t('Ocorreu um erro para coletar os logs. Por favor tente novamente mais tarde.'))
      }
    )
  }

  defineNumberOfPages(){
    this.number_of_pages = Math.ceil(this.webhooks_count/this.page_size)
    this.pages_array = Array(this.number_of_pages).fill(1).map((x,i)=>i+1);

    this.showing_from = ((this.current_page - 1)*this.page_size)+1
    this.showing_to = this.current_page*this.page_size
    if(this.showing_to > this.webhooks_count){
      this.showing_to = this.webhooks_count
    }
  }

  closeModal($event:PointerEvent | MouseEvent){
    if ($event.type =='click')
      this.modalClose.emit(false)
  }

}
