import { Doc, Signer } from '../../../../helpers/model/docs';
import { createAction, props } from '@ngrx/store'


export const lastSignerSelected = createAction(
  '[Last Signer Selected in Options]',
  props<{ payload: Signer }>()
)
export const canvasExtraDocsDOMRect = createAction(
  '[Rubrics Extra Docs Canvas]',
  props<{ payload: DOMRect }>()
)
export const hasToGenerateRubricaTextImage = createAction(
  '[Rubrics Has To Generate Image]',
  props<{ payload: boolean }>()
)
export const originalFileUpdateEvent = createAction(
  'Original File Update Event',
  props<{ originalFileUpdateDoc: Doc, originalFileIsUpdating:boolean }>()
)

export const hasToUpdateSignedFile = createAction(
  'Signed File Update Event',
  props<{ signedFileUpdateDoc: Doc, signedFileIsUpdating:boolean }>()
)
