<div class="navbar_upgrade_plan" *ngIf="shouldShowDocumentsCounter()">
  <span class="titulo">{{getParsedActivePlanName() | translate}}</span>
  <app-bullet-counter-documents
    [consumedDocs]="consumedDocs"
    [docsLimit]="docsLimit"
  ></app-bullet-counter-documents>
  <zs-button typeButton="primary" *ngIf="!notShowAccountUpgradeIfCurrentTabIsPlans()" (click)="navigateViewPlans()"
             [label]="'Aumente seu limite'">

  </zs-button>
</div>
