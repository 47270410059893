import {Injectable} from '@angular/core';
import {Membership} from "../../../client/old-membership-item/old-membership-item.component";
import {AuthBackendService} from "../../../auth/backend.service";
import {WarningService} from "../../../components/warning.service";
import {Router} from "@angular/router";
import {TranslatorService} from "../../../components/translator.service";
import {Language} from "../../../../translation/translation";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class UserGroupPermissionValidatorService {
  constructor(
    private authBackend: AuthBackendService,
    private warningService: WarningService,
    private router: Router,
    private _translationService: TranslatorService,
  ) {
  }

  private lang: Language = localStorage.getItem('lang') as Language || 'pt-br';

  private t(ptText: string) {
    return this._translationService.translate(ptText, this.lang);
  }

  thisPageIsPermitted(companyId: number, access: string, sectionName: string): Observable<Membership> {
    return new Observable((observer) => {
      this.authBackend.getUserGroupPermissionForThisPage(access).subscribe(
        data => {
          const {membership, allowed} = data;
          if (!allowed) {
            this.warningService.toastrError(this.t(`Você não tem acesso ${sectionName}. Em caso de dúvidas, entre em contato com o proprietário da organização.`));
            this.router.navigateByUrl('/conta/documentos');
          }
          observer.next(membership);
        },
        err => console.error(err)
      );
    });
  }

  hasPermissionForPage(access: string): Observable<boolean> {
    return this.authBackend.getUserGroupPermissionForThisPage(access).pipe(map(data => {
        const {membership, allowed} = data;
        return allowed;
      },
      err => console.error(err)
    ));

  }
}
