import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PreFilledInputs } from 'src/app/client/page-single-modelo/page-single-modelo.model';
import { Signer, Template } from 'src/app/helpers/model/docs';
import { SignerBackendService } from 'src/app/signers/signer-backend.service';
import { Language } from 'src/translation/translation';
import { DocBackendService } from '../doc.backend.service';
import { Replacement } from '../modal-new-doc-from-template/modal-new-doc-from-template.component';
import { TranslatorService } from '../translator.service';
import { WarningService } from '../warning.service';


class ModalEditAnswersData{
  signer: Signer
}

@Component({
  selector: 'app-new-modal-edit-answers',
  templateUrl: './new-modal-edit-answers.component.html',
  styleUrls: ['./new-modal-edit-answers.component.scss']
})
export class NewModalEditAnswersComponent implements OnInit {
  lang:Language = localStorage.getItem('lang') as Language || 'pt-br'
  prefills:any[] = [];
  template:Template = null;
  doc_token:string="";
  replacements:Replacement[] = [];

  loadingSaveAnswers:boolean=false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalEditAnswersData,
    private _translationService: TranslatorService,
    private dialogRef: MatDialogRef<NewModalEditAnswersComponent>,
    private signerBackend: SignerBackendService,
    private docBackend: DocBackendService,
    private warningService: WarningService
  ) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.getSignerAnswers()
  }

  t(pt_text: string) {
    return this._translationService.translate(pt_text, this.lang)
  }

  getSignerAnswers(){
    this.signerBackend.getTemplateTokenAndAnswersFromSignerToken(this.data.signer.token).subscribe(
      data => {
        const templateToken = data.template_token;
        this.prefills = data.answers;
        this.doc_token = data.doc_token
        this.getModelo(templateToken);
      }
    )
  }

  getModelo(token:string){
    this.signerBackend.getModel(token).subscribe(
      (data)=>{
        this.template = data.body;
        this.enforcePrefills();
      },
      (err) => {
      }
    )
  }

  enforcePrefills(){
    if(this.prefills.length == 0) return false
    this.template.participants.map(participant=>{
      participant.inputs.map(input=>{
        this.prefills.map(prefill=>{
          this.matchInputWithPrefill(input,prefill)
        })
      })
    })
  }

  matchInputWithPrefill(input,prefill){
    if(input.variable == '{{'+prefill[0]+'}}'){
      input.pre_filled_answer = prefill[1]
    }
  }

  updatePreFilledAnswers() {
    let replacements = [];
    this.template.participants.map(participant => {
      participant.inputs.map((input: PreFilledInputs) => {
        if (input.pre_filled_answer !== undefined && input.pre_filled_answer !== '') {
          replacements.push({'de': input.variable, 'para': input.pre_filled_answer});
        }
      });
    });
    this.replacements = replacements
  }

  saveNewAnswers(){
    this.loadingSaveAnswers=true;
    this.docBackend.updateDocAnswers(this.doc_token,this.replacements).subscribe(
      data=>{
        this.loadingSaveAnswers=false;
        this.dialogRef.close()
        this.warningService.toastrSuccess(this.t("Alterações salvas com sucesso."))
      },
      err=>{
        this.loadingSaveAnswers=false;
        this.warningService.toastrError(this.t("Ocorreu algum erro para salvar alterações. Por favor, atualize a tela."))
      }
    )
  }

}
