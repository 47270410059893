import { Component, OnInit, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'zs-button-photo',
  templateUrl: './button-photo.component.html',
  styleUrls: ['./button-photo.component.scss']
})
export class ButtonPhotoComponent {

  @Input() title = 'Adicionar selfie';
  @Input() subtitle = 'Fotografe o seu rosto em um lugar iluminado';
  @Input() icon = 'face_smiles';
  @Input() loading: boolean = false;
  @Input() error = false;
  @Input() check = false;

  get finalIcon() {
    if (this.loading) {
      return 'circular-loading';
    } else if (this.error) {
      return 'alert-triangle';
    } else if (this.check) {
      return 'check-filled-green';
    } else {
      return 'icon_arrow_right';
    }
  }

}
