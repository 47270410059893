<mat-form-field floatLabel="never">
  <input
    matInput
    [(ngModel)]="data"
    (ngModelChange)="validateDate($event)"
    [placeholder]="placeholderText"
    [min]="minDate"
    [readonly]="true"
    [matDatepicker]="picker"
    (click)="picker.open()"
  >
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker [calendarHeaderComponent]="customHeader"></mat-datepicker>
</mat-form-field>
