import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Language} from 'src/translation/translation';
import {CustomHeader} from '../datepicker-custom/datepicker-custom.component';
import {TranslatorService} from '../translator.service';


@Component({
  selector: 'app-doc-filter-datepicker',
  templateUrl: './doc-filter-datepicker.component.html',
  styleUrls: ['./doc-filter-datepicker.component.scss']
})
export class DocFilterDatePickerComponent {
  @Output() onChange = new EventEmitter<Date>();
  @Input() placeholder = '';
  @Input() data = null;
  private readonly lang = localStorage.getItem('lang') as Language || 'pt-br';
  customHeader = CustomHeader;

  constructor(
    private translatorService: TranslatorService
  ) {
  }

  translator(text: string) {
    return this.translatorService.translate(text, this.lang);
  }

  handleChange($event: string | Date) {
    this.onChange.emit(new Date($event));
  }
}
