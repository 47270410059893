import {Component, OnDestroy, OnInit} from '@angular/core';
import {updateNonViewedNewsletterNotifications} from '../user-navbar/newsletter-modal/store/newsletter-modal-action';
import {useNewsletterNotification} from '../../app.state.selectors';
import {Store} from '@ngrx/store';
import {AppState} from '../../app.state';
import {AuthService} from '../../auth/auth.service';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit, OnDestroy {

  nonViewedNews: string;
  subscriptions: any[];
  newsLetterModalOpen = false;
  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    const subscription = this.store.select(useNewsletterNotification).subscribe(
      ({non_viewed_newsletter_count}) => {
        this.showNewsletterNotificationCount(non_viewed_newsletter_count);
      }
    );
    this.subscriptions = [subscription];
  }

  ngOnDestroy() {
    this.subscriptions.map(subscription => subscription.unsubscribe());
  }

  openNewsLetterModal() {
    this.newsLetterModalOpen = true;
  }

  newsletterClosed($event) {
    if ($event === true) {
      this.newsLetterModalOpen = false;
    }
  }

  showNewsletterNotificationCount(nonViewedNewsletterCount: string) {
    const viewedNewsletterCount = +nonViewedNewsletterCount;
    if (viewedNewsletterCount === -1) {
      this.authService.getNewsletterNotification();
    }
    if (viewedNewsletterCount > 0) {
      this.nonViewedNews = nonViewedNewsletterCount;
    }
  }

  updateNonViewedNews($event: string) {
    if (+$event === 0) {
      this.allNewslettersAlreadyRead();
    }
  }

  private allNewslettersAlreadyRead() {
    this.nonViewedNews = '';
    this.store.dispatch(updateNonViewedNewsletterNotifications({payload: '0'}));
  }

}
