import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-modal-new-feature',
  templateUrl: './modal-new-feature.component.html',
  styleUrls: ['./modal-new-feature.component.scss']
})
export class ModalNewFeatureComponent  {
  @Input() gifUrl = '';
  @Input() explanationText = '';
  @Input() title = '';
  @Output() closeModalEmitter = new EventEmitter<boolean>();

  closeModal(newFeature: boolean) {
    this.closeModalEmitter.emit(newFeature);
  }

  preventModalFromClosing($event) {
    $event.stopPropagation();
    $event.preventDefault();
  }
}
