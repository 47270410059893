import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-beta-toogle',
  templateUrl: './beta-toogle.component.html',
  styleUrls: ['./beta-toogle.component.scss']
})
export class BetaToogleComponent {

  @Input() formUrl = '';
  @Input() textBetaOff = '';
  @Input() ldVariable = false;
  @Input() idVariableLocalStorage = false;
  @Output() changeLdLocalEmitter = new EventEmitter<boolean>();
  @Input() enabledToggleMessage = '';
  @Input() successBetaTag: string = null;

  protected readonly window = window;

  changeLdVariableLocal($event: boolean) {
    this.changeLdLocalEmitter.emit($event);
  }
}
