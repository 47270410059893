export const OldTimezones = [
    {
        country: 'AR',
        name: "Argentina",
        timezones: [
            { country: 'AR', timezone: "America/Buenos_Aires", district:"(UTC-03:00) Buenos Aires", value:"-03:00"},
        ]
    },
    {
        country: 'BO',
        name: "Bolivia",
        timezones: [
            { country: 'BO', timezone: "America/La_Paz", district:"(UTC-04:00) La Paz", value:"-04:00"},
        ]
    },
    {
        country: 'BR',
        name: "Brasil",
        timezones: [
            { country: 'BR', timezone: "America/Noronha", district:"(UTC-02:00) Fernando de Noronha", value:"-02:00"},
            { country: 'BR', timezone: "America/Sao_Paulo", district:"(UTC-03:00) Brasília", value:"-03:00"},
            { country: 'BR', timezone: "America/Manaus", district:"(UTC-04:00) Manaus", value:"-04:00"},
            { country: 'BR', timezone: "America/Rio_Branco", district:"(UTC-05:00) Rio Branco", value:"-05:00"},
        ]
    },
    {
        country: 'CL',
        name: "Chile",
        timezones: [
            { country: 'CL', timezone: "America/Santiago", district:"(UTC-03:00) Santiago", value:"-03:00"},
            { country: 'CL', timezone: "Chile/EasterIsland", district:"(UTC-05:00) Isla de Pascua", value:"-05:00"},
        ]
    },
    {
        country: 'CO',
        name: "Colombia",
        timezones: [
            { country: 'CO', timezone: "America/Bogota", district:"(UTC-05:00) Bogota", value:"-05:00"},
        ]
    },
    {
        country: 'EC',
        name: "Ecuador",
        timezones: [
            { country: 'EC', timezone: "America/Guayaquil", district:"(UTC-05:00) Quito", value:"-05:00"},
            { country: 'EC', timezone: "Pacific/Galapagos", district:"(UTC-06:00) Galápagos", value:"-06:00"},
        ]
    },
    {
        country: 'MX',
        name: "México",
        timezones: [
            { country: 'MX', timezone: "America/Mexico_City", district:"(UTC-06:00) Ciudad de México", value:"-06:00"},
            { country: 'MX', timezone: "America/Chihuahua", district:"(UTC-07:00) Chihuahua, La Paz", value:"-07:00"},
            { country: 'MX', timezone: "America/Tijuana", district:"(UTC-08:00) Tijuana", value:"-08:00"},
        ]
    },
    {
        country: 'PE',
        name: "Peru",
        timezones: [
            { country: 'PE', timezone: "America/Lima", district:"(UTC-05:00) Lima", value:"-05:00"},
        ]
    },
    {
        country: 'United States',
        name: "Estados Unidos",
        timezones: [
            { country: 'US', timezone: "US/Eastern", district:"(UTC-05:00) Eastern Time", value:"-05:00"},
            { country: 'US', timezone: "US/Central", district:"(UTC-06:00) Central Time", value:"-06:00"},
            { country: 'US', timezone: "US/Mountain", district:"(UTC-07:00) Mountain Time", value:"-07:00"},
            { country: 'US', timezone: "US/Pacific", district:"(UTC-08:00) Pacific Time", value:"-08:00"},
            { country: 'US', timezone: "US/Alaska", district:"(UTC-09:00) Alaska", value:"-09:00"},
            { country: 'US', timezone: "US/Hawaii", district:"(UTC-10:00) Hawaii", value:"-10:00"},
        ]
    },
    {
        country: 'Global',
        name: "Global",
        timezones: [
            { country: 'Global', timezone: "Etc/GMT+12", district:"(UTC-12:00) Global", value:"-12:00"},
            { country: 'Global', timezone: "Etc/GMT+11", district:"(UTC-11:00) Global", value:"-11:00"},
            { country: 'Global', timezone: "Etc/GMT+10", district:"(UTC-10:00) Global", value:"-10:00"},
            { country: 'Global', timezone: "Etc/GMT+9", district:"(UTC-09:00) Global", value:"-09:00"},
            { country: 'Global', timezone: "Etc/GMT+8", district:"(UTC-08:00) Global", value:"-08:00"},
            { country: 'Global', timezone: "Etc/GMT+7", district:"(UTC-07:00) Global", value:"-07:00"},
            { country: 'Global', timezone: "Etc/GMT+6", district:"(UTC-06:00) Global", value:"-06:00"},
            { country: 'Global', timezone: "Etc/GMT+5", district:"(UTC-05:00) Global", value:"-05:00"},
            { country: 'Global', timezone: "Etc/GMT+4", district:"(UTC-04:00) Global", value:"-04:00"},
            { country: 'Global', timezone: "Etc/GMT+3", district:"(UTC-03:00) Global", value:"-03:00"},
            { country: 'Global', timezone: "Etc/GMT+2", district:"(UTC-02:00) Global", value:"-02:00"},
            { country: 'Global', timezone: "Etc/GMT+1", district:"(UTC-01:00) Global", value:"-01:00"},
            { country: 'Global', timezone: "Etc/GMT+0", district:"(UTC+00:00) Global", value:"+00:00"},
            { country: 'Global', timezone: "Etc/GMT-1", district:"(UTC+01:00) Global", value:"+01:00"},
            { country: 'Global', timezone: "Etc/GMT-2", district:"(UTC+02:00) Global", value:"+02:00"},
            { country: 'Global', timezone: "Etc/GMT-3", district:"(UTC+03:00) Global", value:"+03:00"},
            { country: 'Global', timezone: "Etc/GMT-4", district:"(UTC+04:00) Global", value:"+04:00"},
            { country: 'Global', timezone: "Etc/GMT-5", district:"(UTC+05:00) Global", value:"+05:00"},
            { country: 'Global', timezone: "Etc/GMT-6", district:"(UTC+06:00) Global", value:"+06:00"},
            { country: 'Global', timezone: "Etc/GMT-7", district:"(UTC+07:00) Global", value:"+07:00"},
            { country: 'Global', timezone: "Etc/GMT-8", district:"(UTC+08:00) Global", value:"+08:00"},
            { country: 'Global', timezone: "Etc/GMT-9", district:"(UTC+09:00) Global", value:"+09:00"},
            { country: 'Global', timezone: "Etc/GMT-10", district:"(UTC+10:00) Global", value:"+10:00"},
            { country: 'Global', timezone: "Etc/GMT-11", district:"(UTC+11:00) Global", value:"+11:00"},
            { country: 'Global', timezone: "Etc/GMT-12", district:"(UTC+12:00) Global", value:"+12:00"},
            { country: 'Global', timezone: "Etc/GMT-13", district:"(UTC+13:00) Global", value:"+13:00"},
            { country: 'Global', timezone: "Etc/GMT-14", district:"(UTC+14:00) Global", value:"+14:00"},
        ]
    }
]
