import {Signer} from 'src/app/helpers/model/docs';
import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';

import {concat, Observable, throwError} from 'rxjs';
import {catchError, map, take, tap} from 'rxjs/operators';

import {environment} from 'src/environments/environment';
import {AuthService} from '../auth/auth.service';
import SignatureRequest, {DeleteDocsLot, CreateInputParams} from './doc.backend.service.model';
import {Folder, FolderLinear} from '../helpers/model/docs';
import {Check, DeletedCheck} from '../helpers/model/checks';
import {Replacement} from './modal-new-doc-from-template/modal-new-doc-from-template.component';
import {HandleErrorsRequestService} from '../shared/services/handle-errors-request-service/handle-errors-request.service';
import {SkipAuthHeader} from '../auth/auth-interceptor.service';

@Injectable({
  providedIn: 'root'
})
export class DocBackendService {

  api_url: string = environment.API_URL;
  doc_api_url: string = this.api_url + '/docs/';
  zapdocs_api_url: string = this.api_url + '/zapdocs/';
  private readonly skipAuthHeader = new HttpHeaders().set(SkipAuthHeader, '');

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private handleErrorsRequestService: HandleErrorsRequestService
  ) {
  }

  getSelectedDocumentsExcel(params) {
    // this.http.post<any>(this.doc_api_url + 'selected-docs/', params, {responseType: 'blob' as 'json'}).pipe(catchError(this.handleError))
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(this.doc_api_url + 'selected-docs/', params, {responseType: 'blob' as 'json'}).pipe(catchError(this.handleError))
    );
  }

  getDocuments(page: number = 1, params: any, responseType: string = 'json') {
    if (responseType == 'json') {
      return concat(
        this.authService.refreshAccessTokenIfNeeded(),
        this.http.post<any>(this.doc_api_url + '?page=' + page, params).pipe(catchError(this.handleError))
      );
    } else {
      return concat(
        this.authService.refreshAccessTokenIfNeeded(),
        this.http.post<any>(this.doc_api_url + '?page=' + page, params, {responseType: 'blob' as 'json'}).pipe(catchError(this.handleError))
      );
    }
  }

  getDocument(token: string) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.get<any>(this.doc_api_url + token + '/').pipe(catchError(this.handleError))
    );
  }

  getDeletedDocuments() {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.get<any>(`${this.doc_api_url}deleted-docs/`).pipe(catchError(this.handleErrorReturns))
    );
  }

  updateDocument(token: string, params: any) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(this.doc_api_url + token + '/update/', params).pipe(catchError(this.handleError))
    );
  }

  sendDocument(params: any) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(this.doc_api_url + 'send/', params)
    );
  }

  updateSignerAuthMode(signer_token: string, params: any) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(this.doc_api_url + 'signer/' + signer_token + '/update-auth-mode/', params).pipe(catchError(this.handleError))
    );
  }

  createSigner(doc_token: string, params: any) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(this.doc_api_url + doc_token + '/add-signer/', params).pipe(catchError(this.handleError))
    );
  }

  updateSigner(signer_token: string, params: any) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(this.doc_api_url + 'signer/' + signer_token + '/update/', params)
        .pipe(catchError(this.handleError))
    );
  }

  deleteDoc(doc_token: string) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.delete<any>(this.doc_api_url + doc_token + '/delete/').pipe(catchError(this.handleError))
    );
  }

  deleteMultiDocs(doc_token: string[]) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<DeleteDocsLot>(`${this.doc_api_url}delete-lot/`, {doc_token}).pipe(catchError(this.handleError))
    );
  }

  moveMultiDocs(doc_token_docs: string[], doc_token_folder: string) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(`${this.doc_api_url}move-lot/`, {doc_token_docs, doc_token_folder}).pipe(catchError(this.handleError))
    );
  }

  moveMultiTemplates(templates_token: string[], folder_token: string) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(`${this.zapdocs_api_url}templates/move-lot/`, {templates_token: templates_token, folder_token: folder_token}).pipe(catchError(this.handleError))
    );
  }

  saveRubricas(doc_token: string, params: any) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(this.doc_api_url + doc_token + '/save-rubricas/', params).pipe(catchError(this.handleError))
    );
  }

  getRubricas(doc_token: string) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.get<any>(this.doc_api_url + doc_token + '/rubricas/').pipe(catchError(this.handleError))
    );
  }

  getModeloRubricas(modelo_token: string) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.get<any>(this.zapdocs_api_url + 'templates/' + modelo_token + '/get-rubricas/').pipe(catchError(this.handleError))
    );
  }

  saveModeloRubricas(modelo_token: string, params: any) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(this.zapdocs_api_url + 'templates/' + modelo_token + '/save-rubricas/', params).pipe(catchError(this.handleError))
    );
  }

  saveModeloSigners(modelo_token: string, params: any) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(this.zapdocs_api_url + 'templates/' + modelo_token + '/save-signers/', params).pipe(catchError(this.handleError))
    );
  }

  getModelos(folderToken = '') {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.get<any>(this.zapdocs_api_url + 'templates/' + folderToken).pipe(catchError(this.handleError))
    );
  }

  getModelo(token: string) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.get<any>(this.zapdocs_api_url + 'templates/' + token + '/').pipe(catchError(this.handleError))
    );
  }

  getExcelFromModelo(token: string) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.get<any>(`${this.zapdocs_api_url}templates/${token}/get-excel/`,
        {responseType: 'blob' as 'json'})
        .pipe(catchError(this.handleErrorsRequestService.getErrorRequest))
    );
  }

  getExcelBatchTemplate(token: string) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.get<any>(this.zapdocs_api_url + 'templates/' + token + '/get-csv/batch-template/',
        {responseType: 'blob' as 'json'}).pipe(catchError(this.handleError))
    );
  }

  postCsvBatchTemplate(token: string, params) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(this.zapdocs_api_url + 'templates/' + token + '/create-batch/upload-csv/', params)).pipe(catchError(this.handleError));
  }

  getModeloTransactions(token: string) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.get<any>(this.zapdocs_api_url + 'templates/' + token + '/transactions/').pipe(catchError(this.handleError))
    );
  }

  newModelo(params: any) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(this.zapdocs_api_url + 'templates/new/', params).pipe(catchError(this.handleError))
    );
  }

  createPrefill(template_token: string, params: { content: string }) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(this.zapdocs_api_url + 'templates/' + template_token + '/create-prefill/', params).pipe(catchError(this.handleError))
    );
  }

  updateModelo(token: string, params: any) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(`${this.zapdocs_api_url}templates/${token}/update/`, params).pipe(catchError(this.handleError))
    );
  }

  deleteModelo(params: any) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(`${this.zapdocs_api_url}templates/delete/`, params).pipe(catchError(this.handleError))
    );
  }

  checkDocxVariables(docx_url: string) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(this.zapdocs_api_url + 'check-docx-variables/', {docx_url}).pipe(catchError(this.handleError))
    );
  }

  convertTemplateDocxToPdf(token: string) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(this.zapdocs_api_url + 'convert-template-docx-to-pdf/' + token + '/', {}).pipe(catchError(this.handleError))
    );
  }

  createInput(template_token: string, params: CreateInputParams) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(`${this.zapdocs_api_url}templates/${template_token}/create-input/`, params).pipe(catchError(this.handleError))
    );
  }

  deleteInput(input_id: number) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.delete<any>(this.zapdocs_api_url + 'inputs/' + input_id.toString() + '/delete/').pipe(catchError(this.handleError))
    );
  }

  updateInput(id: number, params: any) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(this.zapdocs_api_url + 'inputs/' + id.toString() + '/update/', params).pipe(catchError(this.handleError))
    );
  }

  updateParticipant(id: number, params: any) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(this.zapdocs_api_url + 'participants/' + id.toString() + '/update/', params).pipe(catchError(this.handleError))
    );
  }

  doAssinaturaLote(signer_tokens: string[], params) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(this.doc_api_url + 'assinatura-lote/sign/', {signer_tokens, ...params}).pipe(catchError(this.handleError))
    );
  }

  getAssinaturaLoteNonExpiredSigners(page, pageSize, params) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(`${this.doc_api_url}assinatura-lote/signers/non-expired/?page=${page}&page_size=${pageSize}`, params).pipe(catchError(this.handleError))
    );
  }

  getAssinaturaLoteExpiredSigners(page, pageSize, params) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(`${this.doc_api_url}assinatura-lote/signers/expired/?page=${page}&page_size=${pageSize}`, params).pipe(catchError(this.handleError))
    );
  }

  getMinhasAssinaturasSigners(page, pageSize=250, params) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(`${this.doc_api_url}my-signatures/signers/?page=${page}&page_size=${pageSize}`, params).pipe(catchError(this.handleError))
    );
  }

  disableAssinaturaLoteFromSigner(tokens: string[]) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(this.doc_api_url + 'assinatura-lote/disable/', {tokens}).pipe(
        catchError(this.handleError)
      )
    );
  }

  getFolders(folderType = 'docs', orderBy = "-id", parent_folder_id?, allFolders = 'False') {
    let queryParams = ""
    if(parent_folder_id){
      queryParams = 'type=' + folderType + '&parent_folder_id=' + parent_folder_id + '&order_by=' + orderBy + '&all_folders=' + allFolders;
    }
    else{
      queryParams = 'type=' + folderType + '&order_by=' + orderBy + '&all_folders=' + allFolders;
    }
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.get<FolderLinear[]>(this.doc_api_url + 'folders/?' + queryParams).pipe(catchError(this.handleError))
    );
  }

  getFolder(token: string): Observable<Folder> {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.get<any>(this.doc_api_url + 'folders/' + token + '/').pipe(catchError(this.handleError))
    );
  }

  newFolder(params: any) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(this.doc_api_url + 'folders/new/', params).pipe(catchError(this.handleError))
    );
  }

  updateFolder(token: string, params: any) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(this.doc_api_url + 'folders/' + token + '/update/', params).pipe(catchError(this.handleError))
    );
  }

  sendSignatureRequest(doc_token: string, params: SignatureRequest) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(`${this.doc_api_url + doc_token}/signature-request/`, params)
    );
  }

  removeSigner(signer_token: string) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.delete<any>(this.doc_api_url + 'signer/' + signer_token + '/remove/').pipe(catchError(this.handleError))
    );
  }

  reminderEmail(signer_token: string) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post(`${this.doc_api_url}signer/${signer_token}/notify/`, {})
        .pipe(
          take(1),
        )
    );
  }

  createBackgroundCheck(isFree: boolean, params: CreateBackgroundCheckParams) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<Check>(`${this.api_url}/antecedentes/new-check/?free=${isFree ? 1 : 0}`, params)
    );
  }

  getBackgroundChecks() {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.get<Check[]>(`${this.api_url}/antecedentes/checks/`).pipe(catchError(this.handleError))
    );
  }

  deleteCheck(provider_id: string) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.delete<any>(`${this.api_url}/antecedentes/checks/move-to-trash/${provider_id}/`).pipe(catchError(this.handleError))
    );
  }

  updateDeletedCheck(provider_id: string, action: string) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(`${this.api_url}/antecedentes/checks/deleted/${provider_id}/`, {'action': action}).pipe(catchError(this.handleError))
    );
  }

  getDeletedChecks() {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.get<DeletedCheck[]>(`${this.api_url}/antecedentes/checks/get-deleted/`).pipe(catchError(this.handleErrorReturns))
    );
  }

  getBackgroundCheck(provider_id: string) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.get<Check>(`${this.api_url}/antecedentes/checks/${provider_id}/`).pipe(catchError(this.handleError))
    );
  }

  isFreeBackgroundCheckAvailable() {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.get<Check>(`${this.api_url}/antecedentes/free-check/`).pipe(catchError(this.handleError))
    );
  }

  getNewOriginalFileDocument(token: string) {
    return this.http.get<any>(`${this.doc_api_url}${token}/original-file/`);
  }

  undoOriginalFileDocument(token: string) {
    return this.http.post<any>(`${this.doc_api_url}${token}/original-file/undo/`, null);
  }

  updateDocAnswers(doc_token: string, replacements: Replacement[]) {
    return this.http.post<any>(`${this.zapdocs_api_url}templates/doc/${doc_token}/update-doc-answers/`, {replacements});
  }

  // Não lançar nesta sprinta ainda
  // redoOriginalFileDocument(token:string){
  //   return this.http.post<any>(`${this.doc_api_url}${token}/original-file/`, {'redo': 'redo'}).pipe( catchError(this.handleError) )
  // }

  updateObservers(token: string, params: { emails: string[], emailsAllNotifications: string[]}) {
    return this.http.post<any>(`${this.doc_api_url}${token}/update-observers/`, params)
      .pipe(catchError(this.handleError));
  }

  getQuantityCreatedDocs() {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.get<any>(`${this.doc_api_url}allow-create-doc/`)
        .pipe(catchError(this.handleErrorReturns))
    );
  }

  getDocumentZip(docToken: string, type: string = null) {
    const queryType = type ? '?type=' + type : '';
    return concat(
      this.http.get<any>(`${this.doc_api_url}${docToken}/download-files/` + queryType, {responseType: 'blob' as 'json'}).pipe(catchError(this.handleError))
        .pipe(catchError(this.handleErrorReturns))
    );
  }

  addSignerAtPdfTemplate(token: string) {
    return this.http.post<any>(`${this.zapdocs_api_url}templates/pdf-add-signer/${token}/`, null);
  }

  getDocAutenticidade(token: string) {
    return this.http.get<any>(`${this.doc_api_url}doc-autenticidade/${token}/`, {headers: this.skipAuthHeader}).pipe( catchError(this.handleError) )
  }

  getUseUldRubricaFeature(token: string) {
    return concat(
      this.http.get<any>(`${this.doc_api_url}${token}/use-old-rubrica-feature/`)
    );
  }

  // USAR ESSE DAQUI POIS ESSE RETORNA O ERROR NO SEGUNDO PARAMETRO DO SUBSCRIBE
  private handleErrorReturns(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`
      );
      console.error(error.error);

    }
    return throwError(error.error);
  }

  private handleError(error: HttpErrorResponse) {
    console.log(error, 'Error');
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`
      );
      console.error(error.error);

    }
    return throwError(error.error);
  };


}

interface SignerEmail {
  token: string,
  email: string
}

interface CreateBackgroundCheckParams {
  country: 'BR';
  native_country: 'BR';
  type: 'person';
  national_id: string;
  date_of_birth: string;
  region: string;
  first_name: string;
  last_name: string;
  user_authorized: boolean;
  force_creation: boolean;
  lang: 'pt';
}
