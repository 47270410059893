import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {User} from '../../helpers/model/user';
import {HelpersGlobalService} from '../../helpers-global.service';
import {PagamentosBackendService} from '../pagamentos.backend.service';
import {PlanSubscriptions, SubscriptionProps} from '../../client/settings-payment/settings-payment.model';
import {TranslatorService} from '../translator.service';
import {Language} from '../../../translation/translation';
import {TwoFaAuthenticateService} from '../modal-two-fa-authenticate/services/two-fa-authenticate.service';
import {WarningService} from '../warning.service';
import {clearFolderWhereUserWasInDocumentListRedux} from '../../client/page-my-documents/store/folder-where-user-was-in-document-list/folder-where-user-was-in-document-list-redux.actions';

import {Store} from '@ngrx/store';
import {AppState} from '../../app.state';
import {Router} from '@angular/router';


@Component({
  selector: 'app-two-fa-auth',
  templateUrl: './two-fa-auth.component.html',
  styleUrls: ['./two-fa-auth.component.scss']
})
export class TwoFaAuthComponent implements OnInit {
  @Input() user: User;
  possibleErrorsTwoFaAuthentication = '';
  successTwoFaAuthentication = false;
  openModalTwoFaAuthentication = false;
  plan: PlanSubscriptions = null;
  private readonly lang = localStorage.getItem('lang') as Language || 'pt-br';
  @Output() changeLdLocalEmitter = new EventEmitter<boolean>();
  toggleControl2FA: boolean;
  planStatus: string;
  checked: any;


  constructor(
    private helpersGlobalService: HelpersGlobalService,
    private translatorService: TranslatorService,
    private paymentsBackend: PagamentosBackendService,
    private twoFaAuthenticateService: TwoFaAuthenticateService,
    private warningService: WarningService,
    private store: Store<AppState>,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.getMySubscriptions();
    this.toggleControl2FA = this.user.two_factor_authentication_enabled;
  }

  t(text: string, replacements?: string[]) {
    return this.translatorService.translate(text, this.lang, replacements);
  }

  activate2FAuthentication() {
    if (this.user.two_factor_authentication_enabled) {
      this.twoFaAuthenticateService.disable2FAToken().subscribe(() => {
        this.warningService.toastrSuccess(this.t('Autenticação de dois fatores desativada com sucesso.'));
        this.user.two_factor_authentication_enabled = false;
      });
      return;
    }
    const userHasPhone = this.user.phone_number.length > 0 && this.user.phone_country.length > 0;
    if (!this.user.two_factor_authentication_enabled && userHasPhone) {
      sessionStorage.setItem('2fau', btoa(this.user.email));
      this.openModalResendSms();
      this.toggleControl2FA = true;
    } else if (this.user.two_factor_authentication_enabled) {
      this.successTwoFaAuthentication = true;
      this.toggleControl2FA = true;
    } else if (!userHasPhone) {
      this.warningService.toastrError(this.t('A autenticação de dois fatores requer um telefone. Adicione o seu em "Meu Perfil"'));
      setTimeout(() => {this.possibleErrorsTwoFaAuthentication = ''; this.toggleControl2FA = false; }, 6000);
      setTimeout(() => this.toggleControl2FA = false, 1000);
    }
  }

  closeModalTwoFAuthenticate() {
    this.openModalTwoFaAuthentication = false;
    if (!this.user.two_factor_authentication_enabled) {
      this.toggleControl2FA = false;
    }
  }

  private getMySubscriptions() {
    this.paymentsBackend.getMySubscriptions().subscribe(
      (data: SubscriptionProps[]) => {
        if (!data) {
          return;
        }
        const {plan} = data[data.length - 1] as SubscriptionProps;
        this.plan = plan;
        this.setPlanStatus(data);
      },
      err => console.error(err)
    );
  }

  private setPlanStatus(data: SubscriptionProps[]) {
    // tslint:disable-next-line:prefer-for-of
    for (let index = 0; index < data.length; index += 1) {
      if (data[index].status === 'paid') {
        this.planStatus = data[index].status;
        break;
      } else {
        this.planStatus = data[0].status;
      }
    }
  }

  openModalResendSms() {
    if (this.planStatus !== 'paid' || !this.plan || this.plan.paid_docs_limit !== -1) {
      setTimeout(() => this.toggleControl2FA = false, 1000);
      this.warningService.toastrError(this.t('Faça upgrade para um plano completo, API ou Corporativo para usar a autenticação de dois fatores.'));
    } else {
      this.openModalTwoFaAuthentication = true;
    }
  }

  goToNewTemplate() {
    this.store.dispatch(clearFolderWhereUserWasInDocumentListRedux());
    this.router.navigate(['conta', 'configuracoes', 'plans']);
  }
}

