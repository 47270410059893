import {createAction, on, props} from '@ngrx/store';
import {SignPageHeaderProps, DownloadDocument, SignerHeader} from './sign-page-header.model';
export const setSignerHeaderProps = createAction('[Set Signer header props]', props<{ payload: SignPageHeaderProps}>());
export const setSignersArray = createAction('[Set signers array]', props<{ payload: SignerHeader[]}>());
export const showSignersButton = createAction('[Show signers button]', props<{ payload: boolean}>());
export const setDocumentsArray = createAction('[Set documents array]', props<{ payload: DownloadDocument[]}>());
export const showDocumentButton = createAction('[Show documents button]', props<{ payload: boolean}>());
export const setSignerHeaderLogo = createAction('[Set Signer header logo]', props<{ payload: string}>());
export const setSignerHeaderShow = createAction('[Set Signer header show]', props<{ payload: boolean}>());


