import { createAction, props } from '@ngrx/store';

const propertiesLoadingModalActivateAccount = props<{ payload: boolean }>();
const propertiesOpenActiveAccountModal = props<{ payload: boolean }>();

export const setLoadingModalActivateAccount = createAction(
  'loadingModalActivateAccount',
  propertiesLoadingModalActivateAccount
);

export const setOpenActiveAccountModal = createAction(
  'setOpenActiveAccountModal',
  propertiesOpenActiveAccountModal
);
