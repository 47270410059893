import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {concat} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {HandleErrorsRequestService} from './handle-errors-request-service/handle-errors-request.service';


@Injectable({
  providedIn: 'root'
})
export class MixpanelService {

  apiUrl: string = environment.API_URL;
  mixpanelApiUrl: string = this.apiUrl + '/mixpanel';

  constructor(
    private http: HttpClient,
    private handleErrorsRequestService: HandleErrorsRequestService
  ) {}
  sendEventMixpanel(eventName, properties ) {
    const resolution = window.innerWidth;
    properties.eventName = eventName;
    properties.resolution = resolution;
    return concat(
      this.http.post<any>(`${this.mixpanelApiUrl}/send-event/`, {...properties})
        .pipe(catchError(this.handleErrorsRequestService.getErrorRequest) )
    );
  }

  sendMixpanelSignerFlow(eventName, signer, screen = null) {
    const numDocs = signer.doc.extra_docs.length + 1;
    const numSigners = signer.doc.signers.length;
    const properties = {
      is_user: signer.user !== null,
      doc_id: signer.doc.id,
      num_docs: numDocs,
      num_singers: numSigners,
      // auth_methods: signer.authMode
    };
    if (screen) {
      properties['screen'] = screen;
    }
    this.sendEventMixpanel('Signature - Identity (2 of 3)', properties).subscribe();
  }

  sendTemplateFlow(eventName: string, template, replacementsLength: number = null, numberOfDocs: number = null, err: string = null) {
    const proprieties = {
      template: template.token,
      template_type: template.template_type,
      fields: template.participants[0]?.inputs?.map(input => {
        return input.variable;
      })
    };
    if (replacementsLength !== null) {
      const completedFieldsCount = template.participants[0].inputs.length - replacementsLength;
      proprieties['completed_fields'] = completedFieldsCount === 0 ? 'yes' : (replacementsLength === 0 ? 'no' : 'partial');
    }
    if (numberOfDocs !== null) {
      proprieties['number_documents'] = numberOfDocs;
    }
    if (err) {
      proprieties['error'] = err;
    }
    this.sendEventMixpanel(eventName, proprieties).subscribe();
  }

}
