import {ViewportScroller} from '@angular/common';
import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {clearFolderWhereUserWasInDocumentListRedux} from '@client/page-my-documents/store/folder-where-user-was-in-document-list/folder-where-user-was-in-document-list-redux.actions';
import {StepsCreateDocumentService} from '@client/page-new-document/services/steps-create-document.service';
import {Store} from '@ngrx/store';
import {UserGroupPermissionValidatorService} from '@shared/services/user-group-permission-validator/user-group-permission-validator.service';
import {Language} from '@translation/translation';
import {AppState} from '../../app.state';
import {TranslatorService} from '../translator.service';

@Component({
  selector: 'app-btn-new-document',
  templateUrl: './btn-new-document.component.html',
  styleUrls: ['./btn-new-document.component.scss']
})
export class BtnNewDocumentComponent implements OnInit {

  @Input() lang: Language = localStorage.getItem('lang') as Language || 'pt-br';

  disabled = false;
  loading = true;

  constructor(
    private store: Store<AppState>,
    public router: Router,
    private stepsCreateDocumentService: StepsCreateDocumentService,
    private scroller: ViewportScroller,
    private userGroupPermissionValidator: UserGroupPermissionValidatorService,
    private translationService: TranslatorService,
  ) {
  }


  ngOnInit() {
    this.userGroupPermissionValidator.hasPermissionForPage('send_documents').subscribe((permitted) => {
      this.disabled = !permitted;
      this.loading = false;
    });
  }

  t(ptText: string, replacements?: string[]) {
    return this.translationService.translate(ptText, this.lang, replacements);
  }


  async navigateCreateDoc() {
    if (this.loading || this.disabled) {
      return;
    }
    localStorage.removeItem('signersOldCreated');
    if (!this.router.url.includes('/documentos?pasta')) {
      this.store.dispatch(clearFolderWhereUserWasInDocumentListRedux());
    }
    await this.router.navigate(['conta', 'documentos', 'novo']);
    this.stepsCreateDocumentService.emitterTriggerStepsFromRedux('upload-file');
    this.scrollUp();
  }

  scrollUp() {
    this.scroller.scrollToAnchor('zs-up');
  }

}
