import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {AppState} from '../../app.state';
import {DocsCounterProps} from '../../client/page-my-documents/store/docs-counter/docs-counter.model';

@Component({
  selector: 'app-filter-status',
  templateUrl: './filter-status.component.html',
  styleUrls: ['./filter-status.component.scss']
})
export class FilterStatusComponent implements OnInit, OnDestroy {

  @Input() statusFilter: string;
  @Output() emmiterStatusFilter = new EventEmitter<string>();
  private counterReducer: Subscription;
  ongoingDocsCount = '';
  refusedDocsCount = '';
  signedDocsCount = '';
  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.counterReducer = this.store.select('docsCounter').subscribe(
      (value: DocsCounterProps) => {
        if (value.signedDocsCount != null && value.ongoingDocsCount != null && value.refusedDocsCount != null) {
          this.ongoingDocsCount = this.docsCounterSimplify(value.ongoingDocsCount);
          this.refusedDocsCount = this.docsCounterSimplify(value.refusedDocsCount);
          this.signedDocsCount = this.docsCounterSimplify(value.signedDocsCount);
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.counterReducer.unsubscribe();
  }

  docsCounterSimplify(value: number) {
    if (value > 999999999) {
      return (value / 1000000000).toFixed(2).toString() + 'B';
    } else if (value > 999999) {
      return (value / 1000000).toFixed(2).toString() + 'M';
    } else if (value > 999) {
      return (value / 1000).toFixed(3).toString();
    }
    return value.toString();
  }
  changedStatusFilter(status) {
    if (this.statusFilter !== status) {
      this.statusFilter = status;
    } else {
      this.statusFilter = 'all';
    }
    this.emmiterStatusFilter.emit(this.statusFilter);
  }


}
