import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Language} from '@translation/translation';
import {setOptionsConversionImage} from '../../signature-pad-old/cursive-signature/cursive-signature';
import {FontsService} from '../../signature-pad-old/cursive-signature/services/fonts.service';
import {TextToImageService} from '@client/text-to-image.service';
import {Router} from '@angular/router';
import {TranslatorService} from '../../translator.service';
import {PrimaryColorService} from '@shared/services/redux/primaryColor/primary-color.service';
import {SelectOptions} from '@shared/interfaces/selectInterface';
import {FormControl, Validators, FormGroup} from '@angular/forms';
import {Signer} from '@helpers/model/docs';

export interface PropsGenerateImage {
  currentFontCursive: string;
  currentTextCursive: string;
  imageFontCursive: string;
}

@Component({
  selector: 'app-cursive-signature',
  templateUrl: './cursive-signature.component.html',
  styleUrls: ['./cursive-signature.component.scss']
})
export class CursiveSignatureComponent implements OnInit {
  private readonly lang = localStorage.getItem('lang') as Language || 'pt-br';
  @Input() signerName: string;
  @Input() currentTabActive = 'draw';
  @Input() changesMade?: boolean;
  @Input() doingSignature?: boolean;
  @Input() isVisto ? = false;
  @Input() signer: Signer;
  @Output() emmiterGenerateImageBasedFont = new EventEmitter<PropsGenerateImage>();
  @Output() emitSaveSignatureCursive = new EventEmitter<void>();
  @ViewChild('textCursiveSignatureEl', {static: false}) textCursiveSignatureEl: ElementRef<HTMLDivElement>;
  primaryColor: string;
  loading = false;
  check = false;
  indexSelectedFont = 0;
  optionsFonts: SelectOptions[] = [];
  selectedFont: SelectOptions;
  loadingFont = true;

  constructor(
    private fontsService: FontsService,
    private cdr: ChangeDetectorRef,
    private textToImageService: TextToImageService,
    public router: Router,
    private translationService: TranslatorService,
    private primaryColorServiceFromRedux: PrimaryColorService,
  ) {
    this.loadStaticFonts();
  }

  formGroup = new FormGroup({
    requiredText: new FormControl('', Validators.required),
  });

  ngOnInit() {
    this.primaryColorServiceFromRedux.getPrimaryColorFromRedux().subscribe(({primaryColor}) =>
      this.primaryColor = primaryColor
    );
    this.formGroup.patchValue({
      requiredText : this.signerName
    });
    this.formGroup.controls['requiredText'].disable();
    this.optionsFonts = [
      {
        name: 'Dancing',
        value: 'Dancing Script, cursive',
      },
      {
        name: 'Chilanka',
        value: 'Chilanka, cursive',
      },
      {
        name: 'Grand Hotel',
        value: 'Grand Hotel, cursive',
      },
      {
        name: 'Inter',
        value: 'Inter, cursive',
      },
      {
        name: 'Satisfy',
        value: 'Satisfy, cursive',
      },
    ];
    this.selectedFont = this.optionsFonts[0];
  }

  async ngOnChanges(changes: SimpleChanges) {
    const currentTabActive = changes.currentTabActive?.currentValue as string;
    if (currentTabActive && currentTabActive === 'write') {
      await this.setCurrentFont(0);
    }
  }

  private loadStaticFonts() {
    this.fontsService.setPreConnectGStatic();
    this.fontsService.setGoogleFonts();
  }

  async setCurrentFont(chosenFontIndex: number) {
    this.indexSelectedFont = chosenFontIndex ? chosenFontIndex : 0;
    this.selectedFont = this.optionsFonts.find((_, index) => index === chosenFontIndex);

    await this.tryingEmmiterImage();
  }

  private async tryingEmmiterImage() {
    this.cdr.detectChanges();

    try {
      await this.emmiterFontSignatureChanged();
    } catch (e) {
      console.error(e);
      throw new Error('An error ocurred in signature cursive. Try reload page or choice other signature method');
    }
  }

  private async emmiterFontSignatureChanged() {
    this.emmiterGenerateImageBasedFont.emit({
      currentFontCursive: this.selectedFont.value,
      currentTextCursive: this.signerName,
      imageFontCursive: await this.getScreenshotCaptureSignatureCursive()
    });
  }

  async getScreenshotCaptureSignatureCursive() {
    const {nativeElement} = this.textCursiveSignatureEl;
    try {
      this.loadingFont = true;
      this.textToImageService.setOptionsToConvertTextToImage(
        setOptionsConversionImage(nativeElement)
      );
      return await this.textToImageService.convertTextToImage(nativeElement);

    } finally {
      this.loadingFont = false;
    }
  }

  t(ptText: string, replacements?: string[]) {
    return this.translationService.translate(ptText, this.lang, replacements);
  }

  onChangeName($event) {
    if (this.isVisto) {
      this.signerName = this.getInitials($event.target.value);
    } else {
      this.signerName = $event.target.value;
    }
  }

  getInitials(name) {
    const arrayName = name;
    return arrayName[0][0].toUpperCase() + arrayName[arrayName.length - 1][0].toUpperCase();
  }
}
