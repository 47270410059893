import {Injectable, OnDestroy} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.state';
import { useSaveEmailUnauthorizedReducer, useSavePasswordUnauthorizedReducer } from 'src/app/app.state.selectors';
import { HelpersGlobalService } from 'src/app/helpers-global.service';
import { setEmailInUnauthorizedScreens, cleanEmailAuthorizatedScreens } from '../../store/save-email-auth-unauthorized/save-email-unauthorized.actions';
import { setPasswordInUnauthorizedScreens, cleanPasswordAuthorizatedScreens } from '../../store/save-password-auth-unauthorized/save-password-unauthorized.actions';

@Injectable({
  providedIn: 'root'
})
export class UnauthorizedDataService implements OnDestroy {
  protected emailUnauthorized = '';
  protected passwordUnauthorized = '';
  private saveEmailUnautorizedSubscription:any;
  constructor(private store: Store<AppState>, private helpersGlobalService: HelpersGlobalService) { }

  getEmailUnauthorized(): string {
    this.saveEmailUnautorizedSubscription = this.store.select(useSaveEmailUnauthorizedReducer).subscribe((props) =>
      this.emailUnauthorized = props?.email
    );

    return this.emailUnauthorized;
  }

  getPasswordUnauthorized(): string {
    this.store.select(useSavePasswordUnauthorizedReducer).subscribe((props) =>
      this.passwordUnauthorized = props?.password
    );

    return this.passwordUnauthorized;
  }

  setEmailUnauthorizedScreensToRedux(email: string) {
    if (this.helpersGlobalService.isEmailValid(email)) {
      this.store.dispatch(
        setEmailInUnauthorizedScreens({
          email
        })
      );
    }
  }

  setPasswordUnauthorizedScreensToRedux(password: string) {
    this.store.dispatch(
      setPasswordInUnauthorizedScreens({
        password
      })
    );
  }

  removeEmailUnauthenticatedScreens() {
    this.store.dispatch(cleanEmailAuthorizatedScreens());
  }

  removePasswordUnauthenticatedScreens() {
    this.store.dispatch(cleanPasswordAuthorizatedScreens());
  }

  ngOnDestroy(): void {
    this.saveEmailUnautorizedSubscription.unsubscribe()
  }
}
