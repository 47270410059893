import {Actions, ActionTypes} from './signpage.actions';
import {SignPageProps} from './signpage.model';

export const INITIAL_STATE: SignPageProps = Object.freeze({
  isMobile: false,
  isTablet: false,
  isDesktop: false,
});

export const userAgentReducer = (state = INITIAL_STATE, action: Actions) => {
  switch (action.type) {
    case ActionTypes.GET_USER_ACTION: {
      return {
        ...state,
        isMobile: action.payload.isMobile,
        isTablet: action.payload.isTablet,
        isDesktop: action.payload.isDesktop,
      };
    }
    default: return state;
  }
};
