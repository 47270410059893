import {Action, createReducer, on} from '@ngrx/store';
import {clearFolderWhereUserWasInDocumentListRedux, setFolderWhereUserWasInDocumentListRedux} from './folder-where-user-was-in-document-list-redux.actions';
import {FolderWhereUserWasInDocumentListProps} from './folder-where-user-was-in-document-list-redux.model';

export const INITIAL_STATE: FolderWhereUserWasInDocumentListProps = {
  token: '',
  name: ''
};

const _folderWhereUserWasInDocumentListRedux = createReducer(
  INITIAL_STATE,
  on(setFolderWhereUserWasInDocumentListRedux, (state, params: FolderWhereUserWasInDocumentListProps) => {
    return {...state, ...params};
  }),
  on(clearFolderWhereUserWasInDocumentListRedux, () => INITIAL_STATE)
);

export function folderWhereUserWasInDocumentListReduxReducer(state: FolderWhereUserWasInDocumentListProps, action: Action) {
  return _folderWhereUserWasInDocumentListRedux(state, action);
}
