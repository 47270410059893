import {Injectable} from '@angular/core';
import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {ConsumerTranslatorService} from '../consumer-translator/consumer-translator.service';
import {HelpersGlobalService} from "../../../helpers-global.service";
import {SignerUtilsService} from "../../../signers/signer-utils.service";

@Injectable({
  providedIn: 'root'
})
export class FormGroupValidatorsService {

  constructor(
    private translator: ConsumerTranslatorService,
    private helpersGlobalService: HelpersGlobalService,
    private signerUtils: SignerUtilsService,
  ) {
  }

  ERROR_MESSAGES = {
    required: 'Esse campo é obrigatório',
    minlength: 'O valor inserido é muito curto',
    maxlength: 'O valor inserido é muito longo',
    email: 'Insira um e-mail válido',
    dateIsValid: this.translator.translate('Data têm que ser maior que hoje'),
    cvc: this.translator.translate('Código inválido'),
    cpf: 'Insira um CPF válido',
    cnpj: 'Insira um CNPJ válido',
    date: 'Data inválida',
    fullName: 'Insira o nome completo',
  };

  dateIsValidValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let notValid = false;
      if (control.value.length === 5) {
        const [month, year] = control.value.split('/');
        const date = new Date();
        if (parseInt(year, 10) < date.getFullYear() - 2000) {
          notValid = true;
        } else if (parseInt(year, 10) === date.getFullYear() - 2000) {
          if (parseInt(month, 10) < date.getMonth() + 1) {
            notValid = true;
          }
        }
      }
      return notValid ? {dateIsValid: {value: control.value}} : null;
    };
  }

  cvcValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let notValid = false;
      if (control.value.length < 3) {
        notValid = true;
      }
      return notValid ? {cvc: {value: control.value}} : null;
    };
  }

  emailValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const notValid = !this.helpersGlobalService.isEmailValid(control.value);
      return notValid ? {email: {value: control.value}} : null;
    };
  }

  emailValidatorNotRequired(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const notValid = control.value.length > 0 && !this.helpersGlobalService.isEmailValid(control.value);
      return notValid ? {email: {value: control.value}} : null;
    };
  }

  cpfValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const notValid = !this.signerUtils.validationCPF(control.value);
      return notValid ? {cpf: {value: control.value}} : null;
    };
  }

  cnpjValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const notValid = !this.signerUtils.validationCNPJ(control.value);
      return notValid ? {cnpj: {value: control.value}} : null;
    };
  }

  dateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const notValid = !this.helpersGlobalService.isDateValid(control.value);
      return notValid ? {date: {value: control.value}} : null;
    };
  }

  fullNameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const notValid = !this.signerUtils.validationFullName(control.value);
      return notValid ? { fullName: { value: control.value } } : null;
    };
  }

}
