import {Injectable} from '@angular/core';
import {usePrimaryColorReducer} from '../../../../app.state.selectors';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../app.state';
import {primaryColorActions} from '../../../../signers/sign-page/store/primary-color/primary-color.actions';

@Injectable({
  providedIn: 'root'
})
export class PrimaryColorService {

  constructor(private store: Store<AppState>) {
  }

  setterPrimaryColorFromRedux(primaryColor: string) {
    this.store.dispatch(
      primaryColorActions({
        payload: primaryColor
      })
    );

    return this.getPrimaryColorFromRedux();
  }

  getPrimaryColorFromRedux() {
    return this.store.select(usePrimaryColorReducer);
  }
}
