import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatFormFieldAppearance} from "@angular/material/form-field";
import {NgxMatIntlTelInputComponent} from "ngx-mat-intl-tel-input";
import {AsYouType, isValidPhoneNumber} from 'libphonenumber-js';
import {
  ControlValueAccessor,
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
  Validators
} from '@angular/forms';

export interface PhoneInternationalProps {
  phone_number: string
  phone_country: string
}

@Component({
  selector: 'app-phone-country-input',
  templateUrl: './phone-country-input.component.html',
  styleUrls: ['./phone-country-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PhoneCountryInputComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: PhoneCountryInputComponent
    }
  ]
})
export class PhoneCountryInputComponent implements OnInit, Validator {
  @Input() number: string
  @Input() country: string
  @Input() disabled: boolean = false
  @Input() width: string = 'large';
  @Input() widthContainerPhone: 'auto' | '100%' | string = '282px'
  @Input() appearance: MatFormFieldAppearance = 'fill';
  @Output() phoneChange = new EventEmitter<PhoneInternationalProps>();
  @Output() numberChange = new EventEmitter<string>();
  @Output() countryChange = new EventEmitter<string>();
  @ViewChild(NgxMatIntlTelInputComponent) phoneInternationalInput: NgxMatIntlTelInputComponent
  preferredCountries: string[] = ['br', 'co', 'ar', 'bo', 'cl', 'ec', 'mx', 'pe', 'us']
  form = this.formBuilder.group({phone: [this.getCurrentPhoneValues()]});
  // form = this.formBuilder.group({phone: [this.getCurrentPhoneValues(), [Validators.required]]});
  touched: Boolean = false;

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.parseSelectedCountry();
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  // registerOnTouched(onTouched: any): void {
  //   this.onTouched = onTouched;
  // }

  onChange = (phoneProps) => phoneProps;
  onTouched = () => {};
  isValid: boolean;

  writeValue(phoneProps: PhoneInternationalProps): void {
    this.country = phoneProps.phone_country
    this.number = phoneProps.phone_number
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(control: import("@angular/forms").AbstractControl = null): import("@angular/forms").ValidationErrors {
    const formatter = new AsYouType()
    formatter.input("+"+control.value.phone_country)
    this.isValid = isValidPhoneNumber("+"+control.value.phone_country+""+control.value.phone_number,formatter.getCountry());
    if (!this.isValid) {
      const {phone_country, phone_number} = control.value
      return {
        mustBeValid: {
          phone_country,
          phone_number
        }
      }
    }
  }

  handleChange() {
    const phoneProperties = this.getCurrentPhoneValues();
    if (phoneProperties && typeof phoneProperties.phone_country !== 'undefined' && typeof phoneProperties.phone_number !== 'undefined') {
      this.markAsTouched();
      this.phoneChange.emit(phoneProperties)
      this.countryChange.emit(phoneProperties.phone_country)
      this.numberChange.emit(phoneProperties.phone_number)
      this.onChange(phoneProperties)
    }
  }

  private getCurrentPhoneValues() {
    return Object.freeze({
        phone_number: this.phoneInternationalInput?.phoneNumber,
        phone_country: this.getDialCodeInputPhone()
      }
    );
  }

  private parseSelectedCountry() {
    let country = "us";
    if (this.country != "1") {
      const asYouType = new AsYouType();
      asYouType.input(`+${this.country}`);
      country = asYouType.country.toLowerCase();
    }
    var index = this.preferredCountries.indexOf(country);
    if (index !== -1) {
      this.preferredCountries.splice(index, 1);
    }
    this.preferredCountries.unshift(country)
  }

  private getDialCodeInputPhone(): string {
    if (this.phoneInternationalInput) {
      const {selectedCountry: {dialCode}} = this.phoneInternationalInput
      return dialCode
    }
  }
}
