export type UserGroupState = 'no_gruops' | 'list' | 'new' | 'update';
export type DocPermission = 'your_documents' | 'group_documents' | 'all_docmuments';
class GeneralAccess {
  models_and_forms: boolean;
  contacts: boolean;
  settings: boolean;
  reports: boolean;
  background: boolean;
  send_documents: boolean;
  batch_signature: boolean;
  trash: boolean;

  constructor(modelsAndForms, contacts, settings, reports, background, sendDocuments, batchSignature, trash) {
    this.models_and_forms = modelsAndForms;
    this.contacts = contacts;
    this.settings = settings;
    this.reports = reports;
    this.background = background;
    this.send_documents = sendDocuments;
    this.batch_signature = batchSignature;
    this.trash = trash;
  }
}

export class UserGroup {
  id: number;
  name: string;
  doc_permissions: DocPermission | string;
  doc_exclude_permissions: DocPermission | string;
  generals_access: GeneralAccess;
  users_email: string[];
  members_in_group: number;

  constructor(name = '', docPermission = 'group_documents', doc_exclude_permissions = 'group_documents',  modelsAndForms = true, contacts = false, settings = true, reports = true, background = true, sendDocuments = true, batchSignature = false, trash = true) {
    this.name = name;
    this.doc_permissions = docPermission;
    this.doc_exclude_permissions = doc_exclude_permissions;
    this.generals_access = new GeneralAccess(modelsAndForms, contacts, settings, reports, background, sendDocuments, batchSignature, trash);
  }
}
