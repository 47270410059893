import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/app.state";
import { selectCurrentUser } from "src/app/app.state.selectors";
import { setUserReducer } from "src/app/client/page-plans-and-price-component/store/users/users.action";
import { User } from "src/app/helpers/model/user";

@Injectable({
  providedIn: "root"
})

export class UsersReduxService {
  constructor(private store: Store<AppState>) {}

  setUser(user: User) {
    this.store.dispatch(setUserReducer({ payload: user }));
  }

  getUser() {
    return this.store.select(selectCurrentUser);
  }
}
