<a class="zs_content_notifications" mat-icon-button (click)="openNewsLetterModal()">
  <mat-icon matTooltip="{{'Visualizar últimas novidades' | translate}}"
            [matBadge]="nonViewedNews"
            matBadgeSize="small"
            matBadgePosition="after"
            class="icons-notifications"
            svgIcon="icon_notification"
  >
  </mat-icon>
</a>

<app-newsletter-modal [newsLetterModalOpen]="newsLetterModalOpen"
                      (nonViewedNewsCountEmitter)="updateNonViewedNews($event)"
                      (newsLetterModalClosed)="newsletterClosed($event)"></app-newsletter-modal>
