import { Component } from '@angular/core';

@Component({
  selector: 'app-integration-apps',
  templateUrl: './integration-apps.component.html',
  styleUrls: ['./integration-apps.component.scss']
})
export class IntegrationAppsComponent {

  step = 0;

  setStep(index: number) {
    this.step = index;
  }
}
