import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {

  @Input() title = 'Title';
  @Input() open = false;
  @Input() marginTop = '5%';
  @Input() marginBottom = '5%';
  @Input() maxWidth = '620px';
  @Input() showCloseButton = true;
  @Output() onCloseEmitter = new EventEmitter<any>();

  get contentStyles() {
    return {
      'max-width': this.maxWidth,
      'margin-top': this.marginTop,
      'margin-bottom': this.marginBottom
    };
  }

  onClose() {
    this.onCloseEmitter.emit();
  }

  preventModalFromClosing($event) {
    $event.stopPropagation();
  }

}
