import {Component, OnDestroy, OnInit} from '@angular/core';
import {Language} from '@translation/translation';
import {SignerFile} from '@signers/sign-page/sign-page.model';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from '../../../app.state';
import {UtilsService} from '@shared/services/utils/utils.service';
import {WarningService} from '@components/warning.service';
import {TranslatorService} from '@components/translator.service';
import {useSelectorLivenessValidation, useUserAgent} from '../../../app.state.selectors';
import {LivenessResponse} from '@components/liveness-validator/liveness-response';
import {livenessPhoto} from '@components/modal-liveness/store/LivenessValidation/liveness-validation.actions';

@Component({
  selector: 'app-old-modal-liveness',
  templateUrl: './old-modal-liveness.component.html',
  styleUrls: ['./old-modal-liveness.component.scss']
})
export class OldModalLivenessComponent implements OnDestroy {
  lang = localStorage.getItem('lang') as Language || 'pt-br';

  modalOpen: boolean;

  errors: any[] = [];

  userAgent = {
    isMobile: null,
    isTablet: null,
    isDesktop: null
  };

  photo: SignerFile = {
    base64: '',
    url: '',
    file_name: '',
    file_size: 0,
    loading: false
  };

  subscriptions: Subscription[] = [];

  constructor(
    private store: Store<AppState>,
    private utilsService: UtilsService,
    private warningService: WarningService,
    private translatorService: TranslatorService,
  ) {
    const livenessValidationSubscription = this.store.select(useSelectorLivenessValidation).subscribe(({livenessModal, photo}) => {
      this.modalOpen = livenessModal;
      this.photo = {...photo};
    });
    const userAgentSubscription = this.store.select(useUserAgent).subscribe(({isMobile, isTablet, isDesktop}) => {
      this.userAgent.isDesktop = isDesktop;
      this.userAgent.isMobile = isMobile;
      this.userAgent.isTablet = isTablet;
    });
    this.subscriptions.push(livenessValidationSubscription);
    this.subscriptions.push(userAgentSubscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  t(text: string): string {
    return this.translatorService.translate(text, this.lang);
  }

  closeModal() {
    this.utilsService.closeModalLiveness('modal-liveness');
  }

  handleValidation(livenessResponse: LivenessResponse): void {
    if (!livenessResponse.data.isAlive) {
      return this.handleNotAlive();
    }
    this.closeModal();
    this.populatePhotoFromLivenessResponse(livenessResponse);
    this.store.dispatch(livenessPhoto({payload: this.photo}));
  }

  private populatePhotoFromLivenessResponse(livenessResponse: LivenessResponse) {
    this.photo.base64 = livenessResponse.base64;
    this.photo.file_name = `${livenessResponse.id}.png`;
    this.photo.file_size = -1;
  }

  handleNotAlive(): void {
    this.warningService.toastrError(this.t('Não conseguimos validar o Liveness, tente novamente.'));
  }

  handleError(error: any): void {
    this.errors.push(error);
    this.warningService.toastrError(this.t('Ocorreu algum problema. Tente novamente mais tarde.'));
  }

  openModalNotHaveCamera() {
    this.utilsService.openModalNoCameraAvailable('modal-liveness');
    this.closeModal();
  }
}
