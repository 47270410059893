<div *ngIf="showModal" id="menu-mobile-options" class="zs-all-content-bread-crumb-mobile">
  <div [ngClass]="backgroundBlackDefault ? '' : 'default'" class="open-mobile-actions-background">
    <div class="open-mobile-actions-content">
      <div>
        <ul class="zs-modal-list-select">
          <li class="zs-folder-name-mobile zs-modal-list-element"
              (click)="onCloseModal()">
            <strong class="title">{{title | translate}}</strong>
          <li
            [ngClass]="item.iconOption === 'icon_whatsappNew' ? 'icon-whatsapp-new' : ''"
            class="zs-modal-list-element icon-only-stroke"
            *ngFor="let item of itens"
            (click)="item.option(); onCloseModal()">
            <mat-icon class="zs-folder-icon-bread-crumb" svgIcon="{{item.iconOption}}"></mat-icon>
            {{item.textOption | translate}}
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
