import {Language} from 'src/translation/translation';
import {TranslatorService} from '../../../components/translator.service';
import {concat} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {AuthService} from 'src/app/auth/auth.service';
import {environment} from 'src/environments/environment';
import {Injectable} from '@angular/core';
import {FilterContacts, ItemContacts, ContactsInformation} from './integration.contact';


@Injectable({
  providedIn: 'root'
})
export class IntegrationContactService {
  private readonly usersApiUrl: string = `${environment.API_URL}/user`;
  private readonly lang: Language = localStorage.getItem('lang') as Language || 'pt-br';

  constructor(
    private readonly _authService: AuthService,
    private readonly _http: HttpClient,
    private readonly _translationService: TranslatorService
  ) {
  }

  getContacts(filters: FilterContacts, page: number = 1) {
    return concat(
      this._authService.refreshAccessTokenIfNeeded(),
      this._http.post<ItemContacts>(`${this.usersApiUrl}/company/contacts/?page=${page}`, filters)
    );
  }

  getContact(token: string) {
    return concat(
      this._authService.refreshAccessTokenIfNeeded(),
      this._http.get<ContactsInformation>(`${this.usersApiUrl}/company/contacts/get-contact/${token}`)
    );
  }

  createContact(params: ContactsInformation) {
    return concat(
      this._authService.refreshAccessTokenIfNeeded(),
      this._http.post<ContactsInformation>(`${this.usersApiUrl}/company/contacts/create-contact/`, params)
    );
  }

  updateContact(token: string, params: ContactsInformation) {
    return concat(
      this._authService.refreshAccessTokenIfNeeded(),
      this._http.post<ContactsInformation>(`${this.usersApiUrl}/company/contacts/update/${token}/`, params)
    );
  }

  deleteContact(id: string) {
    return concat(
      this._authService.refreshAccessTokenIfNeeded(),
      this._http.delete<void>(`${this.usersApiUrl}/company/contacts/delete/${id}/`)
    );
  }

  t(ptText: string, replacements?: string[]) {
    return this._translationService.translate(ptText, this.lang, replacements);
  }
}
