<div style="display:flex;justify-content:space-between;align-items:baseline;flex-wrap:wrap;">
    <h2>
      {{'Aplicativos prontos para usar' | translate}}
    </h2>
</div>
<p
   style="margin:0">{{'Integrações nativas com as seguintes plataformas. Basta instalar a ZapSign e começar!' | translate}}
  <br><br>
</p>
<div class="ready-to-use-apps-list">
  <div class="ready-to-use-apps-list-row">
    <div class="ready-to-use-apps-list-img">
      <img style="width: 61px" src="/assets/img/integrations/hubspot-text.png"/>
    </div>
    <div class="ready-to-use-apps-list-text">
      <p>{{'No CRM Hubspot de cada empresa, crie documentos e compartilhe-os para coletar assinaturas.' | translate}}</p>
    </div>
    <div class="ready-to-use-apps-list-button">
      <a href="https://app.hubspot.com/ecosystem/21471312/marketplace/apps/productivity/workflow-automation/zapsign-969268" target="_blank">{{'Instalar aplicativo' | translate}}</a>
    </div>
  </div>
  <div class="ready-to-use-apps-list-row">
    <div class="ready-to-use-apps-list-img">
      <img style="width: 69px" src="/assets/img/integrations/truora.png"/>
    </div>
    <div class="ready-to-use-apps-list-text">
      <p>{{'Nos fluxos automatizados de WhatsApp você pode incluir a ZapSign para assinar documentos ou templates.' | translate}}</p>
    </div>
    <div class="ready-to-use-apps-list-button">
      <a href="https://www.truora.com/truconnect?hsLang=en" target="_blank">{{'Instalar aplicativo' | translate}}</a>
    </div>
  </div>
  <div class="ready-to-use-apps-list-row">
    <div class="ready-to-use-apps-list-img">
      <img style="width: 48px" src="/assets/img/integrations/ixc.png"/>
    </div>
    <div class="ready-to-use-apps-list-text">
      <p>{{'Na plataforma IXC para provedores de telecomunicações, crie documentos e compartilhe-os para coletar assinaturas.' | translate}}</p>
    </div>
    <div class="ready-to-use-apps-list-button">
      <a href="{{'https://clients.zapsign.com.br/help/como-integrar-a-zapsign-ao-ixc' | translate}}" target="_blank">{{'Instalar aplicativo' | translate}}</a>
    </div>
  </div>
</div>

