import {Action} from '@ngrx/store';

export enum ActionTypesModal {
  OPENUSER = 'OPENUSER',
  OPENCOMPANY = 'OPENCOMPANY',
  CLOSE = 'CLOSE',
}

export class OpenUserRequest implements Action {
  readonly type = ActionTypesModal.OPENUSER;
}
export class OpenCompanyRequest implements Action {
  readonly type = ActionTypesModal.OPENCOMPANY;
}
export class CloseRequest implements Action {
  readonly type = ActionTypesModal.CLOSE;
}
export type ActionsRequestModal = OpenUserRequest | OpenCompanyRequest | CloseRequest;
