import {animate, style, transition, trigger} from '@angular/animations';
import {Component, OnInit} from '@angular/core';
import {SubscriptionProps} from 'src/app/client/settings-payment/settings-payment.model';
import {GetPlanNameService} from '../../shared/services/get-plan-name/get-plan-name.service';
import {PagamentosBackendService} from '../pagamentos.backend.service';

@Component({
  selector: 'app-old-modal-unpaid',
  templateUrl: './old-modal-unpaid.component.html',
  styleUrls: ['./old-modal-unpaid.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({opacity: 0}),
        animate('.3s ease-out', style({opacity: 1}))
      ])
    ])
  ]
})
export class OldModalUnpaidComponent implements OnInit {

  modalOpened = true;
  userMasterPlanName = '';

  constructor(
    private paymentsBackend: PagamentosBackendService,
    private getPlanNameService: GetPlanNameService
  ) {
  }

  ngOnInit(): void {
    this.getMySubscriptions();
    this.modalOpened = true;
  }

  closeModalUnpaid() {
    this.modalOpened = false;
  }

  private getMySubscriptions() {
    this.paymentsBackend.getMySubscriptions().subscribe(
      (data: SubscriptionProps[]) => this.getPlanName(data),
      err => console.error(err)
    );
  }


  private getPlanName(allPlans: SubscriptionProps[]){
    this.userMasterPlanName = this.getPlanNameService.takePlan(allPlans)
  }

}



