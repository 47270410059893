<div class="doc-status-chip">
  <ng-container [ngSwitch]="doc.status">
    <div *ngSwitchCase="'em-curso'" class="chip-em-curso status-chip">
      <span>{{"Em curso" | translate}}</span>
    </div>
    <div *ngSwitchCase="'assinado'" class="chip-assinado status-chip">
      <span>{{'Assinado' | translate}}</span>
    </div>
    <div *ngSwitchCase="'recusado'" class="chip-recusado status-chip">
      <span>{{"Recusado" | translate}}</span>
    </div>
    <div *ngSwitchCase="'integrado'" class="chip-integrado status-chip" [matTooltip]="'Esse documento foi integrado via ZapSwitch' | translate">
      <span>{{"Integrado" | translate}}</span>
    </div>
  </ng-container>
</div>
