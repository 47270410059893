<div id="myModal" class="modal"
     (click)="closeModal()"
     [style.display]="modalOpen ? 'block':'none'">

  <!-- Modal content -->
  <div class="modal-content" (click)="$event.stopPropagation();">
    <p *ngIf="language === 'en-US'; else ptNavigator">
      Para registrar uma foto, clique em 'Allow' logo acima para dar ao navegador acesso a sua câmera.
    </p>
    <ng-template #ptNavigator>
      <p>Para registrar uma foto, clique em 'Permitir' logo acima para dar ao navegador acesso a sua câmera.</p>
    </ng-template>
    <figure>
      <img src="../../../../assets/img/permissions.png" alt="">
    </figure>
  </div>
</div>
