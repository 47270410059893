import {Injectable} from '@angular/core';
import {Language} from '../../translation/translation';
import {TranslatorService} from '../components/translator.service';

@Injectable({
  providedIn: 'root'
})
export class AffiliateService {
  lang = localStorage.getItem('lang') as Language || 'pt-br';

  constructor(
    private translatorService: TranslatorService
  ) { }

  private t(ptText: string) {
    return this.translatorService.translate(ptText, this.lang);
  }
  getAffiliateLink(queryParams: string) {
    const link = this.t('https://indique.buzz/zapsign');
    return this.lang === 'pt-br' && queryParams ? link.concat(queryParams) : link;
  }

}
