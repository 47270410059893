import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Language} from '../../../translation/translation';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslatorService} from '../translator.service';
import {SlugTextType} from '../../helpers-global.service';
import {TakeDefaultAuthenticationsService} from '../../client/who-sign-list/services/take-default-authentications.service';
import {TakeAdvancedAuthenticationService} from '../../client/who-sign-list/services/take-advanced-authentication.service';
import {MatSelectChange} from '@angular/material/select';
import {Contact, ContactGroup} from '../../helpers/model/user';
import {ContactGroupService} from './contact-group.service';
import {ContactsInformation, FilterContacts, ItemContacts} from '../../client/page-contacts/integration-contacts/integration.contact';
import {IntegrationContactService} from '../../client/page-contacts/integration-contacts/integration.contact.service';
import {AppState} from '../../app.state';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';

interface ModalEditContactGroupProps {
  allContacts: ContactsInformation[];
  contactGroup: ContactGroup;
  title: string;
}

@Component({
  selector: 'app-edit-contact-group-modal',
  templateUrl: './edit-contact-group-modal.component.html',
  styleUrls: ['./edit-contact-group-modal.component.scss']
})
export class EditContactGroupModalComponent implements OnInit, OnDestroy {
  filterText = '';
  loadingContacts = false;
  private readonly lang: Language = localStorage.getItem('lang') as Language || 'pt-br';
  typesAuthAdvancedAuthentication: SlugTextType[];
  typesAuthDefaultAuthentication: SlugTextType[] = this.takeDefaultAuthenticationsService.getDefaultAuthenticationTypes();
  @ViewChild('contactInput') contactInput: ElementRef<HTMLInputElement>;
  private advancedAuthTypesSub: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalEditContactGroupProps,
    public matDialog: MatDialogRef<EditContactGroupModalComponent>,
    private translationService: TranslatorService,
    private takeDefaultAuthenticationsService: TakeDefaultAuthenticationsService,
    private takeAdvancedAuthenticationService: TakeAdvancedAuthenticationService,
    public contactGroupService: ContactGroupService,
    public contactService: IntegrationContactService,
    private store: Store<AppState>
  ) {
    this.advancedAuthTypesSub = this.store.select('advancedAuthTypes')
      .subscribe((types: SlugTextType[]) => this.typesAuthAdvancedAuthentication = JSON.parse(JSON.stringify([...types])));
  }

  ngOnDestroy() {
    this.advancedAuthTypesSub.unsubscribe();
  }

  ngOnInit() {
    if (!this.data.contactGroup) {
      this.data.contactGroup = {
        name: this.t('Nome'),
        contacts: [],
        default_auth_mode: 'assinaturaTela-',
        default_selfie_validation_type: ''
      };
    }
    this.contactGroupService.contactGroup = this.data.contactGroup;
  }

  t(ptText: string) {
    return this.translationService.translate(ptText, this.lang);
  }

  closedModal(): void {
    this.matDialog.close();
  }

  save() {
    this.contactGroupService.saveContactGroup();
    this.closedModal();
  }

  addContactToGroup(event: MatSelectChange): void {
    this.contactGroupService.addContact(event.value);
  }

  removeContactFromGroup(contact: Contact): void {
    this.contactGroupService.removeContact(contact);
  }

  // TODO: corrigir (append) das autenticações
  // TODO: Isolar até o end no takeAdvancedAuthenticationService
  changeValueFromAdvancedAuthentication(event, selectedDefaultAuth: SlugTextType) {
    selectedDefaultAuth.isSelected = event.checked;
    this.validateAdvancedAuth(selectedDefaultAuth, event);
    this.data.contactGroup.default_selfie_validation_type = this.data.contactGroup.default_selfie_validation_type.replace('--', '-');
  }

  private validateAdvancedAuth(selectedDefaultAuth: SlugTextType, event) {
    this.setSelfieValidationType(selectedDefaultAuth);
    this.setCpf(selectedDefaultAuth);
    this.setRg(selectedDefaultAuth);
    this.typesAuthAdvancedAuthentication.forEach(defaultAuthItem => {
      this.validateAdvancedAuthenticationSelection(selectedDefaultAuth, defaultAuthItem, event.checked);
    });
  }

  private setCpf(selectedDefaultAuth: SlugTextType) {
    if (!selectedDefaultAuth.slug.includes('cpf')) {
      return;
    }
    switch (selectedDefaultAuth.slug) {
      case 'cpf': {
        if (this.data.contactGroup.default_auth_mode.includes('cpf')) {
          break;
        }
        this.data.contactGroup.require_cpf = selectedDefaultAuth.isSelected;
        break;
      }
      case 'rg': {
        this.data.contactGroup.require_rg = selectedDefaultAuth.isSelected;
        break;
      }
      case 'cpf-premium': {
        if (selectedDefaultAuth.isSelected) {
          this.data.contactGroup.default_auth_mode += 'cpf-';
          this.data.contactGroup.require_cpf = true;
        } else {
          this.data.contactGroup.default_auth_mode = this.data.contactGroup.default_auth_mode.replace('cpf', '');
          const cpfCheckbox = this.typesAuthAdvancedAuthentication.filter(advancedAuth => advancedAuth.slug === 'cpf')[0];
          this.data.contactGroup.require_cpf = cpfCheckbox?.isSelected;
        }
        break;
      }
    }
    this.data.contactGroup.default_auth_mode = this.data.contactGroup.default_auth_mode.replace('--', '-');
  }

  private setRg(selectedDefaultAuth: SlugTextType) {
    if (selectedDefaultAuth.slug.includes('rg')) {
      this.data.contactGroup.require_rg = selectedDefaultAuth.isSelected;
    }
  }

  private setSelfieValidationType(selectedDefaultAuth: SlugTextType) {
    if (selectedDefaultAuth.slug.includes('cpf')) {
      return;
    }
    this.data.contactGroup.default_selfie_validation_type = this.data.contactGroup.default_selfie_validation_type.replace('liveness-document-match', 'facial-recognition-');
    if (selectedDefaultAuth.isSelected) {
      this.data.contactGroup.default_selfie_validation_type += selectedDefaultAuth.slug + '-';
    } else {
      this.data.contactGroup.default_selfie_validation_type = this.data.contactGroup.default_selfie_validation_type.replace(selectedDefaultAuth.slug, '');
    }
  }

  private validateAdvancedAuthenticationSelection(selectedDefaultAuth: SlugTextType, defaultAuthItem: SlugTextType, checked: boolean) {
    if (selectedDefaultAuth.slug === defaultAuthItem.slug) {
      return;
    }
    this.checkFacialRecognitionCondition(selectedDefaultAuth, defaultAuthItem, checked);
  }

  private checkFacialRecognitionCondition(selectedDefaultAuth: SlugTextType, defaultAuthItem: SlugTextType, checked: boolean) {
    if (selectedDefaultAuth.slug.includes('cpf')) {
      return;
    }

    const defaultFacialRecognition = defaultAuthItem.slug === 'facial-recognition';
    const defaultSelfie = defaultAuthItem.slug === 'selfie';
    const defaultLiveness =  defaultAuthItem.slug === 'liveness-NXCD';
    const defaultIdentityDocument = defaultAuthItem.slug === 'identity-document';

    const selectedDefaultFacial = selectedDefaultAuth.slug === 'facial-recognition';
    const selectedDefaultLiveness = selectedDefaultAuth.slug === 'liveness-NXCD';

    const facialRecognition = selectedDefaultFacial && defaultSelfie || defaultLiveness;
    const livenessNXCD = selectedDefaultLiveness && defaultSelfie || defaultFacialRecognition || defaultIdentityDocument;

    if (facialRecognition || livenessNXCD) {
      defaultAuthItem.isSelected = false;
      defaultAuthItem.isDisabled = checked;
      this.data.contactGroup.default_selfie_validation_type = this.data.contactGroup.default_selfie_validation_type.replace(defaultAuthItem.slug, '');
      this.fixAdvancedAuthMode(defaultAuthItem);
    }
  }
  // END


  // TODO: Isolar daqui p baixo no takeDefaultAuthenticationsService (Almoço didático refatoração)
  // TODO: corrigir (append) das autenticações
  changeValueFromDefaultAuthentication(event, selectedDefaultAuth: SlugTextType) {
    selectedDefaultAuth.isSelected = event.checked;
    this.validateDefaultAuth(selectedDefaultAuth, event);
    this.data.contactGroup.default_auth_mode = this.data.contactGroup.default_auth_mode.replace('--', '-');
  }

  private validateDefaultAuth(selectedDefaultAuth: SlugTextType, event) {
    if (selectedDefaultAuth.isSelected) {
      this.data.contactGroup.default_auth_mode += selectedDefaultAuth.slug + '-';
    } else {
      this.data.contactGroup.default_auth_mode = this.data.contactGroup.default_auth_mode.replace(selectedDefaultAuth.slug, '');
    }
    this.typesAuthDefaultAuthentication.forEach(defaultAuthItem => {
      this.validateDefaultAuthenticationSelection(selectedDefaultAuth, defaultAuthItem, event);
    });
  }

  private validateDefaultAuthenticationSelection(selectedDefaultAuth: SlugTextType, defaultAuthItem: SlugTextType, event) {
    if (selectedDefaultAuth.slug === defaultAuthItem.slug) {
      return;
    }
    this.checkTokensCondition(selectedDefaultAuth, defaultAuthItem);
    this.checkCertificadoDigitalCondition(selectedDefaultAuth, defaultAuthItem, event);
  }

  private checkTokensCondition(selectedDefaultAuth: SlugTextType, defaultAuthItem: SlugTextType) {
    this.checkTokenSmsCondition(selectedDefaultAuth, defaultAuthItem);
    this.checkTokenEmailCondition(selectedDefaultAuth, defaultAuthItem);
    this.checkTokenWppCondition(selectedDefaultAuth, defaultAuthItem);
  }

  private checkCertificadoDigitalCondition(selectedDefaultAuth: SlugTextType, defaultAuthItem: SlugTextType, event) {
    if (selectedDefaultAuth.slug === 'certificadoDigital') {
      defaultAuthItem.isSelected = false;
      this.sanitizeDefaultAuthMode(defaultAuthItem);
      defaultAuthItem.isDisabled = event.checked;
    }
  }

  private checkTokenEmailCondition(selectedDefaultAuth: SlugTextType, defaultAuthItem: SlugTextType) {
    if (selectedDefaultAuth.slug === 'tokenEmail' && (defaultAuthItem.slug === 'tokenSms' || defaultAuthItem.slug === 'tokenWhatsapp')) {
      defaultAuthItem.isSelected = false;
      this.sanitizeDefaultAuthMode(defaultAuthItem);
    }
  }

  private checkTokenSmsCondition(selectedDefaultAuth: SlugTextType, defaultAuthItem: SlugTextType) {
    if (selectedDefaultAuth.slug === 'tokenSms' && (defaultAuthItem.slug === 'tokenEmail' || defaultAuthItem.slug === 'tokenWhatsapp')) {
      defaultAuthItem.isSelected = false;
      this.sanitizeDefaultAuthMode(defaultAuthItem);
    }
  }

  private checkTokenWppCondition(selectedDefaultAuth: SlugTextType, defaultAuthItem: SlugTextType) {
    if (selectedDefaultAuth.slug === 'tokenWhatsapp' && (defaultAuthItem.slug === 'tokenEmail' || defaultAuthItem.slug === 'tokenSms')) {
      defaultAuthItem.isSelected = false;
      this.sanitizeDefaultAuthMode(defaultAuthItem);
    }
  }

  private sanitizeDefaultAuthMode(defaultAuthItem: SlugTextType) {
    this.data.contactGroup.default_auth_mode = this.data.contactGroup.default_auth_mode.replace(defaultAuthItem.slug, '');
  }

  private fixAdvancedAuthMode(defaultAuthItem: SlugTextType) {
    this.data.contactGroup.default_selfie_validation_type = this.data.contactGroup.default_selfie_validation_type.replace(defaultAuthItem.slug, '');
  }

  searchContacts() {
    const filters: FilterContacts = {
      filter_text: this.filterText
    };
    this.loadingContacts = true;
    this.contactService.getContacts(filters)
    .subscribe(({ results, count }: ItemContacts) => {
      this.data.allContacts = results;
      this.loadingContacts = false;
    });
  }
  // END
  shouldCheckAdvancedAuth(auth: SlugTextType) {
    const contactGroup = this.data.contactGroup;
    if (!contactGroup) {
      return;
    }

    const authMode = contactGroup.default_auth_mode;
    const isCpf = contactGroup.require_cpf && auth.slug === 'cpf' && (!authMode.includes('cpf') || auth.isSelected);
    const isCpfPremium = contactGroup.require_cpf && authMode.includes('cpf') && auth.slug === 'cpf-premium';

    const selfieValidationType = contactGroup?.default_selfie_validation_type;
    const containsSelfieValidation = selfieValidationType?.includes(auth.slug);
    const isLiveness = selfieValidationType?.includes('liveness-document-match');
    const isLivenessNXCD = selfieValidationType?.includes('liveness-NXCD');
    const isFacialRecognition = isLiveness && auth.slug.includes('facial-recognition');
    const isLivenessValidation = isLivenessNXCD && auth.slug.includes('liveness-NXCD');
    const containsDocumentValidation = (selfieValidationType?.includes('identity-document')) && auth.slug.includes('identity-document');
    const shouldCheck = auth.isSelected || containsSelfieValidation || isCpf || isCpfPremium || isFacialRecognition || containsDocumentValidation || isLivenessValidation;
    if (shouldCheck) {
      this.typesAuthAdvancedAuthentication.forEach(defaultAuthItem => {
        this.validateAdvancedAuthenticationSelection(auth, defaultAuthItem, true);
      });
    }
    return shouldCheck;
  }
}
