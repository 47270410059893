import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {MenuModel} from '@client/page-single-document/page-single-document.model';

@Component({
  selector: 'app-menu-mobile',
  templateUrl: './menu-mobile.component.html',
  styleUrls: ['./menu-mobile.component.scss']
})
export class MenuMobileComponent {
  @Input() showModal: boolean;
  @Input() itens: MenuModel[];
  @Input() title: string;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Input() backgroundBlackDefault = true;
  @Input() border = true;

  onCloseModal() {
    this.closeModal.emit();
  }

  @HostListener('document:click', ['$event.target'])
  clickOutside(target: HTMLElement) {
    if (!(target instanceof SVGElement) && target.tagName !== 'path' && target.closest('#menu-mobile-options')) {
      this.closeModal.emit();
    }
  }
}
