<h1>{{'Editar respostas do formulário' | translate}}</h1>
<div mat-dialog-content>
  <p>{{'Deseja editar as respostas do formulário?'| translate}}
    <br>{{'Preencha os campos que desejar e os demais deverão ser preenchidos pelo signatário.' | translate}}
  </p>

  <div class="spinner-loading-container-templates" *ngIf="!template">
    <app-spinner-loading></app-spinner-loading>
  </div>
  <app-prefilled *ngIf="template"
                 [model]="template"
                 (updatePreFilledLink)="updatePreFilledAnswers()"
  >
  </app-prefilled>

</div>
<div mat-dialog-actions style="justify-content: flex-end;">
  <button mat-button mat-dialog-close [disabled]="loadingSaveAnswers">{{'Cancelar' | translate}}</button>
  <div *ngIf="loadingSaveAnswers" class="spinner-loading-container-templates">
    <app-spinner-loading></app-spinner-loading>
  </div>
  <zs-button
    [disable]="loadingSaveAnswers"
    typeButton="primary"
    label="Salvar alterações"
    (click)="saveNewAnswers()"
  >
  </zs-button>
</div>
