import { LotDocsProps } from './lot-docs.model';
import { createReducer, on, Action } from '@ngrx/store';
import { deleteDoc, lastAndAllDocsSelected, resetDocs, selectAllDocs } from './lot-docs.actions';


const INITIAL_STATE: LotDocsProps = {
  lastDocSelected: null,
  allDocsSelected: [],
  deselectAll: false,
  selectAll: false
};

const _docsLot = createReducer(INITIAL_STATE,
  on(lastAndAllDocsSelected, (state, { payload }) => ({
    ...state,
    lastDocSelected: payload,
    allDocsSelected: [...state.allDocsSelected, payload],
  })),
  on(deleteDoc, (state, { payload }) => ({...state,
    allDocsSelected: state.allDocsSelected.filter(item => item !== payload),
  })),
  on(resetDocs, (state, payload) => ({
    ...state,
    lastDocSelected: null,
    allDocsSelected: [],
    deselectAll: payload.deselectAll
  })),
  on(selectAllDocs, (state, payload) => ({
      ...state,
      selectAll: payload.selectAll
    })
  )
);

export function docsLotReducer(state: LotDocsProps, action: Action) {
  return _docsLot(state, action);
}
