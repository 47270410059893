<div class="open-actions-background" *ngIf="showModal" (click)="onCloseModal()">
  <div class="container-rmv">
    <div (click)="preventModalFromClosing($event)">
      <div class="container-header">
        <h2>{{title | translate}}</h2>
        <zs-button
          (click)="onCloseModal()"
          typeButton="icon"
          icon="icon_x_close">
        </zs-button>
      </div>
      <span>{{info}}</span>
      <div class="container-btns">
        <zs-button
          id="btn-close-modal"
          typeButton="quaternary"
          [label]="titleBtnCancel ? titleBtnCancel : 'Cancelar'"
          (click)="onCloseModal()">
        </zs-button>
        <zs-button
          id="btn-rmv-membership"
          typeButton="primary"
          (click)="onConfirm()"
          [label]="labelBtn">
        </zs-button>
      </div>
    </div>
  </div>
</div>
