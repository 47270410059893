import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as SignaturePad from 'signature_pad';

interface OptionsSignatureProps {
  minWidth: number;
  maxWidth: number;
  canvasWidth: string | number;
  canvasHeight: string | number;
  backgroundColor: string;
}

@Component({
  selector: 'app-signatura-pad-clean',
  templateUrl: './signatura-pad-clean.component.html',
  styleUrls: ['./signatura-pad-clean.component.scss']
})
export class SignaturaPadCleanComponent implements OnInit, OnDestroy, AfterContentInit, AfterViewInit, OnChanges {
  @Output() done: EventEmitter<string>;
  @Output() cleared: EventEmitter<boolean>;
  signaturePad: any;
  elementRef: ElementRef;
  padWidth: number;
  padHeight: number;
  options: OptionsSignatureProps;
  originalInnerWidth: number;
  originalInnerHeight: number;
  originalIsHorizontal: boolean;

  constructor(
    elementRef: ElementRef,
  ) {
    this.elementRef = elementRef;
    this.options = {
      minWidth: .5,
      maxWidth: 1.8,
      canvasWidth: 0,
      canvasHeight: 0,
      backgroundColor: 'rgba(0,0,0,0)'
    };
    this.done = new EventEmitter();
    this.cleared = new EventEmitter();
  }

  ngOnInit(): void {
    this.originalInnerWidth = window.innerWidth;
    this.originalInnerHeight = window.innerHeight;
    this.originalIsHorizontal = this.originalInnerHeight < this.originalInnerWidth;
  }

  ngOnChanges(changes: SimpleChanges) {
      this.onEnd();
  }

  public ngAfterContentInit(): void {
    const sp: any = SignaturePad.default;
    const canvas: any = this.elementRef.nativeElement.querySelector('canvas');

    if ((this.options as OptionsSignatureProps).canvasHeight) {
      canvas.height = (this.options as OptionsSignatureProps).canvasHeight;
    }

    if ((this.options as OptionsSignatureProps).canvasWidth) {
      canvas.width = (this.options as OptionsSignatureProps).canvasWidth;
    }

    this.signaturePad = new sp(canvas, this.options);
    this.signaturePad.onEnd = this.onEnd.bind(this);
  }

  public ngOnDestroy(): void {
    const canvas: any = this.elementRef.nativeElement.querySelector('canvas');
    canvas.width = 0;
    canvas.height = 0;
  }

  public toDataURL(imageType?: string, quality?: number): string {
    return this.signaturePad?.toDataURL(imageType, quality);
  }

  public clear(): void {
    this.signaturePad.clear();
  }

  public isEmpty(): boolean {
    return this.signaturePad.isEmpty();
  }

  public onEnd(): void {
    this.done.emit(this.toDataURL());
  }

  public set(option: string, value: number): void {
    switch (option) {
      case 'canvasHeight':
        this.signaturePad.canvas.height = value;
        break;
      case 'canvasWidth':
        this.signaturePad.canvas.width = value;
        this.signaturePad.canvas.maxWidth = value;
        break;
      default:
        this.signaturePad[option] = value;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => this.resizeCanvas(), 0); // prevent Error:Expression has changed after it was checked
  }

  resizeCanvas() {
    this.padWidth = this.elementRef.nativeElement.offsetParent.clientWidth;
    this.padHeight = this.elementRef.nativeElement.offsetParent.clientHeight;
    this.set('canvasWidth', this.padWidth);
    this.set('canvasHeight', this.padHeight);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    setTimeout(() => this.resizeCanvas(), 0);
  }

  @HostListener('window:orientationchange', ['$event'])
  screenOrientationChange($event: Event) {
    if ($event.type === 'orientationchange') {
      setTimeout(() => {
        this.setUrlDataImageWhenSignaturePadRotate();
        this.resizeCanvas();
        this.clearSignature();
      }, 0);
    }
  }

  setUrlDataImageWhenSignaturePadRotate() {
    if (!this.isEmpty()) {
      const createImaged = document.createElement('img');
      createImaged.src = this.toDataURL();
    }
  }

  public clearSignature(): void {
    this.signaturePad.clear();
    this.cleared.emit(true);
  }
}
