<div *ngIf="ldVariable" class="slide-toggle">
  <ng-container *ngIf="successBetaTag">
    <p>
      {{successBetaTag | translate}}
    </p>
  </ng-container>

  <ng-container *ngIf="!successBetaTag">
    <p *ngIf="idVariableLocalStorage ; else betaTagFoldersOff" id="message-enable-toggle">
      {{ window.innerWidth > 600 ?
      (enabledToggleMessage ? (enabledToggleMessage | translate) : 'Sua opinião é fundamental para aprimorarmos a experiência de uso.' | translate) :
      ('Restaurar versão original'|translate)
      }}
      <a [href]="formUrl" target="_blank">
        {{'Conte-nos o que você pensa!' | translate}}
      </a>
    </p>

    <ng-template #betaTagFoldersOff>
      <p>
        {{ textBetaOff | translate }}
      </p>
    </ng-template>
  </ng-container>

  <div class="content-slide_tag">
    <div class="zs-tag">
      Beta
    </div>
    <mat-slide-toggle [(ngModel)]="idVariableLocalStorage" (ngModelChange)="changeLdVariableLocal($event)"></mat-slide-toggle>
  </div>
</div>
