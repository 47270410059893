import { Component, Input } from '@angular/core';
import {Signer} from '../../helpers/model/docs';
import {environment} from '../../../environments/environment';
import {CopyService} from '../copy.service';
import { WhatsappService } from 'src/app/shared/services/whatsapp/whatsapp.service';

@Component({
  selector: 'app-signer-input-link',
  templateUrl: './signer-input-link.component.html',
  styleUrls: ['./signer-input-link.component.scss']
})
export class SignerInputLinkComponent  {
  @Input() signer: Signer;
  appUrl = environment.APP_URL;


  constructor(private whatsappService: WhatsappService, private copyService: CopyService) { }

  getWhatsAppSignerLink() {
    return this.whatsappService.getWhatsAppSignerLink(this.signer);
  }

  copy(text: string) {
    this.copyService.copyToClipboard(text);
  }
}
