import {Action, createReducer, on} from '@ngrx/store';
import * as actions from './steps-new-document.actions';
import StepNewDocumentProps from './steps-new-document.model';


export const INITIAL_STATE: StepNewDocumentProps = {
  currentStepFluxCreatingDocument: 'upload-file',
  token_document: '',
  isNewDocument: false
};


const _stepsNewDocumentReducer = createReducer(
  INITIAL_STATE,
  on(actions.saveCurrentTabStepsNewDocument, (state, {payload}) => ({
    ...state,
    currentStepFluxCreatingDocument: payload
  })), on(actions.saveTokenDocumentAction, (state, {payload}) => ({
    ...state,
    token_document: payload
  })),
  on(actions.saveKnowIsNewDocumentAction, (state, {payload}) => ({
    ...state,
    isNewDocument: payload === 1
  }))
);


export default function stepsNewDocumentReducer(state: StepNewDocumentProps, action: Action) {
  return _stepsNewDocumentReducer(state, action);
}
