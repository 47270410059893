import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {CropperComponent} from 'angular-cropperjs';
import {NgxImageCompressService} from 'ngx-image-compress';
import {DrawImageWithGoldenRatioService} from '@shared/services/draw-image-with-golden-ratio/draw-image-with-golden-ratio.service';
import {UtilsService} from '@shared/services/utils/utils.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../app.state';
import {usePhotoDocument, useUserAgent} from '../../../app.state.selectors';
import {PhotoDocumentProps} from '../../modal-camera/store/PhotoDocument/photo-document.model';
import {MenuModel} from '@client/page-single-document/page-single-document.model';


@Component({
  selector: 'app-upload-signature',
  templateUrl: './upload-signature.component.html',
  styleUrls: ['./upload-signature.component.scss']
})
export class UploadSignatureComponent implements OnInit, OnChanges {
  @Input() activeTab = 'draw';
  @Input() isVisto = false;
  @Output() emitSignatureUploaded = new EventEmitter();
  @ViewChild('angularCropper') public angularCropper: CropperComponent;
  signature_image = '';
  signature_image_safe = '';
  finishSignatureImageProcess = false;
  config: Cropper.Options;
  goldenRatio = 1.618;
  openSignatureCamera = false;
  modalOpen = false;

  userAgent = {
    isMobile: null,
    isTablet: null,
    isDesktop: null
  };

  optionMenuGroup: MenuModel[] = [
    {
      option: () => document.getElementById('file').click(),
      textOption: 'Buscar na galeria',
      iconOption: 'edit-05'
    },
    {
      option: () => document.getElementById('photo_file').click(),
      textOption: 'Abrir camera',
      iconOption: 'camera-01'
    }
  ];

  constructor(
    private imageCompress: NgxImageCompressService,
    private drawImageService: DrawImageWithGoldenRatioService,
    public utils: UtilsService,
    private store: Store<AppState>,
  ) {
    store.select(useUserAgent).subscribe(({isMobile, isTablet, isDesktop}) => {
      this.userAgent.isDesktop = isDesktop;
      this.userAgent.isMobile = isMobile;
      this.userAgent.isTablet = isTablet;
    });
  }

  ngOnInit(): void {
    this.goldenRatio = this.isVisto ? 1 : 1.618;
    const minCropHeight = 80;
    this.config = {
      initialAspectRatio: this.goldenRatio,
      minCropBoxWidth: minCropHeight * this.goldenRatio,
      minCropBoxHeight: minCropHeight,
      zoomable: true,
      zoomOnWheel: false,
      zoomOnTouch: true
    };
  }

  async ngOnChanges(changes: SimpleChanges) {
    const activeTab = changes.activeTab?.currentValue as string;
    if (activeTab && activeTab === 'upload') {
      const {width, height} = await this.drawImageService.getImageDimensions(this.signature_image) as { width: string, height: string };
      this.emitSignatureUploadedWithGoldenRatio(this.signature_image, width, height);
    }
  }

  undoImage() {
    this.finishSignatureImageProcess = false;
    this.signature_image = this.signature_image_safe;
    this.angularCropper.cropper.clear();
  }

  finishCropping() {
    this.angularCropper.exportCanvas();
    this.signature_image = this.angularCropper.cropper
      .getCroppedCanvas()
      .toDataURL('image/png');
    const width = this.angularCropper.cropper.getCroppedCanvas().width;
    const height = this.angularCropper.cropper.getCroppedCanvas().height;
    this.emitSignatureUploadedWithGoldenRatio(this.signature_image, width, height);
  }

  emitSignatureUploadedWithGoldenRatio(signatureImage, width, height) {
    const ratio = width / height;
    if (this.goldenRatio - 0.03 <= ratio && ratio <= this.goldenRatio + 0.03) {
      this.compressAndEmitSignatureUploaded(signatureImage);
      return;
    }

    const imgSignature: HTMLImageElement = document.createElement('img');
    imgSignature.src = this.signature_image;
    imgSignature.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      if (ratio >= this.goldenRatio) {
        canvas.width = width;
        canvas.height = width / this.goldenRatio;
        ctx.drawImage(imgSignature, 0, (canvas.height - height) / 2, width, height);
      } else {
        canvas.width = height * this.goldenRatio;
        canvas.height = height;
        ctx.drawImage(imgSignature, (canvas.width - width) / 2, 0, width, height);
      }
      const dataUrl = canvas.toDataURL();
      this.compressAndEmitSignatureUploaded(dataUrl);
    };
  }

  compressAndEmitSignatureUploaded(uncompressedImageData) {
    this.imageCompress.compressFile(uncompressedImageData, 1, 70, 100)
      .then(
        imageCompressed => {
          this.finishSignatureImageProcess = true;
          this.emitSignatureUploaded.emit(imageCompressed);
          console.warn(
            `Img signature antes da compressão -> ${this.imageCompress.byteCount(uncompressedImageData)} Bytes\nDepois da compressão -> ${this.imageCompress.byteCount(imageCompressed)} Bytes`
          );
        }).catch(() => {
      this.finishSignatureImageProcess = true;
      this.emitSignatureUploaded.emit(uncompressedImageData);
    });

  }

  onFileSelected(isSelectedPhoto: boolean) {
    let inputNode: any;
    if (isSelectedPhoto) {
      inputNode = document.querySelector('#photo_file');
    } else {
      inputNode = document.querySelector('#file');
    }

    const img = document.createElement('img');
    if (typeof (FileReader) !== 'undefined') {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        img.src = e.target.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
          const MAX_WIDTH = 2700;
          const MAX_HEIGHT = 900;
          let width = img.width;
          let height = img.height;
          if (width == 0) {
            width = MAX_WIDTH;
          }
          if (height == 0) {
            height = MAX_HEIGHT;
          }
          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          const ctx2 = canvas.getContext('2d');
          ctx2.drawImage(img, 0, 0, width, height);
          const dataUrl = canvas.toDataURL();

          this.signature_image = dataUrl;
          this.signature_image_safe = dataUrl;
        };
      };
      if (this.userAgent.isMobile || this.userAgent.isTablet) {
        this.handleImageSignature();
      }
      reader.readAsDataURL(inputNode.files[0]);
      inputNode.value = '';
    }

    if (this.userAgent.isMobile || this.userAgent.isTablet) {
      this.handleImageSignature();
    }
  }

  openModalPhoto() {
    this.openSignatureCamera = true;
    this.utils.openModalDocumentFront('sign-page');
  }

  handleImageSignature() {
    this.store.select(usePhotoDocument).subscribe(({base64_photo_document}: PhotoDocumentProps) => {
      this.signature_image = base64_photo_document;
      this.signature_image_safe = base64_photo_document;
    });
  }

  remover() {
    this.signature_image = '';
    this.finishSignatureImageProcess = false;
  }

  showModal() {
    this.modalOpen = true;
  }
  closeModal() {
    this.modalOpen = false;
  }
}
