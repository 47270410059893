<div class="container_zs_redirect_styled_link_button">
  <a
    target="_blank"
    mat-raised-button
    class="zs_redirect_styled_link_button"
    [href]="signer.redirect_link"
    [matTooltip]="signer.redirect_link && getMessageTooltipRedirectLink()"
  >
    {{'CONTINUAR' | translate}}
    <mat-icon svgIcon="right_arrow"></mat-icon>
  </a>
</div>