<section class="container-content-documents-trash">

  <div class="container-content-documents-trash-header">
    <app-trash-header
      [docs] = "docs"
      [allDocsSelected]="allDocsSelected"
    ></app-trash-header>
  </div>

  <div class="container-loading-skeleton" *ngIf="loadingDocumentsInTrash">
    <app-skeleton height="80px" *ngFor="let item of skeletonList"></app-skeleton>
  </div>

  <div class="doc-list" *ngIf="!loadingDocumentsInTrash">
    <p *ngIf="docs && docs.length === 0">{{'Não há documentos na lixeira.' | translate}}</p>
    <div class="checkbox-and-text-content">
      <div class="checkbox-and-text-content-left">
        <ng-container *ngIf="docs && docs.length !== 0">
          <mat-checkbox [matTooltip]="checkboxSelectDeselectAll ? t('Desselecionar') : t('Selecionar todos')"
                        [checked]="checkboxSelectDeselectAll"
                        (change)="checkUncheckAll($event)"></mat-checkbox>
        </ng-container>

        <div class="zs-acoes-masivas-buttons">
          <div class="zs-acoes-masivas-button" (click)="recoverDocuments()">
            <mat-icon svgIcon="recover_document"></mat-icon>
            <div>{{'Recuperar' | translate}}</div>
          </div>
          <div *ngIf="roleMembershipIsOwner" class="zs-acoes-masivas-button" (click)="exclude()">
            <mat-icon svgIcon="slash_circle_02"></mat-icon>
            <div>{{'Eliminar' | translate}}</div>
          </div>
        </div>
      </div>

      <div class="checkbox-and-text-content-right">
        <span class="text-show-quantity" *ngIf="allDocsSelected === 0">
            {{'$0 documentos' | translate: [docs.length]}}
        </span>
        <span class="new-feature-acoes-masivas" *ngIf="allDocsSelected === 1">{{allDocsSelected}} {{"documento selecionado" | translate}}</span>
        <span class="new-feature-acoes-masivas" *ngIf="allDocsSelected > 1">{{allDocsSelected}} {{"documentos selecionados" | translate}}</span>
      </div>
    </div>

    <div *ngFor="let doc of docs; let i = index" class="container_docs_deleted">
      <div class="container-itens-trash-documents">
        <mat-checkbox [matTooltip]="doc.selected ? t('Desselecionar') : t('Selecionar')" [checked]="doc.selected"
                      (change)="selectedDocument($event, i)"></mat-checkbox>

        <strong>{{'Documento' | translate}} {{doc.open_id}}</strong>
        - {{doc.name}}
        - {{'criado por $0 em $1' | translate: [doc.created_by.email, doc.created_at | date: (lang !== 'en' ? 'dd/MM/yyyy HH:mm' : 'MM/dd/yyyy HH:mm')]}}
        - {{'excluído por $0 em $1' | translate: [doc.deleted_by.email, doc.deleted_at | date: (lang !== 'en' ? 'dd/MM/yyyy HH:mm' : 'MM/dd/yyyy HH:mm')]}}

      </div>
    </div>

  </div>
</section>
