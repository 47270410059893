import {changeLanguage} from '../../../translation/translation.actions';
import {Language} from '../../../translation/translation';
import {Store} from '@ngrx/store';
import {AfterContentInit, Component, OnDestroy, OnInit} from '@angular/core';
import {AppState} from 'src/app/app.state';
import {
  UpdateCompanyProps,
  UpdateCompanyResponse
} from 'src/app/client/settings-organization/settings-organization.model';
import {AuthBackendService} from 'src/app/auth/backend.service';
import {WarningService} from '../warning.service';
import {TranslatorService} from '../translator.service';
import {MatSelectLanguageService} from '../../shared/services/mat-select-language/mat-select-language.service';
import {LanguageProps} from '../../auth/login/Login';
import {UsersReduxService} from 'src/app/shared/services/redux/users-redux/users-redux.service';
import {SelectOptions} from '@shared/interfaces/selectInterface';
import {User} from '@helpers/model/user';

@Component({
  selector: 'app-select-translation',
  templateUrl: './select-translation.component.html',
  styleUrls: ['./select-translation.component.scss']
})
export class SelectTranslationComponent implements OnInit, OnDestroy, AfterContentInit {
  user: User;

  languages: SelectOptions[] = [];

  selectedLanguage: Language = (localStorage.getItem('lang') as Language) || 'pt-br';
  currentLanguage: LanguageProps = null;
  actualLanguage: SelectOptions;
  private reducerSubscriptions: any = [];

  constructor(
    private _store: Store<AppState>,
    private authBackend: AuthBackendService,
    private warningService: WarningService,
    private _translationService: TranslatorService,
    private matSelectLanguageService: MatSelectLanguageService,
    private usersReduxService: UsersReduxService
  ) {
  }

  ngOnInit(): void {
    this._store.select('translation').subscribe(data => (this.selectedLanguage = data.language));
    this.getUser();
  }

  ngOnDestroy() {
    this.reducerSubscriptions.map((subscription) => {
      subscription.unsubscribe();
    });
  }

  getUser() {
    const userSubscription = this.usersReduxService.getUser().subscribe(
      ({user}) => {
        this.user = user;
        this.setActualLanguage();
      },
      err => {
        console.error(err);
      }
    );
    this.reducerSubscriptions.push(userSubscription);
  }

  ngAfterContentInit() {
    this.renderCorrectLabelAndIconMatSelectTrigger();
  }

  t(ptText: string) {
    return this._translationService.translate(ptText, this.selectedLanguage);
  }

  changeLanguageSelect($event: any) {
    const languageSelected = $event.value as Language;
    this.updateCompany({lang: languageSelected});
    if (languageSelected) {
      this._store.dispatch(
        changeLanguage({
          payload: languageSelected
        })
      );
    }
  }

  private renderCorrectLabelAndIconMatSelectTrigger() {
    this.currentLanguage = this.matSelectLanguageService.renderCorrectLabelAndIconMatSelectTrigger(
      this.selectedLanguage
    );
  }

  updateCompany(params: UpdateCompanyProps) {
    this.authBackend.updateCompany(this.user.company.id, params).subscribe(
      (data: UpdateCompanyResponse) => {
        this.warningService.toastrSuccess(this.t('Atualizado com sucesso.'));
        window.location.reload();
      },
      err => {
        console.error(err);
        this.warningService.toastrError(
          this.t('Ocorreu algum problema na atualização. Tente atualizar a tela.')
        );
      }
    );
  }

  setActualLanguage() {
    const companyLanguage = this.user.company.lang;

    this.languages = this.matSelectLanguageService.languages.map(language => ({
      name: language.label,
      value: language.name
    }));

    const index = this.languages.findIndex(lang => companyLanguage === lang.value);
    this.actualLanguage = this.languages[index];
  }
}
