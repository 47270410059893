import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';


interface SelectOptions {
  name: string;
  value: string;
}

@Component({
  selector: 'app-select-to-input',
  templateUrl: './select-to-input.component.html',
  styleUrls: ['./select-to-input.component.scss'],
})
export class SelectToInputComponent implements AfterViewInit {

  @Input() value: SelectOptions = {
    name: '',
    value: ''
  };

  @Output() valueChange = new EventEmitter<string>();

  @Input() options: SelectOptions[];

  @Input() disabledOption;
  @Input() label: string = null;
  @Input() classRadius = '';
  @Input() styleFont: boolean;


  ngAfterViewInit() {
    this.changeFont(this.value.value);
  }

  ChangeEmiter($event) {
    this.valueChange.emit($event);
    this.changeFont($event.value);
  }
  changeFont(fonte?) {
    if (this.styleFont) {
      const select = document.querySelector('.zs-mat-select .mat-form-field .mat-form-field-flex .mat-select .mat-select-value') as HTMLElement;
      select.style.fontFamily = fonte;
    }
  }
}
