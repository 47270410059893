import {Envs} from './environment.model';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: Envs = {
  production: false,
  sandbox: false,
  APP_URL: 'https://retrospective.app.zapsign.com.br',
  API_URL: 'https://retrospective.api.zapsign.com.br/api',
  API_URL_HOLST: 'https://retrospective.api.zapsign.com.br',
  // APP_URL: "http://bs-local:4200",
  // API_URL: "http://bs-local:3001/api",
  RECAPTCHA_V3_SITE_KEY: '6Lfm9cgZAAAAAMdOBysM3QK9mFHZ_qJ6tSUa-yEB',
  RECAPTCHA_V2_SITE_KEY: '6LcX9sgZAAAAAJq2PQExwIAoRLuQYg7BuW5tdSsQ',
  FACEBOOK_SOCIAL_AUTH_KEY: '665999820628570',
  LINKEDIN_SOCIAL_AUTH_KEY: '77drf2pdlqiby9',
  MICROSOFT_SOCIAL_AUTH_KEY: 'afeb2a93-d8b1-40fa-b1e5-183064ab6e72',
  GOOGLE_SOCIAL_AUTH_KEY: '583344973567-9vpp2qm5135u1iuaru87qctgnonhb4mb.apps.googleusercontent.com',
  WHATSAPP_SUPPORT_NUMBER: '551149508941',
  WHATSAPP_UPSELL_NUMBER: '5531997491944',
  GOOGLE_TAG_MANAGER_ID: 'GTM-PL44W38',
  BR_HOST: 'br.app.zapsign.com.br',
  BR_API_URL: 'https://br.api.zapsign.com.br/api',
  HOMOLOG_API_URL: 'https://homolog.api.zapsign.com.br/api',
  HOMOLOG_HOST: 'homolog.app.zapsign.com.br',
  SANDBOX_API_URL: 'https://sandbox.api.zapsign.com.br/api',
  SANDBOX_HOST: 'sandbox.app.zapsign.com.br',
  TRUORA_HOST: 'truora.com',
  TRUORA_API_URL: 'https://api.sign.truora.com/api',
  DEFAULT_API_URL: 'https://api.zapsign.com.br/api',
  PROXY_APP_URL: 'http://localhost:4200/verificar',
  SELFIE_VALIDATION_TENSORFLOW_URL: 'http://localhost:9001/validate/',
  SAML_URL: 'https://retrospective.api.zapsign.com.br/saml2_bridge',
  NXCD_API_KEY: '645e3f9c17764ebf3de20a9d:OTEX1ga-IAFLPCNg-qvw4yxb',
  NXCD_URL: 'https://api-homolog.nxcd.app',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
