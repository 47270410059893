import {ActionsRequestModal, ActionTypesModal} from '../actions/modal-user-actions';
import {UserModalProps} from '../models/modalUser.model';

export const INITIAL_STATE: UserModalProps = {
  open: false,
  highlight: 'user',
};

export const requestModalUserReducer = (state = INITIAL_STATE, action: ActionsRequestModal) => {
  switch (action.type) {
    case ActionTypesModal.OPENUSER: {
      return {
        ...state,
        open: true,
        highlight: 'user'
      };
    }
    case ActionTypesModal.OPENCOMPANY: {
      return {
        ...state,
        open: true,
        highlight: 'company'
      };
    }
    case ActionTypesModal.CLOSE: {
      return {
        ...state,
        open: false,
        highlight: 'user'
      };
    }
    default: return state;
  }
};
