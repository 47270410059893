import {AppState} from './app.state';

// Todo: Transformar todos os selectors do redux na flag 'selector+algumacoisa'
// Exemplo: useSelectorSetSignerReducer
export const usePhotoDocument = (state: AppState) => state.base64_photo_document;
export const useBase64Photo = (state: AppState) => state.base64;
export const useUserAgent = (state: AppState) => state.userAgent;
export const useModalNoComputerCamera = (state: AppState) => state.modalNoComputerCamera;
export const useBatchDoc = (state: AppState) => state.lotDoc;
export const useTranslationPlatform = (state: AppState) => state.translation;
export const useSetIsFirstUser = (state: AppState) => state.setIsFirstUserReducer;
export const useUpdateSignedFile = (state: AppState) => state.hasToUpdateSingedFile;
export const useModalActivateAccount = (state: AppState) => state.modalActivateAccountReducer;
export const useNewsletterNotification = (state: AppState) => state.newsletterNotificationCount;
export const useSaveEmailUnauthorizedReducer = (state: AppState) => state.saveEmailUnauthorizedReducer;
export const useSavePasswordUnauthorizedReducer = (state: AppState) => state.savePasswordUnauthorizedReducer;

export const usePrimaryColorReducer = (state: AppState) => state.primaryColorReducer;
export const useCurrentTabMethodsSignature = (state: AppState) => state.currentTabReducer;
export const useSelectorSetSignerReducer = (state: AppState) => state.setSignerReducer;
export const useSelectorSetLoadingSignedFile = (state: AppState) => state.setLoadingSignedFileReducer;
export const useSelectorPlans = (state: AppState) => state.plansReducer;
export const useSelectorCounterDocumentsReducer = (state: AppState) => state.counterDocumentsReducer;
export const useSelectorStepsNewDocumentReducer = (state: AppState) => state.stepsNewDocumentReducer;
export const useSelectorFolderWhereUserWasInDocumentListRedux = (state: AppState) => state.folderIamIn;
export const useSelectorUserGroups = (state: AppState) => state.userGroupReducer;
export const useCounterDocumentsReducer = (state: AppState) => state.counterDocumentsReducer;
export const useSelectorChecks = (state: AppState) => state.checkReducer;
export const selectCurrentUser = (state: AppState) => state.currentUserReducer;
export const selectCurrentSubscription = (state: AppState) => state.currentSubscriptionReducer;
export const selectCurrentPaywall = (state: AppState) => state.currentPaywallReducer;
export const selectSelfieValidation = (state: AppState) => state.selfieValidation;
export const useLoginUrlQueryParams = (state: AppState) => state.loginUrlQueryParamsReducer;
export const useSelectorLivenessValidation = (state: AppState) => state.livenessValidation;
export const folderToBeMovedSelector = (state: AppState) => state.folderToBeMoved;
