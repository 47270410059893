import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HelpersGlobalService} from "../../../helpers-global.service";
import {Language} from "../../../../translation/translation";
import {Signer} from "../../../helpers/model/docs";

@Injectable({
  providedIn: 'root'
})
export class WhatsappService {
  private appUrl = environment.APP_URL
  private proxyAppUrl = environment.PROXY_APP_URL
  private lang:Language = localStorage.getItem('lang') as Language || 'pt-br'

  constructor(private helpersGlobalService: HelpersGlobalService) { }

  private getSignUrl(signer: Signer) : string {
    const token = signer.token
    return this.lang === 'pt-br' ? `${this.appUrl}/verificar/${token}` : `${this.proxyAppUrl}/${token}`
  }

  private defaultMessageWhatsapp(): string {
    return this.helpersGlobalService.t('segue link para assinatura eletrônica do documento. Qualquer dúvida por favor avise.')
  }

  private takeFirstNameSigner(signer: Signer): string {
    const [firstName] = signer.name.split(' ')
    return firstName
  }

  getWhatsAppSignerLink(signer: Signer) : string {
    const firstNameSigner = this.takeFirstNameSigner(signer)
    const signUrl = this.getSignUrl(signer)
    const message = this.defaultMessageWhatsapp()
    const text = `${firstNameSigner}, ${message}%0a${signUrl}`;

    const queryParameters = signer.phone_number !== '' ? `?phone=${signer.phone_country}${signer.phone_number}&text=${text}`: `?text=${text}`;

    return `https://api.whatsapp.com/send${queryParameters}`

  }

}
