<mat-tab-group mat-align-tabs="center" dynamicHeight>
  <mat-tab label="QR Code">
    <mat-horizontal-stepper class="qrcode">
      <mat-step label="{{'Escaneie o QR Code abaixo'|translate}}">
        <p>{{'Você pode assinar com seu celular. Para isso, abra a câmera do seu celular e escaneie o QR code abaixo.'|translate}}</p>
        <qrcode [qrdata]="qrCode" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
      </mat-step>
    </mat-horizontal-stepper>
  </mat-tab>
  <mat-tab label="Google Chrome">
    <mat-horizontal-stepper>
      <mat-step label="{{'Etapa 1'|translate}}">
        <p>{{'Abra uma nova guia do seu navegador e acesse: chrome://settings/content/camera'|translate}}</p>
        <div class="center">
          <button mat-button matStepperNext color="primary">{{'Próximo'|translate}}</button>
        </div>
      </mat-step>
      <mat-step label="{{'Etapa 2'|translate}}">
        <p>{{'Na parte escrita \'Block\', clique na lixeira para resetar suas permissões de acesso a câmera.'}}</p>
        <figure class="zs-chrome-permissions">
          <img id="chrome_permissions" src="../../../assets/img/chromePermissions.jpeg" alt="{{'Instruções para não aceitação de permissão da webcam'|translate}}" />
        </figure>
        <div class="center">
          <button mat-button matStepperPrevious color="link">{{'Voltar'|translate}}</button>
          <button mat-button matStepperNext color="primary">{{'Próximo'|translate}}</button>
        </div>
      </mat-step>
      <mat-step label="{{'Etapa 3'|translate}}">
        <p>{{'Recarregue essa página da Zapsign e clique em \'Fotografar selfie\' novamente.'|translate}}</p>
        <div class="center">
          <button mat-button matStepperPrevious color="link">{{'Voltar'|translate}}</button>
          <button mat-button matStepperNext color="primary">{{'Próximo'|translate}}</button>
        </div>
      </mat-step>
      <mat-step label="{{'Etapa 4'|translate}}">
        <p>{{'Permita a permissão da sua webcam clicando no botão \'Allow\'.'|translate}}</p>
        <figure id="figure">
          <img style="width: 66%" src="../../../assets/img/permissions.png" alt="{{'Instruções para não aceitação de permissão da webcam'|translate}}" />
        </figure>
        <div class="center">
          <button mat-button matStepperPrevious color="link">{{'Voltar'|translate}}</button>
        </div>
      </mat-step>
      </mat-horizontal-stepper>
  </mat-tab>
  <mat-tab label="Mozilia Firefox">
    <mat-horizontal-stepper>
      <mat-step label="{{'Etapa 1'|translate}}">
        <p>{{'Recarregue o navegador e clique novamente em \'Fotografar selfie\''|translate}}</p>
        <div class="center">
          <button mat-button matStepperNext color="primary">{{'Próximo'|translate}}</button>
        </div>
      </mat-step>
      <mat-step label="{{'Etapa 2'|translate}}">
        <p>{{'Aceite as permissões, clicando no botão \'Allow\'. Não se esqueça de relembrar sua decisão, clicando em \'Remember this decision\'.'|translate}}</p>
        <figure id="figure">
          <img src="../../../../assets/img/moziliaPermission.png" alt="{{'Instruções para não aceitação de permissão da webcam'|translate}}" />
        </figure>
        <div class="center">
          <button mat-button matStepperPrevious color="link">{{'Voltar'|translate}}</button>
        </div>
      </mat-step>
      </mat-horizontal-stepper>
  </mat-tab>
</mat-tab-group>
