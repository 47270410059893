import {
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";

import { Language } from "src/translation/translation";
@Component({
  selector: "app-file-dropzone-uploading",
  templateUrl: "./file-dropzone-uploading.component.html",
  styleUrls: ["./file-dropzone-uploading.component.scss"],
})
export class FileDropzoneItemUploadingComponent {
  lang: Language = (localStorage.getItem("lang") as Language) || "pt-br";

  @Input() title: string;
  @Input() byteSize: number;
  @Input() progress: number;
  @Output() removeEmitter: EventEmitter<void> = new EventEmitter<void>();

  formatBytes(bytes): string {
    const megabytes = bytes / (1024 * 1024);
    return megabytes.toFixed(2) + ' Mb';
  }

  onClickRemove() {
    this.removeEmitter.emit()
  }

  getTitle(): string {
    return this.title
  }

  getFormattedBytes(): string {
    return this.formatBytes(this.byteSize)
  }

}
