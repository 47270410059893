import { Language } from 'src/translation/translation';
import { TranslatorService } from './../translator.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, Input, OnInit, EventEmitter, Output } from '@angular/core';
import {MatCalendar} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_FORMATS, MatDateFormats} from '@angular/material/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import { WarningService } from '../warning.service';
import { Doc } from 'src/app/helpers/model/docs';
import { DocBackendService } from '../doc.backend.service';


@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker-custom.component.html',
  styleUrls: ['./datepicker-custom.component.scss']
})
export class DatepickerCustomComponent {
  constructor(
    private _warning: WarningService,
    private _docBackendService: DocBackendService,
    private _translatorService: TranslatorService
  ){}

  private readonly lang = localStorage.getItem('lang') as Language || 'pt-br'
  placeholderText = this.translator('Escolha uma data')

  minDate = new Date();
  customHeader = CustomHeader;
  @Input() doc: Doc = null;
  data: string
  @Output() newDate = new EventEmitter<Date>();

  translator(text: string){
    return this._translatorService.translate(text, this.lang)
  }

  validateDate($event: string | Date){
    let date = new Date()
    date.setHours(0,0,0,0)

    let format_date = new Date($event)
    format_date.setHours(23, 59, 59, 999)

    if (format_date < date){
      this._warning.toastrError(this.translator("Por favor insira uma data válida."))
    }
    else{
      this._docBackendService.updateDocument(this.doc.token, {'date_limit_to_sign': format_date}).subscribe(
        (data: Doc) => {
          this.doc = data
          this.newDate.emit(data.date_limit_to_sign)
          this._warning.toastrSuccess(this.translator("Alterações salvas com sucesso."))
        },
        err => {
          console.error(err)
          this._warning.toastrError(this.translator("Ocorreu algum erro para salvar alterações. Por favor, atualize a tela."))
        }
      )
    }
  }
}


@Component({
  selector: 'custom-header',
  styles: [`
    .custom-header {
      display: flex;
      align-items: center;
      padding: 0.5em;
    }

    .custom-header-label {
      flex: 1;
      height: 1em;
      font-weight: 500;
      text-align: center;
    }

    .example-double-arrow .mat-icon {
      margin: -22%;
    }
  `],
  template: `<div class="custom-header">
      <button mat-icon-button (click)="previousClicked('month')">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <span class="custom-header-label">{{periodLabel}}</span>
      <button mat-icon-button (click)="nextClicked('month')">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomHeader<D> implements OnDestroy {
  private destroyed = new Subject<void>();

  constructor(
  private calendar: MatCalendar<D>, private dateAdapter: DateAdapter<D>,
  @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats, cdr: ChangeDetectorRef) {
    calendar.stateChanges
    .pipe(takeUntil(this.destroyed))
    .subscribe(() => cdr.markForCheck());
    dateAdapter.setLocale('BR');
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  get periodLabel() {
    return this.dateAdapter
    .format(this.calendar.activeDate, this.dateFormats.display.monthYearA11yLabel)
    .toLocaleUpperCase();
  }

  previousClicked(mode: 'month') {
    this.calendar.activeDate = mode === 'month'?
      this.dateAdapter.addCalendarMonths(this.calendar.activeDate, -1): this.calendar.activeDate
  }

  nextClicked(mode: 'month') {
    this.calendar.activeDate = mode === 'month'?
        this.dateAdapter.addCalendarMonths(this.calendar.activeDate, 1): this.calendar.activeDate;
  }
}
