import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../app.state';
import {ExtraDoc, Signer} from '../../../../helpers/model/docs';
import {useSelectorSetSignerReducer} from '../../../../app.state.selectors';
import {
  setExtraDocAction,
  setSignerAction
} from '../../../../signers/sign-page/steps-signature/store/setSignerRedux/set-signer-redux.actions';

@Injectable({
  providedIn: 'root'
})
export class SignerPropertiesReduxService {
  constructor(private store: Store<AppState>) {}

  get signer() {
    return this.store.select(useSelectorSetSignerReducer);
  }

  setSigner(signer: Signer) {
    this.store.dispatch(setSignerAction(Object.assign({}, signer, {
      payload: signer
    })));

    return this.signer;
  }

  setExtraDoc(extraDoc: ExtraDoc, index: number) {
    this.store.dispatch(setExtraDocAction(Object.assign({}, extraDoc, {
      payload: extraDoc,
      index: index
    })));
  }
}
