import {Action, createReducer, on} from '@ngrx/store';
import SaveEmailAuthenticationScreensProps from './save-email-unauthorized-model';
import * as actions from './save-email-unauthorized.actions';

export const INITIAL_STATE_SIGNATURE: SaveEmailAuthenticationScreensProps = {
  email: '',
};


export const _saveEmailUnauthorizedReducer = createReducer(INITIAL_STATE_SIGNATURE,
  on(
    actions.setEmailInUnauthorizedScreens,
    (state, { email }) => ({...state, email})
  ),
  on(
    actions.cleanEmailAuthorizatedScreens,
    (state) => ({...state, email: ''})
  )
);

export const saveEmailUnauthorizedReducer = (state: SaveEmailAuthenticationScreensProps, action: Action) => {
  return _saveEmailUnauthorizedReducer(state, action);
};
