import {Injectable} from '@angular/core';
import {concat} from "rxjs";
import {catchError} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../../../auth/auth.service";
import {environment} from "../../../../environments/environment";
import {HandleErrorsRequestService} from "../handle-errors-request-service/handle-errors-request.service";
import {ActionsTrash} from "./trash-documents";

@Injectable({
  providedIn: 'root'
})
export class TrashDocumentsService {
  private readonly doc_api_url = `${environment.API_URL}/docs/`;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private handleErrorsRequestService: HandleErrorsRequestService) {
  }


  trashMultiDocuments(tokens: string[]) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post(`${this.doc_api_url}multi-deleted-docs/`, {doc_token: tokens})
        .pipe(catchError(this.handleErrorsRequestService.getErrorRequest))
    );
  }

  recoverMultiDocuments(tokens: string[]){
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post(`${this.doc_api_url}multi-recover-deleted-docs/`, {doc_token: tokens})
        .pipe(catchError(this.handleErrorsRequestService.getErrorRequest))
    );
  }

  updateRecoverAndExcludeDocument(token: string, action: ActionsTrash, company=null) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(`${this.doc_api_url}deleted-docs/${token}/`, {action, company}).pipe(catchError(this.handleErrorsRequestService.getErrorRequest))
    );
  }
}
