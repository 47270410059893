import {Injectable} from '@angular/core';
import {HelpersGlobalService, SlugTextType} from "../../../helpers-global.service";
import {Language, Locale} from "../../../../translation/translation";
import {TranslatorService} from "../../../components/translator.service";

@Injectable({
  providedIn: 'root'
})
export class TakeDefaultAuthenticationsService {
  private readonly lang: Language = localStorage.getItem('lang') as Language || 'pt-br';
  private readonly locale: Locale = localStorage.getItem('locale') as Locale || 'br';

  readonly types: SlugTextType[] = [];

  getDefaultAuthenticationTypes(): SlugTextType[] {
    let types = [
      {
        slug: 'assinaturaTela',
        text: this.t('Assinatura na tela'),
        icon: 'edit_pencil',
        isSelected: true,
        isDisabled: false,
        tooltip: this.t('Assinatura na tela: Assinatura padrão manuscrita ou digitada feita pelo celular ou computador')
      },
      {
        slug: 'tokenEmail',
        text: this.t('Código por e-mail'),
        icon: 'code-auth',
        isSelected: false,
        isDisabled: false,
        tooltip: this.t('Código por e-mail: Enviaremos um código de confirmação para o e-mail do signatário. Ele deverá inserir o código ao assinar o documento. Quanto mais autenticações avançadas você usar, maior será sua segurança jurídica!')
      },
      {
        slug: 'tokenSms',
        text: this.t('Código por SMS'),
        icon: 'code-auth',
        isSelected: false,
        isDisabled: false,
        tooltip: this.t('Código por SMS: Enviaremos um código de confirmação para o número de celular do signatário. Ele deverá inserir o código ao assinar o documento.Quanto mais autenticações avançadas você usar, maior será sua segurança jurídica!')
      },
      {
        slug: 'tokenWhatsapp',
        text: this.t('Código por WhatsApp'),
        icon: 'code-auth',
        isSelected: false,
        isDisabled: false,
        tooltip: this.t('Código por Whatsapp: Enviaremos um código de confirmação para o Whatsapp do signatário. Ele deverá inserir o código ao assinar o documento. Quanto mais autenticações avançadas você usar, maior será sua segurança jurídica!')
      }
    ];

    if (this.locale === 'br') {
      types = types.concat([{
        slug: 'certificadoDigital',
        text: this.t('Certificado digital'),
        icon: 'digital_certificate',
        isSelected: false,
        isDisabled: false,
        tooltip: 'Certificado digital: O signatário precisa ter um certificado A1 ou A3 para assinar'
      }]);
    }

    return types;
  }

  constructor(private helpersGlobalService: HelpersGlobalService, private translatorService: TranslatorService) {
    if (this.locale === 'br') {
      this.types = this.types.concat([{
        slug: 'certificadoDigital',
        text: this.t('Certificado digital'),
        icon: 'digital_certificate',
        isSelected: false,
        isDisabled: false,
        tooltip: 'Certificado digital: O signatário precisa ter um certificado A1 ou A3 para assinar'
      }]);
    }
    if (this.lang !== 'pt-br') {
      // remove whatsapp option in Latam. TODO: create whatsapp templates in spanish and english
      this.types = this.types.filter(type => type.slug.indexOf('Whatsapp') === -1);
    }
  }

  t(text: string) {
    return this.translatorService.translate(text, this.lang);
  }

}
