import {Component} from '@angular/core';
import {Store} from '@ngrx/store';
import '@tensorflow/tfjs-backend-webgl';
import {NgxImageCompressService} from 'ngx-image-compress';
import {WebcamImage, WebcamInitError} from 'ngx-webcam';
import {Observable, Subject} from 'rxjs';
import {TranslatorService} from 'src/app/components/translator.service';
import {Language} from '../../../translation/translation';
import {UtilsService} from '../../shared/services/utils/utils.service';
import {ValidateImageTensorflowService, ValidatorImageTensorflow} from '../../shared/services/validate-image-tensorflow-service/validate-image-tensorflow.service';
import {LaunchDarkly} from '../../shared/singleton/launch-darkly.singleton';
import {AppState} from './../../app.state';
import {useBase64Photo, useUserAgent} from './../../app.state.selectors';
import {WarningService} from './../warning.service';
import {base64Photo, hideModalWebcam} from './store/PhotoSelfie/photo.actions';
import {disableNextSignStep} from './store/SelfieValidation/selfieValidation.actions';

@Component({
  selector: 'app-modal-camera',
  templateUrl: './modal-camera.component.html',
  styleUrls: ['./modal-camera.component.scss']
})
export class ModalCameraComponent {
  lang: Language = localStorage.getItem('lang') as Language || 'pt-br';
  blazeface = require('@tensorflow-models/blazeface');

  trigger: Subject<void> = new Subject<void>();
  webcamImage: WebcamImage = null;
  modalOpen: boolean;
  loadingImage = false;

  allowCameraSwitch = true;
  videoOptions: MediaTrackConstraints = {
    width: {
      ideal: 1024
    },
    height: {
      ideal: 576
    },

  };

  errors: WebcamInitError[] = [];
  userAgent = {
    isMobile: null,
    isTablet: null,
    isDesktop: null
  };
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
  private tensorFlowFlag: boolean;

  constructor(
    private store: Store<AppState>,
    public warningService: WarningService,
    public utilsService: UtilsService,
    private _imageCompress: NgxImageCompressService,
    private translatorService: TranslatorService,
    private validateImageWithTensorflowService: ValidateImageTensorflowService
  ) {
    const client = LaunchDarkly.getClient();
    client.on('ready', () => {
      this.tensorFlowFlag = client.variation('zexp-17-autenticacao-avancada-selfie', false);
    });

    store.select(useUserAgent).subscribe(({isMobile, isTablet, isDesktop}) => {
      this.userAgent.isDesktop = isDesktop;
      this.userAgent.isMobile = isMobile;
      this.userAgent.isTablet = isTablet;
    });
    store.select(useBase64Photo).subscribe(({selfieModal}) => {
      this.modalOpen = selfieModal;
    });
  }

  takeSnapshot(): void {
    this.trigger.next();
  }

  t(text: string, replacements?: string[]) {
    return this.translatorService.translate(text, this.lang, replacements);
  }

  handleInitError(error: WebcamInitError): void {
    const {mediaStreamError} = error;
    const {name} = mediaStreamError;

    if (mediaStreamError && name === 'NotAllowedError') {
      this.errors.push(error);
    }
  }

  private validateImageWithTensorflow(image: string): Observable<ValidatorImageTensorflow> {
    return this.validateImageWithTensorflowService.validateImage(image);
  }

  private successfulLoadImage(htmlImageElement: HTMLImageElement): Promise<Observable<ValidatorImageTensorflow>> {
    return new Promise((resolve) => {
      htmlImageElement.onload = () => {
        const sourceImage = htmlImageElement.getAttribute('src');
        const responseImageValidate = this.validateImageWithTensorflow(sourceImage);
        resolve(responseImageValidate);
      };
    });
  }

  handleImage(image: WebcamImage): void {
    if (this.tensorFlowFlag === true)
      this.handleImageWithTensorFlowMS(image);
    else
      this.handleImageWithoutTensorFlowMS(image);
  }

  handleImageWithoutTensorFlowMS(image: WebcamImage): void {
    this.webcamImage = image;
    const imageAsDataUrl = image.imageAsDataUrl
    const {imageAsDataUrl: noCompressedImage} = this.webcamImage
    this.loadingImage = true;
    this._imageCompress.compressFile(imageAsDataUrl, 1, 70, 90).then(
      async imageCompressed => {
        const isCompressedImage = imageCompressed ? imageCompressed : noCompressedImage

        const model = await this.blazeface.load();
        const returnTensors = false; // Pass in `true` to get tensors back, rather than values.
        let htmlImageElement = document.createElement('img');
        await htmlImageElement.setAttribute("src", imageAsDataUrl);
        await htmlImageElement.setAttribute("style", "width:300px;height:300px;");
        const predictions = await model.estimateFaces(htmlImageElement, returnTensors);
        this.loadingImage = false;

        if (predictions.length == 0 || predictions.length > 1 || predictions[0].probability < 0.98) {
          const result = this.translatorService.translate("Por favor, tire uma foto mostrando todo o seu rosto, em uma região bem iluminada.", this.lang);
          this.store.dispatch(disableNextSignStep({payload: {disableNextSignStep: true, result: result}}));
          alert(result)
          return;
        }

        this.store.dispatch(base64Photo({payload: isCompressedImage}))
        this.store.dispatch(hideModalWebcam({payload: 'modal-camera'}))
      }
    ).catch((e) => {
        console.log(e)
        this.loadingImage = false;
        this.store.dispatch(base64Photo({payload: noCompressedImage}))
        this.store.dispatch(hideModalWebcam({payload: 'modal-camera'}))
      }
    )
  }

  handleImageWithTensorFlowMS(image: WebcamImage): void {
    this.webcamImage = image;
    const imageAsDataUrl = image.imageAsDataUrl;
    const {imageAsDataUrl: noCompressedImage} = this.webcamImage;
    this.loadingImage = true;
    this._imageCompress.compressFile(imageAsDataUrl, 1, 70, 90).then(
      async imageCompressed => {
        const isCompressedImage = imageCompressed ? imageCompressed : noCompressedImage;

        const htmlImageElement = document.createElement('img');
        await htmlImageElement.setAttribute('src', imageAsDataUrl);
        await htmlImageElement.setAttribute('style', 'width:300px;height:300px;');

        const predictionsImage = await this.successfulLoadImage(htmlImageElement);
        this.store.dispatch(disableNextSignStep({payload: {disableNextSignStep: true, result: '', loading: true}}));
        predictionsImage.subscribe(() => {
          this.store.dispatch(disableNextSignStep({payload: {disableNextSignStep: false, result: ''}}));
        }, error => {
          this.store.dispatch(disableNextSignStep({payload: {disableNextSignStep: true, result: error.result}}));
          if (error.result != "") {
            alert(this.t(error.result));
          }
        });

        this.loadingImage = false;

        this.store.dispatch(base64Photo({payload: isCompressedImage}));
        this.store.dispatch(hideModalWebcam({payload: 'modal-camera'}));
      }
    ).catch((e) => {
        console.log(e);
        this.loadingImage = false;
        this.store.dispatch(base64Photo({payload: noCompressedImage}));
        this.store.dispatch(hideModalWebcam({payload: 'modal-camera'}));
      }
    );
  }

  openModalNotHaveCamera() {
    this.utilsService.openModalNoCameraAvailable('webcam');
    this.closeModal();
  }

  closeModal() {
    this.utilsService.closeModalWebcam('webcam');
  }

  get observableCamera(): Observable<void> {
    return this.trigger.asObservable();
  }

  get nextWebcamObservable(): Observable<string | boolean> {
    return this.nextWebcam.asObservable();
  }

}
