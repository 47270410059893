<div class="zs-content-cursive-signature">
  <div class="note-message">
    <zs-note
      text="Você receberá um código por e-mail para concluir a assinatura e garantir segurança do documento."
      noteType="soft-warning-note"
      size="full-width"
      font="text_label"
    ></zs-note>
  </div>
  <div class="zs-contant-font-name">
    <form class="zs-form" [formGroup]="formGroup">
      <zs-text-input
        label="Nome"
        (keyup)="onChangeName($event)"
        (change)="onChangeName($event)"
        formControlName="requiredText"
      ></zs-text-input>
    </form>
    <app-select-to-input
      [(value)]="selectedFont"
      [options]="optionsFonts"
      classRadius="all-radius"
      [styleFont]="true"
    ></app-select-to-input>
  </div>
  <div class="background-gray-signature-cursive">
    <div class="border-image">
      <div class="container-span-signature-cursive" #textCursiveSignatureEl>
        <span [style.fontFamily]="selectedFont?.value">
        {{ signerName }}
        </span>
      </div>
    </div>
  </div>
</div>


