import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Component({
  selector: 'app-modal-private-files-flag',
  templateUrl: './modal-private-files-flag.component.html',
  styleUrls: ['./modal-private-files-flag.component.scss']
})
export class ModalPrivateFilesFlagComponent {
  @Input() title: string;
  @Input() text: string;
  @Input() subtitle: string;
  @Input() attentionMessage: string;
  @Input() btnTextCancel = 'Cancelar';
  @Input() btnTextConfirm: string;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirm: EventEmitter<void> = new EventEmitter<void>();

  onCloseModal() {
    this.closeModal.emit();
  }

  onConfirm() {
    this.confirm.emit();
  }

  @HostListener('document:click', ['$event.target'])
  clickOutside(target: HTMLElement) {
    const modal = target.closest('.container');
    if (!modal) {
      this.closeModal.emit();
    }
  }
}
