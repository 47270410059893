import {Component} from '@angular/core';
import {Signer} from 'src/app/helpers/model/docs';
import {ConsumerTranslatorService} from 'src/app/shared/services/consumer-translator/consumer-translator.service';
import {SignerPropertiesReduxService} from 'src/app/shared/services/redux/signer-properties-redux/signer-properties-redux.service';

@Component({
  selector: 'app-redirect-styled-link-button',
  templateUrl: './redirect-styled-link-button.component.html',
  styleUrls: ['./redirect-styled-link-button.component.scss']
})
export class RedirectStyledLinkButtonComponent {
  signer: Signer;

  constructor(
    private consumerTranslatorService: ConsumerTranslatorService,
    private signerPropertiesReduxService: SignerPropertiesReduxService
  ) {
    this.signerPropertiesReduxService.signer.subscribe(({signer}) => this.signer = signer);
  }

  getMessageTooltipRedirectLink() {
    return this.t('Navegar para $0', [this.signer.redirect_link]);
  }

  private t(ptText: string, replacements: string[]) {
    return this.consumerTranslatorService.translate(ptText, replacements);
  }

}
