<div id="myModal" class="modal" (click)="closeModal()">
  <div class="modal-content" (click)="preventModalFromClosing($event)">
    <h2>{{ "Adicionar nova pasta" | translate }}</h2>
    <mat-form-field class="app-document-trash">
      <mat-label>{{ "Nome da pasta" | translate }}</mat-label>
      <input [(ngModel)]="newFolderName" (keyup)="cleanNewFolderName()" maxlength="30" type="text" matInput
             placeholder="Ex: Comercial 2021-07" id="new-folder-name-test-id" />
    </mat-form-field>
    <button mat-button mat-raised-button color="primary" (click)="addFolder()"
            [disabled]="loadingAddFolder || newFolderName.length == 0" id="add-new-folder-test-id">
      <mat-icon>add</mat-icon>
      {{ "Adicionar pasta" | translate }}
    </button>
  </div>
</div>
