import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../app.state';
import {
  setDocumentsArray,
  setSignerHeaderLogo,
  setSignerHeaderShow,
  setSignersArray,
  showDocumentButton,
  showSignersButton
} from '@signers/sign-page-header/store/sign-page-header.actions';
import {
  signerFeatureFlag,
  signerFeatureFlagzcre323,
  signerFooterBackButton,
  signerFooterBackButtonClick,
  signerFooterButtonClick,
  signerFooterPrimaryButton,
  signerFooterRefuseButton,
  signerFooterRefuseButtonClick,
  signerFooterShow,
  signerFooterShowNoteMessage, signerRouteBeforeLoading
} from '@signers/sign-page-footer/store/sign-page-footer.actions';
import {DownloadDocument, SignerHeader} from '@signers/sign-page-header/store/sign-page-header.model';

@Injectable({
  providedIn: 'root'
})
export class PageSignerService {

  constructor(private store: Store<AppState>) {
  }

  getHeaderRedux() {
    return this.store.select('signPageHeader');
  }

  setLogo(logo: string) {
    this.store.dispatch(setSignerHeaderLogo({payload: logo}));
  }

  setSigners(signers: SignerHeader[], show: boolean = true) {
    this.store.dispatch(setSignersArray({payload: signers}));
    this.store.dispatch(showSignersButton({payload: show}));
  }
  showSignersButton(show: boolean) {
    this.store.dispatch(showSignersButton({payload: show}));
  }

  setDownloads(doc: any) {
    const docsArray: DownloadDocument[] = [];
    if (doc.original_file) {
      docsArray.push({label: doc.name, url: doc.original_file});
    }
    doc.extra_docs.forEach((extraDoc) => {
      if (extraDoc.original_file) {
        docsArray.push({label: extraDoc.name, url: extraDoc.original_file});
      }
    });
    this.store.dispatch(setDocumentsArray({payload: docsArray}));
    this.store.dispatch(showDocumentButton({payload: true}));
  }

  showDocumentButton(show: boolean) {
    this.store.dispatch(showDocumentButton({payload: show}));
  }

  getFooterRedux() {
    return this.store.select('signPageFooter');
  }

  setPrimaryButton(label: string, loading = false, check = false, disabled = false, showButton = true) {
    this.store.dispatch(signerFooterPrimaryButton({payload: {
        showButton,
        label,
        loading,
        check,
        disabled
      }}));
  }

  setBackButton(showButton = true, disabled = false) {
    this.store.dispatch(signerFooterBackButton({payload: {
        showButton,
        disabled
      }}));
  }

  setRefuseButton(showButton = true, disabled = false) {
    this.store.dispatch(signerFooterRefuseButton({payload: {
        showButton,
        disabled
      }}));
  }

  clickPrimaryButton() {
    this.store.dispatch(signerFooterButtonClick({payload: true}));
  }

  unClickPrimaryButton() {
    this.store.dispatch(signerFooterButtonClick({payload: false}));
  }

  clickBackButton() {
    this.store.dispatch(signerFooterBackButtonClick({payload: true}));
  }

  unClickBackButton() {
    this.store.dispatch(signerFooterBackButtonClick({payload: false}));
  }

  clickRefuseButton() {
    this.store.dispatch(signerFooterRefuseButtonClick({payload: true}));
  }

  unClickRefuseButton() {
    this.store.dispatch(signerFooterRefuseButtonClick({payload: false}));
  }

  showNoteMessage(show: boolean) {
    this.store.dispatch(signerFooterShowNoteMessage({payload: show}));
  }

  setFeatureFlag(featureFlag: boolean) {
    this.store.dispatch(signerFeatureFlag({payload: featureFlag}));
  }

  setFeatureFlagBRApi(zcre323launchDarkly: boolean) {
    this.store.dispatch(signerFeatureFlagzcre323({payload: zcre323launchDarkly}));
  }


  setHeaderShow(show: boolean) {
    this.store.dispatch(setSignerHeaderShow({payload: show}));
  }

  setFooterShow(show: boolean) {
    this.store.dispatch(signerFooterShow({payload: show}));
  }

  setRouteBeforeLoading(route: string) {
    this.store.dispatch(signerRouteBeforeLoading({payload: route}));
  }
  getFeatureToogle(ldFlagControlFlowSignature: boolean, ldFlagControlUrlBROrApi: boolean, createdThrough: string): boolean {
    if (ldFlagControlFlowSignature) {
      if (this.wasCreatedThroughApiOrBr(createdThrough)) {
        return ldFlagControlUrlBROrApi;
      }
    }
    return ldFlagControlFlowSignature;
  }

  private wasCreatedThroughApiOrBr(createdThrough: string): boolean {
    const isApiRequest = createdThrough && createdThrough.includes('api');
    const isBrApp = window.location.href.includes('https://br.app.zapsign.com.br/');
    return isApiRequest || isBrApp;
  }
}
