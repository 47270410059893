import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Language} from 'src/translation/translation';
import {TranslatorService} from '../../translator.service';
import {UserGroupState} from '../store/modal-user-groups.models';

@Component({
  selector: 'app-modal-user-groups-buttons',
  templateUrl: './modal-user-groups-buttons.component.html',
  styleUrls: ['./modal-user-groups-buttons.component.scss']
})
export class ModalUserGroupsButtonsComponent {
  lang: Language = localStorage.getItem('lang') as Language || 'pt-br';

  @Input() state: UserGroupState;
  @Input() closeButton = true;
  @Input() buttonDisabled = true;
  @Output() changeState = new EventEmitter<UserGroupState>();

  constructor(
    private matDialog: MatDialog,
    private translatorService: TranslatorService) { }

  t(ptText: string) {
    return this.translatorService.translate(ptText, this.lang);
  }

  closeModal() {
    this.matDialog.closeAll();
  }

  newGroup() {
    this.changeState.emit(this.state);
  }

  createGroup() {
    this.changeState.emit('new');

  }

  updateGroup() {
    this.changeState.emit('update');
  }

}
