<div class="list-files-successful-item">
   <div class="drag-icon">
    <mat-icon [svgIcon]="dragIcon"></mat-icon>
   </div>
   <span class="index">{{ getIndex() }}</span>
   <span class="ellipsis-text">{{ getTitle() }}</span>
  <div class="list-files-successful-item-actions">
    <zs-button
      typeButton="icon"
      icon="link-external-01"
      matTooltip="{{ 'Abrir documento' | translate }}"
      (click)="onClickOpen()"
    >
    </zs-button>
    <zs-button
      typeButton="icon"
      icon="x-close"
      matTooltip="{{ 'Remover documento' | translate }}"
      (click)="onClickRemove()"
    >
    </zs-button>
  </div>
</div>
