import {AngularCropperjsModule} from 'angular-cropperjs';
import {CommonModule} from '@angular/common';
import {CountdownModule} from 'ngx-countdown';
import {DebounceClickDirective} from '@shared/directives/debounceclick.directive';
import {FileUploadModule} from 'ng2-file-upload';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {IConfig, NgxMaskModule} from 'ngx-mask';
import {LottieModule} from 'ngx-lottie';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatNativeDateModule} from '@angular/material/core';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {NgModule} from '@angular/core';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {NgxCurrencyModule} from 'ngx-currency';
import {NgxImageCompressService} from 'ngx-image-compress';
import {NgxMatIntlTelInputModule} from 'ngx-mat-intl-tel-input';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {QRCodeModule} from 'angularx-qrcode';
import {RouterModule} from '@angular/router';
import {WebcamModule} from 'ngx-webcam';
import { DragDropModule } from '@angular/cdk/drag-drop';

import {
  AccordionComponent,
  ActionsSignatureComponent,
  ActionsDesignSignatureComponent,
  BadgeComponent,
  BetaToogleComponent,
  BtnNewDocumentComponent,
  BulletCounterDocumentsComponent,
  ButtonPhotoComponent,
  ButtonPhotoResultComponent,
  ButtonZSComponent,
  CardInsufficientCreditsComponent,
  ChipComponent,
  ChipNewFeatureComponent,
  CompanySelectComponent,
  CounterDocumentsComponent,
  CursiveSignatureComponent,
  CustomHeader,
  DatepickerCustomComponent,
  DesignSignatureComponent,
  DocFilterDatePickerComponent,
  DocStatusChipComponent,
  DocumentsWaitingComponent,
  DownloadButtonComponent,
  DropzoneComponent,
  EditContactGroupModalComponent,
  ErrorsFormComponent,
  FilesDropzoneComponent,
  FilesDropzoneFileItemComponent,
  FileDropzoneItemUploadingComponent,
  FilterComponent,
  FilterStatusComponent,
  FlashMessageComponent,
  FormUserGroupComponent,
  InstructionsComponent,
  IntegrationAppsComponent,
  LivenessValidatorComponent,
  OldLivenessValidatorComponent,
  LoadingOverlayComponent,
  LoginSignupRightContainerComponent,
  MaintenanceBannerComponent,
  MenuBottomsheetComponent,
  MenuMobileComponent,
  MinhasAssinaturasHeaderComponent,
  ModalActivateAccountComponent,
  ModalAfilliateComponent,
  ModalApiConfirmationComponent,
  ModalAskBusinessSegmentComponent,
  ModalCameraComponent,
  ModalComponent,
  ModalDeleteUsersComponent,
  ModalDocumentsComponent,
  ModalDocumentsOldComponent,
  ModalEditAnswersComponent,
  ModalFreemiumComponent,
  ModalHelperComponent,
  ModalLivenessComponent,
  ModalTopBlueComponent,
  OldModalLivenessComponent,
  ModalLogWebhooksComponent,
  ModalMenuComponent,
  ModalNewDocFromTemplateComponent,
  ModalNewFeatureComponent,
  ModalNewFolderComponent,
  ModalPaguemenosComponent,
  ModalPrivateFilesFlagComponent,
  ModalRemoveComponent,
  ModalSelectComponent,
  ModalTwoFaAuthenticateComponent,
  ModalUnpaidComponent,
  ModalUserGroupListComponent,
  ModalUserGroupsButtonsComponent,
  ModalUserGroupsComponent,
  NewModalEditAnswersComponent,
  NewsletterComponent,
  NewsletterModalComponent,
  NoCameraAvailableComponent,
  NoPermissionCameraComponent,
  NoteComponent,
  ObserversInputComponent,
  ObserversListComponent,
  OldCursiveSignatureComponent,
  OldNoCameraAvailableComponent,
  OldFormUserGroupComponent,
  OldModalNewFolderComponent,
  OldModalUnpaidComponent,
  OldModalUserGroupListComponent,
  OldModalUserGroupsButtonsComponent,
  OldModalUserGroupsComponent,
  OldObserversInputComponent,
  OldPhoneInputComponent,
  OldSelectTimezoneComponent,
  OldSelectTranslationComponent,
  PageDocumentTrashComponent,
  PageSizeSelectComponent,
  PasswordInputZsComponent,
  PdfViewerComponent,
  PerfilSelectComponent,
  PhoneCountryInputComponent,
  PhoneInputZSComponent,
  PinkToastComponent,
  PlansApiListComponent,
  PrefilledComponent,
  ProgressBarComponent,
  ReadyToUseApplicationsComponent,
  RedirectStyledLinkButtonComponent,
  RequestModalComponent,
  RequestModalOldComponent,
  SafePipe,
  ScreenPreventSignersDownloadingSignedDocumentComponent,
  SelectDocumentSigningMethodComponent,
  SelectLanguageComponent,
  SelectTimezoneComponent,
  SelectToInputComponent,
  SelectTranslationComponent,
  SendDocumentSignatureCardComponent,
  SignaturaPadCleanComponent,
  SignaturePadOldComponent,
  SignerInputLinkComponent,
  SignerOutOfOrderComponent,
  SignersDropdownComponent,
  SkeletonComponent,
  SpinnerLoadingComponent,
  SwitchToggleComponent,
  TabbarComponent,
  TabsOptionsSignatureComponent,
  TextInputZSComponent,
  TranslatePipe,
  TrashHeaderComponent,
  TruncatePipe,
  TwoFaAuthComponent,
  UploadInputComponent,
  UploadSignatureComponent,
  UploadSignatureOldComponent,
  UserNavbarComponent,
  UsersLimitWarningComponent,
  VerseOldComponent,
  VerseComponent,
  ZsModalFlowComponent,
  OldDocumentsWaitingComponent,
} from '.';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

export function playerFactory() {
  return import('lottie-web');
}

@NgModule({
  declarations: [
    AccordionComponent,
    ActionsSignatureComponent,
    ActionsDesignSignatureComponent,
    BadgeComponent,
    BetaToogleComponent,
    BtnNewDocumentComponent,
    BulletCounterDocumentsComponent,
    ButtonZSComponent,
    ButtonPhotoComponent,
    ButtonPhotoResultComponent,
    CardInsufficientCreditsComponent,
    ChipComponent,
    ChipNewFeatureComponent,
    CompanySelectComponent,
    CounterDocumentsComponent,
    CursiveSignatureComponent,
    CustomHeader,
    DatepickerCustomComponent,
    DebounceClickDirective,
    DesignSignatureComponent,
    DocFilterDatePickerComponent,
    DocStatusChipComponent,
    DocumentsWaitingComponent,
    DownloadButtonComponent,
    FilesDropzoneComponent,
    FilesDropzoneFileItemComponent,
    FileDropzoneItemUploadingComponent,
    DropzoneComponent,
    EditContactGroupModalComponent,
    ErrorsFormComponent,
    FilterComponent,
    FilterStatusComponent,
    FlashMessageComponent,
    FormUserGroupComponent,
    InstructionsComponent,
    IntegrationAppsComponent,
    LivenessValidatorComponent,
    LoadingOverlayComponent,
    LoginSignupRightContainerComponent,
    MaintenanceBannerComponent,
    MenuBottomsheetComponent,
    MenuMobileComponent,
    ModalDeleteUsersComponent,
    MinhasAssinaturasHeaderComponent,
    ModalComponent,
    ModalActivateAccountComponent,
    ModalAfilliateComponent,
    ModalApiConfirmationComponent,
    ModalAskBusinessSegmentComponent,
    ModalCameraComponent,
    ModalDocumentsOldComponent,
    ModalDeleteUsersComponent,
    ModalDocumentsComponent,
    ModalEditAnswersComponent,
    ModalFreemiumComponent,
    ModalHelperComponent,
    ModalLivenessComponent,
    ModalLogWebhooksComponent,
    ModalMenuComponent,
    ModalNewDocFromTemplateComponent,
    ModalNewFeatureComponent,
    ModalNewFolderComponent,
    ModalPaguemenosComponent,
    ModalPrivateFilesFlagComponent,
    ModalRemoveComponent,
    ModalSelectComponent,
    ModalTopBlueComponent,
    ModalTwoFaAuthenticateComponent,
    ModalUnpaidComponent,
    ModalUnpaidComponent,
    ModalUserGroupListComponent,
    ModalUserGroupsButtonsComponent,
    ModalUserGroupsComponent,
    NewModalEditAnswersComponent,
    NewsletterComponent,
    NewsletterModalComponent,
    NoCameraAvailableComponent,
    NoPermissionCameraComponent,
    NoteComponent,
    ObserversInputComponent,
    ObserversListComponent,
    OldCursiveSignatureComponent,
    OldNoCameraAvailableComponent,
    OldFormUserGroupComponent,
    OldModalNewFolderComponent,
    OldModalUnpaidComponent,
    OldModalUserGroupListComponent,
    OldModalUserGroupsButtonsComponent,
    OldModalUserGroupsComponent,
    OldObserversInputComponent,
    OldPhoneInputComponent,
    PageDocumentTrashComponent,
    PageSizeSelectComponent,
    PasswordInputZsComponent,
    PdfViewerComponent,
    PerfilSelectComponent,
    PhoneCountryInputComponent,
    PhoneInputZSComponent,
    PinkToastComponent,
    PlansApiListComponent,
    PrefilledComponent,
    ProgressBarComponent,
    ReadyToUseApplicationsComponent,
    RedirectStyledLinkButtonComponent,
    RequestModalComponent,
    RequestModalOldComponent,
    SafePipe,
    ScreenPreventSignersDownloadingSignedDocumentComponent,
    SelectDocumentSigningMethodComponent,
    SelectLanguageComponent,
    OldSelectTimezoneComponent,
    SelectToInputComponent,
    OldSelectTranslationComponent,
    SelectTranslationComponent,
    SelectTimezoneComponent,
    SendDocumentSignatureCardComponent,
    SignaturaPadCleanComponent,
    SignaturePadOldComponent,
    SignerInputLinkComponent,
    SignerOutOfOrderComponent,
    SignersDropdownComponent,
    SkeletonComponent,
    SpinnerLoadingComponent,
    SwitchToggleComponent,
    TabbarComponent,
    TabsOptionsSignatureComponent,
    TextInputZSComponent,
    TranslatePipe,
    TrashHeaderComponent,
    TruncatePipe,
    TwoFaAuthComponent,
    UploadInputComponent,
    UploadSignatureComponent,
    UploadSignatureOldComponent,
    UserNavbarComponent,
    UsersLimitWarningComponent,
    VerseComponent,
    VerseOldComponent,
    ZsModalFlowComponent,
    OldDocumentsWaitingComponent,
    OldModalLivenessComponent,
    OldLivenessValidatorComponent,
  ],
  imports: [
    [LottieModule.forRoot({player: playerFactory})],
    AngularCropperjsModule,
    CommonModule,
    CountdownModule,
    DragDropModule,
    FileUploadModule,
    FormsModule,
    HttpClientJsonpModule,
    HttpClientModule,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatStepperModule,
    MatTabsModule,
    MatTooltipModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxCurrencyModule,
    NgxMaskModule.forRoot(options),
    NgxMatIntlTelInputModule,
    PdfViewerModule,
    QRCodeModule,
    ReactiveFormsModule,
    RouterModule,
    WebcamModule,
  ],
  exports: [
    AccordionComponent,
    BadgeComponent,
    BetaToogleComponent,
    ButtonPhotoComponent,
    ButtonPhotoResultComponent,
    ButtonZSComponent,
    CardInsufficientCreditsComponent,
    ChipComponent,
    ChipNewFeatureComponent,
    CounterDocumentsComponent,
    CursiveSignatureComponent,
    CustomHeader,
    DatepickerCustomComponent,
    DebounceClickDirective,
    DesignSignatureComponent,
    DocFilterDatePickerComponent,
    DocStatusChipComponent,
    DocumentsWaitingComponent,
    DownloadButtonComponent,
    DropzoneComponent,
    FilesDropzoneComponent,
    FilesDropzoneFileItemComponent,
    FileDropzoneItemUploadingComponent,
    ErrorsFormComponent,
    FilterComponent,
    FilterStatusComponent,
    IntegrationAppsComponent,
    LivenessValidatorComponent,
    OldLivenessValidatorComponent,
    LoadingOverlayComponent,
    LoginSignupRightContainerComponent,
    MenuBottomsheetComponent,
    MenuMobileComponent,
    MinhasAssinaturasHeaderComponent,
    MaintenanceBannerComponent,
    ModalComponent,
    ModalActivateAccountComponent,
    ModalAfilliateComponent,
    ModalApiConfirmationComponent,
    ModalCameraComponent,
    ModalDocumentsOldComponent,
    ModalDocumentsComponent,
    ModalEditAnswersComponent,
    ModalFreemiumComponent,
    ModalLogWebhooksComponent,
    ModalMenuComponent,
    ModalNewFeatureComponent,
    ModalNewFolderComponent,
    ModalPaguemenosComponent,
    ModalPrivateFilesFlagComponent,
    ModalRemoveComponent,
    ModalTwoFaAuthenticateComponent,
    ModalUnpaidComponent,
    ModalUserGroupListComponent,
    ModalUserGroupsComponent,
    NoCameraAvailableComponent,
    NoteComponent,
    ObserversInputComponent,
    OldCursiveSignatureComponent,
    OldNoCameraAvailableComponent,
    OldModalNewFolderComponent,
    OldObserversInputComponent,
    OldPhoneInputComponent,
    OldSelectTimezoneComponent,
    OldSelectTranslationComponent,
    PageDocumentTrashComponent,
    PageSizeSelectComponent,
    PasswordInputZsComponent,
    PdfViewerComponent,
    PhoneCountryInputComponent,
    PhoneInputZSComponent,
    PlansApiListComponent,
    PrefilledComponent,
    ProgressBarComponent,
    ReadyToUseApplicationsComponent,
    RedirectStyledLinkButtonComponent,
    RequestModalComponent,
    RequestModalOldComponent,
    SafePipe,
    ScreenPreventSignersDownloadingSignedDocumentComponent,
    SelectDocumentSigningMethodComponent,
    SelectLanguageComponent,
    SelectTimezoneComponent,
    SelectToInputComponent,
    SelectTranslationComponent,
    SendDocumentSignatureCardComponent,
    SignaturaPadCleanComponent,
    SignaturePadOldComponent,
    SignerInputLinkComponent,
    SignerOutOfOrderComponent,
    SignersDropdownComponent,
    SkeletonComponent,
    SpinnerLoadingComponent,
    TabbarComponent,
    TabsOptionsSignatureComponent,
    TextInputZSComponent,
    TranslatePipe,
    TruncatePipe,
    TwoFaAuthComponent,
    UploadInputComponent,
    UploadSignatureComponent,
    UserNavbarComponent,
    UsersLimitWarningComponent,
    VerseOldComponent,
    ZsModalFlowComponent,
    OldDocumentsWaitingComponent,
    ModalLivenessComponent,
    OldModalLivenessComponent,
    UploadSignatureOldComponent,
    ModalTopBlueComponent,
  ],
  providers: [
    NgxImageCompressService,
    {
      provide: MatDialogRef,
      useValue: {}
    }
  ]
})

export class ComponentsModule {
}
