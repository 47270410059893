export const reasonCreateAccountData = [
    {id: 'i_just_sign_document', value: 'Eu vou assinar documentos ✍️'},
    {id: 'documents_other_peoples_sign', value: 'Coletar assinaturas de terceiros 🤝'},
    {id: 'i_just_want_see_copy_i_already_signed', value: 'Ver cópias de documentos já assinados 👀'}
];

export const activityData = [
    {id: 'advocacy/legal', value: 'Advocacia/Jurídico'},
    {id: 'autonomous', value: 'Autônomo'},
    {id: 'business', value: 'Comércio'},
    {id: 'consultancy', value: 'Consultoria'},
    {id: 'accounting', value: 'Contabilidade'},
    {id: 'private_education', value: 'Educação privada'},
    {id: 'finance_and_banking', value: 'Financeiro e Seguros'},
    {id: 'real_estate', value: 'Imobiliário'},
    {id: 'industry', value: 'Indústria'},
    {id: 'telecommunications_and_media', value: 'Telecomunicações e mídia'},
    {id: 'marketing_and_advertising', value: 'Marketing e Publicidade'},
    {id: 'hr_and_recruitment', value: 'RH e Recrutamento'},
    {id: 'health', value: 'Saúde'},
    {id: 'technology_software', value: 'Tecnologia, Software'},
    {id: 'tourism_and_leisure', value: 'Turismo e Lazer'},
    {
        id: 'other',
        value: 'Outro ramo não listado'
    }
];

export const documentsPerMonthData = [
    {id: 'up_5_documents_month', value: 'Até 5'},
    {id: 'between_6_and_100_documents_month', value: '6-100'},
    {id: 'between_101_and_500_documents_month', value: '101-500'},
    {id: 'between_500_and_3000_documents_month', value: '500-3.000'},
    {id: 'between_3000_and_10000_documents_month', value: '3.000-10.000'},
    {id: 'over_10000_documents_month', value: '10.000+'}
];

export const howMeetUsData = [
    {id: 'search_engine', value: 'Pesquisa no Google/Bing'},
    {id: 'recommended_by_colleague_or_friend', value: 'Indicações de terceiros'},
    {id: 'social_media', value: 'Redes sociais'},
    {id: 'signing_a_document', value: 'Assinando um documento'},
    {id: 'other', value: 'Outro'}
]
