import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {MenuModel} from '@client/page-single-document/page-single-document.model';
type TextEllipsisType = 'large' | 'medium';

@Component({
  selector: 'app-modal-menu',
  templateUrl: './modal-menu.component.html',
  styleUrls: ['./modal-menu.component.scss']
})
export class ModalMenuComponent {

  @Output() option: EventEmitter<void> = new EventEmitter<void>();
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();

  @Input() textOption: string;
  @Input() iconOption: string;

  @Input() itens: MenuModel[];
  @Input() modalOption: boolean;
  @Input() textEllipsis: TextEllipsisType = 'large';
  index: string = this.makeRandom();
  isFirstEvent = true;

  makeRandom(lengthOfCode: number = 5) {
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
    let text = '';
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  // TODO: Melhorar o fechamento automatico das modais (Linhas 33 - 36 -> controla devido ao lado que a modal aparece)"
  @HostListener('document:click', ['$event'])
  clickOutside($event: PointerEvent) {
    if (this.isFirstEvent) {
      this.isFirstEvent = false;
      return;
    }
    const isSvg = $event.target instanceof SVGElement;
    const isContactPage = document.getElementById('contact-modal');
    const isUserGroupPage = document.getElementById('user-group-modal');
    const btnUserGroup = document.getElementById('btn-modal-menu-group-users')
    if ((isContactPage || isUserGroupPage) && isSvg && btnUserGroup) {
      return;
    }
    const XClick = $event.x;
    const YClick = $event.y;
    const selfElement = document.getElementById(this.index);
    const rect = selfElement.getClientRects()[0];
    const validXClick = XClick >= rect.x && XClick <= rect.x + rect.width;
    const validYClick = YClick >= rect.y - 36 && YClick <= rect.y + rect.height;
    const isClickOutside = !(validXClick && validYClick);
    if (isClickOutside) {
      this.closeModal.emit();
    }
  }
}
