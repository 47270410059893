import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../helpers/model/docs';
import {DocBackendService} from '../doc.backend.service';
import {WarningService} from '../warning.service';
import {TranslatorService} from '../translator.service';
import {Language} from '../../../translation/translation';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FoldersService} from '../../client/folders.service';
import {AuthBackendService} from '../../auth/backend.service';
import {SelectOptions} from '../../shared/interfaces/selectInterface';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-modal-new-folder',
  templateUrl: './modal-new-folder.component.html',
  styleUrls: ['./modal-new-folder.component.scss']
})
export class ModalNewFolderComponent implements OnInit {

  @Input() parentFolder: Folder | null = null;
  @Input() editFolder: Folder | null = null;
  @Input() folderModeloDinamico: Folder | null = null;
  @Input() folderType = 'docs';
  @Input() openSecret = false;
  @Output() closeModalEmitter = new EventEmitter<any>();
  @Output() addFolderEmmiter = new EventEmitter<any>();
  idCompany = +localStorage.getItem('companyId');
  userEmail = localStorage.getItem('user_email');
  userEmailSelect: SelectOptions = {
    name: this.userEmail,
    value: this.userEmail
  };
  canBeSecret = true;
  lang: Language = localStorage.getItem('lang') as Language || 'pt-br';
  loadingAddFolder = false;
  secretFolder = false;
  folderMembership: SelectOptions[] = [];
  memberships: SelectOptions[];
  availableMembershipsToAdd: SelectOptions[] = [];
  userChoosed: any;
  secretFolderFirstTime = false;
  formGroup = new FormGroup({
    title: new FormControl('', Validators.required)
  });
  selectedUser: SelectOptions = {
    name: '',
    value: ''
  };
  emailToAdd: string[] = [];
  emailToDelete: string[] = [];
  userChangeModal = false;
  parentFolderToken: string;

  constructor(
    private docBackend: DocBackendService,
    private warningService: WarningService,
    private _translationService: TranslatorService,
    private folderService: FoldersService,
    private authService: AuthBackendService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    if (this.thisFolderCanNotBeSecret) {
      this.canBeSecret = false;
    }
    if (this.editFolder) {
      this.formGroup.controls.title.setValue(this.editFolder.name);
      if (this.editFolder.secret) {
        this.secretFolder = true;
        this.secretFolderFirstTime = true;
        this.getFolderMembership(this.editFolder.token);
      } else if (this.openSecret && this.canBeSecret) {
        this.secretFolder = true;
        this.changeSecretFolder(true);
      }
    }
  }

  t(ptText: string, replacements?: string[]) {
    return this._translationService.translate(ptText, this.lang, replacements);
  }

  closeModal() {
    this.closeModalEmitter.emit();
  }

  preventModalFromClosing($event) {
    $event.stopPropagation();
  }

  cleanNewFolderName() {
    this.userChangeModal = true;
    const folderName = this.formGroup.get('title').value;
    if (folderName.indexOf('/') !== -1 || folderName.indexOf('\\') !== -1) {
      this.warningService.toastrError(this.t('Não é possível utilizar / ou \\ em nome de pastas.'));
      this.formGroup.controls.title.setValue(folderName.replace(/\//g, '').replace(/\\/g, ''));
    }
  }
  addMemberToSecretFolder($event) {
    this.userChangeModal = true;
    this.folderMembership.push($event);
    this.addEmailToAdd($event.value);
    this.selectedUser = {
      name: '',
      value: ''
    };
    this.setAvailableMembershipsToAdd();
  }

  getFolderMembership(token: string = '') {
    this.folderMembership.push(this.userEmailSelect);
    this.getCompanyMembership(this.idCompany);
    this.folderService.getFolderMembership(token).subscribe(
      memberships => {
        this.folderMembership = memberships.map(membership => {
          this.addEmailToAdd(membership.user.email);
          return {
            name: membership.user.email,
            value: membership.user.email
          };
        });
        if (this.folderMembership.map(fMembership => fMembership.value).indexOf(this.userEmail) === -1) {
          this.folderMembership.unshift(this.userEmailSelect);
        }
        this.getCompanyMembership(this.idCompany);
      }
    );
  }

  getCompanyMembership(companyId: number = 0) {
    this.authService.getCompanyMemberships(companyId).subscribe(
      data => {
        this.memberships = data.map(membership => {
          return {
            name: membership.user.email,
            value: membership.user.email
          };
        });
        this.setAvailableMembershipsToAdd();
      }
    );
  }

  setAvailableMembershipsToAdd() {
    this.availableMembershipsToAdd = this.memberships.filter((membership: SelectOptions) => {
      return this.folderMembership.map(fMembership => fMembership.value).indexOf(membership.value) === -1;
    });
    this.userChoosed = null;
  }

  addFolder() {
    if (this.disableButton) {
      return;
    }
    const folderName = this.formGroup.get('title').value;

    const params = {
      name: folderName,
      type: this.folderType,
      secret: this.secretFolder,
      deleted_users: this.emailToDelete,
      add_users: this.emailToAdd
    } as any;

    if (this.parentFolder !== null) {
      params.parent_folder_token = this.parentFolder.token;
    } else if (this.folderModeloDinamico !== null) {
      params.parent_folder_token = this.folderModeloDinamico;
    }
    if (this.editFolder) {
      params.token = this.editFolder.token;
    }
    this.docBackend.newFolder(params).subscribe(
      (folder) => {
        this.warningService.toastrSuccess(this.t('Alterações salvas com sucesso.'));
        this.addFolderEmmiter.emit(folder);
      },
      err => {
        console.error(err);
        this.warningService.toastrError(this.t('Ocorreu algum problema. Tente atualizar a tela'));
        this.loadingAddFolder = false;
      }
    );
  }

  get disableButton(): boolean {
    return this.formGroup.get('title').value.length === 0 || this.loadingAddFolder || !this.userChangeModal;
  }

  get thisFolderCanNotBeSecret(): boolean {
    if (this.editFolder && this.editFolder.can_be_secret) {
      return false
    }
    if (this.parentFolder) {
      if (this.parentFolder.can_be_secret) {
        if (this.parentFolder.parent_folder !== null) {
          return true;
        }
      } else {
        return true;
      }
    }
    return false;
  }

  changeSecretFolder(checked: boolean) {
    this.userChangeModal = true;
    if (checked) {
      if (!this.secretFolderFirstTime) {
        this.secretFolderFirstTime = true;
        if (this.parentFolder) {
          if(!this.parentFolder.token) {
            this.parentFolderToken = this.route.snapshot.queryParams.pasta;
            this.getFolderMembership(this.parentFolderToken);
          } else {
            this.getFolderMembership(this.parentFolder.token)
          }
        } else if (this.editFolder && this.editFolder.secret) {
          this.getFolderMembership(this.editFolder.token);
        } else {
          this.folderMembership.push(this.userEmailSelect);
          this.addEmailToAdd(this.userEmail);
          this.getCompanyMembership(this.idCompany);
        }
      }
    }
  }

  removeMembership(email: string) {
    this.userChangeModal = true;
    this.addEmailToDelete(email);
    const index = this.folderMembership.map(fMembership => fMembership.value).indexOf(email);
    this.folderMembership.splice(index, 1);
    this.setAvailableMembershipsToAdd();
  }

  addEmailToAdd(email: string) {
    const index = this.emailToDelete.indexOf(email);
    if (index !== -1) {
      this.emailToDelete.splice(index, 1);
    }
    this.emailToAdd.push(email);
  }

  addEmailToDelete(email: string) {
    const index = this.emailToAdd.indexOf(email);
    if (index !== -1) {
      this.emailToAdd.splice(index, 1);
    }
    this.emailToDelete.push(email);
  }
}
