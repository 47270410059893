<div *ngFor="let participant of model.participants" class="test-container-prefilled">
  <div *ngFor="let input of participant.inputs">
    <mat-form-field appearance="fill"
      *ngIf="input.input_type === 'signer_fullname' || input.input_type === 'input'" style="width:100%;max-width:438px;">
        <mat-label>{{input.label}}</mat-label>
        <input [(ngModel)]="input.pre_filled_answer" type="text" matInput [placeholder]="'Digite aqui...' | translate"
        (keyup)="_updatePreFilledLink()">
    </mat-form-field>
      <div *ngIf="input.input_type === 'radio'">
          <mat-label>{{input.label}}</mat-label>
          <mat-radio-group [(ngModel)]="input.pre_filled_answer" class="radio-group" (change)="_updatePreFilledLink()">
              <mat-radio-button *ngFor="let option of input.options.split(';')"
              [value]="option" class="radio-button">
                  {{option}}
              </mat-radio-button>
          </mat-radio-group>
      </div>
      <div *ngIf="input.input_type === 'checklist'">
        <mat-label>{{input.label}}</mat-label>
        <div class="checklist-wrapper" id="checklist-wrapper-prefilled-{{input.id}}">
          <p *ngFor="let option of input.options.split(';')" >
            <mat-checkbox (change)="handleChecklistChange(input)" disabled> <!-- TODO: depois de resolver a parte do .ts, é só tirar o disabled aqui -->
              {{option}}
            </mat-checkbox>
          </p>
        </div>
        <p style="color:red;padding-bottom:16px;"><small>{{'Ainda não é possível pré-preencher caixas de seleção. Em breve!'|translate}}</small></p>
      </div>
      <div *ngIf="input.input_type === 'cpf'">
        <mat-form-field appearance="fill" style="width:100%;max-width:438px;">
          <mat-label>{{input.label}}</mat-label>
          <input [(ngModel)]="input.pre_filled_answer" type="text" name="cpf" matInput
          mask="000.000.000-00" [dropSpecialCharacters]="true" (change)="_updatePreFilledLink()">
        </mat-form-field>
      </div>
      <div *ngIf="input.input_type === 'cnpj'">
        <mat-form-field appearance="fill" class="max-width-container">
          <mat-label>{{input.label}}</mat-label>
          <input [(ngModel)]="input.pre_filled_answer" type="text" name="cnpj" matInput
          mask="00.000.000/0000-00" [dropSpecialCharacters]="true" (change)="_updatePreFilledLink()">
        </mat-form-field>
      </div>
      <div *ngIf="input.input_type === 'cep'">
        <mat-form-field appearance="fill" class="max-width-container">
          <mat-label>{{input.label}}</mat-label>
          <input [(ngModel)]="input.pre_filled_answer" type="text" name="cep" matInput
          mask="00000-000" [dropSpecialCharacters]="true" (keyup)="_updatePreFilledLink()">
        </mat-form-field>
      </div>
      <div *ngIf="input.input_type === 'date'">
        <mat-form-field appearance="fill" class="max-width-container">
          <mat-label>{{input.label}}</mat-label>
          <input [(ngModel)]="input.pre_filled_answer"
          type="text" name="date" #dateRef="ngModel" [pattern]="datePattern" matInput
          (keyup)="_updatePreFilledLink()"
          mask="00/00/0000" [dropSpecialCharacters]="false">
          <mat-error *ngIf="dateRef.errors">{{'Data inválida.' | translate}}</mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="input.input_type === 'dinheiro'">
        <mat-form-field appearance="fill" class="max-width-container">
          <mat-label>{{input.label}}</mat-label>
          <input [(ngModel)]="input.pre_filled_answer"
             matInput
             type="text"
             currencyMask
             [options]="{ prefix: convertNumberToCurrencyService.takeSymbolCurrencyMoney(), thousands: '.', decimal: ',', align: 'left' }"
             (keyup)="_updatePreFilledLink()"
             >
        </mat-form-field>
      </div>
      <div *ngIf="input.input_type === 'email'">
        <mat-form-field appearance="fill" class="max-width-container">
          <mat-label>{{input.label}}</mat-label>
          <input [(ngModel)]="input.pre_filled_answer" type="text" name="email" #emailRef="ngModel" [pattern]="emailPattern" matInput placeholder="Email"
          (keyup)="_updatePreFilledLink()">
          <mat-error *ngIf="emailRef.errors">{{'E-mail inválido.' | translate}}</mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="input.input_type === 'phone_br'">
        <mat-form-field appearance="fill" class="max-width-container">
          <mat-label>{{input.label}}</mat-label>
          <input
            [(ngModel)]="input.pre_filled_answer"
            type="tel" matInput
            mask='(00) 00000-0009' (keyup)="_updatePreFilledLink()" [dropSpecialCharacters]="true" placeholder="(xx) xxxxx-xxxx" autocomplete="tel">
        </mat-form-field>
      </div>

  </div>
</div>
