import {Component, EventEmitter, Input, Output} from '@angular/core';
import {HelpersGlobalService} from 'src/app/helpers-global.service';
import {Language} from '../../../translation/translation';
import {Model} from '../../client/page-single-modelo/page-single-modelo.model';
import {ConvertNumberToCurrencyService} from '../../shared/services/convert-number-to-currency/convert-number-to-currency.service';


@Component({
  selector: 'app-prefilled',
  templateUrl: './prefilled.component.html',
  styleUrls: ['./prefilled.component.scss']
})
export class PrefilledComponent {
  @Input() model: Model;
  @Output() updatePreFilledLink: EventEmitter<boolean> = new EventEmitter();
  private readonly lang = localStorage.getItem('lang') as Language || 'pt-br';
  datePattern = this.helpersGlobalService.datePattern;
  emailPattern = this.helpersGlobalService.emailPattern;

  constructor(
    public convertNumberToCurrencyService: ConvertNumberToCurrencyService,
    private helpersGlobalService: HelpersGlobalService
  ) {}

  _updatePreFilledLink() {
    this.updatePreFilledLink.emit(true);
  }


  handleChecklistChange(input) {
    const inputs = document.querySelectorAll(`#checklist-wrapper-prefilled-${input.id} input`) as any as HTMLInputElement[];
    const options = input.options.split(',');
    input.pre_filled_answer = options.map((option, i: number) => {
      if (inputs[i].checked) {
        return option;
      } else {
        return '';
      }
    }).filter(
      answer => answer !== ''
    ).join(', ');
    this._updatePreFilledLink();

    // TODO: não habilitamos essa opção ainda no frontend porque precisa mostrar as opções selecionadas ao signatário...
  }
}
