import Chance from 'chance';
export function getCurrentUserEmail(useEmailFake = false) {
  let userEmail = localStorage.getItem('user_email');
  if (!userEmail && useEmailFake) {
    const chance = new Chance();
    userEmail = chance.email();
    localStorage.setItem('user_email', userEmail);
  }
  return userEmail;
}
