import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Store} from '@ngrx/store';
import {NgxImageCompressService} from 'ngx-image-compress';
import {WebcamImage, WebcamInitError} from 'ngx-webcam';
import {Observable, Subject} from 'rxjs';
import {AppState} from 'src/app/app.state';
import {UtilsService} from '../../../shared/services/utils/utils.service';
import {usePhotoDocument, useUserAgent} from './../../../app.state.selectors';
import {WarningService} from './../../warning.service';
import {PhotoDocumentBase64Front} from './../store/PhotoDocument/photo-document.action';

@Component({
  selector: 'app-modal-documents-old',
  templateUrl: './modal-documents-old.component.html',
  styleUrls: ['../modal-camera.component.scss', './square.component.scss']
})
export class ModalDocumentsOldComponent {

  trigger: Subject<void> = new Subject<void>();
  webcamImagePhotoDocument: WebcamImage = null

  allowCameraSwitch = true
  modalOpen: boolean = false
  multipleWebcamsAvailable = false
  videoOptions: MediaTrackConstraints = {
    width: {
      ideal: 1024
    },
    height: {
      ideal: 576
    }
  }

  errors: WebcamInitError[] = []
  userAgent = {
    isMobile: null,
    isTablet: null,
    isDesktop: null
  }

  @Input() comeFromUploadSignature = false;
  @Output() emmitHandleImageSignature = new EventEmitter<boolean>();
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
  deviceId;

  constructor(
    private store: Store<AppState>,
    public warningService: WarningService,
    public utilsService: UtilsService,
    private _imageCompress: NgxImageCompressService
    ) {
    store.select(useUserAgent).subscribe(({ isMobile, isTablet, isDesktop }) => {
      this.userAgent.isDesktop = isDesktop
      this.userAgent.isMobile = isMobile
      this.userAgent.isTablet = isTablet
    })
    store.select(usePhotoDocument).subscribe(({ viewModalDocumentFront }) =>
      this.modalOpen = viewModalDocumentFront
    )
  }

  closeModal(){
    this.utilsService.closeModalDocumentFront('modal-document-front')
  }
  openModalNotHaveCamera(){
    this.utilsService.openModalNoCameraAvailable('modal-document-front')
    this.closeModal()
  }
  takeSnapshot(): void {
    this.trigger.next();
    this.closeModal()
  }

  handleInitError(error: WebcamInitError): void {
    const { mediaStreamError } = error;
    const { name } = mediaStreamError;

    if (mediaStreamError && name === 'NotAllowedError') {
      this.errors.push(error)
    }
  }

  showNextWebcam(dirOrDeviceId: any): void {
    this.nextWebcam.next(dirOrDeviceId);
  }

  handleImage(image: WebcamImage): void {
    this.webcamImagePhotoDocument = image;
    const imageAsDataUrl = image.imageAsDataUrl
    const { imageAsDataUrl: noCompressedImage } = this.webcamImagePhotoDocument

    this._imageCompress.compressFile(imageAsDataUrl, 1, 70, 100).then(
      imageCompressed => {
      const isCompressedImage = imageCompressed ? imageCompressed : noCompressedImage
      this.store.dispatch(new PhotoDocumentBase64Front(isCompressedImage))
      console.warn(
        `(Doc Front) Antes da compressão -> ${this._imageCompress.byteCount(noCompressedImage)} Bytes\nDepois da compressão -> ${this._imageCompress.byteCount(imageCompressed)} Bytes`
      )
        if (this.comeFromUploadSignature) {
          this.emmitHandleImageSignature.emit(true);
        }
    }).catch(() => {
        this.store.dispatch(new PhotoDocumentBase64Front(noCompressedImage));
        if (this.comeFromUploadSignature) {
          this.emmitHandleImageSignature.emit(true);
        }})
  }
  get observableCamera(): Observable<void> {
    return this.trigger.asObservable();
  }

  get nextWebcamObservable(): Observable<string | boolean> {
    return this.nextWebcam.asObservable();
  }

  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }
}
