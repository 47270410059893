import { CookieService } from '../cookie.service';
import { MixpanelService } from 'src/app/shared/services/mixpanel.service';
import {AuthBackendService} from "../../auth/backend.service";
import {Component, OnInit} from '@angular/core';
import {Language} from "../../../translation/translation";
import {Router} from "@angular/router";
import {TranslatorService} from "../translator.service";
import {
  MODAL_PAGUEMENOS_DELAY_MILISECONDS_TO_OPEN,
  MODAL_PAGUEMENOS_OPENED_ANY_TIME_COOKIE_NAME,
  MODAL_PAGUEMENOS_OPENED_ANY_TIME_HOUR_COOKIE_EXPIRATION,
} from './constants';

@Component({
  selector: 'app-modal-paguemenos',
  templateUrl: './modal-paguemenos.component.html',
  styleUrls: ['./modal-paguemenos.component.scss']
})
export class ModalPaguemenosComponent implements OnInit {

  private lang: Language = (localStorage.getItem('lang') as Language) || 'pt-br';
  modalIsOpened: boolean;

  constructor(
    private _translationService: TranslatorService,
    private authService: AuthBackendService,
    private mixpanelService: MixpanelService,
    private router: Router,
    private cookieService: CookieService,
  ) {}

  openedAnyTime = false

  ngOnInit(): void {
    this.openedAnyTime = this.cookieService.existsCookie(MODAL_PAGUEMENOS_OPENED_ANY_TIME_COOKIE_NAME);

    if (!this.openedAnyTime) {

      this.cookieService.setCookie(
        MODAL_PAGUEMENOS_OPENED_ANY_TIME_COOKIE_NAME,
        JSON.stringify(true),
        MODAL_PAGUEMENOS_OPENED_ANY_TIME_HOUR_COOKIE_EXPIRATION,
        '/'
      )

      setTimeout(() => {
        this.modalIsOpened = true
      }, MODAL_PAGUEMENOS_DELAY_MILISECONDS_TO_OPEN)

      return
    }

  }

  t(ptText: string) {
    return this._translationService.translate(ptText, this.lang)
  }

  goToPricePage() {
    const extrasPoperties = {queryParams: {tab: 'plans', codigo: 'paguemenosagora'}};
    this.router.navigate(['conta', 'configuracoes', 'plans'], extrasPoperties);
    this.mixpanelService
      .sendEventMixpanel('Modal paguemenos:', {click_modal_099: "quero_desconto"})
      .subscribe();
  }

  disableModal() {
    this.modalIsOpened = false
    this.authService.disableCouponModal().subscribe()
    this.mixpanelService
      .sendEventMixpanel('Modal paguemenos:', {click_modal_099: "não_quero_desconto"})
      .subscribe();
  }
}
