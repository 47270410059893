import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {ConsumerTranslatorService} from '../../../shared/services/consumer-translator/consumer-translator.service';
import {WarningService} from '../../warning.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'zs-upload-input',
  templateUrl: './upload-input.component.html',
  styleUrls: ['./upload-input.component.scss']
})
export class UploadInputComponent {

  @ViewChild('inputShow') inputShow: ElementRef;
  @ViewChild('inputHidden') inputHidden: ElementRef;
  @Input() fileName: string = null;
  @Input() required: boolean;
  @Input() typesAccepted: string = '.';
  @Output() changefileToUpload = new EventEmitter();
  formGroup = new FormGroup({
    vanilla: new FormControl({value: '', disabled: true})
  });

  constructor(
    private warningService: WarningService,
    private t: ConsumerTranslatorService,
  ) { }


  handleFileChangeInUploadField($event, isDroop = false) {

    // cleaning
    let fileToUpload: File | null;

    try {
      fileToUpload = this.getFirstFile($event, isDroop);
    } catch (e) {
      this.warningService.toastrError(this.t.translate(e));
      return;
    }

    if (fileToUpload && fileToUpload.size > 10000000) {
      this.warningService.toastrError(this.t.translate('O arquivo não pode ter mais de 10mb. Por favor, suba uma versão reduzida.'));
      $event.target.value = '';
      return;
    }

    this.fileName = fileToUpload?.name ? fileToUpload?.name : null;

    // setting uploadField values
    this.formGroup.get('vanilla').setValue(fileToUpload?.name);
    this.changefileToUpload.emit(fileToUpload);
  }

  getFirstFile($event, isDroop = false) {
    let files = [];
    if (!isDroop) {
      files = $event.target.files;
    } else {
      files = $event.dataTransfer.files;
    }
    if (files.length > 1) {
      throw new Error('Não é possível enviar mais que um arquivo');
    }
    return files[0];
  }

  triggerFileUpload() {
    if (!this.fileName) {
      this.inputHidden.nativeElement.click();
    }
  }

  cleanUpload($event) {
    $event.stopPropagation();
    $event.preventDefault();
    this.fileName = null;
    this.changefileToUpload.emit(null);
  }

  onDrop($event: DragEvent) {
    $event.preventDefault();
    this.inputShow.nativeElement.classList.remove('is-dragover');
    if (!this.fileName) {
      this.handleFileChangeInUploadField($event, true);
    }
  }

  onDragOver($event: DragEvent) {
    $event.stopPropagation();
    $event.preventDefault();
    this.inputShow.nativeElement.classList.add('is-dragover');
  }

  onDragLeave($event: DragEvent) {
    $event.stopPropagation();
    $event.preventDefault();
    this.inputShow.nativeElement.classList.remove('is-dragover');
  }
}
