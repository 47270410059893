import { Action } from '@ngrx/store';

export enum PhotoDocumentsActionTypes {
  BASE64_PHOTO_DOCUMENT_FRONT = 'BASE64_PHOTO_DOCUMENT_FRONT',
  BASE64_PHOTO_DOCUMENT_VERSE = 'BASE64_PHOTO_DOCUMENT_VERSE',

  HIDE_MODAL_DOCUMENT_FRONT = 'HIDE_MODAL_DOCUMENT_FRONT',
  VIEW_MODAL_DOCUMENT_FRONT = 'VIEW_MODAL_DOCUMENT_FRONT',

  HIDE_MODAL_DOCUMENT_VERSE = 'HIDE_MODAL_DOCUMENT_VERSE',
  VIEW_MODAL_DOCUMENT_VERSE = 'VIEW_MODAL_DOCUMENT_VERSE'


}
export class PhotoDocumentBase64Front implements Action {
  readonly type = PhotoDocumentsActionTypes.BASE64_PHOTO_DOCUMENT_FRONT;
  constructor(public payload: string) {}
}
export class PhotoDocumentBase64Verse implements Action {
  readonly type = PhotoDocumentsActionTypes.BASE64_PHOTO_DOCUMENT_VERSE;
  constructor(public payload: string) {}
}

export class HideModalDocumentFront implements Action {
  readonly type = PhotoDocumentsActionTypes.HIDE_MODAL_DOCUMENT_FRONT;
  constructor(public payload: string) {}
}
export class ViewModalDocumentFront implements Action {
  readonly type = PhotoDocumentsActionTypes.VIEW_MODAL_DOCUMENT_FRONT;
  constructor(public payload: string) {}
}

export class HideModalDocumentVerse implements Action {
  readonly type = PhotoDocumentsActionTypes.HIDE_MODAL_DOCUMENT_VERSE;
  constructor(public payload: string) {}
}
export class ViewModalDocumentVerse implements Action {
  readonly type = PhotoDocumentsActionTypes.VIEW_MODAL_DOCUMENT_VERSE;
  constructor(public payload: string) {}
}



export type ActionsPhotoDocument =
PhotoDocumentBase64Front | PhotoDocumentBase64Verse | HideModalDocumentFront |
ViewModalDocumentFront | HideModalDocumentVerse | ViewModalDocumentVerse;
