import { NotaFiscalEletronica } from "./../client/nfe-modal/nfe-modal.component.model";

import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";

import { concat, Observable, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";

import { environment } from "@env/environment";
import { AuthService } from "../auth/auth.service";
import { DataContainerService } from "../client/data-container.service";
import { SubscriptionProps } from "../client/settings-payment/settings-payment.model";
import { User } from "../helpers/model/user";
import { Router } from "@angular/router";
import { Language } from "src/translation/translation";
import { TranslatorService } from "./translator.service";
import { CancelSubscriptionQ, CancelSubscriptionR, UpdateCancelledSubscriptionQ, UpdateCancelledSubscriptionR } from "@shared/types/Subscription";

@Injectable({
  providedIn: "root"
})
export class PagamentosBackendService {
  lang: Language = (localStorage.getItem("lang") as Language) || "pt-br";

  api_url: string = environment.API_URL;
  pagamentos_api_url: string = this.api_url + "/pagamentos";
  user_api_url: string = this.api_url + "/user";

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private dataContainer: DataContainerService,
    private router: Router,
    private _translationService: TranslatorService
  ) {}

  t(pt_text: string) {
    return this._translationService.translate(pt_text, this.lang);
  }

  getActivePlans(secret_code: string = "") {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http
        .get<any>(`${this.pagamentos_api_url}/plans/?codigo=${secret_code}`)
        .pipe(catchError(this.handleError))
    );
  }

  getPlansByType(type: string) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http
        .get<any>(`${this.pagamentos_api_url}/plans/${type}`)
        .pipe(catchError(this.handleError))
    );
  }

  getMySubscriptions() {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http
        .get<any>(this.pagamentos_api_url + "/subscriptions/")
        .pipe(catchError(this.handleError))
    );
  }

  updateBillingInfo(params: any) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http
        .post<any>(this.pagamentos_api_url + "/billing_info/", params)
        .pipe(catchError(this.handleError))
    );
  }

  getPagarMeSubscriptions(providerId: number) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http
        .get<any>(this.pagamentos_api_url + "/pagarme_billing_info/" + providerId + "/")
        .pipe(catchError(this.handleError))
    );
  }

  getBillingInfo(company_id: number) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http
        .get<any>(this.pagamentos_api_url + "/billing-info/" + company_id + "/")
        .pipe(catchError(this.handleError))
    );
  }

  assinarPlano(company_id: number, plan_id: number, params: any) {
    return concat(
      this.authService.getV3Captcha(),
      this.authService.refreshAccessTokenIfNeeded(),
      this.http
        .post<any>(
          this.pagamentos_api_url + "/company/" + company_id + "/plans/" + plan_id + "/subscribe/",
          params
        )
        .pipe(catchError(this.handleError))
    );
  }

  getPaywall(company_id: number) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.get<any>(this.pagamentos_api_url + "/paywall/" + company_id + "/").pipe(
        catchError(this.handleError),
        tap(paywall => {
          this.dataContainer.refreshPaywall(paywall);
        })
      )
    );
  }

  amIAllowedToUseTheCurrentCompany() {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.get<any>(this.user_api_url + "/paywall-user-company/me/")
    );
  }

  getUsersAllowedToUseTheCurrentCompany(): Observable<User[]> {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.get<any>(this.user_api_url + "/paywall-user-company/users/")
    );
  }

  doIHaveAccessToCompany() {
    this.amIAllowedToUseTheCurrentCompany().subscribe(
      data => {
        //great!
      },
      err => {
        console.error(err);
        if (err.status == 402) {
          alert(
            this.t(
              "Esta organização ultrapassou o limite de usuários. Por favor, fale com o proprietário da conta para atualizar o plano ou contratar usuários adicionais."
            )
          );
          this.router.navigate(["conta", "escolha-organizacao"]);
        }
      }
    );
  }

  postAffiliateComission(params: {
    subscription_id: number;
    comission_amount: number;
    currency: string;
    affiliate_name: string;
    affiliate_uid: string;
  }) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http
        .post<any>(this.pagamentos_api_url + "/affiliate_comission/", params)
        .pipe(catchError(this.handleError))
    );
  }

  subscriptionChangePlan(subs_id: number, new_plan_id: number) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http
        .post<any>(this.pagamentos_api_url + "/subscription-change-plan/", { subs_id, new_plan_id })
        .pipe(catchError(this.handleError))
    );
  }

  updateSubscription(params: UpdateCancelledSubscriptionQ) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http
        .patch<UpdateCancelledSubscriptionR>(this.pagamentos_api_url + "/subscriptions/" + params.id + '/', params)
        .pipe(catchError(this.handleError))
    );
  }

  cancel_subscription(params: CancelSubscriptionQ){
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http
        .post(this.pagamentos_api_url + "/cancel-subscription/", params)
        .pipe(catchError(this.handleError))
    );
  }

  getMyNF(company_id: number) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.get(`${this.pagamentos_api_url}/invoice/${company_id}/`).pipe(
        map((response: NotaFiscalEletronica[]) => response),
        tap(console.log) // todo: Entender pq precisa desse console
      )
    );
  }

  //INIT STRIPE
  getStripeCheckoutUrl(plan_id: number): Observable<{ url: string }> {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http
        .get<any>(`${this.pagamentos_api_url}/stripe/checkout/${plan_id}/`)
        .pipe(catchError(this.handleError))
    );
  }

  getStripeBillingPortalUrl(subscription_id: number): Observable<{ url: string }> {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http
        .get<any>(`${this.pagamentos_api_url}/stripe/billing-portal/${subscription_id}/`)
        .pipe(catchError(this.handleError))
    );
  }

  getStripeSubscriptionFromCheckout(checkout_id: number): Observable<SubscriptionProps> {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http
        .get<any>(`${this.pagamentos_api_url}/stripe/checkout-subscription/${checkout_id}/`)
        .pipe(catchError(this.handleError))
    );
  }

  //END STRIPE

  getCompanyCountry(): Observable<{ country: string }> {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.get<any>(this.user_api_url + "/company/country/")
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error("An error occurred:", error.error.message);
    } else {
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    return throwError(error.error);
  }
}
