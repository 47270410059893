import {Action, createReducer, on} from '@ngrx/store';
import * as actions from './selfieValidation.actions';
import {SelfieValidationProps} from './selfieValidation.model';


export const INITIAL_STATE: SelfieValidationProps = {
  result: '',
  disableNextSignStep: false,
  loading: false
};

const _selfieValidationReducer = createReducer(INITIAL_STATE,
  on(
    actions.disableNextSignStep, (state, {payload}) => ({
      ...state,
      disableNextSignStep: payload.disableNextSignStep,
      result: payload.result,
      loading: payload.loading
    })
  ),
);
export function selfieValidationReducer(state: SelfieValidationProps, action: Action) {
  return _selfieValidationReducer(state, action);
}
