import { Action } from '@ngrx/store';

export enum ActionTypesMemberships {
  ADDMEMBERSHIPS = 'GETMEMBERSHIPS',
  CLEANMEMBERSHIPS = 'CLEANMEMBERSHIPS',
}

export class AddMembershipsRequest implements Action {
  readonly type = ActionTypesMemberships.ADDMEMBERSHIPS;
  constructor(public payload: any) {}
}
export class CleanMembershipsRequest implements Action {
  readonly type = ActionTypesMemberships.CLEANMEMBERSHIPS;
}

export type ActionsRequestMemberships = AddMembershipsRequest | CleanMembershipsRequest;
