import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-observers-list',
  templateUrl: './observers-list.component.html',
  styleUrls: ['./observers-list.component.scss']
})
export class ObserversListComponent {
  @Input() observersEmails = [];
  @Input() observersText = '';
  @Output() removeEmitter = new EventEmitter<string>();

  remove(observer: string) {
    this.removeEmitter.emit(observer);
  }

}
