import { Component, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Language } from 'src/translation/translation';
import { NewsletterInfo } from '../user-navbar.model';
import { NewsletterService } from './newsletter.service';
import {MixpanelService} from '../../../shared/services/mixpanel.service';

@Component({
  selector: 'app-newsletter-modal',
  templateUrl: './newsletter-modal.component.html',
  styleUrls: ['./newsletter-modal.component.scss']
})
export class NewsletterModalComponent implements OnChanges {
  lang: Language = localStorage.getItem('lang') as Language || 'pt-br';

  @Input() newsLetterModalOpen = false;
  @Output() newsLetterModalClosed = new EventEmitter<boolean>();
  @Output() nonViewedNewsCountEmitter = new EventEmitter<string>();

  newsletterInfos: NewsletterInfo[];
  nonViewedNewsCount: string;

  constructor(
    private newsletterService: NewsletterService,
    private mixpanelService: MixpanelService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    this.newsletterModalIsOpen(changes);
  }

  newsletterModalIsOpen(changes: SimpleChanges) {
    if (changes.newsLetterModalOpen.currentValue) {
      this.getNewsletters();
      this.nonViewedNewsCount = '0';
      this.nonViewedNewsCountEmitter.emit(this.nonViewedNewsCount);
    }
  }

  getNewsletters() {
    this.newsletterService.getNewsletters().subscribe(
      data => this.newsletterInfos = data,
      err => console.error(err)
    );
    this.mixpanelService
      .sendEventMixpanel('Notifications (open modal)', {})
      .subscribe();
  }

  preventModalFromClosing($event) {
    $event.stopPropagation();
  }

  closeModal() {
    this.newsLetterModalOpen = false;
    this.newsLetterModalClosed.emit(true);
  }
}
