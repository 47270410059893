import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Language} from '../../../translation/translation';
import {Signer} from '../../helpers/model/docs';
import {TabsActiveMethodsSignature} from '../../signers/sign-page/store/current-tab/current-tab-models';
import {TranslatorService} from '../translator.service';

@Component({
  selector: 'app-tabs-options-signature',
  templateUrl: './tabs-options-signature.component.html',
  styleUrls: ['./tabs-options-signature.component.scss']
})
export class TabsOptionsSignatureComponent implements OnInit {

  @Output() emmiterCurrentTabOptionsSignature = new EventEmitter<TabsActiveMethodsSignature>();
  @Input() signer: Signer;
  @Input() showUploadSignature: boolean;
  @Input() showWriteSignature: boolean;
  @Input() hideDrawSignature: boolean;
  lang = localStorage.getItem('lang') as Language || 'pt-br';
  disabledTabDrawWhenFocus = false;
  disabledTabUploadWhenFocus = false;
  disabledTabCursiveWhenFocus = false;
  tabActive: TabsActiveMethodsSignature = 'draw';
  functionsBlock = {
    'drawing-upload': () => {
      this.hideDrawSignature = true;
      this.showUploadSignature = false;
      this.showWriteSignature = true;
      this.tabActive = 'write';
      this.setCurrentTab('write');
    },
    'writing-drawing': () => {
      this.hideDrawSignature = true;
      this.showWriteSignature = false;
      this.showUploadSignature = true;
      this.tabActive = 'upload';
      this.setCurrentTab('upload');
    },
    'writing-upload': () => {
      this.showUploadSignature = false;
      this.showWriteSignature = false;
      this.hideDrawSignature = false;
      this.tabActive = 'draw';
      this.setCurrentTab('draw');
    },
    drawing: () => {
      this.hideDrawSignature = true;
      this.showUploadSignature = true;
      this.showWriteSignature = true;
      this.tabActive = 'upload';
      this.setCurrentTab('upload');
    },
    upload: () => {
      this.showUploadSignature = false;
      this.hideDrawSignature = false;
      this.showWriteSignature = true;
      this.tabActive = 'draw';
      this.setCurrentTab('draw');
    },
    writing: () => {
      this.showWriteSignature = false;
      this.showUploadSignature = true;
      this.tabActive = 'draw';
      this.setCurrentTab('draw');
    }
  };

  constructor(
    private translatorService: TranslatorService,
    private cdr: ChangeDetectorRef
  ) {

  }

  ngOnInit(): void {
    if (this.hideDrawSignature) {
      this.emmiterCurrentTabOptionsSignature.emit('upload');
      this.cdr.detectChanges();
    }
    if (this.signer.doc.company.sign_mode_not_allowed) {
      this.functionsBlock[this.signer.doc.company.sign_mode_not_allowed]();
      this.cdr.detectChanges();
    }
  }

  t(text: string) {
    return this.translatorService.translate(text, this.lang);
  }

  setCurrentTab(tabActive: TabsActiveMethodsSignature) {
    this.emmiterCurrentTabOptionsSignature.emit(tabActive);
  }
}
