<div class="unpaid-banner">
  <mat-icon svgIcon="link-external-01" class="zs_donwload_icon zs-mobile-unpaid-banner" (click)="goToMyPlans()"></mat-icon>
  <p class="zs-web-unpaid-banner">
    {{'O pagamento do seu plano não foi concluído. Evite a suspensão do seu plano.'| translate}}
  </p>
  <p class="zs-mobile-unpaid-banner" (click)="goToMyPlans()">
    {{'O pagamento do seu plano não foi concluído. Evite a suspensão do seu plano.'| translate}}
  </p>


  <zs-button id="zs-renew-plan-test-id" class="zs-orange-secundary-button" typeButton="secundary" (click)="goToMyPlans()" [label]="'Pagar agora'">

  </zs-button>
</div>


