import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthBackendService } from 'src/app/auth/backend.service';

@Injectable({
  providedIn: 'root'
})
export class ConvertNumberToCurrencyService {

  private companyLocaleSubject = new BehaviorSubject<string | null>(null);
  companyLocale$ = this.companyLocaleSubject.asObservable();
  private idCompany: number;

  constructor(private authBackendService: AuthBackendService) {
    this.initialize();
  }

  private initialize(): void {
    this.idCompany = +localStorage.getItem('companyId');
    if (this.idCompany) {
      this.authBackendService.getMyCompany(this.idCompany).subscribe(company => {
        this.companyLocaleSubject.next(company.locale);
      });
    }
  }

  private getLocale(): string | null {
    let locale = this.companyLocaleSubject.value;
    if (!locale) {
      locale = localStorage.getItem('locale') || (window.location.href.includes('.com.br') ? 'br' : 'us');
    }
    return locale;
  }

  private get currency(): string {
    const locale = this.getLocale();
    return locale === 'br' ? 'BRL' : 'USD';
  }

  private get defaultInitialsCountryToConvertNumberCurrency(): string {
    const locale = this.getLocale();
    return locale === 'br' ? 'pt-BR' : 'en-US';
  }

  takeSymbolCurrencyMoney(): string {
    const locale = this.getLocale();
    return locale === 'br' ? 'R$ ' : '$ ';
  }

  convertToCurrency(number: number, initialsCountryToConvertNumber?: string | string[], optionsFormatNumber?: Intl.NumberFormatOptions) {
    const initialsCountry = this.defaultInitialsCountryToConvertNumberCurrency || initialsCountryToConvertNumber;
    const optionsNumberFormat = {
      currency: this.currency,
      style: 'currency',
      ...optionsFormatNumber
    };
    return Intl.NumberFormat(initialsCountry, optionsNumberFormat).format(number);
  }

  removeSymbolCurrencyMoney(value: string) {
    const valueWithoutSymbol = value.replace(/[^0-9,-]+/g, '');
    const valueWithoutDecimal = valueWithoutSymbol.split(',')[0];
    return Number(valueWithoutDecimal);
  }
}