<!-- Modal -->
<div id="myModal" class="modal"
[style.display]="(isDelete || isEdit)  ? 'block':'none'" style="overflow-y: auto">
  <!-- Modal content -->
  <div class="modal-content modal-api-confirmation" style="position:relative; align-items: normal;">
    <div style="position:absolute;top:10px;right:10px;">
      <button data-cy="close-modal-button" mat-icon-button (click)='closeModal()'><mat-icon class="zs-icon-close">close</mat-icon></button>
    </div>
    <div *ngIf="isEdit" class="modal-api-confirmation-body">
      <h2>
        {{'Renovar Token' | translate}}
      </h2>
      <div class="modal-api-confirmation-text">
        <p>{{'Tem certeza de que deseja renovar o token de acesso à API? As integrações que você fez deixarão de funcionar.' | translate}}</p>
      </div>
      <div class="modal-api-confirmation-buttons">
        <button class="modal-api-confirmation-button" mat-raised-button (click)="closeModal()">
          {{'Voltar' | translate}}
        </button>
        <button class="modal-api-confirmation-button"
                mat-button mat-raised-button color="primary"
                (click)="confirmRenovateToken()" style="margin-left: 18px">
          {{'Renovar Token' | translate}}
        </button>
      </div>
    </div>
    <div *ngIf="isDelete" class="modal-api-confirmation-body">
      <h2>
        {{'Desabilitar API' | translate}}
      </h2>
      <div class="modal-api-confirmation-text">
        <p>{{'Tem certeza de que deseja desativar a API? As integrações que você fez deixarão de funcionar.' | translate}}</p>
      </div>
      <div class="modal-api-confirmation-buttons">
        <button class="modal-api-confirmation-button" mat-raised-button (click)="closeModal()">
          {{'Voltar' | translate}}
        </button>
        <button class="modal-api-confirmation-button"
                mat-button mat-raised-button color="warn"
                (click)="confirmDeleteToken()" style="margin-left: 18px">
          {{'Desabilitar API' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
