import { Injectable } from '@angular/core';
import {WarningService} from '../../../components/warning.service';
import {TranslatorService} from '../../../components/translator.service';
import {Language} from '../../../../translation/translation';
import {string} from '@tensorflow/tfjs-core';
import {DocBackendService} from "@components/doc.backend.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  lang = localStorage.getItem('lang') as Language || 'pt-br';
  constructor(
    private warningService: WarningService,
    private _translationService: TranslatorService,
    private docBackend: DocBackendService,
  ) { }

  t(text: string) {
    return this._translationService.translate(text, this.lang);
  }

  download(blob, docDame, type = '.pdf') {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    const name = docDame.replace(/\.[^/.]+$/, '');
    a.download = name + type;
    document.body.appendChild(a);
    a.click();
    setTimeout(
      _ => { window.URL.revokeObjectURL(url); },
      60000);
    a.remove();
  }
  async downloadDocument(documentRef) {
    return fetch(documentRef.signed_file, {method: 'GET'})
      .then(res => {
        return res.blob();
      })
      .then(blob => {
        this.download(blob, documentRef.name);
      }).catch(err => {
        this.warningService.toastrError(this.t('Erro ao baixar documento'));
      });
  }

  downloadZip(documentRef, type: string = null) {
    return new Observable(observer => {
      this.docBackend.getDocumentZip(documentRef.token, type).subscribe(data => {
        const blob = new Blob([data], { type: 'application/stream'});
        this.download(blob, 'zapsign_' + documentRef.name, '.zip');
        observer.next();
        observer.complete();
      }, error => {
        this.warningService.toastrError(this.t('Erro ao baixar documento'));
        observer.next();
        observer.complete();
      });
    });
  }
}
