import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef, EventEmitter, HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit, Output, SimpleChanges
} from '@angular/core';
import {Language} from '@translation/translation';
import {TranslatorService} from '../../translator.service';
import * as SignaturePad from 'signature_pad';

interface OptionsSignatureProps {
  minWidth: number;
  maxWidth: number;
  canvasWidth: string | number;
  canvasHeight: string | number;
  backgroundColor: string;
}
@Component({
  selector: 'app-design-signature',
  templateUrl: './design-signature.component.html',
  styleUrls: ['./design-signature.component.scss']
})
export class DesignSignatureComponent implements OnInit, OnDestroy, AfterContentInit, AfterViewInit, OnChanges {

  @Input() activeTab = 'draw';
  @Input() lang: Language = localStorage.getItem('lang') as Language || 'pt-br';
  @Input() isVisto = false;
  @Input() widthOffset = 0;
  @Output() done: EventEmitter<string>;
  @Output() cleared: EventEmitter<boolean>;
  @Output() startPad: EventEmitter<boolean>;
  @Output() signaturePhotoActive = new EventEmitter<boolean>();
  @Output() signatureTextActive = new EventEmitter<boolean>();
  signaturePad: any;
  elementRef: ElementRef;
  padWidth: number;
  padHeight: number;
  options: OptionsSignatureProps;
  originalInnerWidth: number;
  originalInnerHeight: number;
  originalIsHorizontal: boolean;
  urlDataImageWhenScreenRotate = '';

  constructor(
    elementRef: ElementRef,
    private translator: TranslatorService
  ) {
    this.elementRef = elementRef;
    this.options = {
      minWidth: .5,
      maxWidth: 1.8,
      canvasWidth: 0,
      canvasHeight: 0,
      backgroundColor: 'rgba(0,0,0,0)'
    };
    this.done = new EventEmitter();
    this.cleared = new EventEmitter();
    this.startPad = new EventEmitter();
    this.signaturePhotoActive = new EventEmitter();
  }

  ngOnInit(): void {
    this.originalInnerWidth = window.innerWidth;
    this.originalInnerHeight = window.innerHeight;
    this.originalIsHorizontal = this.originalInnerHeight < this.originalInnerWidth;
  }

  ngOnChanges(changes: SimpleChanges) {
    const activeTab = changes.activeTab?.currentValue as string;
    if (activeTab && activeTab === 'draw') {
      this.onEnd();
    }
  }

  t(text: string) {
    return this.translator.translate(text, this.lang);
  }

  public ngAfterContentInit(): void {
    const sp: any = SignaturePad.default;
    const canvas: any = this.elementRef.nativeElement.querySelector('canvas');

    if ((this.options as OptionsSignatureProps).canvasHeight) {
      canvas.height = (this.options as OptionsSignatureProps).canvasHeight;
    }

    if ((this.options as OptionsSignatureProps).canvasWidth) {
      canvas.width = (this.options as OptionsSignatureProps).canvasWidth;
    }

    this.signaturePad = new sp(canvas, this.options);
    this.signaturePad.onBegin = this.onBegin.bind(this);
    this.signaturePad.onEnd = this.onEnd.bind(this);
  }

  public ngOnDestroy(): void {
    const canvas: any = this.elementRef.nativeElement.querySelector('canvas');
    canvas.width = 0;
    canvas.height = 0;
  }

  public toDataURL(imageType?: string, quality?: number): string {
    const toDataURL = this.signaturePad?.toDataURL(imageType, quality);
    return this.urlDataImageWhenScreenRotate ? this.urlDataImageWhenScreenRotate : toDataURL; // save image as data URL
  }

  public clear(): void {
    this.signaturePad.clear();
  }

  public isEmpty(): boolean {
    return this.signaturePad.isEmpty();
  }

  public onBegin(): void {
    this.startPad.emit(true);
  }

  public onEnd(): void {
    this.done.emit(this.toDataURL());
  }

  public set(option: string, value: number): void {
    switch (option) {
      case 'canvasHeight':
        this.signaturePad.canvas.height = value;
        break;
      case 'canvasWidth':
        this.signaturePad.canvas.width = value;
        this.signaturePad.canvas.maxWidth = value;
        break;
      default:
        this.signaturePad[option] = value;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => this.resizeCanvas(), 0);
  }

  resizeCanvas() {
    this.padWidth = this.elementRef.nativeElement.querySelector('.zs_signature_pad_container').clientWidth - this.widthOffset;
    this.padHeight = this.elementRef.nativeElement.querySelector('.zs_signature_pad_container').clientHeight;
    this.set('canvasWidth', this.padWidth);
    this.set('canvasHeight', this.padHeight);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    setTimeout(() => this.resizeCanvas(), 0);
  }

  @HostListener('window:orientationchange', ['$event'])
  screenOrientationChange($event: Event) {
    if ($event.type === 'orientationchange') {
      setTimeout(() => {
        this.setUrlDataImageWhenSignaturePadRotate();
        this.resizeCanvas();
        this.clearSignature();
        if (this.urlDataImageWhenScreenRotate !== '') {
          this.done.emit(this.urlDataImageWhenScreenRotate);
        }
      }, 0);
    }
  }

  setUrlDataImageWhenSignaturePadRotate() {
    if (!this.isEmpty()) {
      const createImaged = document.createElement('img');
      createImaged.src = this.toDataURL();
      this.urlDataImageWhenScreenRotate = createImaged.src;
    }
  }

  clearDataImageRotated() {
    this.urlDataImageWhenScreenRotate = '';
    this.clearSignature();
  }

  clearSignature(): void {
    this.signaturePad.clear();
    this.cleared.emit(true);
    setTimeout(() => this.resizeCanvas(), 0);
  }

}
