<div id="myModal" class="modal"
     (click)="closeModal()"
     [style.display]="modalOpen ? 'block':'none'">
  <!-- Modal content -->
  <div class="modal-content" (click)="$event.stopPropagation();">
    <section *ngIf="errors.length === 0; else noPermissionCam">
      <app-old-liveness-validator
        *ngIf="modalOpen"
        (validate)="handleValidation($event)"
        (handleError)="handleError($event)"
      ></app-old-liveness-validator>
    </section>
    <section id='zs-liveness-buttons'>
      <button
        mat-raised-button
        *ngIf="!userAgent.isMobile"
        (click)="openModalNotHaveCamera()"
        matTooltip="{{'Algum problema para tirar sua selfie?' | translate}}"
      >
        {{'Abrir no celular' | translate}}
      </button>
    </section>
    <ng-template #noPermissionCam>
      <app-no-permission-camera [userAgent]="userAgent"></app-no-permission-camera>
    </ng-template>
  </div>
</div>
