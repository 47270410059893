import { on, createReducer, Action } from '@ngrx/store';
import * as actions from './user-group.actions';
import SaveUserGroupProps from './user-group.model';

const INITIAL_STATE: SaveUserGroupProps = {
  userGroups: [],
};


const _saveUserGroupReducer = createReducer(INITIAL_STATE,
  on(
    actions.setUserGroupAction,
    (state, { payload }) => ({...state, userGroups: payload})
  )
);

export const userGroupReducer = (state: SaveUserGroupProps, action: Action) => {
  return _saveUserGroupReducer(state, action);
};
