import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatSelectChange} from "@angular/material/select";

@Component({
  selector: 'app-page-size-select',
  templateUrl: './page-size-select.component.html',
  styleUrls: ['./page-size-select.component.scss']
})
export class PageSizeSelectComponent implements OnInit {
  pageSizes = [25, 50, 100, 250];
  @Input() actualPageSize = 250;
  @Output() pageResized: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
  }

  changePageSize($event: MatSelectChange) {
    this.actualPageSize = $event.value;
    this.pageResized.emit($event.value);
  }

}
