<aside>

  <section class="header-signature-cursive">

    <button (click)="cursiveselect.open()" mat-stroked-button type="button"
            [matTooltip]="'Altere a fonte da sua assinatura' | translate"
            class="button-upload-photo-signature">

      <span class="span-button-upload-photo-signature">
        {{'Alterar fonte' | translate}}
      </span>
      <mat-select panelClass="panel-class-cursive-signature" [disableOptionCentering]="true" class="cursive-select"
                  #cursiveselect>
        <mat-option *ngFor="let optionFont of optionsFonts; let i = index" [value]="optionFont.font"
                    [ngStyle]="{'font-family': optionFont.font}">

          <mat-radio-button [checked]="indexSelectedFont === i" (change)="setCurrentFont(i)">
            <span class="choice-font-cursive" [style.FontFamily]="optionFont.font">
              {{signerName}}
            </span>
          </mat-radio-button>
        </mat-option>
      </mat-select>
    </button>
  </section>


  <section class="container-signature-cursive">

    <div>
      <span>{{'Pré visualização' | translate}}</span>
    </div>
    <div>
      <div class="background-gray-signature-cursive">
        <div class="border-image">
          <div class="container-span-signature-cursive" #textCursiveSignatureEl>
            <span id="signer-name-signature-cursive-test-id" [style.fontFamily]="selectedFont?.font">
            {{ signerName }}
          </span>
          </div>

        </div>
      </div>

      <div class="container-text-signature-cursive">
        <span>
          {{ 'Ao assinar o documento, concordo que a assinatura será uma representação eletrônica da minha assinatura escrita em papel' | translate }}
        </span>
      </div>

    </div>
  </section>
  <button
    *ngIf="!isVisto"
    class="buttons zs-text-uppercase"
    mat-raised-button
    [ngClass]="isSignPageRoute() && 'btn-large'"
    [style.backgroundColor]="primaryColor"
    color="primary"
    (click)="emmiterSaveSignature()"
    [class.spinner]="loadingFont || doingSignature"
    [disabled]="loadingFont || changesMade || doingSignature"
  >
    <span>
      {{textButton()}}
    </span>
  </button>
</aside>
