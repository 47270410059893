<div class="zs_user_page_full">
  <div class="zs-title-and-filter">
    <div class="header-title-heading">
      <h1>
        {{ "Minhas assinaturas" | translate }}
      </h1>
    </div>
    <div class="header-filter">
      <app-filter
        (emitterFilters)="changedFiltersOutput($event)"
        [selectedFiltersShow]="['date','statusDoc']"
      >
      </app-filter>
    </div>
  </div>

  <div style="display: flex; justify-content: space-between">
    <section *ngIf="user && user.email_verified" class="container-infos-my-signatures">
      <zs-button
        typeButton="link"
        (click)="goToRedirectBatchSignature($event)"
        [matTooltip]="canBatchSignature ? t('Assine todos os documentos pendentes com um clique') : t('Habilite esta funcionalidade para obter mais recursos')"
        iconDirection="left"
        [label]="'Assinar em lote'"
        [icon]="'edit_04'"
        [loading]="loading"
        [disable]="companyHasBatchSignature && !userHasBatchSignature"
      ></zs-button>
    </section>
    <section>
      <div style="display: flex; gap: 2px; flex-direction: row; text-align: center">
        <p class="pagination-information"
           *ngIf="hasDocuments">
          {{ paginationService.showing_from }}-{{ paginationService.showing_to }}
          {{ paginationService.count == 1 ? "DOCUMENTO" : "DOCUMENTOS" | translate }}
        </p>
        <app-page-size-select [actualPageSize]="paginationService.page_size" (pageResized)="resizePage($event)"></app-page-size-select>
      </div>
    </section>
  </div>
  <mat-divider></mat-divider>
</div>

