import { Component } from '@angular/core';

@Component({
  selector: 'app-login-signup-right-container',
  templateUrl: './login-signup-right-container.component.html',
  styleUrls: ['./login-signup-right-container.component.scss']
})
export class LoginSignupRightContainerComponent {

}
