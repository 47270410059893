<!-- The Modal -->
<div id="myModal" class="modal"
(click)="closeModal()"
[style.display]="modalOpen ? 'block':'none'">
  <!-- Modal content -->
  <div class="modal-content" (click)="$event.stopPropagation();">
    <div class="modal-header">
      <div class="modal-title">
        <h1>{{'Assinar com o celular' | translate}}</h1>
        <zs-button
          typeButton="icon"
          icon="icon_x_close"
          (click)="closeModal()"
        ></zs-button>
      </div>
      <div class="modal-description">
        <h3>{{'Você pode usar seu celular para assinar. Para isso basta escanear o QR code abaixo com a sua câmera' | translate}}</h3>
      </div>
    </div>
    <div class="modal-qrcode">
      <qrcode [qrdata]="qrCode" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
    </div>
    <div class="divisor"></div>
    <zs-button
      typeButton="secundary"
      label="Voltar"
      (click)="closeModal()"
    ></zs-button>
  </div>
</div>
