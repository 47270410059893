<div class="photo-result-container">
  <div class="photo-box">
    <img *ngIf="photo" class="photo-img" [src]="photo" [alt]="description"/>
  </div>

  <div class="photo-result-body">
    <p class="photo-description">{{description | translate}}</p>
    <zs-button
      typeButton="icon"
      icon="icon_x_close"
      dataCy="photo-result-close"
      (click)="cleanUpload()"
    ></zs-button>
  </div>
</div>
