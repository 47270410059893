import {Action, createReducer, on} from '@ngrx/store';
import {Folder, FolderLinear} from './folder-to-be-moved.model';
import {clearFolderToBeMoved, setFolderToBeMoved} from './folder-to-be-moved.actions';

export const INITIAL_STATE: Folder | FolderLinear = {
  id: 0,
  token: '',
  name: '',
  parent_folder: null,
};

const _folderToBeMovedReducer = createReducer(
  INITIAL_STATE,
  on(setFolderToBeMoved, (state, {payload}) => {
    return {...state, ...payload};
  }),
  on(clearFolderToBeMoved, () => INITIAL_STATE)
);

export function folderToBeMovedReducer(state: Folder | FolderLinear, action: Action) {
  return _folderToBeMovedReducer(state, action);
}
