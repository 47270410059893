import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/app.state";
import { useSelectorSetLoadingSignedFile } from "src/app/app.state.selectors";
import { setLoadingSignedFileAction } from "src/app/signers/sign-page/steps-signature/store/setLoadingSignedFileRedux/set-loading-signed-file-redux.actions";

@Injectable({
  providedIn: "root",
})
export class LoadingSignedFileReduxService {
  private loadingSignedFile: boolean;

  constructor(private store: Store<AppState>) {}

  public setterLoadingSignedFileInStore(loadingSignedFile: boolean) {
    this.store.dispatch(
      setLoadingSignedFileAction(
        Object.assign({}, loadingSignedFile, {
          payload: loadingSignedFile,
        })
      )
    );

    this.getLoadingSignedFileInStore();
    return this.getLoadingSignedFile;
  }

  private getLoadingSignedFileInStore() {
    this.store
      .select(useSelectorSetLoadingSignedFile)
      .subscribe(
        ({ loadingSignedFile }) => (this.loadingSignedFile = loadingSignedFile)
      );
  }

  private get getLoadingSignedFile() {
    return this.loadingSignedFile;
  }
}
