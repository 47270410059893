import {Action, createReducer, on} from '@ngrx/store';
import {controlsStateDeletedBackgroundSection} from './check.actions';
import CheckStoreProps from './check.model';


export const INITIAL_STATE: CheckStoreProps = {
  openTheDeletedBackgroundRecycle: false
};

const _checkReducer = createReducer(
  INITIAL_STATE,
  on(controlsStateDeletedBackgroundSection, (state, {payload}) => {
    return {...state, openTheDeletedBackgroundRecycle: payload};
  }),
);

export function checkReducer(state: CheckStoreProps, action: Action) {
  return _checkReducer(state, action);
}
