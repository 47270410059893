<div [class.small]="width == 'small'">
  <div *ngIf="!disabled">
    <div style="position:relative;display: initial;">
      <mat-form-field class="phone_country" floatLabel="always" [appearance]="appearance" hideRequiredMarker="true">
        <mat-label>{{'País' | translate}}</mat-label>
        <input
          [(ngModel)]="phone_country"
          [disabled]="!do_not_disable_country"
          type="tel" matInput [required]="!hideValidationRedColor"
          mask='+099' [dropSpecialCharacters]="true" placeholder="+55"
          id="phone-country-not-disabled-test-id"
          (keyup)="triggerChange()">
      </mat-form-field>
      <img alt="Image phone country" *ngIf="showPhoneCountry(phone_country)"
           src="/assets/img/flags/{{phone_country}}.png"
           class="country_phone_flag"/>
    </div>
    <mat-form-field
      *ngIf="phone_country === '55' && !hide_phone_number"
      class="phone_number" [appearance]="appearance" hideRequiredMarker="true">
      <mat-label>
        <span *ngIf="width != 'small'">{{'Celular (DDD + Número)' | translate}}</span>
        <span *ngIf="width == 'small'">{{'Celular(opcional)' | translate}}</span>
      </mat-label>
      <input
        id="phone-number-test"
        [(ngModel)]="phone_number"
        type="tel" matInput [required]="!hideValidationRedColor"
        mask='(00) 00000-0009' [dropSpecialCharacters]="true" placeholder="(xx) xxxxx-xxxx" autocomplete="tel"
        (keyup)="triggerChange()">
      <mat-error *ngIf="!hideValidationRedColor" [innerHTML]="'Por favor, digite o celular' | translate"></mat-error>
    </mat-form-field>
    <mat-form-field
      *ngIf="phone_country !== '55' && !hide_phone_number"
      class="phone_number" floatLabel="always" [appearance]="appearance" hideRequiredMarker="true"
    >
      <mat-label>{{'Celular' | translate}}</mat-label>
      <input
        [(ngModel)]="phone_number"
        [dropSpecialCharacters]="true"
        [required]="!hideValidationRedColor"
        matInput
        mask='999999999999999'
        type="tel"
        placeholder=""
        (keyup)="triggerChange()">
      <mat-error *ngIf="!hideValidationRedColor" [innerHTML]="'Por favor, digite o celular' | translate"></mat-error>
    </mat-form-field>
  </div>
  <div *ngIf="disabled && width == 'small'"
       [hidden]="true">
    <div style="position:relative;display: initial;">
      <mat-form-field class="phone_country" floatLabel="always" [appearance]="appearance" hideRequiredMarker="true">
        <mat-label>{{'País' | translate}}</mat-label>
        <input
          [(ngModel)]="phone_country"
          type="tel" matInput [required]="hideValidationRedColor"
          mask='+099' [dropSpecialCharacters]="true" placeholder="+55"
          (keyup)="triggerChange()" [disabled]="!do_not_disable_country">
      </mat-form-field>
      <img alt="Image phone country" *ngIf="showPhoneCountry(phone_country)"
           src="/assets/img/flags/{{phone_country}}.png"
           class="country_phone_flag"/>
    </div>
    <div>
      <mat-form-field
        *ngIf="phone_country === '55' && !hide_phone_number" [appearance]="appearance"
        class="phone_number" hideRequiredMarker="true">
        <mat-label>
          <span *ngIf="width != 'small'">{{'Celular (DDD + Número)' | translate}}</span>
          <span *ngIf="width == 'small'">{{'Celular(opcional)' | translate}}</span>
        </mat-label>
        <input
          [(ngModel)]="phone_number"
          type="tel" matInput [required]="hideValidationRedColor"
          mask='(00) 00000-0009' [dropSpecialCharacters]="true" placeholder="(xx) xxxxx-xxxx" autocomplete="tel"
          (keyup)="triggerChange()" disabled>
        <mat-error *ngIf="hideValidationRedColor" [innerHTML]="'Por favor, digite o celular' | translate"></mat-error>
      </mat-form-field>
      <mat-form-field
      *ngIf="phone_country !== '55' && !hide_phone_number" [appearance]="appearance"
      class="phone_number" floatLabel="always" hideRequiredMarker="true"
    >
      <mat-label>{{'Celular(opcional)' | translate}}</mat-label>
      <input
        [(ngModel)]="phone_number"
        type="tel" matInput [required]="hideValidationRedColor"
        mask='999999999999999' [dropSpecialCharacters]="true" placeholder=""
        (keyup)="triggerChange()" disabled>
      <mat-error *ngIf="hideValidationRedColor" [innerHTML]="'Por favor, digite o celular' | translate"></mat-error>
    </mat-form-field>
    </div>
  </div>
  <div *ngIf="disabled && width != 'small'">
    <div style="position:relative;display: initial;">
      <mat-form-field class="phone_country" floatLabel="always" hideRequiredMarker="true">
        <mat-label>{{'País' | translate}}</mat-label>
        <input
          [(ngModel)]="phone_country"
          type="tel" matInput [required]="hideValidationRedColor"
          mask='+099' [dropSpecialCharacters]="true" placeholder="+55"
          (keyup)="triggerChange()" [disabled]="!do_not_disable_country">
      </mat-form-field>
      <img alt="Image phone country" *ngIf="showPhoneCountry(phone_country)"
           src="/assets/img/flags/{{phone_country}}.png"
           class="country_phone_flag"/>
    </div>
    <mat-form-field
      *ngIf="phone_country === '55'"
      class="phone_number" hideRequiredMarker="true">
      <mat-label>
        <span *ngIf="width != 'small'">{{'Celular (DDD + Número)' | translate}}</span>
        <span *ngIf="width == 'small'">{{'Celular(opcional)' | translate}}</span>
      </mat-label>
      <input
        *ngIf="!hide_phone_number"
        [(ngModel)]="phone_number"
        type="tel" matInput [required]="hideValidationRedColor"
        mask='(00) 00000-0009' [dropSpecialCharacters]="true" placeholder="(xx) xxxxx-xxxx" autocomplete="tel"
        (keyup)="triggerChange()" disabled>
      <mat-error *ngIf="hideValidationRedColor" [innerHTML]="'Por favor, digite o celular' | translate"></mat-error>
    </mat-form-field>
    <mat-form-field
      *ngIf="phone_country !== '55'"
      class="phone_number" floatLabel="always" hideRequiredMarker="true"
    >
      <mat-label>{{'Celular(opcional)' | translate}}</mat-label>
      <input
        *ngIf="!hide_phone_number"
        [(ngModel)]="phone_number"
        type="tel" matInput [required]="hideValidationRedColor"
        mask='999999999999999' [dropSpecialCharacters]="true" placeholder=""
        (keyup)="triggerChange()" disabled>
      <mat-error *ngIf="hideValidationRedColor" [innerHTML]="'Por favor, digite o celular' | translate"></mat-error>
    </mat-form-field>
  </div>
</div>
