<div id="myModal" class="modal">
  <!-- Modal content -->
  <div class="modal-content" (click)="$event.stopPropagation()">
    <section class="container-content-modal-blue-background">
      <app-loading-overlay *ngIf="loadingResendButton"></app-loading-overlay>
      <div class="container-title-modal-blue-background">
        <h2>
          {{ "Oops, parece que você ainda não ativou sua conta!" | translate }}
        </h2>
      </div>
      <div>
        <ng-lottie width="110px" height="110px" [options]="options"></ng-lottie>
      </div>
      <div class="container-insert-code-text-activate-account-modal-blue-background">
        <h3>
          {{
            "Insira o código de 6 dígitos que foi enviado para o seu e-mail"
              | translate
          }}

          <strong>{{ email }}</strong>
        </h3>
      </div>

      <div class="container-insert-code-input-activate-account-modal-blue-background">
        <input
          [(ngModel)]="verificationCode"
          type="text"
          matInput
          placeholder="A A A A A A"
          mask="S S S S S S"
          [dropSpecialCharacters]="true"
          (keyup)="toUpperCaseVerificationCode($event)"
          (keyup.enter)="checkEmailVerificationToken()"

        />

        <button
          *ngIf="verificationCode.length === 6"
          mat-button
          mat-stroked-button
          (click)="checkEmailVerificationToken()"
          appDebounceClick
          [debounceTime]="3000"
          (debounceClick)="checkEmailVerificationToken()"
        >
          {{ "Verificar meu email" | translate }}
        </button>
      </div>

      <aside class="container-infos-footer-activate-account-modal-blue-background">
        <div>
          <span>
            <img
              src="assets/img/icons/icon_cancel_mail.svg"
              [alt]="'Ícone de email cancelado' | translate"
            />
            {{ "Não recebeu nenhum e-mail?" | translate }}
          </span>
          <button
            mat-button
            mat-stroked-button
            appDebounceClick
            [debounceTime]="3000"
            (debounceClick)="resendEmail()"
          >
            {{ "Reenviar E-mail" | translate }}
          </button>
        </div>
        <div>
          <span>
            <img
              src="assets/img/icons/icon_headphone.svg"
              [alt]="'Ícone de headphone' | translate"
            />

            {{ "Precisa de ajuda? Envie um e-mail para" | translate }}&nbsp;
            <b>{{ "support@zapsign.com.br" | translate }}</b>
          </span>
        </div>
        <div>
          <img
            src="assets/img/icons/icon_logout.svg"
            [alt]="'Ícone de logout' | translate"
          />
          <span (click)="logoutZapsign()">
            {{ "Sair da conta" | translate }}
          </span>
        </div>
      </aside>
    </section>
  </div>
</div>
