<div class="zs_container_modal_flow_pagamentos" [ngClass]="!showModal?'zs_modal_close':'' " data-cy="zs-modal-flow-payment">
  <div class="zs_modal_flow_pagamentos">
    <div class="zs-modal_close_confirm" *ngIf="closeConfirm && step !== 'payment-success'">
      <p class="zs-modal_close_confirm_title">
        {{'Tem certeza que deseja sair?' | translate}}
      </p>
      <p class="zs-modal_close_confirm_text">
        {{'Você está no processo de assinatura da ZapSign, ao fechar essa tela você perderá todos os dados preenchidos e a compra será cancelada.' | translate}}
      </p>
      <div class="zs-modal_close_confirm_buttons">
        <div>
          <zs-button (click)="closeCanceled()" label="Cancelar" typeButton="quaternary">

          </zs-button>
        </div>
        <div class="cancelar">
          <zs-button label="Fechar" (click)="closeConfirmed()" typeButton="primary">

          </zs-button>
        </div>
      </div>
    </div>
    <div class="zs_modal_sideBar">
      <div class="zs_content_logo">
        <img class="zs_logo" [src]="assetsService.getLogo('../../../assets/')"/>
      </div>
      <div class="zs_content_body_sideBar">
        <ng-content select="[sidebar]"></ng-content>
      </div>
    </div>
    <div class="zs_modal_content">
      <div class="zs_modal_header">
        <div class="zs_steps">
          <ng-content select="[stepsComponent]"></ng-content>
        </div>
        <div class="zs_content_button">
          <zs-button typeButton="icon" (click)="close()" icon="icon_x_close"></zs-button>
        </div>
      </div>
      <div class="zs_modal_boyd">
        <div class="zs_flow_pagamentos">
          <ng-content select="[body]"></ng-content>
        </div>
        <div class="zs_content_dados_cel">
          <ng-content select="[sidebarcel]"></ng-content>
        </div>
      </div>
      <div class="zs_modal_footer">
        <zs-note
          noteType="check-note"
          text="Este é um ambiente de compra seguro"
        ></zs-note>
        <div class="zs_controle_flow_pagament">
          <ng-content select="[controlsprevious]"></ng-content>
          <div class="zs_controle_next">
            <ng-content select="[controlsNext]"></ng-content>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
