<div class="container-observers-input">
  <span>{{ 'Alguém mais deve receber notificações por e-mail ao final das assinaturas deste documento?' | translate }}</span>
  <form class="width-full" [formGroup]="formGroup" (submit)="addEmail()">
    <zs-text-input
      label="Adicionar e-mail"
      formControlName="email"
    >
      <div class="observer-input-button-add" [class]="getButtonDisabled ? 'color-disable' : 'color-active'">
        <zs-button
          typeButton="icon"
          icon="plus_circle"
        ></zs-button>
      </div>
    </zs-text-input>
  </form>
  <app-switch-toggle
    [switchStep]="typeOfObserver"
    [switchStepsLabels]="switchStepsLabel"
    (changeSwitchEmitter)="changeSwitch($event)"
  ></app-switch-toggle>

  <ng-container *ngIf="typeOfObserver === 1 && observersEmails[1].length > 0">
    <app-observers-list
      [observersEmails]="observersEmails[1]"
      observersText="À cada assinatura e conclusão"
      (removeEmitter)="remove($event, 1)"
    ></app-observers-list>
  </ng-container>
  <app-observers-list
    *ngIf="observersEmails[0].length > 0"
    [observersEmails]="observersEmails[0]"
    observersText="Apenas alerta de conclusão"
    (removeEmitter)="remove($event, 0)"
  ></app-observers-list>
</div>
