import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'zs-button-photo-result',
  templateUrl: './button-photo-result.component.html',
  styleUrls: ['./button-photo-result.component.scss']
})
export class ButtonPhotoResultComponent {

  @Input() description = 'Selfie';
  @Input() photo: string;
  @Output() removePhotoEmitter = new EventEmitter();

  cleanUpload() {
    this.removePhotoEmitter.emit();
  }
}
