<div class="overlay"></div>
<div class="modal">
  <div class="zs_main_content_modal_freemium">
    <div class="zs_content_modal_freemium">
      <h2 class="zs_title_modal_freemium">{{'Ops, você não tem acesso a este recurso ...' | translate}}</h2>
      <span>{{message | translate}}</span>

      <span>{{'Que tal fazer um upgrade e aproveitar o que a ZapSign tem de melhor?' | translate}}</span>

      <a href="https://clients.zapsign.com.br/help/m%C3%A9todos-de-autentica%C3%A7%C3%A3o">
        {{'Saiba mais sobre autenticação avançada' | translate}}
        <mat-icon class="zs_arrow_icon_modal_freemium_link" svgIcon="right_arrow_blue"></mat-icon>
      </a>

      <div class="zs_buttons_modal_freemium">
        <button class="zs_button_maybe_later" [mat-dialog-close]="true"><strong>{{'Talvez mais tarde' | translate}}</strong></button>
        <button class="zs_button_see_plans" (click)="goToPricePage()" [mat-dialog-close]="true"><strong>{{'Ver Planos' | translate}}</strong>
          <mat-icon class="zs_arrow_icon_modal_freemium" svgIcon="vector_icon_white"></mat-icon>
        </button>
      </div>
    </div>
    <div>
      <div class="zs_background_modal_freemium">
        <img src="assets/img/man-with-phone.png" class="zs_img_modal_freemium" alt="Men with cell phone">
      </div>
    </div>
  </div>
</div>
