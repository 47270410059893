<div class="filter-status">
  <div
    [ngClass]="{'filter-status-button': true, 'filter-status-button-selected': statusFilter == 'assinado'}"
    (click)="signedDocsCount !== '0' ? changedStatusFilter('assinado') : ''">
    <div class="container-status signed">
      <mat-icon svgIcon="check"></mat-icon>
    </div>
    <div class="filter-status-button-rigth">
      <h3 class="title" *ngIf="signedDocsCount">{{signedDocsCount}}</h3>
      <span class="status">{{'Assinados' | translate}}</span>
    </div>
  </div>
  <div
    [ngClass]="{'filter-status-button': true, 'filter-status-button-selected': statusFilter == 'em-curso'}"
    (click)="ongoingDocsCount !== '0' ? changedStatusFilter('em-curso') : ''">
    <div class="container-status ongoing">
      <mat-icon svgIcon="loading_01"></mat-icon>
    </div>
    <div class="filter-status-button-rigth">
      <h3 class="title" *ngIf="ongoingDocsCount">{{ongoingDocsCount}}</h3>
      <span class="status">{{'Em curso' | translate}}</span>
    </div>
  </div>
  <div
    [ngClass]="{'filter-status-button': true, 'filter-status-button-selected': statusFilter == 'recusado'}"
    (click)="refusedDocsCount !== '0'? changedStatusFilter('recusado') : ''">
    <div class="container-status rejected">
      <mat-icon svgIcon="icon_x_close"></mat-icon>
    </div>
    <div class="filter-status-button-rigth">
      <h3 class="title" *ngIf="refusedDocsCount" >{{refusedDocsCount}}</h3>
      <span class="status">{{'Recusados' | translate}}</span>
    </div>
  </div>
</div>
