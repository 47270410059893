<div class="zs_container_filter" id="newFilter">
  <div class="zs_contant_input" [ngClass]="isFocusInput || name_filter?'zs_focus_content_input':''">
    <div class="zs_container_input">
      <input class="zs_input" [(ngModel)]="name_filter"
             (ngModelChange)="searchChange()"
             [ngClass]="isFocusInput || name_filter?'zs_focus_input':''" type="text" name="name_filter"
             (focus)='focusInput()' (focusout)="focusInput()"
             (keydown.enter)="emitFilterChange(); inputFocus.blur()"
             (click)="settingFiltro = false; inputFocus.focus()"
             #inputFocus>
      <label class="zs_label"
             [ngClass]="isFocusInput || name_filter?'zs_focus_label':''">{{'Buscar documento' | translate}}</label>
    </div>
    <button mat-button class="zs_button" (click)="settingFiltro = !settingFiltro" *ngIf="isFilterAvancado()"
            [ngClass]="isfilter() ? 'zs_filter_active' : ''" matTooltip="{{
                    'Filtros de busca' | translate
                  }}">
      <mat-icon class="zs_icon" svgIcon="icon_settings_04"></mat-icon>
    </button>
    <button mat-button class="zs_button zs_button_destaque" (click)="limpar ? limparBusca() : emitFilterChange()">
      <mat-icon *ngIf="limpar" class="zs_icon" svgIcon="icon_x_close"></mat-icon>
      <mat-icon *ngIf="!limpar" class="zs_icon" svgIcon="icon_search_sm"></mat-icon>
    </button>
  </div>
  <div class="zs_modal_filter" *ngIf="settingFiltro">
    <div class="zs_content_titulo">
      <p class="zs_titulo">{{'Filtrar busca' | translate}}</p>
      <mat-icon class="mat-icon-high" svgIcon="icon_arrow_up"
                (click)="settingFiltro = !settingFiltro"></mat-icon>
    </div>
    <div class="zs_content_setting">
      <div class="zs_setting" *ngIf="this.selectedFiltersShow.includes('author')">
        <div class="zs_accordeon" (click)="autorShow = !autorShow; periodo = false; pasta = false">
          <div class="zs_titulo">
            <mat-icon class="mat-icon-high" *ngIf="!autorShow" svgIcon="icon_chevron_right"></mat-icon>
            <mat-icon class="mat-icon-high" *ngIf="autorShow" svgIcon="icon_chevron_down"></mat-icon>
            <p>
              {{'Autor do documento' | translate}}
            </p>
          </div>
          <p class="zs_selecionado">
            {{email_filter ? email_filter : 'Todos' | translate}}
          </p>
        </div>
        <div class="zs_accordeon_open .author" *ngIf="autorShow">
          <p class="zs_item" (click)="email_filter = ''"
             [ngClass]="email_filter === '' ? 'zs_item_active' : ''">
            {{'Todos os autores' | translate}}
          </p>
          <p class="zs_item" *ngFor="let email of emails_users_company"
             (click)="email_filter = email.user.email"
             [ngClass]="email_filter === email.user.email ? 'zs_item_active' : ''">
            {{email.user.email}}
            <mat-icon class="mat-icon-high" *ngIf="email_filter === email.user.email"
                      svgIcon="icon_check"></mat-icon>
          </p>
        </div>
      </div>
      <div class="zs_setting" *ngIf="this.selectedFiltersShow.includes('statusDoc')">
        <div class="zs_accordeon" (click)="selectStatus = !selectStatus; periodo = false">
          <div class="zs_titulo">
            <mat-icon class="mat-icon-high" *ngIf="!selectStatus" svgIcon="icon_chevron_right"></mat-icon>
            <mat-icon class="mat-icon-high" *ngIf="selectStatus" svgIcon="icon_chevron_down"></mat-icon>
            <p>
              {{'Status' | translate}}
            </p>
          </div>
          <p class="zs_selecionado">
            <ng-container *ngIf="statusFilter === 'all'">
              {{'Todos' | translate}}
            </ng-container>
            <ng-container *ngIf="statusFilter === 'signed'">
              {{'Assinado' | translate}}
            </ng-container>
            <ng-container *ngIf="statusFilter === 'not-signed'">
              {{'Em curso' | translate}}
            </ng-container>
          </p>
        </div>
        <div class="zs_accordeon_open" *ngIf="selectStatus">
          <p class="zs_item" (click)="statusFilter = 'all'">
            {{ "Todos" | translate }}
            <mat-icon class="mat-icon-high" *ngIf="statusFilter === 'all'"
                      svgIcon="icon_check"></mat-icon>
          </p>
          <p class="zs_item" (click)="statusFilter = 'signed'">
            {{ "Assinado" | translate }}
            <mat-icon class="mat-icon-high" *ngIf="statusFilter === 'signed'"
                      svgIcon="icon_check"></mat-icon>
          </p>
          <p class="zs_item" (click)="statusFilter = 'not-signed'">
            {{ "Em curso" | translate }}
            <mat-icon class="mat-icon-high" *ngIf="statusFilter === 'not-signed'"
                      svgIcon="icon_check"></mat-icon>
          </p>
        </div>
      </div>
      <div class="zs_setting" *ngIf="this.selectedFiltersShow.includes('date')">
        <div class="zs_accordeon" (click)="periodo = !periodo; selectStatus = false">
          <div class="zs_titulo">
            <mat-icon class="mat-icon-high" *ngIf="!periodo" svgIcon="icon_chevron_right"></mat-icon>
            <mat-icon class="mat-icon-high" *ngIf="periodo" svgIcon="icon_chevron_down"></mat-icon>
            <p>
              {{'Definir período' | translate}}
            </p>
          </div>
          <p class="zs_selecionado">
            {{start_date && end_date ? formatDateFront(start_date) + '-' + formatDateFront(end_date) : start_date ? formatDateFront(start_date) : end_date ? formatDateFront(end_date) : 'Todos' | translate}}
          </p>
        </div>
        <div class="zs_accordeon_open" *ngIf="periodo">
          <div class="zs_content_buttons_calendar">
            <button mat-button class="zs_button" (click)="periodoselect = 'apartir'"
                    [ngClass]="periodoselect === 'apartir' ? 'zs_button_destaque' : ''">
              {{start_date ? formatDateFront(start_date) : 'A partir de' | translate}}
            </button>
            <button mat-button class="zs_button" (click)="periodoselect = 'ateHoje'"
                    [ngClass]="periodoselect !== 'apartir' ? 'zs_button_destaque' : ''">
              {{end_date ? formatDateFront(end_date) : 'Até hoje' | translate }}
            </button>
          </div>
          <mat-calendar *ngIf="periodoselect === 'apartir'"
                        (selectedChange)="onSelectedApartirChange($event)"
                        [selected]="start_date" [maxDate]="hoje" [dateClass]="dateClass()">
          </mat-calendar>
          <mat-calendar *ngIf="periodoselect === 'ateHoje'"
                        (selectedChange)="onSelectedAteChange($event)"
                        [selected]="end_date" [maxDate]="hoje" [minDate]="start_date"
                        [dateClass]="dateClass()">
          </mat-calendar>
        </div>
      </div>
      <div class="zs_setting" *ngIf="this.selectedFiltersShow.includes('searchInFolder')">
        <div class="zs_accordeon" (click)="pasta = !pasta; periodo = false; autorShow= false">
          <div class="zs_titulo">
            <mat-icon class="mat-icon-high" *ngIf="!pasta" svgIcon="icon_chevron_right"></mat-icon>
            <mat-icon class="mat-icon-high" *ngIf="pasta" svgIcon="icon_chevron_down"></mat-icon>
            <p>
              {{'Buscar na pasta' | translate}}
            </p>
          </div>
          <p class="zs_selecionado">
            {{buscarSomentePastaAtual ? ('Pasta Atual' | translate) : ('Todas' | translate) }}
          </p>
        </div>
        <div class="zs_accordeon_open" *ngIf="pasta">
          <div class="zs_content_button_pastas">
            <button mat-button class="zs_button" (click)="buscarSomentePastaAtual = false"
                    [ngClass]="!buscarSomentePastaAtual?'zs_button_destaque': ''">
              <mat-icon class="zs_icon" svgIcon="icon_box"></mat-icon>
              {{'Todas' | translate}}
            </button>
            <button mat-button class="zs_button" (click)="buscarSomentePastaAtual = true"
                    [ngClass]="buscarSomentePastaAtual?'zs_button_destaque': ''">
              <mat-icon class="zs_icon" svgIcon="icon_folder"></mat-icon>
              {{'Atual' | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="zs_content_button">
      <button mat-button class="zs_button_Limpar" (click)="limparBusca(); periodo = false; limpar = false">
        <mat-icon class="zs_icon" svgIcon="icon_filter_lines"></mat-icon>
        {{'Limpar' | translate}}
      </button>
      <button mat-button class="zs_button_Aplicar" (click)="emitFilterChange()" (keyup.enter)="emitFilterChange()">
        {{'Aplicar filtro' | translate}}
      </button>
    </div>
  </div>
</div>
