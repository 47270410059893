import {Folder, FolderLinear} from './../helpers/model/docs';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {AuthService} from '../auth/auth.service';
import {concat, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {TranslatorService} from '../components/translator.service';
import {Language} from '../../translation/translation';

@Injectable({
  providedIn: 'root'
})
export class FoldersService {
  api_url: string = environment.API_URL;
  folder_api_url: string = this.api_url + '/docs/secret-folder/';
  lang: Language = localStorage.getItem('lang') as Language || 'pt-br';

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private _translationService: TranslatorService,
  ) {
  }

  t(ptText: string) {
    return this._translationService.translate(ptText, this.lang);
  }

  prepareFoldersSelect(folders: FolderLinear[]): FolderLinear[] {
    let foldersCopy = Object.assign([], folders);
    foldersCopy = foldersCopy.map(
      (folder: FolderLinear) => {
        folder.full_name = this.recursiveFolderNameLinear(folder, folders);
        return folder;
      }
    );
    foldersCopy = foldersCopy.sort((a, b) => a.full_name > b.full_name ? 1 : -1);
    const noFolder: Readonly<FolderLinear> = {id: 0, full_name: 'Sem pasta', name: this.t('Sem pasta'), token: '0', parent_folder: null};
    const foldersConcat = [noFolder].concat(foldersCopy);
    return foldersConcat;
  }

  recursiveFolderNameNested(folder: Folder, name: string = '') {
    if (folder.parent_folder == null) {
      return `${folder.name}${name}`;
    } else {
      return `${this.recursiveFolderNameNested(folder.parent_folder)}/${folder.name}${name}`;
    }
  }

  recursiveFolderNameLinear(folder: FolderLinear, allFolders: FolderLinear[], name: string = '') {
    if (!folder) {
      return `${name}`;
    }
    if (!folder.parent_folder) {
      return `${folder.name}${name}`;
    } else {
      const parentFolder = this.getFolderById(folder.parent_folder, allFolders);
      return `${this.recursiveFolderNameLinear(parentFolder, allFolders)}/${folder.name}${name}`;
    }
  }

  getFolderById(folder_id: number, folders: FolderLinear[]): FolderLinear {
    return folders.find(folder => folder.id == folder_id);
  }

  getFolderMembership(folder_token: string) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.get<any>(this.folder_api_url + folder_token + '/', {}).pipe(catchError(this.handleError))
    );
  }

  setSecretFolder(folder_token: string = '') {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(this.folder_api_url + 'set-secret/' + folder_token + '/', {}).pipe(catchError(this.handleError))
    );
  }

  // leaveSecretFolder(folder_token: string=''){
  //   return concat(
  //     this.authService.refreshAccessTokenIfNeeded(),
  //     this.http.post<any>(this.folder_api_url+"leave/"+folder_token+"/", {}).pipe( catchError(this.handleError) )
  //   )
  // }

  addMemberToSecretFolder(folder_token: string = '', params: any) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(this.folder_api_url + 'add-member/' + folder_token + '/', params).pipe(catchError(this.handleError))
    );
  }

  openSecretFolderToPublic(folder_token: string = '') {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(this.folder_api_url + 'open-folder/' + folder_token + '/', {}).pipe(catchError(this.handleError))
    );
  }

  removeEmailFromSecretFolder(folder_token: string = '', params: any) {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(this.folder_api_url + 'remove-user/' + folder_token + '/', params).pipe(catchError(this.handleError))
    );
  }

  private handleError(error: HttpErrorResponse) {
    console.log(error, 'Error');
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`
      );
      console.error(error.error);
    }
    return throwError(
      'Something bad happened; please try again later.');
  };
}
