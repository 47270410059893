<mat-form-field appearance="outline">
    <mat-label>{{'Fuso horário' | translate}}</mat-label>
    <mat-select [(ngModel)]="user?.company.timezone" (selectionChange)="updateCompanyTimezone($event.value)">
        <mat-optgroup *ngFor="let country of timezonesOptions" [label]="country.name">
            <mat-option *ngFor="let timezone of country.timezones" [value]="timezone.timezone">
                {{timezone.district}}
            </mat-option>
        </mat-optgroup>
    </mat-select>
</mat-form-field>
