<section>
  <mat-form-field appearance="outline">
    <mat-label>{{'Idioma' | translate}}</mat-label>
    <mat-select [(ngModel)]="selectedLanguage" (selectionChange)="changeLanguageSelect($event)">
      <mat-select-trigger [ngStyle]="styleMatSelectTrigger()">
        <div class="trigger-select">
          <mat-icon
            class="icon_language"
            [svgIcon]="currentLanguage?.icon"
          ></mat-icon>
          {{ currentLanguage?.label }}
        </div>
      </mat-select-trigger>
      <mat-option *ngFor="let language of languages" [value]="language.name">
        <mat-icon [svgIcon]="language.icon"></mat-icon>
        {{language.label}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</section>
