import {Injectable} from '@angular/core';
import {LanguageProps} from 'src/app/auth/login/Login';
import {Language} from 'src/translation/translation';

@Injectable({
  providedIn: 'root'
})
export class MatSelectLanguageService {
  private lang: Language =
    (localStorage.getItem('lang') as Language) || 'pt-br';

  public languages: LanguageProps[] = [
    {name: 'pt-br', label: 'Português', icon: 'icon_brazil'},
    {name: 'en', label: 'English', icon: 'icon_us'},
    {name: 'es', label: 'Español', icon: 'icon_spain'},
    {name: 'fr', label: 'Français', icon: 'icon_france'},
  ];

  public setLangLocalStorage(language: Language) {
    const oldLang = this.lang;
    this.lang = language;
    localStorage.setItem('lang', this.lang);
    if (oldLang !== this.lang) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('lang', this.lang);
      window.location.search = searchParams.toString();
    }
  }

  public renderCorrectLabelAndIconMatSelectTrigger(selectedLanguage: string): LanguageProps {
    const [language] = this.languages.filter(
      ({name}) => name === selectedLanguage
    );
    return language;
  }

  public styleMatSelectTrigger() {
    return {
      color: '#424242',
      fontWeight: 400,
    };
  }
}
