import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Component({
  selector: 'app-modal-remove',
  templateUrl: './modal-remove.component.html',
  styleUrls: ['./modal-remove.component.scss']
})
export class ModalRemoveComponent {
  @Input() title: string;
  @Input() showModal: boolean;
  @Input() labelBtn: string;
  @Input() info: string;
  @Input() titleBtnCancel: string;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirm: EventEmitter<void> = new EventEmitter<void>();

  onConfirm() {
    this.confirm.emit();
  }

  onCloseModal() {
    this.closeModal.emit();
  }

  @HostListener('document:click', ['$event.target'])
  clickOutside(target: HTMLElement) {
    const modal = target.closest('.container-rmv');
    const modalUserGroup = target.closest('.modal-edit-delete-user-group');
    const btnOpenModal = target.closest('.btn-rmv-membership');
    const modalMobileUserGroup = target.closest('.open-mobile-actions-content');
    const modalListUsersGroup = target.closest('.container-rmv-users-group');
    if (!modal && !btnOpenModal && !modalUserGroup && !modalMobileUserGroup && !modalListUsersGroup) {
      this.closeModal.emit();
    }
  }

  preventModalFromClosing($event) {
    $event.stopPropagation();
  }
}
