import {Component} from '@angular/core';

@Component({
  selector: 'app-signer-out-of-order',
  templateUrl: './signer-out-of-order.component.html',
  styleUrls: ['./signer-out-of-order.component.scss']
})
export class SignerOutOfOrderComponent {

}
