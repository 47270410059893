import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


export const routes: Routes = [
  { path: '', redirectTo: '/acesso/entrar', pathMatch: 'full' },
  { path: 'conta', loadChildren: () => import('./client/client.module').then(m => m.ClientModule) },
  { path: 'acesso', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  { path: 'verificar', loadChildren: () => import('./signers/signers.module').then(m => m.SignersModule) },
  { path: 'sign', loadChildren: () => import('./signers/signers.module').then(m => m.SignersModule) },
  { path: 'poc-serpro', loadChildren: () => import('./poc-serpro/poc-serpro.module').then(m => m.PocSerproModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
