import { ActionTypesRequestModal, ActionsRequestModal } from './request-modal.actions';

const INITIAL_STATE: { openOrCloseModalRequestCamera: boolean } = Object.freeze({
  openOrCloseModalRequestCamera: false
});

export const requestModalReducer = (state = INITIAL_STATE, action: ActionsRequestModal) => {
  switch (action.type) {
    case ActionTypesRequestModal.VIEW_MODAL: {
      return {
        ...state,
        openOrCloseModalRequestCamera: true
      };
    }
    case ActionTypesRequestModal.HIDE_MODAL: {
      return {
        ...state,
        openOrCloseModalRequestCamera: false
      };
    }
    default: return state;
  }
};
