<div *ngFor="let plan of apiPlans">
  <div
    class="wrapper-item-plan-api"
    *ngIf="verPlanosApi === plan.api && (plan.ab_test == '' || plan.ab_test == user.ab_test || (user.ab_test == '' && plan.ab_test == 'A'))">
    <p><b>{{plan.name}}</b></p>
    <div class="item-plan-api">

      <p *ngIf="plan.period=='semestral'" class="secondary_text">{{'Equivale a $0/mês' | translate:[plan.price/600 | currency:'BRL':'symbol']}}</p>


      <ng-container *ngIf="plan.period === 'anual'">
        <p *ngIf="plan.installments == 1" class="secondary_text">
          {{'Equivale a $0/mês' | translate:[plan.price/1200 | currency:'BRL':'symbol']}}
        </p>
        <p *ngIf="plan.installments == 12" class="secondary_text">{{'Somente no cartão' | translate}}</p>
      </ng-container>



      <button class="button-buy-plan-api-test" *ngIf="subToChangePlan == null || (subToChangePlan.plan.id != plan.id)" mat-button mat-raised-button color="primary"
              (click)="emitStartPayingFlow(plan)" [disabled]="loadingMudarBtn">
        <span [innerHTML]="subToChangePlan == null ? ('Contratar'| translate):('Mudar' | translate)"></span>&nbsp;
        <span *ngIf="plan.period=='mensal'">({{plan.price/100 | currency:'BRL':'symbol'}}/mês)</span>
        <span *ngIf="plan.period=='semestral'">({{plan.price/100 | currency:'BRL':'symbol'}}/semestre)</span>
        <span *ngIf="plan.period=='anual' && plan.installments == 1">({{plan.price/100 | currency:'BRL':'symbol'}}/ano)</span>
        <span *ngIf="plan.period=='anual' && plan.installments == 12">(12x de {{plan.price/1200 | currency:'BRL':'symbol'}}/mês)</span>
      </button>
      <button class="button-plan-api-now-test" *ngIf="subToChangePlan != null && (subToChangePlan.plan.id == plan.id)"
              disabled mat-raised-button style="margin-left:16px;">
        {{'Plano atual' | translate}}
      </button>
    </div>
  </div>

</div>
