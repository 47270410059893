import {AfterContentInit, Component, HostListener, OnDestroy} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../app.state';
import {CloseRequest, OpenUserRequest} from '../../../reducer/actions/modal-user-actions';

@Component({
  selector: 'app-perfil-select',
  templateUrl: './perfil-select.component.html',
  styleUrls: ['./perfil-select.component.scss'],
})
export class PerfilSelectComponent implements AfterContentInit, OnDestroy {
  showModal = false;
  userEmail = '';
  private subscribeShowModal: any;
  private clickedInside = false;

  constructor(
    public store: Store<AppState>
  ) {
  }

  ngAfterContentInit(): void {
    this.userEmail = localStorage.getItem('user_email');
    this.subscribeShowModal = this.store.select('modalUser').subscribe(
      ({open}) => {
        this.showModal = open;
      }
    );
  }

  ngOnDestroy(): void {
    this.subscribeShowModal.unsubscribe();
  }

  openModal() {
    if (this.showModal) {
      this.store.dispatch(new CloseRequest());
    } else {
      this.store.dispatch(new OpenUserRequest());
    }
  }

  @HostListener('click')
  clickInside() {
    this.clickedInside = true;
  }

  @HostListener('document:click', ['$event.target'])
  clickOutside(target: HTMLElement) {
    if (this.shouldCloseModal(target)) {
      this.store.dispatch(new CloseRequest());
    }
    this.clickedInside = false;
  }

  private shouldCloseModal(target: HTMLElement): boolean {
    if (this.clickedInside) { return false; }
    return !this.hasClickShield(target);
  }

  private hasClickShield(target: HTMLElement) {
    return target.closest('.modal_click_shield');
  }


}
