<div class="background">
  <div class="container">
    <div class="header">
      <h2 class="title">{{title | translate}}</h2>
      <zs-button typeButton="icon" icon="icon_x_close" (click)="onCloseModal()"></zs-button>
    </div>
    <span class="text">{{text | translate}}</span>
    <span *ngIf="subtitle" class="subtitle">{{subtitle | translate}}</span>
    <div *ngIf="attentionMessage" class="container-attention">
      <mat-icon svgIcon="alert-triangle"></mat-icon>
      <span class="attention-message">{{attentionMessage | translate}}</span>
    </div>
    <div class="container-btns">
      <zs-button class="btn-cancel" typeButton="quaternary" [label]="btnTextCancel" (click)="onCloseModal()"></zs-button>
      <zs-button class="btn-confirm" typeButton="primary" [label]="btnTextConfirm" (click)="onConfirm()"></zs-button>
    </div>
  </div>
</div>
