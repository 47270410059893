import {Injectable} from '@angular/core';
import {SlackMessage} from '@shared/services/slack/builders/slack-message.model';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {HandleErrorsRequestService} from '@shared/services/handle-errors-request-service/handle-errors-request.service';
import {concat} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SlackService {
  apiUrl: string = environment.API_URL;
  slackApiUrl = `${this.apiUrl}/slack`;

  constructor(
    private httpClient: HttpClient,
    private handleErrorsRequestService: HandleErrorsRequestService
  ) {
  }

  send(message: SlackMessage) {
    return concat(
      this.httpClient.post<any>(`${this.slackApiUrl}/send-message/`, {...message})
        .pipe(catchError(this.handleErrorsRequestService.getErrorRequest))
    ).subscribe();
  }
}
