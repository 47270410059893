import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {UtilsService} from '../../../shared/services/utils/utils.service';

@Component({
  selector: 'app-actions-signature',
  templateUrl: './actions-signature.component.html',
  styleUrls: ['./actions-signature.component.scss']
})
export class ActionsSignatureComponent implements OnInit {

  isMobile = false;
  @Input() isEmpty: boolean;

  @Output() emitClearSignature = new EventEmitter<void>();
  @Output() emitOpenModalNoCameraAvailable = new EventEmitter<void>();

  constructor(
    private device: DeviceDetectorService,
    private utils: UtilsService
  ) {
  }

  ngOnInit(): void {
    this.isMobile = this.device.isMobile();
  }

  emitterClearSignature() {
    this.emitClearSignature.emit();
  }

  openModalNotHaveCamera() {
    this.utils.openModalNoCameraAvailable('sign-page');
  }
}
