<div class="zs-user-group">
  <div class="zs-user-group-body">
    <div *ngIf="userGroups.length === 0" class="zs-user-group-no-groups">
      <p>
        {{"Parece que você ainda não possui nenhum grupo de usuários definido. Clique em" | translate}}
        <strong> + {{"Novo Grupo" | translate}} </strong>{{"para criar um grupo e personalizar o nível de acesso dele." | translate}}
      </p>
      <app-old-modal-user-groups-buttons
        [state]="state"
        [closeButton]="false"
        (changeState)="changeState()">
      </app-old-modal-user-groups-buttons>
    </div>
    <div *ngIf="userGroups.length > 0">
      <app-old-modal-user-group-list
        [state]="state"
        (goToEdit)="goToEdit($event)"
        (goToNew)="goToNew()"
        (deletedEmitter)="deleteUserGroup()">
      </app-old-modal-user-group-list>
    </div>
  </div>
</div>
