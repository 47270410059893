import {AfterContentInit, Component, OnDestroy} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../app.state';
import {CloseRequest, OpenCompanyRequest} from '../../../reducer/actions/modal-user-actions';


@Component({
  selector: 'app-company-select',
  templateUrl: './company-select.component.html',
  styleUrls: ['./company-select.component.scss']
})
export class CompanySelectComponent implements AfterContentInit, OnDestroy {
  isMultiCompany = false;
  showModal = false;
  highlight = '';
  memberships = [];
  loading = true;
  currentCompany: number;
  private subscribeShowModal: any;
  private subscribehighlight: any;
  private subscribeMemberships: any;

  constructor(
    private store: Store<AppState>,
  ) {

  }
  ngAfterContentInit(): void {
    if (localStorage.getItem('multi_companies') === '1') {
      this.isMultiCompany = true;
    }
    if (localStorage.getItem('companyId')) {
      this.currentCompany = parseInt(localStorage.getItem('companyId'));
    }
    this.subscribeShowModal = this.store.select('modalUser').subscribe(
      ({ open }) => {
        this.showModal = open;
      }
    );
    this.subscribehighlight = this.store.select('modalUser').subscribe(
      ({ highlight }) => {
        this.highlight = highlight;
      }
    );
    this.subscribeMemberships = this.store.select('memberships').subscribe(
      ({ Memberships }) => {
        // @ts-ignore
        this.memberships = Memberships.filter(membership => membership.company.id === this.currentCompany )[0];
      }
    );
  }

  ngOnDestroy(): void {
    this.subscribeShowModal.unsubscribe();
    this.subscribehighlight.unsubscribe();
    this.subscribeMemberships.unsubscribe();
  }

  openModal() {
    if (this.showModal && this.highlight === 'company') {
      this.store.dispatch(new CloseRequest());
    } else {
      this.store.dispatch(new OpenCompanyRequest());
    }
  }
}
