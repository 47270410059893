import { HideModalRequestCamera } from './../store/RequestModal/request-modal.actions';
import { AppState } from './../../../app.state';
import { Store } from '@ngrx/store';
import { Component } from '@angular/core';

@Component({
  selector: 'app-request-modal-old-old',
  templateUrl: './request-modal-old.component.html',
  styleUrls: ['./request-modal-old.component.scss']
})
export class RequestModalOldComponent {
  language: string = navigator.language || 'en-US';
  modalOpen = false;

  constructor(
    public store: Store<AppState>
  ) {
    store.select('requestModalPermissionCamera').subscribe(
      ({ openOrCloseModalRequestCamera }) =>
        this.modalOpen = openOrCloseModalRequestCamera
      );
    }

  closeModal() {
    this.store.dispatch(new HideModalRequestCamera());
  }
}
