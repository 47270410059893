<div [id]="this.index" class="container-modal">
  <ng-container *ngIf="modalOption; else optionThree">
    <div class="container">
      <ng-container *ngFor="let item of itens">
        <button class="button" (click)="item.option ? item.option() : ''">
          <div class="button-left">
            <mat-icon *ngIf="item.iconOption" svgIcon="{{item.iconOption}}"></mat-icon>
            <span class="text-ellipsis" [ngClass]="textEllipsis" >{{item.textOption | translate}}</span>
          </div>
          <div *ngIf="item.status" class="status-circle" [ngClass]="item.status"></div>
        </button>
      </ng-container>
    </div>
  </ng-container>

  <ng-template #optionThree>
    <div class="container-option-three">
      <ng-container *ngFor="let item of itens">
        <button [ngClass]="item.iconOption === 'icon_whatsappNew' ? 'icon-whatsapp-new' : ''" class="button-option-three" (click)="item.option()">
          <mat-icon *ngIf="item.iconOption" svgIcon="{{item.iconOption}}"></mat-icon>
          <span>{{item.textOption | translate}}</span>
        </button>
      </ng-container>
    </div>
  </ng-template>
</div>
