import {Action, createReducer, on} from '@ngrx/store';
import {setIsFirstUserActions} from './set-is-first-user.actions';
import {SetIsFirstUserProps} from './set-is-first-user.model';

export const INITIAL_STATE: SetIsFirstUserProps = {
  first_user: true
};

// tslint:disable-next-line:variable-name
const _setIsFirstUserReducer = createReducer(
  INITIAL_STATE,
  on(setIsFirstUserActions, (state, {payload}) => {
    return {first_user: payload};
  }),
);

export function setIsFirstUserReducer(state: SetIsFirstUserProps, action: Action) {
  return _setIsFirstUserReducer(state, action);
}
