import { Component } from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../app.state';
import {HideModalRequestCamera} from '../store/RequestModal/request-modal.actions';

@Component({
  selector: 'app-request-modal',
  templateUrl: './request-modal.component.html',
  styleUrls: ['./request-modal.component.scss']
})
export class RequestModalComponent {
  language: string = navigator.language || 'en-US';
  modalOpen = false;

  constructor(
    public store: Store<AppState>
  ) {
    store.select('requestModalPermissionCamera').subscribe(
      ({ openOrCloseModalRequestCamera }) =>
        this.modalOpen = openOrCloseModalRequestCamera
    );
  }

  closeModal() {
    this.store.dispatch(new HideModalRequestCamera());
  }
}
