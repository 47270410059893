import {Injectable} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

export interface AngularMaterialIconsPersonalize {
  iconName: string;
  iconPath: string;
}

@Injectable({
  providedIn: 'root'
})
export class IconRegistryService {
  private readonly relativePathToIcons = `../assets/img/icons`;

  icons: Readonly<AngularMaterialIconsPersonalize>[] = [
    //   fora de pastas
    {
      iconName: 'approve_icon',
      iconPath: `${this.relativePathToIcons}/approve-icon.svg`
    },
    {
      iconName: 'approved_background_blue_icon',
      iconPath: `${this.relativePathToIcons}/approved-background-blue.svg`
    },
    {
      iconName: 'vector_icon',
      iconPath: `${this.relativePathToIcons}/vector_icon.svg`
    },
    {
      iconName: 'vector_icon_white',
      iconPath: `${this.relativePathToIcons}/vector_icon_white.svg`
    },
    {
      iconName: 'cpf',
      iconPath: `${this.relativePathToIcons}/cpf.svg`
    },
    {
      iconName: 'download',
      iconPath: `${this.relativePathToIcons}/download.svg`
    },
    {
      iconName: 'icon_x_blue',
      iconPath: `${this.relativePathToIcons}/icon-x.svg`
    },
    {
      iconName: 'icon_x_outline',
      iconPath: `${this.relativePathToIcons}/icon-x-outline.svg`
    },
    {
      iconName: 'reload_file',
      iconPath: `${this.relativePathToIcons}/file-and-folder/reload_file.svg`
    },
    {
      iconName: 'edit_pencil',
      iconPath: `${this.relativePathToIcons}/edit-pencil.svg`
    },
    {
      iconName: 'digital_certificate',
      iconPath: `${this.relativePathToIcons}/digital-certificate.svg`
    },
    {
      iconName: 'code-auth',
      iconPath: `${this.relativePathToIcons}/code-auth.svg`
    },
    {
      iconName: 'camera',
      iconPath: `${this.relativePathToIcons}/camera.svg`
    },
    {
      iconName: 'video',
      iconPath: `${this.relativePathToIcons}/video.svg`
    },
    {
      iconName: 'identity-doc',
      iconPath: `${this.relativePathToIcons}/identity-doc.svg`
    },
    {
      iconName: 'cpf-auth',
      iconPath: `${this.relativePathToIcons}/cpf-auth.svg`
    },
    {
      iconName: 'cpf-premium-auth',
      iconPath: `${this.relativePathToIcons}/cpf-premium-auth.svg`
    },
    {
      iconName: 'auth_info',
      iconPath: `${this.relativePathToIcons}/auth_info.svg`
    },
    {
      iconName: 'icon_zapSign',
      iconPath: `${this.relativePathToIcons}/Ellipse 2.svg`
    },
    {
      iconName: 'icon_x_close',
      iconPath: `${this.relativePathToIcons}/user-interface/x-close.svg`
    },
    {
      iconName: 'icon_menu_open',
      iconPath: `${this.relativePathToIcons}/user-interface/menu-02.svg`
    },
    {
      iconName: 'icon_notification',
      iconPath: `${this.relativePathToIcons}/user-interface/notification.svg`
    },
    {
      iconName: 'icon_chevron_down',
      iconPath: `${this.relativePathToIcons}/arrows/chevron-down.svg`
    },
    {
      iconName: 'icon_chevron_up',
      iconPath: `${this.relativePathToIcons}/arrows/chevron-up.svg`
    },
    {
      iconName: 'icon_user_new',
      iconPath: `${this.relativePathToIcons}/users/user_new.svg`
    },
    {
      iconName: 'icon_code',
      iconPath: `${this.relativePathToIcons}/user-interface/code.svg`
    },
    {
      iconName: 'icon_chevron_right',
      iconPath: `${this.relativePathToIcons}/arrows/chevron-right.svg`
    },
    {
      iconName: 'icon_dataflow',
      iconPath: `${this.relativePathToIcons}/user-interface/dataflow-01.svg`
    },
    {
      iconName: 'icon_dataflow_02',
      iconPath: `${this.relativePathToIcons}/development/dataflow-02.svg`
    },
    {
      iconName: 'brush_02',
      iconPath: `${this.relativePathToIcons}/editor/brush-02.svg`
    },
    {
      iconName: 'icon_currency_dollar_circle',
      iconPath: `${this.relativePathToIcons}/business/currency-dollar-circle.svg`
    },
    {
      iconName: 'icon_log_out',
      iconPath: `${this.relativePathToIcons}/user-interface/log-out-01.svg`
    },
    {
      iconName: 'icon_check',
      iconPath: `${this.relativePathToIcons}/user-interface/check.svg`
    },
    {
      iconName: 'icon_whatsappNew',
      iconPath: `${this.relativePathToIcons}/user-interface/whatsapp.svg`
    },
    {
      iconName: 'icon_dots_grid',
      iconPath: `${this.relativePathToIcons}/user-interface/dots-grid.svg`
    },
    {
      iconName: 'icon_settings_04',
      iconPath: `${this.relativePathToIcons}/general/settings-04.svg`
    },
    {
      iconName: 'icon_search_sm',
      iconPath: `${this.relativePathToIcons}/general/search-sm.svg`
    },
    {
      iconName: 'icon_filter_lines',
      iconPath: `${this.relativePathToIcons}/general/filter-lines.svg`
    },
    {
      iconName: 'icon_folder',
      iconPath: `${this.relativePathToIcons}/files/folder.svg`
    },
    {
      iconName: 'icon_box',
      iconPath: `${this.relativePathToIcons}/files/box.svg`
    },
    {
      iconName: 'download-02',
      iconPath: `${this.relativePathToIcons}/general/download-02.svg`
    },
    {
      iconName: 'chevron-right-02',
      iconPath: `${this.relativePathToIcons}/arrows/chevron-right-02.svg`
    },
    {
      iconName: 'chevron-left-02',
      iconPath: `${this.relativePathToIcons}/arrows/chevron-left-02.svg`
    },
    {
      iconName: 'loading_01',
      iconPath: `${this.relativePathToIcons}/general/loading-01.svg`
    },
    {
      iconName: 'check_circle',
      iconPath: `${this.relativePathToIcons}/general/check-circle.svg`
    },
    {
      iconName: 'check_circle_blue',
      iconPath: `${this.relativePathToIcons}/general/check-circle-blue.svg`
    },
    {
      iconName: 'check_filled',
      iconPath: `${this.relativePathToIcons}/filled/check-filled.svg`
    },
    {
      iconName: 'check_circle_broken',
      iconPath: `${this.relativePathToIcons}/general/check-circle-broken.svg`
    },
    {
      iconName: 'upload_04',
      iconPath: `${this.relativePathToIcons}/general/upload-04.svg`
    },
    {
      iconName: 'upload',
      iconPath: `${this.relativePathToIcons}/user-interface/upload.svg`
    },
    {
      iconName: 'x_circle',
      iconPath: `${this.relativePathToIcons}/general/x-circle.svg`
    },
    {
      iconName: 'help_octagon',
      iconPath: `${this.relativePathToIcons}/general/help-octagon.svg`
    },
    {
      iconName: 'x_square',
      iconPath: `${this.relativePathToIcons}/general/x-square.svg`
    },
    {
      iconName: 'server_01',
      iconPath: `${this.relativePathToIcons}/development/server-01.svg`
    },
    {
      iconName: 'bar_code1',
      iconPath: `${this.relativePathToIcons}/development/bar-code1.svg`
    },
    {
      iconName: 'coins_04',
      iconPath: `${this.relativePathToIcons}/finance-and-ecommerce/coins-04.svg`
    },
    {
      iconName: 'tag_02',
      iconPath: `${this.relativePathToIcons}/finance-and-ecommerce/tag-02.svg`
    },
    {
      iconName: 'icon_arrow_down',
      iconPath: `${this.relativePathToIcons}/arrows/arrow-down.svg`
    },
    {
      iconName: 'icon_arrow_right',
      iconPath: `${this.relativePathToIcons}/arrows/arrow-right.svg`
    },
    {
      iconName: 'icon_arrow_up',
      iconPath: `${this.relativePathToIcons}/arrows/arrow-up.svg`
    },
    {
      iconName: 'info_square',
      iconPath: `${this.relativePathToIcons}/general/info-square.svg`
    },
    {
      iconName: 'slash_circle_02',
      iconPath: `${this.relativePathToIcons}/general/slash-circle-02.svg`
    },
    {
      iconName: 'recover_document',
      iconPath: `${this.relativePathToIcons}/files/recover-document.svg`
    },
    {
      iconName: 'trash-04',
      iconPath: `${this.relativePathToIcons}/general/trash-04.svg`
    },
    {
      iconName: 'user_01',
      iconPath: `${this.relativePathToIcons}/users/user-01.svg`
    },
    {
      iconName: 'file_06',
      iconPath: `${this.relativePathToIcons}/files/file-06.svg`
    },
    {
      iconName: 'folder-download',
      iconPath: `${this.relativePathToIcons}/files/folder-download.svg`
    },
    {
      iconName: 'check-verified-01',
      iconPath: `${this.relativePathToIcons}/general/check-verified-01.svg`
    },
    {
      iconName: 'plus',
      iconPath: `${this.relativePathToIcons}/general/plus.svg`
    },
    {
      iconName: 'plus_circle',
      iconPath: `${this.relativePathToIcons}/general/plus-circle.svg`
    },
    {
      iconName: 'tag_01',
      iconPath: `${this.relativePathToIcons}/finance-and-ecommerce/tag-01.svg`
    },
    {
      iconName: 'zapcoin',
      iconPath: `${this.relativePathToIcons}/finance-and-ecommerce/zapcoin.svg`
    },
    {
      iconName: 'zapcoin-01',
      iconPath: `${this.relativePathToIcons}/finance-and-ecommerce/zapcoin-01.svg`
    },
    {
      iconName: 'folder-plus',
      iconPath: `${this.relativePathToIcons}/files/folder-plus.svg`
    },
    {
      iconName: 'check',
      iconPath: `${this.relativePathToIcons}/general/check.svg`
    },
    {
      iconName: 'file-plus-02',
      iconPath: `${this.relativePathToIcons}/files/file-plus-02.svg`
    },
    {
      iconName: 'box',
      iconPath: `${this.relativePathToIcons}/files/box.svg`
    },
    {
      iconName: 'users-02',
      iconPath: `${this.relativePathToIcons}/users/users-02.svg`
    },
    {
      iconName: 'x-sum',
      iconPath: `${this.relativePathToIcons}/general/x-sum.svg`
    },
    {
      iconName: 'arrow-narrow-left',
      iconPath: `${this.relativePathToIcons}/arrows/arrow-narrow-left.svg`
    },
    {
      iconName: 'three-dots',
      iconPath: `${this.relativePathToIcons}/general/three-dots.svg`
    },
    {
      iconName: 'three-blue-dots',
      iconPath: `${this.relativePathToIcons}/three-blue-dots.svg`
    },
    {
      iconName: 'qr_code_01',
      iconPath: `${this.relativePathToIcons}/development/qr-code-01.svg`
    },
    {
      iconName: 'button-contacts',
      iconPath: `${this.relativePathToIcons}/icon-button-contacts.svg`
    },
    {
      iconName: 'close-modal-contacts',
      iconPath: `${this.relativePathToIcons}/close-modal-contacts.svg`
    },
    {
      iconName: 'group-contacts',
      iconPath: `${this.relativePathToIcons}/group-contact.svg`
    },
    {
      iconName: 'saved-contacts',
      iconPath: `${this.relativePathToIcons}/saved-contacts.svg`
    },
    {
      iconName: 'more-contacts-table',
      iconPath: `${this.relativePathToIcons}/more-contacts-table.svg`
    },
    {
      iconName: 'star-not-favorite',
      iconPath: `${this.relativePathToIcons}/star-not-favorite.svg`
    },
    {
      iconName: 'star-favorite',
      iconPath: `${this.relativePathToIcons}/star-favorite.svg`
    },
    {
      iconName: 'delete-contact-icon',
      iconPath: `${this.relativePathToIcons}/delete-contact-icon.svg`
    },
    {
      iconName: 'not-contacts',
      iconPath: `${this.relativePathToIcons}/not-contacts.svg`
    },
    {
      iconName: 'not-group-contacts',
      iconPath: `${this.relativePathToIcons}/not-group-contacts.svg`
    },
    {
      iconName: 'close-modal',
      iconPath: `${this.relativePathToIcons}/close-modal.svg`
    },
    {
      iconName: 'add',
      iconPath: `${this.relativePathToIcons}/add.svg`
    },
    {
      iconName: 'refresh-cw-04',
      iconPath: `${this.relativePathToIcons}/refresh-cw-04.svg`
    },
    {
      iconName: 'single-file-download',
      iconPath: `${this.relativePathToIcons}/single-file-download.svg`
    },
    {
      iconName: 'single-file-upload',
      iconPath: `${this.relativePathToIcons}/single-file-upload.svg`
    },
  //   pastas
  //   alerts-and-feedback
    {
      iconName: 'bell-ringing-04',
      iconPath: `${this.relativePathToIcons}/alerts-and-feedback/bell-ringing-04.svg`
    },
    {
      iconName: 'alert-triangle',
      iconPath: `${this.relativePathToIcons}/alerts-and-feedback/alert-triangle.svg`
    },
  //   arrows
    {
      iconName: 'arrow-narrow-right',
      iconPath: `${this.relativePathToIcons}/arrows/arrow-narrow-right.svg`
    },
    {
      iconName: 'circular-loading',
      iconPath: `${this.relativePathToIcons}/arrows/circular-loading.svg`
    },
    {
      iconName: 'arrow-left',
      iconPath: `${this.relativePathToIcons}/arrows/arrow-left.svg`
    },
    {
      iconName: 'arrow_narrow_up_right',
      iconPath: `${this.relativePathToIcons}/arrows/arrow-narrow-up-right.svg`
    },
    {
      iconName: 'arrow-down-black',
      iconPath: `${this.relativePathToIcons}/arrows/arrow-down-black.svg`
    },
    {
      iconName: 'arrow-narrow-left',
      iconPath: `${this.relativePathToIcons}/arrows/arrow-narrow-left.svg`
    },
    {
      iconName: 'icon_code',
      iconPath: `${this.relativePathToIcons}/arrows/code.svg`
    },
    {
      iconName: 'open_in_new_tab',
      iconPath: `${this.relativePathToIcons}/arrows/open_in_new_tab.svg`
    },
    {
      iconName: 'right_arrow',
      iconPath: `${this.relativePathToIcons}/arrows/right_arrow.svg`
    },
    {
      iconName: 'right_arrow_blue',
      iconPath: `${this.relativePathToIcons}/arrows/right_arrow_blue.svg`
    },
    {
      iconName: 'arrow_down_white',
      iconPath: `${this.relativePathToIcons}/arrows/arrow_down_white.svg`
    },
    {
      iconName: 'icon_chevron_down',
      iconPath: `${this.relativePathToIcons}/arrows/chevron-down.svg`
    },
    {
      iconName: 'icon_chevron_up',
      iconPath: `${this.relativePathToIcons}/arrows/chevron-up.svg`
    },
    {
      iconName: 'icon_chevron_right',
      iconPath: `${this.relativePathToIcons}/arrows/chevron-right.svg`
    },
    {
      iconName: 'chevron-right-02',
      iconPath: `${this.relativePathToIcons}/arrows/chevron-right-02.svg`
    },
    {
      iconName: 'chevron-left-02',
      iconPath: `${this.relativePathToIcons}/arrows/chevron-left-02.svg`
    },
    {
      iconName: 'icon_arrow_down',
      iconPath: `${this.relativePathToIcons}/arrows/arrow-down.svg`
    },
    {
      iconName: 'icon_arrow_right',
      iconPath: `${this.relativePathToIcons}/arrows/arrow-right.svg`
    },
    {
      iconName: 'icon_arrow_up',
      iconPath: `${this.relativePathToIcons}/arrows/arrow-up.svg`
    },
    //   brands
    {
      iconName: 'whatsapp',
      iconPath: `${this.relativePathToIcons}/brands/whatsapp.svg`
    },
  //   business
    {
      iconName: 'icon_graph',
      iconPath: `${this.relativePathToIcons}/business/graph.svg`
    },
    {
      iconName: 'icon_attach_money',
      iconPath: `${this.relativePathToIcons}/business/attach_money.svg`
    },
    {
      iconName: 'icon_bar_chart',
      iconPath: `${this.relativePathToIcons}/business/bar-chart-10.svg`
    },
    {
      iconName: 'icon_currency_dollar_circle',
      iconPath: `${this.relativePathToIcons}/business/currency-dollar-circle.svg`
    },
  //   communication
    {
      iconName: 'email',
      iconPath: `${this.relativePathToIcons}/communication/email.svg`
    },
    {
      iconName: 'icon_gift',
      iconPath: `${this.relativePathToIcons}/communication/gift.svg`
    },
    {
      iconName: 'icon_gift_white',
      iconPath: `${this.relativePathToIcons}/communication/gift_white.svg`
    },
    {
      iconName: 'mail-01',
      iconPath: `${this.relativePathToIcons}/communication/mail-01.svg`
    },
    {
      iconName: 'icon_mail',
      iconPath: `${this.relativePathToIcons}/communication/mail.svg`
    },
  //   design-tools
    {
      iconName: 'eye_white',
      iconPath: `${this.relativePathToIcons}/design-tools/eye_white.svg`
    },
  //   development
    {
      iconName: 'icon_dataflow_02',
      iconPath: `${this.relativePathToIcons}/development/dataflow-02.svg`
    },
    {
      iconName: 'server_01',
      iconPath: `${this.relativePathToIcons}/development/server-01.svg`
    },
    {
      iconName: 'bar_code1',
      iconPath: `${this.relativePathToIcons}/development/bar-code1.svg`
    },
    {
      iconName: 'qr-code-01',
      iconPath: `${this.relativePathToIcons}/development/qr-code-01.svg`
    },
  //   editor
    {
      iconName: 'brush_02',
      iconPath: `${this.relativePathToIcons}/editor/brush-02.svg`
    },
  //   file-and-folder
    {
      iconName: 'icon_documents',
      iconPath: `${this.relativePathToIcons}/file-and-folder/documents.svg`
    },
    {
      iconName: 'icon_model',
      iconPath: `${this.relativePathToIcons}/file-and-folder/model.svg`
    },
    {
      iconName: 'reload_file',
      iconPath: `${this.relativePathToIcons}/file-and-folder/reload_file.svg`
    },
    {
      iconName: 'icon_layout_alt',
      iconPath: `${this.relativePathToIcons}/file-and-folder/layout-alt.svg`
    },
    {
      iconName: 'icon_file_search',
      iconPath: `${this.relativePathToIcons}/file-and-folder/file-search.svg`
    },
  //   files
    {
      iconName: 'icon_folder',
      iconPath: `${this.relativePathToIcons}/files/folder.svg`
    },
    {
      iconName: 'icon_box',
      iconPath: `${this.relativePathToIcons}/files/box.svg`
    },
    {
      iconName: 'recover_document',
      iconPath: `${this.relativePathToIcons}/files/recover-document.svg`
    },
    {
      iconName: 'file_06',
      iconPath: `${this.relativePathToIcons}/files/file-06.svg`
    },
    {
      iconName: 'folder-download',
      iconPath: `${this.relativePathToIcons}/files/folder-download.svg`
    },
    {
      iconName: 'folder-plus',
      iconPath: `${this.relativePathToIcons}/files/folder-plus.svg`
    },
    {
      iconName: 'file-plus-02',
      iconPath: `${this.relativePathToIcons}/files/file-plus-02.svg`
    },
    {
      iconName: 'box',
      iconPath: `${this.relativePathToIcons}/files/box.svg`
    },
    {
      iconName: 'folder-move',
      iconPath: `${this.relativePathToIcons}/files/folder-move.svg`
    },
    {
      iconName: 'file_02',
      iconPath: `${this.relativePathToIcons}/files/file_02.svg`
    },
  //   finance-and-ecommerce
    {
      iconName: 'coins_04',
      iconPath: `${this.relativePathToIcons}/finance-and-ecommerce/coins-04.svg`
    },
    {
      iconName: 'tag_02',
      iconPath: `${this.relativePathToIcons}/finance-and-ecommerce/tag-02.svg`
    },
    {
      iconName: 'tag_01',
      iconPath: `${this.relativePathToIcons}/finance-and-ecommerce/tag-01.svg`
    },
    {
      iconName: 'zapcoin',
      iconPath: `${this.relativePathToIcons}/finance-and-ecommerce/zapcoin.svg`
    },
    {
      iconName: 'zapcoin-01',
      iconPath: `${this.relativePathToIcons}/finance-and-ecommerce/zapcoin-01.svg`
    },
    {
      iconName: 'shopping-cart-02',
      iconPath: `${this.relativePathToIcons}/finance-and-ecommerce/shopping-cart-02.svg`
    },
    {
      iconName: 'coins-04',
      iconPath: `${this.relativePathToIcons}/finance-and-ecommerce/coins-04.svg`
    },
  //   flag
    {
      iconName: 'icon_brazil',
      iconPath: `${this.relativePathToIcons}/flag/icon_br.svg`
    },
    {
      iconName: 'icon_us',
      iconPath: `${this.relativePathToIcons}/flag/icon_us.svg`
    },
    {
      iconName: 'icon_spain',
      iconPath: `${this.relativePathToIcons}/flag/icon_spain.svg`
    },
    {
      iconName: 'icon_france',
      iconPath: `${this.relativePathToIcons}/flag/icon_france.svg`
    },
  //   general
    {
      iconName: 'edit_04',
      iconPath: `${this.relativePathToIcons}/general/edit-04.svg`
    },
    {
      iconName: 'icon_settings_04',
      iconPath: `${this.relativePathToIcons}/general/settings-04.svg`
    },
    {
      iconName: 'icon_search_sm',
      iconPath: `${this.relativePathToIcons}/general/search-sm.svg`
    },
    {
      iconName: 'icon_filter_lines',
      iconPath: `${this.relativePathToIcons}/general/filter-lines.svg`
    },
    {
      iconName: 'download-02',
      iconPath: `${this.relativePathToIcons}/general/download-02.svg`
    },
    {
      iconName: 'loading_01',
      iconPath: `${this.relativePathToIcons}/general/loading-01.svg`
    },
    {
      iconName: 'check_circle',
      iconPath: `${this.relativePathToIcons}/general/check-circle.svg`
    },
    {
      iconName: 'check_circle_broken',
      iconPath: `${this.relativePathToIcons}/general/check-circle-broken.svg`
    },
    {
      iconName: 'x_circle',
      iconPath: `${this.relativePathToIcons}/general/x-circle.svg`
    },
    {
      iconName: 'help_octagon',
      iconPath: `${this.relativePathToIcons}/general/help-octagon.svg`
    },
    {
      iconName: 'x_square',
      iconPath: `${this.relativePathToIcons}/general/x-square.svg`
    },
    {
      iconName: 'info_square',
      iconPath: `${this.relativePathToIcons}/general/info-square.svg`
    },
    {
      iconName: 'slash_circle_02',
      iconPath: `${this.relativePathToIcons}/general/slash-circle-02.svg`
    },
    {
      iconName: 'trash-04',
      iconPath: `${this.relativePathToIcons}/general/trash-04.svg`
    },
    {
      iconName: 'check-verified-01',
      iconPath: `${this.relativePathToIcons}/general/check-verified-01.svg`
    },
    {
      iconName: 'plus',
      iconPath: `${this.relativePathToIcons}/general/plus.svg`
    },
    {
      iconName: 'plus_circle',
      iconPath: `${this.relativePathToIcons}/general/plus-circle.svg`
    },
    {
      iconName: 'check',
      iconPath: `${this.relativePathToIcons}/general/check.svg`
    },
    {
      iconName: 'x-sum',
      iconPath: `${this.relativePathToIcons}/general/x-sum.svg`
    },
    {
      iconName: 'three-dots',
      iconPath: `${this.relativePathToIcons}/general/three-dots.svg`
    },
    {
      iconName: 'edit-05',
      iconPath: `${this.relativePathToIcons}/general/edit-05.svg`
    },
    {
      iconName: 'loading-02',
      iconPath: `${this.relativePathToIcons}/general/loading-02.svg`
    },
    {
      iconName: 'link-external-01',
      iconPath: `${this.relativePathToIcons}/general/link-external-01.svg`
    },
    {
      iconName: 'eye',
      iconPath: `${this.relativePathToIcons}/general/eye.svg`
    },
    {
      iconName: 'eye-off',
      iconPath: `${this.relativePathToIcons}/general/eye-off.svg`
    },
    {
      iconName: 'qrcode',
      iconPath: `${this.relativePathToIcons}/general/pix.svg`
    },
    {
      iconName: 'rotate-phone',
      iconPath: `${this.relativePathToIcons}/general/rotate-phone.svg`
    },
    {
      iconName: 'Ellipse',
      iconPath: `${this.relativePathToIcons}/general/Ellipse 2.svg`
    },
    {
      iconName: 'copy-07',
      iconPath: `${this.relativePathToIcons}/general/copy-07.svg`
    },
    {
      iconName: 'log-in-04',
      iconPath: `${this.relativePathToIcons}/general/log-in-04.svg`
    },
    {
      iconName: 'info-circle',
      iconPath: `${this.relativePathToIcons}/general/info-circle.svg`
    },
    {
      iconName: 'settings-02',
      iconPath: `${this.relativePathToIcons}/general/settings-02.svg`
    },
    {
      iconName: 'share-06',
      iconPath: `${this.relativePathToIcons}/general/share-06.svg`
    },
    {
      iconName: 'face_smiles',
      iconPath: `${this.relativePathToIcons}/users/face-smile.svg`
    },
    {
      iconName: 'link-03',
      iconPath: `${this.relativePathToIcons}/general/link-03.svg`
    },
    {
      iconName: 'x_sm',
      iconPath: `${this.relativePathToIcons}/general/x-sm.svg`
    },
    {
      iconName: 'x-sm',
      iconPath: `${this.relativePathToIcons}/general/x-sm.svg`
    },
    {
      iconName: 'upload-02',
      iconPath: `${this.relativePathToIcons}/general/upload-02.svg`
    },
    {
      iconName: 'upload-04',
      iconPath: `${this.relativePathToIcons}/general/upload-04.svg`
    },
  //   images
    {
      iconName: 'image-user-plus',
      iconPath: `${this.relativePathToIcons}/images/image-user-plus.svg`
    },
    {
      iconName: 'camera-01',
      iconPath: `${this.relativePathToIcons}/images/camera-01.svg`
    },
  //   layout
    {
      iconName: 'grid-02',
      iconPath: `${this.relativePathToIcons}/layout/grid-02.svg`
    },
  //   main
    {
      iconName: 'Aa-icon',
      iconPath: `${this.relativePathToIcons}/main/Aa-icon.svg`
    },
  //   media-devices
    {
      iconName: 'recording-01',
      iconPath: `${this.relativePathToIcons}/media-devices/recording-01.svg`
    },
    {
      iconName: 'liveness',
      iconPath: `${this.relativePathToIcons}/media-devices/liveness.svg`
    },
  //   security
    {
      iconName: 'shield-tick',
      iconPath: `${this.relativePathToIcons}/security/shield-tick.svg`
    },
    {
      iconName: 'lock-01',
      iconPath: `${this.relativePathToIcons}/security/lock-01.svg`
    },
    {
      iconName: 'lock-03',
      iconPath: `${this.relativePathToIcons}/security/lock-03.svg`
    },
    {
      iconName: 'lock-01',
      iconPath: `${this.relativePathToIcons}/security/lock-01.svg`
    },
  //   time
    {
      iconName: 'calendar',
      iconPath: `${this.relativePathToIcons}/time/calendar.svg`
    },
    {
      iconName: 'alarm-clock-svg',
      iconPath: `${this.relativePathToIcons}/time/alarm-clock.svg`
    },
    {
      iconName: 'calendar-date',
      iconPath: `${this.relativePathToIcons}/time/calendar-date.svg`
    },
  //   user-interface
    {
      iconName: 'icon_subscription',
      iconPath: `${this.relativePathToIcons}/user-interface/subscription.svg`
    },
    {
      iconName: 'icon_settings',
      iconPath: `${this.relativePathToIcons}/user-interface/settings.svg`
    },
    {
      iconName: 'icon_help',
      iconPath: `${this.relativePathToIcons}/user-interface/help.svg`
    },
    {
      iconName: 'icon_help_gray',
      iconPath: `${this.relativePathToIcons}/user-interface/help-gray.svg`
    },
    {
      iconName: 'icon_notifications',
      iconPath: `${this.relativePathToIcons}/user-interface/notifications.svg`
    },
    {
      iconName: 'icon_notifications_gray',
      iconPath: `${this.relativePathToIcons}/user-interface/notifications-gray.svg`
    },
    {
      iconName: 'icon_visibility_off',
      iconPath: `${this.relativePathToIcons}/user-interface/visibility_off.svg`
    },
    {
      iconName: 'icon_edit',
      iconPath: `${this.relativePathToIcons}/user-interface/edit.svg`
    },
    {
      iconName: 'icon_edit_gray',
      iconPath: `${this.relativePathToIcons}/user-interface/edit-gray.svg`
    },
    {
      iconName: 'icon_delete',
      iconPath: `${this.relativePathToIcons}/user-interface/delete.svg`
    },
    {
      iconName: 'icon_delete_gray',
      iconPath: `${this.relativePathToIcons}/user-interface/delete-gray.svg`
    },
    {
      iconName: 'add',
      iconPath: `${this.relativePathToIcons}/user-interface/add.svg`
    },
    {
      iconName: 'icon_pencil',
      iconPath: `${this.relativePathToIcons}/user-interface/Pencil.svg`
    },
    {
      iconName: 'icon_open',
      iconPath: `${this.relativePathToIcons}/user-interface/Open.svg`
    },
    {
      iconName: 'icon_remove',
      iconPath: `${this.relativePathToIcons}/user-interface/Remove.svg`
    },
    {
      iconName: 'icon_settings_01',
      iconPath: `${this.relativePathToIcons}/user-interface/settings-01.svg`
    },
    {
      iconName: 'icon_help_circle',
      iconPath: `${this.relativePathToIcons}/user-interface/help-circle.svg`
    },
    {
      iconName: 'icon_play_square',
      iconPath: `${this.relativePathToIcons}/user-interface/play-square.svg`
    },
    {
      iconName: 'icon_phone',
      iconPath: `${this.relativePathToIcons}/user-interface/phone.svg`
    },
    {
      iconName: 'icon_message_text_circle',
      iconPath: `${this.relativePathToIcons}/user-interface/message-text-circle.svg`
    },
    {
      iconName: 'icon_x_close',
      iconPath: `${this.relativePathToIcons}/user-interface/x-close.svg`
    },
    {
      iconName: 'icon_menu_open',
      iconPath: `${this.relativePathToIcons}/user-interface/menu-02.svg`
    },
    {
      iconName: 'icon_notification',
      iconPath: `${this.relativePathToIcons}/user-interface/notification.svg`
    },
    {
      iconName: 'icon_code',
      iconPath: `${this.relativePathToIcons}/user-interface/code.svg`
    },
    {
      iconName: 'icon_dataflow',
      iconPath: `${this.relativePathToIcons}/user-interface/dataflow-01.svg`
    },
    {
      iconName: 'icon_log_out',
      iconPath: `${this.relativePathToIcons}/user-interface/log-out-01.svg`
    },
    {
      iconName: 'icon_check',
      iconPath: `${this.relativePathToIcons}/user-interface/check.svg`
    },
    {
      iconName: 'icon_whatsappNew',
      iconPath: `${this.relativePathToIcons}/user-interface/whatsapp.svg`
    },
    {
      iconName: 'icon_dots_grid',
      iconPath: `${this.relativePathToIcons}/user-interface/dots-grid.svg`
    },
    {
      iconName: 'dataflow-02',
      iconPath: `${this.relativePathToIcons}/user-interface/dataflow-02.svg`
    },
  //   users
    {
      iconName: 'icon_contact',
      iconPath: `${this.relativePathToIcons}/users/contact.svg`
    }, {
      iconName: 'icon_tracker',
      iconPath: `${this.relativePathToIcons}/users/tracker.svg`
    },
    {
      iconName: 'icon_contact_blue',
      iconPath: `${this.relativePathToIcons}/users/contact-blue.svg`
    },
    {
      iconName: 'icon_group',
      iconPath: `${this.relativePathToIcons}/users/group.svg`
    },
    {
      iconName: 'icon_group_gray',
      iconPath: `${this.relativePathToIcons}/users/group_gray.svg`
    },
    {
      iconName: 'icon_user_square',
      iconPath: `${this.relativePathToIcons}/users/user-square.svg`
    },
    {
      iconName: 'icon_user_new',
      iconPath: `${this.relativePathToIcons}/users/user_new.svg`
    },
    {
      iconName: 'user_01',
      iconPath: `${this.relativePathToIcons}/users/user-01.svg`
    },
    {
      iconName: 'users-02',
      iconPath: `${this.relativePathToIcons}/users/users-02.svg`
    },
    {
      iconName: 'users-plus',
      iconPath: `${this.relativePathToIcons}/users/users-plus.svg`
    },
    {
      iconName: 'user-plus-01',
      iconPath: `${this.relativePathToIcons}/users/user-plus-01.svg`
    },
    {
      iconName: 'user-circle',
      iconPath: `${this.relativePathToIcons}/users/user-circle.svg`
    },
    {
      iconName: 'user-circle',
      iconPath: `${this.relativePathToIcons}/users/user-circle.svg`
    },
    {
      iconName: 'user-edit',
      iconPath: `${this.relativePathToIcons}/users/user-edit.svg`
    },
    {
      iconName: 'zapcontact',
      iconPath: `${this.relativePathToIcons}/finance-and-ecommerce/zapcontact.svg`
    },
    {
      iconName: 'alert-square',
      iconPath: `${this.relativePathToIcons}/alerts-and-feedback/alert-square.svg`
    },
    {
      iconName: 'passport',
      iconPath: `${this.relativePathToIcons}/maps-and-travel/passport.svg`
    },
    {
      iconName: 'dots-vertical',
      iconPath: `${this.relativePathToIcons}/general/dots-vertical.svg`
    },
    {
      iconName: 'lock-keyhole-circle',
      iconPath: `${this.relativePathToIcons}/security/lock-keyhole-circle.svg`
    },
    {
      iconName: 'exclamation-triangle-fill',
      iconPath: `${this.relativePathToIcons}/alerts-and-feedback/exclamation-triangle-fill.svg`
    },
    {
      iconName: 'data',
      iconPath: `${this.relativePathToIcons}/development/data.svg`
    },
    {
      iconName: 'code-square-02',
      iconPath: `${this.relativePathToIcons}/development/code-square-02.svg`
    },
    {
      iconName: 'refresh-ccw-04',
      iconPath: `${this.relativePathToIcons}/arrows/refresh-ccw-04.svg`
    },
    {
      iconName: 'clock-refresh',
      iconPath: `${this.relativePathToIcons}/time/clock-refresh.svg`
    },
    {
      iconName: 'x-close',
      iconPath: `${this.relativePathToIcons}/general/x-close.svg`
    },
    {
      iconName: 'drag',
      iconPath: `${this.relativePathToIcons}/general/drag.svg`
    },
    {
      iconName: 'type-02',
      iconPath: `${this.relativePathToIcons}/editor/type-02.svg`
    },
    {
      iconName: 'check-filled-green',
      iconPath: `${this.relativePathToIcons}/general/check-filled-green.svg`
    },
    {
      iconName: 'edit-02',
      iconPath: `${this.relativePathToIcons}/general/edit-02.svg`
    },
    {
      iconName: 'link-02',
      iconPath: `${this.relativePathToIcons}/general/link-02.svg`
    },
    {
      iconName: 'mail-03',
      iconPath: `${this.relativePathToIcons}/communication/mail-03.svg`
    },
    {
      iconName: 'line-chart-up-01',
      iconPath: `${this.relativePathToIcons}/charts/line-chart-up-01.svg`
    },
    {
      iconName: 'arrow_drop_down',
      iconPath: `${this.relativePathToIcons}/arrows/arrow_drop_down.svg`
    },
    {
      iconName: 'arrow_drop_up',
      iconPath: `${this.relativePathToIcons}/arrows/arrow_drop_up.svg`
    },
    {
      iconName: 'link-external-02',
      iconPath: `${this.relativePathToIcons}/general/link-external-02.svg`
    },
    {
      iconName: 'file-download-02',
      iconPath: `${this.relativePathToIcons}/files/file-download-02.svg`
    },
    {
      iconName: 'linkedin',
      iconPath: `${this.relativePathToIcons}/general/linkedin.svg`
    },
  ];

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
  }


  addNewIcon(iconName: string, pathImage: string) {
    this.matIconRegistry.addSvgIcon(
      iconName,
      this.domSanitizer.bypassSecurityTrustResourceUrl(pathImage)
    );
  }
}
