import {appReducers} from './app.state';
import {StoreModule} from '@ngrx/store';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {Angulartics2Module} from 'angulartics2';

import {ComponentsModule} from './components/components.module';
import {ToastrModule} from 'ngx-toastr';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from 'src/environments/environment';
import {FacebookLoginProvider, GoogleLoginProvider, MicrosoftLoginProvider, SocialAuthServiceConfig} from 'angularx-social-login';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';
import {NgApexchartsModule} from 'ng-apexcharts';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {CurrencyMaskConfig} from 'ngx-currency/src/currency-mask.config';
import {CurrencyMaskInputMode, NgxCurrencyModule} from 'ngx-currency';
import {MatTreeModule} from '@angular/material/tree';
import {BrowserModule, Meta} from '@angular/platform-browser';
import { AuthInterceptor } from './auth/auth-interceptor.service';
import { DatePipe } from '@angular/common';

const fbLoginOptions = {
  scope: 'public_profile,email',
  fields: 'email',
  version: 'v2.5',
  return_scopes: true,
  enable_profile_selector: true
};


export function socialAuthKeys() {
  return [
    {
      id: GoogleLoginProvider.PROVIDER_ID,
      provider: new GoogleLoginProvider(environment.GOOGLE_SOCIAL_AUTH_KEY)
    },
    {
      id: FacebookLoginProvider.PROVIDER_ID,
      provider: new FacebookLoginProvider(environment.FACEBOOK_SOCIAL_AUTH_KEY, fbLoginOptions)
    },
    {
      id: MicrosoftLoginProvider.PROVIDER_ID,
      provider: new MicrosoftLoginProvider(environment.MICROSOFT_SOCIAL_AUTH_KEY)
    },
  ];
}

export const customCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'left',
  allowNegative: true,
  allowZero: true,
  decimal: ',',
  precision: 2,
  prefix: 'R$ 0,00',
  suffix: '',
  thousands: '.',
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL
};

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    ToastrModule.forRoot(),
    Angulartics2Module.forRoot(),
    StoreModule.forRoot(appReducers),
    StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}),
    RecaptchaV3Module,
    NgApexchartsModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    MatTreeModule,
  ],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.RECAPTCHA_V3_SITE_KEY
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: socialAuthKeys(),
      } as SocialAuthServiceConfig,
    },
    {
      provide: 'googleTagManagerId',
      useValue: environment.GOOGLE_TAG_MANAGER_ID
    },
    Meta,
    DatePipe,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
