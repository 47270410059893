import {Component, Input, OnDestroy} from '@angular/core';
import {PrimaryColorService} from '../../../shared/services/redux/primaryColor/primary-color.service';
import {Subscription} from 'rxjs';

export type ButtonSize = 'medium' | 'large';

export type TypeButton = 'primary' | 'secundary' | 'tertiary' | 'quaternary' | 'icon' | 'link' | 'link_subline' | 'link_capslock' | 'suboption' | 'line_suboption' | 'card_suboption';

export type Type = 'submit' | 'button' | 'reset';

/**
 * Componente de botão ZapSign
 * @link http://localhost:4200/conta/components
 *
 * @typeButton typeButton="primary"
 *
 * 'primary' | 'secundary' | 'tertiary' | 'quaternary' | 'icon' | 'link' | 'link_subline' | 'link_capslock';
 * @typeSubmit type="submit"
 *
 * 'submit' | 'button' | 'reset';
 * @iconDirection iconDirection='left'
 *
 * 'left' | 'right';
 * @disable disable=true;
 *
 * true | false;
 * @label label='Label';
 *
 * string, O componente ja traduz a string
 * @icon icon='icon';
 *
 * string, nome do icone
 * @loading loading=true;
 *
 * true | false
 * @check check=true;
 *
 * true | false
 * @matTooltip matTooltip='Tooltip';
 *
 * string, texto do tooltip, O componente ja traduz a string
 * @size size='medium';
 * 'medium' | 'large'
 * @colorClient colorClient='signer.doc.company.primary_color';
 *
 * string, cor do cliente
 */
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'zs-button',
  templateUrl: './button-zs.component.html',
  styleUrls: ['./button-zs.component.scss']
})
export class ButtonZSComponent implements OnDestroy {
  @Input() typeButton: TypeButton;
  @Input() type: Type;
  @Input() iconDirection = 'right';
  @Input() disable = false;
  @Input() label = '';
  @Input() icon = '';
  @Input() loading: boolean = false;
  @Input() check = false;
  @Input() matTooltip = '';
  @Input() size: ButtonSize = 'medium';
  @Input() dataCy = '';
  @Input() colorClient: string;
  primaryColor: string;
  private subscriptionColorButton: Subscription;
  constructor(
    private primaryColorServiceFromRedux: PrimaryColorService,
  ) {
    this.subscriptionColorButton = this.primaryColorServiceFromRedux.getPrimaryColorFromRedux().subscribe(({primaryColor}) => {
        this.primaryColor = primaryColor;
        this.changeColorButton();
      }
    );
  }

  ngOnDestroy(): void {
    if (this.subscriptionColorButton) {
      this.subscriptionColorButton.unsubscribe();
    }
  }

  changeColorButton() {
    if (this.primaryColor) {
      document.documentElement.style.setProperty('--clientColor', `${this.primaryColor}`);
    }
  }
}
