import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {concat, Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {Language} from 'src/translation/translation';
import {AuthService} from '../../../auth/auth.service';
import {HandleErrorsRequestService} from '../../../shared/services/handle-errors-request-service/handle-errors-request.service';

import {NewsletterInfo} from '../user-navbar.model';

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {
  private readonly lang: Language = localStorage.getItem('lang') as Language || 'pt-br';

  private readonly api_url = environment.API_URL;
  private readonly support_api_url = `${this.api_url}/suporte/`;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private handleErrorsRequestService: HandleErrorsRequestService
  ) { }

  getNewsletters(): Observable<NewsletterInfo[]> {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(`${this.support_api_url}newsletter/`, {lang: this.lang})
        .pipe(catchError(this.handleErrorsRequestService.getErrorRequest))
    );
  }

}
