export const DICTIONARYLOCALE = {
  'https://indique.buzz/zapsign?bl_ref=WJWE2': {
    global: 'https://zapsign.firstpromoter.com/signup/17179'
  },
  'Cada envio automático via WhatsApp custa R$ 0,50. Adicione créditos em Configurações > Plano.': {
    global: 'Cada envio por WhatsApp tem um custo de $0,10 USD (5 créditos). Compre créditos em Configurações > Planos > Créditos'
  },
  'Reconhecimento facial durante a assinatura do documento: 15 créditos que equivalem a $0,3 USD': {
    global: 'Reconhecimento facial durante a assinatura do documento: 25 créditos que equivalem a $0,5 USD'
  },
  'O reconhecimento facial tem um custo adicional de 15 créditos caso utilizado (15 créditos = R$ 1,50).': {
    global: 'O reconhecimento facial tem um custo adicional de 25 créditos caso utilizado (25 créditos = $0,5 USD).'
  },
}
