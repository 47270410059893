import {Injectable} from '@angular/core';
import {getTranslationForTruoraSign, Language} from 'src/translation/translation';
import {environment} from '../../environments/environment';
import {DICTIONARY} from '../../translation/dictionary';

@Injectable({
  providedIn: 'root'
})
export class TranslatorService {
  constructor() { }

  /*
    replacements string array ex. ['João','23','30']
    will replace $0,$1,$2 values in the original string
    Ex. original: "$0, você criou $1 documentos nos últimos $2 dias"
    Ex. replaced: "João, você criou 23 documentos nos últimos 30 dias"
  */
  translate(ptbrText: string, toLang: Language, replacements: string[] = []) {
    let text = '';
    const liguageOptions = ['pt-br', 'en', 'es', 'fr'];
    if (liguageOptions.indexOf(toLang) === -1) {
      toLang = 'pt-br';
    }
    if (toLang === 'pt-br') {
      text = ptbrText;
    } else {
      const translated_text = DICTIONARY[ptbrText];
      if (!translated_text) {
        console.error('Translation not found for text: ' + ptbrText);
        text = ptbrText; // fallback em português
      } else {
        text = translated_text[toLang];
      }
    }

    // TODO
    // 1 - Verificar se o usuário está acessando via 'truora sign' (algo como - if (window.location.hostname === 'app.sign.truora')
    // 2 - Se sim, verificar a presença da palavra 'ZapSign' no texto JÁ traduzido (text)
    // 3 - Se sim, substituir a palavra 'ZapSign' por 'Truora Sign'


    if(replacements.length > 0){
      text = this.doReplacements(text,replacements)
    }
    if (window.location.host.includes(environment.TRUORA_HOST)){
      text = getTranslationForTruoraSign(text);
    }
    return text
  }

  private doReplacements(text,replacements){
    for(let i = 0; i < replacements.length; i++){
      text = text.replaceAll(`$${i}`,`${replacements[i]}`)
    }
    return text
  }
}
