<div class="zs-content-upload">
  <div class="zs-select-metodo">
    <div class="zs-content-metodo">
      <div class="zs-metodo-slect-file" (click)="userAgent.isMobile || userAgent.isTablet ? showModal() : fileInput.click()">
        {{'Buscar arquivo'|translate}}
        <mat-icon class="zs-icon" svgIcon="upload-04"></mat-icon>
      </div>
      <input hidden (change)="onFileSelected(false)" #fileInput type="file" id="file" accept="image/x-png,image/jpeg">
      <div class="zs-metodo-photo" (click)="userAgent.isMobile || userAgent.isTablet ? fileInputPhoto.click() : openModalPhoto()">
        {{'Abrir camera'|translate}}
        <mat-icon class="zs-icon" svgIcon="camera-01"></mat-icon>
      </div>
      <input hidden id="photo_file" (change)="onFileSelected(true)" #fileInputPhoto type="file" accept="image/x-png,image/jpeg" capture="environment">
    </div>
    <div class="zs-text" *ngIf="signature_image === ''">
      {{'Extensões de arquivo permitidas: .png ou.jpeg'|translate}}
    </div>
  </div>
  <ng-container *ngIf="signature_image !== '' && !finishSignatureImageProcess">
    <div class="zs-content-btn-img">
      <zs-button
        class="btn-remover"
        typeButton="quaternary"
        label="Remover assinatura"
        icon="x-sum"
        iconDirection="left"
        (click)="remover()"
      ></zs-button>
      <zs-button
        class="btn-finish"
        typeButton="quaternary"
        label="Salvar assinatura"
        icon="check"
        iconDirection="left"
        (click)="finishCropping()"
      ></zs-button>
    </div>
  </ng-container>
  <ng-container *ngIf="signature_image != '' && finishSignatureImageProcess">
    <zs-button
      class="btn-remover"
      typeButton="quaternary"
      label="Editar assinatura"
      icon="edit_04"
      iconDirection="left"
      (click)="undoImage()"
    ></zs-button>
  </ng-container>
  <div class="zs-content-arquive">
    <ng-container *ngIf="signature_image != '' && !finishSignatureImageProcess">
      <angular-cropper
              #angularCropper
              [cropperOptions]="config"
              [imageUrl]="signature_image"
      ></angular-cropper>
    </ng-container>
    <ng-container *ngIf="signature_image != '' && finishSignatureImageProcess">
      <img id="signature-image-preview" [src]="signature_image"
           alt="Signature photo user">
    </ng-container>
  </div>

    <ng-container *ngIf="openSignatureCamera">
      <app-request-modal></app-request-modal>
      <app-no-camera-available></app-no-camera-available>
      <app-modal-documents
              *ngIf="userAgent.isDesktop"
              [comeFromUploadSignature]="true"
              (emmitHandleImageSignature)="handleImageSignature()">
      </app-modal-documents>
    </ng-container>
</div>

<app-menu-mobile
  *ngIf="userAgent.isMobile || userAgent.isTablet"
  [backgroundBlackDefault]="true"
  [title]="'Buscar arquivo'|translate"
  [showModal]="modalOpen"
  [itens]="optionMenuGroup"
  (closeModal)="closeModal()"
></app-menu-mobile>
