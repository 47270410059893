import {Injectable} from '@angular/core';
import {DocBackendService} from 'src/app/components/doc.backend.service';
import {Doc, Signer} from '../../../helpers/model/docs';
import {StepsCreateDocumentService} from './steps-create-document.service';

@Injectable({
  providedIn: 'root'
})
export class CurrentDocService {
  constructor(private docBackendService: DocBackendService, private stepsCreateDocumentService: StepsCreateDocumentService) {
  }

  getCurrentDoc(): Doc {
    return JSON.parse(localStorage.getItem('current_doc'));
  }

  setCurrentDoc(doc: any) {
    localStorage.setItem('current_doc', JSON.stringify(doc));
  }


  setIsAdvancedCurrentSigners(checked: boolean) {
    localStorage.setItem('current_signers_has_advanced_authentication', checked + '');
  }

  removeSetIsAdvancedCurrentSigners() {
    localStorage.removeItem('current_signers_has_advanced_authentication');
  }

  setSigner(index: number, signer: Signer) {
    const currentDoc = this.getCurrentDoc();
    currentDoc.signers[index] = signer;
    this.setCurrentDoc(currentDoc);
  }

  removeSigner(index: number) {
    const doc = this.getCurrentDoc();
    if (doc) {
      doc.signers = doc.signers.splice(index, 1);
      this.setCurrentDoc(doc);
    }
  }

  setSuccessfulFiles() {
    const doc = this.getCurrentDoc();
    const createSuccessfulFiles = [{name: doc.name, size: null, url: doc.original_file}];
    doc.extra_docs.forEach((extra_doc) => {
      createSuccessfulFiles.push({
        name: extra_doc.name,
        size: null,
        url: extra_doc.original_file
      });
    });
    sessionStorage.setItem('successfulFiles', JSON.stringify(createSuccessfulFiles));
  }

  editDocumentSigners(signers: Signer[]) {
    signers.map((signer, index: number) => {
      const isNewSigner = !signer.token;
      if (!isNewSigner) {
        this.docBackendService.updateSigner(signer.token, signer).subscribe((signer) =>
          this.setSigner(index, signer)
        );
      } else {
        this.docBackendService.createSigner(this.getCurrentDoc()?.token, signer).subscribe((signer) =>
          this.setSigner(index, signer)
        );
      }
    });
  }

  removeDocumentSigners(signers: Signer[]) {
    signers.map((signer) => {
      this.docBackendService.removeSigner(signer.token).subscribe();
    });
  }

  editDocumentFiles() {
    const currentDoc = this.getCurrentDoc();

    const extraDocs = JSON.parse(sessionStorage.getItem('successfulFiles')) || [];
    const docName = extraDocs[0].name ||  currentDoc.name;
    const firstFile = extraDocs.shift();
    const updated_doc = {
      ...currentDoc,
      name: docName,
      extra_docs: extraDocs
    };
    updated_doc.original_file = firstFile.url;
    return this.docBackendService.updateDocument(currentDoc?.token, {doc: updated_doc}).subscribe((data) => {
        this.stepsCreateDocumentService.emmiterCurrentTokenDocumentFromRedux(data.token);
        this.setCurrentDoc(data as Doc);
      }
    );
  }

  editDocumentObservers(observersEmailArray: string[], observersAllNotificationsEmails: string[]) {
    const currentDoc = this.getCurrentDoc();
    return this.docBackendService.updateObservers(currentDoc.token, {emails: observersEmailArray, emailsAllNotifications: observersAllNotificationsEmails}).subscribe();
  }
}
