import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HelpersGlobalService } from '../helpers-global.service';
import { WarningService } from './warning.service';

@Injectable({
  providedIn: 'root'
})
export class CopyService {
  appUrl:string = environment.APP_URL

  constructor(
      private warningService: WarningService,
      private helperGlobal: HelpersGlobalService
  ) { }

  copyToClipboard(val: string, successMsg:string = this.helperGlobal.t('Link copiado!')){
    navigator.clipboard.writeText(val);
    this.warningService.toastrSuccess(successMsg);
  }

}
