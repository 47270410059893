import {Action, createReducer, on} from '@ngrx/store';
import * as actions from './expired-email.actions';
import {ExpiredSignerProps} from './expired-email.model';


export const INITIAL_STATE: ExpiredSignerProps = {
  email: '',
};

const reducer = createReducer(INITIAL_STATE,
  on(
    actions.expiredSigner, (state, { email }) => ({ ...state, email })
  )
);
export function expiredEmailReducer(state: ExpiredSignerProps, action: Action) {
  return reducer(state, action);
}
