<mat-form-field appearance="none">
<mat-select [placeholder]="getCountSigners()" #selectSignersDropdown style="position: relative;top: 5px;">
    <mat-option class="zs-text-option" 
      *ngFor="let signer of signers; let i = index" 
      [value]="signer.name" 
      disabled multiLineOption
    >
      <span class="dot" [ngStyle]="{'background-color': getColorBullet(signer.status)}"></span>
      &nbsp;<span [matTooltip]="signer.name">{{signer.name}}</span>
      <br>
      <small [style.color]="getColorBullet(signer.status)">
        {{ getSignerStatus(signer) }}
      </small>
    </mat-option>
  </mat-select>
</mat-form-field>
<button mat-icon-button (click)="selectSignersDropdown.toggle()" matTooltip="{{'Signatários' | translate}}" class="zs-signers-button">
  <mat-icon svgIcon="icon_group"></mat-icon>
</button>
