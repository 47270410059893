import { Component, OnInit } from '@angular/core';
import {Language} from '@translation/translation';
import {Store} from '@ngrx/store';
import {AppState} from '../../../app.state';
import {UtilsService} from '@shared/services/utils/utils.service';
import {useModalNoComputerCamera} from '../../../app.state.selectors';

@Component({
  selector: 'app-old-no-camera-available',
  templateUrl: './old-no-camera-available.component.html',
  styleUrls: ['./old-no-camera-available.component.scss']
})
export class OldNoCameraAvailableComponent {
  modalOpen: boolean;
  whoIsCalled: string;
  qrCode: string = window.location.href;
  text = '';
  private readonly lang = localStorage.getItem('lang') as Language || 'pt-br';

  constructor(
    private store: Store<AppState>,
    public utils: UtilsService) {
    this.store.select(useModalNoComputerCamera).subscribe(({noComputerCamera, whoCalled}) => {
      this.modalOpen = noComputerCamera;
      this.whoIsCalled = whoCalled;
    });
  }

  closeModal(): void {
    if (this.whoIsCalled === 'webcam') {
      this.utils.closeModalNoCameraAvailable('no-cam');
      this.utils.openModalWebcam('no-cam');
    } else if (this.whoIsCalled === 'modal-document-front') {
      this.utils.closeModalNoCameraAvailable('modal-document-front');
      this.utils.openModalDocumentFront('modal-document-front');
    } else if (this.whoIsCalled === 'modal-document-verse') {
      this.utils.closeModalNoCameraAvailable('modal-document-verse');
      this.utils.openModalDocumentVerse('modal-document-verse');
    } else {
      this.utils.closeModalNoCameraAvailable('no-cam');
    }
  }

  getButtonText(): string {
    if (this.whoIsCalled === 'webcam' || this.whoIsCalled === 'modal-document-front' || this.whoIsCalled === 'modal-document-verse') {
      return 'Voltar';
    } else {
      return 'Fechar';
    }
  }

}
