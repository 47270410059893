import {Injectable, OnInit} from '@angular/core';
import {Contact, ContactGroup} from "../../helpers/model/user";
import {WarningService} from "../warning.service";
import {ContactGroupBackendService} from "./contact-group-backend.service";
import {TranslatorService} from "../translator.service";
import {Language} from "../../../translation/translation";

@Injectable({
  providedIn: 'root'
})
export class ContactGroupService implements OnInit {
  private readonly lang = localStorage.getItem('lang') as Language || 'pt-br';

  contactGroup: ContactGroup = {
    name: 'Nome',
    contacts: []
  }
  allContactsGroup: ContactGroup[] = [];

  constructor(
    private contactGroupBackendService: ContactGroupBackendService,
    private warning: WarningService,
    private translationService: TranslatorService
  ) {
  }

  ngOnInit() {
    this.contactGroup = {
      name: 'Nome',
      contacts: []
    };
    this.getAllContactGroups();
  }

  t(pt_text: string) {
    return this.translationService.translate(pt_text, this.lang);
  }

  removeContact(contact: Contact): void {
    this.contactGroupBackendService.removeContact(contact, this.contactGroup.token).subscribe(
      data => {
        this.warning.toastrSuccess(this.t("Contato removido com sucesso!"))
      },
      error => {
        this.warning.toastrError(this.t("Ocorreu um erro ao remover o contato."))
      }
    );
    const index = this.contactGroup.contacts.indexOf(contact);

    if (index >= 0) {
      this.contactGroup.contacts.splice(index, 1);
    }
  }

  addContact(contact: Contact) {
    this.contactGroup.contacts.push(contact)
  }

  saveContactGroup() {
    this.fixAuthMode();
    this.fixSelfieValidationType();
    const body = JSON.stringify(this.contactGroup);
    if (this.contactGroup.token)
      this.updateContactGroup(body);
    else
      this.createContactGroup(body);
  }

  private fixAuthMode() {
    const defaultAuthMode = this.contactGroup.default_auth_mode;
    if (defaultAuthMode.includes('certificadoDigital')) {
      this.contactGroup.default_auth_mode = 'certificadoDigital'
      return
    }

    var authMode = []
    if (defaultAuthMode.includes('assinaturaTela'))
      authMode.push('assinaturaTela')
    if (defaultAuthMode.includes('tokenSms'))
      authMode.push('tokenSms')
    else if (defaultAuthMode.includes('tokenEmail'))
      authMode.push('tokenEmail')
    else if (defaultAuthMode.includes('tokenWhatsapp'))
      authMode.push('tokenWhatsapp')
    else if (defaultAuthMode.includes('cpf'))
      authMode.push('cpf')

    this.contactGroup.default_auth_mode = authMode.join('-')
  }

  private updateContactGroup(body: string) {
    this.contactGroupBackendService.update(this.contactGroup.token, body).subscribe(
      data => {
        this.contactGroup = data;
        this.warning.toastrSuccess(this.t("Alterações salvas com sucesso!"));
      },
      error => {
        this.warning.toastrError(this.t("Ocorreu algum erro."));
      }
    )
  }

  private createContactGroup(body: string) {
    this.contactGroupBackendService.create(body).subscribe(
      data => {
        this.contactGroup = data;
        this.allContactsGroup.push(this.contactGroup);
        this.warning.toastrSuccess(this.t("Grupo de contatos criado com sucesso!"));
      },
      error => {
        this.warning.toastrError(this.t("Ocorreu algum erro."));
      }
    )
  }

  getAllContactGroups() {
    this.contactGroupBackendService.getAll().subscribe(
      data => {
        this.allContactsGroup = data;
        return data
      },
      error => {
        this.warning.toastrError(this.t("Ocorreu algum erro."));
        return error
      }
    )
  }

  deleteGroup(contactGroupToken: string) {
    this.contactGroupBackendService.delete(contactGroupToken).subscribe(
      data => {
        this.warning.toastrSuccess(this.t('Grupo de contatos excluído com sucesso!'))
      },
      error => {
        this.warning.toastrError(this.t('Ocorreu um erro ao remover o grupo.'))
      }
    )
  }

  private fixSelfieValidationType() {

  }
}
