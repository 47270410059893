import { Action } from '@ngrx/store';


export enum ActionTypesNoComputerCamera {
  VIEW_MODAL_NO_COMPUTER_CAMERA = 'VIEW_MODAL_NO_COMPUTER_CAMERA',
  HIDE_MODAL_NO_COMPUTER_CAMERA = 'HIDE_MODAL_NO_COMPUTER_CAMERA'
}


export class ViewModalNoComputerCamera implements Action {
  readonly type = ActionTypesNoComputerCamera.VIEW_MODAL_NO_COMPUTER_CAMERA;
  constructor(public payload: string) {}
}

export class HideModalNoComputerCamera implements Action {
  readonly type = ActionTypesNoComputerCamera.HIDE_MODAL_NO_COMPUTER_CAMERA;
  constructor(public payload: string) {}
}

export type ActionsNoComputerCamera = ViewModalNoComputerCamera | HideModalNoComputerCamera;
