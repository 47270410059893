import {Injectable} from '@angular/core';
import {concat, Observable, throwError} from 'rxjs';
import {AuthService} from "../../auth/auth.service";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {catchError} from "rxjs/operators";
import {Contact, ContactGroup} from "../../helpers/model/user";

@Injectable({
  providedIn: 'root'
})
export class ContactGroupBackendService {
  api_url: string = environment.API_URL;
  contact_group_url: string = this.api_url + '/user/company/contact-group/';

  constructor(
    private _authService: AuthService,
    private http: HttpClient
  ) {
  }

  create(body): Observable<any> {
    return concat(
      this._authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(`${this.contact_group_url}create/`, body)
        .pipe(catchError(this.handleError))
    );
  }

  update(token: string, body): Observable<any> {
    return concat(
      this._authService.refreshAccessTokenIfNeeded(),
      this.http.post<any>(`${this.contact_group_url + token}/update/`, body)
        .pipe(catchError(this.handleError))
    );
  }

  getAll(): Observable<any> {
    return concat(
      this._authService.refreshAccessTokenIfNeeded(),
      this.http.get<any>(`${this.contact_group_url}get-all/`)
        .pipe(catchError(this.handleError))
    );
  }

  get(token: string): Observable<any> {
    return concat(
      this._authService.refreshAccessTokenIfNeeded(),
      this.http.get<any>(`${this.contact_group_url + token}/get/`)
        .pipe(catchError(this.handleError))
    );
  }

  delete(token: string): Observable<any> {
    return concat(
      this._authService.refreshAccessTokenIfNeeded(),
      this.http.delete<any>(`${this.contact_group_url + token}/delete/`)
        .pipe(catchError(this.handleError))
    );
  }

  removeContact(contact: Contact, group_token: string): Observable<any> {
    return concat(
      this._authService.refreshAccessTokenIfNeeded(),
      this.http.delete<any>(`${this.contact_group_url + contact.token}/remove/${group_token}`)
        .pipe(catchError(this.handleError))
    );
  }

  private handleError(error: HttpErrorResponse) {
    console.log(error, 'Error');
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`
      );
      console.error(error.error);
    }
    return throwError(
      'Something bad happened; please try again later.');
  };
}
