import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-modal-top-blue',
    templateUrl: './modal-top-blue.component.html',
    styleUrls: ['./modal-top-blue.component.scss']
})
export class ModalTopBlueComponent {

  @Input() title: string = '';
  @Input() open: boolean = false;
  @Input() maxWidth: string = '720px';
  @Input() showCloseButton: boolean = true;
  @Output() onCloseEmitter = new EventEmitter<any>();

  onClose() {
    this.onCloseEmitter.emit();
  }

  preventModalFromClosing($event) {
    $event.stopPropagation();
  }

}
