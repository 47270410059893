import {Action, createReducer, on} from '@ngrx/store';
import {TranslationProps} from './translation';
import {changeLanguage, changeLocale} from './translation.actions';

export const INITIAL_STATE: TranslationProps = {
  language: 'pt-br',
  locale: 'br'
};

const _translationReducer = createReducer(
  INITIAL_STATE,
  on(changeLanguage, (state, { payload }) => {
    localStorage.setItem('lang', payload);
    return { ...state, language: payload };
  }),
  on(changeLocale, (state, { payload }) => {
    localStorage.setItem('locale', payload);
    return { ...state, locale: payload };
  })
);

export function translationReducer(state: TranslationProps, action: Action) {
  return _translationReducer(state, action);
}
