import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MixpanelService} from "../../shared/services/mixpanel.service";
import {SubscriptionProps} from "../../client/settings-payment/settings-payment.model";
import {PagamentosBackendService} from "../pagamentos.backend.service";
import {GetPlanNameService} from "../../shared/services/get-plan-name/get-plan-name.service";

@Component({
  selector: 'app-modal-unpaid',
  templateUrl: './modal-unpaid.component.html',
  styleUrls: ['./modal-unpaid.component.scss']
})
export class ModalUnpaidComponent implements OnInit {
  userMasterPlanName = '';

  constructor(
    private router: Router,
    private mixpanelService: MixpanelService,
    private paymentsBackend: PagamentosBackendService,
    private getPlanNameService: GetPlanNameService
  ) {}

  ngOnInit() {
    this.paymentsBackend.getMySubscriptions().subscribe(
      (data) => {
        this.getPlanName(data);
        this.mixpanelService
          .sendEventMixpanel('Banner failure payment - Pageview',
            {
              plan_name: this.userMasterPlanName,
              current_period_start: data[0].current_period_end,
              current_period_end: data[0].current_period_start
            }).subscribe();
      },err => console.error(err));
  }

  async goToMyPlans() {
    this.getMySubscriptions();
    await this.router.navigate(['conta', 'configuracoes', 'plans']);
  }

  private getMySubscriptions() {
    this.paymentsBackend.getMySubscriptions().subscribe(
      (data) => {
        this.getPlanName(data);
        this.mixpanelService
          .sendEventMixpanel('Banner failure payment - Click',
            {
              plan_name: this.userMasterPlanName,
              current_period_start: data[0].current_period_end,
              current_period_end: data[0].current_period_start
            }).subscribe();
      },err => console.error(err));
  }

  private getPlanName(allPlans){
    this.userMasterPlanName = this.getPlanNameService.takePlan(allPlans);
  }
}
