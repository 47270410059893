<zs-button
  typeButton="primary"
  label="Criar documento"
  id="button-create-doc-sidebar-test"
  (click)="navigateCreateDoc()"
  class="zs-btn"
  [disable]="disabled"
  [loading]="loading"
  [matTooltip]="disabled ? t('Você não tem permissão para enviar documentos. Contate o proprietário da conta.') : null"
>
</zs-button>
