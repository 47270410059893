import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {UserGroupState, UserGroup} from '../old-store/old-modal-user-groups.models';
import {AuthBackendService} from "../../../auth/backend.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../../app.state";
import {useSelectorUserGroups} from "../../../app.state.selectors";
import {Subscription} from "rxjs";
import {setUserGroupAction} from "../../../client/settings-organization/store/user-group/user-group.actions";
import { animate, style, transition, trigger } from '@angular/animations';
import { WarningService } from '../../warning.service';
import { TranslatorService } from '../../translator.service';
import { Language } from 'src/translation/translation';

@Component({
  selector: 'app-old-modal-user-group-list',
  templateUrl: './old-modal-user-group-list.component.html',
  styleUrls: ['./old-modal-user-group-list.component.scss'],
  animations: [
    trigger('inOut', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('500ms',
          style({ opacity: 1 })
        )
      ]),
      transition('* => void', [
        animate('500ms',
          style({ opacity: 0 })
        )
      ])
    ])
  ]
})
export class OldModalUserGroupListComponent implements OnInit {
  lang: Language = localStorage.getItem('lang') as Language || 'pt-br';

  confirmDelete = false;
  confirmDeleteIndex: number;
  emailInUserGroup: string[];
  showMembers: boolean = false;
  showMembersIndex: number;
  deleteMemberIcon: boolean = true;
  deleteMemberText: boolean = false;
  memberIndex: number;

  userGroups: UserGroup[] = [];
  @Input() state: UserGroupState;
  @Output() goToEdit = new EventEmitter<UserGroup>();
  @Output() goToNew = new EventEmitter();
  @Output() deletedEmitter = new EventEmitter();
  storeSubscriptions: Subscription[];

  constructor(
    private authBackend: AuthBackendService,
    private store: Store<AppState>,
    private warningService: WarningService,
    private translatorService: TranslatorService) { }

  ngOnInit(): void {
    const userGroupsSupscription = this.store.select(useSelectorUserGroups).subscribe((state) => {
      this.userGroups = state.userGroups;
    });
    this.storeSubscriptions = [userGroupsSupscription];
  }

  t(ptText: string) {
    return this.translatorService.translate(ptText, this.lang);
  }

  changeState() {
    this.goToNew.emit();
  }

  editUserGroup(userGroup: UserGroup) {
    this.goToEdit.emit(userGroup);
  }

  showMembersUserGroup(userGroupId: number, index: number) {
    this.authBackend.getEmailsUserGroup(userGroupId).subscribe(({ userGroups }) => {
      this.emailInUserGroup = userGroups.email_in_user_group;
      this.showMembers = !this.showMembers;
      this.showMembersIndex = index;
    })
  }

  showConfirmDelete(index: number) {
    this.confirmDelete = true;
    this.confirmDeleteIndex = index;
  }

  deleteUserGroup(userGroupId: number) {
    this.authBackend.removeUserGroup(userGroupId).subscribe(() => {
      const userGroups = this.userGroups.filter((userGroup) => {
        return userGroup.id !== userGroupId;
      });
      this.store.dispatch(setUserGroupAction({payload: userGroups}));
      this.confirmDelete = false;
      this.deletedEmitter.emit();
      this.warningService.toastrSuccess(this.t('Grupo deletado com sucesso.'));
    }, err => {this.warningService.toastrError(this.t(err.error));
    })
  }

  changeStateDeleteMember(index: number) {
    this.deleteMemberText = !this.deleteMemberText;
    this.deleteMemberIcon = !this.deleteMemberIcon;
    this.memberIndex = index
  }

  deleteMember(userGroupId: number, email: string) {
    this.authBackend.removeMemberFromUserGroup(userGroupId, email).subscribe(() => {
      this.authBackend.getEmailsUserGroup(userGroupId).subscribe(({ userGroups }) => {
        this.emailInUserGroup = userGroups.email_in_user_group;
        this.warningService.toastrSuccess(this.t('Membro deletado do grupo com sucesso.'));
      });
      this.deletedEmitter.emit();
    },
      err => {
        if (err) {
          console.error(err)
          this.warningService.toastrError(this.t(err.error));
        }
      })
  }
}
