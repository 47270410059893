import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LivenessValidatorService {
  readonly apiKey = environment.NXCD_API_KEY;
  constructor() {
  }

  async getJWT() {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `ApiKey ${this.apiKey}`
      },
    };
    const endpoint = 'https://api-homolog.nxcd.app/auth-jwt';
    const response = await fetch(endpoint, requestOptions);
    const token = await response.json();
    return token.accessToken;
  }
}
