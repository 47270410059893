import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {AnimationOptions} from 'ngx-lottie';
import {AppState} from 'src/app/app.state';
import {useModalActivateAccount, useSetIsFirstUser} from 'src/app/app.state.selectors';
import {AuthService} from 'src/app/auth/auth.service';
import {AuthBackendService} from '../../auth/backend.service';
import {WarningService} from '../warning.service';
import {setLoadingModalActivateAccount, setOpenActiveAccountModal} from './store/modal-activate-account.action';
import {TranslatorService} from '../translator.service';
import {Language} from '../../../translation/translation';
import {SessionStorageService} from 'src/app/shared/services/session-storage/session-storage.service';
import {ModalAskBusinessService} from '../modal-ask-business-segment/modal-ask-business.service';
import {getCurrentUserEmail} from "../../shared/services/current-user";

@Component({
  selector: 'app-modal-activate-account',
  templateUrl: './modal-activate-account.component.html',
  styleUrls: ['./modal-activate-account.component.scss']
})
export class ModalActivateAccountComponent implements OnInit {
  email = getCurrentUserEmail();
  isAwsTurn = true;
  verificationCode = '';
  waitToSendCodeAgain = false;
  loadingResendButton = false;
  options: AnimationOptions = {
    path: 'assets/animations/padlock.json',
  };
  isFirstUser = true;
  private readonly lang = localStorage.getItem('lang') as Language || 'pt-br';

  constructor(
    private authService: AuthService,
    private router: Router,
    private authBackendService: AuthBackendService,
    private warningService: WarningService,
    private store: Store<AppState>,
    private activatedRoute: ActivatedRoute,
    private translatorService: TranslatorService,
    private sessionStorageService: SessionStorageService,
    private modalAskBusinessService: ModalAskBusinessService,

  ) {

  }

  ngOnInit() {
    this.sendEmailVerificationCode('onInit');
    this.setLoadingStore();
    this.setIsFirstUser();
    this.setQueryParametersCodeVerificationEmail();
  }

  t(text: string, replacements?: string[]) {
    return this.translatorService.translate(text, this.lang, replacements);
  }

  logoutZapsign() {
    this.authService.logout();
    this.router.navigate(['acesso', 'entrar']);
  }

  toUpperCaseVerificationCode($event) {
    this.verificationCode = $event.target.value.toUpperCase();
  }

  checkEmailVerificationToken() {
    this.authBackendService.checkEmailVerificationToken(this.verificationCode, this.email).subscribe(
      () => {
        this.warningService.toastrSuccess(this.t('O seu e-mail foi verificado com sucesso!'));
        this.store.dispatch(setOpenActiveAccountModal({payload: false}));
        this.navigateAfterCheckVerificationToken();

        this.authBackendService.getMe().subscribe( (data:any) => {
            if (data.show_business_segment_modal) {
              this.modalAskBusinessService.openModal();
            }
          }, err => {
            console.error(err);
            this.warningService.toastrError(this.t('Ocorreu algum problema. Por favor, atualize a tela.'));
          }
        );
      },
      () => {
        this.setDisabledLoading();
        this.warningService.toastrError(this.t('O código parece incorreto. Veja se inseriu corretamente.'));
      }
    );
  }

  resendEmail() {
    this.waitToSendCodeAgain = false;
    if (!this.waitToSendCodeAgain) {
      this.sendEmailVerificationCode();
    }
  }

  private setQueryParametersCodeVerificationEmail() {
    const getParamCode = this.activatedRoute.snapshot.queryParamMap.get('code');

    if (getParamCode) {
      this.verificationCode = getParamCode;
      this.checkEmailVerificationToken();
    }
  }

  private setLoadingStore() {
    this.store.select(
      useModalActivateAccount
    ).subscribe(({loadingModalActivateAccount}) => this.loadingResendButton = loadingModalActivateAccount);
  }

  private setIsFirstUser() {
    this.store.select(
      useSetIsFirstUser
    ).subscribe(({first_user}) => this.isFirstUser = first_user);
  }

  private setDisabledLoading() {
    this.store.dispatch(setLoadingModalActivateAccount({payload: false}));
  }

  private async navigateAfterCheckVerificationToken() {
    const hasEmailInSessionStorageUserBeforeSignedDocument = this.sessionStorageService.getKey('email_user_not_registered');
    if (hasEmailInSessionStorageUserBeforeSignedDocument) {
      this.sessionStorageService.removeKey('email_user_not_registered');
      await this.router.navigate(['conta', 'minhas-assinaturas']);
    } else {
      await this.router.navigate(['conta', 'documentos'], {
        queryParams: {
          code: null,
          email: null
        },
        queryParamsHandling: 'merge'
      });
    }
  }

  private sendEmailVerificationCode(calledByOnInit?: string) {
    this.waitToSendCodeAgain = true;
    const emailProvider = this.isAwsTurn ? 'aws' : 'sendgrid';
    this.authBackendService.sendEmailVerificationToken(emailProvider)
      .subscribe(
        () => {
          this.setDisabledLoading();
          if (calledByOnInit) {
            return;
          } else {
            this.warningService.toastrSuccess(this.t('Código enviado com sucesso.'));
          }
        },
        () => {
          this.setDisabledLoading();

          this.warningService.toastrError(this.t('Houve algum erro para enviar o código. Tente mais tarde.'));
        }
      );
    this.isAwsTurn = !this.isAwsTurn;
  }

}



