<input type="file"
       hidden
       #inputHidden
       [required]="required"
       (change)="handleFileChangeInUploadField($event)"
       class="zs-upload-file-button"
       [accept]="typesAccepted"
>

<div #inputShow [class]="fileName ? 'upload-input-with-file' : 'upload-input-with-no-file'" (click)="triggerFileUpload()" (drop)="onDrop($event)" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)">
  <ng-container *ngIf="!fileName">
    <p class="color-black only-desktop">
      <span class="color-black">{{'Arraste e solte ou ' | translate}}</span>
      <span class="color-blue">{{'busque pelo arquivo' | translate}}</span>
    </p>
    <span class="color-blue only-mobile-tablet">{{'Buscar arquivo' | translate}}</span>
    <div class="icon-box">
      <mat-icon svgIcon="upload_04"></mat-icon>
    </div>
  </ng-container>
  <ng-container *ngIf="fileName">
    <p class="color-black">{{fileName}}</p>
    <zs-button
      typeButton="icon"
      icon="x_circle"
      (click)="cleanUpload($event)"
    ></zs-button>
  </ng-container>
</div>




