<div *ngFor="let userGroup of userGroups; index as i" class="container-group" id="user-group-modal">
  <div class="container-name">
    <mat-icon svgIcon="group-contacts"></mat-icon>
    <span>{{userGroup.name}}</span>
  </div>
  <div class="container-info">
    <zs-button class="btn-users-group"
               typeButton="quaternary"
               label="{{userGroups[i].id === userGroup.id ? userGroups[i].members_in_group + (' USUÁRIOS' | translate) : 0 + (' USUÁRIOS' | translate)}}"
               (click)="openModalRemoveUsers(userGroup, i);">
    </zs-button>
    <zs-button id="btn-modal-menu-group-users" typeButton="icon" icon="dots-vertical" (click)="toggleModal(userGroup)"></zs-button>
    <app-modal-menu
      class="modal-edit-delete-user-group"
      [ngClass]="{'modal-open': modalOpen && clickedUserGroup === userGroup}"
      *ngIf="actualUserGroup === userGroup && modalOpen && !isMobile"
      [itens]="optionMenuGroup"
      (closeModal)="closeModal()"
    ></app-modal-menu>
    <app-menu-mobile
      *ngIf="isMobile"
      [backgroundBlackDefault]="false"
      [title]="userGroup.name"
      [showModal]="modalOpen"
      [itens]="optionMenuGroup"
      (closeModal)="closeModal()"
    ></app-menu-mobile>
  </div>
</div>
<app-modal-remove
  title="Eliminar grupo de usuário"
  labelBtn="Eliminar grupo"
  [showModal]="showRemoveUserGroup"
  [info]="actualUserGroup ? actualUserGroup.name : t('Sem nome')"
  (confirm)="deleteUserGroup(actualUserGroup ? actualUserGroup.id : 0)"
  (closeModal)="closeModalDeleteUserGroup()"
></app-modal-remove>
<app-modal-delete-users
  *ngIf="showModalRemoveUsers"
  (removeUser)="deleteMember(actualGroupId, $event.email)"
  [groupId]="actualGroupId"
  [emailsUserGroup]="emailInUserGroup"
  [groupName]="actualUserGroup ? actualUserGroup.name : t('Sem nome')"
  (closeModal)="closeModalRemoveUsers()"
></app-modal-delete-users>
