import {Doc} from '@helpers/model/docs';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {PageSignerService} from '@shared/services/page-signer/page-signer.service';
import {Subscription} from 'rxjs';
import {DownloadService} from '@shared/services/download/download.service';
import {ConsumerTranslatorService} from '@shared/services/consumer-translator/consumer-translator.service';

@Component({
  selector: 'app-documents-waiting',
  templateUrl: './documents-waiting.component.html',
  styleUrls: ['./documents-waiting.component.scss']
})
export class DocumentsWaitingComponent implements OnInit, OnDestroy {

  @Output() userWantsViewSpecificPDF = new EventEmitter<string>();
  @Output() userWantsSignatureAll = new EventEmitter<string>();
  @Input() doc: Doc;
  @Input() color_branding: string;
  private subscriptions: Subscription[] = [];
  extraDocs: any;

  constructor(
    private pageSignerService: PageSignerService,
    private downloadService: DownloadService,
    private t: ConsumerTranslatorService
  ) {
  }

  ngOnInit() {
    this.sortByOpenIDExtraDocs();
    this.footerReducerSubscribe();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription) => {
      sub.unsubscribe();
    });
  }

  footerReducerSubscribe() {
    const footerReducerSubscribe = this.pageSignerService.getFooterRedux().subscribe(({clickPrimaryButton}) => {
      if (clickPrimaryButton) {
        this.signatureAll();
      }
    });
    this.subscriptions = [footerReducerSubscribe];
    this.pageSignerService.setPrimaryButton(this.t.translate('Assinar $0 documentos', [`${this.doc.extra_docs.length + 1}`]));
    this.pageSignerService.setBackButton(false);
    this.pageSignerService.showNoteMessage(true);
  }

  private sortByOpenIDExtraDocs() {
    this.extraDocs = [...this.doc.extra_docs];
    this.extraDocs.sort((a, b) => a.open_id - b.open_id);
  }

  getPdf(docItem: Doc) {
    const renderSignedDocument = this.doc.company.render_signed_document || false;
    const shouldShowSignedFile = renderSignedDocument && this.doc.signed_file != null && docItem.signed_file != null && docItem.signed_file;
    return shouldShowSignedFile ? docItem.signed_file : docItem.original_file;
  }

  viewPDF(file: string) {
    this.userWantsViewSpecificPDF.emit(file);
  }

  signatureAll() {
    this.pageSignerService.unClickPrimaryButton();
    this.userWantsSignatureAll.emit(this.getPdf(this.doc));
  }
  async download(documentRef: any) {
    const doc = {name: documentRef.name, signed_file: documentRef.original_file};
    await this.downloadService.downloadDocument(doc);
  }

  permissionToDownload() {
    return !this.doc?.company?.hide_button_download_original && !this.doc.disable_signers_get_original_file;
  }
}
