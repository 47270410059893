<div
  id="myModal"
  class="container"
  (click)="onClose()"
  [style.display]="open ? 'block':'none'"
  style="overflow-y: auto"
>
  <div
    class="content"
    (click)="preventModalFromClosing($event)"
    [ngStyle]="{'max-width': maxWidth}"
  >
    <div class="zs-top-blue"></div>
    <div class="header">
      <div *ngIf="showCloseButton">
        <zs-button
          (click)="onClose()"
          typeButton="icon"
          icon="icon_x_close"
        >
        </zs-button>
      </div>
    </div>
    <div class="children">
      <ng-content></ng-content>
    </div>
  </div>
</div>
