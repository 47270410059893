<div class="zs-toast" [style.display]="state.value === 'inactive' ? 'none' : ''">
  <div [class]="'zs-toast-icon ' + toastPackage.toastType">
    <ng-container class="desktop-only">
      <mat-icon *ngIf="toastPackage.toastType === 'toast-success'" svgIcon="check_circle"></mat-icon>
      <mat-icon *ngIf="toastPackage.toastType === 'toast-error'" svgIcon="x_circle"></mat-icon>
      <mat-icon *ngIf="toastPackage.toastType === 'toast-info'" svgIcon="help_octagon"></mat-icon>
      <mat-icon *ngIf="toastPackage.toastType === 'toast-warning'" svgIcon="alert-triangle"></mat-icon>
    </ng-container>
  </div>
  <div class="zs-toast-text">
    <p *ngIf="title" class="zs-toast-title">{{title}}</p>
    <p class="zs-toast-message">{{message}}</p>
  </div>
</div>
