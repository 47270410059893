<div [class]="'language-options-container ' + position">
  <div class="language-options">
    <mat-form-field appearance="outline">
      <mat-select
        [(ngModel)]="selectedLanguage"
        (selectionChange)="changeLanguageSelect($event)"
        id="selected-language-signup-test-id"
      >
        <mat-select-trigger >
          <div class="trigger-select">
            <mat-icon style="margin-right: 8px" [svgIcon]="currentLanguage?.icon"></mat-icon>
            {{ currentLanguage?.label }}
          </div>
        </mat-select-trigger>
        <mat-option *ngFor="let language of languages" [value]="language.name">
          <div class="text-select-option">
            <mat-icon [svgIcon]="language.icon"></mat-icon>
            {{ language.label }}
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
