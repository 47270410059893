import {Action, createReducer, on} from '@ngrx/store';
import SavePasswordAuthenticationScreensProps from './save-password-unauthorized-model';
import * as actions from './save-password-unauthorized.actions';

export const INITIAL_STATE_SIGNATURE: SavePasswordAuthenticationScreensProps = {
  password: '',
};


export const _savePasswordUnauthorizedReducer = createReducer(INITIAL_STATE_SIGNATURE,
  on(
    actions.setPasswordInUnauthorizedScreens,
    (state, { password }) => ({...state, password})
  ),
  on(
    actions.cleanPasswordAuthorizatedScreens,
    (state) => ({...state, password: ''})
  )
);

export const savePasswordUnauthorizedReducer = (state: SavePasswordAuthenticationScreensProps, action: Action) => {
  return _savePasswordUnauthorizedReducer(state, action);
};
