import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';

export const SkipAuthHeader = 'X-Skip-Auth-Header';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.headers.has(SkipAuthHeader)) {
      const headers = request.headers.delete(SkipAuthHeader);
      return next.handle(request.clone({headers}));
    }

    const accessToken = this.authService.getAccessToken();

    request = request.clone({
      setHeaders: {
        Authorization: accessToken ? `Bearer ${accessToken}` : '',
        'Captcha-Token': localStorage.getItem('captcha_token') || '',
        'Captcha-Provider': localStorage.getItem('captcha_provider') || ''
      }
    });
    return next.handle(request);
  }
}
