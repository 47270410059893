import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Component({
  selector: 'app-modal-delete-users',
  templateUrl: './modal-delete-users.component.html',
  styleUrls: ['./modal-delete-users.component.scss']
})
export class ModalDeleteUsersComponent {
  @Input() groupName: string;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() removeUser: EventEmitter<{groupId: number, email: string}> = new EventEmitter<{groupId: number, email: string}>();
  @Input() emailsUserGroup: string[];
  @Input() groupId: number;
  modalRemove = false;
  modalUsersToDelete = true;
  actualEmail: string;

  onCloseModal() {
    this.closeModal.emit();
  }

  openModalRemove(email: string) {
    this.modalRemove = true;
    this.modalUsersToDelete = false;
    this.actualEmail = email;
  }

  closeModalRemove() {
    this.modalRemove = false;
    this.modalUsersToDelete = true;
  }

  onRemoveUser(email: string) {
    this.removeUser.emit({ groupId: this.groupId, email });
    this.modalRemove = false;
    this.modalUsersToDelete = false;
  }

  @HostListener('document:click', ['$event.target'])
  clickOutside(target: HTMLElement) {
    const modal = target.closest('.container-rmv-users-group');
    const btnUsersGroup = target.closest('.btn-users-group');
    const modalRemoveUser = target.closest('.container-rmv');
    if (!modal && !btnUsersGroup && !modalRemoveUser) {
      this.closeModal.emit();
    }
  }
}
