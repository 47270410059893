import {Action, createReducer, on} from '@ngrx/store';
import {SlugTextType} from '../../../helpers-global.service';
import {setAdvancedAuthTypes} from './take-advanced-authentication.action';

export const INITIAL_STATE = [
  {
    slug: 'selfie',
    text: 'Selfie',
    icon: 'camera',
    isSelected: false,
    isDisabled: false,
    tooltip: 'Selfie: Pedir que o signatário tire uma selfie (foto do rosto) ao assinar. A foto constará no relatório de assinaturas como um dos pontos de autenticação. Quanto mais autenticações avançadas você usar, maior será sua segurança jurídica!'
  },
  {
    slug: 'identity-document',
    text: 'Documento de identidade',
    icon: 'identity-doc',
    isSelected: false,
    isDisabled: false,
    tooltip: 'Documento de identidade: Pedir para que o signatário tire uma foto do documento de identidade (ex RG, CNH) ao assinar. Constará no relatório de assinaturas como um dos pontos de autenticação. Quanto mais autenticações avançadas você usar, maior será sua segurança jurídica!'
  },
  {
    slug: 'facial-recognition',
    text: 'Reconhecimento facial',
    icon: 'video',
    isSelected: false,
    isDisabled: false,
    tooltip: 'Reconhecimento facial: Solicitar para que o signatário grave um vídeo seguindo as instruções de “vire o rosto”. Deverá também fornecer fotos frente e verso de seu RG ou CNH. O rosto filmado será comparado com a foto do documento oficial'
  },
] as SlugTextType[];

const _advancedAuthTypesReducer = createReducer(
  INITIAL_STATE,
  on(setAdvancedAuthTypes, (state, {payload}) => {
    return [...payload];
  })
);

export function advancedAuthTypesReducer(state: SlugTextType[], action: Action): SlugTextType[] {
  return _advancedAuthTypesReducer(state, action);
}
