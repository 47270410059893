import { Component, OnInit } from '@angular/core';
import { TranslatorService } from '../translator.service';
import { Language } from '../../../translation/translation';
import { SignerPropertiesReduxService } from 'src/app/shared/services/redux/signer-properties-redux/signer-properties-redux.service';
import { LoadingSignedFileReduxService } from 'src/app/shared/services/redux/loading-signed-file-redux/loading-signed-file-redux.service';
import { SignerBackendService } from 'src/app/signers/signer-backend.service';
import { Signer } from 'src/app/helpers/model/docs';

@Component({
  selector: 'app-screen-prevent-signers-downloading-signed-document',
  templateUrl: './screen-prevent-signers-downloading-signed-document.component.html',
  styleUrls: ['./screen-prevent-signers-downloading-signed-document.component.scss'],
})
export class ScreenPreventSignersDownloadingSignedDocumentComponent implements OnInit {
  private readonly lang = localStorage.getItem('lang') as Language || 'pt-br';
  loadingSignedFile: boolean;
  public signer: Signer;

  constructor(
    private translationService: TranslatorService,
    private signerPropertiesReduxService: SignerPropertiesReduxService,
    private loadingSignedFileReduxService: LoadingSignedFileReduxService,
    private signerBackendService: SignerBackendService,
  ) {
  }

  ngOnInit() {
    this.signerPropertiesReduxService.signer.subscribe(({signer}) => this.signer = signer);
    this.updateSignedFileUrl();
  }

  t(ptText: string) {
    return this.translationService.translate(ptText, this.lang);
  }

  private async updateSignedFileUrl() {
    this.loadingSignedFile = this.loadingSignedFileReduxService.setterLoadingSignedFileInStore(true);
    const data = await this.signerBackendService.updateSignedFileDocument(this.signer.doc.token).toPromise();

    try {
      this.signerPropertiesReduxService.setSigner({
        ...this.signer,
        doc: {...this.signer.doc, signed_file: data.signed_file, status: data.status}
      });
    } catch {
      throw new Error('Não foi possível ter os dados do documento assinado.');
    } finally {
      this.loadingSignedFile = this.loadingSignedFileReduxService.setterLoadingSignedFileInStore(false);
    }

    this.windowPostMessage('signed-file-ready');
  }

  windowPostMessage(eventName: string) {
    try {
      window.top.postMessage('zs-' + eventName, '*');
    } catch {
    }
  }
}
