import {Action, createReducer, on} from '@ngrx/store';
import {clearCompanyIDSelected, setCompanyIDSelected} from './using-company.actions';
import {CompanySelected} from './using-company.model';

export const INITIAL_STATE: CompanySelected = {
    id: 0
};

const _companySelectedReducer = createReducer(
  INITIAL_STATE,
  on(setCompanyIDSelected, (state, {payload}) => {
    return {...state, id: payload};
  }),
  on(clearCompanyIDSelected, () => INITIAL_STATE),
);

export function companySelectedReducer(state: CompanySelected, action: Action) {
  return _companySelectedReducer(state, action);
}
