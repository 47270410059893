import {Action, createReducer, on} from '@ngrx/store';
import * as actions from './counter-documents.actions';
import {CounterDocumentsProps} from './counter-documents.model';

export const INITIAL_STATE: CounterDocumentsProps = {
  numberOfDocumentsInAccount: undefined,
  numberOfDocumentsFreeInAccount: undefined,
  documentsLimitInAccount: undefined,
  isAllowEverthing: undefined
};

export const _counterDocumentsReducer = createReducer(INITIAL_STATE,
  on(
    actions.catchNumberOfDocumentsInAccount,
    (state, {payload}) => ({...state, numberOfDocumentsInAccount: payload})
  ),
  on(
    actions.catchDocumentsLimitInAccount,
    (state, {payload}) => ({...state, documentsLimitInAccount: payload})
  ),
  on(
    actions.catchFreeDocsMonthInAccount,
    (state, {payload}) => ({...state, numberOfDocumentsFreeInAccount: payload})
  ),
  on(
    actions.catchIfUserContainsOnePlan,
    (state, {payload}) => ({...state, isAllowEverthing: payload})
  ),
  on(
    actions.subtractDocumentInAccount,
    (state, {payload}) => ({...state, numberOfDocumentsInAccount: state.numberOfDocumentsInAccount - payload})
  )
);

export const counterDocumentsReducer = (state: CounterDocumentsProps, action: Action) => {
  return _counterDocumentsReducer(state, action);
};
