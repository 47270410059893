import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {PhoneNumberService} from '../../shared/services/phone-number.service';

@Component({
  selector: 'app-old-phone-input',
  templateUrl: './old-phone-input.component.html',
  styleUrls: ['./old-phone-input.component.scss']
})
export class OldPhoneInputComponent implements OnInit {
  @Input() hideValidationRedColor = false;
  @Input() initial_phone_country;
  @Input() initial_phone_number;
  @Input() disabled = false;
  @Input() hide_phone_number = false;
  @Input() do_not_disable_country = false;
  @Input() width = 'large';
  @Input() appearance: MatFormFieldAppearance = 'fill';
  phone_country: string;
  phone_number: string;

  @Output() change = new EventEmitter<any>();
  @Output() initial_phone_numberChange = new EventEmitter<any>();

  constructor(
    private phoneNumberService: PhoneNumberService
  ) {
  }

  ngOnInit(): void {
    this.phone_country = this.initial_phone_country  ? this.initial_phone_country : this.phoneNumberService.getDefaultPhoneCountry();
    this.phone_number = this.initial_phone_number;
  }

  triggerChange() {
    const data: any = {};
    if (this.phone_number !== undefined) {
      data.phone_number = this.phone_number;
    }
    if (this.phone_country !== undefined) {
      data.phone_country = this.phone_country;
    }
    if (data !== {}) {
      this.change.emit(data);
      // TODO remover esse initial phone, initial phone country e initial phone umber e criar só phone e phoneChange
      if (this.initial_phone_numberChange) {
        this.initial_phone_numberChange.emit(data.phone_number);
      }
    }
  }

  showPhoneCountry(phoneCountry: string) {
    const weHaveFlagsFor = ['1', '51', '52', '53', '54', '55', '56', '57', '58', '591', '593', '595', '598'];
    return weHaveFlagsFor.indexOf(phoneCountry) > -1;
  }

}
