<div class="zs_navbar_container">
  <app-modal-unpaid *ngIf="showUnpaidBanner && zcre113modalInadimplente"></app-modal-unpaid>
  <div class="zs_container">
    <div class="zs_show_mobile">
      <a mat-icon-button (click)="showMenuMobile()">
        <mat-icon>menu</mat-icon>
      </a>
    </div>
    <a class="zs_link_logo" id="zs-up" href="/conta/documentos">
      <img class="zs_logo" [src]="assetsService.getLogo('../../../assets/')"/>
    </a>
    <app-company-select class="zs_content_company"></app-company-select>
    <div class="zs_navbar_right">
      <app-btn-new-document></app-btn-new-document>
      <div class="zs_content_dados_user">
        <app-newsletter></app-newsletter>
        <app-perfil-select></app-perfil-select>
      </div>
    </div>
  </div>
</div>


