import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpClient, HttpHeaders} from '@angular/common/http';
import {throwError, concat, Observable, of, defer} from 'rxjs';
import {catchError, delay, map, retryWhen, switchMap} from 'rxjs/operators';
import {AuthService} from '../auth/auth.service';
import {environment} from '../../environments/environment';
import {SkipAuthHeader} from '../auth/auth-interceptor.service';
import {PostSignatureParams, UploadSignerPictureParams} from './signers.model';

export interface UploadedFile {
  file_name: string;
  file_url: string;
  label: string;
  upload_field_id: number;
}

@Injectable({
  providedIn: 'root'
})
export class SignerBackendService {

  apiUrl = environment.API_URL;
  docApiUrl = `${this.apiUrl}/docs/`;
  zapdocsApiUrl = `${this.apiUrl}/zapdocs/`;
  usersApiUrl = `${this.apiUrl}/user/`;
  biometryApiUrl = `${this.apiUrl}/biometry/`;
  skipAuthHeader = new HttpHeaders().set(SkipAuthHeader, '');

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {
  }

  getMySignature() {
    return concat(
      this.authService.refreshAccessTokenIfNeeded(),
      this.http.get<any>(this.usersApiUrl + 'me/signature/')
    );
  }

  getSigner(token: string, expired_token: string = '') {
    if (this.authService.isAuthenticatedAndNotExpired()) {
      return concat(
        this.authService.refreshAccessTokenIfNeeded(),
        this.http.get<any>(`${this.docApiUrl}signer/${token}/authenticated/?token=${expired_token}`)
      );
    } else {
      return this.http.get<any>(`${this.docApiUrl}signer/${token}/?token=${expired_token}`, {headers: this.skipAuthHeader});
    }
  }

  getModel(token: string) {
    return this.http.get<any>(
      `${this.zapdocsApiUrl}templates/${token}/open/`, {headers: this.skipAuthHeader, observe: 'response'})
      .pipe(catchError(
        (error: HttpErrorResponse) => throwError(error)
      ));
  }

  getPrefill(token: string) {
    return this.http.get<any>(`${this.zapdocsApiUrl}prefill/${token}/open/`, {headers: this.skipAuthHeader}).pipe(catchError(this.handleError));
  }

  createDocFromTemplate(token: string, signer_name: string, replacements: any[], uploaded_files: UploadedFile[], prefill_created_by: string = '', has_incomplete_fields: boolean = false) {
    return this.http.post<any>(
      `${this.zapdocsApiUrl}templates/${token}/doc/`,
      {signer_name, replacements, uploaded_files, prefill_created_by, has_incomplete_fields},
      {headers: this.skipAuthHeader}).pipe(catchError(this.handleError)
    );
  }

  updateDocFromTemplate(signerToken: string, replacements: any[], uploaded_files: UploadedFile[]) {
    return this.http.put<any>(`${this.zapdocsApiUrl}templates/signer/${signerToken}/update-doc/`, {
      replacements, uploaded_files
    }, {headers: this.skipAuthHeader}).pipe(catchError(this.handleError));
  }

  zapdocsUploadFile(template_token: string, upload_field_id: number, fileToUpload: File) {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    return this.http.post<any>(
      `${this.zapdocsApiUrl}templates/${template_token}/upload-file/${upload_field_id}/`,
      formData,
      {headers: this.skipAuthHeader}
    ).pipe(catchError(this.handleError));
  }

  sendCode(token: string, params: Object = {}) {
    return this.http.post<any>(`${this.docApiUrl}signer/${token}/send-code/`, params, {headers: this.skipAuthHeader});
  }

  sendCodeFallback(token: string) {
    return this.http.post<any>(`${this.docApiUrl}signer/${token}/send-code-fallback/`, {}, {headers: this.skipAuthHeader});
  }

  updateSignerEmail(token: string, params: Object) {
    return this.http.post<any>(`${this.docApiUrl}signer/${token}/update-email/`, params, {headers: this.skipAuthHeader}).pipe(catchError(this.handleError));
  }

  updateSignerPhone(token: string, params: Object) {
    return this.http.post<any>(`${this.docApiUrl}signer/${token}/update-phone/`, params, {headers: this.skipAuthHeader}).pipe(catchError(this.handleError));
  }

  updateSignerEmailPhoneName(token: string, params: {
    email: string
    phone_number: string
    phone_country: string
    name: string
  }) {
    return this.http.post<any>(`${this.docApiUrl}signer/${token}/update-email-phone-name/`, params);
  }

  postSignature(token: string, params: PostSignatureParams) {
    return concat(
      this.authService.getV3Captcha(),
      this.http.post<any>(`${this.docApiUrl}signer/${token}/sign/`, params).pipe(catchError(this.handleError))
    );
  }

  changeCursiveSignature(token: string, params: any) {
    return concat(
      this.authService.getV3Captcha(),
      this.http.post<any>(`${this.docApiUrl}signer/${token}/change-cursive-signature/`, params).pipe(catchError(this.handleError))
    );
  }

  uploadSignerPicture(signerToken: string, params: UploadSignerPictureParams) {
    return this.http.post<any>(
      `${this.docApiUrl}upload-picture/${signerToken}/`,
      params, {headers: this.skipAuthHeader}
    )
      .pipe(catchError(this.handleError));
  }

  updateSignedFileDocumentAsync(token: string) {
    return this.http.get<any>(`${this.docApiUrl}${token}/signed-file/async/`, {headers: this.skipAuthHeader}).pipe(catchError(this.handleError));
  }

  validateExtraDocsSigned(token: string) {
    return this.http.get<any>(`${this.docApiUrl}${token}/validate-extra-docs-signed/`, {headers: this.skipAuthHeader}).pipe(catchError(this.handleError));
  }

  updateSignedFileDocument(token: string) {
    return this.http.post<any>(`${this.docApiUrl}${token}/signed-file/`, {headers: this.skipAuthHeader}).pipe(catchError(this.handleError));
  }

  getSignedFileDocument(token: string) {
    const maxRetries = 30;
    const retryDelay = 2000;

    return this.http.get<any>(
      `${this.docApiUrl}${token}/signed-file/`,
      {
        headers: this.skipAuthHeader,
        observe: 'response'
      }).pipe(
      retryWhen(errors => {
        let retryCount = 0;
        return errors.pipe(
          switchMap(err => {
            if (err.status === 202 && retryCount < maxRetries){
              retryCount++;
              return of(null).pipe(delay(retryDelay)); // wait and retry
            }

            return this.handleError(err);
          })
        );
      }),
      map(response => response.body)
    );
  }


  uploadSignedToLacuna(token: string) {
    let resp: any = this.http.post<any>(`${this.docApiUrl}${token}/upload-signed-lacuna/`, {}, {headers: this.skipAuthHeader}).pipe(catchError(this.handleError));
    return resp;
  }

  signLacunaPades(token: string, lacunatoken: string, params: any) {
    let resp: any = this.http.post<any>(`${this.docApiUrl}${token}/${lacunatoken}/sign-pades-lacuna/`, params, {headers: this.skipAuthHeader});
    return resp;
  }

  getValidationProcess(signer_token: string): Observable<ValidationProcess> {
    const maxRetries = 4
    const retryDelay = 2000

    return this.http.get<any>(
      `${this.biometryApiUrl}validation-process/${signer_token}/`,
      { observe: 'response' },
    ).pipe(
      retryWhen(errors => defer(() => {
        let retryCount = 0;
        return errors.pipe(
          switchMap(err => {
            if (err.status === 202 && retryCount < maxRetries){
              retryCount++;
              return of(null); // retry
            }

            return throwError(err);
          }),
          delay(retryDelay),
        )
      })),
      map(response => response.body)
    )
  }

  checkValidationProcessStatus(signer_token: string, process_id: string): Observable<Validation> {
    return this.http.get<any>(`${this.biometryApiUrl}validation-process/${signer_token}/${process_id}/`);
  }

  getTemplateTokenAndAnswersFromSignerToken(signer_token: string) {
    return this.http.get<any>(`${this.docApiUrl}signer/${signer_token}/template-token/`, {headers: this.skipAuthHeader}).pipe(catchError(this.handleError));
  }


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`
      );
    }
    return throwError(error.error);
  }
}


export interface ValidationProcess {
  url: string;
  status: 'pending' | 'success' | 'failure';
  failure_status: string;
}

export interface Validation {
  status: 'pending' | 'success' | 'failure';
  reason: string;
}
