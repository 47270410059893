import {SlackMessage, SlackMessageType} from '@shared/services/slack/builders/slack-message.model';

export class SlackMessageBuilder {

  private slackMessage: SlackMessage;

  constructor() {
    this.reset();
  }

  reset(): SlackMessageBuilder {
    this.slackMessage = {
      text: '',
      channel: '',
      attachments: [
        {
          author_name: '',
          color: '',
          title: '',
          text: ''
        }
      ]
    };
    return this;
  }

  setText(text: string): SlackMessageBuilder {
    this.slackMessage.text = text;
    return this;
  }

  setMessageType(messageType: SlackMessageType): SlackMessageBuilder {
    this.slackMessage.attachments[0].color = messageType.toString();
    return this;
  }

  setTitle(title: string): SlackMessageBuilder {
    this.slackMessage.attachments[0].title = title;
    return this;
  }

  setDescription(description: string): SlackMessageBuilder {
    this.slackMessage.attachments[0].text = description;
    return this;
  }

  setPath(path: string): SlackMessageBuilder {
    this.slackMessage.attachments[0].author_name = path;
    return this;
  }

  setChannel(channel: string): SlackMessageBuilder {
    this.slackMessage.channel = channel;
    return this;
  }

  fromError(error: Error): SlackMessageBuilder {
    return this
      .setText(error.name)
      .setMessageType(SlackMessageType.ERROR)
      .setTitle('StackTrace')
      .setDescription(error.stack)
      .setPath(window.location.href);
  }

  getMessage(): SlackMessage {
    return this.slackMessage;
  }

}
