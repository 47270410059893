import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
  activityData,
  documentsPerMonthData,
  reasonCreateAccountData,
  howMeetUsData,
} from './modal-ask-business-segment.data';
import {TranslatorService} from '../translator.service';
import {Language} from '../../../translation/translation';
import {ModalAskBusinessService} from './modal-ask-business.service';
import {WarningService} from '../warning.service';

@Component({
  selector: 'app-modal-ask-business-segment',
  templateUrl: './modal-ask-business-segment.component.html',
  styleUrls: ['./modal-ask-business-segment.component.scss'],
})
export class ModalAskBusinessSegmentComponent implements OnInit {
  formAskBusinessSegment: FormGroup;
  reasonCreateAccountOptions = reasonCreateAccountData;
  activityOptions = activityData;
  documentsPerMonthOptions = documentsPerMonthData;
  howMeetUsOptions = howMeetUsData;
  private readonly lang: Language =
    (localStorage.getItem('lang') as Language) || 'pt-br';

  constructor(
    private formBuilder: FormBuilder,
    private translationService: TranslatorService,
    private modalAskBusinessService: ModalAskBusinessService,
    private warningService: WarningService
  ) {
  }

  ngOnInit(): void {
    this.constructFormBusinessSegment();
  }

  private constructFormBusinessSegment() {
    this.formAskBusinessSegment = this.formBuilder.group({
      reasonCreateAccount: ['', [Validators.required]],
      activity: ['', [Validators.required]],
      documentsPerMonth: ['', [Validators.required]],
      employeesQuantity: ['', [Validators.required]],
      howMeetUs: ['', [Validators.required]],
    });
  }

  private t(ptText: string) {
    return this.translationService.translate(ptText, this.lang);
  }

  setMatTooltipButton() {
    if (!this.formAskBusinessSegment.valid) {
      return this.t('Preencha os dados acima!');
    }

    return '';
  }

  sendDataBusinessSegment(notAnwserQuestions?: boolean) {
    const {value} = this.formAskBusinessSegment;
    if (this.formAskBusinessSegment.valid) {

      const businessSegmentUserZapsign = {
        event: 'business-segment',
        data: {
          ...value
        }
      };

      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push(businessSegmentUserZapsign);

      const data = {
        reason_create_account: value.reasonCreateAccount,
        activity: value.activity,
        documents_per_month: value.documentsPerMonth,
        employees_quantity: value.employeesQuantity,
        how_meet_us: value.howMeetUs
      };
      this.modalAskBusinessService
        .doingInformationsForCompany({...data})
        .subscribe(
          ({message}: { message: string }) => {
            if (!notAnwserQuestions)
              this.warningService.toastrSuccess(
                this.t(
                  message || 'Perguntas respondidas com sucesso'
                )
              );
          }
        );
    }
  }

  sendDataBusinessSegmentWithoutAnswer() {
    this.formAskBusinessSegment = this.formBuilder.group({
      reasonCreateAccount: ['Não quero responder', [Validators.required]],
      activity: ['Não quero responder', [Validators.required]],
      documentsPerMonth: ['Não quero responder', [Validators.required]],
      howMeetUs: ['Não quero responder', [Validators.required]],
    });

    this.sendDataBusinessSegment(true);
  }
}
