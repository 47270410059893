import {AfterContentInit, Component, Input} from '@angular/core';
import {Language} from '../../../translation/translation';
import {MatSelectChange} from '@angular/material/select';
import {LanguageProps} from '../../auth/login/Login';
import {MatSelectLanguageService} from '../../shared/services/mat-select-language/mat-select-language.service';
export type PositionSelect = 'select-fixed-top' | 'select-relative';
@Component({
  selector: 'app-select-language',
  templateUrl: './select-language.component.html',
  styleUrls: ['./select-language.component.scss']
})
export class SelectLanguageComponent implements AfterContentInit {
  selectedLanguage: Language = localStorage.getItem('lang') as Language || 'pt-br';
  currentLanguage: LanguageProps;
  languages: LanguageProps[] = [];
  @Input() position: PositionSelect = 'select-fixed-top';

  constructor(
    private matSelectLanguageService: MatSelectLanguageService,
  ) {
    this.languages = this.matSelectLanguageService.languages;
  }

  ngAfterContentInit() {
    this.renderCorrectLabelAndIconMatSelectTrigger();
  }

  changeLanguageSelect($event: MatSelectChange) {
    this.selectedLanguage = $event.value as Language;
    if ($event.value) {
      this.setLangLocalStorage($event.value);
    }
  }

  private setLangLocalStorage(language: Language) {
    return this.matSelectLanguageService.setLangLocalStorage(language);
  }

  private renderCorrectLabelAndIconMatSelectTrigger() {
    this.currentLanguage = this.matSelectLanguageService.renderCorrectLabelAndIconMatSelectTrigger(this.selectedLanguage);
  }

}
