<ng-container *ngIf="!isMobile && isEmpty">
    <button mat-button color="primary" (click)="openModalNotHaveCamera()">
        <mat-icon>qr_code</mat-icon>
        <span>{{'Abrir no celular' | translate}}</span>
    </button>
    <app-old-no-camera-available></app-old-no-camera-available>
</ng-container>

<ng-container *ngIf="!isEmpty">
    <button [disabled]="isEmpty" mat-button color="primary"
        (click)="emitterClearSignature()">
        <mat-icon>close</mat-icon>
        <span>{{'Limpar' | translate}}</span>
    </button>
</ng-container>
