import {Component} from '@angular/core';

@Component({
  selector: 'app-chip-new-feature',
  templateUrl: './chip-new-feature.component.html',
  styleUrls: ['./chip-new-feature.component.scss']
})
export class ChipNewFeatureComponent {

}
