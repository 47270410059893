import {Action, createReducer, on} from '@ngrx/store';
import * as actions from './subscriptions.action';
import {SubscriptionReducerProps} from './subscriptions.model';

export const INITIAL_STATE: SubscriptionReducerProps = {
  subscription: null
};

const _currentSubscriptionReducer = createReducer(
  INITIAL_STATE,
  on(actions.setSubscriptionReducer, (state, { payload }) => ({
    ...state,
    subscription: payload
  }))
);

export function currentSubscriptionReducer(state: SubscriptionReducerProps, action: Action) {
  return _currentSubscriptionReducer(state, action);
}
