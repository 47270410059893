import {DOCUMENT} from '@angular/common';
import {Inject} from '@angular/core';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FontsService {
  constructor(@Inject(DOCUMENT) private dom) {
  }

  setPreConnectGStatic() {
    if (!document.getElementById('gstatic')) {
      const link = this.dom.createElement('link');
      link.setAttribute('rel', 'preconnect');
      this.dom.head.appendChild(link);
      link.setAttribute('href', 'https://fonts.gstatic.com');
      link.setAttribute('id', 'gstatic');
    }
    //  TODO ao destruir o componente, remover o google fonts
  }

  setGoogleFonts() {
    if (!document.getElementById('googleapis')) {
      const link = this.dom.createElement('link');
      link.setAttribute('rel', 'stylesheet');
      this.dom.head.appendChild(link);
      link.setAttribute('crossorigin', 'anonymous');
      link.setAttribute(
        'href',
        'https://fonts.googleapis.com/css2?family=Bad+Script&family=Chilanka&family=Dancing+Script&family=Grand+Hotel&family=Inter:wght@400;500&family=Sacramento&family=Satisfy&display=swap'
      );
      link.setAttribute('id', 'googleapis');
    }
    //  TODO ao destruir o componente, remover o google fonts
  }
}
