import {Component, Inject, Output, OnDestroy, OnInit, EventEmitter, Input} from '@angular/core';
import {UserGroup, UserGroupState} from './old-store/old-modal-user-groups.models';
import {AuthBackendService} from "../../auth/backend.service";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {OldFormUserGroupComponent} from './old-form-user-group/old-form-user-group.component';
import {Store} from "@ngrx/store";
import {AppState} from "../../app.state";
import {useSelectorUserGroups} from "../../app.state.selectors";
import { Subscription } from 'rxjs';
import {PaywallData} from '../../helpers/model/paywallData';
import {WarningService} from '../warning.service';
import {TranslatorService} from "../translator.service";
import {Language} from "../../../translation/translation";


@Component({
  selector: 'app-old-modal-user-groups',
  templateUrl: './old-modal-user-groups.component.html',
  styleUrls: ['./old-modal-user-groups.component.scss']
})
export class OldModalUserGroupsComponent implements OnInit, OnDestroy {
  lang: Language = localStorage.getItem('lang') as Language || 'pt-br';
  @Output() updateMembershipEmitter = new EventEmitter();
  @Input() paywalls: PaywallData;
  state: UserGroupState = 'list';
  userGroup: UserGroup = new UserGroup();
  userGroupEditIndex: number;
  userGroups: UserGroup[] = [];
  storeSubscriptions: Subscription[];


  constructor(
    private matDialog: MatDialog,
    private store: Store<AppState>,
    private warningService: WarningService,
    private _translationService: TranslatorService,
    ) {}

  ngOnInit(): void {
    const userGroupsSupscription = this.store.select(useSelectorUserGroups).subscribe((state) => {
      this.userGroups = state.userGroups;
    });
    this.storeSubscriptions = [userGroupsSupscription];
  }

  ngOnDestroy(): void {
     this.storeSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
     });
  }

  t(text: string) {
    return this._translationService.translate(text, this.lang)
  }

  openModal(userGroup: UserGroup = null) {
    if (!this.paywalls.can_create_user_groups) {
      this.warningService.toastrError(
        this.t('Essa funcionalidade só está disponível para organizações do Plano Completo ou Corporativo. Faça upgrade do seu plano agora mesmo para começar a utilizar Grupos de Usuários.'
        ))
    } else {
      this.matDialog.open(OldFormUserGroupComponent, {panelClass: 'user-group-form', data: userGroup}).afterClosed().subscribe((data) => {
      if (data) {
        this.updateMembershipEmitter.emit();
      }
    });
    }
  }

  changeState() {
    this.goToNew();
  }

  goToEdit($event: UserGroup) {
    this.userGroup = $event;
    this.userGroupEditIndex = this.userGroups.indexOf($event);
    const generalsAccess = {...this.userGroup.generals_access};
    const userGroup = {...this.userGroup};
    userGroup.generals_access = generalsAccess;
    this.openModal(userGroup);
  }

  goToNew() {
    this.userGroup = new UserGroup();
    this.openModal();
  }

  deleteUserGroup() {
    if (this.userGroups.length === 0) {
      this.state = 'no_gruops';
    }
    this.updateMembershipEmitter.emit();
  }

}
