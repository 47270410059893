import {Component, forwardRef, Input} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {ControlValueAccessorProxy} from '@shared/proxy/control-value-accessor-proxy';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'zs-text-input',
  templateUrl: './text-input-zs.component.html',
  styleUrls: ['./text-input-zs.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TextInputZSComponent),
    multi: true
  }]
})
export class TextInputZSComponent extends ControlValueAccessorProxy {

  @Input() label = '';
  @Input() placeholder = '';
  @Input() loading: boolean = false;
  @Input() check = false;
  @Input() disabled = false;
  @Input() width = '100%';
  @Input() maskInput = null;
  @Input() autocomplete = true;
  @Input() dropSpecialCharacters = false;
  @Input() inputId = '';
  @Input() limitLength = 9999999;
  @Input() maskCurrency = null;
  @Input() customErrorMessage = null;
  @Input() icon = null;
  @Input() type = 'text';

  private inputName = {
    numberWithDDD: 'phone',
    country: 'country-code',
  };

  get controlNameInput() {
    return this.inputName[this.formControlName] ? this.inputName[this.formControlName] : this.formControlName;
  }
}


