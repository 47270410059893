<section @fade id="container-modal-afilliate-test" [style.display]="modalOpened ? 'block': 'none'">

  <div class="header-modal-afilliate">
    <div class="icon">
      <mat-icon id="close-modal-afilliate-test" class="mat-18 icon-close-modal-afilliate"
                (click)="closeModalAfilliate()">close
      </mat-icon>
    </div>
    <div class="header-text">
      <h4>
        {{'Quer ganhar dinheiro indicando a Zapsign? Veja como é fácil!' | translate}}
        💰😱
        <!-- icon money and icon surprised -->
      </h4>
    </div>
  </div>

  <div class="content-modal-afilliate">
    <p *ngIf="lang == 'pt-br'">
      Participe do programa <b>Indique e Ganhe</b> e ganhe até <strong>R$ 500,00</strong> por indicação! Basta se cadastrar e compartilhar o seu <b>link.</b>
    </p>
    <p *ngIf="lang == 'en'">
      <b>Earn up to $200</b> for each referral who purchases a plan on ZapSign. Enter here and start referring.
    </p>
    <p *ngIf="lang == 'es'">
      <b>Gana hasta 200 dólares</b> por cada referido que compre un plan en ZapSign. Ingresa aquí y empieza a referir
    </p>
  </div>

  <div class="footer-modal-afilliate">
    <a [href]="affiliateService.getAffiliateLink('?bl_ref=JQENG')" target="_blank">
      <mat-icon style="margin-right: 3px" class="mat-18">add_circle_outline</mat-icon>
      {{'Saiba mais' | translate}}
    </a>
  </div>

</section>
