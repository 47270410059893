import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormatDateService} from '../../shared/services/format-date/format-date.service';
import {MatCalendarCellCssClasses} from '@angular/material/datepicker';
import {AuthBackendService} from '../../auth/backend.service';
import {User} from '../../helpers/model/user';
import {Subscription} from "rxjs";
import {UsersReduxService} from "@shared/services/redux/users-redux/users-redux.service";


@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnDestroy {
  @Input() selectedFiltersShow = [];
  @Output() emitterFilters = new EventEmitter<any>();
  isFocusInput: boolean;
  name_filter = '';
  limpar: boolean;
  settingFiltro: boolean;
  statusFilter = 'all';
  start_date;
  end_date;
  selectStatus = false;
  periodo: boolean;
  periodoselect = 'apartir';
  hoje = new Date();
  pasta: boolean;
  autorShow: boolean;
  buscarSomentePastaAtual = true;
  email_filter = '';
  emails_users_company = [];
  user: any;
  isClickInside = false;
  storeSubscriptions: Subscription[] = [];

  constructor(
      private authBackend: AuthBackendService,
      private formatDateService: FormatDateService,
      private usersReduxService: UsersReduxService
  ) { }

  ngOnInit(): void {
      this.getMe();
  }

  ngOnDestroy(): void {
    this.storeSubscriptions.map(subscription => subscription.unsubscribe());
  }

  searchChange() {
    this.limpar = false;
    this.settingFiltro = false;
  }

  focusInput(): void {
    this.isFocusInput = !this.isFocusInput;
  }

  emitFilterChange() {
    const filters = {
      textFilter: this.name_filter,
    };
    if (this.selectedFiltersShow.includes('author')) {
      filters['author'] = this.email_filter;
    }
    if (this.selectedFiltersShow.includes('statusDoc')) {
      filters['statusDoc'] = this.statusFilter;
    }
    if (this.selectedFiltersShow.includes('date')) {
      filters['startDate'] = this.start_date;
      filters['endDate'] = this.end_date;
    }
    if (this.selectedFiltersShow.includes('searchInFolder')) {
      filters['searchInFolder'] = this.buscarSomentePastaAtual;
    }
    this.emitterFilters.emit(filters);
    if (this.name_filter !== '') {
      this.limpar = true;
    }
    this.settingFiltro = false;
  }

  isfilter() {
    if (!this.buscarSomentePastaAtual || this.email_filter || this.start_date || this.end_date || this.statusFilter !== 'all') {
      return true;
    }
    return false;
  }

  limparBusca() {
    this.name_filter = '';
    this.start_date = undefined;
    this.end_date = undefined;
    this.settingFiltro = false;
    this.limpar = false;
    this.statusFilter = 'all';
    this.selectStatus = false;
    this.periodo = false;
    this.email_filter = '';
    this.buscarSomentePastaAtual = true;
    this.emitFilterChange();
  }

  formatDateFront(data) {
    return this.formatDateService.formatDate(data);
  }

  onSelectedApartirChange($event) {
    this.start_date = $event;
    this.periodoselect = 'ateHoje';
    if ($event > this.end_date) {
      this.end_date = undefined;
    }
  }

  dateClass() {
    return (date: Date): MatCalendarCellCssClasses => {
      if (+new Date(date) === +new Date(this.start_date)) {
        return 'special-date';
      } else if (+new Date(date) === +new Date(this.end_date)) {
        return 'special-date';
      } else if (this.start_date && this.end_date && new Date(this.start_date) < new Date(date) && new Date(date) < new Date(this.end_date)) {
        return 'special-date-rege';
      } else {
        return;
      }
    };
  }

  onSelectedAteChange($event) {
    if (this.periodoselect === 'ateHoje') {
      this.end_date = $event;
      this.periodoselect = 'apartir';
    }
  }

  getMyMemberships() {
    this.authBackend.getCompanyMemberships(this.user.company.id).subscribe(
      data => {
        this.emails_users_company = data;
      },
      err => console.error(err)
    );
  }

  getMe() {
    const userSubscription = this.usersReduxService.getUser().subscribe(async ({user}) => {
      if (user) {
        this.user = user;
        this.getMyMemberships();
      }
    });

    this.storeSubscriptions.push(userSubscription);
  }

  isFilterAvancado() {
    if (this.selectedFiltersShow?.includes('author') || this.selectedFiltersShow?.includes('date') || this.selectedFiltersShow?.includes('searchInFolder') || this.selectedFiltersShow?.includes('statusDoc')) {
      return true;
    }
    return false;
  }

  @HostListener('click')
  clickInside() {
    this.isClickInside = true;
  }
  @HostListener('document:click', ['$event.target'])
  clickOutside() {
    if (!this.isClickInside) {
      this.settingFiltro = false;
    }
    this.isClickInside = false;
  }

}
