<div class="zs_container_screen_prevent_signers_downloading_signed_document">
  <div class="zs_card_screen_prevent_signers_downloading_signed_document">
    <div class="zs_card_with_padding_screen_prevent_signers_downloading_signed_document">

      <div class="zs_card_header_screen_prevent_signers_downloading_signed_document">
        <div class="zs_card_header_title_container_screen_prevent_signers_downloading_signed_document">
          <h1>{{'Assinado com sucesso!' | translate}}</h1>
          <mat-icon class="icon_approve" svgIcon="approve_icon"></mat-icon>
        </div>

        <p>{{'Você receberá o documento assinado no seu e-mail assim que encerrar as assinaturas ou conforme definido pelo proprietário.' | translate}}</p>
        <app-redirect-styled-link-button *ngIf="signer.redirect_link"></app-redirect-styled-link-button>
        <hr class="zs_line_between_header_and_content">
      </div>
      <div class="zs_card_content_screen_prevent_signers_downloading_signed_document">
        <h2>{{'Envie documentos para assinatura' | translate}}</h2>
        <ul class="zs_ul_screen_prevent_signers_downloading_signed_document">
          <li>
            <mat-icon class="icon_approve_blue_background" svgIcon="approved_background_blue_icon"></mat-icon>
            <span>{{'Colete assinaturas pelo' | translate}}
              <strong>{{'WhatsApp, e-mail, SMS, Telegram' | translate}}</strong> {{'ou qualquer canal de comunicação.' | translate}}</span>
          </li>
          <li>
            <mat-icon class="icon_approve_blue_background" svgIcon="approved_background_blue_icon"></mat-icon>
            <span><strong>{{'92% dos documentos' | translate}}</strong> {{'são assinados em menos de 1 hora.' | translate}}</span>
          </li>
          <li>
            <mat-icon class="icon_approve_blue_background" svgIcon="approved_background_blue_icon"></mat-icon>
            <span><strong>{{'Sem impressoras,' | translate}}</strong> {{'correios e motoboys.' | translate}}</span>
          </li>
        </ul>
      </div>
      <div class="zs_card_footer_screen_prevent_signers_downloading_signed_document">
        <a mat-stroked-button
           class="zs_button_screen_prevent_signers_downloading_signed_document"
           [href]="'https://zapsign.com.br?utm_source=zapsign&utm_medium=product&utm_campaign=signer-conhecer' | translate"
           [innerHTML]="t('Conhecer ZapSign')" target="_blank"></a>
      </div>

    </div>
  </div>
</div>
