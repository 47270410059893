import {Action, createReducer, on} from '@ngrx/store';
import {Doc, Signer} from '../../../../../helpers/model/docs';
import * as actions from './set-signer-redux.actions';
import {SetSignerProps} from './set-signer-redux.models';

export const INITIAL_STATE: SetSignerProps = {
  signer: null
};


export const _setSignerReducer = createReducer(
  INITIAL_STATE,
  on(
    actions.setSignerAction, (state, {payload}) => {
      return {signer: {...state.signer, ...payload}};
    }
  ),
  on(
    actions.setExtraDocAction, (state, {payload, index}) => {
      // TODO criar um reducer especifico pros extra_docs
      const signer = {...state.signer} as Signer;
      signer.doc = {...signer.doc} as Doc;
      const extra_docs = [...signer.doc.extra_docs];
      extra_docs[index] = payload;
      signer.doc.extra_docs = extra_docs;
      return {signer};
    }
  )
);

export function setSignerReducer(state: SetSignerProps, action: Action) {
  return _setSignerReducer(state, action);
}
