import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PlanSubscriptions} from '../../client/settings-payment/settings-payment.model';
import {User} from '../../helpers/model/user';
import {PlansReduxService} from '../../shared/services/redux/plans/plans-redux.service';

@Component({
  selector: 'app-plans-api-list',
  templateUrl: './plans-api-list.component.html',
  styleUrls: ['./plans-api-list.component.scss']
})
export class PlansApiListComponent implements OnInit {
  @Input() verPlanosApi: boolean;
  @Input() subToChangePlan: any;
  @Input() user: User;
  @Input() loadingMudarBtn = false;
  @Output() emmiterStartPayingFlow = new EventEmitter<PlanSubscriptions>();

  apiPlans: PlanSubscriptions[];

  constructor(private plansReduxService: PlansReduxService) {}

  ngOnInit(): void {
    this.plansReduxService.getListPlans().subscribe(({ plansAPI }) => {
      this.apiPlans = plansAPI;
    });
  }
  emitStartPayingFlow(plan: PlanSubscriptions) {
    this.emmiterStartPayingFlow.emit(plan);
  }

}
