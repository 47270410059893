<section class="tabs-options-signature" [ngClass]="hideDrawSignature ? 'space-around' : 'space-between'">
  <input
    [disabled]="disabledTabDrawWhenFocus"
    (click)="setCurrentTab('draw')"
    class="radio-tabs-input"
    *ngIf="!hideDrawSignature"
    id="first-tab" name="group" type="radio"
    [checked]="tabActive === 'draw'"
  />

  <input
    [disabled]="disabledTabUploadWhenFocus"
    (click)="setCurrentTab('upload')"
    class="radio-tabs-input"
    id="second-tab"
    name="group" type="radio"
    [checked]="tabActive === 'upload'"
  />

  <input
    [disabled]="disabledTabCursiveWhenFocus"
    (click)="setCurrentTab('write')"
    class="radio-tabs-input"
    id="third-tab"
    name="group"
    type="radio"
    [checked]="tabActive === 'write'"
  />

  <aside class="tabs"
         [style.justifyContent]="(showWriteSignature && !showUploadSignature || (!showWriteSignature && showUploadSignature)) && 'space-around'"
         [ngClass]="(!showUploadSignature && !showWriteSignature) && 'hideNode'">
    <label
      *ngIf="!hideDrawSignature"
      class="tab" id="one-tab" for="first-tab">
      {{ "Desenhar" | translate }}
    </label>
    <label
      [ngClass]="!showUploadSignature && 'hideNode'"
      class="tab" id="two-tab" for="second-tab">
      {{ "Subir" | translate }}
    </label>
    <label
      [ngClass]="!showWriteSignature && 'hideNode'"
      class="tab" id="three-tab" for="third-tab">
      {{'Escrever' | translate}}
    </label>
  </aside>

  <div class="panels">
    <div class="panel" id="first-panel" *ngIf="!hideDrawSignature">
      <ng-content select="[method_signature_screen]"></ng-content>
    </div>
    <div class="panel" id="two-panel" *ngIf="showUploadSignature">
      <ng-content select="[method_signature_upload]"></ng-content>
    </div>
    <div class="panel" id="three-panel" *ngIf="showWriteSignature">
      <ng-content></ng-content>
    </div>
  </div>

</section>
