import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent {
  @Input() count = 0;
  @Input() title = 'Documentos';
  @Input() buttonLabel = 'Criar';
  @Input() secondButtonLabel = 'Criar';
  @Input() countTitle: string;
  @Input() active = false;
  @Input() canOpen = true;
  @Input() showCount = true;
  @Input() showSecondBtn = false;
  @Output() changeActiveEmitter = new EventEmitter();
  @Output() createEmitter = new EventEmitter();
  @Output() createEmitterSecondBtn = new EventEmitter();

  emitCreateEmitter($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    this.createEmitter.emit();
  }

  emitCreateEmitterSecondBtn($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    this.createEmitterSecondBtn.emit();
  }

  emitChangeActiveEmitter() {
    this.changeActiveEmitter.emit();
  }

  preventDefault($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
  }
}
