import {Overlay} from '@angular/cdk/overlay';
import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {Language} from '../../../../translation/translation';
import {TextToImageService} from '../../../client/text-to-image.service';
import {PrimaryColorService} from '../../../shared/services/redux/primaryColor/primary-color.service';
import {TranslatorService} from '../../translator.service';
import {Fonts, setOptionsConversionImage} from './cursive-signature';
import {CustomSelectOverlay} from './custom-select-overlay';
import {FontsService} from './services/fonts.service';

export interface PropsGenerateImage {
  currentFontCursive: string;
  currentTextCursive: string;
  imageFontCursive: string;
}

@Component({
  selector: 'app-old-cursive-signature',
  templateUrl: './old-cursive-signature.component.html',
  styleUrls: ['./old-cursive-signature.component.scss'],
  viewProviders: [
    {
      provide: Overlay,
      useClass: CustomSelectOverlay,
    },
  ],
})
export class OldCursiveSignatureComponent implements OnInit, OnChanges {
  private readonly lang = localStorage.getItem('lang') as Language || 'pt-br';

  @ViewChild('textCursiveSignatureEl', {static: false}) textCursiveSignatureEl: ElementRef<HTMLDivElement>;
  @Output() emmiterGenerateImageBasedFont = new EventEmitter<PropsGenerateImage>();
  @Output() emitSaveSignatureCursive = new EventEmitter<void>();
  @Input() signerName: string;
  @Input() currentTabActive = 'draw';
  @Input() changesMade?: boolean;
  primaryColor: string;
  @Input() doingSignature?: boolean;
  @Input() isVisto? = false;

  indexSelectedFont = 0;
  optionsFonts: Fonts[] = [
    {
      font: 'Dancing Script, cursive',
    },
    {
      font: 'Chilanka, cursive',
    },
    {
      font: 'Grand Hotel, cursive',
    },
    {
      font: 'Inter, cursive',
    },
    {
      font: 'Satisfy, cursive',
    },
  ];

  selectedFont: Fonts = this.optionsFonts[0];
  loadingFont = true;

  constructor(
    private fontsService: FontsService,
    private cdr: ChangeDetectorRef,
    private textToImageService: TextToImageService,
    public router: Router,
    private translationService: TranslatorService,
    private primaryColorServiceFromRedux: PrimaryColorService
  ) {
    this.loadStaticFonts();
  }

  ngOnInit() {
    this.primaryColorServiceFromRedux.getPrimaryColorFromRedux().subscribe(({primaryColor}) =>
      this.primaryColor = primaryColor
    );
  }

  async ngOnChanges(changes: SimpleChanges) {
    const currentTabActive = changes.currentTabActive?.currentValue as string;
    if (currentTabActive && currentTabActive === 'write') {
      await this.setCurrentFont(0);
    }
  }


  private loadStaticFonts() {
    this.fontsService.setPreConnectGStatic();
    this.fontsService.setGoogleFonts();
  }

  async setCurrentFont(chosenFontIndex: number) {
    this.indexSelectedFont = chosenFontIndex ? chosenFontIndex : 0;
    this.selectedFont = this.optionsFonts.find((_, index) => index === chosenFontIndex);

    await this.tryingEmmiterImage();
  }

  private async tryingEmmiterImage() {
    this.cdr.detectChanges();

    try {
      await this.emmiterFontSignatureChanged();
    } catch (e) {
      console.error(e);
      throw new Error('An error ocurred in signature cursive. Try reload page or choice other signature method');
    }
  }

  private async emmiterFontSignatureChanged() {
    this.emmiterGenerateImageBasedFont.emit({
      currentFontCursive: this.selectedFont.font,
      currentTextCursive: this.signerName,
      imageFontCursive: await this.getScreenshotCaptureSignatureCursive()
    });
  }

  async getScreenshotCaptureSignatureCursive() {
    const {nativeElement} = this.textCursiveSignatureEl;
    try {
      this.loadingFont = true;
      this.textToImageService.setOptionsToConvertTextToImage(
        setOptionsConversionImage(nativeElement)
      );
      return await this.textToImageService.convertTextToImage(nativeElement);

    } finally {
      this.loadingFont = false;
    }
  }

  emmiterSaveSignature() {
    this.emitSaveSignatureCursive.emit();
  }

  isSignPageRoute() {
    return this.router.url.startsWith('/verificar');
  }

  textButton() {
    return this.isSignPageRoute() ? this.t('Finalizar') : this.t('Salvar assinatura');
  }

  t(ptText: string, replacements?: string[]) {
    return this.translationService.translate(ptText, this.lang, replacements);
  }

}
