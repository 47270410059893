<div class="observers-list" *ngFor="let observer of observersEmails">
  <div class="divisor"></div>
  <div class="observer-row">
    <div class="observer-row-info">
      <p class="observer-email">{{observer}}</p>
      <p class="observer-type">{{observersText | translate}}</p>
    </div>
    <zs-button
      typeButton="icon"
      icon="icon_x_close"
      (click)="remove(observer)"
    ></zs-button>
  </div>
</div>
