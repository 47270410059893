<div class="right_container">
  <img class="right_container_img" src="assets/img/logo/Logo-ZapsignBranca.svg" alt="Logo Zapsign">
  <h1 class="right_container_title">{{ "A assinatura eletrônica mais fácil de usar" | translate}}</h1>
  <h2 class="right_container_subtitle">{{ "Mais de 1 milhão de usuários confiam na ZapSign" | translate}}</h2>
  <div class="clients-logos">
    <div class="clients-logos-row">
      <img src="assets/img/logo/clients/itau.png" alt="Logo Itau" class="itau">
      <img src="assets/img/logo/clients/rappi.png" alt="Logo Rappi">
      <img src="assets/img/logo/clients/greenpace.png" alt="Logo Greenpeace">
      <img src="assets/img/logo/clients/fundacao.png" alt="Logo Fundação">
    </div>
    <div class="clients-logos-row">
      <img src="assets/img/logo/clients/rede_dor.png" alt="Logo Rede Dor">
      <img src="assets/img/logo/clients/unimed.png" alt="Logo Unimed">
      <img src="assets/img/logo/clients/grupo_boticario.png" alt="Logo Grupo Boticario">
    </div>
  </div>
</div>
