<div id="myModal" class="modal">
  <!-- Modal content -->
  <div class="modal-content" (click)="$event.stopPropagation()">
    <section class="container-content-modal-blue-background">
      <app-loading-overlay *ngIf="loadingResendSms"></app-loading-overlay>
      <div class="container-title-modal-blue-background">
        <h2>
        </h2>
        <div *ngIf="!modalCloseIconNotVisible" class="icon-close-modal-blue-background">
          <mat-icon (click)="closeModal()">close</mat-icon>
        </div>
      </div>
      <div>
        <img class="logo-zapsign" src="/assets/img/logo/logo.svg" alt="Logo da Zapsign">
      </div>
      <div class="modal-container">
        <h4 class="modal-container-text">{{ "Insira o código de 6 dígitos que foi enviado para o seu celular " | translate }}<strong *ngIf="user">({{user.phone_country}}) {{phoneWithAsteristics}}</strong></h4>
      </div>
      <div class="container-insert-code">
        <input [(ngModel)]="verificationCodeSms" type="text" matInput placeholder="A A A A A A" mask="S S S S S S" [dropSpecialCharacters]="true"
               (keyup)="toUpperCaseVerificationCode($event)" (keyup.enter)="checkSmsVerificationCode()" class="input_code"/>
      </div>
      <div class="modal-container-info">
        <p class="modal-container-info-text"> {{ "Não recebeu nenhum código?" | translate }} </p>
        <zs-button
          typeButton="secundary"
          [disable]="disableResendButton"
          (click)="disableResendButton ? '' : resendSMS(false)">

          <div renderHtmlButton *ngIf="disableResendButton">
            <countdown #cdSms [config]="configCountdown" (event)="listenerCountdownResendSms($event)"></countdown>
          </div>
          <div renderHtmlButton *ngIf="!disableResendButton">
            <p *ngIf="!disableResendButton"> {{'Reenviar SMS' | translate}} </p>
          </div>
        </zs-button>
      </div>
      <div class="modal-container-note">
        <zs-note noteType="info-note" text="Precisa de ajuda? Envie um e-mail para " textBoldFinal="support@zapsign.com.br" size="full-width"></zs-note>
      </div>
      <div class="go-out-validate">
        <zs-button typeButton="quaternary" (click)="logoutZapsign()" [label]="'Sair da conta'"></zs-button>
        <zs-button typeButton="primary" [disable]="!(verificationCodeSms.length === 6)" [label]="'Validar código'" (click)="checkSmsVerificationCode()"></zs-button>
      </div>
    </section>
  </div>
</div>
