<section @fade [style.display]="modalOpened ? 'block': 'none'">

  <div class="header-modal-unpaid">
    <div class="icon">
      <mat-icon id="close-modal-unpaid-test" class="mat-18 icon-close-modal-unpaid"
                (click)="closeModalUnpaid()">close
      </mat-icon>
    </div>
    <div class="header-text">
      <h4>
        {{'Ops, seu plano venceu' | translate}}
        ⏰🗓
      </h4>
    </div>
  </div>

  <div class="content-modal-unpaid">
    <p>
      {{'Olá, ZapSigner! Verificamos que o pagamento de renovação do seu $0 ainda não foi efetuado. Que tal fazer sua renovação agora? Em caso de dúvidas, entre em contato com a gente! Estamos à sua disposição.' | translate :
      [userMasterPlanName] }}
    </p>
  </div>
  <div class="footer-modal-unpaid">
    <a href="https://zapsign.com.br/contato/" target="_blank">
      {{'Falar com especialista' | translate}}
    </a>
    <a href="/conta/configuracoes/plans" mat-button class="zs_remake_plan_button">{{'Renovar meu plano' | translate}}</a>
  </div>

</section>
