import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-trash-header',
  templateUrl: './trash-header.component.html',
  styleUrls: ['./trash-header.component.scss']
})
export class TrashHeaderComponent {
  @Input() docs = [];
  @Input() allDocsSelected = 0;
}
