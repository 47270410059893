import { Language, Locale } from './translation';
import { createAction, props } from '@ngrx/store';


export const changeLanguage = createAction(
  '[Translate] Change the Zapsign translation',
  props<{ payload: Language }>()
);

export const changeLocale = createAction(
  '[Locale] Change the Zapsign locale',
  props<{ payload: Locale }>()
);
