<div class="zs_all_modal_content">
  <section class="zs_content_section_modal_helper">
    <h2 mat-dialog-title class="zs_title_modal_helper">{{data.title}}</h2>
    <section>
      <mat-form-field style="width: 100%" appearance="fill">
        <input
          maxlength="30"
          [(ngModel)]="this.data.contactGroup.name"
          [placeholder]="this.data.contactGroup.name"
          class="form-control"
          matInput
        />
      </mat-form-field>

      <div style="display: flex; gap: 24px">

        <section>
          <ng-container class="zs_who_sign_auth">
            <div class="zs_how_to_use">
              <span class="zs_who_sign_auth_span">{{'Autenticação padrão' | translate}}</span>
              <div class="zs_how_to_use">
                <mat-icon class="zs_help_icon">help</mat-icon>
                <a href="{{'https://clients.zapsign.com.br/help/m%C3%A9todos-de-autentica%C3%A7%C3%A3o' | translate}}"
                   target="_blank" class="zs_who_sign_auth_how_to_use">{{'Qual usar?' | translate}}</a>
              </div>
            </div>
            <mat-list>
              <mat-list-item *ngFor="let defaultAuthMethod of typesAuthDefaultAuthentication;">
                <mat-checkbox
                  [disabled]="defaultAuthMethod.isDisabled"
                  [checked]="defaultAuthMethod.isSelected || this.data.contactGroup?.default_auth_mode?.includes(defaultAuthMethod.slug)"
                  (change)="changeValueFromDefaultAuthentication($event, defaultAuthMethod)"
                  matTooltip="{{defaultAuthMethod.tooltip | translate}}"
                  [ngStyle]="this.data.contactGroup?.company?.locale === 'global' && defaultAuthMethod.text === 'Código por WhatsApp' ? {display: 'none'} : {display: 'block'}">
                  <div
                    class="zs_who_sign_icon_name"
                    [ngClass]="defaultAuthMethod.isDisabled ? 'zs_zs_who_sign_disabled_option' : 'zs_zs_who_sign_enabled_option'">
                    <mat-icon svgIcon="{{defaultAuthMethod.icon}}"></mat-icon>
                    <span class="text-item-auth-checkbox">{{defaultAuthMethod.text}}</span>
                  </div>
                </mat-checkbox>
              </mat-list-item>
            </mat-list>
          </ng-container>
        </section>

        <section class="container-auth-advanced-checkboxs">
          <div class="zs_how_to_use">
            <span class="zs_who_sign_auth_span">{{'Autenticação avançada' | translate}}</span>
            <div class="zs_how_to_use">
              <mat-icon class="zs_help_icon">help</mat-icon>
              <a href="{{'https://clients.zapsign.com.br/help/m%C3%A9todos-de-autentica%C3%A7%C3%A3o' | translate}}"
                 target="_blank" class="zs_who_sign_auth_how_to_use">{{'Qual usar?' | translate}}</a>
            </div>
          </div>
          <mat-list>
            <mat-list-item *ngFor="let advancedAuthMethod of typesAuthAdvancedAuthentication;">
              <mat-checkbox
                [disabled]="advancedAuthMethod.isDisabled"
                (change)="changeValueFromAdvancedAuthentication($event, advancedAuthMethod)"
                [checked]="shouldCheckAdvancedAuth(advancedAuthMethod)"
                matTooltip="{{advancedAuthMethod.tooltip | translate}}">
                <div
                  [ngClass]="advancedAuthMethod.isDisabled ? 'zs_zs_who_sign_disabled_option' : 'zs_zs_who_sign_enabled_option'"
                  class="zs_who_sign_icon_name">
                  <mat-icon svgIcon="{{advancedAuthMethod.icon}}"></mat-icon>
                  <span class="text-item-auth-checkbox">{{advancedAuthMethod.text}}</span>
                  <ng-container *ngIf="advancedAuthMethod.text === 'CPF Avançado'">
                    <em class="gray-text">
                      ({{'validado na Receita Federal' | translate}})
                    </em>
                  </ng-container>
                  <app-chip-new-feature *ngIf="advancedAuthMethod.slug == 'cpf-premium' || advancedAuthMethod.slug == 'liveness-NXCD'"></app-chip-new-feature>
                </div>
              </mat-checkbox>
            </mat-list-item>
          </mat-list>
        </section>
      </div>

      <div style="display: flex; flex-direction: column;">
        <span>{{ 'Contatos do grupo' | translate }}</span>
        <mat-form-field appearance="outline">
          <mat-select
            (selectionChange)="addContactToGroup($event)"
            id="selected-language-login-test-id"
            (keydown)="$event.stopPropagation()"
          >
            <mat-form-field class="zs-field-filter-contacts">
              <input matInput [(ngModel)]="filterText" (keyup)="searchContacts()" type="text"
                     placeholder="{{'Busque por nome, e-mail ou telefone'|translate}}"
                     (keydown)="$event.stopPropagation()"
              >
            <button mat-icon-button matSuffix class="button-color">
                <mat-icon>search</mat-icon>
              </button>
            </mat-form-field>
            <ng-container *ngIf="loadingContacts">
              <p class="flex">
                {{'Carregando seus contatos...' | translate}}
                &nbsp;<mat-spinner diameter="20"></mat-spinner>
              </p>
            </ng-container>
            <ng-container style="display: flex;" *ngIf="!loadingContacts && this.data.allContacts.length === 0">
              {{'Nenhum contato encontrado' | translate}}.
            </ng-container>
            <mat-option *ngFor="let contact of this.data.allContacts" [value]="contact" (keydown)="$event.stopPropagation()">
              {{ contact.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-chip-list aria-label="Contact selection">
          <mat-chip *ngFor="let group of data.contactGroup.contacts">
            <p style="align-self: baseline">{{group.name}}</p>
            <button mat-icon-button (click)="removeContactFromGroup(group)">
              <mat-icon>close</mat-icon>
            </button>
          </mat-chip>
        </mat-chip-list>
      </div>
    </section>
  </section>

  <mat-dialog-actions class="zs_actions_modal_helper" style="justify-content: flex-end; gap: 16px">
    <a
      (click)="closedModal()"
      mat-button
      mat-raised-button
      [mat-dialog-close]="true"
      class="zs_resolved_doubt_button">{{'Cancelar' | translate}}</a>

    <a
      (click)="save()"
      mat-button
      mat-raised-button
      color="primary"
      [mat-dialog-close]="true">{{'Salvar alterações' | translate}}</a>
  </mat-dialog-actions>
</div>
