import {AfterContentInit, AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import * as SignaturePad from 'signature_pad';
import {Language} from 'src/translation/translation';
import {TranslatorService} from '../translator.service';


interface OptionsSignatureProps {
  minWidth: number;
  maxWidth: number;
  canvasWidth: string | number;
  canvasHeight: string | number;
  backgroundColor: string;
}

@Component({
  selector: 'app-signature-pad-old',
  templateUrl: './signature-pad-old.component.html',
  styleUrls: ['./signature-pad-old.component.scss']
})
export class SignaturePadOldComponent implements OnInit, OnDestroy, AfterContentInit, AfterViewInit, OnChanges {
  @Input() activeTab = 'draw';
  @Input() lang: Language = localStorage.getItem('lang') as Language || 'pt-br';
  @Input() isVisto = false;
  signaturePad: any;
  elementRef: ElementRef;
  @Output() done: EventEmitter<string>;
  @Output() cleared: EventEmitter<boolean>;
  @Output() startPad: EventEmitter<boolean>;
  @Output() signaturePhotoActive = new EventEmitter<boolean>();
  @Output() signatureTextActive = new EventEmitter<boolean>();
  padWidth: number;
  padHeight: number;
  options: OptionsSignatureProps;


  originalInnerWidth: number;

  originalInnerHeight: number;
  originalIsHorizontal: boolean;
  @Input() widthOffset = 0;

  urlDataImageWhenScreenRotate = '';


  constructor(
    elementRef: ElementRef,
    private translator: TranslatorService
  ) {
    this.elementRef = elementRef;

    if (this.isVisto) {
      this.padWidth = 130;
      this.padHeight = 130;
    } else {
      this.padWidth = 260;
      this.padHeight = 130;
    }
    this.options = {
      minWidth: .5,
      maxWidth: 1.8,
      canvasWidth: this.padWidth,
      canvasHeight: this.padHeight,
      backgroundColor: 'rgba(0,0,0,0)'
    };
    this.done = new EventEmitter();
    this.cleared = new EventEmitter();
    this.startPad = new EventEmitter();
    this.signaturePhotoActive = new EventEmitter();
  }

  ngOnInit(): void {
    this.originalInnerWidth = window.innerWidth;
    this.originalInnerHeight = window.innerHeight;
    this.originalIsHorizontal = this.originalInnerHeight < this.originalInnerWidth;
  }

  ngOnChanges(changes: SimpleChanges) {
    const activeTab = changes.activeTab?.currentValue as string;
    if (activeTab && activeTab === 'draw') {
      this.onEnd();
    }
  }

  t(text: string) {
    return this.translator.translate(text, this.lang);
  }

  public ngAfterContentInit(): void {
    const sp: any = SignaturePad.default;
    const canvas: any = this.elementRef.nativeElement.querySelector('canvas');

    if ((this.options as OptionsSignatureProps).canvasHeight) {
      canvas.height = (this.options as OptionsSignatureProps).canvasHeight;
    }

    if ((this.options as OptionsSignatureProps).canvasWidth) {
      canvas.width = (this.options as OptionsSignatureProps).canvasWidth;
    }

    this.signaturePad = new sp(canvas, this.options);
    this.signaturePad.onBegin = this.onBegin.bind(this);
    this.signaturePad.onEnd = this.onEnd.bind(this);
  }

  public ngOnDestroy(): void {
    const canvas: any = this.elementRef.nativeElement.querySelector('canvas');
    canvas.width = 0;
    canvas.height = 0;
  }

  // Returns signature image as data URL (see https://mdn.io/todataurl for the list of possible paramters)
  public toDataURL(imageType?: string, quality?: number): string {
    let toDataURL = this.signaturePad?.toDataURL(imageType, quality);
    return this.urlDataImageWhenScreenRotate ? this.urlDataImageWhenScreenRotate : toDataURL; // save image as data URL
  }

  // Clears the canvas
  public clear(): void {
    this.signaturePad.clear();
  }

  // Returns true if canvas is empty, otherwise returns false
  public isEmpty(): boolean {
    return this.signaturePad.isEmpty();
  }

  // notify subscribers on signature begin
  public onBegin(): void {
    this.startPad.emit(true);
  }

  // notify subscribers on signature end
  public onEnd(): void {
    this.done.emit(this.toDataURL());
  }

  // set an option on the signaturePad - e.g. set('minWidth', 50);
  public set(option: string, value: number): void {

    switch (option) {
      case 'canvasHeight':
        this.signaturePad.canvas.height = value;
        break;
      case 'canvasWidth':
        this.signaturePad.canvas.width = value;
        this.signaturePad.canvas.maxWidth = value;
        break;
      default:
        this.signaturePad[option] = value;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => this.resizeCanvas(), 0); // prevent Error:Expression has changed after it was checked
  }

  resizeCanvas() {
    let innerWidth = window.innerWidth;
    let innerHeight = window.innerHeight;
    const isHorizontal = innerHeight < innerWidth;

    if (isHorizontal === this.originalIsHorizontal) {
      if (innerHeight > this.originalInnerHeight) {
        innerHeight = this.originalInnerHeight;
      }
      if (innerWidth > this.originalInnerWidth) {
        innerWidth = this.originalInnerWidth;
      }
    } else {
      if (innerHeight > this.originalInnerWidth) {
        innerHeight = this.originalInnerWidth;
      }
      if (innerWidth > this.originalInnerHeight) {
        innerWidth = this.originalInnerHeight;
      }
    }

    let width = innerWidth - 24;
    if (isHorizontal) {
      width = innerWidth - 96;
    }
    if (width > 580) {
      width = 580;
    }
    if (width < 286) {
      width = 286;
    }

    // important to "my profile" page
    width -= this.widthOffset;

    let height: number;
    if (this.isVisto) {
      if (width > 290) {
        width = 290;
      }
      height = width;
    } else {
      height = Math.floor(width * 0.618); // golden ratio
    }
    if (this.padWidth !== width || this.padHeight !== height) {
      this.padWidth = width;
      this.padHeight = height;
      this.set('canvasWidth', width);
      this.set('canvasHeight', height);

    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    setTimeout(() => this.resizeCanvas(), 0);
  }

  @HostListener('window:orientationchange', ['$event'])
  screenOrientationChange($event: Event) {
    if ($event.type === 'orientationchange') {
      setTimeout(() => {
        this.setUrlDataImageWhenSignaturePadRotate();
        this.resizeCanvas();
        this.clearSignature();
        if (this.urlDataImageWhenScreenRotate !== '') {
          this.done.emit(this.urlDataImageWhenScreenRotate);
        }
      }, 0);
    }
  }

  setUrlDataImageWhenSignaturePadRotate() {
    if (!this.isEmpty()) {
      const createImaged = document.createElement('img');
      createImaged.src = this.toDataURL();
      this.urlDataImageWhenScreenRotate = createImaged.src;
    }
  }

  clearDataImageRotated() {
    this.urlDataImageWhenScreenRotate = '';
    this.clearSignature();
  }

  clearSignature(): void {
    this.signaturePad.clear();
    this.cleared.emit(true);
  }

}
