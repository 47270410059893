import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {COMMA, ENTER, SPACE} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';

@Component({
  selector: 'app-old-observers-input',
  templateUrl: './old-observers-input.component.html',
  styleUrls: ['./old-observers-input.component.scss']
})
export class OldObserversInputComponent implements OnInit {
  @Output() observersEmailsEmitter = new EventEmitter<string[]>();
  readonly separatorKeysCodes = [ENTER, COMMA, SPACE] as const;
  addOnBlur = true;

  @Input() observersEmailsArray: string[] = [];
  observersEmails: string[] = [];

  ngOnInit(): void {
    if (this.observersEmailsArray.length > 0) {
      this.observersEmails = this.observersEmailsArray;
    }
  }

  sendObserverForm() {
    this.observersEmailsEmitter.emit(this.observersEmails);
  }

  remove(email: string) {
    this.observersEmails.forEach((item, index) => {
      if (item === email) {
        this.observersEmails.splice(index, 1);
      }
    });
    this.sendObserverForm();
  }

  add(event: MatChipInputEvent): void {
    const email = event.value.trim();

    if (email !== '') {
      this.observersEmails.push(email);
    }

    event.input.value = '';
  }
}
