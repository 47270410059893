import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {AuthService} from '../../../auth/auth.service';
import {UploadSignerPictureResponse} from '../../../signers/signers.model';

export type ResponseUploadFileFromUrl = UploadSignerPictureResponse;


@Injectable({
  providedIn: 'root'
})
export class UploadFileFromUrlService {
  private readonly apiUploadUrl = `${environment.API_URL}/docs/upload-file/`;

  constructor(private authService: AuthService) {}

  async uploadFile(fileUrl: string, name: string): Promise<ResponseUploadFileFromUrl> {
    const res = await fetch(fileUrl);
    const blob = await res.blob();
    const formData: FormData = new FormData();
    formData.append('file', blob, name);
    const response = await fetch(this.apiUploadUrl + '?convert_docx_to_pdf=false', {
      method: 'POST',
      body: formData,
      headers: {Authorization: `Bearer ${this.authService.getAccessToken()}`}
    });
    return await response.json();
  }
}
