<div class="menu-bottomsheet-external" (click)="close()">
  <div class="menu-bottomsheet">
    <ul class="menu-bottomsheet-list">
      <li class="menu-bottomsheet-title menu-bottomsheet_element no-border">
        <strong>{{title | translate}}</strong>
        <div class="icon-color-arrow-down">
          <mat-icon svgIcon="icon_arrow_down"></mat-icon>
        </div>
      <li
        [class]="'menu-bottomsheet_element'"
        *ngFor="let action of actions; let i = index"
        (click)="action.action()"
        [ngClass]="[
          'zs_modal_list_elements_length_'+actions.length && action.class,
          i === actions.length - 1 ? 'no-border' : ''
        ]"
      >
        <mat-icon class="menu-bottomsheet_element_icon" svgIcon="{{action.icon}}"></mat-icon>
        {{action.name | translate}}
      </li>
    </ul>
  </div>
</div>
