import {Action, createReducer, on} from '@ngrx/store';
import * as actions from './primary-color.actions';
import {PrimaryColorRedux} from './primary-color.models';


export const INITIAL_STATE: PrimaryColorRedux = {
  primaryColor: null
};

const _primaryColorReducer = createReducer(INITIAL_STATE, on(
  actions.primaryColorActions, (state, {payload}) => ({
    primaryColor: payload
  })
));


export function primaryColorReducer(state: PrimaryColorRedux, action: Action) {
  return _primaryColorReducer(state, action);
}
