import {Doc} from '@helpers/model/docs';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-old-documents-waiting',
  templateUrl: './old-documents-waiting.component.html',
  styleUrls: ['./old-documents-waiting.component.scss']
})
export class OldDocumentsWaitingComponent implements OnInit {

  @Output() userWantsViewSpecificPDF = new EventEmitter<string>();
  @Output() userWantsSignatureAll = new EventEmitter<string>();
  @Input() doc: Doc;
  @Input() color_branding: string;

  ngOnInit() {
    this.sortByOpenIDExtraDocs();
  }

  private sortByOpenIDExtraDocs() {
    this.doc.extra_docs.sort((a, b) => a.open_id - b.open_id);
  }

  getPdf(docItem: Doc) {
    const renderSignedDocument = this.doc.company.render_signed_document || false;
    const shouldShowSignedFile = renderSignedDocument && this.doc.signed_file != null && docItem.signed_file != null && docItem.signed_file;
    return shouldShowSignedFile ? docItem.signed_file : docItem.original_file;
  }

  viewPDF(file: string) {
    this.userWantsViewSpecificPDF.emit(file);
  }

  signatureAll() {
    this.userWantsSignatureAll.emit(this.getPdf(this.doc));
  }
}
