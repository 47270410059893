import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import moment from 'moment';
import {ReCaptchaV3Service} from 'ng-recaptcha';

import {concat, Observable, Subscription, throwError} from 'rxjs';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {CookieService} from 'src/app/components/cookie.service';
import {HelpersGlobalService} from 'src/app/helpers-global.service';

import {environment} from 'src/environments/environment';
import {Language} from 'src/translation/translation';
import {changeLanguage, changeLocale} from 'src/translation/translation.actions';
import {AppState} from '../app.state';
import {clearFolderWhereUserWasInDocumentListRedux} from '../client/page-my-documents/store/folder-where-user-was-in-document-list/folder-where-user-was-in-document-list-redux.actions';
import {setCompanyIDSelected} from '../client/page-my-documents/store/using-company/using-company.actions';
import {setOpenActiveAccountModal} from '../components/modal-activate-account/store/modal-activate-account.action';
import {OldModalUnpaidComponent} from '../components/old-modal-unpaid/old-modal-unpaid.component';
import {PagamentosBackendService} from '../components/pagamentos.backend.service';
import {TranslatorService} from '../components/translator.service';
import {updateNonViewedNewsletterNotifications} from '../components/user-navbar/newsletter-modal/store/newsletter-modal-action';
import {WarningService} from '../components/warning.service';
import {PaywallData} from '../helpers/model/paywallData';
import {Company, User} from '../helpers/model/user';
import {LaunchDarkly} from '../shared/singleton/launch-darkly.singleton';
import {ActionRecaptcha} from '../signers/sign-page/sign-page.model';
import {RedefinePassword} from './auth.model';
import {UnauthorizedDataService} from './services/unauthorized-email/unauthorized-email.service';
import { useLoginUrlQueryParams } from '../app.state.selectors';
import { Userpilot } from 'userpilot';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  lang: Language = localStorage.getItem('lang') as Language || 'pt-br';

  api_url: string = environment.API_URL;
  users_api_url: string = this.api_url + '/user/';
  auth_api_url: string = this.api_url + '/auth/';
  support_api_url: string = this.api_url + '/suporte/';
  zcre113modalInadimplente = true;
  showUnpaidPopup: boolean
  loginPlanIdQueryParam: number = null;
  private loginQueryParamSubscription: Subscription;

  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store<AppState>,
    private _recaptchaV3Service: ReCaptchaV3Service,
    private warningService: WarningService,
    private unauthorizedDataService: UnauthorizedDataService,
    private _translationService: TranslatorService,
    private injector: Injector,
    private dialog: MatDialog,
    private helpersGlobalService: HelpersGlobalService,
    private cookieService: CookieService,
  ) {

    const client = LaunchDarkly.getClient();
    client.on('ready', () => {
      this.zcre113modalInadimplente = client.variation('zcre-113-alterar-o-modal-inadimplente', false);
    });


    this.loginQueryParamSubscription = this.store.select(useLoginUrlQueryParams).subscribe(({ planId }) => {
      if (planId) {
        this.loginPlanIdQueryParam = planId;
      }
    });

  }

  ngOnDestroy() {
    if (this.loginQueryParamSubscription) {
      this.loginQueryParamSubscription.unsubscribe();
    }
  }

  twoFactorPreLogin(username, password, doCaptcha: boolean = false) {
    if (doCaptcha) {
      return concat(
        this.getV3Captcha(),
        this.http.post<any>(this.auth_api_url + 'two-factor-authentication/check/', {
          username,
          password
        }).pipe(tap(response => this.setSession(response)))
      );
    } else {
      return this.http.post<any>(this.auth_api_url + 'two-factor-authentication/check/', {
        username,
        password
      }).pipe(tap(response => this.setSession(response)));
    }
  }

  login(username: string, password: string, doCaptcha: boolean = true): Observable<any> {
    if (doCaptcha) {
      return concat(
        this.getV3Captcha(),
        this._loginObservable(username, password)
      );
    } else {
      return this._loginObservable(username, password);
    }
  }

  private _loginObservable(username: string, password: string) {
    return this.http.post<any>(this.auth_api_url + 'token/', {username, password}).pipe(tap(response => this.setSession(response)));
  }

  t(pt_text: string) {
    return this._translationService.translate(pt_text, this.lang);
  }

  afterGetMeAction(user: User) {
    this.store.dispatch(changeLocale({payload: user.company.locale}));
    this.store.dispatch(changeLanguage({payload: user.company.lang}));
    this.store.dispatch(setCompanyIDSelected({payload: +user.company.id}));
    this.store.dispatch(setOpenActiveAccountModal({payload: user.has_to_show_account_verification_modal}));
    localStorage.setItem('companyId', user.company.id.toString());
    localStorage.setItem('ab_test', user.ab_test);
    localStorage.setItem('activateAccountPending', user.has_to_show_account_verification_modal.toString());
    if (!this.zcre113modalInadimplente) {
      this.openOldModalForUsersUnpaid(user);
    }
  }

  navigateToPlansPageWithPlanId(planId: number): void {
    this.router.navigate(['conta', 'configuracoes', 'plans'], {
      queryParams: {
        planId: planId,
        tab: 'plans',
      }
    });
  }

  navigateToResearchPage() {
    this.router.navigate(['acesso', 'pesquisa'])
  }


  userPilotSendCompany(user: User) {
    Userpilot.identify(String(user.company.id), {
      email: user.email,
      companyId: user.company.id,
      companyLocale: user.company.locale,
      companyLang: user.company.lang,
      companyCreated_at: user.company.created_at,
      ab_test: user.ab_test,
    });
  }

  afterLoginRouting(
    username: string,
    fromSignUp = false,
    redirectToAfterAuthentication?: string,
  ) {

    const existsRedirectAfterAuthentication =
      sessionStorage.getItem('redirect_after_authentication')
      || '/conta/documentos';

    redirectToAfterAuthentication =
      !!redirectToAfterAuthentication
      ? redirectToAfterAuthentication
      : existsRedirectAfterAuthentication;

    localStorage.setItem('user_email', username);
    localStorage.removeItem('organization_name');
    sessionStorage.removeItem('2fau');
    sessionStorage.removeItem('2fap');
    sessionStorage.removeItem('2fapr');
    sessionStorage.removeItem('origin_register');
    sessionStorage.removeItem('origin_signer');

    this.unauthorizedDataService.removeEmailUnauthenticatedScreens();
    this.unauthorizedDataService.removePasswordUnauthenticatedScreens();

    this.http.get<any>(this.users_api_url + 'me/').subscribe(
      (data: User) => {
        localStorage.setItem('user_phone', `${data.phone_country}-${data.phone_number}`);
        const user = data;
        this.getNewsletterNotification();

        if (user) {
          this.afterGetMeAction(user);
          this.userPilotSendCompany(user);
        }

        localStorage.setItem('is_owner', JSON.stringify(user.is_owner));

        if (user.company.has_assinatura_lote) {
          localStorage.setItem('has_assinatura_lote', '1');
        }

        if (user.multi_companies) {
          localStorage.setItem('multi_companies', '1');
          this.router.navigate(['conta', 'escolha-organizacao']);
        }

        if (!user.multi_companies) {

          localStorage.setItem('multi_companies', '0');
          let queryParams = {};

          if (fromSignUp) {
            queryParams = {
              welcome: 1,
              v: data.ab_test ? data.ab_test : '0',
              register_origin: data.register_origin ? data.register_origin : 'undefined'
            }
          }

          if (data.has_to_show_account_verification_modal) {

            this.router.navigate(['acesso', 'ativar-conta']);

          } else if (data.show_business_segment_modal) {

              if (this.loginPlanIdQueryParam) {
                this.navigateToPlansPageWithPlanId(this.loginPlanIdQueryParam)
              } else {
                this.navigateToResearchPage()
              }

          } else if (
            redirectToAfterAuthentication === '/conta/documentos'
            || redirectToAfterAuthentication === ''
            || redirectToAfterAuthentication.indexOf('http') !== -1
          ) {
            // we only accept relative paths, to prevent redirect to other website

            this.router.navigate(['conta', 'documentos'], {
              queryParams: {
                ...queryParams,
              }
            });

          } else {
            sessionStorage.removeItem('redirect_after_authentication');
            history.pushState(null, '', redirectToAfterAuthentication);
            window.location.reload();
          }
        }
      },
      err => {
        console.error(err);
      }
    );
  }

  getNewslettersNotificationInfo() {
    return concat(
      this.refreshAccessTokenIfNeeded(),
      this.http.post<any>(`${this.support_api_url}newsletter-info/`, {lang: this.lang})
        .pipe(catchError(this.handleError))
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`
      );
    }
    return throwError(
      error.error
    );
  }

  forgotPassword(email: string): Observable<any> {
    return this.http.post<any>(this.auth_api_url + 'forgot_password/', {email});
  }

  redefinePassword(token: string, password: typeof token): Observable<any> {
    const data: Readonly<RedefinePassword> = {
      token, password
    };
    return this.http.post<any>(`${this.auth_api_url}redefine_password/`, data);
  }

  logout() {
    localStorage.removeItem('auth_access_token');
    localStorage.removeItem('auth_refresh_token');
    localStorage.removeItem('auth_expiration_access_token');
    localStorage.removeItem('auth_expiration_refresh_token');
    localStorage.removeItem('user_email');
    localStorage.removeItem('multi_companies');
    localStorage.removeItem('has_assinatura_lote');
    localStorage.removeItem('infos-modal-confirm-email');
    localStorage.removeItem('role_membership');
    sessionStorage.removeItem('successfulFiles');

    this.cookieService.deleteCookie({
      name: 'modal_paguemenos',
      path: '/'
    });

    this.cookieService.deleteCookie({
      name: 'access_page',
      path: '*'
    });

    this.store.dispatch(clearFolderWhereUserWasInDocumentListRedux());
  }

  refreshTokenObserver() {
    const refresh_token = this.getRefreshToken();
    return this.http.post<any>(this.auth_api_url + 'token-refresh/', {refresh: refresh_token})
      .pipe(
        tap(response => this.updateAccessToken(response)),
        switchMap(ev => this.emptyObserver())
      );
  }

  private hasToken() {
    return this.getAccessToken() != 'undefined' && this.getRefreshToken() != 'undefined';
  }

  refreshAccessTokenIfNeeded() {
    if (this.hasToken() && !moment().isBefore(this.getAccessTokenExpiration())) {
      return this.refreshTokenObserver();
    } else {
      // TODO: melhorar essa gambiarra (ver backend service no module admin para entender)
      return this.emptyObserver();
    }
  }

  getAccessToken() {
    return localStorage.getItem('auth_access_token') || '';
  }

  isAuthenticatedAndNotExpired(): boolean {
    const tokensValid = this.getAccessToken() != 'undefined' && this.getRefreshToken() != 'undefined';
    return tokensValid && moment().isBefore(this.getRefreshTokenExpiration());
  }

  emptyObserver() {
    return new Observable(
      observer => observer.complete()
    );
  }

  getV3Captcha() {
    const action: ActionRecaptcha = 'google_captcha_v3';
    return this._recaptchaV3Service.execute(action).pipe(
      tap(token => {
        localStorage.setItem('captcha_token', token);
        localStorage.setItem('captcha_provider', 'googlev3');
      }),
      switchMap(ev => this.emptyObserver())
    ).pipe(
      catchError(() => {
        this.warningService.toastrError(this.t('Não foi possível carregar o Google Captcha. Por favor, atualize a página.'));
        return this.emptyObserver();
      })
    );
  }

  private setSession(authResult) {
    localStorage.setItem('auth_access_token', authResult.access);
    localStorage.setItem('auth_refresh_token', authResult.refresh);
    this.setRefreshTokenExpiration();
    this.setAccessTokenExpiration();
  }

  updateAccessToken(authResult) {
    localStorage.setItem('auth_access_token', authResult.access);
    this.setAccessTokenExpiration();
  }

  private getRefreshToken() {
    return localStorage.getItem('auth_refresh_token');
  }

  private getAccessTokenExpiration() {
    return JSON.parse(localStorage.getItem('auth_expiration_access_token'));

  }

  private getRefreshTokenExpiration() {
    return JSON.parse(localStorage.getItem('auth_expiration_refresh_token'));
  }

  setAccessTokenExpiration() {
    localStorage.setItem('auth_expiration_access_token', JSON.stringify(moment().add(270, 'seconds')));
  }

  setRefreshTokenExpiration() {
    localStorage.setItem('auth_expiration_refresh_token', JSON.stringify(moment().add(1, 'days').subtract(120, 'minutes')));
  }

  isEmailValid(email: string): boolean {
    if (!email || email.length < 1) {
      return false;
    }
    return this.helpersGlobalService.isEmailValid(email);
  }

  getNewsletterNotification() {
    this.getNewslettersNotificationInfo().subscribe(
      data => {
        this.store.dispatch(updateNonViewedNewsletterNotifications({
          payload: data.non_viewed_newsletter_count
        }));
      },
      err => {
        console.log(err);
      }
    );
  }

  openOldModalForUsersUnpaid(user: User){
    const pagamentosBackendService = this.injector.get(PagamentosBackendService)
    pagamentosBackendService.getPaywall(user.company.id).subscribe(
      (data: PaywallData) => {
        const showUnpaidPopup = data.show_unpaid_popup && user.is_owner
        if(showUnpaidPopup) {
          this.dialog.open(OldModalUnpaidComponent, {
            panelClass: 'modal-unpaid-dialog-container'
          })
        }
      },
      err => {
        console.error(err)
      }
    )
  }

  async openModalForUsersUnpaid(user: User): Promise<boolean> {
    const pagamentosBackendService = this.injector.get(PagamentosBackendService);
    try {
      const data: PaywallData = await pagamentosBackendService.getPaywall(user.company.id).toPromise();
      this.showUnpaidPopup = data.show_unpaid_popup && user.is_owner;
      return this.showUnpaidPopup;
    } catch (err) {
      console.error(err);
      this.showUnpaidPopup = false;
      return this.showUnpaidPopup;
    }
  }
}
