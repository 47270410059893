import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Router} from '@angular/router';

@Component({
  selector: 'app-modal-freemium',
  templateUrl: './modal-freemium.component.html',
  styleUrls: ['./modal-freemium.component.scss']
})
export class ModalFreemiumComponent implements OnInit {
  message = 'É preciso ter um plano mensal ou anual ativo para poder utilizar esta funcionalidade.';

  constructor(
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public messageData: string
    ) {
  }

  ngOnInit(): void {
    if (this.messageData) { this.message = this.messageData; }
  }

  async goToPricePage() {
    await this.router.navigate(['conta', 'configuracoes', 'plans']);
  }

}
