import {Component, ElementRef, Input, OnInit, Optional, ViewChild} from '@angular/core';
import {ControlContainer, FormGroup} from '@angular/forms';
import {MaskApplierService} from 'ngx-mask';


@Component({
  // tslint:disable-next-line:component-selector
  selector: '[formGroup] zs-phone-input, [formGroupName] zs-phone-input',
  templateUrl: './phone-input-zs.component.html',
  styleUrls: ['./phone-input-zs.component.scss'],
})
export class PhoneInputZSComponent implements OnInit {
  @ViewChild('ddi', {read: ElementRef, static: false}) ddiInput!: ElementRef<HTMLInputElement>;
  @ViewChild('phoneNumber', {read: ElementRef, static: false}) phoneNumberInput!: ElementRef<HTMLInputElement>;

  @Input() label = 'Celular';
  @Input() disabled = false;

  public phoneNumberFormGroup: FormGroup;

  constructor(
    @Optional() private controlContainer: ControlContainer,
    private maskService: MaskApplierService) {
  }


  ngOnInit(): void {
    this.phoneNumberFormGroup = this.controlContainer.control as FormGroup;
    if (!this.phoneNumberFormGroup.get('country').value || this.phoneNumberFormGroup.get('country').value === '') {
      this.phoneNumberFormGroup.get('country').setValue('55');
    }
    this.phoneNumberFormGroup.get('numberWithDDD').valueChanges.subscribe(value => {
      if (this.phoneNumberFormGroup.get('country').value !== '55') {
        return this.phoneNumberFormGroup.get('number').setValue(value);
      }
      const [ddd, number] = this.splitNumberAndDDD(value);
      this.phoneNumberFormGroup.get('ddd').setValue(ddd);
      this.phoneNumberFormGroup.get('number').setValue(number);
    });
  }

  splitNumberAndDDD(numberWithDDD: string) {
    const numberWithMask = this.maskService.applyMask(numberWithDDD, '(00) 00000-0009');
    let ddd = '';
    const dddMatch = numberWithMask.match(/\(\d{2}\)/g);
    if (dddMatch) {
      ddd = dddMatch[0].replace('(', '').replace(')', '');
    }
    const number = numberWithDDD.replace(ddd, '');
    return [ddd, number];
  }

  focusBothInputs() {
    (this.ddiInput.nativeElement.firstChild as HTMLElement).classList.add('mat-focused');
    (this.phoneNumberInput.nativeElement.firstChild as HTMLElement).classList.add('mat-focused');
  }

  focusOut() {
    (this.ddiInput.nativeElement.firstChild as HTMLElement).classList.remove('mat-focused');
    (this.phoneNumberInput.nativeElement.firstChild as HTMLElement).classList.remove('mat-focused');
  }
}
