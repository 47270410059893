import {Action, createReducer, on} from '@ngrx/store';
import {NewsletterNotificationCount} from '../../user-navbar.model';
import {updateNonViewedNewsletterNotifications} from './newsletter-modal-action';

export const INITIAL_STATE = {
    non_viewed_newsletter_count: '-1'
} as NewsletterNotificationCount;

const _reducerNewsletterModal = createReducer(
    INITIAL_STATE,
    on(updateNonViewedNewsletterNotifications, (state, {payload}) => {
      return {...state, non_viewed_newsletter_count: payload};
    })
);

export function reducerNewsletterModal(state: NewsletterNotificationCount, action: Action) {
  return _reducerNewsletterModal(state, action);
}
