import { on, createReducer, Action } from '@ngrx/store'
import {GeneratedRubricasStates, SignatureClickPDF, UpdateSignedFileProps} from './signature.model'
import * as actions from './signature.actions'

const INITIAL_STATE_SIGNATURE: SignatureClickPDF = {
  lastSignerSelected: undefined,
}
const INITIAL_STATE_HAS_TO_GENERATE_RUBRICA_TEXT_IMAGE: GeneratedRubricasStates = {
  state: false,
  originalFileUpdateDoc: undefined,
  originalFileIsUpdating:false
}

export const _signatureReducer = createReducer(INITIAL_STATE_SIGNATURE,
  on(
    actions.lastSignerSelected,
    (state, { payload }) => ({...state, lastSignerSelected: payload})
  )
)

export const signatureReducer = (state: SignatureClickPDF, action: Action) => {
  return _signatureReducer(state, action)
}


export const _hasToGenerateRubricaTextImageImageReducer = createReducer(
  INITIAL_STATE_HAS_TO_GENERATE_RUBRICA_TEXT_IMAGE,
  on(
    actions.hasToGenerateRubricaTextImage,
    (state, { payload }) => ({...state, state: payload})
  ),
  on(
    actions.originalFileUpdateEvent,
    (state, { originalFileUpdateDoc,originalFileIsUpdating }) => ({...state, originalFileUpdateDoc, originalFileIsUpdating})
  )
)


export const rubricaTextReducer = (state: GeneratedRubricasStates, action: Action) => {
  return _hasToGenerateRubricaTextImageImageReducer(state, action)
}

const INITIAL_STATE_HAS_TO_UPDATE_SIGNED_FILE: UpdateSignedFileProps = {
  signedFileUpdateDoc: undefined,
  signedFileIsUpdating:false
}

export const _hasToUpdateSignedFile = createReducer(
  INITIAL_STATE_HAS_TO_UPDATE_SIGNED_FILE,
  on(
    actions.hasToUpdateSignedFile,
    (state, { signedFileUpdateDoc, signedFileIsUpdating }) => ({...state, signedFileUpdateDoc: signedFileUpdateDoc, signedFileIsUpdating: signedFileIsUpdating, })
  )
)


export const hasToUpdateSingedFileReducer = (state: UpdateSignedFileProps, action: Action) => {
  return _hasToUpdateSignedFile(state, action)
}
