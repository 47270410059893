<div class="container-observers-input">
  <span>{{ 'Alguém mais deve receber notificações por e-mail ao final das assinaturas deste documento?' | translate }}</span>
  <mat-form-field style="width: 100%; margin-top: 22px;" appearance="fill">
    <mat-chip-list #observers>
      <mat-chip *ngFor="let email of observersEmails" (removed)="remove(email)">
        {{email}} <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <input class="observers-email-input-test" matInput
             placeholder="{{'E-mails dos destinatários (limite de 20).' | translate}}"
             [matChipInputFor]="observers"
             [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
             [matChipInputAddOnBlur]="addOnBlur"
             (focusout)="sendObserverForm()"
             (matChipInputTokenEnd)="add($event)">
    </mat-chip-list>
  </mat-form-field>
</div>
