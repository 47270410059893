<mat-form-field [ngStyle]="{'width': width}" [ngClass]="{'invalid-input': showError}">
  <div></div>
  <mat-label>{{ label | translate }}</mat-label>
  <input *ngIf="!maskInput && !maskCurrency"
     matInput
     class="zs-text-input"
     [id]="inputId"
     [type]="type"
     [name]="controlNameInput"
     [autocomplete]="autocomplete ? 'on' : 'off'"
     [placeholder]="placeholder | translate"
     [disabled]="disabled"
     [formControl]="control"
     [maxlength]="limitLength"
  >
  <input *ngIf="maskInput"
     matInput
     class="zs-text-input"
     [id]="inputId"
     [type]="type"
     [name]="controlNameInput"
     [mask]=maskInput
     [validation]="false"
     [dropSpecialCharacters]="dropSpecialCharacters"
     [autocomplete]="autocomplete ? 'on' : 'off'"
     [placeholder]="placeholder | translate"
     [disabled]="disabled"
     [formControl]="control"
     [maxlength]="limitLength"
  >
  <input *ngIf="maskCurrency"
     matInput
     currencyMask
     class="zs-text-input"
     [id]="inputId"
     [type]="type"
     [name]="controlNameInput"
     [options]="maskCurrency"
     [autocomplete]="autocomplete ? 'on' : 'off'"
     [placeholder]="placeholder | translate"
     [disabled]="disabled"
     [formControl]="control"
     [maxlength]="limitLength"
  >
  <span *ngIf="(icon && !showError)" matSuffix class="zs-icon zs-outside-icon">
    <mat-icon [svgIcon]="icon"></mat-icon>
  </span>
  <span matSuffix class="zs-icon zs-alert-icon"> <mat-icon [matTooltip]="customErrorMessage ? (customErrorMessage | translate) : (errorMessage ? (errorMessage | translate ) : '')"
                                                           *ngIf="showError" svgIcon="alert-triangle"></mat-icon></span>
  <span matSuffix class="zs-icon zs-loading-icon"> <mat-icon *ngIf="loading && !showError"
                                                             svgIcon="circular-loading"></mat-icon></span>
  <span matSuffix class="zs-icon zs-check-icon"> <mat-icon *ngIf="(check && !loading && !showError)"
                                                           svgIcon="check"></mat-icon></span>
  <span matSuffix class="zs-icon" *ngIf="!showError && !loading && !check">
    <ng-content></ng-content>
  </span>
</mat-form-field>
