<!-- The Modal Newsletter -->
<div id="myModal" class="modal"

     [style.display]="newsLetterModalOpen ? 'block':'none'">
  <!-- Modal content -->
  <div class="modal-content" (click)="preventModalFromClosing($event)">
    <section class="news-letter-title">
      <aside>
        <span class="title-newsletter">{{"Mural de novidades" | translate }}</span>
      </aside>
      <aside>
        <button mat-icon-button (click)='closeModal()'>
          <mat-icon class="zs-icon-close">close</mat-icon>
        </button>
      </aside>
    </section>

    <div class="container-text-newsletter">
      <h3>{{"Confira abaixo as últimas novidades que a ZapSign preparou para você:" | translate }}</h3>
    </div>

    <mat-accordion>
      <mat-expansion-panel *ngFor="let news of newsletterInfos">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ news.date_release | date:'dd/MM/yyyy' }} &nbsp;&nbsp; <strong>{{ news.title }}</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="text-p color-grey-700" [innerHTML]="news.content" style="white-space:pre-wrap;"></p>
        <section class="section-newsletter">
          <img class="news-img" *ngIf="news.picture" src="{{news.picture.url}}" alt="{{news.picture.title}}">
        </section>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
