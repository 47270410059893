import {Component, EventEmitter, Input, Output} from '@angular/core';
@Component({
  selector: 'app-switch-toggle',
  templateUrl: './switch-toggle.component.html',
  styleUrls: ['./switch-toggle.component.scss']
})
export class SwitchToggleComponent {
  @Input() switchStep = 0;
  @Input() switchStepsLabels: string[] = [];
  @Output() changeSwitchEmitter: EventEmitter<number> = new EventEmitter();

  toggleSwitch(switchStep: number) {
    this.changeSwitchEmitter.emit(switchStep);
  }
}
