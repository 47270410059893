import {SignPageHeaderProps} from './sign-page-header.model';
import {Action, createReducer, on} from '@ngrx/store';
import * as actions from './sign-page-header.actions';
export const INITIAL_STATE: SignPageHeaderProps = Object.freeze({
  logo: 'assets/img/logo/Logo-ZapSign.svg',
  showDownloadButton: false,
  showSigners: false,
  downloadDocuments: [],
  signers: [],
  showHeader: false
});

const _signPageFooterReducer = createReducer(INITIAL_STATE,
  on(actions.setSignerHeaderProps, (state, { payload }) => ({
    ...state,
    logo: payload.logo,
    showDownloadButton: payload.showDownloadButton,
    downloadDocuments: payload.downloadDocuments,
    signers: payload.signers
  })),
  on(actions.setSignerHeaderLogo, (state, { payload }) => ({
    ...state,
    logo: payload
  })),
  on(actions.setSignersArray, (state, { payload }) => ({
    ...state,
    signers: payload
  })),
  on(actions.showSignersButton, (state, { payload }) => ({
    ...state,
    showSigners: payload
  })),
  on(actions.setDocumentsArray, (state, { payload }) => ({
    ...state,
    downloadDocuments: payload
  })),
  on(actions.showDocumentButton, (state, { payload }) => ({
    ...state,
    showDownloadButton: payload
  })),
  on(actions.setSignerHeaderShow, (state, { payload }) => ({
    ...state,
    showHeader: payload
  })),
);

export default function signPageHeaderReducer(state: SignPageHeaderProps, action: Action) {
  return _signPageFooterReducer(state, action);
}
