import {Component, Input} from '@angular/core';
type typeSize = 'normal' | 'short';

@Component({
  selector: 'app-spinner-loading',
  templateUrl: './spinner-loading.component.html',
  styleUrls: ['./spinner-loading.component.scss']
})
export class SpinnerLoadingComponent {
  @Input() size: typeSize = 'normal';
}
