import {Component} from '@angular/core';
import {Store} from '@ngrx/store';
import {NgxImageCompressService} from 'ngx-image-compress';
import {WebcamImage, WebcamInitError} from 'ngx-webcam';
import {Observable, Subject} from 'rxjs';
import {AppState} from 'src/app/app.state';
import {usePhotoDocument, useUserAgent} from '../../../../app.state.selectors';
import {UtilsService} from '../../../../shared/services/utils/utils.service';
import {WarningService} from '../../../warning.service';
import {PhotoDocumentBase64Verse} from '../../store/PhotoDocument/photo-document.action';

@Component({
  selector: 'app-verse',
  templateUrl: './verse.component.html',
  styleUrls: ['../../modal-camera.component.scss', '../../modal-documents-old/square.component.scss']
})
export class VerseComponent {

  trigger: Subject<void> = new Subject<void>();
  webcamImagePhotoDocument: WebcamImage = null;
  modalOpen = false;
  allowCameraSwitch = true;
  videoOptions: MediaTrackConstraints = {
    width: {
      ideal: 1024
    },
    height: {
      ideal: 576
    }
  };

  errors: WebcamInitError[] = [];
  userAgent = {
    isMobile: null,
    isTablet: null,
    isDesktop: null
  };
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

  constructor(
    private store: Store<AppState>,
    public warningService: WarningService,
    public utilsService: UtilsService,
    private imageCompressService: NgxImageCompressService
    ) {
    store.select(useUserAgent).subscribe(({ isMobile, isTablet, isDesktop }) => {
      this.userAgent.isDesktop = isDesktop;
      this.userAgent.isMobile = isMobile;
      this.userAgent.isTablet = isTablet;
    });
    store.select(usePhotoDocument).subscribe(({ viewModalDocumentVerse }) =>
      this.modalOpen = viewModalDocumentVerse
    );
  }

  takeSnapshot(): void {
    this.trigger.next();
    this.closeModal();
  }

  handleInitError(error: WebcamInitError): void {
    const { mediaStreamError } = error;
    const name = mediaStreamError?.name;

    if (mediaStreamError && name === 'NotAllowedError') {
      this.errors.push(error);
    }
  }

  handleImage(image: WebcamImage): void {
    this.webcamImagePhotoDocument = image;
    const imageAsDataUrl = image.imageAsDataUrl;
    const { imageAsDataUrl: noCompressedImage } = this.webcamImagePhotoDocument;

    this.imageCompressService.compressFile(imageAsDataUrl, 1, 70, 90).then(
      imageCompressed => {
        const isCompressedImage = imageCompressed ? imageCompressed : noCompressedImage;
        this.store.dispatch(new PhotoDocumentBase64Verse(isCompressedImage));
      }
    ).catch(() => this.store.dispatch(new PhotoDocumentBase64Verse(noCompressedImage)));
  }
  get observableCamera(): Observable<void> {
    return this.trigger.asObservable();
  }
  openModalNotHaveCamera() {
    this.utilsService.openModalNoCameraAvailable('modal-document-verse');
    this.closeModal();
  }
  closeModal() {
    this.utilsService.closeModalDocumentVerse('modal-document-verse');
  }
  get nextWebcamObservable(): Observable<string | boolean> {
    return this.nextWebcam.asObservable();
  }

}
