import {Action, createReducer, on} from '@ngrx/store';
import * as actions from './paywall.actions';
import {PaywallReducerProps} from './paywall.model';

export const INITIAL_STATE: PaywallReducerProps = {
  paywall: null
};

const _currentPaywallReducer = createReducer(
  INITIAL_STATE,
  on(actions.setPaywallReducer, (state, { payload }) => ({
    ...state,
    paywall: payload
  }))
);

export function currentPaywallReducer(state: PaywallReducerProps, action: Action) {
  return _currentPaywallReducer(state, action);
}
