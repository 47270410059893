import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-bullet-counter-documents',
  templateUrl: './bullet-counter-documents.component.html',
  styleUrls: ['./bullet-counter-documents.component.scss']
})
export class BulletCounterDocumentsComponent implements OnInit {
  @Input() consumedDocs: number;
  @Input() docsLimit: number;
  parsedAvailableDocs: string;

  ngOnInit() {
    const numberFormatter = new Intl.NumberFormat();
    this.parsedAvailableDocs = numberFormatter.format(this.availableDocs);
  }


  get availableDocs() {
    return this.docsLimit - this.consumedDocs;
  }

  private clampPercentage(number: number): number {
    return Math.max(0, Math.min(number, 100));
  }

  getPercentageOfDocsConsumption(): number {
    const percentage = (100 * this.consumedDocs) / this.docsLimit;
    return this.clampPercentage(percentage);
  }

}
