import { ActionsNoComputerCamera, ActionTypesNoComputerCamera } from './no-computer-camera.actions';
import { NoComputerCameraProps } from './no-computer-camera.model';

const INITIAL_STATE: NoComputerCameraProps = Object.freeze({
  noComputerCamera: false,
  whoCalled: ''
});

export const noComputerCameraReducer = (state = INITIAL_STATE, action: ActionsNoComputerCamera) => {
  switch (action.type) {
    case ActionTypesNoComputerCamera.VIEW_MODAL_NO_COMPUTER_CAMERA: {
      return {
        ...state,
        noComputerCamera: true,
        whoCalled: action.payload === '' ? '' : action.payload
      };
    }
    case ActionTypesNoComputerCamera.HIDE_MODAL_NO_COMPUTER_CAMERA: {
      return {
        ...state,
        noComputerCamera: false,
        whoCalled: action.payload === '' ? '' : action.payload
      };
    }
    default: return state;
  }
};
