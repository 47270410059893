<div>
  <div class="user-group-list">
    <div *ngFor="let userGroup of userGroups; index as i" class="user-group-item">
      <div class="user-group-item-options">
        <section>
          <p class="user-group-item-name"><strong>{{userGroup.name}}</strong></p>
        </section>
        <div class="user-group-item-buttons">
          <button mat-icon-button (click)="showMembersUserGroup(userGroup.id, i)" matTooltip="{{'Participantes do grupo' | translate}}">
            <mat-icon class="mat-icon-down" svgIcon="icon_group"></mat-icon>
          </button>
          <button mat-icon-button (click)="editUserGroup(userGroup)" matTooltip="{{'Editar grupo' | translate}}">
            <mat-icon>edit_gray</mat-icon>
          </button>
          <button mat-icon-button (click)="showConfirmDelete(i)" matTooltip="{{'Excluir grupo' | translate}}">
            <mat-icon>delete_gray</mat-icon>
          </button>
        </div>
      </div>
      <div *ngIf="confirmDelete === true && confirmDeleteIndex === i"
        class="user-group-item-delete">
        <p>{{"Tem certeza que deseja excluir este grupo? Essa ação não poderá ser desfeita. Todos os usuários pertencentes ao grupo ele serão automaticamente configurados como Limitados." | translate}}</p>
        <div class="user-group-item-delete-buttons">
          <button mat-button (click)="confirmDelete = false">
            {{"Manter grupo" |translate}}
          </button>
          <button mat-button color="warn" (click)="deleteUserGroup(userGroup.id)">
            {{"Excluir grupo (sem volta)" |translate}}
          </button>
        </div>
      </div>
      <div *ngIf="showMembers && showMembersIndex === i">
        <p *ngIf="emailInUserGroup.length === 0" class="zs-email">{{'Este grupo ainda não possui membros.'}}</p>
        <div *ngFor="let email of emailInUserGroup; index as iEmail" class="zs-member-and-icon">
          <p *ngIf="emailInUserGroup" class="zs-email">{{email}}</p>
          <mat-icon
            @inOut
            class="zs-icon-close"
            size="10px"
            *ngIf="deleteMemberIcon"
            (click)="changeStateDeleteMember(iEmail)">close
          </mat-icon>
          <span @inOut *ngIf="deleteMemberText && iEmail === memberIndex" class="zs-delete-question-section">
            <p class="zs-question">{{"Deseja remover do grupo?" |translate}}</p>
            <p class="zs-yes-answer" (click)="deleteMember(userGroup.id, email)">{{"Sim, remover" |translate}}</p>
            <p class="zs-no-answer" (click)="deleteMemberText = false; deleteMemberIcon = true">{{"Não"}}</p>
          </span>
        </div>
      </div>
    </div>
  </div>
  <app-old-modal-user-groups-buttons
    [state]="state"
    [closeButton]="false"
    (changeState)="changeState()">
  </app-old-modal-user-groups-buttons>
</div>
