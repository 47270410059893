import {Action, createReducer, on} from '@ngrx/store';
import * as actions from './current-tab-actions';
import {CurrentTabActiveMethodsSignature} from './current-tab-models';


export const INITIAL_STATE: CurrentTabActiveMethodsSignature = {
  currentTabActive: 'draw'
};


const _currentTabReducer = createReducer(INITIAL_STATE, on(
  actions.currentTabActiveOptionsSignatureAction, (state, {payload}) => ({
    currentTabActive: payload
  })
));


export function currentTabReducer(state: CurrentTabActiveMethodsSignature, action: Action) {
  return _currentTabReducer(state, action);
}
