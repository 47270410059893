import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MixpanelService} from '../../shared/services/mixpanel.service';

interface MixpanelSendTabbar {
  name: string;
  type: string;
}

export interface TabbarElement {
  title: string;
  url: string;
  icon?: string;
}
@Component({
  selector: 'app-tabbar',
  templateUrl: './tabbar.component.html',
  styleUrls: ['./tabbar.component.scss']
})
export class TabbarComponent implements OnInit {

  @Input() loading: boolean = false;
  @Input() tabSelectedIndex = 0;
  @Input() title = '';
  @Input() tabbarElements: TabbarElement[] = [];
  @Input() mixpanel: MixpanelSendTabbar = null;
  @Input() getInitialIndex = false;
  @Input() showFooter = true;
  @Input() showMobileHeader = true;

  @Output() changeIndexEmiter = new EventEmitter<number>();
  @Output() changeNameTabEmiter = new EventEmitter<string>();


  constructor(
    private router: Router,
    private mixpanelService: MixpanelService,
    private route: ActivatedRoute,
    ) {
  }

  ngOnInit(): void {
    if (this.getInitialIndex) {
      this.selectInitialTab();
    }
  }

  changeTabIndex($event: number) {
    this.tabSelectedIndex = $event;
    this.changeIndexEmiter.emit($event);
    this.changeNameTabEmiter.emit(this.getTabNameFromIndex($event));
    const tabName = this.getTabNameFromIndex($event);
    if (tabName) {
      if (this.mixpanel) {
        this.mixpanelService
          .sendEventMixpanel(this.mixpanel.name, {type: this.mixpanel.type, subMenu: tabName})
          .subscribe();
      }
      this.redirectToTab(tabName);
    }
  }

  private redirectToTab(tabName: string) {
    this.router.navigate([], {
      queryParams: {
        tab: tabName,
      },
      queryParamsHandling: 'merge'
    });
    this.tabSelectedIndex = this.tabIndexDictionary[tabName];
  }

  private getTabNameFromIndex($tabIndex) {
    return Object.keys(this.tabIndexDictionary).find(key => this.tabIndexDictionary[key] === $tabIndex);
  }

  get tabIndexDictionary() {
    const availableTabs = this.tabbarElements.map((tabbarElement: TabbarElement) => {
      return tabbarElement.url;
    });
    const tabIndexDictionary = {};
    availableTabs.forEach((tab, index) => {
      tabIndexDictionary[tab] = index;
    });
    return tabIndexDictionary;
  }

  private selectInitialTab() {
    const tabIndexName = this.route.snapshot.queryParams.tab;
    const tabIndex = this.tabbarElements.findIndex((tabElement: TabbarElement) => {
      return tabElement.url === tabIndexName;
    });
    if (tabIndex >= 0) {
      this.tabSelectedIndex = tabIndex;
      this.changeIndexEmiter.emit(tabIndex);
    }
  }
}
