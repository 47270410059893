import { Injectable } from '@angular/core';
import { TranslatorService } from 'src/app/components/translator.service';
import { Language } from 'src/translation/translation';

@Injectable({
  providedIn: 'root'
})
export class ConsumerTranslatorService {
  private readonly lang: Language = localStorage.getItem('lang') as Language || 'pt-br';

  constructor(private translatorService: TranslatorService) { }


  translate(ptText: string, replacements?: string[]) {
    return this.translatorService.translate(ptText, this.lang, replacements);
  }
}
