import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {cnpj as validatorCNPJ, cpf as validatorCPF} from 'cpf-cnpj-validator';
import moment from 'moment';
import {DeviceDetectorService} from 'ngx-device-detector';
import {AppState} from '../app.state';
import * as UserAgentAction from './store/SignPage/signpage.actions';

moment.locale('pt-br');

@Injectable({
  providedIn: 'root'
})
export class SignerUtilsService {

  constructor(
    private device: DeviceDetectorService,
    private store: Store<AppState>
  ) { }

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(({ coords }) => resolve({ lng: coords.longitude, lat: coords.latitude }),
        err => {
          reject(err);
          if (err.PERMISSION_DENIED) {
            // this.warningService.toastrInfo(this.t('Você não deu permissão de acesso a geolocalização.')
          } else if (err.POSITION_UNAVAILABLE) {
            // this.warningService.toastrInfo(this.t('Não foi possível alcancar sua localização.')
          }
        });
    });
  }

  userAgent(): void {
    const isMobile = this.device.isMobile();
    const isTablet = this.device.isTablet();
    const isDesktop = this.device.isDesktop();
    this.store.dispatch(new UserAgentAction.GetUserAgent({ isDesktop, isMobile, isTablet }));
  }

  validationFullName(fullName: string): boolean {
    const words = fullName.trim().split(' ');

    if (words.length < 2) {
      return false;
    }

    const hasNumbers = words.some(word => /\d/.test(word));
    if (hasNumbers) {
      return false;
    }

    return true;
  }

  validationCNPJ(cnpj: string) {
    return validatorCNPJ.isValid(cnpj);
  }

  validationCPF(cpf: string): boolean {
    return validatorCPF.isValid(cpf);
  }

   validationDate(date: string) {
    const replaced = date.replace('/', '-').replace('/', '-');
    const dateFormatted = moment(replaced, 'DD-MM-YYYY', 'pt', true);

    return dateFormatted.isValid();
  }
}
