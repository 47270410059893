import {Action, createReducer, on} from '@ngrx/store';
import * as actions from './plans.actions';
import {PlansReducerProps} from './plans.model';
import {setAddonsSubscriptions} from "./plans.actions";

export const INITIAL_STATE: PlansReducerProps = {
  plans: [],
  plansAPI: [],
  plansStripe: [],
  plansAddon: [],
  plansAddonStrip: [],
  selectPlan: undefined,
  extraSeats: 1,
  credits: undefined,
  addonsSubscriptions: [],
};


const _plansReducer = createReducer(INITIAL_STATE,
  on(actions.setPlans, (state, { payload }) => ({
    ...state,
    plans: payload
  })),
  on(actions.setPlansAPI, (state, { payload }) => ({
    ...state,
    plansAPI: payload
  })),
  on(actions.setPlansStripe, (state, { payload }) => ({
    ...state,
    plansStripe: payload
  })),
  on(actions.setPlansAddon, (state, { payload }) => ({
    ...state,
    plansAddon: payload
  })),
  on(actions.setPlansAddonStrip, (state, { payload }) => ({
    ...state,
    plansAddonStrip: payload
  })),
  on(actions.setSelectPlan, (state, { payload }) => ({
    ...state,
    selectPlan: payload
  })),
  on(actions.setExtraSeats, (state, { payload }) => ({
    ...state,
    extraSeats: payload
  })),
  on(actions.setCredits, (state, { payload }) => ({
    ...state,
    credits: payload
  })),
  on(actions.setAddonsSubscriptions, (state, { payload }) => ({
    ...state,
    addonsSubscriptions: payload
  })),
  on(actions.addAddonSubscription, (state, { payload }) => ({
  ...state,
  addonsSubscriptions: [...state.addonsSubscriptions, payload]
  }))
);

export default function plansReducer(state: PlansReducerProps, action: Action) {
  return _plansReducer(state, action);
}
