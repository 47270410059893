import { Input, Pipe, PipeTransform } from '@angular/core';
import { Language } from 'src/translation/translation';
import { TranslatorService } from './translator.service';

@Pipe({
  name: 'translate',
  pure: true
})
export class TranslatePipe implements PipeTransform {
  @Input() lang: Language = localStorage.getItem('lang') as Language || 'pt-br';

  constructor(
    private _translator: TranslatorService,
  ) { }

  // args = replacements
  transform(value: string, args: any[] = []): string {
    return this._translate(value, this.lang, args);
  }

  private _translate(ptText: string, lang: Language, replacements: string[]= []) {
    return this._translator.translate(ptText, lang, replacements);
  }

}
