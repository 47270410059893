import {ActionsPhotoDocument, PhotoDocumentsActionTypes} from './photo-document.action';
import {PhotoDocumentProps} from './photo-document.model';

export const INITIAL_STATE_PHOTO_DOCUMENT: PhotoDocumentProps = {
  base64_photo_document: '',
  base64_photo_document_verse: '',
  viewModalDocumentFront: false,
  viewModalDocumentVerse: false,
  whoIsCalledModalDocument: ''
};

export const photoDocumentReducer = (state = INITIAL_STATE_PHOTO_DOCUMENT, action: ActionsPhotoDocument ) => {
  switch (action.type) {
    case PhotoDocumentsActionTypes.BASE64_PHOTO_DOCUMENT_FRONT: {
      return {
        ...state,
        base64_photo_document: action.payload
      };
    }
    case PhotoDocumentsActionTypes.BASE64_PHOTO_DOCUMENT_VERSE: {
      return {
        ...state,
        base64_photo_document_verse: action.payload
      };
    }
    case PhotoDocumentsActionTypes.VIEW_MODAL_DOCUMENT_FRONT: {
      return {
        ...state,
        viewModalDocumentFront: true,
        whoIsCalledModalDocument: action.payload
      };
    }
    case PhotoDocumentsActionTypes.HIDE_MODAL_DOCUMENT_FRONT: {
      return {
        ...state,
        viewModalDocumentFront: false,
        whoIsCalledModalDocument: action.payload
      };
    }
    case PhotoDocumentsActionTypes.VIEW_MODAL_DOCUMENT_VERSE: {
      return {
        ...state,
        viewModalDocumentVerse: true,
        whoIsCalledModalDocument: action.payload
      };
    }
    case PhotoDocumentsActionTypes.HIDE_MODAL_DOCUMENT_VERSE: {
      return {
        ...state,
        viewModalDocumentVerse: false,
        whoIsCalledModalDocument: action.payload
      };
    }
    default: return state;
  }
};
