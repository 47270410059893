<section *ngIf="urlDataImageWhenScreenRotate" class="section-static-photo-rotated">
  <img [src]="urlDataImageWhenScreenRotate" [alt]="'Assinatura ou visto do signatário' | translate"/>
  <button class="buttons zs-remove-signature-button" mat-button (click)="clearDataImageRotated()">
    <span *ngIf="isVisto">{{ "Remover visto" | translate }}</span>
    <span *ngIf="!isVisto">{{ "Remover assinatura" | translate }}</span>
  </button>
</section>
<div class="zs-container-pad" [ngClass]="isVisto? 'visto' : 'assinatura'" [style.display]="urlDataImageWhenScreenRotate ? 'none' : 'block'">
  <div class="zs_signature_pad_container" [ngClass]="isVisto? 'visto' : 'assinatura'">
    <app-actions-design-signature
      [isEmpty]="signaturePad.isEmpty()"
      (emitClearSignature)="clearSignature()"
      [isVisto]="isVisto"
      (emitSignatureDone)="setUrlDataImageWhenSignaturePadRotate()"
    >
    </app-actions-design-signature>
    <canvas></canvas>
    <div class="zs-linha-assinatura">
      <mat-icon class="zs-icon" svgIcon="edit_04"></mat-icon>
      <div class="zs-linha"></div>
    </div>
    <img *ngIf="signaturePad.isEmpty() && !isVisto" class="fakeSignature" [src]="'assets/img/fakeSignatures/Assine-aqui.svg'|translate">
  </div>
</div>
