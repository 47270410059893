<ng-container>
  <div class="content_progress">
    <div class="destaque_progress">
      <div class="info_consumo_de_documentos">
        <div class="limite_atingido" *ngIf="availableDocs === 0"> {{'Você atingiu o limite' | translate }}</div>
        <div class="limite_disponivel" *ngIf="availableDocs > 0">
          <span>{{'Disponíveis' | translate}}</span>
          <span class="documentos_disponiveis">
          <mat-icon svgIcon="file_06"></mat-icon>
            {{parsedAvailableDocs}}
        </span>
        </div>
      </div>
    </div>
    <div class="bar_progress">
      <div class="progress" [style.width]="getPercentageOfDocsConsumption() + '%'"
           [ngClass]="{
           'threshold-warning': getPercentageOfDocsConsumption() >= 75,
           'full-progress': getPercentageOfDocsConsumption() === 100
           }">
      </div>
    </div>
  </div>
</ng-container>
