import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AssetsService {

  getLogo(relativePath: string) {
    let logo = 'LOGO-ZAPSIGN.svg';
    if (window.location.host.includes(environment.TRUORA_HOST)) {
      logo = 'logo-truora.png';
    }
    return relativePath + 'img/' + logo;
  }

  getSmallLogo(relativePath: string) {
    let logo = 'logo/Logo-ZapSign.svg';
    if (window.location.host.includes(environment.TRUORA_HOST)) {
      logo = 'logo-truora.png';
    }
    return relativePath + 'img/' + logo;
  }
}
