import {Injectable} from '@angular/core';
import {Language} from 'src/translation/translation';
import {TranslatorService} from './translator.service';


export type Status = 'em-curso' | 'assinou' | 'abriu-link' | 'novo' | 'recusado';

export interface Signers {
  name: string;
  status: Status;
}

@Injectable({
  providedIn: 'root'
})
export class SignersDropdownService {
  private readonly lang: Language = localStorage.getItem('lang') as Language || 'pt-br';

  constructor(
    private translatorService: TranslatorService
  ) {
  }

  t(ptText: string) {
    return this.translatorService.translate(ptText, this.lang);
  }

  getColorBullet(status: Status) {
    switch (status) {
      case 'assinou':
        return '#40BF4B';
      default:
        return '#999999';
    }
  }

  getSignerStatus(signer: Signers) {
    switch (signer.status) {
      case 'assinou':
        return this.t('Assinou');
      default:
        return this.t('Assinatura pendente');
    }
  }

  getCountSigners(signers: Signers[]): string {
    return `${signers.filter(signer => signer.status === 'assinou').length}/${signers.length} ${this.t('Assinaturas')}`;
  }
}
