<h1 mat-dialog-title>{{'Quem irá assinar o documento?' | translate}}</h1>
<div mat-dialog-content>
  <p
    innerHTML="{{'Defina o $0 do primeiro signatário do documento.'|translate:[t('nome')]}}"
  >
  </p>

  <section class="container-name-complete-signer">
    <mat-form-field appearance="fill" style="width:100%">
      <mat-label>{{'Nome completo do signatário' | translate}}</mat-label>
      <input [(ngModel)]="newSignerName" type="text" matInput (change)="fillSignerName()">
    </mat-form-field>
  </section>

  <div *ngIf="data.template.participants ? data.template.participants[0].inputs.length > 0 : false">
    <p>
      {{'Opcional' | translate}}: <b>{{'Pré-preencha' | translate}}</b> {{'os campos que quiser.' | translate}}
    </p>
    <app-prefilled
      [model]="data.template"
      (updatePreFilledLink)="updatePreFilledAnswers()"
    >
    </app-prefilled>
  </div>

  <mat-slide-toggle color="primary" (change)="wantToEditDocument = $event.checked" style="max-width: 400px;padding:32px 0;">
    <span style="white-space:break-spaces;">
      {{'Quero adicionar documentos extras no envelope ou editar signatários' | translate}}
    </span>

  </mat-slide-toggle>
  <div mat-dialog-actions class="button_cancel">
    <button mat-button mat-dialog-close [disabled]="createDocLoading">
      {{'Cancelar' | translate}}
    </button>

    <div *ngIf="createDocLoading" class="spinner-loading-container-templates">
      <app-spinner-loading></app-spinner-loading>
    </div>
    <button mat-raised-button color="primary" cdkFocusInitial
            (click)="createDocumentFromTemplate()" [disabled]="createDocLoading">
      <mat-icon>add</mat-icon>
      {{'Criar documento' | translate}}
    </button>
  </div>
</div>

