 <div class="zs-user-group-buttons-footer">
    <button
      *ngIf="closeButton"
      class="zs-user-group-button-footer"
      data-cy="close-button"
      style="margin-right: 19px"
      mat-button
      mat-raised-button
      (click)="closeModal()"
      >
        {{ "Fechar" | translate }}
    </button>
   <!-- TODO: trocar para no_groups -->
    <button
      *ngIf="state === 'list' || state === 'no_gruops' "
      class="zs-user-group-button-footer"
      data-cy="new-group-button"
      mat-button
      mat-raised-button
      color="primary"
      (click)="newGroup()"
      >
        + {{ "Novo Grupo" | translate }}
    </button>
    <button
      *ngIf="state === 'new'"
      class="zs-user-group-button-footer"
      data-cy="create-group-button"
      mat-button
      mat-raised-button
      color="primary"
      [disabled]="buttonDisabled"
      (click)="createGroup()"
    >
      + {{ "Criar Grupo" | translate }}
    </button>
    <button
      *ngIf="state === 'update'"
      class="zs-user-group-button-footer"
      data-cy="update-group-button"
      mat-button
      mat-raised-button
      color="primary"
      (click)="updateGroup()"
        >
        + {{ "Salvar alterações" | translate }}
    </button>
  </div>
