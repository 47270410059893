import {Action, createReducer, on} from '@ngrx/store';
import {setUserInformations} from './get-user-properties.actions';
import {GetUserProps} from './get-user-properties.model';

export const INITIAL_STATE: GetUserProps = {
  user: null
};


const _setIsFirstUserReducer = createReducer(
  INITIAL_STATE,
  on(setUserInformations, (state, {payload}) => {
    return {...state, user: payload};
  }),
);

export function getUserPropertiesReducer(state: GetUserProps, action: Action) {
  return _setIsFirstUserReducer(state, action);
}
