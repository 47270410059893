import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-no-permission-camera',
  templateUrl: './no-permission-camera.component.html'
})
export class NoPermissionCameraComponent {
  @Input() userAgent = {
    isDesktop: null,
    isMobile: null,
    isTablet: null
  };
}
