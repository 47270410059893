<div class="open-actions-background" *ngIf="modalUsersToDelete">
  <div class="container-rmv-users-group">
    <div class="container-title">
      <h2 id="title">{{'Grupo de usuários' | translate}}</h2>
      <zs-button id="btn-close" typeButton="icon" icon="icon_x_close" (click)="onCloseModal()"></zs-button>
    </div>
    <div class="container-name">
      <span class="group-name">{{groupName}}</span>
      <span>{{' - '}}</span>
      <span class="emails-length">{{emailsUserGroup.length + (' USUÁRIOS' | translate)}}</span>
    </div>
    <div class="container-users" *ngFor="let email of emailsUserGroup; index as iEmail">
      <zs-button class="btn-remove-user" typeButton="icon" icon="icon_x_close" (click)="openModalRemove(email)"></zs-button>
      <span>{{email}}</span>
    </div>
    <zs-button typeButton="quaternary" label="Fechar" (click)="onCloseModal()"></zs-button>
  </div>
</div>
<app-modal-remove
  title="Deseja remover do grupo?"
  [info]="actualEmail"
  [showModal]="modalRemove"
  titleBtnCancel="Voltar"
  labelBtn="Remover usuário"
  (confirm)="onRemoveUser(actualEmail)"
  (closeModal)="closeModalRemove()"
></app-modal-remove>
