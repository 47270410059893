<section *ngIf="urlDataImageWhenScreenRotate" class="section-static-photo-rotated">
  <img [src]="urlDataImageWhenScreenRotate" [alt]="'Assinatura ou visto do signatário' | translate"/>
  <button class="buttons" mat-button color="warn" (click)="clearDataImageRotated()">
    <mat-icon>close</mat-icon>
    <span *ngIf="isVisto">{{ "Limpar visto" | translate }}</span>
    <span *ngIf="!isVisto">{{ "Limpar assinatura" | translate }}</span>
  </button>
</section>
<small>
  <span class="zs_show_mobile">{{ "Gire a tela se quiser mais espaço." | translate }}</span>
</small>
<div class="zs_signature_pad_container" [style.display]="urlDataImageWhenScreenRotate ? 'none' : 'block'">
  <app-actions-signature
    [isEmpty]="signaturePad.isEmpty()"
    (emitClearSignature)="clearSignature()">
  </app-actions-signature>
  <img alt="Image container signature helper" class="zs_signature_pad_img_helper"
       [height]="padHeight"
       [width]="padWidth"
       src="/assets/img/signature-pad-helper.png"
  />
  <canvas></canvas>
  <br/>
</div>
