<div class="mobile-header" *ngIf="showMobileHeader">
  <h1 class="mobile-header-title">
    {{title | translate}}
  </h1>
</div>
<div class="header-section" *ngIf="!loading" [ngClass]="!showFooter ? 'header-section-not-footer' : ''">
  <h1 class="header-title" *ngIf="title">
    {{title | translate}}
  </h1>
  <mat-tab-group [selectedIndex]="tabSelectedIndex"
                 (selectedIndexChange)="changeTabIndex($event)"
                 dynamicHeight>
    <ng-container *ngFor="let tabbarElement of tabbarElements | slice:0:3">
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon *ngIf="tabbarElement.icon" style="height: 16px; margin-right: 5px;" [svgIcon]="tabbarElement.icon"></mat-icon>
          <span class="tab-label-text">{{tabbarElement.title | translate}}</span>
        </ng-template>
      </mat-tab>
    </ng-container>
  </mat-tab-group>
</div>

