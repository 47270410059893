import {Component, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {CurrentDocService} from 'src/app/client/page-new-document/services/current-doc.service';
import {PreFilledInputs} from 'src/app/client/page-single-modelo/page-single-modelo.model';
import {Template} from 'src/app/helpers/model/docs';
import {SignerBackendService} from 'src/app/signers/signer-backend.service';
import {Language} from 'src/translation/translation';
import {DocBackendService} from '../doc.backend.service';
import {TranslatorService} from '../translator.service';
import {WarningService} from '../warning.service';
import {
  ConvertNumberToCurrencyService
} from '../../shared/services/convert-number-to-currency/convert-number-to-currency.service';
import {getCurrentUserEmail} from "../../shared/services/current-user";
import { MixpanelService } from 'src/app/shared/services/mixpanel.service';


export class ModalNewDocFromTemplateData {
  template: Template;
}

export class Replacement {
  de: string;
  para: string;
}

@Component({
  selector: 'app-modal-new-doc-from-template',
  templateUrl: './modal-new-doc-from-template.component.html',
  styleUrls: ['./modal-new-doc-from-template.component.scss']
})
export class ModalNewDocFromTemplateComponent {
  lang: Language = localStorage.getItem('lang') as Language || 'pt-br';

  userEmail: string = getCurrentUserEmail() || '';
  newSignerName = '';
  signer_token = '';
  createDocLoading = false;
  replacements: Replacement[] = [];
  wantToEditDocument = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalNewDocFromTemplateData,
    private dialog: MatDialog,
    private signerBackend: SignerBackendService,
    private warningService: WarningService,
    private _translationService: TranslatorService,
    private dialogRef: MatDialogRef<ModalNewDocFromTemplateComponent>,
    private router: Router,
    private currentDocService: CurrentDocService,
    private docBackend: DocBackendService,
    public convertNumberToCurrencyService: ConvertNumberToCurrencyService,
    private mixpanelService: MixpanelService
  ) {
  }

  t(ptText: string) {
    return this._translationService.translate(ptText, this.lang);
  }

  fillSignerName() {
    if (this.data.template.signers[0].name.indexOf('{{') > -1) {
      this.data.template.participants.map(participant => {
        participant.inputs.map((input: PreFilledInputs) => {
          if (input.variable == this.data.template.signers[0].name) {
            input.pre_filled_answer = this.newSignerName;
            this.updatePreFilledAnswers();
          }
        });
      });
    }
  }

  async createDocumentFromTemplate() {
    if (this.newSignerName.trim() === '') {
      this.warningService.toastrError(this.t('Por favor, preencha o nome do primeiro signatário.'));
      return;
    }

    try {
      this.createDocLoading = true;
      this.dialogRef.disableClose = true;

      this.warningService.toastrInfo(this.t('Por favor, aguarde um instante'));

      await this.updatePreFilledAnswers();

      const data = await this.signerBackend.createDocFromTemplate(
        this.data.template.token,
        this.newSignerName,
        this.replacements,
        [],
        this.userEmail,
        true
      ).toPromise();

      this.sendMixpanelEvent();
      this.warningService.toastrSuccess(this.t('Documento criado com sucesso!'));
      this.createDocLoading = false;
      this.dialogRef.disableClose = false;

      this.signer_token = data.signer_token;
      const doc_token = data.doc_token;
      this.dialogRef.close();
      if (this.wantToEditDocument) {
        this.goToEditDocument(doc_token);
      } else {
        this.router.navigate(['conta', 'documentos', doc_token, 'compartilhar']);
      }
    } catch (err) {
      this.createDocLoading = false;
      this.dialogRef.disableClose = false;

      if (err === 'PAYWALL') {
        this.sendMixpanelEvent(err);
        this.warningService.toastrError(
          this.t('Você atingiu o limite de documentos do seu plano. Confira suas Configurações > Plano.')
        );
      } else {
        this.sendMixpanelEvent('Ocorreu um erro ao processar o documento.');
        console.error(err);
        this.warningService.toastrError(this.t('Ocorreu um erro ao processar o documento.'));
      }
    }
  }

  goToEditDocument(doc_token) {
    this.docBackend.getDocument(doc_token).subscribe(
      data => {
        this.currentDocService.setCurrentDoc(data);
        this.currentDocService.setSuccessfulFiles();
        this.router.navigate(['conta', 'documentos', doc_token, 'edit']);
      },
      () => {
        console.error('error in goToEditDocument.');
        this.router.navigate(['conta', 'documentos', doc_token, 'compartilhar']);
      }
    );
  }

  private addExtDateReplacement(input: PreFilledInputs, replacements: any[]): void {
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = new Intl.DateTimeFormat('pt-BR', { month: 'long' }).format(currentDate);
    const year = currentDate.getFullYear();
  
    const formattedDate = `${day} de ${month} de ${year}`;
  
    replacements.push({ de: input.variable, para: formattedDate });
  }
  
  private addDateSignatureReplacement(input: PreFilledInputs, replacements: any[]): void {
    const currentDate = new Date();
    const formattedDate = new Intl.DateTimeFormat('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(currentDate);
  
    replacements.push({ de: input.variable, para: formattedDate });
  }
  

  updatePreFilledAnswers(): Promise<void> {
    return new Promise<void>((resolve) => {
      const replacements = [];
      this.data.template.participants.map((participant) => {
        participant.inputs.map((input: PreFilledInputs) => {
          if (input.input_type === 'date') {
            const preFilledAnswer = String(input.pre_filled_answer);
            replacements.push({de: input.variable, para: this.convertFieldDataToData(preFilledAnswer)});
            return;
          }
          if (input.input_type === 'ext-date') {
            this.addExtDateReplacement(input, replacements);
            return;
          }
  
          if (input.input_type === 'date-signature') {
            this.addDateSignatureReplacement(input, replacements);
            return;
          }
  
          if (input.pre_filled_answer !== undefined && input.pre_filled_answer !== '') {
            let preFilledAnswer = input.pre_filled_answer;
            if (input.input_type === 'dinheiro' && typeof preFilledAnswer === 'number') {
              preFilledAnswer = this.convertNumberToCurrencyService.convertToCurrency(preFilledAnswer);
            }
            replacements.push({de: input.variable, para: preFilledAnswer});
          }
        });
      });
      this.replacements = replacements;
      resolve();
    });
  }
  

  private convertFieldDataToData(field: string) {
    field = field.replace(/\//g, '');
    return field.slice(0, 2) + '/' + field.slice(2, 4) + '/' + field.slice(4);
  }

  private sendMixpanelEvent(err: string = null) {
    this.mixpanelService.sendTemplateFlow(
      'Send Template - Create document (2 of 2)',
      this.data.template,
      this.replacements.length,
      null,
      err
    );
  }

}
