import {Component, Input, OnInit} from '@angular/core';

type NoteType = 'check-note' | 'info-note' | 'warning-note' | 'error-note' | 'soft-warning-note' | '';
type NoteSize = 'full-width' | 'default';
type NoteResponsive = 'only-desktop' | 'all';
type TextFont = 'text_label' | 'text_table';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'zs-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss']
})
export class NoteComponent implements OnInit {
  @Input() noteType: NoteType = 'check-note';
  @Input() size: NoteSize = 'default';
  @Input() text = '';
  @Input() textBoldFinal = '';
  @Input() responsive: NoteResponsive = 'all';
  @Input() withIcon = true;
  @Input() transparent = false;
  @Input() font: TextFont = 'text_table';
  icon = '';

  ngOnInit(): void {
    switch (this.noteType) {
      case 'check-note':
        this.icon = 'shield-tick';
        break;
      case 'info-note':
        this.icon = 'help_octagon';
        break;
      case 'warning-note':
        this.icon = 'info_square';
        break;
      case 'soft-warning-note':
        this.icon = 'info_square';
        break;
      case 'error-note':
        this.icon = 'alert-triangle';
        break;
      default:
        this.icon = 'shield-tick';
    }
  }

}
