import { Component, Input } from '@angular/core';
import { Doc } from 'src/app/helpers/model/docs';

@Component({
  selector: 'app-doc-status-chip',
  templateUrl: './doc-status-chip.component.html',
  styleUrls: ['./doc-status-chip.component.scss']
})
export class DocStatusChipComponent {
  @Input() doc: Doc;
}
