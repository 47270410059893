import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

import {
  CdkDragDrop,
  CdkDragEnd,
  CdkDragEnter,
  CdkDragExit,
  CdkDragStart,
  moveItemInArray
} from '@angular/cdk/drag-drop';

import {
  FileItem,
  FileLikeObject,
  FileUploader,
  FileUploaderOptions,
  ParsedResponseHeaders
} from 'ng2-file-upload';

import {AuthService} from 'src/app/auth/auth.service';
import {Language} from 'src/translation/translation';
import {environment} from '@env/environment';
import {TranslatorService} from '../translator.service';
import {WarningService} from '../warning.service';

@Component({
  selector: 'app-files-dropzone',
  templateUrl: './files-dropzone.component.html',
  styleUrls: ['./files-dropzone.component.scss'],
})

export class FilesDropzoneComponent implements OnInit, OnDestroy {
  lang: Language = localStorage.getItem('lang') as Language || 'pt-br';

  @Output() documentsUpdate = new EventEmitter<any>();
  @Input() convertDocxToPdf = false;
  @Input() pathExampleDownload: string = null;
  @Input() allowDocx = false;
  @Input() allowPdf = true;
  @Input() allowHowManyFiles = 1;
  @Input() maxMbSize = 12.5;
  @Input() isDinamicTemplate: boolean;

  apiUploadUrl: string = environment.API_URL + '/docs/upload-file/';
  uploader: FileUploader;
  isDraggingFiles = false;
  isDraggingSuccessfullFiles = false;
  successfulFiles: DocToSign[];
  refresher: any;
  uploadButtonIsDisabled: boolean;

  @ViewChild('fileInput') fileInput: ElementRef;

  constructor(
    private warningService: WarningService,
    private authService: AuthService,
    private _translationService: TranslatorService,
  ) {
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.successfulFiles, event.previousIndex, event.currentIndex);
    this.setSuccessfulFilesOnLocalStorageAndSendEmitter()
  }

  ngOnInit() {
    this.keepRefreshingAccessToken();
    this.successfulFiles = JSON.parse(sessionStorage.getItem('successfulFiles')) || [];
    this.setSuccessfulFilesOnLocalStorageAndSendEmitter();
    this.startUploader();
  }

  ngOnDestroy() {
    clearInterval(this.refresher);
  }

  t(ptText: string, replacements?: string[]) {
    return this._translationService.translate(ptText, this.lang, replacements);
  }

  keepRefreshingAccessToken() {
    this.refreshAccessToken();
    this.refresher = setInterval(() => {
      this.refreshAccessToken();
    }, 29000);
  }

  refreshAccessToken() {
    this.authService.refreshAccessTokenIfNeeded().subscribe(
      data => {
        this.uploader.options.headers = [
          {name: 'Authorization', value: `Bearer ${this.authService.getAccessToken()}`}
        ];
      },
      err => console.error(err)
    );
  }


  triggerFileInputClick(): void {
    if (this.fileInput) {
      const fileInputElement: HTMLInputElement = this.fileInput.nativeElement as HTMLInputElement;
      fileInputElement.click();
    }
  }

  checkIfUploadButtonIsDisabled(): boolean {
    return this.successfulFiles.length === this.allowHowManyFiles
  }

  startUploader() {
    const options: FileUploaderOptions = {};
    options.url = this.apiUploadUrl + '?convert_docx_to_pdf=' + this.convertDocxToPdf;
    options.isHTML5 = true;
    options.maxFileSize = 1024 * 1024 * this.maxMbSize; // 12.5mb

    options.autoUpload = true;
    options.parametersBeforeFiles = true;
    options.itemAlias = 'file';
    options.method = 'POST';
    options.queueLimit = this.allowHowManyFiles;

    options.headers = [
      {name: 'Authorization', value: `Bearer ${this.authService.getAccessToken()}`}
    ];
    this.uploader = new FileUploader(options);

    this.uploader.onBeforeUploadItem = (file: FileItem) => {
      const totalFiles = this.successfulFiles.length + this.uploader.queue.length;
      if (totalFiles > this.allowHowManyFiles) {
        this.warningService.toastrError(this.t('Você pode subir no máximo $0 documentos.', [this.allowHowManyFiles.toString()]));
        throw new Error();
      }
    };


    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };

    this.uploader.onWhenAddingFileFailed = (fileItem: FileLikeObject) => {
      const {name, size} = fileItem;
      if (!name.endsWith('.pdf') && !this.allowDocx) {
        this.warningService.toastrError(this.t('Só são aceitos arquivos no formato PDF.'));
        throw new Error();
      } else if (size && size > 1024 * 1024 * this.maxMbSize) {
        this.warningService.toastrError(`${this.t('O arquivo')} ${name} ${this.t('contém mais de $0MB. Comprima ou divida o PDF em partes menores utilizando o site ilovepdf.com', [this.maxMbSize.toString()])}`);
        throw new Error();
      }

      let filesAllowed = [];
      if (this.allowPdf) {
        filesAllowed = filesAllowed.concat(['PDF']);
      }
      if (this.allowDocx) {
        filesAllowed = filesAllowed.concat(['Word (.docx)']);
      }

      this.warningService.toastrError(
        `${this.t('Você pode subir')} ${this.allowHowManyFiles > 1 ? this.t('até') + ' '
          + this.allowHowManyFiles + ' ' + this.t('Arquivos') : this.t('apenas um arquivo')}
          ${filesAllowed.join('/')} ${this.t('com no máximo')}
           10MB${this.allowHowManyFiles > 1 ? ' ' + this.t('cada') : ''}.`
      );
    };

    this.uploader.onSuccessItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      const data = JSON.parse(response);
      if (status === 200) {
        const newFile = {
          name: data.file_name,
          url: data.file_url,
          size: data.file_size,
        };
        if (this.allowHowManyFiles === 1) {
          this.successfulFiles = [newFile];
        } else {
          this.successfulFiles = this.successfulFiles.concat([newFile]);
        }
        item.remove();
      }
    };

    this.uploader.onCompleteAll = () => {
      this.setSuccessfulFilesOnLocalStorageAndSendEmitter();
    };

    this.uploader.onErrorItem = (item: FileItem, response: string, status: number, headers: any) => {
      if (status === 406) {
        this.warningService.toastrError(this.t('Ocorreu um erro ao processar o documento.'));
        this.uploader.clearQueue();
        this.uploader.destroy();
        return;
      }
      if (status === 0 || status === 400) {
        this.uploader.removeFromQueue(item);
        if (!response) {
          return;
        }
        if (JSON.parse(response).error === 'WRONG_FORMAT') {
          this.warningService.toastrError(this.t('Só são aceitos arquivos no formato PDF ou Word.'));
        }
        return 0;
      }
      this.warningService.toastrError(this.t('Ocorreu um erro. Por favor, atualize a página.'));
      console.log('error response: ' + response + ' status: ' + status + ' headers: ' + JSON.stringify(headers));
      console.log('*** error item ***' +
        '\nformData: ' + JSON.stringify(item.formData) +
        '\nisError: ' + item.isError +
        '\nisCancel: ' + item.isCancel +
        '\nisReady: ' + item.isReady +
        '\nisSuccess: ' + item.isSuccess +
        '\nisUploading: ' + item.isUploading +
        '\nisUploaded: ' + item.isUploaded +
        '\nalias: ' + item.alias +
        '\nmethod: ' + item.method +
        '\nwithCredentials: ' + item.withCredentials);
    };
  }

  fileOverBase(e: any): void {
    this.isDraggingFiles = e;
  }

  onDragStarted(event: CdkDragStart<any>) {
    this.isDraggingSuccessfullFiles = true;
  }

  onDragEnded(event: CdkDragEnd<any>) {
    this.isDraggingSuccessfullFiles = false;
  }

  removeFile(index: number): void {
    this.successfulFiles.splice(index, 1);
    this.setSuccessfulFilesOnLocalStorageAndSendEmitter();
  }

  checkIfAllowMultipleFilesToUpload(){
    return this.allowHowManyFiles > 1
  }

  checksThatNoFilesWereSent() {
    return this.successfulFiles.length == 0
  }

  checksThatFilesWereSent() {
    return this.successfulFiles.length > 0
  }

  checkIfFilesIsUploadingAndNotHasFilesUploaded() {
    return !this.uploader.isUploading
      && this.checksThatNoFilesWereSent()
  }

  checkIfHasFilesUploading(): boolean {
    return this.uploader.queue.length > 0
  }

  checkIfShowSecondStep(){
    return this.checksThatFilesWereSent() || this.checkIfHasFilesUploading()
  }

  getQuantityFilesUploaded(){
    return this.successfulFiles.length
  }

  checkIfHaveMoreThanOneFileUploaded(){
    return this.successfulFiles.length > 0
  }

  showIntroBackground(){
    return this.checkIfHaveMoreThanOneFileUploaded() || this.uploader.isUploading
  }

  checkIfShowFileUploadView(){
    const filesUploadedQuantityIsLessThanAllowFilesUploaded = this.successfulFiles.length < this.allowHowManyFiles
    const notSendingFiles = !this.uploader.isUploading
    return notSendingFiles && (this.checksThatNoFilesWereSent() || filesUploadedQuantityIsLessThanAllowFilesUploaded)
  }

  checkIfShowIntroView(){
    const notSendingFiles = !this.uploader.isUploading
    return notSendingFiles && this.checksThatNoFilesWereSent()
  }

  goToFile(url) {
    window.open(url, '_blank');
  }

  setSuccessfulFilesOnLocalStorageAndSendEmitter() {
    sessionStorage.setItem('successfulFiles', JSON.stringify(this.successfulFiles))
    this.documentsUpdate.emit(this.successfulFiles);
  }

}

export class DocToSign {
  name: string;
  url: string;
  size: number;
}
