<div
  *ngIf="open"
  id="myModal"
  class="container"
  (click)="onClose()"
>
  <div
    class="content"
    (click)="preventModalFromClosing($event)"
    [ngStyle]="contentStyles"
  >
    <div class="header">
      <h2>{{ title | translate }}</h2>
      <div *ngIf="showCloseButton">
        <zs-button
        (click)="onClose()"
        typeButton="icon"
        icon="icon_x_close"
      >
      </zs-button>
      </div>
    </div>
    <div class="children">
      <ng-content></ng-content>
    </div>
  </div>
</div>
