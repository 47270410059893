import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-users-limit-warning',
  templateUrl: './users-limit-warning.component.html',
  styleUrls: ['./users-limit-warning.component.scss']
})
export class UsersLimitWarningComponent {

  @Input() showPremiumUsersLimit: boolean;

  @Input() showFreeUsersLimit: boolean;

}
