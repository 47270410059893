import { Injectable } from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../app.state';
import {
  saveCurrentTabStepsNewDocument,
  saveKnowIsNewDocumentAction,
  saveTokenDocumentAction
} from '../store/steps-new-document/steps-new-document.actions';
import {StepsNewDocument} from '../store/steps-new-document/steps-new-document.model';

@Injectable({
  providedIn: 'root'
})
export class StepsCreateDocumentService {

  constructor(private store: Store<AppState>) { }

  emmiterCurrentTokenDocumentFromRedux(token: string) {
    this.store.dispatch(saveTokenDocumentAction({
      payload: token
    }));
  }

  emmiterKnowIsNewDocumentFromRedux(isNewDocument: number) {
    this.store.dispatch(saveKnowIsNewDocumentAction({
      payload: isNewDocument
    }));
  }

  emitterTriggerStepsFromRedux(tab: StepsNewDocument) {
    this.store.dispatch(saveCurrentTabStepsNewDocument({
      payload: tab
    }));
  }
}
