<div class="container">
  <div *ngIf="userGroups.length === 0">
    <div class="empty-state">
      <mat-icon class="not-group" svgIcon="not-group-contacts"></mat-icon>
      <div class="title-not-group">
        <p class="title">{{'Aproveite todos os benefícios da Zapsign,' | translate}}</p>
        <p>{{'organize seus usuários em grupos com diferentes níveis de acesso.' | translate}}</p>
      </div>
      <zs-button *ngIf="paywalls.can_create_user_groups"
                 typeButton="secundary" iconDirection="left"
                 label="{{'Criar grupo' | translate}}"
                 icon="add"
                 (click)="openModal()">
      </zs-button>
      <zs-button *ngIf="!paywalls.can_create_user_groups"
                 typeButton="secundary" iconDirection="left"
                 label="{{'Contratar plano' | translate}}"
                 icon="add"
                 (click)="onRedirectToBuyPlan()">
      </zs-button>
    </div>
  </div>
  <div *ngIf="userGroups.length > 0">
    <app-modal-user-group-list
      [state]="state"
      (goToEdit)="goToEdit($event)"
      (goToNew)="goToNew()"
      (deletedEmitter)="deleteUserGroup()">
    </app-modal-user-group-list>
  </div>
</div>
