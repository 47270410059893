import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import { Language } from "./../../../translation/translation";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/app.state";
import { AuthBackendService } from "src/app/auth/backend.service";
import { OldTimezones } from "@components/old-select-timezone/old-timezones";
import { Company, User } from "src/app/helpers/model/user";
import { TranslatorService } from "../translator.service";
import { WarningService } from "../warning.service";
import { UsersReduxService } from "src/app/shared/services/redux/users-redux/users-redux.service";

export interface UpdateCompanyTimezone {
  timezone: string;
}
@Component({
  selector: "app-old-select-timezone",
  templateUrl: "./old-select-timezone.component.html",
  styleUrls: ["./old-select-timezone.component.scss"]
})
export class OldSelectTimezoneComponent implements OnInit,OnDestroy {
  user: User;
  lang: Language = "pt-br";
  timezonesOptions = OldTimezones;
  private reducerSubscriptions: any=[];

  constructor(
    private authBackend: AuthBackendService,
    private warningService: WarningService,
    private _translationService: TranslatorService,
    private _store: Store<AppState>,
    private usersReduxService: UsersReduxService
  ) {}

  ngOnInit(): void {
    this._store.select("translation").subscribe(data => (this.lang = data.language));
    this.getUser();
  }

  ngOnDestroy() {
    this.reducerSubscriptions.map((subscription) => {
      subscription.unsubscribe()
    })
  }
  t(text: string) {
    return this._translationService.translate(text, this.lang);
  }

  getUser() {
    const userSubscription = this.usersReduxService.getUser().subscribe(
      ({ user }) => {
        this.user = user;
      },
      err => {
        console.error(err);
      }
    );
    this.reducerSubscriptions.push(userSubscription)
  }

  updateCompanyTimezone(timezone: string) {
    let params: UpdateCompanyTimezone = { timezone: timezone };
    this.updateCompany(params);
  }

  private updateCompany(params: UpdateCompanyTimezone | any) {
    this.authBackend.updateCompany(this.user.company.id, params).subscribe(
      data => {
        this.warningService.toastrSuccess(this.t("Atualizado com sucesso."));
        this.user.company.timezone = data.timezone;
      },
      err => {
        console.error(err);
        this.warningService.toastrError(
          this.t("Ocorreu algum problema na atualização. Tente atualizar a tela.")
        );
      }
    );
  }
}
