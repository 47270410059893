<section class="card">
    <div *ngIf="showPremiumUsersLimit">
        <h3><strong>{{'Limite atingido' | translate}}</strong></h3>
        <p>{{'Você atingiu o limite de usuários da sua conta. Adicione mais fazendo' | translate }}
          <a href="/conta/configuracoes/plans">{{'upgrade de plano' | translate}}</a> {{ 'ou contrate usuários extras em Configurações > Plano > Planos adicionais ou' | translate}} <a href="/conta/configuracoes/?tab=plans">{{'clicando aqui' | translate}}</a></p>
    </div>
    <div *ngIf="showFreeUsersLimit">
        <h3><strong>{{'Limite atingido' | translate}}</strong></h3>
        <p>{{'Você atingiu o limite de usuários da sua conta. Adicione mais fazendo' | translate}}
          <a href="/conta/configuracoes/plans">{{'upgrade de plano' | translate}}.</a></p>
    </div>
</section>
