<div *ngIf="modalIsOpened">
<div class="modal-overlay">
  <div class="modal-container">
    <div class="zs-mini-logo"></div>
    <div class="texts">
      <div class="texts-heading">
        <div class="title">
          Imperdível!
        </div>
        <div class="title">
          Primeiro mês por apenas <span class="title-price">R$ 0,99</span>
        </div>
      </div>
      <div class="subtitle">Disponível para os planos Profissional, Equipe ou Completo. E ainda cancele quando quiser!</div>
      <div class="buttons">
        <zs-button typeButton="primary" label="Ativar meu desconto" (click)="goToPricePage()"></zs-button>
        <zs-button typeButton="quaternary" label="Ignorar desconto" class="bt-no-discount" (click)="disableModal()"></zs-button>
      </div>
    </div>
  </div>
</div>
</div>
