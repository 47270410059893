<div id="myModal" class="modal" (click)="closeModal(true)">
  <div class="modal-content" (click)="preventModalFromClosing($event)">
    <div class="modal-header-gif">
      <img [src]="gifUrl | translate" alt="folder-feature-gif">
    </div>
    <div class="modal-body">
      <div class="modal-body-content">
        <div class="badge">
          <zs-badge text="NOVIDADE"></zs-badge>
        </div>
        <h2>{{title | translate}}</h2>
        <div class="divisor"></div>
        <h3>{{explanationText | translate}}</h3>
      </div>
      <div class="modal-body-buttons">
        <zs-button
          typeButton="primary"
          label="Experimentar Versão Beta"
          (click)="closeModal(true)"
        ></zs-button>
        <zs-button
          typeButton="link"
          label="Continuar com a Versão Atual"
          (click)="closeModal(false)"
        ></zs-button>
      </div>
    </div>
  </div>
</div>
