import {Injectable} from '@angular/core';

interface DrawImageCanvas {
  canvas: HTMLCanvasElement;
}

interface DrawImageCanvasWidthHeight {
  width: number;
  height: number;
}

interface DrawImageWithGoldenRatioCanvas extends DrawImageCanvas, DrawImageCanvasWidthHeight {
  signatureImage: CanvasImageSource;
}

interface CreateElementsDrawImage extends DrawImageCanvas {
  imgSignature: HTMLImageElement;
}

@Injectable({
  providedIn: 'root'
})
export class DrawImageWithGoldenRatioService {
  private goldenRatio = 1.618;

  private createElements(width: number): CreateElementsDrawImage {
    const imgSignature: HTMLImageElement = document.createElement('img');
    const canvas = document.createElement('canvas');
    this.setCanvasHeightAndWidth(canvas, width);
    return {
      imgSignature,
      canvas,
    };
  }


  private drawImage(properties: DrawImageWithGoldenRatioCanvas, dx: number, dy: number, context: CanvasRenderingContext2D) {
    const {canvas, width, signatureImage, height} = properties;
    this.setCanvasHeightAndWidth(canvas, width);
    context.drawImage(signatureImage, dx, dy, width, height);
  }


  private setCanvasHeightAndWidth(canvas: HTMLCanvasElement, width: number) {
    canvas.width = width;
    canvas.height = width / this.goldenRatio;
  }

async getDataUrlWithGoldenRatioImage(signatureImage: string) {
    const {width, height} = await this.getImageDimensions(signatureImage) as DrawImageCanvasWidthHeight;

    let dataUrl = null;
    const {canvas, imgSignature} = this.createElements(width);
    const ctx = canvas.getContext('2d');
    imgSignature.src = signatureImage;
    imgSignature.style.width = canvas.width + 'px';
    imgSignature.style.height = 'auto';
    imgSignature.style.padding = '5px 0';
    imgSignature.style.display = 'flex';
    imgSignature.style.justifyContent = 'center';
    imgSignature.style.alignItems = 'center';

    const xOffset = imgSignature.width < canvas.width ? ((canvas.width - imgSignature.width) / 2) : 0;
    const yOffset = imgSignature.height < canvas.height ? ((canvas.height - imgSignature.height) / 2) : 0;

    this.drawImage({
      canvas,
      width: imgSignature.width,
      height: imgSignature.height,
      signatureImage: imgSignature
    }, xOffset, yOffset, ctx);

    dataUrl = canvas.toDataURL();
    return dataUrl;

  }


  public getImageDimensions(file) {
    // tslint:disable-next-line:only-arrow-functions
    return new Promise(function(resolved) {
      const createImage = new Image();
      createImage.onload = () => {
        resolved({width: createImage.width, height: createImage.height});
      };
      createImage.src = file;
    });
  }
}
