<div class="acordeom-container" [class]="canOpen ? 'can-open' : ''" (click)="emitChangeActiveEmitter()">
  <zs-button
    *ngIf="canOpen"
    typeButton="icon"
    [icon]="active ? 'icon_chevron_down' : 'icon_chevron_right'"
  ></zs-button>

  <div *ngIf="!canOpen" class="space-for-icon"></div>

  <h2>{{title | translate}}</h2>

  <div class="container-btn">
    <zs-button
      typeButton="quaternary"
      iconDirection="left"
      icon="plus"
      [label]="buttonLabel"
      (click)="emitCreateEmitter($event)"
    ></zs-button>

    <zs-button
      *ngIf="showSecondBtn"
      typeButton="quaternary"
      iconDirection="left"
      icon="plus"
      [label]="secondButtonLabel"
      (click)="emitCreateEmitterSecondBtn($event)"
    ></zs-button>
  </div>

  <div *ngIf="count > 0" class="acordeom-container_options">
    <ng-container *ngIf="showCount">
      <p>{{count | number}} {{countTitle | translate}}</p>
    </ng-container>
    <div class="order-by" (click)="preventDefault($event)">
      <ng-content></ng-content>
    </div>
  </div>
</div>
