import { Injectable } from '@angular/core';
import domtoimage, { Options } from 'dom-to-image';

@Injectable({
  providedIn: 'root'
})
export class TextToImageService {
  private options: Options;

  async convertTextToImage(htmlElementNode: Node): Promise<string> {
    return await domtoimage.toPng(htmlElementNode, this.options);
  }

  setOptionsToConvertTextToImage(options) {
    this.options = options;
  }
}
