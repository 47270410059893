import {ButtonSize} from "../button-primary/button-zs.component";
import {Component, Input} from '@angular/core';


@Component({
  selector: 'zs-download-button',
  templateUrl: './download-button.component.html',
  styleUrls: ['./download-button.component.scss']
})

export class DownloadButtonComponent {
  @Input() typeButton = '';
  @Input() type: 'submit' | 'button' | 'reset' = 'submit';
  @Input() iconDirection = 'right';
  @Input() disable = false;
  @Input() label = '';
  @Input() icon = '';
  @Input() loading: boolean = false;
  @Input() check = false;
  @Input() matTooltip = '';
  @Input() size: ButtonSize = 'medium';
  @Input() iconSize = 'icon-medium';
  @Input() border = 'solid';
  @Input() withBackground = false;
}
