import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {UtilsService} from '@shared/services/utils/utils.service';

@Component({
  selector: 'app-actions-design-signature',
  templateUrl: './actions-design-signature.component.html',
  styleUrls: ['./actions-design-signature.component.scss']
})
export class ActionsDesignSignatureComponent implements OnInit {
  isMobile = false;
  @Input() isEmpty: boolean;
  @Input() isVisto = false;
  @Output() emitClearSignature = new EventEmitter<void>();
  @Output() emitSignatureDone = new EventEmitter<void>();
  @Output() emitOpenModalNoCameraAvailable = new EventEmitter<void>();
  expandSignature = false;

  constructor(
    private device: DeviceDetectorService,
    public utils: UtilsService
  ) {}

  ngOnInit(): void {
    this.isMobile = this.device.isMobile();
  }

  emitterClearSignature() {
    this.emitClearSignature.emit();
  }

  openModalNotHaveCamera() {
    this.utils.openModalNoCameraAvailable('sign-page');
  }
  rotateDesgnSignature() {
    const orientation = screen.orientation;
    const contentSignature = document.querySelector('.zs-container-pad') as HTMLElement;
    const contentSignaturePad = document.querySelector('.zs_signature_pad_container') as HTMLElement;
    if (orientation.type === 'portrait-primary' || orientation.type === 'portrait-secondary') {
      if (this.expandSignature) {
        contentSignature.style.transform = 'rotate(0deg)';
        contentSignature.style.width = 'auto';
        contentSignature.style.height = 'auto';
        contentSignature.style.top = 'auto';
        contentSignature.style.left = 'auto';
        contentSignature.style.margin = 'auto';
        contentSignature.style.zIndex = 'auto';
        contentSignature.style.position = 'relative';
        contentSignature.style.marginBottom = '40px';
        contentSignaturePad.style.width = '100%';
        contentSignaturePad.style.height = '334px';
        contentSignaturePad.style.maxWidth = '773px';
      } else {
        contentSignature.style.transform = 'translate(-50%, -50%) rotate(90deg)';
        contentSignature.style.width = '100vh';
        contentSignature.style.height = '100vw';
        contentSignature.style.top = '50%';
        contentSignature.style.left = '50%';
        contentSignature.style.margin = '0';
        contentSignature.style.zIndex = '100';
        contentSignature.style.position = 'fixed';
        contentSignaturePad.style.width = '100%';
        contentSignaturePad.style.height = '100%';
        contentSignaturePad.style.maxWidth = '100%';
      }
    } else if (orientation.type === 'landscape-primary' || orientation.type === 'landscape-secondary') {
      if (this.expandSignature) {
        contentSignature.style.transform = 'rotate(0deg)';
        contentSignature.style.width = '100%';
        contentSignature.style.height = '100%';
        contentSignature.style.top = 'auto';
        contentSignature.style.left = 'auto';
        contentSignature.style.margin = 'auto';
        contentSignature.style.zIndex = 'auto';
        contentSignature.style.position = 'relative';
        contentSignature.style.marginBottom = '40px';
        contentSignaturePad.style.width = '100%';
        contentSignaturePad.style.height = '334px';
        contentSignaturePad.style.maxWidth = '773px';
      } else {
        contentSignature.style.transform = 'translate(-50%, -50%) rotate(0deg)';
        contentSignature.style.width = '100vw';
        contentSignature.style.height = '100vh';
        contentSignature.style.top = '50%';
        contentSignature.style.left = '50%';
        contentSignature.style.margin = '0';
        contentSignature.style.zIndex = '100';
        contentSignature.style.position = 'fixed';
        contentSignaturePad.style.width = '100%';
        contentSignaturePad.style.height = '100%';
        contentSignaturePad.style.maxWidth = '100%';
      }
    }
    this.expandSignature = !this.expandSignature;
  }
  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event) {
    if (!this.isVisto) {
      const contentSignature = document.querySelector('.zs-container-pad') as HTMLElement;
      const contentSignaturePad = document.querySelector('.zs_signature_pad_container') as HTMLElement;
      if (event.target.screen.orientation.type === 'portrait-primary') {
        contentSignature.style.transform = 'rotate(0deg)';
        contentSignature.style.width = '100%';
        contentSignature.style.height = '100%';
        contentSignature.style.top = 'auto';
        contentSignature.style.left = 'auto';
        contentSignature.style.margin = 'auto';
        contentSignature.style.zIndex = 'auto';
        contentSignature.style.position = 'relative';
        contentSignature.style.marginBottom = '40px';
        contentSignaturePad.style.width = '100%';
        contentSignaturePad.style.height = '334px';
        contentSignaturePad.style.maxWidth = '773px';
      } else if (event.target.screen.orientation.type === 'landscape-primary') {
        contentSignature.style.transform = 'translate(-50%, -50%) rotate(0deg)';
        contentSignature.style.width = '100vw';
        contentSignature.style.height = '100vh';
        contentSignature.style.top = '50%';
        contentSignature.style.left = '50%';
        contentSignature.style.margin = '0';
        contentSignature.style.zIndex = '100';
        contentSignature.style.position = 'fixed';
        contentSignaturePad.style.width = '100%';
        contentSignaturePad.style.height = '100%';
        contentSignaturePad.style.maxWidth = '100%';
      }
      this.expandSignature = !this.expandSignature;
    }
  }

  saveSignature() {
    this.emitSignatureDone.emit();
  }
}
