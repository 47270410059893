import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Language} from '../../../translation/translation';
import {Folder} from '../../helpers/model/docs';
import {DocBackendService} from '../doc.backend.service';
import {TranslatorService} from '../translator.service';
import {WarningService} from '../warning.service';

@Component({
  selector: 'app-old-modal-new-folder',
  templateUrl: './old-modal-new-folder.component.html',
  styleUrls: ['./old-modal-new-folder.component.scss']
})
export class OldModalNewFolderComponent  {

  @Input() folder: Folder | null = null;

  @Input() folderModeloDinamico: Folder | null = null;
  @Input() folderType = 'docs';
  @Output() closeModalEmitter = new EventEmitter<any>();
  @Output() addFolderEmmiter = new EventEmitter<any>();
  lang: Language = localStorage.getItem('lang') as Language || 'pt-br';
  newFolderName = '';
  loadingAddFolder = false;

  constructor(
    private docBackend: DocBackendService,
    private warningService: WarningService,
    private _translationService: TranslatorService,
  ) { }


  t(ptText: string, replacements?: string[]) {
    return this._translationService.translate(ptText, this.lang, replacements);
  }

  closeModal() {
    this.closeModalEmitter.emit();
  }

  preventModalFromClosing($event) {
    $event.stopPropagation();
  }

  cleanNewFolderName() {
    if (this.newFolderName.indexOf('/') !== -1 || this.newFolderName.indexOf('\\') !== -1) {
      this.warningService.toastrError(this.t('Não é possível utilizar / ou \\ em nome de pastas.'));
      this.newFolderName = this.newFolderName.replace(/\//g, '').replace(/\\/g, '');
    }
  }

  addFolder() {
    if (this.newFolderName.length === 0) {
      this.closeModal();
      return;
    }

    const params = {
      name: this.newFolderName,
      type: this.folderType
    } as any;

    if (this.folder !== null) {
      params.parent_folder_token = this.folder.token;
    } else if (this.folderModeloDinamico !== null) {
      params.parent_folder_token = this.folderModeloDinamico;
    }
    this.docBackend.newFolder(params).subscribe(
      () => {
        this.addFolderEmmiter.emit();
      },
      err => {
        console.error(err);
        this.warningService.toastrError(this.t('Ocorreu algum problema. Tente atualizar a tela'));
        this.loadingAddFolder = false;
      }
    );
  }
}
