<h2 mat-dialog-title>{{"Ei, ZapSigner! Queremos te conhecer melhor" | translate}} </h2>
<form [formGroup]="formAskBusinessSegment" (submit)="sendDataBusinessSegment()">
  <section>
    <mat-dialog-content class="mat-typography">
      <span>{{"Qual o motivo de criar a conta?" | translate}}</span>
      <mat-form-field appearance="outline">
        <mat-label>{{'Selecione' | translate}}</mat-label>
        <mat-select id="reason-create-account-select-test-id" panelClass="optionsSelectModalAskBusiness" formControlName="reasonCreateAccount">
          <mat-option
            *ngFor="let reason of reasonCreateAccountOptions"
            [value]="reason.id">
            {{reason.value | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <span>{{"Qual seu ramo de atividade?" | translate}}</span>
      <mat-form-field appearance="outline">
        <mat-label>{{'Selecione' | translate}}</mat-label>
        <mat-select id="activity-select-test-id" panelClass="optionsSelectModalAskBusiness" formControlName="activity">
          <mat-option *ngFor="let activity of activityOptions" [value]="activity.id">
            {{activity.value | translate}}
          </mat-option>

        </mat-select>
      </mat-form-field>

      <span>{{"Quantos documentos envia por mês?" | translate}}</span>
      <mat-form-field appearance="outline">
        <mat-label>{{'Selecione' | translate}}</mat-label>
        <mat-select  id="documents-per-month-select-test-id"  panelClass="optionsSelectModalAskBusiness" formControlName="documentsPerMonth">
          <mat-option *ngFor="let documentsPerMonth of documentsPerMonthOptions" [value]="documentsPerMonth.id">
            {{documentsPerMonth.value | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <span>{{"Como nos descobriu? Conta pra gente!" | translate}}</span>
      <mat-form-field appearance="outline">
        <mat-label>{{'Selecione' | translate}}</mat-label>
        <mat-select  id="meet-us-select-test-id"  panelClass="optionsSelectModalAskBusiness" formControlName="howMeetUs">
          <mat-option *ngFor="let howMeetUs of howMeetUsOptions" [value]="howMeetUs.id">
            {{howMeetUs.value | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </mat-dialog-content>
  </section>


  <mat-dialog-actions align="center" style="display: flex;flex-direction: column;">
    <div [matTooltip]="setMatTooltipButton()">
      <button
        [disabled]="!formAskBusinessSegment.valid"
        [style.background]="!formAskBusinessSegment.valid ? '#BDBDBD': '#2196f3'"
        type="submit"
        mat-dialog-close
        mat-raised-button>
        {{"Enviar" | translate}}
      </button>
    </div>
    <button
      style="margin-top:10px;"
      [style.color]="'#BDBDBD'"
      mat-button
      (click)="sendDataBusinessSegmentWithoutAnswer()"
      mat-dialog-close>{{'Não quero responder' | translate}}</button>
  </mat-dialog-actions>
</form>
