import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UserGroup, UserGroupState} from '../store/modal-user-groups.models';
import {AuthBackendService} from "../../../auth/backend.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Store} from "@ngrx/store";
import {AppState} from "../../../app.state";
import {setUserGroupAction} from "../../../client/settings-organization/store/user-group/user-group.actions";
import {useSelectorUserGroups} from "../../../app.state.selectors";
import {Subscription} from "rxjs";
import {TranslatorService} from '../../translator.service';
import {Language, Locale} from 'src/translation/translation';
import {WarningService} from '../../warning.service';
import {PaywallData} from "../../../helpers/model/paywallData";
import {User} from "../../../helpers/model/user";
import {PagamentosBackendService} from "../../pagamentos.backend.service";
import {UsersReduxService} from "@shared/services/redux/users-redux/users-redux.service";

@Component({
  selector: 'app-form-user-group',
  templateUrl: './form-user-group.component.html',
  styleUrls: ['./form-user-group.component.scss']
})
export class FormUserGroupComponent implements OnInit, OnDestroy {
  locale: Locale = localStorage.getItem('locale') as Locale || 'br';
  lang: Language = localStorage.getItem('lang') as Language || 'pt-br';
  userGroups: UserGroup[];
  storeSubscriptions: Subscription[] = [];
  user: User;
  canBatchSignature: boolean;
  state: UserGroupState = 'new';

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      itemsShowLimit: 4,
      allowSearchFilter: true,
      enableCheckAll: false,
      searchPlaceholderText: this.translatorService.translate('Procurar', this.lang)
    };

  loading = true;

  constructor(private authBackend: AuthBackendService,
              @Inject(MAT_DIALOG_DATA) public userGroup: UserGroup | null,
              private store: Store<AppState>,
              private matDialog: MatDialogRef<FormUserGroupComponent>,
              private translatorService: TranslatorService,
              private warningService: WarningService,
              private pagamentosBackend: PagamentosBackendService,
              private _store: Store<AppState>,
              private usersReduxService: UsersReduxService
  ) { }

  ngOnInit(): void {
    this.getMe();
    if (this.userGroup) {
      this.state = 'update';
      this.getEmailsUserGroup();
    } else {
      this.userGroup = new UserGroup();
      this.getEmailsMemberships();
    }
    const userGroupsSupscription = this.store.select(useSelectorUserGroups).subscribe((state) => {
      this.userGroups = state.userGroups;
    });
    this.storeSubscriptions.push(userGroupsSupscription);
  }

  ngOnDestroy(): void {
    this.storeSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  getMe() {
    const userSubscription = this.usersReduxService.getUser().subscribe(({user}) => {
      if (user) {
        this.user = user;
        this.getPaywall();
      }
    });

    this.storeSubscriptions.push(userSubscription);
  }

  getPaywall() {
    this.pagamentosBackend.getPaywall(this.user.company.id).subscribe(
      (data: PaywallData) => {
        this.canBatchSignature = data.can_batch_signature;
        this.loading = false;
      },
      err => {
        console.error(err);
      }
    );
  }

  t(ptText: string) {
    return this.translatorService.translate(ptText, this.lang);
  }

  async getCompanyUserGroup(): Promise<void> {
    try {
      const {userGroups} = await this.authBackend.getCompanysUserGroups().toPromise();
      this._store.dispatch(setUserGroupAction({payload: userGroups}));
    } catch (error) {
      console.error(error);
    }
  }

  createUserGroup(state) {
    this.userGroup.users_email = this.selectedItems.map(selected => {
      return selected.item_id;
    });
    if (state === 'new') {
      this.authBackend.createUserGroup(this.userGroup).subscribe((data) => {
        this.userGroup.id = data.userGroup.id;
        const newUserGroups = [...this.userGroups, this.userGroup];
        this.store.dispatch(setUserGroupAction({payload: newUserGroups}));
        this.warningService.toastrSuccess(this.t('Grupo criado com sucesso.'));
        this.matDialog.close('change');
        this.getCompanyUserGroup();
      },
      err => {
        if(err) {
          console.error(err)
          this.warningService.toastrError(this.t(err.error));
        }
      });
    }
    if (state === 'update') {
      this.authBackend.updateUserGroup(this.userGroup).subscribe(() => {
        const updatedUserGroups = this.userGroups.map((userGroup) => {
          if (userGroup.id === this.userGroup.id) {
            return this.userGroup;
          }
          return userGroup;
        });
        this.store.dispatch(setUserGroupAction({payload: updatedUserGroups}));
        this.warningService.toastrSuccess(this.t('Grupo atualizado com sucesso.'));
        this.matDialog.close('change');
        this.getCompanyUserGroup();
      },
      err => {
        if(err) {
          console.error(err)
          this.warningService.toastrError(this.t(err.error));
        }
      });
    }
  }

  getEmailsUserGroup() {
    this.authBackend.getEmailsUserGroup(this.userGroup.id).subscribe(({ userGroups }) => {
      this.dropdownList = userGroups.all_emails.map((email: string) => {
        return { item_id: email, item_text: email };
      });

      this.selectedItems = userGroups.email_in_user_group.map((email: string) => {
        return { item_id: email, item_text: email };
      });

    });
  }

  private getEmailsMemberships() {
    this.authBackend.getEmailsMemberships().subscribe(({ memberships }) => {
      this.dropdownList = memberships.all_emails.map((email: string) => {
        return { item_id: email, item_text: email };
      });
    });
  }

  textPlaceholder() {
    return this.t('Procurar usuários');
  }
}
