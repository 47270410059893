import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-card-insufficient-credits',
  templateUrl: './card-insufficient-credits.component.html',
  styleUrls: ['./card-insufficient-credits.component.scss']
})
export class CardInsufficientCreditsComponent {
  @Input() creditsAmount: number;

}
