<div class="zs_all_modal_content">
  <section class="zs_content_section_modal_helper">
    <h2 mat-dialog-title class="zs_title_modal_helper">{{data.title}}</h2>
      <p class="zs_description_modal_helper">{{data.description}}</p>
      <img [alt]="data.description" class="zs_gif_modal_helper" src="{{data.gif}}">
      <p>{{'Saiba mais sobre em nosso material' | translate}}
       <a href="{{data.helperLink}}" target="_blank" class="zs_link_modal_helper">"{{data.titleHelperLink}}"</a>
      {{'da Central de ajuda.' | translate}}</p>
  </section>

  <section class="zs_need_help_modal_helper">
    <h3><strong>{{'Ainda precisa de ajuda?' | translate}}</strong></h3>
    <mat-dialog-actions class="zs_actions_modal_helper">
      <a
        [href]="redirectToWhatsAppBotmakerOrLATAM()" target="_blank"
        mat-button
        mat-raised-button
        [mat-dialog-close]="true"
        class="zs_yes_need_help_button">{{'Sim, preciso de ajuda' | translate}} 🤔</a>
      <a
        (click)="closedModal()"
        mat-button
        mat-raised-button
        [mat-dialog-close]="true"
        class="zs_resolved_doubt_button">{{'Dúvida resolvida' | translate}} 👍</a>
    </mat-dialog-actions>
  </section>
</div>
