<div class="container-dropzone">
  <div
    ng2FileDrop
    [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
    (fileOver)="fileOverBase($event)"
    [uploader]="uploader"
    class="my-drop-zone dir_dropzone"
    [class.uploaded_doc_container]="successfulFiles.length > 0"
    accept=".doc, .docx, .pdf, .odt"
  >
    <div *ngIf="!uploader.isUploading && !isLoadingExampleFile && successfulFiles.length == 0">
      <mat-icon class="dir_dropzone_icon">cloud_upload</mat-icon>
    </div>
    <div *ngIf="!uploader.isUploading && !isLoadingExampleFile && successfulFiles.length > 0">
      <mat-icon class="dir_dropzone_icon dir_dropzone_icon_done">cloud_done</mat-icon>
    </div>

    <app-spinner-loading *ngIf="uploader.isUploading || isLoadingExampleFile"></app-spinner-loading>

    <div *ngFor="let file of successfulFiles; let i = index" class="file-successful-item">
      <p id="file-name-uploaded-test" [innerHTML]="file.name.length > 24 ? file.name.slice(0,24)+'...' : file.name"></p>
      <a mat-icon-button class="color_icons_document_file_successful-item"
         matTooltip="{{'Abrir documento' | translate}}"
         href="{{file.url}}"
         target="_blank">
        <mat-icon class="open_document_icon_svg" svgIcon
          ="open_in_new_tab"></mat-icon>
      </a>
      <button data-cy="remove-document-icon-dropzone" mat-icon-button class="color_icons_document_file_successful-item"
              matTooltip="{{'Remover documento' | translate}}"
              (click)="removeFile(i)">
        <mat-icon>remove_circle</mat-icon>
      </button>
    </div>

    <div *ngFor="let item of uploader.queue">
      <p *ngIf="item.progress < 100">{{'Carregando' | translate}} {{item.file.name.slice(0, 12)}}... ({{item.progress}}
        %)</p>
      <p *ngIf="item.progress == 100">{{'Só mais um instante...' | translate}}</p>
    </div>


    <div *ngIf="isLoadingExampleFile">
      <p>{{'Só mais um instante...' | translate}}</p>
    </div>
    <div
      *ngIf="!uploader.isUploading && (successfulFiles.length == 0 || allowHowManyFiles > successfulFiles.length)"
      [style.margin]="successfulFiles.length == 0 ? '0 0 12px 0' : '0 0 0 0'"
    >
      <label for="files">
                <span id="select-files-test" class="selecionar-arquivos-btn"
                      *ngIf="successfulFiles.length == 0 && !isLoadingExampleFile">
                    {{'SELECIONAR DOCUMENTO' | translate}}
                </span>
        <span data-cy="add-documents-complementary-test-id"
              class="selecionar-arquivos-btn button-add-more-documents-complementary"
              *ngIf="successfulFiles.length > 0" matTooltip="{{'Adicionar documentos complementares' | translate}}">
                    <mat-icon class="icon-add-more-documents">add</mat-icon>
            </span>

        <input
          [attr.multiple]="allowHowManyFiles > 1"
          id="files"
          type="file"
          name="files"
          ng2FileSelect
          [uploader]="uploader"
          accept=".doc, .docx, .pdf, .odt"
        >
      </label>
      <p *ngIf="pathExampleDownload && !isLoadingExampleFile && successfulFiles.length == 0" style="margin-top:1em;">
        {{'ou' | translate}}
        <a [href]="" (click)="useExampleDownloadAsFile()" target="_blank" download class="example-file">
          {{'clique aqui' | translate}}
        </a> {{'para usar um arquivo de exemplo' | translate}}
      </p>
      <span *ngIf="successfulFiles.length == 0 && !isDinamicTemplate" class="message_accept_formatted_file">{{'Aceitamos arquivos em formato docx ou' | translate}} <abbr [title]="'Formato de Documento Portátil' | translate">PDF</abbr></span>

    </div>
  </div>
</div>
