import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {Locale} from '@translation/translation';
import {AppState} from '../../app.state';
import {AuthBackendService} from '@auth/backend.service';
import {StepsCreateDocumentService} from '@client/page-new-document/services/steps-create-document.service';
import {AddMembershipsRequest} from '@reducers/actions/memberships.actions';
import {CloseRequest, OpenCompanyRequest} from '@reducers/actions/modal-user-actions';
import {ConsumerTranslatorService} from '@shared/services/consumer-translator/consumer-translator.service';
import {MixpanelService} from '@shared/services/mixpanel.service';
import {UserCompaniesService} from '@shared/services/user-companies/user-companies.service';
import {TranslateLocaleService} from '../translate-locale.service';
import {OptionsNavbar} from '../user-navbar/user-navbar.model';
import {WarningService} from '../warning.service';
import {User} from '@helpers/model/user';
import {UsersReduxService} from '@shared/services/redux/users-redux/users-redux.service';
import {Subscription} from 'rxjs';


@Component({
  selector: 'app-modal-select',
  templateUrl: './modal-select.component.html',
  styleUrls: ['./modal-select.component.scss']
})
export class ModalSelectComponent implements OnInit, OnDestroy {
  locale: Locale = localStorage.getItem('locale') as Locale || 'br';

  showModal = false;
  highlight = 'user';
  showProfile = true;
  showCompanies = false;
  isMultiCompany = false;
  optionsNavbar: OptionsNavbar[] = [
    {value: this.translator.translate('Meu perfil'), icon: 'icon_user_new', id: '1', route: ['conta', 'perfil'], href: ''},
    {value: this.translator.translate('Organizações'), icon: 'icon_dataflow', id: '2', route: ['conta', 'escolha-organizacao'], href: ''},
    {value: this.translator.translate('Indique e ganhe!'), icon: 'icon_currency_dollar_circle', id: '3', route: null, href: this.translateLocaleService.translateLocale('https://indique.buzz/zapsign?bl_ref=WJWE2', this.locale)},
    {value: this.translator.translate('Sair'), icon: 'icon_log_out', id: '4', route: ['acesso', 'sair'], href: ''}
  ];
  memberships = [];
  loading = true;
  currentCompany: number;
  userEmail = '';
  membershipsLength: number;
  isMembershipsPage: number;
  user: User;
  credits = 0;
  showCredits = true;

  mayCompany: any;
  subscriptions: Subscription[] = [];
  constructor(
    private router: Router,
    private translator: ConsumerTranslatorService,
    private stepsCreateDocumentService: StepsCreateDocumentService,
    private authBackend: AuthBackendService,
    private warningService: WarningService,
    private userCompaniesService: UserCompaniesService,
    public store: Store<AppState>,
    private mixpanelService: MixpanelService,
    private translateLocaleService: TranslateLocaleService,
    private usersReduxService: UsersReduxService
  ) {

  }
  ngOnInit(): void {
    if (localStorage.getItem('multi_companies') === '1') {
      this.isMultiCompany = true;
      this.optionsNavbar.splice(1, 1);
      this.getMemberships();
    }
    if (localStorage.getItem('companyId')) {
      this.currentCompany = parseInt(localStorage.getItem('companyId'));
    }
    this.userEmail = localStorage.getItem('user_email');
    this.closeModal();
    const subscribeShowModal = this.store.select('modalUser').subscribe(
      ({ open }) => {
        this.showModal = open;
      }
    );
    const subscribeHighlight = this.store.select('modalUser').subscribe(
      ({ highlight }) => {
        this.highlight = highlight;
        if (this.highlight === 'user') {
          this.showCompanies = false;
          this.showProfile = true;
        } else {
          this.showCompanies = true;
          this.showProfile = false;
        }
      }
    );
    const userSubscription = this.usersReduxService.getUser().subscribe(({user}) => {
      if (user) {
        this.user = user;
        this.credits = this.user.company.credits_balance;
      }
    });

    this.subscriptions = [
      userSubscription,
      subscribeHighlight,
      subscribeShowModal
    ];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  t(text: string) {
    return this.translator.translate(text);
  }

  closeModal() {
    this.store.dispatch(new CloseRequest());
  }

  preventModalFromClosing($event: MouseEvent) {
    $event.stopPropagation();
  }

  toogleShowProfile() {
    this.showProfile = !this.showProfile;
    this.showCompanies = !this.showProfile;
    if (this.showCompanies && this.memberships.length === 0) {
      this.getMemberships();
    }
  }

  toogleShowCompanies() {
    this.showCompanies = !this.showCompanies;
    this.showProfile = !this.showCompanies;
    this.store.dispatch(new OpenCompanyRequest());
    if (this.showCompanies && this.memberships.length === 0) {
      this.getMemberships();
    }
  }

  labelCredits() {
    return this.credits === 1 ? 'crédito' : 'créditos';
  }
  navigateTo(navbarElement: OptionsNavbar) {
    localStorage.removeItem('signersOldCreated');
    this.stepsCreateDocumentService.emitterTriggerStepsFromRedux('upload-file');
    if (navbarElement.value === this.translator.translate('Sair')) {
      sessionStorage.setItem('redirect_after_authentication', window.location.pathname + window.location.search);
    }
    if (navbarElement.route) {
      this.router.navigate(navbarElement.route);
    } else {
      window.open(navbarElement.href, '_blank');
      this.mixpanelService.sendEventMixpanel('Affiliate program buttom - Click', {}).subscribe();
    }
    this.closeModal();
  }

  navigateOrganizations() {
    this.router.navigate(['conta', 'escolha-organizacao']);
    this.closeModal();
  }

  redirectCreditsPlanAndPrices() {
    this.router.navigate(
      ['conta/configuracoes/plans'],
        { queryParams: {
          tab: 'credits',
        }});
    this.closeModal();
  }

  getMemberships() {
    this.authBackend.getMyMemberships().subscribe(
      data => {
        this.store.dispatch(new AddMembershipsRequest(data));
        this.membershipsLength = data.length;
        this.isMembershipsPage = data.length > 4 ? 2 : 1;
        this.memberships = [...data];
        this.mayCompany = this.memberships.filter(maycompany => (maycompany.company.id === this.currentCompany))[0];
        (this.mayCompany.role === 'owner' || this.mayCompany.role === 'admin') ? this.showCredits = true : this.showCredits = false;
        if (this.mayCompany) {
          const indice = this.memberships.indexOf(this.mayCompany);
          this.memberships.splice(indice, 1);
          this.memberships.unshift(this.mayCompany);
        }
        this.memberships = this.memberships.slice(0, 4);
        this.loading = false;
      },
      err => {
        console.error(err);
        this.warningService.toastrError(this.translator.translate('Ocorreu algum problema. Tente novamente mais tarde.'));
      }
    );
  }

  chooseCompany(company: any) {
    this.userCompaniesService.chooseCompany(company);
    this.store.dispatch(new CloseRequest());
  }
}
