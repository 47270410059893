import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {LivenessValidatorService} from './services/liveness-validator.service';
import {Liveness} from './liveness';
import {LivenessResponse} from './liveness-response';
import {useUserAgent} from '../../app.state.selectors';
import {Store} from '@ngrx/store';
import {AppState} from '../../app.state';
import {Subscription} from 'rxjs';
import {environment} from '@env/environment';
import {SlackService} from '@shared/services/slack/slack.service';
import {SlackMessageBuilder} from '@shared/services/slack/builders/slack-message-builder';
import {SlackMessage, SlackMessageType} from '@shared/services/slack/builders/slack-message.model';

@Component({
  selector: 'app-liveness-validator',
  templateUrl: './liveness-validator.component.html',
  styleUrls: ['./liveness-validator.component.scss'],
})
export class LivenessValidatorComponent implements OnInit, OnDestroy {

  @Output()
  validate = new EventEmitter<LivenessResponse>();

  @Output()
  handleError = new EventEmitter<any>();

  livenessApiScriptElement: HTMLScriptElement;

  liveness: Liveness;

  isDesktop: boolean;

  subscriptions: Subscription[] = [];

  slackMessageBuilder: SlackMessageBuilder = new SlackMessageBuilder();

  constructor(
    private store: Store<AppState>,
    private livenessValidatorService: LivenessValidatorService,
    private slackService: SlackService
  ) {
    const userAgentSubscription = this.store.select(useUserAgent).subscribe(({isDesktop}) => {
      this.isDesktop = isDesktop;
    });
    this.subscriptions.push(userAgentSubscription);
  }

  ngOnInit(): void {
    this.addLivenessScriptToPage();
  }

  private addLivenessScriptToPage() {
    this.livenessApiScriptElement = document.createElement('script');
    this.livenessApiScriptElement.src = 'https://cdn.jsdelivr.net/gh/nextcodebr/liveness-sdk-web-sample/dist/v6.4/liveness.js';
    this.livenessApiScriptElement.onload = () => {
      void this.startLiveness();
    };
    document.body.appendChild(this.livenessApiScriptElement);
  }

  ngOnDestroy() {
    try {
      this.liveness?.stop();
    } catch (e) {
      console.error(e);
    } finally {
      this.livenessApiScriptElement?.remove();
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }


  private async getConfig() {
    const config: any = {
      isDebug: false,
      token: await this.livenessValidatorService.getJWT(),
      faceapiPath: 'https://cdn.jsdelivr.net/gh/nextcodebr/liveness-sdk-web-sample/libs',
      livenessUrlBase: environment.NXCD_URL,
      livenessConfirmEndpoint: '/liveness/v3',
      isShowPreview: false,
      errorCallback: this.onError,
      successCallback: this.onSuccess,
      cameraPermissionErrorCallback: this.cameraPermissionError,
      ellipseStrokeStyle: '#287DF0',
      activatedEllipseStrokeStyle: '#287DF0',
      boxMessageBackgroundColor: '#287DF040',
      boxMessageTextColor: '#2196F3',
      configEyesBoxHeight: 100,
    };
    if (this.isDesktop) {
      config.frameBox = {
        eyesInner: 0.52, // modo debug = retangulo amarelo
        eyesOutter: 1.1, // modo debug = retangulo vermelho

        // 50% do tamanho do vídeo
        box: 0.50 // modo debug = retangulo azul *** quanto maior, mais aberto === elipse mais aberta
      };

      config.width = 400;
      config.height = 300;
      config.scalingFactorForLiveness = 3;
    }
    return config;
  }

  async startLiveness() {
    const videoWrapper = document.getElementById('video-wrapper');
    // @ts-ignore
    this.liveness = new Liveness(videoWrapper, await this.getConfig());
    this.liveness.start();
  }

  public onSuccess = (response: LivenessResponse) => {
    this.validate.emit(response);
    const slackMessage = this.getSlackMessageForSuccess(response);
    this.slackService.send(slackMessage);
  }
  public onError(response) {
    this.handleError.emit(response);
    const slackMessage = this.getSlackMessageForError(response.error.error);
    this.slackService.send(slackMessage);
  }
  public cameraPermissionError = (response: any) => {
    this.handleError.emit(response);
    const slackMessage = this.getSlackMessageForCameraPermissionError(response);
    this.slackService.send(slackMessage);
  }

  private getSlackMessageForSuccess(response: LivenessResponse): SlackMessage {
    const isAlive = response.data.isAlive;
    return this.slackMessageBuilder
      .setChannel('#liveness')
      .setText('Uma validação de liveness foi bem sucedida!')
      .setTitle(`Resposta da NXCD: isAlive = ${isAlive}`)
      .setDescription(`Dispositivo: ${window.navigator.userAgent}`)
      .setMessageType(isAlive ? SlackMessageType.SUCCESS : SlackMessageType.WARNING)
      .setPath(window.location.href)
      .getMessage();
  }


  private getSlackMessageForError(error: any): SlackMessage {
    return this.slackMessageBuilder
      .setChannel('#liveness')
      .setText(`[${error.statusCode}] ${error.error}`)
      .setTitle('Mensagem de erro')
      .setDescription(error.message)
      .setMessageType(SlackMessageType.ERROR)
      .setPath(window.location.href)
      .getMessage();
  }

  private getSlackMessageForCameraPermissionError(error: DOMException): SlackMessage {
    return this.slackMessageBuilder
      .setChannel('#liveness')
      .setText(`[${error.name}] Usuário não concedeu permissão para a câmera`)
      .setTitle('Mensagem de erro')
      .setDescription(error.message)
      .setMessageType(SlackMessageType.ERROR)
      .setPath(window.location.href)
      .getMessage();
  }

}
