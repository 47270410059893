<div class="zs_profile"
     id="select-navbar-test"
     (click)="openModal()"
     [ngClass]="showModal ? 'zs_profile_active' : ''">
  <div class="zs_content_dados_user">
    <span class="zs_email_user">{{userEmail}}</span>
    <div class="zs_content_icons">
      <mat-icon class="mat-icon-high" *ngIf="showModal" svgIcon="icon_arrow_up"></mat-icon>
      <mat-icon class="mat-icon-high" *ngIf="!showModal" svgIcon="icon_arrow_down"></mat-icon>
    </div>
  </div>
  <div class="zs_icon_user">
    <mat-icon class="zs_modal_icon" svgIcon="icon_user_new"></mat-icon>
  </div>
</div>


<app-modal-select
></app-modal-select>
