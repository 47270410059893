<div class="integration-apps-header">
    <h2>
      {{'Aplicativos' | translate}}
    </h2>
    <p class="tutorial-container">
    <a href="https://www.youtube.com/watch?v=S9oLOsbu0eA" target="_blank">
      <img src="/assets/img/youtube.svg" class="ajuda_icon">
      {{'Assistir Tutorial: Como integrar a ZapSign com Google Drive' | translate}}
    </a>
  </p>
</div>
  <p
     style="margin:0">{{'Integre a ZapSign a mais de 2.000 plataformas sem uma linha de código via Pluga, Make ou Zapier. Selecione a plataforma para vincular o aplicativo.' | translate}}
    <br><br>
  </p>

<mat-accordion>
    <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)">
      <mat-expansion-panel-header>
        <mat-panel-title class="accordion-title">
          {{'Armazenamento e documentos' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div>
        <p class="secondary_text">{{'Salve uma cópia em uma plataforma de nuvem quando um documento é assinado. Selecione a plataforma que você deseja vincular à sua conta ZapSign.' | translate}}</p>
        <div class="apps-list">
          <div class="apps-row">
            <div class="integration-item">
              <div class="integration-inside-item">
                <div class="integration-img"><img src="/assets/img/integrations/google-drive.png"/></div>
                <div class="integration-title">
                  <p>Google</p>
                  <p>Drive</p>
                </div>
                <div class="integration-item-buttons">
                  <div class="integration-item-button">
                    <a class="integration-cta" href="https://www.integromat.com/en/integration/8889-integration-zapsign-webhook-document-signed-http-google-drive" target="_blank" >{{'Vincular com'|translate}} Make</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="integration-item">
              <div class="integration-inside-item">
                <div class="integration-img"><img src="/assets/img/integrations/dropbox.png"/></div>
                <div class="integration-title">
                  <p>Dropbox</p>
                </div>
                <div class="integration-item-buttons">
                  <div class="integration-item-button">
                    <a href="https://www.integromat.com/en/integration/8890-integration-zapsign-webhook-document-signed-http-dropbox" target="_blank"  class="integration-cta">{{'Vincular com'|translate}} Make</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="apps-row">
            <div class="integration-item">
              <div class="integration-inside-item">
                <div class="integration-img"><img src="/assets/img/integrations/onedrive.png"/></div>
                <div class="integration-title">
                  <p>OneDrive</p>
                </div>
                <div class="integration-item-buttons">
                  <div class="integration-item-button">
                    <a href="https://www.integromat.com/en/integration/8891-integration-zapsign-webhook-document-signed-http-onedrive" target="_blank" class="integration-cta">{{'Vincular com'|translate}} Make</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="integration-item">
              <div class="integration-inside-item">
                <div class="integration-img"><img src="/assets/img/integrations/google-sheets.png"/></div>
                <div class="integration-title">
                  <p>Google</p>
                  <p>Sheets</p>
                </div>
                <div class="integration-item-buttons">
                  <div class="integration-item-button">
                    <a href="https://www.integromat.com/en/help/app/zapsign" target="_blank" class="integration-cta">{{'Vincular com'|translate}} Make</a>
                  </div>
                  <div class="integration-item-button">
                    <a href="https://pluga.co/ferramentas/zapsign/integracao/google_sheets/" target="_blank" class="integration-cta">{{'Vincular com'|translate}} Pluga</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)">
      <mat-expansion-panel-header>
        <mat-panel-title  class="accordion-title">
          {{'Vendas e CRM' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div>
        <p class="secondary_text">{{'Automatize a criação e envio de documentos com informações de contato do CRM.' | translate}}</p>
        <div class="apps-list">
          <div class="apps-row horizontal-all-apps">
            <div class="integration-item">
              <div class="integration-inside-item">
                <div class="integration-img-no-discription"><img style="width: 61px" src="/assets/img/integrations/hubspot-text.png"/></div>
                <div class="integration-item-buttons">
                  <div class="integration-item-button">
                    <a href="https://www.integromat.com/en/help/app/zapsign" target="_blank"  class="integration-cta">{{'Vincular com'|translate}} Make</a>
                  </div>
                  <div class="integration-item-button">
                    <a href="https://pluga.co/ferramentas/zapsign/integracao/hubspot_crm/" target="_blank" class="integration-cta">{{'Vincular com'|translate}} Pluga</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="integration-item">
              <div class="integration-inside-item">
                <div class="integration-img-no-discription"><img style="width: 51px" src="/assets/img/integrations/zoho.png"/></div>
                <div class="integration-item-buttons">
                  <div class="integration-item-button">
                    <a href="https://www.integromat.com/en/help/app/zapsign" target="_blank" class="integration-cta">{{'Vincular com'|translate}} Make</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="apps-row horizontal-all-apps">
            <div class="integration-item">
              <div class="integration-inside-item">
                <div class="integration-img-no-discription"><img style="width: 53px" src="/assets/img/integrations/safeforce.png"/></div>
                <div class="integration-item-buttons">
                  <div class="integration-item-button">
                    <a href="https://www.integromat.com/en/help/app/zapsign" target="_blank" class="integration-cta">{{'Vincular com'|translate}} Make</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="integration-item">
              <div class="integration-inside-item">
                <div class="integration-img-no-discription"><img style="width: 71px" src="/assets/img/integrations/pipedrive-text.png"/></div>
                <div class="integration-item-buttons">
                  <div class="integration-item-button">
                    <a href="https://www.integromat.com/en/apps/invite/06ff10f6273cf4876f175b8fc5b0f2ac" target="_blank" class="integration-cta">{{'Vincular com'|translate}} Make</a>
                  </div>
                  <div class="integration-item-button">
                    <a href="https://pluga.co/ferramentas/zapsign/integracao/pipedrive/" target="_blank" class="integration-cta">{{'Vincular com'|translate}} Pluga</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)">
      <mat-expansion-panel-header>
        <mat-panel-title  class="accordion-title">
          {{'Outros' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div>
        <p class="secondary_text">{{'Encontre mais plataformas para integrar de acordo com as necessidades da sua empresa. Integre a ZapSign com plataformas de e-commerce, sites, ERP e muito mais.' | translate}}</p>
        <div class="apps-list">
          <div class="apps-row">
            <div class="integration-item">
              <a href="https://www.make.com/en/integrations" target="_blank" >
                <div class="integration-inside-item-vertical vertical-all-apps">
                  <div class="integration-img-no-discription"><img style="width: 133px" src="/assets/img/integrations/make.png"/></div>
                  <div class="integration-item-all-button">
                      <p class="integration-cta">{{'Aplicativos disponíveis'|translate}}</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="integration-item">
              <a href="https://pluga.co/ferramentas/zapsign/integracao/" target="_blank" >
                <div class="integration-inside-item-vertical vertical-all-apps">
                  <div class="integration-img-no-discription"><img style="width: 81px; height: 34px" src="/assets/img/integrations/pluga.png"/></div>
                  <div class="integration-item-all-button">
                      <p class="integration-cta">{{'Aplicativos disponíveis'|translate}}</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="integration-item">
              <a href="https://zapier.com/app/login?next=/developer/public-invite/164001/bc85b26ea48f36e03b89a38c12af490b/" target="_blank" >
                <div class="integration-inside-item-vertical vertical-all-apps">
                  <div class="integration-img-no-discription"><img style="width: 98px" src="/assets/img/integrations/zapier.png"/></div>
                  <div class="integration-item-all-button">
                      <p class="integration-cta">{{'Aplicativos disponíveis'|translate}}</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
