<div [class]="'zs-mat-select ' + classRadius" [ngClass]="label ? 'with-label' : ''">
  <mat-form-field appearance="fill" floatLabel="never">
    <mat-label *ngIf="label">{{label|translate}}</mat-label>
    <mat-select panelClass="my-select-panel-class" [value]="value"
                (valueChange)="ChangeEmiter($event)">
      <mat-option *ngFor="let option of options" [value]="option" [disabled]="option.name===disabledOption">
        <p class="text-select-option" [style.fontFamily]="styleFont? option?.value : ''">{{ option.name | translate }}</p>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
