export const TIMEZONES = [
  {name: '(UTC-03:00) Buenos Aires', value: 'America/Buenos_Aires'},
  {name: '(UTC-04:00) La Paz', value: 'America/La_Paz'},
  {name: '(UTC-02:00) Fernando de Noronha', value: 'America/Noronha'},
  {name: '(UTC-03:00) Brasília', value: 'America/Sao_Paulo'},
  {name: '(UTC-04:00) Manaus', value: 'America/Manaus'},
  {name: '(UTC-05:00) Rio Branco', value: 'America/Rio_Branco'},
  {name: '(UTC-03:00) Santiago', value: 'America/Santiago'},
  {name: '(UTC-05:00) Isla de Pascua', value: 'Chile/EasterIsland'},
  {name: '(UTC-05:00) Bogota', value: 'America/Bogota'},
  {name: '(UTC-05:00) Quito', value: 'America/Guayaquil'},
  {name: '(UTC-06:00) Galápagos', value: 'Pacific/Galapagos'},
  {name: '(UTC-06:00) Ciudad de México', value: 'America/Mexico_City'},
  {name: '(UTC-07:00) Chihuahua, La Paz', value: 'America/Chihuahua'},
  {name: '(UTC-08:00) Tijuana', value: 'America/Tijuana'},
  {name: '(UTC-05:00) Lima', value: 'America/Lima'},
  {name: '(UTC-05:00) Eastern Time', value: 'US/Eastern'},
  {name: '(UTC-06:00) Central Time', value: 'US/Central'},
  {name: '(UTC-07:00) Mountain Time', value: 'US/Mountain'},
  {name: '(UTC-08:00) Pacific Time', value: 'US/Pacific'},
  {name: '(UTC-09:00) Alaska', value: 'US/Alaska'},
  {name: '(UTC-10:00) Hawaii', value: 'US/Hawaii'},
  {name: '(UTC-12:00) Global', value: 'Etc/GMT+12'},
  {name: '(UTC-11:00) Global', value: 'Etc/GMT+11'},
  {name: '(UTC-10:00) Global', value: 'Etc/GMT+10'},
  {name: '(UTC-09:00) Global', value: 'Etc/GMT+9'},
  {name: '(UTC-08:00) Global', value: 'Etc/GMT+8'},
  {name: '(UTC-07:00) Global', value: 'Etc/GMT+7'},
  {name: '(UTC-06:00) Global', value: 'Etc/GMT+6'},
  {name: '(UTC-05:00) Global', value: 'Etc/GMT+5'},
  {name: '(UTC-04:00) Global', value: 'Etc/GMT+4'},
  {name: '(UTC-03:00) Global', value: 'Etc/GMT+3'},
  {name: '(UTC-02:00) Global', value: 'Etc/GMT+2'},
  {name: '(UTC-01:00) Global', value: 'Etc/GMT+1'},
  {name: '(UTC+00:00) Global', value: 'Etc/GMT+0'},
  {name: '(UTC+01:00) Global', value: 'Etc/GMT-1'},
  {name: '(UTC+02:00) Global', value: 'Etc/GMT-2'},
  {name: '(UTC+03:00) Global', value: 'Etc/GMT-3'},
  {name: '(UTC+04:00) Global', value: 'Etc/GMT-4'},
  {name: '(UTC+05:00) Global', value: 'Etc/GMT-5'},
  {name: '(UTC+06:00) Global', value: 'Etc/GMT-6'},
  {name: '(UTC+07:00) Global', value: 'Etc/GMT-7'},
  {name: '(UTC+08:00) Global', value: 'Etc/GMT-8'},
  {name: '(UTC+09:00) Global', value: 'Etc/GMT-9'},
  {name: '(UTC+10:00) Global', value: 'Etc/GMT-10'},
  {name: '(UTC+11:00) Global', value: 'Etc/GMT-11'},
  {name: '(UTC+12:00) Global', value: 'Etc/GMT-12'},
  {name: '(UTC+13:00) Global', value: 'Etc/GMT-13'},
  {name: '(UTC+14:00) Global', value: 'Etc/GMT-14'},
];
