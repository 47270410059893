import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessorProxy} from '../../../shared/proxy/control-value-accessor-proxy';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'zs-password-input',
  templateUrl: './password-input-zs.component.html',
  styleUrls: ['./password-input-zs.component.scss', '../text-input/text-input-zs.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PasswordInputZsComponent),
    multi: true
  }]
})
export class PasswordInputZsComponent extends ControlValueAccessorProxy {
  @Input() formControlName = 'password';
  @Input() label = '';
  @Input() placeholder = '';
  @Input() width = '100%';
  @Input() disabled = false;

  hidePassword = true;

  toggleHidePassword() {
    this.hidePassword = !this.hidePassword;
  }

}
