import {Action, createReducer, on} from '@ngrx/store';
import {changeDocsFilter} from './change-docs-filter.action';
import {ChangeDocsFilterProps} from './change-docs-filter.model';

export const INITIAL_STATE: ChangeDocsFilterProps = {
    status_filter: 'all'
};

const _changeDocsFilterReducer = createReducer(
  INITIAL_STATE,
  on(changeDocsFilter, (state, payload: ChangeDocsFilterProps) => {
    return {...state, ...payload};
  }),
);

export function changeDocsFilterReducer(state: ChangeDocsFilterProps, action: Action) {
  return _changeDocsFilterReducer(state, action);
}
