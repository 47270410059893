import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AssetsService} from '../../shared/services/assets.service';
import {MixpanelService} from '../../shared/services/mixpanel.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'zs-modal-flow',
  templateUrl: './zs-modal-flow.component.html',
  styleUrls: ['./zs-modal-flow.component.scss']
})
export class ZsModalFlowComponent {
  @Input() step: any;
  @Output() closeModal = new EventEmitter();
  showModal = true;
  closeConfirm = false;

  // @Output() stepClick = new EventEmitter();
  constructor(
    public assetsService: AssetsService,
    public mixpanelService: MixpanelService
  ) {
    this.heightModal();
    window.addEventListener('resize', this.heightModal);
  }

  close() {
    if ( this.step !== 'payment-success') {
      this.closeConfirm = true;
      this.mixpanelService.sendEventMixpanel('Checkout: Cancel purchase (1 of 2)', {
        checkout_stage: this.step
      }).subscribe();
    } else {
      this.closeModal.emit();
    }
  }
  heightModal() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  closeConfirmed() {
    this.mixpanelService.sendEventMixpanel('Checkout: Cancel purchase (2 of 2)', {
      checkout_stage: this.step
    }).subscribe();
    this.showModal = false;
    this.closeModal.emit();
  }
  closeCanceled() {
    this.closeConfirm = false;
  }
}
