<aside *ngIf="userAgent.isDesktop;else isMobile">
  <p>
    {{'Por favor, permita o uso da câmera para fotografar sua selfie. Siga as instruções abaixo.'|translate}}
  </p>

  <app-instructions></app-instructions>
</aside>
<ng-template #isMobile>
  <p>
    {{'Você não deu acesso a sua câmera. Suba um arquivo da sua galeria para podermos continuarmos o processo de assinatura. Para isso, feche essa mensagem e clique em `Fotografar Selfie`.'|translate}}
  </p>

</ng-template>
