<div class="zs_meet_zapsign_content">
  <h2><ng-content></ng-content></h2>
  <ul class="zs_ul_step_done_when_downloading_document">
      <li>
        <mat-icon class="icon_approve_blue_background" svgIcon="approved_background_blue_icon"></mat-icon>
        <span>{{'Colete assinaturas pelo' | translate}}
          <strong>{{'WhatsApp, e-mail, SMS, Telegram' | translate}}</strong> {{'ou qualquer canal de comunicação.' | translate}}</span>
      </li>
      <li>
        <mat-icon class="icon_approve_blue_background" svgIcon="approved_background_blue_icon"></mat-icon>
        <span><strong>{{'92% dos documentos' | translate}}</strong> {{'são assinados em menos de 1 hora.' | translate}}</span>
      </li>
      <li>
        <mat-icon class="icon_approve_blue_background" svgIcon="approved_background_blue_icon"></mat-icon>
        <span><strong>{{'Sem impressoras,' | translate}}</strong> {{'correios e motoboys.' | translate}}</span>
      </li>
  </ul>
  <a mat-raised-button
     class="zs_button_screen_prevent_signers_downloading_signed_document"
     [href]="'https://zapsign.com.br?utm_source=zapsign&utm_medium=product&utm_campaign=signer-conhecer' | translate"
     [innerHTML]="t('Conhecer ZapSign')" target="_blank" (click)="setKeyInSessionStorageToTrackUser()"></a>
</div>
