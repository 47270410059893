import {Injectable} from '@angular/core';


interface DeleteCookieProps {
  name: string
  path: string
  domain?: string
}
@Injectable({
  providedIn: 'root'
})
export class CookieService {

  existsCookie(name: string) {
    let cookiesArray: Array<string> = document.cookie.split(';');
    let cookiesArrayLen: number = cookiesArray.length;
    let cookieName = `${name}=`;
    let cookie: string;

    for (let i: number = 0; i < cookiesArrayLen; i += 1) {
      cookie = cookiesArray[i].replace(/^\s+/g, '');
      if (cookie.indexOf(cookieName) == 0) {
        return true
      }
    }
    return false;
  }

  setCookie(name: string, value: string, expireHours: number, path: string = '', maxAge: string = '') {
    let date: Date = new Date();
    date.setTime(date.getTime() + expireHours * 60 * 60 * 1000);
    let expires = `expires=${date.toUTCString()}`;
    let cpath = path ? `; path=${path}` : '';
    let limitAge = maxAge ? `; Max-Age=${maxAge}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath}${limitAge}`;
  }

  getCookieValue(name: string): string {
    const nameLenPlus = (name.length + 1);
    return document.cookie
      .split(';')
      .map(c => c.trim())
      .filter(cookie => {
        return cookie.substring(0, nameLenPlus) === `${name}=`;
      })
      .map(cookie => {
        return decodeURIComponent(cookie.substring(nameLenPlus));
      })[0] || null;
  }

  deleteCookie(cookieConfigs: DeleteCookieProps) {
    const { name, path, domain } = cookieConfigs
    if (this.getCookieValue(name)) {
      document.cookie = name + "=" +
        ((path) ? ";path=" + path : "") +
        ((domain) ? ";domain=" + domain : "") +
        ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
  }
}
